<template>
    <article class="w-full">
        <team-form title="Create Team" @save="createTeam" />
    </article>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TeamForm from '@outreach/pages/admin/teams/TeamForm';

export default {
    name: 'CreateTeam',
    components: { TeamForm },
    computed: {
        ...mapState({
            loading: ({ loader }) => loader.loading
        })
    },
    methods: {
        ...mapActions({
            create: 'teams/create',
            setMessage: 'alerts/setMessage'
        }),
        async createTeam (data) {
            const team = await this.create(data);
            this.setMessage({ message: 'Team created successfully.', messageType: 'success', duration: 5000 });
            this.$router.push({ name: 'edit-team', params: { teamId: team.id } });
        }
    }
};
</script>
