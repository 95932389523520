<template>
    <portal to="modal">
        <transition name="modal">
            <div v-if="open" class="modal">
                <div
                    v-click-away="onClickAwayClose"
                    class="modal__panel bg-white rounded overflow-hidden max-h-full overflow-y-auto min-w-1/2"
                    :class="{
                        'w-full h-auto': fullScreen,
                        [sizeClasses]: true
                    }"
                >
                    <div
                        v-if="header"
                        class="flex justify-between bg-gray-200 p-3 border-gray-300 border-b sticky top-0 z-10"
                    >
                        <h2 class="text-md text-gray-800">{{ header }}</h2>
                        <button class="px-2 m-0" aria-label="Close Modal" @click="close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div :class="{'p-6' : !noPad}">
                        <slot name="content"></slot>
                    </div>
                    <div v-if="$slots['modal-actions']" class="text-right p-3 border-t sticky bottom-0 bg-white">
                        <slot name="modal-actions"></slot>
                    </div>
                </div>
            </div>
        </transition>
    </portal>
</template>

<script>
import { mixin as clickAway } from '@outreach/mixins/clickAway';

const SIZES = {
    sm: 'w-full max-w-sm',
    md: 'w-full max-w-md',
    lg: 'w-full max-w-lg',
    xl: 'w-full max-w-xl'
};

export default {
    mixins: [clickAway],
    props: {
        open: { type: null, default: false },
        header: { type: String, default: null },
        fullScreen: { type: Boolean, default: false },
        noPad: { type: Boolean, default: false },
        disableOnClickAway: { type: Boolean, default: false },
        size: { type: String, default: null }
    },
    computed: {
        sizeClasses () {
            return SIZES[this.size] || '';
        }
    },
    watch: {
        open (open) {
            const mainElement = document.querySelector('#main');
            if (!mainElement) {
                return;
            }
            mainElement.classList[open ? 'add' : 'remove']('overflow-hidden');
        }
    },
    mounted () {
        document.addEventListener('keyup', this.escape);
    },
    beforeDestroy () {
        document.removeEventListener('keyup', this.escape);
    },
    methods: {
        close () {
            if (this.open) {
                this.$emit('close-modal');
            }
        },
        onClickAwayClose () {
            if (!this.disableOnClickAway) {
                this.close();
            }
        },
        escape (event) {
            if (event.key === 'Escape') {
                this.close();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal,
.modal__panel {
    transition: all 300ms ease-in-out;
}

.modal {
    @apply items-center flex h-screen w-screen fixed justify-center z-50 inset-y-0 inset-x-0 md:py-16 md:px-8;
    background: rgba(0, 0, 0, 0.6);
}

.modal-leave-active,
.modal-enter {
    opacity: 0;

    .modal__panel {
        transform: scale(0.95) translateY(25px);
    }
}
</style>
