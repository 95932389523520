"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MockInterceptor = void 0;
const api_mocks_1 = require("@services/api-mocks");
class MockErrorResponse extends Error {
    constructor(config) {
        super();
        this.config = config;
    }
}
class MockInterceptor {
    requestFulfilled(config) {
        var _a;
        if (this.getMockedData(config.url, (_a = config.method) !== null && _a !== void 0 ? _a : 'get')) {
            console.log(`[MOCK] Mocking url "${config.method}: ${config.url}"`);
            return Promise.reject(new MockErrorResponse(config));
        }
        return config;
    }
    requestFailed(error) {
        return Promise.reject(error);
    }
    responseFulfilled(response) {
        return response;
    }
    responseFailed(error) {
        var _a, _b;
        if (MockInterceptor.isMockedError(error)) {
            const config = error.config;
            const mock = this.getMockedData(config.url, (_a = config.method) !== null && _a !== void 0 ? _a : 'get');
            return Promise.resolve({
                data: mock === null || mock === void 0 ? void 0 : mock.response,
                status: (_b = mock === null || mock === void 0 ? void 0 : mock.status) !== null && _b !== void 0 ? _b : 200,
                statusText: 'OK',
                headers: {},
                config: error.config,
                isMock: true
            });
        }
        return Promise.reject(error);
    }
    getMockedData(url, method) {
        return api_mocks_1.mockedRequests.find((item) => (url === null || url === void 0 ? void 0 : url.includes(item.endpoint)) && item.method === method.toLowerCase());
    }
    static isMockedError(error) {
        return error.constructor.name === MockErrorResponse.name && error.config != null;
    }
}
exports.MockInterceptor = MockInterceptor;
