<template>
    <div class="label">
        <slot></slot>
        <span
            class="label-text -translate-t-50 top-1/2"
            :class="{
                'label-readonly': readonly,
                'ml-3': !readonly,
                'label-focused': focused && !readonly,
                'opacity-50': disabled,
                'round-sm scale-3/4 transform-origin-left-center top-[-9px]': isFilled,
            }"
        >
            {{ text }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'VLabel',
    props: {
        text: { type: String, required: true },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        focused: { type: Boolean, default: false }
    },
    computed: {
        isFilled () {
            return this.readonly || this.focused;
        }
    }
};
</script>

<style scoped>
@tailwind variants;

.label {
    @apply flex flex-col relative block text-gray-600;
}

.label-text {
    @apply uppercase text-sm pointer-events-none absolute tracking-wider truncate transition-all;
}

.label-readonly {
    @apply bg-transparent text-gray-700 px-3 text-xs ml-0;
}

.label-focused {
    @apply bg-white text-gray-700 px-2 text-xs;
}
</style>
