const ActionSubscriber = (store) => {
    store.subscribeAction({
        after: (action, state) => {
            Object.values(state).map(module => module.events || []).forEach(events => {
                const actions = events[action.type] || [];
                actions.forEach(action => {
                    if (!store._actions[action]) {
                        throw new Error(`The subscribed action ${action} does not exist`);
                    }
                    store.dispatch(action);
                });
            });
        }
    });
};

export default ActionSubscriber;
