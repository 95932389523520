<template>
    <img
        :src="url"
        :alt="contact.name"
        :width="size"
        :height="size"
        class="object-cover w-full h-full"
    />
</template>

<script>
const md5 = require('md5');

export default {
    props: {
        contact: {
            type: Object,
            required: true
        },
        size: {
            type: Number,
            required: false,
            default: 100
        },
        protocol: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        gravatarUrl () {
            const protocol = this.protocol.endsWith(':') ? this.protocol : `${this.protocol}:`;

            return [
                `${protocol === ':' ? '' : protocol}//www.gravatar.com/avatar/`,
                md5(this.email.trim().toLowerCase()),
                `?s=${this.size}&d=mm&r=g`
            ].join('');
        },
        url () {
            return this.contact.avatar ? this.contact.avatar : this.gravatarUrl;
        },
        email () {
            return this.contact.email ? this.contact.email : '';
        }
    }
};
</script>
