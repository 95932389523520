
<template>
    <button
        :id="`${value}-trigger`"
        class="group tab-button"
        :class="[ active ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700' ]"
        @click="$emit('click', value)"
    >
        <span>{{ label }}</span>
        <span
            v-if="subtext"
            class="label"
            :class="[ active ? 'bg-brand-regular text-white' : 'bg-gray-100 text-gray-900' ]"
        >
            {{ subtext }}
        </span>
        <span
            class="absolute inset-x-0 bottom-0 h-0.5"
            aria-hidden="true"
            :class="[ active ? 'bg-brand-regular' : 'bg-transparent' ]"
        ></span>
    </button>
</template>

<script>
export default {
    name: 'TabButton',
    props: {
        label: { type: String, required: true },
        value: { type: String, required: true },
        subtext: { type: String, default: null },
        active: { type: Boolean, required: true }
    }
};
</script>

<style scoped lang="scss">

.tab-button {
    @apply relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 first:rounded-l-lg last:rounded-r-lg;
}

.tab-button .label {
    @apply hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block;
}

</style>
