<template>
    <div>
        <v-panel title="Filters">
            <v-input v-model="query.q" label="Search" />
            <div class="flex gap-2 mt-3">
                <div class="grid grid-cols-2 flex-1 gap-2">
                    <fieldset class="flex flex-col gap-2">
                        <legend class="text-gray-700 font-medium mb-2">Date</legend>
                        <v-input
                            id="submitted-after"
                            ref="start-date-input"
                            v-model="query.start_date"
                            type="date"
                            class="mb-2 mr-0 sm:mr-2"
                            placeholder=""
                            label="Submitted After"
                            :errors="formErrors.start_date"
                            @input="query.start_date = $event"
                        />
                        <v-input
                            id="submitted-before"
                            v-model="query.end_date"
                            type="date"
                            class="mb-2 mr-0 sm:mr-2"
                            placeholder=""
                            label="Submitted Before"
                            :errors="formErrors.end_date"
                            @input="query.end_date = $event"
                        />
                    </fieldset>
                    <fieldset class="flex flex-col gap-2">
                        <legend class="text-gray-700 font-medium mb-2">Hour</legend>
                        <v-input
                            id="submitted-after"
                            ref="start-hour-input"
                            v-model="query.start_hour"
                            type="number"
                            class="mb-2 mr-0 sm:mr-2"
                            placeholder=""
                            label="Submitted After"
                            :min="0"
                            :max="23"
                            :step="1"
                            :errors="formErrors.start_hour"
                            @input="query.start_hour = $event"
                        />
                        <v-input
                            id="submitted-before"
                            v-model="query.end_hour"
                            type="number"
                            class="mb-2 mr-0 sm:mr-2"
                            placeholder=""
                            label="Submitted Before"
                            :min="0"
                            :max="23"
                            :step="1"
                            :errors="formErrors.end_hour"
                            @input="query.end_hour = $event"
                        />
                    </fieldset>
                </div>
                <div class="my-auto">
                    <div class="flex flex-col sm:flex-row items-center items-stretch justify-end sm:px-0 px-6 ">
                        Total: <span class="pill-box"> {{ formatNumber(meta.total || 0) }} </span>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="flex w-full justify-end">
                    <v-button
                        label="Apply"
                        class="ml-auto"
                        size="sm"
                        button-style="primary"
                        @click="getHourlies"
                    />
                </div>
            </template>
        </v-panel>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading report&hellip;</span>
            </div>
            <div v-else>
                <div v-if="hourlies.length > 0">
                    <v-table is-elevated :rows="hourlies">
                        <template #default="{ row }">
                            <v-table-column label="Hour" value="hour">
                                <div class="mt-2">
                                    <span><strong class="text-lg">{{ row[0].hour }}</strong></span>
                                </div>
                            </v-table-column>
                            <v-table-column label="Clients" value="clients">
                                <div
                                    v-for="hourly in row"
                                    :key="hourly.campaign_id"
                                    class="border-b-2"
                                >
                                    <span>{{ hourly.name }}</span>
                                    <span class="pill-box">{{ hourly.count }}</span>
                                    <span class="text-xs inline-block text-gray-700">
                                        {{ hourly.email }}
                                    </span>
                                </div>
                            </v-table-column>
                            <v-table-column label="Count" value="count" class="text-center">
                                <div class="text-xl font-normal">
                                    <div>{{ row.reduce((sum, hourly) => sum + hourly.count, 0) }}</div>
                                </div>
                            </v-table-column>
                        </template>
                    </v-table>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No hourlies found.</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import services from '@services/Api';
import VLoader from '@outreach/components/VLoader';
import { VTable, VTableColumn } from '@outreach/components/shared/table';
import { formatNumber } from '@services/Utils';
import VInput from '@outreach/components/inputs/VInput';
import VButton from '@outreach/components/VButton';
import VPanel from '@outreach/components/shared/VPanel';
import moment from 'moment';

const CURRENT_DAY = moment().format('YYYY-MM-DD');

export default {
    name: 'PrintCountReport',
    components: {
        VPanel,
        VLoader,
        VTable,
        VTableColumn,
        VInput,
        VButton
    },
    data: () => ({
        hourlies: [],
        meta: {},
        date: null,
        formErrors: {},
        query: {
            start_date: CURRENT_DAY,
            end_date: CURRENT_DAY,
            start_hour: 0,
            end_hour: 23
        }
    }),
    computed: {
        ...mapGetters({
            loading: 'loader/isLoading'
        })
    },
    watch: {
        'query.start_date' () {
            if (this.query.start_date > this.query.end_date || !this.query.end_date) {
                this.query.end_date = this.query.start_date;
            }
        },
        'query.start_hour' () {
            if (this.query.start_hour >= this.query.end_hour) {
                this.query.end_hour = Math.min(23, parseInt(this.query.start_hour) + 1);
            }
        }
    },
    async created () {
        await this.getHourlies();
    },
    methods: {
        ...mapActions({
            setMessage: 'alerts/setMessage',
            clearMessages: 'alerts/clearAll'
        }),
        async getHourlies () {
            this.formErrors = {};
            this.clearMessages();
            try {
                const { data } = await services.admin.printCount(this.query);
                this.hourlies = data.data;
                this.meta = data.meta;
            } catch (error) {
                if (((error.response || {}).data || {}).errors) {
                    this.formErrors = error.response.data.errors;
                }
                this.setMessage({ messageType: 'error', message: 'Unable to load report. Please try again!' });
            }
        },
        formatNumber
    }
};
</script>

<style scoped>
.list {
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.pill-box {
    @apply text-sm px-2 py-1 rounded mr-2 bg-gray-200;
}
</style>
