import { geoCenterOfUS, googleMapStyles } from '@global/config';
import { GooglePolygon } from '@outreach/pages/OutboundProspecting/GooglePolygon';
import { GoogleMarkerBuilder } from '@outreach/pages/OutboundProspecting/GoogleMarkerBuilder';
import { FreeDrawMode } from '@outreach/pages/OutboundProspecting/FreeDrawMode';
import { DrawType } from '@definitions/MapTypes';
import { PointDrawMode } from '@outreach/pages/OutboundProspecting/PointDrawMode';

export class GoogleService {
    #map = undefined;
    #polygon = undefined;
    #centerMarker = undefined;
    #drawMode;

    createMap (element, options = {}) {
        this.#map = new google.maps.Map(element,
            {
                zoom: 4,
                mapTypeControl: false,
                streetViewControl: false,
                center: geoCenterOfUS,
                styles: googleMapStyles,
                ...options
            }
        );
        this.#drawMode = new FreeDrawMode(this.#map).onComplete(() => this.removePolygon());
    }

    createPolygon (path) {
        this.#polygon = GooglePolygon.fromPath(path, this.#map);
        return this.#polygon;
    }

    createCenterMarker () {
        this.#centerMarker = new GoogleMarkerBuilder(this.#map)
            .draggable()
            .icon({ scale: 5, fillColor: '#FFFFFF' })
            .build();
    }

    createAutocomplete (input, onPlaceChanged) {
        const autocomplete = new google.maps.places.Autocomplete(input, {
            bounds: {
                north: geoCenterOfUS.lat + 0.1,
                south: geoCenterOfUS.lat - 0.1,
                east: geoCenterOfUS.lng + 0.1,
                west: geoCenterOfUS.lng - 0.1
            },
            componentRestrictions: { country: 'us' },
            fields: ['formatted_address', 'geometry', 'place_id', 'address_components'],
            strictBounds: false
        });

        autocomplete.addListener('place_changed', onPlaceChanged);

        return autocomplete;
    }

    get centerMarker () {
        return this.#centerMarker;
    }

    get map () {
        return this.#map;
    }

    get polygon () {
        return this.#polygon;
    }

    zoomMap () {
        const bounds = new google.maps.LatLngBounds();
        this.polygon.getPath().forEach((point) => bounds.extend(point));
        this.map.fitBounds(bounds);
    }

    removePolygon () {
        if (!this.#polygon) return;

        this.clearCluster();
        this.#polygon.setMap(null);
    }

    async searchPlace (query, onResults, element) {
        const request = { fields: ['formatted_address', 'name', 'geometry', 'place_id'], query };
        const service = new google.maps.places.PlacesService(element);
        await service.findPlaceFromQuery(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                onResults(results);
            }
        });
    }

    async getPlace (placeId, element) {
        const service = new google.maps.places.PlacesService(element);
        const request = { fields: ['formatted_address', 'geometry', 'place_id', 'address_components'], placeId };
        return new Promise(resolve => {
            service.getDetails(request, async (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                    resolve(results);
                }
            });
        });
    }

    polygonPath (center, radius, points) {
        const path = [];
        const p = 360 / points;
        let d = 0;

        for (let i = 0; i < points; ++i, d += p) {
            path.push(google.maps.geometry.spherical.computeOffset(center, radius, d));
        }

        return path;
    }

    getPolygonCenter () {
        const bounds = new google.maps.LatLngBounds();
        this.polygon.getPath().forEach((point) => bounds.extend(point));

        return bounds.getCenter();
    }

    rebuildCluster (estates) {
        this.clearCluster();
        this.#polygon.cluster.build(estates);
    }

    clearCluster () {
        this.#polygon.cluster.clear();
    }

    enableDrawing (onPolygonComplete) {
        this.#drawMode.enable((polygon) => {
            this.#polygon = polygon;
            onPolygonComplete(this.#polygon);
        });
    }

    disableDrawing () {
        this.#drawMode.disable();
    }

    switchDrawMode (type) {
        this.disableDrawing();
        switch (type) {
            case DrawType.FREE_DRAW:
                this.#drawMode = new FreeDrawMode(this.#map).onComplete(() => this.removePolygon());
                break;
            default:
                this.#drawMode = new PointDrawMode(this.#map).onComplete(() => this.removePolygon());
        }
    }
}
