import ApiClient from '@services/Http/Api';

export const list = (accountId = null, params = {}) => {
    return accountId
        ? ApiClient.get(`/dashboard/accounts/${accountId}/images/assets`, { params })
        : ApiClient.get('/dashboard/admin/assets', { params });
};

export const create = (accountId, data) => {
    return accountId
        ? ApiClient.upload(`/dashboard/accounts/${accountId}/images/assets`, data)
        : ApiClient.upload('/dashboard/admin/assets/', data);
};

export const update = (accountId, assetId, data) => {
    return accountId
        ? ApiClient.patch(`/dashboard/accounts/${accountId}/images/assets/${assetId}`, data)
        : ApiClient.patch(`/dashboard/admin/assets/${assetId}`, data);
};

export const addToFolder = (accountId, params = {}) => {
    return ApiClient.put(`/dashboard/accounts/${accountId}/images/assets/tags`, params);
};

export const removeFromFolder = (accountId, params = {}) => {
    return ApiClient.destroy(`/dashboard/accounts/${accountId}/images/assets/tags`, params);
};

export const destroy = (accountId, assetId) => {
    return accountId
        ? ApiClient.destroy(`/dashboard/accounts/${accountId}/images/assets/${assetId}`)
        : ApiClient.destroy(`/dashboard/admin/assets/${assetId}`);
};

export const restore = (accountId, assetId) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/images/assets/${assetId}/restore`);
};
