<template>
    <div class="w-full">
        <div class="p-8 container mx-auto">
            <div class="flex justify-between items-center mb-6">
                <h3
                    class="text-2xl text-gray-800 font-semibold"
                >{{ collateralId ? 'Edit collateral' : 'Add collateral' }}</h3>
                <v-button ref="submit-button" label="Save" button-style="primary" @click="submit" />
            </div>
            <div v-if="collateral" class="bg-white p-8">
                <v-input
                    v-model="collateral.name"
                    class="mb-4"
                    label="Name"
                    :errors="formErrors.name"
                />
                <div class="flex flex-row mb-4 -mx-2">
                    <account-select
                        v-model="collateral.accounts"
                        class="flex-1 mx-2"
                        :errors="formErrors.account_ids"
                        :multiple="true"
                    />
                    <v-input
                        v-model="collateral.quantity"
                        class="flex-1 mx-2"
                        label="Quantity"
                        type="number"
                        :errors="formErrors.quantity"
                    />
                </div>
                <v-textarea
                    v-model="collateral.description"
                    class="mb-4"
                    label="Description"
                    :errors="formErrors.description"
                />
                <div class="flex flex-row mb-4 -mx-2">
                    <vue-dropzone
                        ref="upload-input"
                        class="flex-1 mx-2 mb-4 h-full"
                        label="Upload image"
                        :accepted="imageTypes"
                        disable-on-click-away
                        @add-file="addImage($event)"
                    />
                    <div
                        ref="print"
                        class="qr-code mx-2 border border-gray-400 rounded overflow-hidden cursor-pointer"
                        @click="print"
                        v-html="collateral.qr_code"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDropzone from '@outreach/components/shared/dropzone/VueDropzone';
import AccountSelect from '@outreach/components/account/AccountSelect';
import { imageTypes } from '@global/config';
import { mapActions, mapState } from 'vuex';
import VTextarea from '@outreach/components/inputs/VTextarea';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { VueDropzone, AccountSelect, VTextarea, VButton, VInput },
    data: () => ({
        collateral: {}
    }),
    computed: {
        ...mapState({
            existingCollateral: ({ collaterals }) => collaterals.record,
            formErrors: ({ collaterals }) => collaterals.formErrors
        }),
        imageTypes: () => imageTypes,
        collateralId () {
            return this.$route.params.collateral;
        }
    },
    async mounted () {
        await this.load();
    },
    methods: {
        ...mapActions({
            fetch: 'collaterals/fetch',
            update: 'collaterals/update',
            create: 'collaterals/create',
            setMessage: 'alerts/setMessage'
        }),
        async load () {
            if (!this.collateralId) return;
            await this.fetch(this.collateralId);
            this.collateral = { ...this.existingCollateral };
        },
        async submit () {
            if (this.collateralId) {
                await this.updateCollateral();
            } else {
                await this.createCollateral();
            }
        },
        async updateCollateral () {
            await this.update({ collateralId: this.collateralId, payload: this.collateral });
            this.$router.push({ name: 'collaterals' });
            this.setMessage({ messageType: 'success', message: 'Collateral updated' });
        },
        async createCollateral () {
            await this.create(this.collateral);
            this.$router.push({ name: 'collaterals' });
            this.setMessage({ messageType: 'success', message: 'Collateral created' });
        },
        print () {
            const tab = window.open('');
            tab.document.write(this.collateral.qr_code);
            tab.focus();
            tab.print();
            tab.close();
        },
        addImage (image) {
            this.collateral.image = image;
        }
    }
};
</script>
