<template>
    <base-widget :method="getNoteCount" :report-link="{ name: 'print-count-report' }">
        <template #content="{ data }">
            <p class="font-bold text-sm text-gray-700">{{ interval === 'day' ? 'Weekly' : 'Recent' }} Notes Sent</p>
            <p ref="notes-total" class="text-4xl">{{ formatNumber(data.total) }}</p>
            <line-graph :data="data.values" :labels="data.labels" />
        </template>
    </base-widget>
</template>

<script>
import { widgets } from '@services/Api/Admin';
import LineGraph from '@outreach/components/widgets/LineGraph';
import { formatNumber } from '@services/Utils';
import BaseWidget from '@outreach/components/widgets/BaseWidget';
import { mapActions } from 'vuex';
import WidgetPeriod from '@outreach/components/widgets/WidgetPeriod';
import JobWasCompleted from '@events/JobWasCompleted';
import throttle from 'lodash/throttle';
import { throttleTimeout } from '@global/config';

export default {
    name: 'NoteCountWidget',
    components: { BaseWidget, LineGraph },
    props: { interval: { type: String, default: 'days' } },
    computed: {
        period () {
            return new WidgetPeriod(this.interval);
        }
    },
    created () {
        this.subscribe({
            event: JobWasCompleted,
            subscription: this.throttleProcess
        });
    },
    destroyed () {
        this.unsubscribe({
            event: JobWasCompleted,
            subscription: this.throttleProcess
        });
    },
    methods: {
        ...mapActions({
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        formatNumber,
        throttleProcess: throttle(async function () {
            await this.getNoteCount();
        }, throttleTimeout),
        async getNoteCount () {
            return widgets.noteCount(this.period.getRange());
        }
    }
};

</script>
