<template>
    <transition name="fade" mode="out-in">
        <div>
            <search-header>
                <template #content>
                    <v-input
                        ref="search-input"
                        v-model="query"
                        class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1"
                        label="Search"
                        @input="search"
                    />
                </template>
            </search-header>
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading agent data</span>
            </div>
            <div v-if="agents.length > 0 || loading">
                <div v-for="agent in agents" :key="agent.id">
                    <div class="bg-white flex rounded mb-4 p-2 text-gray-700">

                        <div class="w-48 max-h-60 overflow-y-hidden">
                            <img v-if="agent.photo" :src="agent.photo" :alt="`Photo of ${agent.name}`" class="w-full" />
                            <div v-else class="w-48 bg-gray-200">
                                <avatar :contact="{ name: agent.name, email: agent.email }" />
                            </div>
                        </div>

                        <div class="flex flex-col gap-2 ml-2">
                            <div class="flex">
                                <p class="font-bold mb-2">{{ agent.name }}</p>
                                <router-link
                                    :to="{ name: 'update-agent', params: { agent: agent.id }}"
                                >
                                    <i class="fa fa-pencil ml-2"></i>
                                </router-link>
                            </div>
                            <div class="flex gap-2">
                                <div class="flex-col">
                                    <p><span class="bg-brand-light p-1 rounded">
                                        {{ `Rating: ${agent.rating}/5 ` }}
                                    </span></p>
                                    <p class="text-xs p-1">{{ agent.reviews }} reviews</p>
                                </div>
                                <p>{{ agent.company }}</p>
                            </div>
                            <div class="flex-col gap-2">
                                <p class="text-sm">Mobile: {{ agent.phone.mobile }}</p>
                                <p class="text-sm">Office: {{ agent.phone.office }}</p>
                                <p class="text-sm"> {{ agent.email }} </p>
                            </div>
                        </div>
                        <button
                            :ref="`delete-confirm-${agent.id}`"
                            class="ml-auto mr-6"
                            @click="deleteAgent(agent)"
                        >
                            <i class="fa fa-trash text-xl"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="text-center">
                    <p class="text-lg text-gray-700">No agents found.</p>
                </div>
            </div>
            <div class="flex items-center justify-center py-8 px-2">
                <v-paginator
                    class="flex-none"
                    :total="pagination.lastPage"
                    :value="pagination.currentPage"
                    @input="getAgents({ q: query, page: $event })"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import VLoader from '@outreach/components/VLoader.vue';
import services from '@services/Api';
import SearchHeader from '@outreach/components/shared/SearchHeader.vue';
import VInput from '@outreach/components/inputs/VInput.vue';
import debounce from 'lodash/debounce';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { Alert } from '@services/Alert';
import VPaginator from '@outreach/components/VPaginator.vue';
import { LengthAwarePaginator } from '@services/LengthAwarePaginator';
import Avatar from '@outreach/components/shared/avatar/index.vue';

export default {
    name: 'Agents',
    components: {
        VPaginator,
        VLoader,
        VInput,
        SearchHeader,
        Avatar
    },
    data: () => ({
        loading: undefined,
        agents: [],
        query: null,
        pagination: new LengthAwarePaginator()
    }),
    created () {
        this.getAgents();
    },
    methods: {
        search: debounce(function (q) {
            this.getAgents({ q });
        }, 500),
        async getAgents (params = {}) {
            this.loading = true;
            try {
                const { data } = await services.admin.agents.listAgents({
                    ...params
                });
                this.agents = data.data;
                this.pagination = new LengthAwarePaginator(data.meta);
            } catch (e) {
                await Alert.error('An error occurred getting agents. Please try again');
            }
            this.loading = false;
        },
        async deleteAgent (agent) {
            await dialogEmitter
                .name('Confirm')
                .message(`Are you sure you want to delete ${agent.name}?`)
                .isDestructive()
                .onConfirmed(async () => {
                    try {
                        this.loading = true;
                        await services.admin.agents.deleteAgent(agent);
                    } catch (error) {
                        await Alert.error('This Agent could not be deleted.');
                    }
                    this.loading = false;

                    await this.getAgents();
                })
                .confirm();
        }
    }
};
</script>
