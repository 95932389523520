<template>
    <div>

        <button
            v-tooltip="tooltip"
            class="button"
            :class="{'onActive' : active }"
            @click="$emit('click')"
        >
            <i :class="icon"></i>
            <span v-if="title" class="uppercase font-bold leading-3">{{ title }}</span>
        </button>
    </div>
</template>
<script>
export default {
    name: 'ActionBarButton',
    props: {
        active: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'fa-solid fa-code'
        },
        tooltip: {
            type: String,
            default: 'Select an Item'
        }
    }
};
</script>
<style lang="scss" scoped>
.button {
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  @apply flex gap-2 justify-center items-end px-4 py-3 text-gray-800 bg-gray-300 border border-gray-300;
  @apply rounded shadow focus:shadow-outline text-sm;

  &:hover {
    @apply opacity-90;
  }
}

</style>
