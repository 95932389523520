<template>
    <div v-if="tags.length > 0">
        <span
            v-for="tag in tags"
            :key="tag.id"
            class="inline-block bg-brand-light px-2 my-1 mr-1 rounded-lg text-white text-sm"
        >
            {{ tag.name }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'DisplayTags',
    props: {
        tags: { type: Array, default: () => [] }
    }
};
</script>
