<template>
    <authentication-layout>
        <h2 class="text-gray-800 text-xl mb-5">Set Password</h2>
        <form class="block" @submit.prevent="setPassword">
            <v-input
                class="mb-6"
                label="Email"
                type="email"
                :value="$route.query.email"
                disabled
            />
            <v-input
                v-model="password"
                class="mb-8"
                label="password"
                type="password"
                :errors="formErrors.password"
            />
            <v-input
                v-model="passwordConfirmation"
                class="mb-8"
                label="password confirmation"
                type="password"
                :errors="formErrors.password_confirmation"
            />
            <v-button
                class="w-full text-left flex items-center"
                label="Set Password"
                button-style="success"
                :loading="loading"
            >
                <template slot="after-label">
                    <i class="fa fa-chevron-right ml-auto"></i>
                </template>
            </v-button>
        </form>
    </authentication-layout>
</template>

<script>
import AuthenticationLayout from '@outreach/components/layouts/Authentication';
import services from '@services/Api';
import get from 'lodash/get';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { AuthenticationLayout, VButton, VInput },
    data: () => ({
        password: '',
        passwordConfirmation: '',
        loading: false,
        formErrors: {}
    }),
    methods: {
        async setPassword () {
            this.loading = true;
            this.formErrors = {};

            try {
                const data = {
                    email: this.$route.query.email,
                    token: this.$route.query.token,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation
                };
                await services.authentication.resetPassword(data);
                this.$router.push({ name: 'login' });
                return;
            } catch (e) {
                const { response } = e;
                this.formErrors = get(response, 'data.errors', {});
            }

            this.loading = false;
        }
    }
};
</script>
