import axios from 'axios';
import { Interceptors } from './Interceptors';
import { formDataBuilder } from '@services/FormDataBuilder';

export class Api {
    constructor (client) {
        const config = {
            baseURL: process.env.API_URL
        };
        this.client = client || axios.create(config);
        // eslint-disable-next-line no-new
        new Interceptors(this.client);
    }

    async makeRequest (method, location, data, options, errorCallback) {
        try {
            const config = { data, ...options };
            const params = {
                method,
                url: location,
                ...config
            };
            return await this.client(params);
        } catch (e) {
            console.log(e);
            if (errorCallback) {
                errorCallback(e);
            }

            throw e;
        }
    }

    async get (location, options, errorCallback) {
        return await this.makeRequest('GET', location, {}, options, errorCallback);
    }

    async post (location, data, options, errorCallback) {
        return await this.makeRequest('POST', location, data, options, errorCallback);
    }

    async put (location, data, options, errorCallback) {
        return await this.makeRequest('PUT', location, data, options, errorCallback);
    }

    async patch (location, data, options, errorCallback) {
        return await this.makeRequest('PATCH', location, data, options, errorCallback);
    }

    async destroy (location, data, options, errorCallback) {
        return await this.makeRequest('DELETE', location, data, options, errorCallback);
    }

    async upload (location, data, options = {}, errorCallback) {
        options.headers = { ...options.headers, ...{ 'Content-Type': 'multipart/form-data' } };
        return await this.post(location, formDataBuilder(data), options, errorCallback);
    }

    patchUpload (location, data, options = {}, errorCallback) {
        data._method = 'patch';
        return this.upload(location, data, options, errorCallback);
    }
}

const ApiClient = new Api();
export default ApiClient;
