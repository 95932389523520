import ApiClient from '@services/Http/Api';

export const list = (params = {}) => {
    return ApiClient.get('/dashboard/campaigns', { params });
};

export const submitted = (params = {}) => {
    return ApiClient.get('/dashboard/campaigns/submitted', { params });
};

export const fetch = (campaignId, includes = []) => {
    const params = {};

    if (includes.length) {
        params.include = includes.join(',');
    }

    return ApiClient.get(`/dashboard/campaigns/${campaignId}`, { params });
};

export const create = (campaign) => {
    return ApiClient.post('/dashboard/campaigns', campaign);
};

export const duplicate = async (campaignId) => {
    return ApiClient.post(`/dashboard/campaigns/${campaignId}/duplicate`);
};

export const update = (campaignId, campaign) => {
    return ApiClient.patch(`/dashboard/campaigns/${campaignId}`, campaign);
};

export const quickSend = (data) => {
    const formData = new FormData();
    formData.append('contact_id', data.contact_id);
    formData.append('template_id', data.template_id);
    formData.append('custom_message', data.custom_message);
    if (data.photo) {
        formData.append('photo', data.photo);
        formData.append('photo_name', data.photo.name);
    }
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
    };
    return ApiClient.post('/dashboard/campaigns/quick-send', formData, config);
};

export const submit = (campaignId, data) => {
    return ApiClient.post(`/dashboard/campaigns/${campaignId}/submit`, data);
};

export const destroy = (campaignId) => {
    return ApiClient.destroy(`/dashboard/campaigns/${campaignId}`);
};

export const batchDestroy = (campaignIds) => {
    return ApiClient.destroy('/dashboard/campaigns', { campaign_ids: campaignIds });
};

export const restore = (campaignId) => {
    return ApiClient.post(`/dashboard/campaigns/${campaignId}/restore`);
};

let previewController;

export const preview = (campaignId, contact_id = null) => {
    if (previewController != null) {
        previewController.abort();
    }
    previewController = new AbortController();
    const params = contact_id ? { contact_id } : {};
    return ApiClient.get(
        `/dashboard/campaigns/${campaignId}/preview`,
        { params, signal: previewController.signal }
    );
};

export const addPhoto = (campaignId, photo) => {
    return ApiClient.upload(`/dashboard/campaigns/${campaignId}/photo`, {
        photo,
        photo_name: photo.name
    });
};

export const attachContact = (campaignId, contactId) => {
    return ApiClient.post(`/dashboard/campaigns/${campaignId}/contacts/attach`, { contact_id: contactId });
};

export const sendSuggestion = (campaignId, userId) => {
    return ApiClient.post(`/dashboard/admin/campaigns/${campaignId}/suggest`, { userId });
};

export const fetchContacts = (campaignId, params = {}) => {
    return ApiClient.get(`/dashboard/campaigns/${campaignId}/contacts/add`, { params });
};

export const discardPostIt = (deliveryId) => {
    return ApiClient.destroy(`/dashboard/campaigns/deliveries/${deliveryId}/post-it`);
};

export const assignAssociates = (campaignId, payload) => {
    return ApiClient.post(`/dashboard/admin/campaigns/${campaignId}/associates`, payload);
};

export const comments = {
    list: (campaignId, params = {}) => ApiClient.get(
        `/dashboard/admin/queues/campaigns/${campaignId}/comments`,
        { params }
    ),
    create: (campaignId, payload) => ApiClient.post(
        `/dashboard/admin/queues/campaigns/${campaignId}/comments`,
        payload
    ),
    update: (campaignId, comment) => ApiClient.patch(
        `/dashboard/admin/queues/campaigns/${campaignId}/comments/${comment.id}`,
        comment
    ),
    destroy: (campaignId, commentId) => ApiClient.destroy(
        `/dashboard/admin/queues/campaigns/${campaignId}/comments/${commentId}`
    )
};
