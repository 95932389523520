<template>
    <div v-if="details" class="flex items-center">
        <i v-tooltip="details.label" class="fa text-gray-700" :class="details.icon" :aria-label="details.label"></i>
        <span v-if="withStatus" class="ml-3">{{ details.label }}</span>
    </div>
</template>

<script>

const statusList = {
    scheduled: {
        label: 'Scheduled', icon: 'fa-calendar-alt'
    },
    submitted: {
        label: 'Submitted', icon: 'fa-edit'
    },
    pending: {
        label: 'Pending', icon: 'fa-hourglass-start'
    },
    sent: {
        label: 'Sent', icon: 'fa-paper-plane'
    },
    printing: {
        label: 'Printing', icon: 'fa-print'
    }
};

export default {
    props: {
        status: {
            type: String,
            required: true
        },
        withStatus: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        details () {
            return statusList[this.status];
        }
    }
};
</script>
