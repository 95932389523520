<template>
    <div class="p-4 pt-6 bg-white shadow-md rounded flex flex-col">
        <img :src="serviceLogo" class="h-12 mx-auto mb-6" :alt="service.name" />

        <v-button
            label="Get Started"
            button-style="primary"
            @click="$emit('select-service', service)"
        />
    </div>
</template>

<script>
import VButton from '@outreach/components/VButton';

export default {
    components: { VButton },
    props: {
        service: { type: Object, required: true }
    },
    computed: {
        serviceLogo () {
            return `/svg/services/${this.service.name.toLowerCase()}-logo.svg`;
        }
    }
};
</script>
