import ApiClient from '@services/Http/Api';
import * as contacts from './Contacts';
import * as drivers from './Drivers';

export const list = (params = {}) => {
    return ApiClient.get('/dashboard/audiences', { params });
};

export const fetch = (audienceId) => {
    return ApiClient.get(`/dashboard/audiences/${audienceId}`);
};

export const update = (audienceId, audience) => {
    return ApiClient.patch(`/dashboard/audiences/${audienceId}`, audience);
};

export const create = (audience, config = {}) => {
    return ApiClient.post('/dashboard/audiences', audience, config);
};

export const detailsKeys = (audience) => {
    return ApiClient.get(`/dashboard/audiences/${audience}/contact-details`);
};

export const destroy = (audience) => {
    return ApiClient.destroy(`/dashboard/audiences/${audience}`);
};

export const restore = (audience) => {
    return ApiClient.post(`/dashboard/audiences/${audience}/restore`);
};

export {
    contacts,
    drivers
};
