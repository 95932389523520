import services from '@services/Api';

export const state = {
    addresses: [],
    meta: {}
};
export const actions = {
    async list ({ commit }, { account_id, params = { page: 1, q: null } }) {
        const { data } = await services.addresses.list(account_id, params);
        commit('setAddresses', data);
    },
    async delete ({}, { account_id, id }) {
        await services.addresses.destroy(account_id, id);
    }
};

export const mutations = {
    setAddresses: (state, { data, meta }) => {
        state.addresses = data;
        state.meta = meta;
    }
};

export default { namespaced: true, state, actions, mutations };
