<template>
    <campaign-compose
        v-if="campaign"
        ref="campaign-compose"
        :data.sync="data"
        :deliveries.sync="deliveries"
        :deliveries-loading="isLoading"
        :selected-delivery-id="selectedDeliveryId"
        :tags="tags"
        :covers="covers"
        :errors="errors"
        :total="deliveryMeta && deliveryMeta.total"
        :steps="steps"
        @next="next"
        @on-dirty="(dirty) => isDirty = dirty"
        @select-delivery="handleSelectDelivery"
        @update-layout-type="handleUpdateLayoutType"
        @fontUpdated="handleFontId"
        @update="handleUpdate"
        @update-and-continue="handleUpdateAndContinue"
        @update-all-with-refresh="handleUpdateAllWithRefresh"
        @update-all-and-continue="handleUpdateAllAndContinue"
        @get-more-deliveries="getDeliveries(deliveryMeta.nextPage)"
        @set-delivery-query="handleDeliveriesQueryUpdate"
        @delete-contact="handleDeleteContact"
        @get-new-contacts="refreshDeliveries"
    />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CampaignCompose from '@outreach/pages/CampaignCompose/CampaignCompose';
import services from '@services/Api';
import { debounce, get } from 'lodash';
import Vue from 'vue';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { LengthAwarePaginator } from '@services/LengthAwarePaginator';
import CampaignUpdated from '@events/CampaignUpdated';
import { TypeaheadOptions } from '@services/TypeaheadOptions';

export default {
    components: { CampaignCompose },
    props: {
        steps: { type: Array, required: false, default: () => ['Contacts', 'Compose', 'Review'] },
        reviewRoute: { type: String, required: false, default: 'campaign-review' }
    },
    data: () => ({
        data: {
            doodles: []
        },
        deliveries: [],
        deliveryMeta: null,
        deliveriesQuery: null,
        errors: {},
        selectedDeliveryId: null,
        previousPage: 0,
        isDirty: false,
        isUpdating: false
    }),
    computed: {
        ...mapState({
            campaign: ({ campaign }) => campaign.campaign,
            letter: ({ letters }) => letters.letter,
            user: ({ user }) => user.user,
            isLoading: ({ loader }) => loader.loading,
            covers: ({ accounts }) => accounts.covers
        }),
        campaignId () {
            return this.$route.params.campaign;
        },
        qrCodeUrl () {
            if (!this.data.resources || !this.data.resources.qr_code_url) {
                return null;
            }
            const url = this.data.resources.qr_code_url.trim();
            return url && !/^https?:\/\//i.test(url) ? `http://${url}` : url;
        },
        tags () {
            const typeaheadOptions = new TypeaheadOptions();
            return typeaheadOptions.merge({
                contact: [
                    ...typeaheadOptions.values.contact,
                    ...(get(this.user, 'account.custom_attributes', []) || [])
                ]
            });
        }
    },
    watch: {
        letter (value) {
            this.data = {
                ...this.data,
                layout_type: value.layout_type
            };
        },
        deliveries (newDeliveries, oldDeliveries) {
            if (oldDeliveries.length === 0 && newDeliveries.length > 0) {
                this.handleSelectDelivery(newDeliveries[0].id);
            }
        }
    },
    async beforeRouteLeave (to, __, next) {
        const options = [
            'Unsaved Changes',
            'Are you sure you would like to leave with unsaved changes?'
        ];

        if (
            to.name !== this.reviewRoute &&
            this.isDirty &&
            !await dialogEmitter.confirm(...options)
        ) return;

        next();
    },
    async created () {
        await this.getCampaign({ campaignId: this.campaignId });
        await Promise.all([
            this.refreshDeliveries(),
            this.getLetter(this.campaignId),
            this.getCovers({ accountId: this.campaign.account_id, params: { per_page: 50 } })
        ]);
        this.subscribe({
            event: CampaignUpdated,
            subscription: this.handleCampaignUpdated
        });
    },
    destroyed () {
        this.unsubscribe({
            event: CampaignUpdated,
            subscription: this.handleCampaignUpdated
        });
    },
    methods: {
        ...mapActions({
            getCampaign: 'campaign/getCampaign',
            getLetter: 'letters/get',
            createLetter: 'letters/create',
            updateLetter: 'letters/update',
            getCovers: 'accounts/getCovers',
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        handleCampaignUpdated (context, event) {
            if (parseInt(this.campaignId) !== event.id) {
                return;
            }

            this.getCampaign({ campaignId: this.campaignId });
        },
        async refreshDeliveries () {
            this.deliveries = [];
            this.previousPage = 0;
            this.deliveryMeta = null;
            this.selectedDeliveryId = null;
            await this.getDeliveries();
        },
        async getDeliveries (page = 1) {
            if (!page || page === this.previousPage) {
                return;
            }
            this.previousPage = page;
            try {
                const { data } = await services.deliveries.list(this.campaignId, {
                    page,
                    q: this.deliveriesQuery
                });
                this.deliveryMeta = new LengthAwarePaginator(data.meta);
                this.deliveries = [
                    ...this.deliveries,
                    ...data.data
                ];
            } catch (error) {
                this.handleErrors(error.response);
            }
        },
        next () {
            this.$router.push({
                name: this.reviewRoute,
                params: { campaign: this.campaignId }
            });
        },
        handleSelectDelivery (deliveryId) {
            this.selectedDeliveryId = deliveryId;
            const delivery = this.deliveries.find(delivery => delivery.id === deliveryId);

            this.data = {
                ...this.data,
                content: delivery.content,
                photo_path: delivery.photo_path,
                doodles: delivery.doodles,
                post_it: delivery.post_it,
                resources: {
                    qr_code_url: get(delivery, 'resources.qr_code_url')
                }
            };
        },
        handleDeliveriesQueryUpdate: debounce(function (searchTerm) {
            this.deliveriesQuery = searchTerm;
            this.refreshDeliveries();
        }, 350),
        async handleUpdate () {
            if (this.isUpdating) return;
            this.isUpdating = true;
            try {
                const { data } = await services.deliveries.update(this.campaign.id, this.selectedDeliveryId, {
                    post_it: this.data.post_it,
                    content: this.data.content,
                    doodles: this.data.doodles,
                    qr_code_url: this.qrCodeUrl,
                    photo_path: this.data.photo_path
                });
                const deliveryIndex = this.deliveries.findIndex(delivery => delivery.id === this.selectedDeliveryId);
                Vue.set(this.deliveries, deliveryIndex, data.data);

                this.$toasted.success('Your message to this contact has been updated.');
            } catch (error) {
                this.handleErrors(error.response);
            }
            this.isUpdating = false;
        },
        async handleUpdateAll (override) {
            if (this.isUpdating) return;
            this.isUpdating = true;
            try {
                await services.deliveries.updateAll(this.campaign.id, {
                    post_it: this.data.post_it,
                    content: this.data.content,
                    doodles: this.data.doodles,
                    qr_code_url: this.qrCodeUrl,
                    photo_path: this.data.photo_path,
                    override
                });
                this.$toasted.success('Your message has been updated.');
            } catch (error) {
                this.handleErrors(error.response);
            }
            this.isUpdating = false;
        },
        async handleUpdateAndContinue (...args) {
            await this.handleUpdate(...args);
            this.next();
        },
        async handleUpdateAllWithRefresh (...args) {
            await this.handleUpdateAll(...args);
            await this.refreshDeliveries();
        },
        async handleUpdateAllAndContinue (...args) {
            await this.handleUpdateAllWithRefresh(...args);
            this.next();
        },
        handleFontId (fontId) {
            if (!fontId) {
                return;
            }
            this.updateLetter({ id: this.letter.id, font_id: fontId });
        },
        async handleUpdateLayoutType (value) {
            this.data = {
                ...this.data,
                layout_type: value
            };
            this.updateLetter({
                ...this.letter,
                layout_type: value
            });
        },
        handleErrors (response) {
            if (response && response.data && response.data.errors) {
                this.errors = response.data.errors;
                window.scrollTo(0, 0);
            }
        },
        async handleDeleteContact (contact) {
            if (this.isLoading) return;

            const confirm = await dialogEmitter.confirm(
                `Delete ${contact.title}`,
                'Are you sure you want to delete this contact?'
            );

            if (!confirm) {
                return;
            }

            try {
                await services.deliveries.deleteDelivery(contact.id);
                this.$toasted.success('Your contact has been removed.');
                const deliveryIndex = this.deliveries.findIndex(delivery => delivery.id === contact.id);

                Vue.set(
                    this.deliveries,
                    deliveryIndex,
                    { ...this.deliveries[deliveryIndex], deleted_at: new Date() }
                );
                setTimeout(() => {
                    this.deliveries.splice(deliveryIndex, 1);
                    this.selectedDeliveryId = this.deliveries[0].id;
                }, 400);
            } catch (error) {
                this.handleErrors(error.response);
            }
        }
    }
};
</script>
