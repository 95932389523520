import AccountWrapper from '@outreach/pages/account/Index';
import ListTeams from '@outreach/pages/admin/teams/ListTeams';
import CreateTeam from '@outreach/pages/admin/teams/CreateTeam';
import ModifyTeam from '@outreach/pages/admin/teams/ModifyTeam';
import ViewTeam from '@outreach/pages/Teams/ViewTeam';

export default [
    {
        path: '/teams',
        component: AccountWrapper,
        children: [
            {
                path: '',
                name: 'teams',
                component: ListTeams,
                meta: {
                    title: 'Teams',
                    requiresAuth: true
                }
            },
            {
                path: 'add',
                name: 'add-team',
                component: CreateTeam,
                meta: {
                    title: 'Add Team',
                    requiresAuth: true,
                    breadcrumbs: [
                        { name: 'teams', label: 'Teams' },
                        { name: 'add-team', label: 'Add Team' }
                    ]
                }
            },
            {
                path: 'edit/:teamId',
                name: 'edit-team',
                component: ModifyTeam,
                meta: {
                    title: 'Edit Team',
                    requiresAuth: true,
                    breadcrumbs: [
                        { name: 'teams', label: 'Teams' },
                        { name: 'edit-team', label: 'Edit Team' }
                    ]
                }
            },
            {
                path: 'view',
                name: 'view-team',
                component: ViewTeam,
                meta: {
                    title: 'View Team',
                    requiresAuth: true,
                    breadcrumbs: [
                        { name: 'teams', label: 'Teams' },
                        { name: 'view-team', label: 'View Team' }
                    ]
                }
            }
        ]
    }
];
