<template>
    <div>
        <v-label
            v-if="label"
            :text="label"
            :focused="hasValue || isFocused"
            :readonly="readonly"
            :disabled="disabled"
        >
            <slot></slot>
        </v-label>
        <slot v-else></slot>
        <p v-for="(error, index) in errorMessages" :key="index" class="text-red-400 text-xs mb-2">{{ error }}</p>
        <p class="text-gray-500 text-xs" :class="{'hidden': !Boolean(help)}">{{ help }}</p>
    </div>
</template>
<script>
import VLabel from '@outreach/components/shared/VLabel.vue';

export default {
    components: { VLabel },
    props: {
        label: { type: String, default: undefined },
        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        errors: { type: [Array, String], required: false, default: () => [] },
        help: { type: String, default: undefined },
        hasValue: { type: Boolean, required: true },
        isFocused: { type: Boolean, required: true }
    },
    computed: {
        errorMessages () {
            return Array.isArray(this.errors) ? this.errors : [this.errors];
        }
    }
};
</script>
