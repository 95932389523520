import { VueRenderer } from '@tiptap/vue-2';
import { PluginKey } from 'prosemirror-state';
import { mergeAttributes, Node } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';
import tippy from 'tippy.js';
import InterpolationList from '@outreach/components/services/rich-text-plugins/InterpolationList';

export const InterpolationPluginKey = new PluginKey('interpolation');

export const Interpolation = Node.create({
    name: 'interpolation',

    addOptions () {
        return {
            HTMLAttributes: {},
            renderLabel ({ options, node }) {
                return `${options.suggestion.char}${node.attrs.label || node.attrs.id}}}`;
            },
            suggestion: {
                char: '{{',
                pluginKey: InterpolationPluginKey,
                command: ({ editor, range, props }) => {
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    const overrideSpace = nodeAfter && nodeAfter.text ? nodeAfter.text.startsWith(' ') : undefined;

                    if (overrideSpace) {
                        range.to += 1;
                    }

                    editor
                        .chain()
                        .focus()
                        .insertContentAt(range, [
                            {
                                type: this.name,
                                attrs: props
                            },
                            {
                                type: 'text',
                                text: ' '
                            }
                        ])
                        .run();
                },
                allow: ({ editor, range }) => {
                    const $from = editor.state.doc.resolve(range.from);
                    const type = editor.schema.nodes[this.name];
                    return !!$from.parent.type.contentMatch.matchType(type);
                },
                render: () => {
                    let component;
                    let popup;

                    return {
                        onStart: props => {
                            console.log(props, this);
                            component = new VueRenderer(InterpolationList, {
                                propsData: props
                            });
                            popup = tippy('body', {
                                getReferenceClientRect: props.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: 'manual',
                                placement: 'bottom-start'
                            });
                        },

                        onUpdate (props) {
                            component.updateProps(props);

                            popup[0].setProps({
                                getReferenceClientRect: props.clientRect
                            });
                        },

                        onKeyDown (props) {
                            if (props.event.key === 'Escape') {
                                popup[0].hide();

                                return true;
                            }

                            return component.ref.onKeyDown(props);
                        },

                        onExit () {
                            popup[0].destroy();
                            component.destroy();
                        }
                    };
                }
            }
        };
    },

    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,

    addAttributes () {
        return {
            id: {
                default: null,
                parseHTML: element => element.getAttribute('data-id'),
                renderHTML: attributes => {
                    if (!attributes.id) {
                        return {};
                    }

                    return {
                        'data-id': attributes.id
                    };
                }
            },

            label: {
                default: null,
                parseHTML: element => element.getAttribute('data-label'),
                renderHTML: attributes => {
                    if (!attributes.label) {
                        return {};
                    }

                    return {
                        'data-label': attributes.label
                    };
                }
            }
        };
    },

    parseHTML () {
        return [
            {
                tag: `span[data-type="${this.name}"]`
            }
        ];
    },

    renderHTML ({ node, HTMLAttributes }) {
        return [
            'span',
            mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
            this.options.renderLabel({
                options: this.options,
                node
            })
        ];
    },

    renderText ({ node }) {
        return this.options.renderLabel({
            options: this.options,
            node
        });
    },

    addKeyboardShortcuts () {
        return {
            Backspace: () => this.editor.commands.command(({ tr, state }) => {
                let isMention = false;
                const { selection } = state;
                const { empty, anchor } = selection;

                if (!empty) {
                    return false;
                }

                state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                    if (node.type.name === this.name) {
                        isMention = true;
                        tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize);

                        return false;
                    }
                });

                return isMention;
            })
        };
    },

    addProseMirrorPlugins () {
        return [
            Suggestion({ editor: this.editor, ...this.options.suggestion })
        ];
    }
});
