import services from '@services/Api';
import get from 'lodash/get';
import allSettled from 'promise.allsettled';

export const state = {
    campaign: null,
    collaterals: [],
    contact: null,
    deliveries: [],
    fonts: null,
    previews: null,
    previewVersion: 0,
    formErrors: {}
};

export const actions = {
    async preview ({ commit }, { campaignId, contactId = null }) {
        const response = await services.campaigns.preview(campaignId, contactId);
        commit('setPreview', get(response, 'data.data', { envelope: '', letter: '' }));
    },
    async load ({ commit, dispatch }, campaignId) {
        dispatch('preview', { campaignId });
        const campaign = await services.campaigns.fetch(campaignId);
        const [collaterals, fonts] = await allSettled([
            services.collaterals.listForAccount(campaign.data.data.account.id),
            services.fonts.list(),
            dispatch('addresses/list', { account_id: campaign.data.data.account.id }, { root: true }),
            dispatch('campaignDeliveries/refreshDeliveries', { campaignId: campaign.data.data.id }, { root: true })
        ]);

        commit('load', {
            campaign: get(campaign, 'data.data', {}),
            collaterals: get(collaterals, 'value.data.data', []),
            fonts: get(fonts, 'value.data.data', [])
        });
    },
    async updateFont ({ dispatch, commit, state }, font_id) {
        await services.letters.update(state.campaign.letter.id, { ...state.campaign.letter, font_id });
        const campaign = await services.campaigns.fetch(state.campaign.id);
        commit('setFont', campaign.data.data);
        dispatch('preview', { campaignId: state.campaign.id });
    },
    async updateCampaignField ({ dispatch, commit, state }, payload) {
        const campaign = await services.campaigns.update(state.campaign.id, payload);
        dispatch('preview', { campaignId: state.campaign.id });
        commit('setSenderAddress', campaign.data.data);
    },
    async updateSenderAddress ({ dispatch, commit, state }, addressId) {
        dispatch('updateCampaignField', { sender_address_id: parseInt(addressId, 10) });
    },
    async updateDeliveryOptions ({ commit, state }, { destination, collaterals }) {
        await services.campaigns.update(state.campaign.id, { destination, collaterals });
        const campaign = await services.campaigns.fetch(state.campaign.id);
        commit('setCampaign', campaign.data.data);
    },
    async selectContact ({ dispatch, commit, state }, contactId) {
        dispatch('preview', { campaignId: state.campaign.id, contactId });
        const { contact, ...delivery } = state.deliveries.find(delivery => delivery.contact_id === contactId);
        commit('setContact', { ...contact, ...{ delivery } });
    },
    async updatePhoto ({ commit, state }, photo) {
        await services.deliveries.updatePhoto(state.campaign.id, state.contact.delivery.id, photo);
        const campaign = await services.campaigns.fetch(state.campaign.id);
        commit('setPhoto', campaign.data.data);
    },
    async updatePhotoAll ({ commit, state }, photo) {
        await services.deliveries.updatePhotoAll(state.campaign.id, photo);
        const { data } = await services.campaigns.fetch(state.campaign.id);
        commit('setPhoto', data.data);
    },
    async submit ({ state, commit, dispatch }) {
        commit('setFormErrors', {});
        try {
            await services.campaigns.submit(state.campaign.id, { ...state.campaign });
        } catch (e) {
            if (e.response && e.response.data.errors) {
                commit('setFormErrors', e.response.data.errors);
            }
            throw e;
        }
    },
    async updateCampaign ({ state, commit }) {
        commit('setFormErrors', {});
        try {
            await services.campaigns.update(state.campaign.id, { name: state.campaign.name });
        } catch (e) {
            if (e.response && e.response.data.errors) {
                commit('setFormErrors', e.response.data.errors);
            }
            throw e;
        }
    },
    async duplicate ({}, campaignId) {
        const { data } = await services.campaigns.duplicate(campaignId);
        return data.data.id;
    },
    async reject ({ commit }, campaignId) {
        const campaign = await services.admin.rejectCampaign(campaignId);
        commit('setCampaign', campaign.data.data);
    },
    async discardPostIt ({ commit, dispatch }, delivery) {
        await services.campaigns.discardPostIt(delivery.id);
        await dispatch('preview', { campaignId: delivery.campaign_id });
    },
    clearPreview ({ commit }) {
        commit('setPreview', null);
    }
};

export const mutations = {
    load: (state, { campaign, collaterals, fonts }) => {
        state.campaign = campaign;
        state.collaterals = collaterals;
        const delivery = campaign.deliveries ? campaign.deliveries[0] : {};
        state.contact = { ...delivery.contact, ...{ delivery } };
        state.deliveries = campaign.deliveries;
        state.fonts = fonts.map(font => ({
            label: font.title,
            value: font.id
        }));
    },
    setSenderAddress: (state, campaign) => {
        state.campaign = campaign;
    },
    setCampaign: (state, campaign) => {
        state.campaign = campaign;
        state.deliveries = campaign.deliveries;
        const delivery = campaign.deliveries.find(delivery => delivery.contact_id === state.contact.id);
        state.contact = { ...delivery.contact, ...{ delivery } };
    },
    setFont: (state, campaign) => {
        state.campaign = campaign;
    },
    setContact: (state, contact) => {
        state.contact = contact;
    },
    setPhoto: (state, campaign) => {
        state.campaign = campaign;
        state.deliveries = campaign.deliveries;
        const { contact, ...delivery } = campaign.deliveries.find(delivery => delivery.contact_id === state.contact.id);
        state.contact = { ...contact, ...{ delivery } };
    },
    setPreview: (state, preview) => {
        state.previewVersion += 1;
        state.previews = preview;
    },
    setFormErrors: (state, errors) => {
        state.formErrors = errors;
    }
};

export default { namespaced: true, state, actions, mutations };
