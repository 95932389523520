<template>
    <section class="w-full">
        <portal to="header-actions">
            <router-link :to="{ name: 'add-article' }">
                <v-button label="Add New" button-style="primary" />
            </router-link>
        </portal>
        <search-header>
            <template #content>
                <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1" label="Search" @input="search" />
                <admin-tag-select
                    v-model="tags"
                    placeholder="Filter By Tag"
                    :taggable="false"
                    :type="tagType"
                    @input="adminList({ q: query, tags: tagIds })"
                />
            </template>
        </search-header>
        <div v-if="articles.length > 0" class="flex flex-col">
            <v-paginator
                class="self-center my-4 md:mt-0"
                :value="meta.current_page"
                :total="meta.last_page"
                @input="adminList({ page: $event, q: query, tags: tagIds })"
            />
            <div class="grid gap-4 lg:grid-cols-2">
                <div
                    v-for="article in articles"
                    :key="article.id"
                    class="relative bg-white rounded shadow overflow-hidden flex flex-col"
                >
                    <router-link :to="{ name: 'view-article', params: { articleId: article.id }}">
                        <img class="w-full bg-gray-500" :src="article.header_image" :alt="article.title" />
                        <div
                            v-if="article.media_url"
                            v-tooltip="'Video Article'"
                            class="absolute top-0 right-0 m-2 p-2 bg-gray-50 rounded-full shadow"
                        >
                            <i class="fas fa-2x fa-film mt-px"></i>
                        </div>
                        <div class="p-4">
                            <p class="text-xl font-bold">{{ article.title }}</p>
                            <time
                                :datetime="article.created_at"
                                class="font-light text-sm"
                            >{{ formatFullDate(article.created_at) }}</time>
                            <display-tags class="mt-1" :tags="article.tags" />
                            <p class="mt-4 font-light" v-html="article.summary"></p>
                        </div>
                    </router-link>

                    <div class="border-t flex divide-x divide-gray-200 mt-auto">
                        <router-link
                            :to="{ name: 'edit-article', params: { articleId: article.id }}"
                            class="article-link"
                        >
                            <i class="fa fa-pencil mr-2"></i>
                            <span>Edit</span>
                        </router-link>
                        <button
                            ref="delete-confirm"
                            class="article-link"
                            @click="deleteArticle(article)"
                        >
                            <i class="fa fa-trash mr-2"></i>
                            <span>Delete</span>
                        </button>
                    </div>
                </div>
            </div>
            <v-paginator
                class="self-center mb-44 mt-4 md:mb-0"
                :value="meta.current_page"
                :total="meta.last_page"
                @input="adminList({ page: $event, q: query, tags: tagIds })"
            />
        </div>
        <v-call-to-action
            v-else
            title="No Content"
            subtitle="Get started by creating new content."
            icon="newspaper"
        >
            <template #action>
                <router-link :to="{ name: 'add-article' }">
                    <v-button class="mt-4" label="Add New" button-style="primary" />
                </router-link>
            </template>
        </v-call-to-action>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import VCallToAction from '@outreach/components/shared/VCallToAction';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { formatFullDate } from '@services/Filters';
import VButton from '@outreach/components/VButton';
import VPaginator from '@outreach/components/VPaginator';
import VInput from '@outreach/components/inputs/VInput';
import DisplayTags from '@outreach/components/shared/DisplayTags';
import debounce from 'lodash/debounce';
import AdminTagSelect from '@outreach/components/tag-select/AdminTagSelect';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import { TagType } from '@services/TagType';

export default {
    name: 'AdminArticles',
    components: { SearchHeader, AdminTagSelect, DisplayTags, VPaginator, VCallToAction, VButton, VInput },
    data () {
        return {
            query: null,
            tags: []
        };
    },
    computed: {
        ...mapState({
            articles: ({ articles }) => articles.records,
            meta: ({ articles }) => articles.meta
        }),
        tagType () {
            return TagType.CONTENT;
        },
        tagIds () {
            return this.tags.map(tag => (tag.id));
        }
    },
    created () {
        this.adminList();
    },
    methods: {
        ...mapActions({
            adminList: 'articles/adminList',
            destroy: 'articles/destroy'
        }),
        search: debounce(function (q) {
            this.adminList({ q, tags: this.tagIds });
        }, 500),
        formatFullDate,
        async deleteArticle (article) {
            if (await dialogEmitter.confirm('Delete Article', `Are you sure you want to delete '${article.title}'?`)) {
                try {
                    await this.destroy(article.id);
                } catch (error) {
                    throw new ClientVisibleError('This article could not be deleted.');
                }
            }
        }
    }
};
</script>

<style scoped>
.article-link {
    @apply flex items-center justify-center py-4 text-sm text-gray-700 font-medium hover:text-gray-500 w-1/2;
}
</style>
