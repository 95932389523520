<template>
    <div
        ref="container"
        class="container mx-auto"
        :style="{ height }"
    >
        <transition name="fade" mode="out-in">
            <div class="w-full h-full">
                <import-contacts-modal
                    :show-modal="showImportModal"
                    @close-modal="showImportModal = false"
                    @csv-imported="csvImported"
                />
                <div class="h-full">
                    <div ref="container-element" class="h-full">
                        <virtual-list
                            class="h-full overflow-y-auto"
                            :data-key="'id'"
                            :data-sources="contacts || []"
                            :data-component="component"
                            @tobottom="handleNextPage"
                        >
                            <template #header>
                                <contact-search-bar
                                    :filter-count="filters.length"
                                    :show-filters="user.account.custom_attributes.includes('est_value')"
                                    :allow-export-button="!!isCustomerService() || hasPreference('can_export_contacts')"
                                    :allow-extra-actions="allowExtraActions"
                                    :campaigns="campaigns"
                                    :tags.sync="tags"
                                    :query.sync="query"
                                    :query-errors="formErrors.q"
                                    :allow-deleted-checkbox="!!isCustomerService()"
                                    :is-showing-archived.sync="archived"
                                    :selected-count="selected.size"
                                    :all-selected.sync="allSelected"
                                    :total="meta.total"
                                    :selected-campaign.sync="selectedCampaign"
                                    @campaign-search-change="getCampaigns"
                                    @delete="deleteDialog"
                                    @send="sendSelected"
                                    @add-tags="addTags"
                                    @restore="restoreMany"
                                    @export="exportAll"
                                    @import="showImportModal = true"
                                    @open-filters="openFilters"
                                    @add-contact="$router.push({ name: 'add-contact' })"
                                />

                                <div
                                    v-if="loading || !contacts"
                                    class="flex items-center justify-center flex-1 px-2 pt-4 md-pt-0"
                                >
                                    <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                                    <span class="text-sm text-gray-700 px-2 text-center">Loading contact data...</span>
                                </div>
                                <div
                                    v-else-if="contacts.length === 0"
                                    class="text-center"
                                >
                                    <p class="text-lg text-gray-700">We couldn't find any contacts matching your query.</p>
                                    <v-button
                                        button-style="info"
                                        label="Add Contact"
                                        class="mt-2"
                                        @click="$router.push({name: 'add-contact', params: { accountId }})"
                                    />
                                </div>
                            </template>
                            <template #item="{item}">
                                <card-wrapper
                                    :actions="item.deleted_at ? null : actions"
                                    :priority-action="item.deleted_at && isCustomerService() ? restoreAction : null"
                                    :faded="!!item.deleted_at"
                                    @action="handleAction($event, item.id)"
                                >
                                    <contact-card
                                        :contact="item"
                                        :allow-note-button="true"
                                        :checked="selected.has(item.id) || allSelected"
                                        :checkbox-disabled="allSelected"
                                        @select="handleSelected(item)"
                                    />
                                </card-wrapper>
                            </template>
                        </virtual-list>

                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import contacts from '@outreach/mixins/contacts';
import services from '@services/Api';
import ImportContactsModal from '@outreach/components/contacts/ImportContactsModal';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { get } from 'lodash';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VButton from '@outreach/components/VButton';
import VLoader from '@outreach/components/VLoader';
import { Alert } from '@services/Alert';
import VirtualList from 'vue-virtual-scroll-list';
import { getElementPosition } from '@services/Dom';
import ContactSearchBar from '@outreach/pages/ContactSearchBar.vue';
import CardWrapper from '@outreach/components/CardWrapper.vue';
import ContactCard from '@outreach/components/contacts/ContactCard.vue';
import RealEstateFilters from '@outreach/pages/OutboundProspecting/RealEstateFilters';
import CsvWasImported from '@events/CsvWasImported';

export default {
    components: {
        ContactCard,
        CardWrapper,
        ContactSearchBar,
        ImportContactsModal,
        VButton,
        VLoader,
        VirtualList
    },
    mixins: [contacts, hasPermission],
    data: function () {
        return {
            showImportModal: false,
            allSelected: false,
            expanded: false,
            archived: Boolean(this.$route.params.campaign),
            filters: {
                ...new RealEstateFilters().getFilters(),
                campaign_id: undefined
            },
            campaigns: [],
            selectedCampaign: undefined,
            component: () => null,
            height: '0'
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            loading: ({ loader }) => loader.loading
        }),
        accountId () {
            return this.$route.params.accountId || this.user.account_id;
        },
        allowExtraActions () {
            return this.$route.name !== 'campaign-contacts-index';
        },
        restoreAction () {
            return { name: 'restore', label: 'Restore' };
        },
        actions () {
            return [
                { name: 'view', label: 'View' },
                { name: 'edit', label: 'Edit' },
                { name: 'delete', label: 'Delete' },
                { name: 'send', label: 'Send mail' },
                { name: 'similar', label: 'Find surrounding households' }
            ];
        }
    },
    watch: {
        tags () {
            this.reloadContacts();
        },
        selectedCampaign () {
            this.filters.campaign_id = this.selectedCampaign?.id;
            this.reloadContacts();
        }
    },
    mounted () {
        this.updateHeight();
    },
    async created () {
        await this.getCampaigns();
        await this.subscribe({
            event: CsvWasImported,
            subscription: () => this.reloadContacts()
        });
    },
    destroy () {
        this.unsubscribe({ event: CsvWasImported });
    },
    methods: {
        ...mapActions({
            setMessage: 'alerts/setMessage',
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        handleAction (name, id) {
            if (!this[name]) {
                this.$toasted.error('Sorry, this action is not supported.');
                return;
            }
            this[name](id);
        },
        similar (id) {
            const contact = this.contacts.find(c => c.id === id);
            this.$router.push(contact.comparable_link);
        },
        view (id) {
            this.$router.push({ name: 'view-contact', params: { contact: id } });
        },
        edit (id) {
            this.$router.push({ name: 'edit-contact', params: { accountId: this.accountId, contact: id } });
        },
        async send (id) {
            await this.sendBatch([id]);
        },
        async sendBatch (ids) {
            try {
                const response = await services.campaigns.create(this.batchPayload(ids));
                this.$router.push({ name: 'campaign-compose', params: { campaign: response.data.data.id } });
            } catch (error) {
                const messages = get(error, 'response.data.errors', []);
                if (messages.hasOwnProperty('availability')) {
                    this.setMessage({ messageType: 'error', message: messages.availability });
                }
                this.$toasted.error('Sorry we were unable to send mail to the selected contacts. ' +
                    'Please refresh the page and try again.');
            }
        },
        sendSelected () {
            this.sendBatch([...this.selected]);
        },
        async deleteDialog () {
            await dialogEmitter
                .message(this.dialogText('delete'))
                .isDestructive()
                .onConfirmed(() => this.deleteSelected())
                .confirm('Delete Contact');
        },
        async deleteSelected () {
            try {
                await services.contacts.destroyMany(this.accountId, this.batchPayload([...this.selected]));
                this.$toasted.success('Contacts deleted');
                this.selected = new Set([]);
                this.contacts = this.allSelected
                    ? []
                    : this.contacts.filter(contact => ![...this.selected].includes(contact.id));
            } catch (error) {
                this.$toasted.error(
                    'Sorry we were unable to remove the selected contacts. Please refresh the page and try again.'
                );
            }
        },
        async restoreMany () {
            try {
                if (!await dialogEmitter.confirm('Restore Contact', this.dialogText('restore'))) {
                    return;
                }
                await services.contacts.restore(this.accountId, this.batchPayload([...this.selected]));
                this.$toasted.success('Contacts restored');
                await this.reloadContacts();
            } catch (e) {
                this.$toasted.error(
                    'Sorry we were unable to restore the selected contacts. Please refresh the page and try again.'
                );
            }
        },
        dialogText (action) {
            return `Are you sure you want to ${action} ${this.allSelected ? 'all' : this.selected.size} ${
                this.selected.size > 1 || this.allSelected ? 'contacts' : 'contact'}?`;
        },
        reloadContacts () {
            this.load(1, true);
        },
        async addTags () {
            dialogEmitter.openDialog('ContactsAddTagDialog', {
                contacts: this.allSelected ? new Set(['all']) : this.selected,
                callback: this.reloadContacts,
                filters: {
                    q: this.query,
                    tags: this.tags,
                    ...this.filters
                }
            });
        },
        async exportAll () {
            try {
                const { headers, data } = await services.contacts.exportAll(this.accountId, {
                    q: this.query,
                    tags: this.tags.map(item => item.id),
                    filters: this.filters
                });
                const blob = new Blob([data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', headers['content-disposition'].split('=')[1]);
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                await Alert.error('Could not export contacts, please try again.');
            }
        },
        async getCampaigns (q) {
            const { data } = await services.campaigns.list({ q, per_page: 50 });
            this.campaigns = data.data;
        },
        openFilters () {
            dialogEmitter
                .name('RealEstateFiltersDialog')
                .props({
                    filters: this.filters,
                    formErrors: this.formErrors,
                    allowable: this.user.account.custom_attributes
                })
                .addListener('submitted', () => this.reloadContacts())
                .wait();
        },
        handleSelected (contact) {
            if (this.allSelected) return;
            const values = new Set(this.selected);
            this.selected.has(contact.id)
                ? values.delete(contact.id)
                : values.add(contact.id);
            this.handleSelection(values);
        },
        updateHeight () {
            const { container } = this.$refs;

            const { top } = getElementPosition(container);

            const bottomOffset = container.parentElement
                ? getComputedStyle(container.parentElement).paddingBottom
                : '0';

            this.height = `calc(100vh - ${top}px - ${bottomOffset} - 1px)`;
        },
        batchPayload: function (ids) {
            return {
                ids: this.allSelected ? ['all'] : ids,
                filters: {
                    q: this.query,
                    tags: this.tags,
                    ...this.filters,
                    sort_key: this.sort.key,
                    sort_direction: this.sort.direction
                }
            };
        },
        csvImported () {
            this.showImportModal = false;
            Alert.info(
                'Your contacts are being imported, we\'ll notify you when they\'re ready! ' +
                'This might take a few minutes.'
            );
        }
    }
};
</script>
