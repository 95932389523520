import ApiClient from '@services/Http/Api';

export const list = async (accountId, params) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/links`, { params });
};

export const exportList = async (accountId, params = {}) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/links/export`, params);
};

export const campaignLinks = (accountId, params = {}) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/campaign-links`, { params });
};
