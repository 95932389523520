"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Roles = void 0;
var Roles;
(function (Roles) {
    Roles["SUPER_ADMIN"] = "super-admin";
    Roles["ADMIN"] = "admin";
    Roles["CUSTOMER_SERVICE"] = "customer-service";
    Roles["ACCOUNT_MANAGER"] = "account-manager";
    Roles["ACCOUNT_USER"] = "account-user";
    Roles["ACCOUNT_BILLING"] = "account-billing";
    Roles["ACCOUNT_SALES"] = "account-sales";
    Roles["ACCOUNT_MARKETING"] = "account-marketing";
})(Roles = exports.Roles || (exports.Roles = {}));
