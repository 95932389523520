import ClientVisibleError from '@exceptions/ClientVisibleError';

export const imageTypes = 'image/jpeg,image/jpg,image/png,image/heic,image/heif';

export const commentTextLimit = 130;

export const throttleTimeout = 300000;

export const IGNORED_ERRORS = [
    'NavigationDuplicated',
    ClientVisibleError.name
];

export const lastMailedLinkedinGracePeriod = 0;

export const lastMailedEmailGracePeriod = 0;

export const geoCenterOfUS = { lat: 37.90518784134848, lng: -99.76692239491015 };

export const brandColor = '#FE962D';

export const googleMapStyles = [
    { featureType: 'administrative', elementType: 'labels.text.fill', stylers: [{ color: '#444444' }] },
    { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f2f2f2' }] },
    { featureType: 'landscape.man_made.building', elementType: 'geometry.fill', stylers: [{ color: '#4A5568' }] },
    {
        featureType: 'landscape.man_made.building',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#E2E8F0' }, { weight: 8 }]
    },
    {
        featureType: 'landscape.man_made.building',
        elementType: 'labels.text',
        stylers: [{ color: '#2d2d2d' }, { weight: '0.01' }]
    },
    { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'simplified' }] },
    { featureType: 'road', elementType: 'all', stylers: [{ saturation: -100 }, { lightness: 45 }] },
    { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'simplified' }] },
    { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#e8b964' }] },
    { featureType: 'road.highway', elementType: 'labels.text', stylers: [{ color: '#2d2d2d' }] },
    { featureType: 'road.arterial', elementType: 'geometry', stylers: [{ color: 'white' }] },
    { featureType: 'road.arterial', elementType: 'labels.text', stylers: [{ color: '#2d2d2d' }, { weight: '0.01' }] },
    { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'simplified' }] },
    { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: 'white' }] },
    { featureType: 'road.local', elementType: 'labels', stylers: [{ hue: '#ff0000' }] },
    {
        featureType: 'road.local',
        elementType: 'labels.text',
        stylers: [{ visibility: 'on' }, { color: '#2d2d2d' }, { weight: '0.01' }]
    },
    { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] }
];

export const METERS_PER_MILE = 1609.344;

export const minSendAtOffset = 5;

export const maxSendAtOffset = 364;

export const helpdeskIdleDelay = 2 * 60 * 1000;
export const helpdeskCooldown = 30 * 60 * 1000;

export const logoPath = '/images/audience-logo.png';
