<template>
    <div v-click-away="close">
        <button
            class="notification-button"
            :class="{
                'bg-gray-300': isOpen,
                'hover:border-gray-300': notifications
            }"
            @click="isOpen = !isOpen"
        >
            <span v-if="hasUnread" class="unread-dot">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
            </span>
            <i class="fas fa-bell text-xl"></i>
        </button>
        <notification-dropdown
            v-if="isOpen"
            :notifications="notifications"
            :showing-all="showingAll"
            @toggle-show-all="toggleShowAll"
            @toggle-read="toggleRead"
            @close="close"
        />
    </div>
</template>
<script>

import NotificationDropdown from '@outreach/components/notification/NotificationDropdown.vue';
import { mixin as clickAway } from '@outreach/mixins/clickAway';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: { NotificationDropdown },
    mixins: [clickAway],
    data () {
        return {
            isOpen: false
        };
    },
    computed: {
        ...mapGetters({
            notifications: 'notifications/all',
            showingAll: 'notifications/showingAll',
            hasUnread: 'notifications/hasUnread'
        })
    },
    mounted () {
        this.sync();
    },
    methods: {
        ...mapActions({
            sync: 'notifications/sync',
            toggleRead: 'notifications/toggleRead',
            toggleShowAll: 'notifications/toggleShowingAll'
        }),
        close () {
            this.isOpen = false;
        }
    }
};
</script>
<style>
.notification-button {
    @apply inline-flex relative p-3 rounded text-gray-700 border border-white
}

.unread-dot {
    @apply absolute flex h-3 w-3 top-0.5 right-0.5
}
</style>
