<template>
    <div>
        <v-field
            :is-focused="isFocused"
            :has-value="hasValue"
            :label="label"
            :readonly="readonly"
            :disabled="disabled"
            :help="help"
            :errors="errors"
        >
            <textarea v-if="type === 'textarea'" v-bind="attributes" v-on="handlers"></textarea>
            <input
                v-else
                ref="inputRef"
                :type="type"
                :min="min"
                :max="max"
                :step="step"
                v-bind="attributes"
                v-on="handlers"
            />
            <span v-if="loading" class="absolute right-0 top-0 inset-y-0 h-42 flex items-center mr-3">
                <v-loader :size="20" />
            </span>
        </v-field>
    </div>
</template>

<script>
import VLoader from '@outreach/components/VLoader';
import { useCurrencyInput } from 'vue-currency-input';
import VField from '@outreach/components/shared/VField.vue';

export default {
    components: { VField, VLoader },
    props: {
        autocomplete: { type: String, default: null },
        value: { type: [String, Number], default: '' },
        label: { type: String, required: true },
        readonly: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
        type: { type: String, default: 'text' },
        errors: { type: [Array, String], required: false, default: () => [] },
        loading: { type: Boolean, default: false },
        min: { type: [Number, String], default: undefined },
        max: { type: [Number, String], default: undefined },
        step: { type: Number, default: undefined },
        currency: { type: Boolean, default: false },
        pattern: { type: String, default: undefined },
        help: { type: String, default: undefined }
    },
    data: () => ({
        focused: false,
        autofilled: false
    }),
    computed: {
        isFocused () {
            return this.focused || this.hasValue || this.autofilled;
        },
        hasValue () {
            return this.value === 0 || Boolean(this.value);
        },
        isDateInput () {
            return this.type === 'date';
        },
        handlers () {
            return {
                focus: function () {
                    this.$emit('focus');
                    this.focused = true;
                }.bind(this),
                blur: function () {
                    this.$emit('blur');
                    this.focused = false;
                }.bind(this),
                input: ($event) => this.onChange($event.target.value),
                animationstart: ($event) => this.setAutofill($event),
                keydown: ($event) => this.$emit('keydown', $event),
                keyup: ($event) => this.$emit('keyup', $event)
            };
        },
        attributes () {
            return {
                ref: 'input',
                class: {
                    'appearance-none p-2 block rounded focus:outline-none transition-all text-gray-800': true,
                    'bg-white focus:border-brand-regular border border-solid border-gray-400': !this.readonly,
                    'border-transparent bg-transparent w-full': this.readonly,
                    'text-white gap-x-8': this.isDateInput && !this.isFocused,
                    'opacity-50 cursor-not-allowed': this.disabled
                },
                readonly: this.readonly,
                name: this.label,
                disabled: this.disabled,
                pattern: this.pattern,
                autocomplete: this.autocomplete,
                'aria-label': this.label,
                ...this.currency ? {} : { value: this.value }
            };
        }
    },
    methods: {
        setAutofill ({ animationName }) {
            this.autofilled = !!animationName.includes('onAutoFillStart');
        },
        onChange (value) {
            if (this.pattern && !RegExp(this.pattern).test(value)) {
                return;
            }

            this.$emit('input', value);
        }
    },
    setup (props) {
        if (!props.currency) {
            return;
        }

        const { inputRef } = useCurrencyInput({ currency: 'USD', valueRange: { min: props.min, max: props.max } });
        return { inputRef };
    }
};
</script>

<style scoped>
@keyframes onAutoFillStart { from {/**/} to {/**/} }
@keyframes onAutoFillCancel { from {/**/} to {/**/} }

input:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 0.1s ease-in-out 0s;
}
input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
}
</style>
