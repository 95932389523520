<template>
    <div class="container mx-auto p-6 bg-white rounded-sm shadow-sm">
        <image-library asset-type="note_cover" disable-select />
    </div>
</template>

<script>
import ImageLibrary from '@outreach/components/shared/image-library/ImageLibrary.vue';

export default {
    name: 'Covers',
    components: { ImageLibrary }
};
</script>
