<template>
    <div>
        <slot></slot>
        <v-button
            v-if="!template.is_default"
            id="bulk-assign"
            button-style="default"
            size="sm"
            :label="confirmTitle"
            @click="confirm"
        />
        <v-button
            v-if="template.is_default"
            id="bulk-remove"
            button-style="error"
            size="sm"
            :label="confirmTitle"
            @click="confirm"
        />
    </div>
</template>

<script>
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VButton from '@outreach/components/VButton.vue';
import services from '@services/Api';
import { Alert } from '@services/Alert';

export default {
    name: 'BulkTemplateActions',
    components: { VButton },
    props: {
        template: {
            type: Object,
            required: true
        }
    },
    computed: {
        confirmTitle () {
            if (this.template.is_default) {
                return 'Un-Assign From All';
            }
            return 'Assign To All';
        },
        confirmMessage () {
            if (this.template.is_default) {
                return 'Are you sure you want to un-assign this template from all accounts?';
            }
            return 'Are you sure you want to assign this template to all accounts?';
        }
    },
    methods: {
        async confirm () {
            const confirmed = await dialogEmitter.confirm(this.confirmTitle, this.confirmMessage);

            if (confirmed) {
                this[this.template.is_default ? 'remove' : 'assign']();
            }
        },
        async remove () {
            try {
                await services.admin.templates.unassignAll(this.template.id);
                Alert.success('All accounts removed from template');
            } catch (e) {
                await Alert.fromError(e, 'Error removing accounts from template');
            }
        },
        async assign () {
            try {
                await services.admin.templates.assignAll(this.template.id);
                Alert.info('The template is being assigned to all accounts. This may take a few moments...', 3000);
            } catch (e) {
                await Alert.fromError(e, 'Error assigning accounts to template');
            }
        }
    }
};
</script>
