<template>
    <div class="bg-gray-400 flex justify-between items-center px-4">
        <div class="flex">
            <div>{{ title }}</div>
            <slot name="actions"></slot>
        </div>
        <div>
            <i
                ref="left-arrow"
                class="fa fa-chevron-left p-4 cursor-pointer"
                :class="previousClasses"
                @click="navigate(-1)"
            ></i>
            <i
                ref="right-arrow"
                class="fa fa-chevron-right p-4 cursor-pointer"
                :class="nextClasses"
                @click="navigate(1)"
            ></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rows: { type: Array, default: () => [] },
        selectedId: { type: Number, default: undefined }
    },
    computed: {
        selected () {
            return this.rows.find(row => row.id === this.selectedId);
        },
        title () {
            if (!this.selected) {
                return undefined;
            }
            return this.selected.title;
        },
        indexOfSelected () {
            return this.rows.indexOf(this.selected);
        },
        previousClasses () {
            return { 'text-gray-500': this.indexOfSelected === 0 };
        },
        nextClasses () {
            return { 'text-gray-500': this.indexOfSelected === this.rows.length - 1 };
        },
        offsetIndex () {
            return Math.max(1, this.rows.length - 4);
        }
    },
    watch: {
        selectedId () {
            if (this.indexOfSelected === this.offsetIndex) {
                this.$emit('end-reached');
            }
        }
    },
    methods: {
        navigate (increment) {
            const index = this.indexOfSelected + increment;
            if (!this.rows[index]) {
                return;
            }
            this.$emit('select', this.rows[index].id);
        }
    }
};
</script>
