<template>
    <div
        class="flex bg-white border-b shadow-md rounded hover:bg-gray-100 py-4 md:px-6"
    >
        <div
            class="flex cursor-pointer flex-1 flex-col mx-4 md-mx-0 md:flex-row md:items-center"
            :class="{'opacity-50': faded}"
        >
            <slot></slot>
        </div>
        <div v-if="showActions" class="flex items-center ml-auto">
            <dropdown ref="dropdown" class="flex items-center">
                <template v-if="priorityAction" #priority-options>
                    <button
                        ref="priority-action"
                        @click="$emit('action', priorityAction.name)"
                    >{{ priorityAction.label }}
                    </button>
                </template>
                <template v-if="actions" #content>
                    <button
                        v-for="action in actions"
                        :key="action.label"
                        :ref="`action-${action.name}`"
                        @click="$emit('action', action.name)"
                    >
                        {{ action.label }}
                    </button>
                </template>
            </dropdown>
        </div>
    </div>
</template>

<script>
import Dropdown from '@outreach/components/shared/dropdown';

export default {
    components: { Dropdown },
    props: {
        faded: { type: Boolean, default: false },
        showActions: { type: Boolean, default: true },
        actions: {
            type: Array,
            default: null
        },
        priorityAction: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            component: () => null
        };
    }
};
</script>
