<template>
    <article class="w-full">
        <team-form
            v-if="canEdit"
            :can-edit="canEdit"
            :team="team"
            :show-credit-transfer="canTransferCredits"
            :can-create-user="true"
            title="Update Team"
            @save="updateTeam"
            @refreshTeam="loadTeam"
        >
            <template #actionButton>
                <div class="ml-auto">
                    <v-button label="Import..." button-style="default" @click="openCsvDialog" />
                </div>
            </template>
        </team-form>
        <div v-else-if="!loading" class="rounded-md bg-red-50 p-4">
            <p class="text-sm font-medium text-red-800">You do not have permission to view this page.</p>
        </div>
    </article>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TeamForm from '@outreach/pages/admin/teams/TeamForm';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VButton from '@outreach/components/VButton';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';

export default {
    name: 'ModifyTeam',
    components: { TeamForm, VButton },
    mixins: [hasPermission],
    data () {
        return {
            team: { users: [] }
        };
    },
    computed: {
        ...mapState({
            loading: ({ loader }) => loader.loading,
            user: ({ user }) => user.user
        }),
        teamId () {
            return this.$route.params.teamId || this.user.current_team_id;
        },
        canEdit () {
            return this.isCustomerService() || this.team.is_owner;
        },
        canTransferCredits () {
            return !!this.team.is_owner;
        }
    },
    watch: {
        user (newUser, oldUser) {
            if (newUser.current_team_id !== oldUser.current_team_id) {
                this.loadTeam();
            }
        }
    },
    async mounted () {
        await this.loadTeam();
    },
    methods: {
        ...mapActions({
            setMessage: 'alerts/setMessage',
            fetchTeam: 'teams/show',
            update: 'teams/update'
        }),
        async loadTeam () {
            this.team = await this.fetchTeam(this.teamId);
        },
        async updateTeam (data) {
            await this.update(data);
            await this.loadTeam();
            this.setMessage({ message: 'Team updated successfully.', messageType: 'success', duration: 5000 });
        },
        openCsvDialog () {
            dialogEmitter.name('ImportUsersDialog')
                .props({ teamId: this.team.id })
                .addListener('csv-imported', () => this.loadTeam())
                .wait();
        }
    }
};
</script>
