<template>
    <span class="buttonGroup relative z-0 inline-flex">
        <slot name="default"></slot>
    </span>
</template>

<script>
export default {
    name: 'VButtonGroup'
};
</script>

<style lang="scss">
.buttonGroup {
    div {
        @apply border-l -ml-px border-gray-400;
        > button {
            @apply rounded-none;
        }
        &:first-child {
            @apply ml-0 border-l-0;
            > button {
                @apply rounded-l-full ;
            }
        }
        &:last-child {
            > button {
                @apply rounded-r-full;
            }
        }
    }
}
</style>
