<template>
    <div class="vue-csv-uploader">
        <div class="flex items-center">
            <v-button
                label="Change File"
                class="mb-4"
                button-style="info"
                @click="$emit('change-file')"
            />
            <div
                v-if="headerRules && invalidFields.length"
                id="invalid-fields"
                class="flex-1 ml-4 p-4 rounded bg-red-100"
            >
                <div class="font-bold">
                    <i class="fas fa-exclamation-triangle text-red-500 mr-2"></i>
                    Required field(s) still need to be mapped -
                </div>
                <p class="ml-7">
                    {{ invalidFields.slice(0, 2).join(', ') }}
                    <span v-if="invalidFields.length === 3">
                        and {{ invalidFields[2] }}
                    </span>
                    <span v-if="invalidFields.length > 3">
                        and {{ invalidFields.length - 2 }} more...
                    </span>
                </p>
            </div>
        </div>
        <div class="form">
            <div v-if="sample" class="max-h-[50vh] overflow-y-scroll">
                <div class="grid grid-cols-4 border-b border-gray-200 py-2">
                    <div class="font-bold">Matched</div>
                    <div class="font-bold">Column Header From File</div>
                    <div class="font-bold">Preview Information</div>
                    <div class="font-bold">Audience Property</div>
                </div>
                <div
                    v-for="(column, i) in firstRow"
                    :key="`column-${i}`"
                    class="grid grid-cols-4 border-b border-gray-200 py-2"
                >
                    <div class="pl-4">
                        <i
                            v-if="availableFields.some(field => field === column)"
                            class="fa-solid fa-check text-green-500"
                        ></i>
                        <i v-else class="fa-solid fa-dash text-gray-600"></i>
                    </div>
                    <div>{{ column }}</div>
                    <div class="text-gray-600 text-sm leading-tight">
                        <div v-if="sample[1]">{{ sample[1][i] }}</div>
                        <div v-if="sample[2]">{{ sample[2][i] }}</div>
                        <div v-if="sample[3]">{{ sample[3][i] }}</div>
                    </div>
                    <div>
                        <select
                            class="border border-gray-200 p-2 rounded w-full"
                            @change="onChange($event, column)"
                        >
                            <option value="">Unchanged</option>
                            <option
                                v-for="(field, j) in availableFields"
                                :key="`field-${j}`"
                                :value="field"
                                :selected="headerRules.getField(field) === column"
                            >{{ field }} {{ isInvalid(field) ? '❌' : '' }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import Papa from 'papaparse';
import VButton from '@outreach/components/VButton.vue';
import { CsvMapper } from '@outreach/components/services/CsvMapper';

export default {
    components: { VButton },
    props: {
        file: { type: File, required: true },
        headerRules: { type: CsvMapper, required: true }
    },
    data: () => ({
        csv: null,
        sample: null
    }),
    computed: {
        firstRow () {
            return get(this, 'sample.0');
        },
        invalidFields () {
            return this.headerRules.invalidFields;
        },
        availableFields () {
            return this.headerRules.fields;
        }
    },
    watch: {
        firstRow () {
            this.headerRules.fields.forEach(
                (field) => this.firstRow.includes(field) ? this.headerRules.updateMappings(field, field) : null
            );
            this.$emit('input', this.headerRules.mappings);
        }
    },
    mounted () {
        this.load();
    },

    methods: {
        onChange (e, heading) {
            this.headerRules.updateMappings(e.target.value, heading);
            this.$emit('input', this.headerRules.mappings);
        },
        load () {
            this.readFile((output) => {
                this.sample = get(Papa.parse(output, { preview: 4, skipEmptyLines: true }), 'data');
                this.csv = get(Papa.parse(output, { skipEmptyLines: true }), 'data');
            });
        },
        readFile (callback) {
            const file = this.file;

            if (file) {
                const reader = new FileReader();
                reader.readAsText(file, 'UTF-8');
                reader.onload = (evt) => { callback(evt.target.result); };
            }
        },
        isInvalid (field) {
            return this.headerRules.invalidFields.includes(field);
        }
    }
};
</script>
