import ApiClient from '@services/Http/Api';

export const list = (params = {}) => {
    return ApiClient.get('/dashboard/admin/drawings', { params });
};

export const archive = (id) => {
    return ApiClient.destroy(`/dashboard/admin/drawings/${id}`);
};

export const create = (payload) => {
    return ApiClient.upload('/dashboard/admin/drawings', payload);
};

export const update = (id, payload = {}) => {
    return ApiClient.patchUpload(`/dashboard/admin/drawings/${id}`, payload);
};

export const plot = (id, payload = {}) => {
    return ApiClient.post(`/dashboard/admin/drawings/${id}/plot`, payload);
};
