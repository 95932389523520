<template>
    <div>
        <div class="border border-gray-400 rounded flex flex-row justify-between items-stretch">
            <div class="flex items-center p-2">
                <svg
                    v-if="selected"
                    ref="preview"
                    height="60"
                    viewBox="0 0 300 200"
                    v-html="selected.svg"
                />
                <span v-else class="text-gray-600">
                    None selected
                </span>
            </div>

            <button-modal ref="doodle-select" :label="label" header="Choose Doodle" class="flex">
                <template #button="{ open }">
                    <button
                        ref="open-button"
                        class="uppercase tracking-wider text-sm font-bold p-5 bg-gray-300 h-full text-gray-800"
                        @click="open"
                    >
                        {{ label }}
                    </button>
                </template>
                <template #content>
                    <div class="content">
                        <ul ref="list" class="flex flex-row flex-wrap -m-2">
                            <li
                                v-for="doodle in doodles"
                                ref="item"
                                :key="doodle.id"
                                class="doodle"
                                :class="{ 'selected' : selected && doodle.id === selected.id }"
                                @click="selected = doodle"
                            >
                                <svg height="80" viewBox="0 0 300 200" v-html="doodle.svg" />
                            </li>
                        </ul>
                    </div>
                </template>
                <template #actions="{ close }">
                    <div class="p-2">
                        <v-button
                            ref="clear-button"
                            label="Clear"
                            button-style="error"
                            :disabled="!selected"
                            @click="selected = null; handleChoose(close)"
                        />
                        <v-button
                            ref="choose-button"
                            label="Choose"
                            button-style="primary"
                            :disabled="!selected"
                            @click="handleChoose(close)"
                        />
                    </div>
                </template>
            </button-modal>
        </div>
        <p v-for="(error, index) in errors" :key="index" class="text-red-400 text-xs mb-2">{{ error }}</p>
    </div>
</template>

<script>
import ButtonModal from '@outreach/components/shared/modal/ButtonModal';
import VButton from '@outreach/components/VButton';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: { ButtonModal, VButton },
    props: {
        label: { type: String, default: 'Choose doodle...' },
        value: { type: Object, default: undefined },
        errors: { type: [Array, String], required: false, default: () => [] }
    },
    data: () => ({
        selected: null
    }),
    computed: {
        ...mapGetters({
            doodles: 'doodles/get'
        })
    },
    watch: {
        doodles () {
            this.updateSelected();
        },
        value () {
            this.updateSelected();
        }
    },
    created () {
        this.listDoodles();
        this.updateSelected();
    },
    methods: {
        ...mapActions({
            listDoodles: 'doodles/list'
        }),
        handleChoose (close) {
            this.$emit('selected', this.selected);
            close();
        },
        updateSelected () {
            this.selected = this.doodles.find(doodle => this.value && doodle.id === this.value.id);
        }
    }
};
</script>

<style scoped>
    .content {
        height: 40vh;
        overflow: auto;
    }

    .selected {
        @apply bg-gray-200;
    }

    .doodle {
        @apply flex flex-col flex-1 p-4 cursor-pointer rounded border border-gray-400 m-2 hover:bg-gray-100
    }
</style>
