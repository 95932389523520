"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Template = void 0;
const Layout_1 = require("@services/Layout");
const lodash_1 = require("lodash");
class Template {
    constructor(template = {}) {
        this.doodles = [];
        this.cover_id = undefined;
        this.cover = undefined;
        this.font = undefined;
        this.layout_type = Layout_1.Layout.NOTE;
        this.id = 0;
        this.name = '';
        this.description = '';
        this.content = '';
        this.font_id = 0;
        this.meta = {};
        this.qr_code_url = undefined;
        this.accounts = [];
        this.admin_provided = false;
        this.is_default = false;
        this.created_at = '';
        this.updated_at = '';
        this.deleted_at = undefined;
        this.scope = null;
        Object.assign(this, this.attributes(template));
    }
    get idAttribute() {
        return this.id;
    }
    get isEmail() {
        return this.layout_type === Layout_1.Layout.EMAIL;
    }
    attributes(template) {
        var _a, _b;
        const attributes = Object.assign(Object.assign({}, template), { scope: (_a = template.scope) !== null && _a !== void 0 ? _a : '' });
        if (attributes.layout_type !== Layout_1.Layout.EMAIL && ((_b = attributes.meta) === null || _b === void 0 ? void 0 : _b.subject)) {
            return (0, lodash_1.omit)(attributes, ['meta.subject']);
        }
        return attributes;
    }
}
exports.Template = Template;
