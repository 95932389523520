<template>
    <div v-click-outside="onOutsideClick" class="relative">
        <action-bar-button
            tooltip="Select a Font"
            class="ml-2"
            :title="(value || {}).title || 'Font'"
            icon="fa-solid fa-font-case"
            :active="showFlyout"
            @click="showFlyout = !showFlyout"
        />
        <flyout :show-flyout="showFlyout">
            <drilldown-menu
                :items="fontFormatter"
                title="SELECT A FONT"
                @clicked-item="updateFont"
            />
        </flyout>
    </div>
</template>

<script>
import services from '@services/Api';
import ActionBarButton from '@outreach/components/shared/ActionBarButton.vue';
import Flyout from '@outreach/components/shared/Flyout.vue';
import DrilldownMenu from '@outreach/components/shared/DrilldownMenu.vue';
import { directive as clickOutside } from '@outreach/mixins/clickAway';

export default {
    components: { DrilldownMenu, Flyout, ActionBarButton },
    directives: {
        clickOutside
    },
    props: {
        value: { type: Object, default: undefined }
    },
    data () {
        return {
            fonts: [],
            isLoading: false,
            showFlyout: false
        };
    },
    computed: {
        defaultFont () {
            return this.fonts.find(font => font.default);
        },
        fontFormatter () {
            return this.fonts.map((font) => ({ key: font.title, ...font }));
        }
    },
    created () {
        this.getFonts();
    },
    methods: {
        async getFonts () {
            this.isLoading = true;
            try {
                const { data } = await services.fonts.list();
                this.fonts = data.data.map(font => ({
                    ...font,
                    label: font.title,
                    value: font.id
                }));
                if (!this.value) {
                    await this.setDefaultFont();
                }
            } finally {
                this.isLoading = false;
            }
        },
        async setDefaultFont () {
            if (!this.value && this.defaultFont) {
                this.updateFont(this.defaultFont);
            }
        },
        onOutsideClick () {
            this.showFlyout = false;
        },
        updateFont (font) {
            this.$emit('input', font);
        }
    }
};
</script>
