"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LengthAwarePaginator = void 0;
class LengthAwarePaginator {
    constructor(meta = {}) {
        this.meta = meta;
    }
    get isLengthAware() {
        return typeof this.meta.total !== 'undefined';
    }
    get isSimple() {
        return (this.nextPage || this.currentPage) !== null;
    }
    get lastPage() {
        var _a;
        return (_a = this.meta.last_page) !== null && _a !== void 0 ? _a : 1;
    }
    get currentPage() {
        var _a;
        return (_a = this.meta.current_page) !== null && _a !== void 0 ? _a : 1;
    }
    get nextPage() {
        if (this.currentPage === this.lastPage)
            return null;
        return Math.min(this.currentPage + 1, this.lastPage);
    }
    get previousPage() {
        if (this.currentPage === 1)
            return null;
        return Math.max(this.currentPage - 1, 1);
    }
    get total() {
        var _a;
        return (_a = this.meta.total) !== null && _a !== void 0 ? _a : 0;
    }
    set total(value) {
        this.meta.total = value;
    }
}
exports.LengthAwarePaginator = LengthAwarePaginator;
