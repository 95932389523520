<template>
    <div class="flex flex-col gap-4">
        <v-table
            v-if="customers.length"
            :rows="customers"
            :sort="sort"
            has-actions
            @sort-changed="sortChanged"
        >
            <template #default="{ row }">
                <v-table-column label="Account" value="account">
                    <p><strong class="text-lg">{{ row.name }}</strong></p>
                </v-table-column>
                <v-table-column label="Name" value="name">
                    <account-users-info :account="row" />
                </v-table-column>
                <v-table-column
                    label="Last Login"
                    value="last_logged_in_at"
                    sortable
                >
                    <span v-if="row.last_logged_in_at">{{ formatDate(row.last_logged_in_at) }}</span>
                    <div
                        v-if="row.last_logged_in_at"
                        class="text-sm text-gray-700"
                    >{{ fromNow(row.last_logged_in_at) }}
                    </div>
                    <span v-else>Never    </span>
                </v-table-column>
                <v-table-column label="Campaign Last Submitted" value="last_submitted_at" sortable>
                    <p v-if="row.last_submitted_at">
                        {{ formatFullDate(row.last_submitted_at, 'll') }}
                    </p>
                    <p v-else>No Submissions yet</p>
                </v-table-column>
                <v-table-column label="Credits" value="credits" sortable>
                    <p class="text-gray-700">{{ row.credits }}</p>
                </v-table-column>
                <v-table-column label="Notes Sent" value="completed_count" sortable>
                    <p :id="`completed-job-count-${row.id}`">{{ row.completed_count }}</p>
                </v-table-column>
                <v-table-column label="Recent Emails" value="transmissions_count" sortable>
                    <p :id="`sent-email-count-${row.id}`">{{ row.transmissions_count }}</p>
                </v-table-column>
            </template>
            <template #priority-buttons="{ row }">
                <button @click="viewEmails(row.id)">View Emails</button>
            </template>
        </v-table>
        <v-paginator
            class="mx-auto"
            :pagination="pagination"
            :total="pagination.lastPage"
            :value="pagination.currentPage"
            @input="(page) => listCustomers({ page })"
        />

        <v-loader v-if="loading" />

        <div v-if="customers.length === 0 && !loading " class="text-center">
            <p class="text-lg text-gray-700">It doesn't look like you have any customers yet.</p>
        </div>
    </div>
</template>

<script>
import VTableColumn from '@outreach/components/shared/table/VTableColumn.vue';
import AccountUsersInfo from '@outreach/pages/Customers/AccountUsersInfo.vue';
import VTable from '@outreach/components/shared/table/VTable.vue';
import { formatDate, formatFullDate, fromNow } from '@services/Filters';
import { mapState } from 'vuex';
import { LengthAwarePaginator } from '@services/LengthAwarePaginator';
import debounce from 'lodash/debounce';
import { Alert } from '@services/Alert';
import VLoader from '@outreach/components/VLoader.vue';
import VPaginator from '@outreach/components/VPaginator.vue';
import { customers } from '@services/Api/Customers';

export default {
    name: 'CustomersTable',
    components: { VPaginator, VTable, AccountUsersInfo, VTableColumn, VLoader },
    props: {
        query: { type: String, default: undefined },
        sort: { type: Object, required: true }
    },
    data () {
        return {
            customers: [],
            pagination: new LengthAwarePaginator()
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            loading: ({ loader }) => loader.loading
        })
    },
    watch: {
        query: debounce(async function () {
            await this.listCustomers();
        }, 500),
        sort () {
            this.listCustomers();
        }
    },
    mounted () {
        this.listCustomers();
    },
    methods: {
        formatDate,
        formatFullDate,
        fromNow,
        sortChanged (sort) {
            this.$emit('update:sort', sort);
        },
        async listCustomers (params = {}) {
            try {
                const { data } = await customers.list(this.user.id, {
                    ...params,
                    q: this.query,
                    ...this.sort
                });
                this.customers = data.data;
                this.pagination.meta = data.meta;
            } catch (e) {
                await Alert.fromError(e, 'Error loading customers. Please try again.');
            }
        },
        viewEmails (accountId) {
            this.$router.push({ name: 'view-emails', params: { accountId } });
        }
    }
};
</script>
