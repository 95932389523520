<template>
    <div>
        <slot name="action-bar"></slot>
        <v-input
            v-if="type === 'input'"
            ref="input"
            :label="label"
            :value="value"
            :errors="errors"
            :readonly="readonly"
            autocomplete="false"
            :disabled="disabled"
            @input="$emit('input', $event)"
        />
        <v-textarea
            v-else
            ref="input"
            class="text-area"
            autocomplete="false"
            :label="label"
            :value="value"
            :readonly="readonly"
            :styles="{'rounded-t': !hasActionBar}"
            :disabled="disabled"
            :limit="limit"
            :rows="rows"
            @input="$emit('input', $event)"
        />
    </div>
</template>

<script>
import { Textarea, Textcomplete } from 'textcomplete';
import VInput from '@outreach/components/inputs/VInput';
import VTextarea from '@outreach/components/inputs/VTextarea';
import getStrategies from '@services/Typeahead';

export default {
    components: { VInput, VTextarea },
    props: {
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            required: true
        },
        rows: {
            type: Number,
            default: 10
        },
        limit: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'input'
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        errors: {
            type: Array,
            required: false,
            default: () => []
        },
        strategiesFunction: {
            type: Function,
            required: false,
            default: getStrategies
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasActionBar () {
            return Boolean(this.$slots['action-bar']);
        }
    },
    mounted () {
        this.initializeTextcomplete();
    },
    methods: {
        initializeTextcomplete () {
            const input = this.$refs.input.$refs.input;

            if (input) {
                this.editor = new Textarea(input);
                this.textcomplete = new Textcomplete(this.editor);
                this.textcomplete.register(this.strategiesFunction(this.options));
            }
        }
    }
};
</script>

<style>
    ul.dropdown-menu.textcomplete-dropdown {
        @apply absolute flex flex-col right-0 shadow-lg bg-white rounded-sm z-20 min-w-content w-40;
    }

    ul.dropdown-menu.textcomplete-dropdown li {
        padding: 5px 10px;
        cursor: pointer;
    }

    ul.dropdown-menu.textcomplete-dropdown li.active {
        @apply bg-gray-300;
    }
</style>
