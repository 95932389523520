<template>
    <div>
        <div>
            <div class="ml-auto mb-2">
                <v-checkbox
                    ref="likely-to-sell"
                    v-tooltip="'Set filters to find the households most likely to sell'"
                    @input="setLikelyToSell"
                >&#128293; Show only likely to sell households &#128293;</v-checkbox>
            </div>
        </div>
        <div class="mb-6 font-bold cursor-pointer" @click="visible = !visible">
            Optional Filters
            <i class="fa ml-2" :class="{'fa-chevron-up': visible, 'fa-chevron-down': ! visible}"></i>
        </div>
        <slide-transition>
            <real-estate-filters-form
                v-show="visible"
                :filters="filters"
                :form-errors="formErrors"
                :comparables="comparables"
                class="mb-6 flex flex-col gap-6 lg:gap-4"
                @update:comparables="$emit('update:comparables', $event)"
            />
        </slide-transition>
    </div>
</template>

<script>
import 'vue-slider-component/theme/default.css';
import SlideTransition from '@outreach/components/shared/SlideTransition.vue';
import RealEstateFilters from '@outreach/pages/OutboundProspecting/RealEstateFilters';
import RealEstateFiltersForm from '@outreach/pages/OutboundProspecting/RealEstateFiltersForm.vue';
import { ComparableFilters } from '@outreach/pages/OutboundProspecting/ComparableFilters';
import { Alert } from '@services/Alert';
import VCheckbox from '@outreach/components/inputs/VCheckbox.vue';

export default {
    name: 'PropertyMapOptions',
    components: { VCheckbox, SlideTransition, RealEstateFiltersForm },
    props: {
        formErrors: { type: Object, default: () => ({}) },
        property: { type: Object, default: undefined },
        comparables: { type: Boolean, default: false }
    },
    data () {
        return {
            filters: RealEstateFilters.fromQueryString(this.$route.query),
            visible: false
        };
    },
    watch: {
        filters: {
            deep: true,
            handler () {
                this.$emit('update', this.filters.getFilters());
            }
        },
        property () {
            if (!this.comparables) {
                return;
            }
            this.$set(this, 'filters', new ComparableFilters(this.property).update(this.filters));
            this.visible = this.filters.hasFilters();
        }
    },
    mounted () {
        this.visible = this.filters.hasFilters();
    },
    methods: {
        setLikelyToSell (apply = true) {
            if (apply) {
                this.filters.setFilters({
                    equity_percent: { min: 30, max: 100 },
                    sold_at: { min: 7, max: undefined },
                    exclude_companies: true,
                    owner_occupied: true
                });
                Alert.success('Likely to Sell filters set.', 2000);
            } else {
                this.filters.setFilters({
                    equity_percent: { min: undefined, max: undefined },
                    sold_at: { min: undefined, max: undefined }
                });
                this.filters.exclude_companies = undefined;
                this.filters.owner_occupied = undefined;
                Alert.info('Reset Likely to Sell filters.', 2000);
            }
        }
    }
};
</script>
