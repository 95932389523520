<template>

    <div class="p-2 sm:p-0 max-w-full">
        <div v-if="! account" class="flex flex-col items-center justify-center p-6">
            <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
            <span class="text-gray-700">Loading...</span>
        </div>
        <div v-else>
            <div class="w-full mb-4">
                <div class="sm:block">
                    <nav class="account-navigation" aria-label="Tabs">
                        <button
                            v-for="(tab, tabIdx) in tabs"
                            :ref="`${tab.value}-tab`"
                            :key="tab.value"
                            class="group navigation-tab"
                            :class="[
                                currentTab === tab.value ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                                {'rounded-l-lg' : tabIdx === 0},
                                {'rounded-r-lg' : tabIdx === tabs.length - 1}
                            ]"
                            :aria-current="currentTab === tab.value ? 'page' : undefined"
                            @click="tab.click"
                        >
                            <span>{{ tab.label }}</span>
                            <span
                                v-if="tab.count"
                                class="hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                :class="{
                                    'bg-gray-100 text-gray-900' : currentTab !== tab.value,
                                    'bg-brand-regular text-white' : currentTab === tab.value
                                }"
                            >{{ tab.count }}</span>
                            <span
                                aria-hidden="true"
                                class="absolute inset-x-0 bottom-0 h-0.5"
                                :class="[
                                    currentTab === tab.value ? 'bg-brand-regular' : 'bg-transparent'
                                ]"
                            ></span>
                        </button>
                    </nav>
                </div>
            </div>

            <reorder-collaterals v-if="currentTab === 'add-ons'" />

            <v-panel v-else-if="currentTab === 'details'" title="Account details" class="overflow-visible">
                <form ref="account-form" @submit.prevent="updateAccountInfo">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <v-input
                            ref="account-name-input"
                            v-model="account.name"
                            class="flex-1"
                            label="Name"
                            :errors="formErrors.name"
                        />
                        <v-input
                            ref="account-credits-input"
                            v-model="account.credits"
                            class="flex-1"
                            type="number"
                            label="Credits"
                            :errors="formErrors.credits"
                            :disabled="! isAdmin()"
                        />
                        <span class="flex-1"></span>
                        <v-input
                            ref="account-note-price-input"
                            v-model="account.note_price"
                            class="flex-1"
                            type="number"
                            label="Note Price Override"
                            :errors="formErrors.note_price"
                            :disabled="! isAdmin()"
                        />
                    </div>
                    <hr class="my-4" />
                    <v-json-input v-model="account.details" header="Details Editor" />
                    <hr class="my-4" />
                    <admin-tag-select
                        ref="tag_select"
                        v-model="account.tagged"
                        placeholder="Add Tags"
                        :taggable="true"
                        :type="tagType"
                    />
                </form>
                <template #footer>
                    <v-button
                        ref="save-account-details"
                        button-style="primary"
                        label="Save Changes"
                        @click="updateAccountInfo"
                    />
                </template>
            </v-panel>

            <v-panel v-else-if="currentTab === 'covers'" title="Covers">
                <image-library :account-id="account.id" disable-select />
            </v-panel>

            <v-panel
                v-else-if="currentTab === 'campaign-summary'"
                title="Campaign Summary"
                subtitle="Please upload a PDF file or a link to your campaign summary."
            >
                <vue-dropzone ref="dropzone" class="mb-4" accepted=".pdf" @add-file="handleFile($event)" />

                <p class="mb-4 text-center">OR</p>

                <v-input
                    ref="campaign-summary"
                    v-model="campaignSummary"
                    label="URL to Campaign Summary"
                    :errors="formErrors.campaign_summary"
                />
                <template #footer>
                    <v-button
                        ref="save-account"
                        button-style="primary"
                        label="Save Changes"
                        @click="updateAccountInfo"
                    />
                </template>
            </v-panel>
        </div>
    </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import VueDropzone from '@outreach/components/shared/dropzone/VueDropzone';
import VPanel from '@outreach/components/shared/VPanel';
import VButton from '@outreach/components/VButton';
import VJsonInput from '@outreach/components/inputs/VJsonInput';
import ReorderCollaterals from '@outreach/pages/account/ReorderCollaterals';
import AdminTagSelect from '@outreach/components/tag-select/AdminTagSelect';
import ImageLibrary from '@outreach/components/shared/image-library/ImageLibrary';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import { TagType } from '@services/TagType';

export default {
    components: {
        ImageLibrary,
        AdminTagSelect,
        ReorderCollaterals,
        VJsonInput,
        VPanel,
        VueDropzone,
        VButton,
        VInput,
        VLoader
    },
    mixins: [hasPermission],
    data: () => ({
        campaignSummary: null,
        campaignSummaryFile: null,
        selectedAccountId: null,
        currentTab: 'details'
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            account: ({ accounts }) => accounts.account,
            formErrors: ({ accounts }) => accounts.formErrors
        }),
        tabs () {
            return [
                { label: 'Account Details', value: 'details', click: () => this.changeTab('details') },
                {
                    label: 'Add-Ons',
                    value: 'add-ons',
                    count: this.account.collaterals.length,
                    click: () => this.changeTab('add-ons')
                },
                { label: 'Covers', value: 'covers', click: () => this.changeTab('covers') },
                {
                    label: 'Campaign Summary',
                    value: 'campaign-summary',
                    click: () => this.changeTab('campaign-summary')
                }
            ];
        },
        tagType () {
            return TagType.ACCOUNTS;
        }
    },
    watch: {
        formErrors () {
            Object.entries(this.formErrors).filter(([_, v]) => v !== undefined).forEach(([_, message]) => {
                this.setMessage({ messageType: 'error', message });
            });
        },
        account: {
            deep: true,
            handler () {
                this.account.credits = Math.max(~~this.account.credits, 0);
            }
        }
    },
    async created () {
        await this.getAccount({
            accountId: this.$route.params.accountId,
            params: { include: 'nav_items, tagged, collaterals' }
        });
        this.populate();
    },
    methods: {
        ...mapActions({
            getUser: 'user/getUser',
            updateAccount: 'accounts/updateAccount',
            getAccount: 'accounts/getAccount',
            setMessage: 'alerts/setMessage'
        }),
        populate () {
            if (!this.account) return;
            this.campaignSummary = this.account.getNavItemUrl('Campaign Summary');
        },
        handleFile (file) {
            this.campaignSummary = null;
            this.campaignSummaryFile = file;
        },
        async updateAccountInfo () {
            const { collaterals, ...account } = this.account;
            await this.updateAccount({
                ...account,
                tagged: account.tagged.map(tag => ({ id: tag.id })),
                campaign_summary_file: this.campaignSummaryFile,
                campaign_summary: this.campaignSummary
            });
            this.populate();
        },
        changeTab (value) {
            this.currentTab = value;
        }
    }
};
</script>

<style scoped>
.account-navigation {
    @apply relative z-0 rounded-lg shadow grid grid-cols-2 divide-y divide-x divide-gray-200 sm:flex sm:divide-y-0;
}

.navigation-tab {
    @apply relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center;
    @apply hover:bg-gray-50 focus:z-10;
}
</style>
