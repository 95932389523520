<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1" label="Search" />
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading summaries</span>
            </div>
            <div v-else>
                <div v-if="summaries.length > 0">
                    <paginator-wrap :pagination="meta" :change-page="changePage">
                        <template #content>
                            <v-list ref="list" :rows="summaries" class="bg-white shadow-md rounded">
                                <template #default="{ row }">
                                    <div class="list bg-white shadow-md p-4 font-light">
                                        <div ref="first-column" class="flex flex-col justify-between p-3">
                                            <div class="text-xl font-normal">
                                                <div>{{ row.name }}</div>
                                                <div>
                                                    <span>Sent: {{ row.sent }}</span>
                                                    <span>Scanned: {{ row.visited }} ({{
                                                        Math.round(row.visited / row.sent * 100)
                                                    }}%)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div ref="second-column" class="py-3 px-6">
                                            <div class="font-bold">Campaigns</div>
                                            <div
                                                v-for="campaign in row.campaigns"
                                                :key="campaign.id"
                                                class="border-b-2"
                                            >
                                                <div class="flex justify-between">
                                                    <span class="font-normal">{{ campaign.name }}</span>
                                                </div>
                                                <div class="flex justify-between">
                                                    <span class="font-light">Submitted At:</span>
                                                    {{ formatDateTime(campaign.submitted_at) }}
                                                </div>
                                                <div class="flex justify-between">
                                                    <span class="font-light">Last Scan:</span>
                                                    {{ formatDateTime(campaign.last_visit) }}
                                                </div>
                                                <div class="flex justify-between">
                                                    <span class="font-light">Scanned/Sent:</span>
                                                    {{ campaign.visited }} / {{ campaign.sent }} ({{
                                                        Math.round(campaign.visited / campaign.sent * 100)
                                                    }}%)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </v-list>
                        </template>
                    </paginator-wrap>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No summaries found.</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import services from '@services/Api';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import VLoader from '@outreach/components/VLoader';
import { debounce } from 'lodash';
import VList from '@outreach/components/shared/VList';
import { formatDateTime } from '@services/Filters';
import VInput from '@outreach/components/inputs/VInput';
import SearchHeader from '@outreach/components/shared/SearchHeader';

export default {
    name: 'ScanSummaryReport',
    components: {
        SearchHeader,
        PaginatorWrap,
        VLoader,
        VList,
        VInput
    },
    data: () => ({
        summaries: [],
        meta: {},
        query: null
    }),
    computed: {
        ...mapGetters({
            loading: 'loader/isLoading'
        })
    },
    watch: {
        query: debounce(function (q) {
            this.getSummary({ q });
        }, 500)
    },
    async created () {
        await this.getSummary();
    },
    methods: {
        formatDateTime,
        async getSummary (params) {
            const { data } = await services.admin.getScanSummary(params);
            this.summaries = data.data;
            this.meta = data.meta;
        },
        changePage (page) {
            this.getSummary({ q: this.query, page });
        }
    }
};
</script>

<style scoped>
.list {
    display: grid;
    grid-template-columns: 1fr 2fr;
}
</style>
