import { dialogEmitter } from './dialog-utils';

const Dialogs = {
    AttachAccountsToArticlesDialog: () => import('./AttachAccountsToArticlesDialog'),
    ImageLibraryDialog: () => import('./ImageLibraryDialog'),
    Confirm: () => import('./ConfirmDialog'),
    AssignAccount: () => import('./AssignAccountDialog'),
    SimpleDialog: () => import('./NoteDialog'),
    CreditTransfer: () => import('./CreditTransfer'),
    ModifyAssociateDialog: () => import('../pages/Associates/ModifyAssociate'),
    AssignAssociates: () => import('@outreach/components/queues/AssignAssociatesDialog'),
    CommentDialog: () => import('./CommentDialog'),
    MachineDialog: () => import('@outreach/components/machine/MachineDialog'),
    PrintDialog: () => import('./PrintDialog'),
    ListLogsDialog: () => import('@outreach/components/account/ListLogsDialog'),
    ContactsAddTagDialog: () => import('@outreach/components/contacts/ContactsAddTagDialog'),
    CreateApiToken: () => import('./CreateApiTokenDialog'),
    LinksDialog: () => import('@outreach/components/links/LinksDialog'),
    PlotDrawingDialog: () => import('./PlotDrawingDialog'),
    DrawingDialog: () => import('@outreach/components/drawings/DrawingDialog'),
    TemplateSelect: () => import('./TemplateSelectDialog'),
    ImagePreviewDialog: () => import('@outreach/components/ImagePreviewDialog'),
    EditImageDialog: () => import('@outreach/components/shared/image-library/EditImageDialog'),
    AssignArticlesDialog: () => import('@outreach/components/account/AssignArticlesDialog'),
    PaymentDialog: () => import('@outreach/components/payments/PaymentDialog'),
    ContactQuickEdit: () => import('@outreach/components/contacts/ContactQuickEdit'),
    MarkAsPrintedDialog: () => import('@outreach/components/campaign/MarkAsPrintedDialog'),
    EmailContentDialog: () => import('@outreach/components/account/EmailContentDialog'),
    CropImageDialog: () => import('@outreach/components/shared/image-library/CropImageDialog'),
    UserFormDialog: () => import('@outreach/components/user/UserFormDialog.vue'),
    RealEstateFiltersDialog: () => import('@outreach/components/contacts/RealEstateFiltersDialog'),
    TerritoryPaymentDialog: () => import('@outreach/components/payments/TerritoryPaymentDialog'),
    ImportUsersDialog: () => import('@outreach/pages/admin/teams/ImportUsersDialog'),
    ManageClaimDialog: () => import('@outreach/pages/admin/ManageClaimDialog.vue'),
    CreateClaimDialog: () => import('@outreach/pages/admin/CreateClaimDialog.vue'),
    TemplatePreviewDialog: () => import('@outreach/pages/Templates/TemplatePreviewDialog.vue'),
    PlanDialog: () => import('@outreach/pages/Payment/PlanDialog.vue'),
    ImportInvitationsDialog: () => import('@outreach/pages/admin/ImportInvitationsDialog.vue'),
    InvitationDialog: () => import('@outreach/pages/admin/InvitationDialog.vue'),
    ConfirmSubmitDialog: () => import('@outreach/components/campaign/ConfirmSubmitDialog.vue'),
    FolderDialog: () => import('@outreach/components/shared/image-library/FolderDialog.vue'),
    AddToFolderDialog: () => import('@outreach/components/shared/image-library/AddToFolderDialog.vue'),
    ListingDialog: () => import('@outreach/pages/admin/ListingDialog.vue')
};

const DialogManager = {
    data () {
        return {
            dialogs: []
        };
    },
    components: {
        ...Dialogs
    },

    methods: {
        onOpen ({ name, props, listeners }) {
            if (!Dialogs[name]) {
                throw new Error(`Dialog ${name} is not registered.`);
            }
            this.dialogs.push({ name, props, listeners });
        },
        onClose () {
            this.dialogs.pop();
        },
        closeDialog () {
            dialogEmitter.closeDialog();
        }
    },

    mounted () {
        dialogEmitter.on('open-dialog', this.onOpen);
        dialogEmitter.on('close-dialog', this.onClose);
    },

    destroyed () {
        dialogEmitter.off('open-dialog', this.onOpen);
        dialogEmitter.off('close-dialog', this.onClose);
    },

    render (createElement) {
        return createElement(
            'div',
            {},
            this.dialogs.map((dialog) => createElement(dialog.name, {
                props: {
                    ...dialog.props,
                    closeDialog: this.closeDialog
                },
                on: dialog.listeners
            }))
        );
    }
};

export default DialogManager;
