import ApiClient from '@services/Http/Api';

export const currentUser = (params) => {
    return ApiClient.get('/me', { params });
};

export const get = (userId, params = {}) => {
    return ApiClient.get(`/dashboard/users/${userId}`, { params });
};

export const resetPassword = (email) => {
    return ApiClient.post('password/email', { email });
};
