import services from '@services/Api';
import { get } from 'lodash';
import debounce from 'lodash/debounce';
import { toast } from '@services/Toast';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import { handleErrors } from '../common';

const DefaultState = {
    selected: null,
    deliveries: [],
    meta: null,
    errors: null,
    query: null,
    previousRequestedPage: 0
};

export const state = { ...DefaultState };

export const actions = {

    select ({ commit }, delivery) {
        commit('setDelivery', delivery);
    },

    async setQuery ({ commit, dispatch }, { query, campaignId }) {
        await commit('resetState');
        await commit('setQuery', query);
        await dispatch('getDeliveries', { campaignId });
    },

    async refreshDeliveries ({ dispatch, commit }, { campaignId }) {
        await commit('resetState');
        await dispatch('getDeliveries', { campaignId });
    },

    debounceRefreshDeliveries: debounce(function ({ dispatch }, data) {
        return dispatch('refreshDeliveries', data);
    }, 350),

    getDeliveries: debounce(async function ({ commit, state }, { campaignId, page = 1 }) {
        if (page === state.previousRequestedPage) return;

        commit('setPreviousRequestedPage', page);

        try {
            const { data } = await services.deliveries.list(campaignId, {
                page,
                q: state.query
            });
            commit('setMeta', data.meta);
            commit('appendDeliveries', data.data);
            if (state.selected == null && data.data.length > 0) {
                commit('setDelivery', data.data[0]);
            }
        } catch (e) {
            throw new ClientVisibleError('Failed to fetch deliveries');
        }
    }, 350),

    async getNextPage ({ state, dispatch }, { campaignId }) {
        const lastPage = get(state.meta, 'last_page', 1);
        const currentPage = get(state.meta, 'current_page');
        const nextPage = Math.min(currentPage + 1, lastPage);
        dispatch('getDeliveries', { campaignId, page: nextPage });
    },

    async updateDelivery ({ commit, state }, { campaignId, payload }) {
        try {
            const { data } = await services.deliveries.update(campaignId, state.selected.id, payload);
            commit('updateDelivery', data.data);

            toast.success('Your message to this contact has been updated.');
        } catch (error) {
            commit('setErrors', error.response);
        }
    },

    async updateAll ({ commit, dispatch }, { campaignId, payload }) {
        try {
            await services.deliveries.updateAll(campaignId, payload);
            toast.success('Your message has been updated.');
            await dispatch('refreshDeliveries', { campaignId });
        } catch (error) {
            commit('setErrors', error.response);
        }
    },

    async delete ({ commit }, { delivery }) {
        try {
            await services.deliveries.deleteDelivery(delivery.id);
            commit('destroyDelivery', delivery);

            toast.success('Your contact has been removed.');
        } catch (error) {
            handleErrors(error, commit);
        }
    }
};

export const mutations = {
    setDelivery (state, delivery) {
        state.selected = delivery;
    },
    appendDeliveries (state, deliveries = []) {
        state.deliveries = state.deliveries.concat(...deliveries);
    },
    resetState (state) {
        Object.assign(state, { ...DefaultState });
    },
    setMeta (state, meta) {
        state.meta = meta;
    },
    setQuery (state, query) {
        state.query = query;
    },
    updateDelivery (state, delivery) {
        const deliveryIndex = state.deliveries.findIndex(current => current.id === state.selected.id);
        state.deliveries[deliveryIndex] = delivery;
    },
    destroyDelivery (state, delivery) {
        const deliveryIndex = state.deliveries.findIndex(d => d.id === delivery.id);
        state.deliveries.splice(deliveryIndex, 1);
    },
    setFormErrors (state, errors) {
        state.errors = errors;
    },
    setPreviousRequestedPage (state, previousRequestedPage) {
        state.previousRequestedPage = previousRequestedPage;
    }
};

export default { namespaced: true, state, actions, mutations };
