import ApiClient from '@services/Http/Api';

export const create = (data) => {
    data.account_ids = data.accounts.map(account => account.id);

    return ApiClient.upload('/dashboard/admin/collaterals', data);
};

export const fetch = async (collateralId) => {
    return await ApiClient.get(`/dashboard/admin/collaterals/${collateralId}`);
};

export const list = (params = {}) => {
    return ApiClient.get('/dashboard/admin/collaterals', { params });
};

export const listForAccount = (accountId) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/collaterals`);
};

export const update = (collateralId, data) => {
    data.account_ids = data.accounts.map(account => account.id);
    data._method = 'patch';

    return ApiClient.upload(`/dashboard/admin/collaterals/${collateralId}`, data);
};

export const destroy = (collateralId) => {
    return ApiClient.destroy(`/dashboard/admin/collaterals/${collateralId}`);
};
