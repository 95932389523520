<template>
    <div>
        <div class="text-center font-bold py-4">
            {{ heading }}
        </div>
        <div
            class="flex gap-x-2 mx-auto"
            :class="video ? 'max-w-xl' : 'max-w-md'"
        >
            <div class="flex-1">
                <v-input v-model="link" :readonly="!!video" label="https://vimeo.com/XXXXXXXXX" />
                <form-errors :errors="error" />
            </div>
            <div>
                <template v-if="video">
                    <v-button
                        ref="build-article"
                        button-style="primary"
                        label="Build Video"
                        @click="buildArticle"
                    />
                    <v-button
                        ref="cancel-button"
                        label="Cancel"
                        @click="cancel"
                    />
                </template>
                <v-button
                    v-else
                    ref="find-video"
                    :disabled="loading || !link"
                    button-style="primary"
                    :label="loading ? 'Finding...' : 'Find Video'"
                    @click="findVideo"
                />
            </div>
        </div>
        <div v-if="loading" class="flex justify-center mt-4">
            <v-loader />
        </div>
        <div v-if="video" class="aspect-w-16 aspect-h-9 mt-4">
            <iframe
                :src="videoEmbedLink"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
            ></iframe>
        </div>
    </div>
</template>

<script>
import VInput from '@outreach/components/inputs/VInput';
import VButton from '@outreach/components/VButton';
import services from '@services/Api';
import FormErrors from '@outreach/components/shared/FormErrors';
import { Alert } from '@services/Alert';
import VLoader from '@outreach/components/VLoader';
import { split } from 'lodash';

export default {
    name: 'VideoArticleForm',
    components: { VInput, VButton, FormErrors, VLoader },
    data () {
        return {
            loading: false,
            error: [],
            link: null,
            video: null
        };
    },
    computed: {
        heading () {
            return this.video ? 'We found it!' : 'Please enter a Vimeo link';
        },
        videoEmbedLink () {
            const videoId = split(this.link, '/', 4).pop();
            const hash = split(this.link, '/', 5).pop();
            return `https://player.vimeo.com/video/${videoId}?h=${hash}`;
        }
    },
    methods: {
        async findVideo () {
            this.loading = true;
            this.error = [];
            const videoId = split(this.link, '/', 4).pop();
            try {
                const { data } = await services.articles.getVideo(videoId);
                this.video = data.data;
            } catch (e) {
                this.error = ['Could not find your video, please try again.'];
                await Alert.error('Could not find your video, please try again.');
            }

            this.loading = false;
        },
        buildArticle () {
            this.$emit('create', this.video);
        },
        cancel () {
            this.video = null;
            this.link = null;
            this.error = [];
            this.loading = false;
        }
    }
};
</script>
