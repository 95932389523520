<template>
    <div class="w-full border border-gray-400 rounded">
        <div
            ref="dropzone"
            class="p-6 flex flex-col items-center justify-center cursor-pointer min-h-dropzone"
            :class="{ 'opacity-50': isDragEnter }"
        >
            <div class="dz-message needsclick pointer-events-none flex flex-col justify-center items-center">
                <img
                    v-if="isImage"
                    ref="preview-image"
                    class="w-48 m-1 mb-2"
                    :src="preview"
                    alt="Preview"
                />
                <i v-else class="text-gray-400 fa fa-paperclip text-4xl mb-2"></i>
                <span class="text-gray-800 text-center">{{ label }}</span>
            </div>
        </div>
        <p v-for="(error, index) in errorMessages" :key="index" class="text-red-400 text-xs mb-2">{{ error }}</p>
    </div>
</template>

<script>
import Dropzone from 'dropzone';
import { fileToBase64 } from '@services/Utils';

export default {
    props: {
        accepted: { type: String, default: '.csv' },
        label: { type: String, default: 'Drop file here or click to upload' },
        errors: { type: [Array, String], required: false, default: () => [] },
        defaultValue: { type: String, default: null },
        type: { type: String, default: 'file' }
    },
    data () {
        return {
            dropzone: null,
            preview: this.defaultValue,
            options: {
                url: '/',
                acceptedFiles: this.accepted,
                autoProcessQueue: false,
                previewTemplate: `
                <div class="dz-preview dz-file-preview m-2">
                    <div class="dz-details p-2 block rounded border border-solid border-gray-500 flex">
                        <div class="dz-filename text-sm  text-gray-800"><span data-dz-name></span></div>

                        <div class="self-center ml-2" data-dz-remove>
                            <svg
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 11.5L6.15874 5.84126L10.292 1.01621M1 1.01621L10.292 11.5L1 1.01621Z"
                                    stroke="#808080"
                                />
                            </svg>
                        </div>
                    </div>

                    <div class="dz-error-message"><span data-dz-errormessage></span></div>
                </div>
                `,
                dragenter: () => {
                    this.isDragEnter = true;
                },
                dragleave: () => {
                    this.isDragEnter = false;
                },
                accept: async (file, done) => {
                    if (this.dropzone.files.length > 1) {
                        this.dropzone.removeFile(this.dropzone.files[0]);
                    }
                    this.isDragEnter = false;
                    this.preview = await fileToBase64(file);

                    this.$emit('add-file', file);
                    done();
                },
                error: (file) => {
                    this.dropzone.removeFile(file);
                }
            },
            isDragEnter: false
        };
    },
    computed: {
        hasFiles () {
            if (!this.dropzone) return;
            return !!this.dropzone.files.length;
        },
        errorMessages () {
            return Array.isArray(this.errors) ? this.errors : [this.errors];
        },
        isImage () {
            return this.preview && this.type === 'image';
        }
    },
    mounted () {
        this.dropzone = new Dropzone(this.$refs.dropzone, this.options);
    }
};
</script>

<style scoped lang="scss">
@import "~dropzone/dist/dropzone.css";
</style>
