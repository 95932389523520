import ComponentGallery from '@outreach/pages/ComponentGallery';
import Login from '@outreach/pages/Login';
import SignedLinkHandler from '@outreach/pages/SignedLinkHandler';
import ForgotPassword from '@outreach/pages/ForgotPassword';
import PasswordReset from '@outreach/pages/PasswordReset';
import AcceptInvitation from '@outreach/pages/AcceptInvitation';
import ViewContact from '@outreach/pages/ViewContact';
import PageNotFound from '@outreach/pages/PageNotFound';
import Callback from '@outreach/pages/Callback';
import Contacts from '@outreach/pages/Contacts';
import CampaignDashboardContainer from '@outreach/pages/CampaignDashboard/CampaignDashboardContainer';
import CampaignContacts from '@outreach/pages/CampaignContacts';
import CampaignComposeContainer from '@outreach/pages/CampaignCompose/CampaignComposeContainer';
import CampaignReviewContainer from '@outreach/pages/CampaignReview/CampaignReviewContainer';
import QuickSendContainer from '@outreach/pages/QuickSend/QuickSendContainer';
import DeliverySummaryContainer from '@outreach/pages/account/DeliverySummaryContainer';
import DeliverySummaryPreview from '@outreach/pages/account/DeliverySummaryPreview';
import Integrations from '@outreach/pages/Integrations';
import Guides from '@outreach/pages/guides';
import ShopifySetupGuide from '@outreach/pages/guides/ShopifySetupGuide';
import QrScans from '@outreach/pages/account/QrScans';
import UpdateContact from '@outreach/components/contacts/UpdateContact';
import ModifyTeam from '@outreach/pages/admin/teams/ModifyTeam';
import Reports from '@outreach/pages/Reports';
import ServerError from '@outreach/pages/ServerError';
import PersonalAccessTokens from '@outreach/pages/PersonalAccessTokens';
import salesRoutes from '@router/salesRoutes';
import accountRoutes from '@router/accountRoutes';
import campaignRoutes from '@router/campaignRoutes';
import adminRoutes from '@router/adminRoutes';
import teamsRoutes from '@router/teamsRoutes';
import CampaignScans from '@outreach/pages/account/CampaignScans';
import ViewCustomers from '@outreach/pages/Customers/ViewCustomers';
import OutboundProspecting from '@outreach/pages/OutboundProspecting/OutboundProspecting';
import TerritoryMap from '@outreach/pages/OutboundProspecting/TerritoryMap.vue';
import store from '@audience/index';
import { Roles } from '@services/Roles';

export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login',
            full_screen: true
        }
    },
    {
        path: '/signed-link-handler',
        name: 'signed-link-handler',
        component: SignedLinkHandler,
        meta: {
            title: 'Signed Link Handler',
            full_screen: true
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            title: 'Forgot Password',
            full_screen: true
        }
    },
    {
        path: '/password/reset',
        name: 'password-reset',
        component: PasswordReset,
        meta: {
            title: 'Password Reset',
            full_screen: true
        }
    },
    {
        path: '/invitations/accept/:loginToken',
        name: 'accept-invitation',
        component: AcceptInvitation,
        meta: {
            title: 'Accept Invitation',
            full_screen: true
        }
    },
    {
        path: '/contacts/:id/update',
        name: 'update-contact',
        component: UpdateContact,
        meta: {
            title: 'Update Contact',
            full_screen: true
        }
    },
    {
        path: '/login/:provider/callback',
        name: 'callback',
        component: Callback,
        meta: {
            title: 'Authenticating...',
            full_screen: true
        }
    },
    {
        path: '/send-notes',
        name: 'send-notes',
        component: CampaignDashboardContainer,
        meta: {
            title: 'Dashboard',
            header: false,
            requiresAuth: true
        }
    },
    ...campaignRoutes,
    {
        path: '/reports',
        name: 'reports',
        component: Reports,
        meta: {
            title: 'Reports',
            breadcrumbs: [{ name: 'reports', label: 'Reports' }],
            requiresAuth: true
        }
    },
    {
        path: '/send/dashboard',
        name: 'campaign-dashboard',
        component: CampaignDashboardContainer,
        meta: {
            title: 'Send a Note or Postcard',
            header: false,
            requiresAuth: true
        }
    },
    {
        path: '/component-gallery',
        name: 'component-gallery',
        component: ComponentGallery,
        meta: {
            title: 'Component Gallery',
            breadcrumbs: [{ name: 'component-gallery', label: 'Component Gallery' }],
            requiresAuth: true
        }
    },
    {
        path: '/send',
        name: 'campaign-contacts',
        component: CampaignContacts,
        meta: {
            title: 'Contacts',
            header: false,
            requiresAuth: true
        }
    },
    {
        path: '/send/:campaign/compose',
        name: 'campaign-compose',
        component: CampaignComposeContainer,
        meta: {
            title: 'Compose',
            header: false,
            requiresAuth: true
        }
    },
    {
        path: '/send/:campaign/review',
        name: 'campaign-review',
        component: CampaignReviewContainer,
        meta: {
            title: 'Review',
            header: false,
            requiresAuth: true
        }
    },
    {
        path: '/send/quick',
        name: 'quick-send',
        component: QuickSendContainer,
        meta: {
            title: 'Quick Send',
            header: false,
            requiresAuth: true
        }
    },
    ...adminRoutes,
    ...salesRoutes,
    ...accountRoutes,
    {
        path: '/account/:accountId/contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
            title: 'Contacts',
            breadcrumbs: [
                { name: 'contacts', label: 'Contacts' }
            ],
            requiresAuth: true
        }
    },
    {
        path: '/account/:accountId/contacts/:contact',
        name: 'view-contact',
        component: ViewContact,
        meta: {
            title: 'View Contact',
            breadcrumbs: [
                { name: 'user', label: 'Account' },
                { name: 'contacts', label: 'Contacts' },
                { name: 'view-contact', label: 'View Contact' }
            ],
            requiresAuth: true
        }
    },
    {
        path: '/account/:accountId/delivery-summary',
        name: 'delivery-summary',
        component: DeliverySummaryContainer,
        meta: {
            title: 'Delivery Summary',
            breadcrumbs: [
                { name: 'user', label: 'Account' }
            ],
            requiresAuth: true
        }
    },
    {
        path: '/account/:accountId/delivery-summary-preview',
        name: 'delivery-summary-preview',
        component: DeliverySummaryPreview,
        meta: {
            title: 'Delivery Summary',
            requiresAuth: false,
            full_screen: true
        }
    },
    {
        path: '/integrations',
        name: 'integrations',
        component: Integrations,
        meta: {
            title: 'Integrations',
            requiresAuth: true
        }
    },
    {
        path: '/qr-scans',
        name: 'qr-scans',
        component: CampaignScans,
        meta: {
            title: 'Campaign Scans',
            requiresAuth: true,
            breadcrumbs: [
                { name: 'qr-scans', label: 'Campaign Scans' }
            ]
        }
    },
    {
        path: '/scan-details',
        name: 'scan-details',
        component: QrScans,
        meta: {
            title: 'QR Code Scans',
            requiresAuth: true,
            breadcrumbs: [
                { name: 'qr-scans', label: 'Campaign Scans' },
                { name: 'scan-details', label: 'QR Code Scans' }
            ]
        }
    },
    {
        path: '/guides',
        component: Guides,
        children: [
            {
                path: '/guides/shopify-setup',
                name: 'shopify-setup',
                component: ShopifySetupGuide,
                meta: {
                    title: 'Shopify Setup Guide'
                }
            }
        ]
    },
    ...teamsRoutes,
    {
        path: '/manage-team',
        name: 'manage-team',
        component: ModifyTeam,
        meta: {
            title: 'Manage Team',
            requiresAuth: true,
            breadcrumbs: [
                { name: 'manage-team', label: 'Manage Team' }
            ]
        }
    },
    {
        path: '/personal-access-tokens',
        name: 'personal-access-tokens',
        component: PersonalAccessTokens,
        meta: {
            title: 'Personal Access Tokens',
            requiresAuth: true,
            breadcrumbs: [
                { name: 'personal-access-tokens', label: 'Personal Access Tokens' }
            ]
        }
    },
    {
        path: '*',
        name: 'page-not-found',
        component: PageNotFound,
        meta: {
            title: 'Page Not Found'
        }
    },
    {
        path: '/error',
        name: 'server-error-page',
        component: ServerError,
        meta: {
            title: ''
        }
    },
    {
        path: '/customers',
        name: 'customers',
        component: ViewCustomers,
        meta: {
            title: 'Customers',
            requiresAuth: true,
            breadcrumbs: [
                { name: 'customers', label: 'Customers' }
            ]
        }
    },
    {
        path: '/',
        name: 'send-notes-with-map',
        component: OutboundProspecting,
        alias: ['/prospecting'],
        meta: {
            title: 'Send Notes',
            breadcrumbs: [
                { name: 'send-notes-with-map', label: 'Send Notes With Map' }
            ],
            requiresAuth: true
        },
        beforeEnter: (to, from, next) => {
            const roles = [Roles.CUSTOMER_SERVICE, Roles.ADMIN, Roles.SUPER_ADMIN];
            const module = store.state?.user || {};
            const isStaff = module.user?.roles?.some((r) => roles.includes(r.name)) || module.imposter;
            if (isStaff || module.user?.preferences?.enable_map) {
                next();
            } else {
                next({ name: 'campaign-dashboard' });
            }
        }
    },
    {
        path: '/territories',
        name: 'territories',
        component: TerritoryMap,
        meta: {
            title: 'Territories',
            requiresAuth: true
        }
    },
    {
        path: '/prospecting/:campaign/compose',
        name: 'send-notes-with-map-compose',
        component: CampaignComposeContainer,
        meta: {
            title: 'Send Notes With Map Compose',
            breadcrumbs: [
                { name: 'send-notes-with-map', label: 'Send Notes With Map' },
                { name: 'send-notes-with-map-compose', label: 'Compose Campaign' }
            ],
            requiresAuth: true
        },
        props: {
            steps: ['Select Households', 'Compose', 'Review'],
            reviewRoute: 'send-notes-with-map-review'
        }
    },
    {
        path: '/prospecting/:campaign/review',
        name: 'send-notes-with-map-review',
        component: CampaignReviewContainer,
        meta: {
            title: 'Send Notes With Map Review',
            breadcrumbs: [
                { name: 'send-notes-with-map', label: 'Send Notes With Map' },
                { name: 'send-notes-with-map-compose', label: 'Compose Campaign' },
                { name: 'send-notes-with-map-review', label: 'Review Campaign' }
            ],
            requiresAuth: true
        },
        props: { steps: ['Select Households', 'Compose', 'Review'] }
    }

];
