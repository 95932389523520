"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = exports.NotificationType = void 0;
var NotificationType;
(function (NotificationType) {
    NotificationType["Success"] = "success";
    NotificationType["Warning"] = "warning";
    NotificationType["Error"] = "error";
    NotificationType["Info"] = "info";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
class Notification {
    constructor(notification = {}) {
        this.id = '';
        this.text = '';
        this.read = false;
        this.type = NotificationType.Info;
        this.createdAt = new Date();
        this.updatedAt = new Date();
        this.actions = [];
        Object.assign(this, notification);
    }
    static fromResponseData(value) {
        var _a;
        return new Notification({
            id: value.id,
            read: value.read_at !== null,
            createdAt: new Date(value.created_at),
            updatedAt: new Date(value.updated_at),
            text: value.message,
            type: value.type,
            actions: (_a = value.actions) !== null && _a !== void 0 ? _a : []
        });
    }
    static fromEvent({ id, message, type, actions }) {
        return new Notification({
            id,
            type,
            text: message,
            actions
        });
    }
}
exports.Notification = Notification;
