<template>
    <vue-slider
        :value="value"
        :min="min"
        :max="max"
        :min-range="minRange"
        :max-range="maxRange"
        :interval="interval"
        :disabled="disabled"
        :enable-cross="enableCross"
        :silent="true"
        :dot-style="{ backgroundColor: brandColor}"
        :tooltip-style="{
            backgroundColor: brandColor,
            borderColor: brandColor
        }"
        :process-style="{ backgroundColor: brandColor }"
        :data="data"
        :marks="{}"
        @change="$emit('input', $event)"
    >
        <template #tooltip="{ value:tooltip }">
            <div class="vue-slider-dot-tooltip vue-slider-dot-tooltip-top">
                <div class="vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-top">
                    <span class="vue-slider-dot-tooltip-text">{{ tooltipValue(tooltip) }}</span>
                </div>
            </div>
        </template>
    </vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component';
import { brandColor } from '@global/config';
import { find } from 'lodash';

export default {
    components: {
        VueSlider
    },
    props: {
        value: { type: [Number, Array], default: 0 },
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100 },
        enableCross: { type: Boolean, default: true },
        minRange: { type: Number, default: null },
        maxRange: { type: Number, default: null },
        disabled: { type: Boolean, default: false },
        interval: { type: Number, default: 1 },
        data: { type: [Array], default: undefined }
    },
    data: () => ({
        brandColor
    }),
    methods: {
        tooltipValue (value) {
            return (value || value === 0) ? value : find(this.data, { value })?.label;
        }
    }
};
</script>

<style scoped>
.vue-slider-dot-tooltip-inner {
  @apply bg-brand-regular border-brand-regular;
}
</style>
