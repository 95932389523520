export default class Account {
    constructor (data) {
        Object.assign(this, data);
    }

    getNavItemUrl (name) {
        if (!this.nav_items) return '';
        const navItem = this.nav_items.find(item => item.name === name);
        return navItem ? navItem.location : '';
    }
}
