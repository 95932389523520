<template>
    <div class="bg-white shadow-md rounded flex flex-col">
        <div
            v-if="hasHeader"
            class="flex justify-between items-center px-5 py-3 rounded-t bg-gray-100 border-b border-gray-300"
        >
            <h2 class="text-md text-gray-800 font-semibold"><slot name="header"></slot></h2>
            <slot name="header-actions"></slot>
        </div>

        <div class="flex flex-col flex-1" :class="{ 'p-5': hasBodyPadding }">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hasHeader: { type: Boolean, default: false },
        hasBodyPadding: { type: Boolean, default: true }
    }
};
</script>
