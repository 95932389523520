import get from 'lodash/get';
import Vue from 'vue';
import { getAccessToken, removeAccessToken, setAccessToken, setImposter } from '../Api/Authentication';
import { refreshTokenAndReattemptRequest } from './RefreshToken';
import Qs from 'qs';
import store from '@audience';
import axios from 'axios';
import { MockInterceptor } from '@services/Http/MockInterceptor';

const mockInterceptor = new MockInterceptor();

let showingRefreshToast = false;

export class Interceptors {
    constructor (client) {
        this.client = client;
        this.setupInterceptors();
    }

    setupInterceptors () {
        this.client.interceptors.request.use(this.requestFulfilled.bind(this), this.requestFailed.bind(this));
        this.client.interceptors.response.use(this.responseFulfilled.bind(this), this.responseFailed.bind(this));
        this.client.interceptors.request.use(
            mockInterceptor.requestFulfilled.bind(mockInterceptor),
            mockInterceptor.requestFailed.bind(mockInterceptor)
        );
        this.client.interceptors.response.use(
            mockInterceptor.responseFulfilled.bind(mockInterceptor),
            mockInterceptor.responseFailed.bind(mockInterceptor)
        );
    }

    requestFulfilled (config) {
        const token = getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            setImposter(token);
        }
        store.dispatch('loader/pending');

        config.paramsSerializer = params => {
            return Qs.stringify(params, {
                arrayFormat: 'brackets',
                encode: false
            });
        };

        return config;
    }

    requestFailed (error) {
        store.dispatch('loader/done');
        return Promise.reject(error);
    }

    responseFulfilled (response) {
        store.dispatch('loader/done');
        const token = get(response, 'data.data.token.access_token');
        if (token) {
            setAccessToken(token);
        }
        const version = get(response, 'headers.audience-version');
        if (version && version !== process.env.COMMIT_HASH && !showingRefreshToast) {
            showingRefreshToast = true;
            Vue.prototype.$toasted.show('A new version of Audience is available. Please refresh your browser.', {
                type: 'info',
                icon: 'fa-arrows-rotate',
                duration: null,
                singleton: true,
                action: {
                    text: 'Refresh',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                        window.location.reload();
                    }
                },
                onComplete: () => {
                    showingRefreshToast = false;
                }
            });
        }
        return response;
    }

    responseFailed (error) {
        if (!error.response) {
            if (axios.isCancel(error)) {
                store.dispatch('loader/cancel');
            }
            return Promise.reject(error);
        }
        const { response: { status, data } } = error;

        store.dispatch('loader/done');

        if (status === 401) {
            return refreshTokenAndReattemptRequest(error, this.client)
                .catch(() => {
                    removeAccessToken();
                    this.redirectToLogin(error);
                });
        }

        if (get(data, 'message') && Vue.prototype.$toasted) {
            Vue.prototype.$toasted.error(get(data, 'message'), {
                className: 'bg-red'
            });
        }

        return Promise.reject(error);
    }

    redirectToLogin (error) {
        if (error.config.url.includes('/me')) return;

        const search = new URLSearchParams({ redirect: window.location.pathname });

        window.location.href = `/login?${search}`;
    }
}
