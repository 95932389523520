<template>
    <div class="w-full">
        <steps
            class="text-xs truncate lg:text-base"
            :steps="['Select Households', 'Compose', 'Review']"
            value="Select Households"
        />
        <v-card class="w-full relative">
            <template #body>
                <loading-overlay v-if="loading" />
                <div class="flex flex-col-reverse gap-y-3 lg:flex-row lg:gap-y-0 lg:gap-x-3">
                    <div class="flex flex-col pt-2 lg:w-1/3 lg:p-0">
                        <tab-view :tabs="tabs" @tab-changed="onTabChanged">
                            <template #address>
                                <google-auto-complete
                                    v-if="map"
                                    :map="map"
                                    @selected="onPlaceSelected"
                                    @autofilled="onAddressAutofilled"
                                />
                                <radius-selector v-model="radius" :max="5" class="mt-4" @input="resetCounts" />
                            </template>
                        </tab-view>

                        <div class="mb-6">
                            <property-map-options
                                class="pt-6 border-y border-gray-300 mt-6"
                                :form-errors="formErrors"
                                :property="matchedProperty"
                                :comparables.sync="comparables"
                                @update="resetCounts(); filters = $event;"
                            />

                            <button
                                id="search-button"
                                class="bg-brand-regular text-white px-4 py-2 rounded mt-6 w-full"
                                :disabled="searchDisabled"
                                :class="{'cursor-not-allowed opacity-75' : searchDisabled}"
                                @click="searchProperties"
                            >
                                Search This Area For Properties
                            </button>

                        </div>

                        <transition name="fade" mode="out-in">
                            <div class="flex flex-col flex-1 mt-4">
                                <div v-if="!hasResults" class="flex flex-1 flex-col">
                                    <p class="text-xl font-bold mt-2">
                                        No Results
                                    </p>
                                </div>
                                <div v-else class="flex flex-1 flex-col">
                                    <label class="flex w-full items-center">
                                        <p class="mr-4">Maximum</p>
                                        <div class="flex-1">
                                            <v-input
                                                v-model="maximum"
                                                type="number"
                                                label="Maximum"
                                                :errors="formErrors.maximum"
                                                :disabled="!propertyCount"
                                                :max="propertyCount"
                                                :min="0"
                                            />
                                        </div>
                                    </label>
                                    <div class="text-xl font-bold mt-2 leading-none flex flex-wrap">
                                        <div class="mr-2">Available Households:</div>
                                        <div>{{ propertyCount }}</div>
                                    </div>
                                </div>
                                <v-clipboard
                                    v-if="propertyCount"
                                    class="text-sm"
                                    text="Copy Share Link"
                                    :copy="shareableLink"
                                />
                                <button
                                    class="bg-brand-regular text-white px-4 py-2 rounded mt-6"
                                    :class="{ 'cursor-not-allowed opacity-75' : underLimit }"
                                    :disabled="underLimit"
                                    @click="generateCampaign"
                                >
                                    Next
                                </button>
                            </div>
                        </transition>
                    </div>
                    <div class="w-full lg:w-2/3">
                        <prospect-map
                            v-if="loaded"
                            :radius="radius"
                            :place="place"
                            :results="results"
                            :is-drawing="isDrawing"
                            @init="map = $event"
                            @searchDetails="onSearchDetails"
                            @initialPath="searchProperties"
                            @circleMoved="resetCounts"
                        />
                    </div>
                </div>
            </template>
        </v-card>
    </div>
</template>

<script>
import GoogleAutoComplete from '@outreach/pages/OutboundProspecting/GoogleAutoComplete';
import RadiusSelector from '@outreach/pages/OutboundProspecting/RadiusSelector';
import PropertyMapOptions from '@outreach/pages/OutboundProspecting/PropertyMapOptions';
import VCard from '@outreach/components/VCard';
import Steps from '@outreach/pages/Steps';
import ProspectMap from '@outreach/pages/OutboundProspecting/ProspectMap';
import services from '@services/Api';
import { Alert } from '@services/Alert';
import { mapState } from 'vuex';
import { get } from 'lodash';
import LoadingOverlay from '@outreach/components/shared/LoadingOverlay';
import VInput from '@outreach/components/inputs/VInput';
import VClipboard from '@outreach/components/shared/VClipboard';
import { formatNumber } from '@services/Utils';
import TabView from '@outreach/components/shared/TabView';
import { groupEstates } from '@models/Estate';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { loadGoogleMaps } from '@services/GoogleMaps';
import Place from '@outreach/pages/OutboundProspecting/Place';
import PolygonPath from '@outreach/pages/OutboundProspecting/PolygonPath';
import { GoogleService } from '@outreach/pages/OutboundProspecting/GoogleService';

const MAX_SQ_MILES = 500;
export default {
    name: 'OutboundProspecting',
    components: {
        LoadingOverlay,
        VCard,
        PropertyMapOptions,
        RadiusSelector,
        GoogleAutoComplete,
        ProspectMap,
        Steps,
        VInput,
        VClipboard,
        TabView
    },
    data () {
        return {
            map: null,
            place: null,
            radius: 0.25,
            propertyCount: 0,
            loaded: false,
            maximum: 0,
            minimum: 50,
            path: null,
            centroid: null,
            sqMiles: null,
            filters: undefined,
            formErrors: {},
            hasResults: true,
            results: [],
            lastQueryId: null,
            loading: false,
            claimed: 0,
            tab: 'address',
            tabs: ['Address', 'Draw'],
            matchedProperty: undefined,
            comparables: false,
            service: new GoogleService()
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        shareableLink () {
            const params = {};

            if (this.place) { params.address = this.place.formatted_address; }
            if (this.path) { params.path = this.path; }
            if (this.filters.est_value.min) { params['est_value.min'] = this.filters.est_value.min; }
            if (this.filters.est_value.max) { params['est_value.max'] = this.filters.est_value.max; }
            params.radius = this.radius;

            return `${window.location.origin}/prospecting?${(new URLSearchParams(params)).toString()}`;
        },
        searchDisabled () {
            return !this.path || this.loading;
        },
        isDrawing () {
            return this.tab === 'draw';
        },
        underLimit () {
            return this.maximum < this.minimum;
        }
    },
    watch: {
        path () {
            this.resetCounts();
        },
        maximum (newVal) {
            delete this.formErrors.maximum;
            if (newVal < this.minimum) {
                this.formErrors.maximum = `At least ${this.minimum} properties must be selected to generate a campaign`;
            }
        },
        comparables () {
            if (this.place && this.comparables) {
                this.findMatchingProperty(this.place);
            }
        },
        filters: {
            deep: true,
            handler () {
                if (this.path) {
                    this.searchProperties();
                }
            }
        }
    },
    async mounted () {
        await loadGoogleMaps();
        this.loaded = true;
        this.prefillSearch();
    },
    methods: {
        searchProperties: debounce(async function () {
            if (this.sqMiles > MAX_SQ_MILES) {
                await Alert.warning(
                    `The selected search area of ${formatNumber(this.sqMiles)} square miles is too large. \
                    Please narrow the search area`
                );
                return;
            }
            try {
                this.loading = true;
                const { data } = await this.fetchEstates();
                this.propertyCount = Number(data.data.length);
                this.maximum = this.propertyCount;
                this.hasResults = !!this.maximum;
                this.results = groupEstates(data.data);
                this.lastQueryId = data.query_id;
                this.claimed = data.claimed_count;
                window.scrollTo(0, 0);
            } catch (e) {
                if (axios.isCancel(e)) { return; }
                this.formErrors = get(e, 'response.data.errors', {});
                await Alert.error('Could not search properties, please try again.');
            } finally {
                this.loading = false;
            }
        }, 200),
        onTabChanged (tab) {
            this.tab = tab;
            this.resetCounts();
            this.place = null;
            this.path = null;
        },
        async fetchEstates (params) {
            this.formErrors = {};
            return await services.prospecting.estateList({
                ...this.filters,
                path: this.path,
                centroid: { lat: this.centroid?.lat(), lng: this.centroid?.lng() },
                ...params
            });
        },
        async generateCampaign () {
            try {
                this.loading = true;
                const { data } = await services.prospecting.createFromEstates({
                    limit: this.maximum,
                    query_id: this.lastQueryId
                });

                await this.$router.push({
                    name: 'send-notes-with-map-compose',
                    params: { campaign: data.data.id }
                });
            } catch (e) {
                await Alert.fromError(e, 'Could not create campaign, please try again.');
            } finally {
                this.loading = false;
            }
        },
        resetCounts () {
            this.propertyCount = 0;
            this.maximum = 0;
            this.claimed = 0;
            this.results = [];
        },
        prefillSearch () {
            this.radius = parseFloat(this.$route.query.radius ?? this.radius);
            this.filters = {
                est_value: {
                    min: this.$route.query['est_value.min'] || undefined,
                    max: this.$route.query['est_value.max'] || undefined
                }
            };
        },
        onAddressAutofilled (place) {
            this.place = place;
            this.$nextTick(() => { this.searchProperties(); });
        },
        onSearchDetails (details) {
            if (details.path && details.path.split(';').length < 3) {
                Alert.error('Please select a larger area');
                return;
            }
            this.path = details.path;
            this.centroid = details.centroid;
            this.sqMiles = details.sqMiles;
            if (details.path) {
                this.searchProperties();
            }
        },
        async findMatchingProperty (place) {
            if (!this.comparables) {
                this.matchedProperty = undefined;
                return;
            }

            if (!place.address_components) {
                place = await this.service.getPlace(place.place_id, this.map);
            }

            const centroid = new Place(place).toCentroid();
            const path = PolygonPath.fromRadius(centroid, this.radius).toString();
            const { data } = await this.fetchEstates({
                limit: 1,
                path,
                centroid,
                address: new Place(place).toString()
            });
            this.matchedProperty = data.data[0];
        },
        async onPlaceSelected (place) {
            await this.findMatchingProperty(place);
            this.resetCounts();
            this.place = place;
        }
    }
};
</script>
