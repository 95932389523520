<template>
    <div v-if="messages.length > 0">
        <div
            v-for="(message, index) in messages"
            :key="index"
            class="rounded-md p-4"
            :class="VALIDATION_TYPES[message.messageType].background"
        >
            <div class="flex">
                <div class="shrink-0">
                    <i
                        class="fa fa-info-circle"
                        :class="VALIDATION_TYPES[message.messageType].icon"
                    ></i>
                </div>
                <div class="ml-3">
                    <div :class="VALIDATION_TYPES[message.messageType].text">
                        <ul v-if="Array.isArray(message.message)">
                            <li v-for="(point, i) in message.message" :key="i" v-html="point"></li>
                        </ul>
                        <span v-else v-html="message.message"></span>
                    </div>
                </div>
                <div class="ml-auto pl-3">
                    <div class="-mx-2 -my-2">
                        <button
                            ref="close-alert"
                            type="button"
                            class="inline-flex rounded-md p-2"
                            :class="VALIDATION_TYPES[message.messageType].icon"
                            @click="clearMessage(index)"
                        >
                            <span class="sr-only ">Dismiss</span>
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import WebAlert from '@events/WebAlert';
import { Alert } from '@services/Alert';

export default {
    name: 'Alert',
    computed: {
        ...mapState({
            messages: ({ alerts }) => alerts.messages,
            subscriptions: ({ events }) => events.subscriptions
        }),
        VALIDATION_TYPES () {
            return {
                success: {
                    background: 'bg-green-100',
                    icon: 'text-green-400',
                    text: 'text-green-800'
                },
                warning: {
                    background: 'bg-yellow-100',
                    icon: 'text-yellow-400',
                    text: 'text-yellow-800'
                },
                info: {
                    background: 'bg-blue-100',
                    icon: 'text-blue-400',
                    text: 'text-blue-800'
                },
                error: {
                    background: 'bg-red-100',
                    icon: 'text-red-400',
                    text: 'text-red-800'
                }
            };
        }

    },
    created () {
        if (!this.subscriptions[WebAlert.name]) {
            this.subscribe({
                event: WebAlert,
                subscription: (context, event) => {
                    Alert[event.type](event.message, 0);
                }
            });
        }
    },
    methods: {
        ...mapActions({
            clearMessage: 'alerts/clearMessage',
            subscribe: 'events/subscribe'
        })
    }
};
</script>

<style scoped>

</style>
