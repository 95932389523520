<template>
    <div class="bg-gray-100 w-full min-h-screen p-4">
        <div v-if="contact" class="w-full max-w-lg mx-auto sm:mt-8">
            <div class="mb-6 text-center">
                <h2 class="text-xl font-bold text-gray-800">{{ contact.owner_name }} wants your contact info!</h2>
            </div>
            <div
                v-if="submitted"
                class="bg-white rounded-lg p-10 shadow justify-between text-center"
            >
                <div>
                    <svg class="mb-4 h-20 w-20 text-green-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" :d="path.d" clip-rule="evenodd" />
                    </svg>
                    <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">Your information was updated!</h2>
                    <div class="text-gray-600 mb-8">
                        Thank you. You'll hear from {{ contact.owner_name }} soon!
                    </div>
                </div>
            </div>
            <form
                v-else
                ref="contact-form"
                class="bg-white shadow rounded p-10"
                @submit.prevent="update"
            >
                <h3 class="text-lg mb-6 font-medium text-gray-800 mx-auto">Profile Information</h3>

                <v-input
                    ref="name-input"
                    v-model="contact.name"
                    class="mb-6"
                    label="Name"
                    :errors="formErrors['name']"
                />

                <v-input
                    ref="email-input"
                    v-model="contact.email"
                    class="mb-6"
                    label="Email"
                    :errors="formErrors['email']"
                />

                <h3 class="text-lg mb-6 font-medium text-gray-800 mx-auto">Address Information</h3>

                <v-input
                    ref="address1-input"
                    v-model="contact.address.address1"
                    class="mb-6"
                    label="Address"
                    :errors="formErrors['address.address1']"
                />
                <v-input
                    v-model="contact.address.address2"
                    class="mb-6"
                    label="Address2"
                    :errors="formErrors['address.address2']"
                />
                <v-input
                    v-model="contact.address.city"
                    class="mb-6"
                    label="City"
                    :errors="formErrors['address.city']"
                />
                <v-input
                    v-model="contact.address.state"
                    class="mb-6"
                    label="State"
                    :errors="formErrors['address.state']"
                />
                <v-input
                    v-model="contact.address.postcode"
                    class="mb-6"
                    label="Postal Code"
                    :errors="formErrors['address.postcode']"
                />
                <v-input
                    v-model="contact.address.country"
                    class="mb-6"
                    label="Country"
                    :errors="formErrors['address.country']"
                />
                <v-button
                    ref="submit-button"
                    :loading="loading"
                    label="Save"
                    button-style="primary"
                    @click="update"
                />
            </form>
        </div>
        <div v-else class="p-8">
            <v-loader />
        </div>
    </div>
</template>

<script>
import services from '@services/Api';
import VLoader from '@outreach/components/VLoader';
import { mapState } from 'vuex';
import get from 'lodash/get';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    name: 'UpdateContact',
    components: { VLoader, VButton, VInput },
    data () {
        return {
            contact: null,
            formErrors: {},
            submitted: false,
            path: {
                // eslint-disable-next-line vue/max-len
                d: 'M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
            }
        };
    },
    computed: {
        ...mapState({
            loading: ({ loader }) => loader.loading
        })
    },
    created () {
        this.fetch();
    },
    methods: {
        async fetch () {
            const { data } = await services.contacts.publicContact(this.$route.params.id, {
                signature: window.location.href
            });
            this.contact = data.data;
        },
        async update () {
            this.formErrors = {};
            try {
                await services.contacts.updatePublicContact(this.$route.params.id, {
                    ...this.contact,
                    signature: window.location.href
                });
                this.submitted = true;
                this.$toasted.success('Contact saved.');
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
                throw new ClientVisibleError('Failed to update contact.');
            }
        }
    }

};
</script>
