<template>
    <tab-view :tabs="tabs">
        <template #pending>
            <single-jobs-table key="pending-table" title="Pending Jobs" status="pending" />
        </template>
        <template #reserved>
            <single-jobs-table key="reserved-table" title="Reserved Jobs" status="reserved" />
        </template>
        <template #completed>
            <single-jobs-table key="completed-table" status="completed" title="Completed Jobs" />
        </template>
    </tab-view>
</template>

<script>
import SingleJobsTable from '@outreach/pages/admin/SingleJobsTable';
import TabView from '@outreach/components/shared/TabView';

export default {
    name: 'SingleJobsQueue',
    components: { TabView, SingleJobsTable },
    data: () => ({
        tabs: ['Pending', 'Reserved', 'Completed']
    })
};
</script>
