<template>
    <div v-click-away="reset" class="relative">
        <v-input
            :value="value"
            :loading="loading"
            autocomplete="off"
            :label="label"
            @focus="show = true"
            @input="$emit('input', $event)"
        />

        <slide-transition>
            <div
                v-if="show"

                class="p-2 rounded-b bg-white shadow overflow-auto absolute z-40 max-h-64 top-12 w-full"
            >
                <div v-if="!loading && categories.length === 0">No Results Found</div>
                <v-loader v-if="loading" />
                <div
                    v-for="category in categories"
                    :id="`category-${category}`"
                    :key="`category-${category}`"
                    class="mt-3"
                >
                    <p class="text-gray-600 text-xs uppercase"> {{ category }} </p>
                    <div
                        v-for="(search, index) in getSearches(category)"
                        :key="`search-${index}`"
                        class="cursor-pointer hover:bg-gray-100 py-2 px-2 rounded text-sm leading-none"
                        @click="setInput(search)"
                    >
                        {{ search.text }}
                    </div>
                </div>
            </div>
        </slide-transition>
    </div>
</template>

<script>
import VInput from '@outreach/components/inputs/VInput.vue';
import { uniq } from 'lodash';
import SlideTransition from '@outreach/components/shared/SlideTransition.vue';
import VLoader from '@outreach/components/VLoader.vue';
import { mixin as clickAway } from '@outreach/mixins/clickAway';

export default {
    name: 'CategorizedSearch',
    components: { SlideTransition, VInput, VLoader },
    mixins: [clickAway],
    props: {
        label: { type: String, default: 'Recent Searches' },
        items: { type: Array, required: true },
        value: { type: String, default: undefined },
        loading: { type: Boolean, default: false }
    },
    data () {
        return {
            show: false
        };
    },
    computed: {
        categories () {
            return uniq(this.items.map(search => search.category)).filter(category => category);
        }
    },
    methods: {
        getSearches (category) {
            return this.items.filter(search => search.category === category);
        },
        setInput (value) {
            this.$emit('selected', value);
            this.$emit('input', value.text);
            this.reset();
        },
        reset () {
            this.show = false;
        }
    }
};
</script>
