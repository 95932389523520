<template>
    <div class="flex flex-col gap-4 stale-campaigns">
        <v-table v-if="campaigns.length > 0" :rows="campaigns" :sort="sort" @sort-changed="sortChanged">
            <template #default="{ row }">
                <v-table-column label="Campaign" value="name" sortable>
                    <router-link :to="{name:'campaign-review', params: {campaign: row.id}}">
                        <div class="underline hover:text-brand-regular">{{ row.name }}</div>
                    </router-link>
                </v-table-column>
                <v-table-column label="Account" value="account.name">
                    <div class="font-bold">{{ row.account.name }}</div>
                </v-table-column>
                <v-table-column label="Scheduled" value="send_at">
                    <div v-if="row.send_at">
                        <div>{{ formatDate(row.send_at) }}</div>
                        <div>{{ formatRelativeDateTime(row.send_at) }}</div>
                    </div>
                    <div v-else>--</div>
                </v-table-column>
                <v-table-column label="Created" value="created_at" sortable>
                    <div class="stale-badge" :class="`stale-badge-${translateStatus(row.created_at)}`">
                        {{ formatRelativeDateTime(row.created_at) }}
                    </div>
                </v-table-column>
                <v-table-column label="Contacts" value="contacts_count" sortable>
                    {{ row.contacts_count }}
                </v-table-column>
            </template>
        </v-table>

        <v-loader v-if="loading" />

        <div v-if="campaigns.length === 0 && !loading " class="text-center">
            <p class="text-lg text-gray-700">We couldn't find any stale campaigns.</p>
        </div>

        <v-paginator
            class="mx-auto"
            :pagination="pagination"
            :total="pagination.lastPage"
            :value="pagination.currentPage"
            @input="(page) => fetchCampaigns({ page })"
        />
    </div>
</template>

<script>

import VTable from '@outreach/components/shared/table/VTable.vue';
import VTableColumn from '@outreach/components/shared/table/VTableColumn.vue';
import moment from 'moment';
import { formatDate, formatDateTime, formatRelativeDateTime } from '@services/Filters';
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import VPaginator from '@outreach/components/VPaginator.vue';
import { LengthAwarePaginator } from '@services/LengthAwarePaginator';
import { staleCampaigns } from '@services/Api/Customers';
import { Alert } from '@services/Alert';
import VLoader from '@outreach/components/VLoader.vue';
import CampaignUpdated from '@events/CampaignUpdated';

export default {
    name: 'StaleCampaignsTable',
    components: { VPaginator, VTableColumn, VTable, VLoader },
    props: {
        query: { type: String, default: undefined },
        sort: { type: Object, required: true }
    },
    data: () => ({
        campaigns: [],
        pagination: new LengthAwarePaginator()
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            loading: ({ loader }) => loader.loading
        }),
        moment () {
            return moment;
        }
    },
    watch: {
        query: debounce(async function () {
            await this.fetchCampaigns();
        }, 500),
        sort () {
            this.fetchCampaigns();
        }
    },
    mounted () {
        this.fetchCampaigns();
    },
    created () {
        this.subscribe({
            event: CampaignUpdated,
            subscription: () => this.fetchCampaigns()
        });
    },
    destroyed () {
        this.unsubscribe({ event: CampaignUpdated });
    },
    methods: {
        formatDate,
        formatRelativeDateTime,
        formatDateTime,
        ...mapActions({
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        sortChanged (sort) {
            this.$emit('update:sort', sort);
        },
        async fetchCampaigns (params = {}) {
            try {
                const { data } = await staleCampaigns.list(this.user.id, { ...params, ...this.sort, q: this.query });
                this.campaigns = data.data;
                this.pagination.meta = data.meta;
            } catch (e) {
                Alert.fromError(
                    e,
                    'Oops, we weren\'t able to retrieve the list of campaigns. Please refresh the page and try again.'
                );
            }
        },
        translateStatus (createdAt) {
            const diff = moment().diff(moment(createdAt), 'days');

            switch (true) {
                case diff < 20:
                    return 0;
                case diff < 40:
                    return 1;
                default:
                    return 2;
            }
        }
    }
};
</script>

<style>
.stale-badge {
    @apply inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500;
}

.stale-badge-0 {
    @apply bg-yellow-50 text-yellow-800 ring-yellow-500;
}

.stale-badge-1 {
    @apply bg-orange-50 text-orange-800 ring-orange-500;
}

.stale-badge-2 {
    @apply bg-red-50 text-red-800 ring-red-500;
}
</style>
