<template>
    <div ref="container-element">
        <virtual-list
            :style="{ height: height, overflowY: 'auto'}"
            :data-key="'id'"
            :data-sources="contacts"
            :data-component="component"
            @tobottom="$emit('page-end')"
        >
            <template #item="{item}">
                <card-wrapper :show-actions="false">
                    <contact-card
                        :contact="item"
                        :checkbox-disabled="allSelected"
                        :checked="selected.has(item.id) || allSelected"
                        @select="handleSelected(item)"
                    />
                </card-wrapper>
            </template>
        </virtual-list>
    </div>
</template>

<script>
import VirtualList from 'vue-virtual-scroll-list';
import { mapState } from 'vuex';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import ContactCard from '@outreach/components/contacts/ContactCard.vue';
import CardWrapper from '@outreach/components/CardWrapper.vue';

export default {
    components: { CardWrapper, ContactCard, VirtualList },
    mixins: [hasPermission],
    props: {
        actions: {
            type: Array,
            default: () => ([
                { name: 'view', label: 'View' },
                { name: 'edit', label: 'Edit' },
                { name: 'delete', label: 'Delete' },
                { name: 'send', label: 'Send mail' },
                { name: 'similar', label: 'Find surrounding households' }
            ])
        },
        allSelected: { type: Boolean, default: false },
        contacts: { type: Array, default: null },
        meta: { type: Object, default: null },
        sort: { type: Object, default: null },
        selected: { type: Set, default: new Set() }
    },
    data () {
        return {
            height: 110,
            showNotes: false,
            component: () => null
        };
    },
    computed: {
        ...mapState({ user: ({ user }) => user.user })
    },
    mounted () {
        this.getParentHeight();
        window.addEventListener('resize', this.getParentHeight);
    },
    destroyed () {
        window.removeEventListener('resize', this.getParentHeight);
    },
    methods: {
        getParentHeight () {
            this.height = 110;
            setTimeout(() => {
                const element = this.$refs['container-element'];
                if (element && element.parentElement) {
                    this.height = `${element.parentElement.clientHeight}px`;
                }
            });
        },
        handleSelected (contact) {
            if (this.allSelected) return;
            const values = new Set(this.selected);
            this.selected.has(contact.id) ? values.delete(contact.id) : values.add(contact.id);
            this.$emit('selection-changed', values);
        }
    }
};
</script>

<style scoped>
</style>
