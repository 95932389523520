<template>
    <div class="choose-card">
        <div v-if="cards.length > 0">
            <div v-if="cards.length > 0" class="flex flex-col">
                <div class="mb-2 font-semibold">Please Select a Card for Payment</div>
                <transition-group name="list">
                    <div
                        v-for="card in cards"
                        :key="card.id"
                        class="border rounded flex items-center mb-2 p-4"
                        :class="{
                            'border-brand-regular': selectedCard(card.id),
                            'border-400-grey': !selectedCard(card.id)
                        }"
                    >
                        <label class="flex justify-between cursor-pointer w-full" :for="card.id">
                            <input
                                :id="card.id"
                                class="mr-4"
                                type="radio"
                                name="methods"
                                :value="card.id"
                                :checked="card.id === cardId"
                                @change="$emit('change', parseInt($event.target.value))"
                            />
                            <div class="flex flex-1 justify-between items-center flex-col sm:flex-row">
                                <div class="flex items-center">
                                    <card-icon class="text-gray-600 mr-4" :brand="card.brand" />
                                    <span class="mr-2 text-lg text-gray-800">
                                        &bull;&bull;&bull;&bull;&nbsp;
                                        &bull;&bull;&bull;&bull;&nbsp;
                                        &bull;&bull;&bull;&bull;
                                        <span class="text-sm text-gray-800">
                                            {{ card.last4 }}
                                        </span>
                                    </span>

                                </div>
                                <span v-if="formatExpiryDate(card)" class="text-sm text-gray-600">
                                    {{ formatExpiryDate(card) }}
                                </span>
                            </div>
                        </label>
                        <div class="ml-auto">
                            <button
                                v-tooltip="`Remove Card`"
                                class="small-red-button"
                                @click="removeCard(card)"
                            >
                                <v-loader v-if="loading" :size="16" class="opacity-75" />
                                <i v-else class="fas fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
        <div v-else key="no-cards" class="flex flex-col items-center justify-center py-8">
            <div class="font-medium">No Credit Cards Found on Account</div>
        </div>
        <div>
            <div class="flex items-center gap-2 py-1">
                <div class="font-medium">Add Credit Card</div>
                <div
                    class="w-6 h-6 flex items-center justify-center cursor-pointer rounded-full bg-gray-500 text-white"
                    @click="adding = ! adding"
                >
                    <i class="fa" :class="{'fa-plus': ! adding, 'fa-minus': adding}"></i>
                </div>
            </div>
            <stripe-form v-if="adding" class-name="mb-6" label="Save Card" @update="handleSubmitCard" />
        </div>
    </div>
</template>

<script>
import CardIcon from '@outreach/pages/Payment/CardIcon';
import { mapActions, mapGetters, mapState } from 'vuex';
import VLoader from '@outreach/components/VLoader';
import StripeForm from '@outreach/components/billing/StripeForm.vue';
import services from '@services/Api';
import { Alert } from '@services/Alert';

export default {
    components: { StripeForm, CardIcon, VLoader },
    props: {
        user: { type: Object, required: true },
        cardId: { type: Number, default: undefined }
    },
    data () {
        return {
            adding: false
        };
    },
    computed: {
        ...mapGetters({
            loading: 'loader/isLoading'
        }),
        ...mapState({
            cards: ({ cards }) => cards.list
        })
    },

    async created () {
        await this.getCards(this.user.account_id);
        if (this.cards.length > 0) {
            this.$emit('change', this.cards[0].id);
        }
    },
    methods: {
        ...mapActions({
            getCards: 'cards/getCards',
            deleteCard: 'cards/deleteCard'
        }),
        selectedCard (id) {
            return id === this.cardId;
        },
        formatExpiryDate (card) {
            if (!card.exp_month || !card.exp_year) {
                return null;
            }
            const month = card.exp_month.padStart(2, '0');
            const year = card.exp_year.substr(-2);
            return `${month} / ${year}`;
        },
        async handleSubmitCard (response) {
            try {
                await services.cards.create(this.user.account_id, {
                    token: response.id,
                    card: response.card
                });
                await Alert.success('Card added');
                this.adding = false;
            } catch (e) {
                await this.displayError(e, 'Unable to add card. Please try again.');
            }
            await this.getCards(this.user.account_id);
        },
        async removeCard (card) {
            try {
                await this.deleteCard({ accountId: this.user.account_id, cardId: card.id });
                await Alert.success(`The card [${card.last4}] was removed.`);
            } catch (e) {
                await this.displayError(e, `Failed to remove card [${card.last4}]. Please refresh and try again.`);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.small-red-button {
    &:hover{
      @apply bg-red-500 border-red-500 ;
    }
    @apply m-2 py-2 bg-gray-500 text-white border-solid border border-gray-500 inline-flex;
    @apply justify-center items-center flex-1 transition-all px-3 hover:opacity-90 font-bold;
    @apply focus:outline-none focus:shadow-outline uppercase tracking-wider text-sm rounded-full;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
