<template>
    <div>
        <div class="lg:col-span-2 mb-2 flex-col items-start">
            <div class="grid grid-cols-2 gap-2">
                <v-checkbox
                    v-if="isAllowable('exclude_companies')"
                    id="filter-exclude-companies"
                    v-model="filters.exclude_companies"
                    class="cursor-pointer"
                    label="Exclude LLCs, Corps & Trusts"
                />
                <v-checkbox
                    v-show="isCustomerService()"
                    :value="comparables"
                    label="Best Comparables (Admin Only)"
                    class="cursor-pointer"
                    @input="$emit('update:comparables', $event)"
                />

                <div>
                    <div v-if="isAllowable('owner_occupied')">
                        <h3 class="text-gray-700 font-medium mb-2">Owner Occupied</h3>
                        <toggle-group
                            id="filter-owner-occupied"
                            v-model="filters.owner_occupied"
                            label="Owner Occupied"
                            class="mb-2"
                            :options="oocOpts"
                            :default-selected="(opt) => opt.value === filters.owner_occupied"
                        />
                    </div>
                    <div
                        v-if="isAllowable('beds')"
                        id="filter-beds"
                        class="flex flex-col gap-2 lg:col-span-2"
                    >
                        <h3 class="text-gray-700 font-medium">Beds</h3>
                        <toggle-group
                            id="filter-bedroom-count"
                            v-model="filters.bedroom_count"
                            label="Bedrooms"
                            :options="bedroomOpts"
                            :default-selected="(opts) => opts.value.min === ~~filters.bedroom_count.min"
                        />

                    </div>
                </div>

            </div>

        </div>

        <est-value-dropdown
            v-if="isAllowable('est_value')"
            :min-value.sync="filters.est_value.min"
            :max-value.sync="filters.est_value.max"
            :form-errors="formErrors"
        />
        <sq-ft-dropdown
            v-if="isAllowable('sq_ft')"
            :form-errors="formErrors"
            :min-value.sync="filters.sq_ft.min"
            :max-value.sync="filters.sq_ft.max"
        />
        <div
            v-if="isAllowable('purchase_date')"
            id="filter-purchase-date"
            class="flex flex-col gap-2 lg:col-span-2"
        >
            <h3 class="text-gray-700 font-medium">Current Owner - Yrs in House</h3>
            <count-plus-slider
                v-model="filters.sold_at"
                field="sold_at"
                :count="20"
            />
        </div>

        <div
            v-if="isAllowable('yr_built')"
            id="filter-yr-built"
            class="flex flex-col gap-2 lg:col-span-2"
        >
            <h3 class="text-gray-700 font-medium">Yr Built</h3>
            <count-plus-slider
                v-model="filters.year"
                field="year"
                :min-value="1900"
                :max-value="new Date().getFullYear()"
            />
        </div>
        <div
            v-if="isAllowable('est_equity%')"
            id="filter-est-equity"
            class="flex flex-col gap-2 lg:col-span-2"
        >
            <h3 class="text-gray-700 font-medium">Estimated Equity %</h3>
            <count-plus-slider
                v-model="filters.equity_percent"
                field="equity_percent"
                :min-value="0"
                :max-value="100"
            />
        </div>
    </div>
</template>

<script>
import VCheckbox from '@outreach/components/inputs/VCheckbox.vue';
import RealEstateFilters from '@outreach/pages/OutboundProspecting/RealEstateFilters';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import CountPlusSlider from '@outreach/pages/OutboundProspecting/CountPlusSlider.vue';
import ToggleGroup from '@outreach/components/shared/ToggleGroup.vue';
import EstValueDropdown from '@outreach/components/inputs/EstValueDropdown.vue';
import SqFtDropdown from '@outreach/components/inputs/SqFtDropdown.vue';

export default {
    name: 'RealEstateFiltersForm',
    components: { SqFtDropdown, EstValueDropdown, ToggleGroup, CountPlusSlider, VCheckbox },
    mixins: [hasPermission],
    props: {
        filters: { type: RealEstateFilters, required: true },
        formErrors: { type: Object, default: () => ({}) },
        allowable: { type: Array, default: undefined },
        comparables: { type: Boolean, default: false }
    },
    data: () => ({
        bedroomOpts: [{ label: 'Any', value: {} }].concat(
            Array.from({ length: 5 })
                .map((_, i) => ({ label: `${i + 1}+`, value: { min: i + 1 } }))
        ),
        oocOpts: [
            { label: 'Any', value: undefined },
            { label: 'Yes', value: true },
            { label: 'No', value: false }
        ]
    }),

    methods: {
        isAllowable (field) {
            return this.allowable ? this.allowable.includes(field) : true;
        }
    }
};
</script>
