<template>
    <div
        class="flex flex-col md:flex-row w-full"
        @click="$emit('select', contact.id)"
    >
        <div class="flex flex-row flex-1">
            <div class="flex-0 flex items-center">
                <input
                    ref="select"
                    type="checkbox"
                    :disabled="checkboxDisabled"
                    :checked="checked"
                    class="mr-4"
                />
                <avatar class="rounded-full" :contact="contactModel" style="height: 60px; width: 60px;" />
            </div>
            <div class="flex-1 mx-4">
                <h4 ref="name" class="text-lg">{{ contactModel.name }}</h4>
                <v-address
                    v-if="contactModel.address.id"
                    :address="contactModel.address"
                    inline
                    class="text-gray-600"
                />
                <display-tags ref="tags" :tags="contactModel.tags" />
            </div>
        </div>

        <div
            :class="{'my-4': contactModel.details.length > 0 || contactModel.companyUrl}"
            class="text-gray-600 flex flex-col my-1 md:flex-1 md:my-0"
        >
            <table v-if="contactModel.companyUrl" class="w-full mb-2">
                <tr>
                    <td>Company URL</td>
                    <td class="text-right">
                        <a
                            ref="company-url-button"
                            :href="contactModel.companyUrl"
                            target="_blank"
                            class="text-brand-regular"
                        >
                            {{ contactModel.companyUrl }}
                        </a>
                    </td>
                </tr>
            </table>
            <expandable v-if="contactModel.details.length > 0" :length="contactModel.details.length" :max-height="75">
                <table ref="details" class="border-2 w-full">
                    <tr v-for="[label, value] in contactModel.details" :key="label">
                        <td class="bg-gray-100 border px-1 text-sm">{{ label }}</td>
                        <td class="border px-1">
                            <truncate-with-tooltip :value="value" :length="150" />
                        </td>
                    </tr>
                </table>
            </expandable>
        </div>

        <div class="flex flex-col items-center md:mx-4 md:self-center" @click.stop>
            <div class="flex flex-col w-full md:flex-row md:justify-end md:gap-2 md:items-center">
                <mail-to-button
                    v-if="contact.email"
                    :ref="`email-button-${contact.id}`"
                    :disabled="disableEmail"
                    :template-id="user.account.outreach_template.id"
                    :contact-id="contact.id"
                    class="md:mr-2"
                    size="sm"
                />

                <a
                    v-if="contactModel.linkedinUrl"
                    :id="`linkedin-button-${contact.id}`"
                    :href="contactModel.linkedinUrl"
                    target="_blank"
                    :class="{'pointer-events-none': linkedInDisabled}"
                >
                    <v-button
                        class="my-2 w-full md:my-0 md:w-auto"
                        label="LINKEDIN"
                        button-style="info"
                        size="sm"
                        :disabled="linkedInDisabled"
                    >
                        LINKEDIN
                    </v-button>
                </a>
            </div>

            <a
                v-if="showSendAddressRequestButton"
                ref="request-address"
                target="_blank"
                class="w-full md:w-auto"
                @click="requestAddress"
            >
                <v-button
                    class="mb-2 w-full"
                    label="Request Address"
                    button-style="default"
                    size="sm"
                >
                    Request Address
                </v-button>
            </a>
            <p v-if="contact.last_completed" class="text-gray-600 text-sm mt-auto text-center w-full md:w-auto">
                Last Mailed: {{ formatRelativeDateTime(contact.last_completed.completed_at) }}
            </p>
        </div>
        <button
            v-if="allowNoteButton && contact.note"
            ref="show-notes"
            class="show-note-button"
            @click="handleNoteSelect"
        >
            <i class="fa fa-sticky-note"></i>
        </button>
    </div>
</template>

<script>
import Avatar from '@outreach/components/shared/avatar';
import VAddress from '@outreach/components/shared/VAddress';
import VButton from '@outreach/components/VButton';
import { Contact } from '@models/Contact';
import services from '@services/Api';
import { mapState } from 'vuex';
import Expandable from '@outreach/components/shared/Expandable';
import TruncateWithTooltip from '@outreach/components/shared/TruncateWithTooltip';
import { LinkTracker } from '@services/LinkTracker';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { formatRelativeDateTime } from '@services/Filters';
import { lastMailedEmailGracePeriod, lastMailedLinkedinGracePeriod } from '@global/config';
import moment from 'moment';
import DisplayTags from '@outreach/components/shared/DisplayTags';
import MailToButton from '@outreach/components/contacts/MailToButton';

const tracker = new LinkTracker();

export default {
    components: { DisplayTags, TruncateWithTooltip, Expandable, Avatar, VAddress, VButton, MailToButton },
    props: {
        contact: { type: Object, required: true },
        allowNoteButton: { type: Boolean, default: false },
        checked: { type: Boolean, required: true },
        checkboxDisabled: { type: Boolean, default: false }
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        contactModel () {
            return new Contact(this.contact);
        },
        showSendAddressRequestButton () {
            if (!this.user.preferences.request_addresses) {
                return false;
            }
            return !this.contactModel.hasAddress && Boolean(this.contactModel.address_mailto);
        },
        disableEmail () {
            return this.lastMailed ? this.lastMailedLessThanDaysAgo(lastMailedEmailGracePeriod) : false;
        },
        lastMailed () {
            return this.contact.last_completed?.created_at;
        },
        linkedInDisabled () {
            return this.lastMailed ? this.lastMailedLessThanDaysAgo(lastMailedLinkedinGracePeriod) : false;
        }
    },
    methods: {
        lastMailedLessThanDaysAgo (days) {
            return moment().startOf('d').diff(moment(this.lastMailed).startOf('d').local(), 'days') < days;
        },
        formatRelativeDateTime,
        async sendAddressRequest () {
            await services.contacts.requestContactAddress(this.contactModel.id);
            this.$toasted.success('Contact request sent.');
        },
        async confirmRequestAddress (trackingTime) {
            return dialogEmitter.confirm(
                'Request Address',
                `You requested an address ${formatRelativeDateTime(
                    trackingTime)}, are you sure you want to send this again?`
            );
        },
        async requestAddress () {
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = this.contactModel.address_mailto;
            const trackingTime = tracker.get(`request_address_${this.contactModel.id}`);
            if (trackingTime && !await this.confirmRequestAddress(trackingTime)) {
                return;
            }
            tracker.track(`request_address_${this.contactModel.id}`);
            link.click();
        },
        handleNoteSelect () {
            dialogEmitter.openDialog('SimpleDialog', { header: 'Note Details', message: this.contact.note });
        }
    }

};
</script>
