<template>
    <v-button
        ref="export-links"
        label="Export"
        class="ml-2 mr-0 sm:mr-2 flex-1 md:w-1/4"
        button-style="primary"
        @click="exportLinks"
    />
</template>

<script>
import services from '@services/Api';
import VButton from '@outreach/components/VButton';
import { Alert } from '@services/Alert';

export default {
    name: 'ExportLinksButton',
    components: { VButton },
    props: {
        accountId: { type: Number, required: true },
        q: { type: String, default: undefined }
    },
    methods: {
        async exportLinks () {
            try {
                const { headers, data } = await services.links.exportList(this.accountId, { q: this.q });
                const blob = new Blob([data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', headers['content-disposition'].split('=')[1]);
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                await Alert.error('There was an error exporting links, please try again');
            }
        }
    }
};
</script>

<style scoped>

</style>
