import services from '@services/Api';
import debounce from 'lodash/debounce';

export const state = {
    campaigns: [],
    meta: {},
    formErrors: {},
    preview: null
};

export const actions = {
    getCampaigns: debounce(async function ({ commit }, params = {}) {
        const { data } = await services.admin.submittedCampaigns(params);
        commit('setCampaigns', data);
    }, 500),
    archive: async ({ dispatch }, campaignId) => {
        await services.campaigns.destroy(campaignId);
        dispatch('getCampaigns');
    },
    restore: async ({ dispatch }, campaignId) => {
        await services.campaigns.restore(campaignId);
        dispatch('getCampaigns');
    },
    preview: async ({ commit }, campaignId) => {
        const { data } = await services.campaigns.preview(campaignId);
        commit('setPreview', data.data);
    }
};

export const mutations = {
    setCampaigns: (state, payload = {}) => {
        state.campaigns = payload.data;
        state.meta = payload.meta;
    },
    setPreview: (state, payload) => {
        state.preview = payload;
    }
};

export const getters = {
    campaigns: (state) => state.campaigns,
    meta: (state) => state.meta,
    formErrors: (state) => state.formErrors,
    preview: (state) => state.preview
};

export default { namespaced: true, state, actions, mutations, getters };
