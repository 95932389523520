import services from '@services/Api';
import { handleErrors } from '@audience/modules/common';
import Vue from 'vue';

export const state = {
    covers: [],
    accounts: [],
    meta: {},
    account: null,
    formErrors: {}
};

export const actions = {
    getCovers: async ({ commit }, { accountId, params = {} }) => {
        const { data } = await services.accounts.covers(accountId, params);
        commit('setCovers', data.data);
    },
    getAccounts: async ({ commit }, params = {}) => {
        const { data } = await services.admin.accounts(params);
        commit('setAccounts', data.data);
        commit('setMeta', data.meta);
    },
    updateAccount: async ({ commit }, account) => {
        try {
            commit('setFormErrors', {});
            const { data } = await services.admin.updateAccount(account, { include: 'nav_items, tagged, collaterals' });
            commit('setAccount', data.data);
            if (Vue.prototype.$toasted) {
                Vue.prototype.$toasted.success('Account details have been updated');
            }
        } catch (error) {
            handleErrors(error, commit);
        }
    },
    getAccount: async ({ commit }, { accountId, params }) => {
        const { data } = await services.admin.fetchAccount(accountId, params);
        commit('setAccount', data.data);
    },
    removeCover: async ({ commit }, { accountId, coverId }) => {
        await services.admin.deleteCover(accountId, coverId);
        commit('removeCover', coverId);
    }
};

export const mutations = {
    setAccounts: (state, accounts) => {
        state.accounts = accounts;
    },
    setMeta: (state, meta) => {
        state.meta = meta;
    },
    setAccount: (state, account) => {
        state.account = account;
    },
    setCovers: (state, covers) => {
        state.covers = covers;
    },
    setCollaterals: (state, collaterals) => {
        state.account.collaterals = collaterals;
    },
    setFormErrors: (state, payload) => {
        state.formErrors = payload;
    },
    removeCover: (state, coverId) => {
        state.covers = state.covers.filter(cover => cover.id !== coverId);
    }
};

export default { namespaced: true, state, actions, mutations };
