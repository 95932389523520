import ApiClient from '@services/Http/Api';

export const customers = {
    list (userId, params = {}) {
        return ApiClient.get(`/dashboard/admin/users/${userId}/customers`, { params });
    },
    destroy (userId, accountId) {
        return ApiClient.destroy(`/dashboard/admin/users/${userId}/customers/${accountId}`);
    },
    update (userId, accounts) {
        return ApiClient.patch(`/dashboard/admin/users/${userId}/customers`, { accounts });
    }
};

let abortList;
let abortOldest;
export const staleCampaigns = {
    list (userId, params = {}) {
        abortList?.abort();
        abortList = new AbortController();
        return ApiClient.get(`/dashboard/admin/users/${userId}/campaigns/stale`, { params, signal: abortList.signal });
    },
    oldest (userId) {
        abortOldest?.abort();
        abortOldest = new AbortController();
        return ApiClient.get(
            `/dashboard/admin/users/${userId}/campaigns/stale`,
            { params: { direction: 'ASC', key: 'created_at', limit: 5 }, signal: abortOldest.signal }
        );
    }
};
