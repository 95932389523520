import ApiClient from '@services/Http/Api';

export const list = (params) => {
    return ApiClient.get('/dashboard/admin/associates', { params });
};

export const create = (params) => {
    return ApiClient.post('/dashboard/admin/associates', params);
};

export const update = (id, data) => {
    return ApiClient.patch(`/dashboard/admin/associates/${id}`, data);
};

export const destroy = (id) => {
    return ApiClient.destroy(`/dashboard/admin/associates/${id}`);
};
