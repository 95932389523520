export const interpolationValues = {
    firstname: [],
    lastname: [],
    city: []
};

export const tiptapSuggestions = flattenConfig(interpolationValues);

export function flattenConfig (config, base = null) {
    return Object.entries(config).reduce((carry, [key, children]) => {
        const prefix = [base, key].filter(a => a != null).join('.');
        if (Array.isArray(children)) {
            if (children.length <= 0) {
                return [...carry, prefix];
            } else {
                return [
                    ...carry,
                    ...children.map(child => `${prefix}.${child}`)
                ];
            }
        }
        return [...carry, ...flattenConfig(children, prefix)];
    }, []);
}
