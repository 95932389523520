import get from 'lodash/get';

const template = (name) => `${name}`;

const CAMPAIGN_PATTERNS = [
    /(^|\s|\/|#)@(\w*)$/,
    /(^|\s|\/|#)@(\w*)\.(\w*)$/,
    /(^|\s|\/|#)@(\w*\.\w*)\.(\w*)$/
];

const HASHTAG_PATTERNS = [
    /(^|\s|\/|#){{(\w*)$/,
    /(^|\s|\/|#){{(\w*)\.(\w*)$/,
    /(^|\s|\/|#){{(\w*\.\w*)\.(\w*)$/
];

export default function getStrategies (initialOptions, baseWord = null, patterns = CAMPAIGN_PATTERNS) {
    const options = toObjectStructure(initialOptions);

    const getOptionsProperties = (path, query) => {
        const baseObject = get(options, path, null) || options;
        return Object.keys(baseObject).filter(key => key.includes(query) && key !== query);
    };

    const replace = (value) => {
        const needle = get(options, baseWord, false) ? `${baseWord}.${value}` : value;

        const hasMoreProperties = Object.keys(get(options, needle, {})).length > 0;
        baseWord = hasMoreProperties ? needle : null;

        return patterns === CAMPAIGN_PATTERNS ? `$1@${needle}` : `$1{{${needle}}}`;
    };

    const search = (term, callback, matches) => {
        matches = Array.from(matches).slice(2);
        const chain = matches.join('.');
        baseWord = get(options, chain, null) ? chain : matches[0];
        callback(getOptionsProperties(term, matches.pop()));
    };

    return patterns.map((match) => ({ match, template, search, replace }));
}

export function getHashtagsStrategy (initialOptions, baseWord = null) {
    return getStrategies(initialOptions, baseWord, HASHTAG_PATTERNS);
}

export function toObjectStructure (config) {
    const result = {};

    Object.entries(config).forEach(([key, value]) => {
        result[key] = {};

        value.forEach(property => {
            if (typeof property === 'string') {
                result[key][property] = {};
                return;
            }

            result[key] = { ...result[key], ...toObjectStructure(property) };
        });
    });

    return result;
}
