<template>
    <div>
        <div class="flex justify-end">
            <v-button
                v-if="!isLoading"
                label="Add Address"
                button-style="primary"
                @click="addAddress"
            />
        </div>
        <save-address-modal
            :address="address"
            :show-modal="showAddressModal"
            @addressCreated="refresh"
            @addressUpdated="refresh"
            @close="showAddressModal = false"
        />
        <transition name="fade" mode="out-in">
            <div v-if="isLoading" key="isLoading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading addresses</span>
            </div>
            <div v-else>
                <div v-if="addresses.length > 0">
                    <div class="flex items-center justify-center py-4 px-2">
                        <v-paginator
                            v-if="meta.current_page"
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getAddresses({ account_id: user.account_id, params: {page: $event} })"
                        />
                    </div>
                    <v-table
                        is-elevated
                        has-actions
                        :rows="addresses"
                    >
                        <template #default="{ row }">
                            <v-table-column label="Address" value="address1">
                                <span>{{ row.address1 }}</span>
                                <span class="text-sm text-gray-700">{{ row.address2 }}</span>
                            </v-table-column>
                            <v-table-column label="City" value="city">
                                <span>{{ row.city }}</span>
                            </v-table-column>
                            <v-table-column label="Postal Code" value="postcode">
                                <span>{{ row.postcode }}</span>
                            </v-table-column>
                            <v-table-column label="Country" value="country">
                                <span>{{ row.country }}</span>
                            </v-table-column>
                        </template>

                        <template #dropdown="{ row }">
                            <button @click="updateAddress(row)">Update</button>
                            <button @click="deleteAddress(row.id)">Delete</button>
                        </template>
                    </v-table>
                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            v-if="meta.current_page"
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getAddresses({ account_id: user.account_id, params: {page: $event }})"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No addresses found.</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SaveAddressModal from '@outreach/components/account/SaveAddressModal';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import VPaginator from '@outreach/components/VPaginator';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import VButton from '@outreach/components/VButton';
import VLoader from '@outreach/components/VLoader';

export default {
    components: {
        SaveAddressModal,
        VTable,
        VTableColumn,
        VPaginator,
        VButton,
        VLoader
    },
    data: () => ({
        address: {},
        showAddressModal: false
    }),
    computed: {
        ...mapState({
            addresses: ({ addresses }) => addresses.addresses,
            meta: ({ addresses }) => addresses.meta,
            user: ({ user }) => user.user
        }),
        ...mapGetters({
            isLoading: 'loader/isLoading'
        }),
        accountId () {
            return this.$route.params.accountId || this.user.account_id;
        }
    },
    async created () {
        await this.refresh();
    },
    methods: {
        ...mapActions({
            getAddresses: 'addresses/list',
            delete: 'addresses/delete'
        }),
        async refresh () {
            await this.getAddresses({ account_id: this.accountId });
        },
        addAddress () {
            this.address = {};
            this.showAddressModal = true;
        },
        updateAddress (address) {
            this.address = address;
            this.showAddressModal = true;
        },
        async deleteAddress (id) {
            try {
                await this.delete({ account_id: this.user.account_id, id });
                this.$toasted.success('Address deleted');
                await this.refresh();
            } catch (e) {
                throw new ClientVisibleError('Could not delete the address.');
            }
        }
    }
};
</script>
