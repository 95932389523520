<template>
    <div class="container mx-auto">
        <transition name="fade" mode="out-in">
            <div class="mt-8 md:mt-0 md:p-8">
                <div class="flex flex-col sm:flex-row justify-between mb-6 px-6 sm:px-0">
                    <div class="flex flex-row lg:w-1/3 w-full items-center">
                        <h3 class="text-xl text-gray-800 font-semibold">{{ meta.total }} Sent So Far</h3>
                    </div>
                </div>
                <search-header>
                    <template #content>
                        <v-input
                            class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1"
                            label="Search"
                            @input="$emit('update:query', $event)"
                        />
                    </template>
                </search-header>
                <div v-if="loading" class="flex flex-col items-center justify-center p-6">
                    <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                    <span class="text-gray-700">Loading summary data</span>
                </div>

                <div v-if="contacts.length > 0">
                    <v-list ref="list" :rows="contacts">
                        <template #default="{ row }">
                            <div class="w-full">
                                <div class="flex justify-items-auto w-full">
                                    <div class="md:w-1/4 flex flex-row my-auto">
                                        <div class="contact-avatar">
                                            <avatar :contact="row.contact" :size="80" />
                                        </div>
                                        <p class="hidden md:inline-flex my-auto">{{ row.contact.name }}</p>
                                    </div>
                                    <div class="w-1/2 md:w-1/4 flex flex-col md:flex-row my-auto">
                                        <div class="md:w-1/2 my-auto">
                                            <p class="md:hidden text-lg">{{ row.name }}</p>
                                            <p class="text-gray-600">{{ row.contact.address.address1 }}</p>
                                            <p class="text-gray-600">{{ row.contact.address.address2 }}</p>
                                            <p class="text-gray-600">{{ row.contact.address.city }}, {{ row.contact.address.state }}</p>
                                            <p class="text-gray-600">
                                                {{ row.contact.address.country }}, {{ row.contact.address.postcode }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="w-1/3 md:w-1/2 flex flex-col md:flex-row items-center">
                                        <div
                                            v-if="row.resources.note"
                                            class="md:w-1/2 md:my-auto flex flex-col md:flex-row items-center"
                                        >
                                            <img
                                                :src="row.resources.note"
                                                alt="Note Render"
                                                class="w-1/3 object-scale-down"
                                                @click="preview(row.resources.note)"
                                            />
                                            <img
                                                :src="row.resources.envelope"
                                                alt="Envelope Render"
                                                class="w-1/3 object-scale-down"
                                                @click="preview(row.resources.envelope)"
                                            />
                                            <img
                                                :src="row.photo_path"
                                                alt="Cover Photo"
                                                class="w-1/3 object-scale-down"
                                                @click="preview(row.photo_path)"
                                            />
                                        </div>
                                        <div v-else class="md:w-1/2 md:my-auto flex flex-col md:flex-row items-center">
                                            <img
                                                :src="row.resources.message"
                                                alt="Message Render"
                                                class="w-2/3 object-scale-down"
                                                @click="preview(row.resources.message)"
                                            />
                                            <img
                                                :src="row.photo_path"
                                                alt="Cover Photo"
                                                class="w-1/3 object-scale-down"
                                                @click="preview(row.photo_path)"
                                            />
                                        </div>
                                        <div class="m-auto text-center">
                                            <mail-to-button
                                                label="Send Mail"
                                                :contact-id="row.contact.id"
                                                :template-id="user.account.outreach_template.id"
                                            >
                                                <template #before-label>
                                                    <i class="fa mr-3 fa-envelope"></i>
                                                </template>
                                            </mail-to-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </v-list>

                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            v-if="meta"
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="$emit('page-changed', $event)"
                        />
                    </div>
                </div>

                <div v-else class="text-center">
                    <p class="text-lg text-gray-700">It doesn't look like you've sent any campaigns yet.</p>
                    <v-button
                        button-style="info"
                        label="Add Campaign"
                        class="mt-2"
                        @click="$router.push({ name: 'campaign-dashboard' })"
                    />
                </div>
            </div>
        </transition>
        <modal header="Preview" :open="showModal" @close-modal="showModal = false">
            <template slot="content">
                <div class="flex w-full md:w-modal">
                    <img :src="previewImage" alt="Preview" />
                </div>
            </template>
            <template slot="modal-actions">
                <div class="p-2">
                    <v-button
                        label="Close"
                        :disabled="loading"
                        :loading="loading"
                        class="mr-2"
                        @click="showModal = false"
                    />
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import Avatar from '@outreach/components/shared/avatar';
import VPaginator from '@outreach/components/VPaginator';
import VList from '@outreach/components/shared/VList';
import Modal from '@outreach/components/shared/modal';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import MailToButton from '@outreach/components/contacts/MailToButton';
import { mapState } from 'vuex';

export default {
    components: { MailToButton, SearchHeader, Avatar, VPaginator, VList, Modal, VButton, VInput, VLoader },
    props: {
        contacts: { type: Array, default: () => ([]) },
        meta: { type: Object, default: () => ({}) },
        loading: { type: Boolean, default: true },
        actions: { type: Array, default: () => ([]) },
        query: { type: String, default: null }
    },
    data: function () {
        return {
            showModal: false,
            previewImage: null
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    methods: {
        preview (preview) {
            this.previewImage = preview;
            this.showModal = true;
        }
    }
};
</script>

<style scoped>
.contact-avatar {
    @apply w-10 h-10 rounded-full mr-4 overflow-hidden bg-gray-300 border border-gray-300 lg:w-20 lg:h-20;
}
</style>
