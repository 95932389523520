<template>
    <div class="relative">
        <v-button
            :label="label"
            :disabled="disabled"
            :loading="loading"
            :button-style="buttonStyle"
            class="w-full"
            @click="showDropdown = !showDropdown"
        >
            <i slot="after-label" class="text-sm fa fa-chevron-down ml-2"></i>
        </v-button>

        <transition name="dropdown">
            <div
                v-if="showDropdown"
                v-click-away="() => showDropdown = false"
                class="dropdown"
                @click="showDropdown = !showDropdown"
            >
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import { mixin as clickAway } from '@outreach/mixins/clickAway';
import VButton from '@outreach/components/VButton';

export default {
    components: { VButton },
    mixins: [clickAway],
    props: {
        label: { type: String, required: true },
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        buttonStyle: { type: String, default: 'default' }
    },
    data: () => ({
        showDropdown: false
    })
};
</script>

<style lang="scss" scoped>
    .dropdown {
        @apply absolute flex flex-col right-0 shadow-lg bg-white rounded-md;
        @apply border-2 border-gray-300 z-20 min-w-content py-2 w-40;
        > button,
        > a {
            color: #2d3748;
            font-size: 0.875rem;
            font-weight: 500;
            padding: 0.375rem 0.75rem;
            text-align: left;

            &:hover {
                background: #f7fafc;
            }
        }
    }
</style>
