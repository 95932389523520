import services from '@services/Api';
import common, { toggleLoading } from '@audience/modules/queue/common';

export const state = {
    ...common.state,
    events: {
        'completed/retryJob': ['queue/refreshQueues'],
        'completed/deleteJob': ['queue/refreshQueues']
    }
};

export const actions = {
    ...common.actions,
    listCampaigns: toggleLoading(async function ({ commit }, params) {
        const { data } = await services.queues.listCompleted(params);
        commit('setCampaigns', data);
    }),
    listJobs: async function ({ commit }, { params = {}, campaignId }) {
        const { data } = await services.queues.listCompletedJobs(campaignId, params);
        commit('setJobs', data);
    },
    retryCampaign: async function ({ }, campaign) {
        await services.queues.retryCampaign(campaign.id);
    },
    retryJob: async function ({ dispatch }, job) {
        await services.queues.retryCompletedJob(job);
        dispatch('listJobs', { campaignId: job.campaign_id });
    },
    deleteJob: async function ({ dispatch }, job) {
        await services.queues.deleteCompletedJob(job.id);
        dispatch('listJobs', { campaignId: job.campaign_id });
    }
};

export const mutations = {
    ...common.mutations
};

export const getters = {
    ...common.getters
};

export default { namespaced: true, state, actions, mutations, getters };
