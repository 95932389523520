import services from '@services/Api';
import Layouts from '@services/Api/Layouts';

const state = {
    letter: {
        layout_type: Layouts.NOTE
    }
};

const actions = {
    get: async ({ commit }, campaign_id) => {
        const { data } = await services.campaigns.fetch(campaign_id, ['letter']);
        commit('set', data.data.letter);
    },
    create: async ({ commit }, { campaign_id, letter }) => {
        const { data } = await services.letters.createForCampaign(campaign_id, letter);
        commit('set', data.data);
    },
    update: async ({ commit }, letter) => {
        const { data } = await services.letters.update(letter.id, letter);
        commit('set', data.data);
    }
};

const mutations = {
    set: (state, letter) => {
        if (Array.isArray(letter)) {
            return;
        }
        state.letter = letter;
    }
};

export default { namespaced: true, state, actions, mutations };
