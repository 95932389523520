import ApiClient from '@services/Http/Api';

export const create = (account, address) => {
    return ApiClient.post(`/dashboard/accounts/${account}/addresses`, address);
};

export const list = (account, params) => {
    return ApiClient.get(`/dashboard/accounts/${account}/addresses`, { params });
};

export const destroy = (account, addressId) => {
    return ApiClient.destroy(`/dashboard/accounts/${account}/addresses/${addressId}`);
};

export const update = (account, address) => {
    return ApiClient.patch(`/dashboard/accounts/${account}/addresses/${address.id}`, address);
};

export const save = (account, address) => {
    if (address.id) {
        return update(account, address);
    }
    return create(account, address);
};
