"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignStatuses = void 0;
var CampaignStatuses;
(function (CampaignStatuses) {
    CampaignStatuses["SCHEDULED"] = "scheduled";
    CampaignStatuses["PENDING"] = "pending";
    CampaignStatuses["SENT"] = "sent";
    CampaignStatuses["PRINTING"] = "printing";
    CampaignStatuses["SUBMITTED"] = "submitted";
})(CampaignStatuses = exports.CampaignStatuses || (exports.CampaignStatuses = {}));
