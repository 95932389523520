<template>
    <div>
        <div ref="dropzone" class="border rounded grid container">
            <div class="flex items-center justify-center mainImage">
                <transition name="fade">
                    <img
                        v-if="selectedImage && !value"
                        :key="selectedImage.id"
                        class="max-w-full max-h-full"
                        :src="selectedImage.location"
                    />
                    <img
                        v-else-if="value"
                        class="max-w-full max-h-full"
                        :src="value"
                    />
                    <button
                        v-else
                        ref="add-image"
                        class="w-full h-full flex items-center justify-center"
                        @click="openDropzone"
                    >
                        <span class="text-gray-800 text-center">{{ label }}</span>
                    </button>
                </transition>
            </div>
            <div class="border-t justify-center overflow-x-auto flex py-4 mx-4 w-full">
                <button ref="add-image-button" class="upload-button" @click="openDropzone">
                    <i class="fa fa-plus text-2xl text-gray-500"></i> Upload or Select Image
                </button>
            </div>
        </div>
        <p
            v-for="(error, index) in errors"
            ref="photo-errors"
            :key="index"
            class="text-red-400 text-xs p-2"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>

import { AssetType } from '@services/AssetType';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { mapState } from 'vuex';

export default {
    name: 'VImagePicker',
    props: {
        value: { type: String, default: null },
        label: { type: String, default: 'Click here to upload' },
        errors: { type: Array, default: () => ([]) },
        disableDelete: { type: Boolean, default: false },
        accountId: { type: Number, required: false, default: null },
        assetType: { type: String, default: AssetType.NOTE_COVER }
    },
    data () {
        return {
            selectedImage: null
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    methods: {
        selectImage (image) {
            this.selectedImage = image;
            this.$emit('input', image.location);
        },
        deleteImage (image) {
            this.$emit('delete-image', image);
        },
        openDropzone () {
            dialogEmitter.openDialog('ImageLibraryDialog', {
                onSelect: this.selectImage,
                assetType: this.assetType,
                accountId: this.accountId
            });
        }
    }
};
</script>

<style scoped lang="scss">
.upload-button {
    @apply border rounded flex items-center bg-gray-200 image gap-2 px-6 uppercase tracking-wider text-sm font-bold text-gray-800;
}
.container {
    display: grid;
    grid-template-rows: 2fr auto;
}
.mainImage {
    height: 300px;
}
.imageList {
    gap: 0.5rem;
}
.image {
    min-height: 80px;
    height: 80px;
}
.delete-button {
    display: none;
}
.image:hover > .delete-button {
    display: block;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
