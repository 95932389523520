<template>
    <section class="flex justify-between items-start">
        <slot></slot>
        <button
            ref="copy-to-clipboard-button"
            type="button"
            class="clipboard-button"
            @click="copyText(copy)"
        >
            <i class="fa fa-copy mr-2"></i>
            {{ text }}
        </button>
    </section>
</template>

<script>
import { copyText } from '@services/Utils';

export default {
    name: 'VClipboard',
    props: {
        copy: { type: String, required: true },
        text: { type: String, required: false, default: 'Copy' }
    },
    methods: { copyText }
};
</script>

<style scoped>
.clipboard-button {
    @apply inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
</style>
