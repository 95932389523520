import moment from 'moment';

export const formatDateTime = (value, format = 'MM/DD/YY h:mm a') => {
    if (!value) {
        return '';
    }
    const date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.local().format(format);
};

export const formatRelativeDateTime = (value) => {
    if (!value) {
        return '';
    }
    const date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.local().fromNow();
};

export const formatFullDate = (value, format = 'LL') => {
    return formatDateTime(value, format);
};

export const formatDate = (value, format = 'MM/DD/YY') => {
    return formatDateTime(value, format);
};

export const fromNow = (value) => formatRelativeDateTime(value);

export const centsToDollars = (value) => {
    return (value / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
