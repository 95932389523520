<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <div class="w-full">
                    <div class="flex flex-col lg:flex-row lg:items-center gap-3">
                        <v-input v-model="query" label="Search" class="w-full lg:w-auto lg:flex-1" />
                        <multiselect
                            id="type-select"
                            placeholder="Roles"
                            :value="roles"
                            :options="allRoles"
                            :multiple="true"
                            class="flex-1"
                            @input="roles = $event"
                        />
                        <v-checkbox
                            v-if="isCustomerService()"
                            ref="toggle-archived"
                            v-model="showArchived"
                            label="Show Deleted"
                        />
                    </div>

                    <div class="flex items-center gap-3 pt-5">
                        <v-button
                            label="Add User"
                            button-style="primary"
                            @click="openUserForm()"
                        />
                        <v-button label="Import..." button-style="default" @click="openCsvDialog" />
                    </div>
                </div>
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading users</span>
            </div>
            <div v-else>
                <div v-if="users.length > 0">
                    <v-table
                        is-elevated
                        has-actions
                        clickable
                        :rows="users"
                        :sort="sorting"
                        @sort-changed="updateSort"
                    >
                        <template #default="{ row }">
                            <v-table-column
                                label="Account"
                                value="account"
                            >
                                <div class="mt-2">
                                    <div>
                                        <strong class="text-lg">{{ row.account && row.account.name }}</strong>
                                        <p v-if="row.deleted_at" class="deleted-header">Deleted</p>
                                    </div>
                                </div>
                            </v-table-column>
                            <v-table-column label="Name" value="name">
                                <span>{{ row.first_name }} {{ row.last_name }}</span>
                                <span class="text-sm text-gray-700">({{ row.email }})</span>
                                <div class="mt-2">
                                    <span
                                        v-for="role in row.roles"
                                        :key="role.id"
                                        class="text-xs bg-gray-200 inline-block px-2 py-1 rounded mr-2"
                                    >
                                        {{ role.title }}
                                    </span>
                                </div>
                            </v-table-column>
                            <v-table-column
                                label="Last Login"
                                value="last_logged_in_at"
                                sortable
                            >
                                <span v-if="row.last_login">{{ formatDate(row.last_login.logged_in_at) }}</span>
                                <div
                                    v-if="row.last_login"
                                    class="text-sm text-gray-700"
                                >{{ fromNow(row.last_login.logged_in_at) }}</div>
                            </v-table-column>
                            <v-table-column
                                label="Created Date"
                                value="created_at"
                                sortable
                            >
                                <span>{{ formatDate(row.created_at) }}</span>
                                <div class="text-sm text-gray-700">{{ fromNow(row.created_at) }}</div>
                            </v-table-column>
                        </template>
                        <template #dropdown="{ row }">
                            <button @click="openUserForm(row.id)">Update User</button>
                            <button @click="resetPassword(row.email)">Reset Password</button>
                            <button @click="addContact(row)">Add Contact</button>
                            <button :id="`impersonate-${row.id}`" @click="impersonate(row)">Impersonate</button>
                            <button v-if="rowDeleted(row)" :ref="`restore-${row.id}`" @click="restoreUser(row)">
                                Restore User
                            </button>
                            <button
                                v-if="canManageCustomers(row)"
                                :id="`manage-customers-${row.id}`"
                                @click="$router.push({ name: 'manage-customers', params: { userId: row.id }})"
                            >Manage Customers</button>
                        </template>
                    </v-table>
                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getUsers({ q: query, page: $event })"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No users found.</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import services from '@services/Api';
import VLoader from '@outreach/components/VLoader';
import VPaginator from '@outreach/components/VPaginator';
import { VTable, VTableColumn } from '@outreach/components/shared/table';
import VInput from '@outreach/components/inputs/VInput';
import VButton from '@outreach/components/VButton';
import debounce from 'lodash/debounce';
import { formatDate, fromNow } from '@services/Filters';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VCheckbox from '@outreach/components/inputs/VCheckbox';
import { Roles } from '@services/Roles';
import { Multiselect } from 'vue-multiselect';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';

export default {
    components: {
        SearchHeader,
        VLoader,
        VPaginator,
        VTable,
        VTableColumn,
        VButton,
        VInput,
        VCheckbox,
        Multiselect
    },
    mixins: [hasPermission],
    data: () => ({
        loading: true,
        meta: {},
        users: [],
        selectedUser: { account: { address: {} } },
        query: undefined,
        sorting: {
            key: undefined,
            direction: 'DESC'
        },
        showArchived: false,
        roles: undefined
    }),
    computed: {
        allRoles () {
            return Object.values(Roles);
        }
    },
    watch: {
        showArchived () {
            this.debounceUsers();
        },
        query () {
            this.sorting = { key: undefined, direction: undefined };
            this.debounceUsers();
        },
        roles () {
            this.debounceUsers();
        }
    },
    created () {
        if (this.$route?.query?.q) {
            this.query = this.$route.query.q;
        }
        this.getUsers();
    },
    methods: {
        formatDate,
        fromNow,
        debounceUsers: debounce(function (params) {
            this.getUsers(params);
        }, 500),
        async getUsers (params = {}) {
            this.loading = true;
            const { data } = await services.admin.listUsers({
                ...params,
                q: this.query,
                roles: this.roles,
                sort_key: this.sorting.key || undefined,
                sort_direction: this.sorting.direction || undefined,
                archived: this.showArchived ? true : undefined
            });
            this.users = data.data;
            this.meta = data.meta;
            this.sorting = data.sorting || this.sorting;
            this.loading = false;
        },
        async restoreUser (user) {
            await services.admin.restoreUser(user.id);
            await this.getUsers();
        },
        updateSort (data) {
            this.sorting = data;
            this.getUsers();
        },
        async resetPassword (email) {
            const { data } = await services.users.resetPassword(email);
            if (data.status) {
                this.$toasted.success(data.status);
            }
        },
        rowDeleted (row) {
            return row.deleted_at != null;
        },
        addContact (user) {
            this.$router.push({ name: 'add-contact', params: { accountId: user.account_id } });
        },
        async impersonate (user) {
            await services.authentication.impersonate(user.id);
            this.$router.push({ name: 'send-notes' });
        },
        openUserForm (userId) {
            dialogEmitter.name('UserFormDialog').props({ userId }).addListener('onSuccess', this.onUserSaved).wait();
        },
        onUserSaved (user) {
            const userIndex = this.users.findIndex(({ id }) => id === user.id);
            this.users.splice(Math.max(0, userIndex), userIndex >= 0 ? 1 : 0, user);
        },
        canManageCustomers (target) {
            return this.hasRoles(target, Roles.CUSTOMER_SERVICE) && (this.isAdmin() || this.user.id === target.id);
        },
        openCsvDialog () {
            dialogEmitter.name('ImportUsersDialog').addListener('csv-imported', () => this.getUsers()).wait();
        }
    }
};
</script>

<style scoped>
.deleted-header {
    @apply mt-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800;
}
</style>
