<template>
    <div class="border-b border-gray-200 pb-4 text-gray-800">
        <div class="flex">
            <div class="mb-2 text-gray-800 flex items-center">
                <div class="bg-gray-300 leading-none p-3 rounded cursor-pointer" @click="viewFolder()">
                    <i class="fa fa-folder"></i> Images
                </div>
                <transition name="fade" mode="out-in">
                    <div v-if="value" class="flex items-center">
                        <i class="fa fa-chevron-right text-gray-800 mx-4 leading-none"></i>
                        <div class="bg-gray-300 p-3 rounded leading-none">
                            <i class="fa fa-folder"></i> {{ value.name }}
                        </div>
                        <i
                            v-if="value.assets_count === 0"
                            id="archive-folder"
                            class="fa fa-trash ml-2 cursor-pointer"
                            title="Delete Folder"
                            @click="confirmDelete"
                        ></i>
                    </div>
                </transition>
            </div>
            <div class="ml-auto">
                <v-button v-if="! value" ref="add-folder" label="Add Folder" @click="addFolder">
                    <template #before-label><i class="fa fa-folder-plus mr-2"></i></template>
                </v-button>
                <v-button v-else ref="edit-folder" label="Edit Folder" @click="editFolder">
                    <template #before-label><i class="fa fa-pencil mr-2"></i></template>
                </v-button>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <v-button
                v-if="value"
                ref="back"
                label="Back"
                class="my-4"
                button-style="primary"
                @click="viewFolder()"
            >
                <template #before-label><i class="fa fa-backward mr-2"></i></template>
            </v-button>
            <div v-else>
                <folder-list
                    ref="list"
                    :account-id="user.account_id"
                    :folders="folders"
                    class="grid grid-cols-2 xl:grid-cols-4 gap-4 my-4"
                    @selected="viewFolder"
                />
            </div>
        </transition>

        <div class="grid grid-cols-1 xl:grid-cols-4 gap-4 text-gray-700">
            <div>
                <v-text-dropdown
                    :button-style="selectedItems.length === 0 ? 'default' : 'primary'"
                    class="mb-2 md:mb-0"
                    label="Actions"
                    :disabled="selectedItems.length === 0"
                >
                    <template #content>
                        <button @click="moveToFolder">Move Items</button>
                    </template>
                </v-text-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import VButton from '@outreach/components/VButton.vue';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import FolderList from '@outreach/components/shared/image-library/FolderList.vue';
import services from '@services/Api';
import { Alert } from '@services/Alert';
import VTextDropdown from '@outreach/components/shared/dropdown/VTextDropdown.vue';
import AssetsUpdated from '@events/AssetsUpdated';

export default {
    name: 'FolderNavigation',
    components: { VTextDropdown, FolderList, VButton },
    props: {
        value: { type: Object, default: undefined },
        type: { type: String, required: true },
        selectedItems: { type: Array, default: () => [] },
        accountId: { type: Number, required: true }
    },
    data () {
        return {
            folders: []
        };
    },
    computed: {
        ...mapState({ user: ({ user }) => user.user })
    },
    mounted () {
        this.listFolders();

        this.subscribe({
            event: AssetsUpdated,
            subscription: () => {
                this.listFolders();
            }
        });
    },
    destroyed () {
        this.unsubscribe({
            event: AssetsUpdated
        });
    },
    methods: {
        ...mapActions({
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        moveToFolder () {
            const folders = this.value
                ? [{ id: null, name: 'Images (Main)' }]
                : this.folders.filter(folder => folder.id !== this.value?.id);

            dialogEmitter.name('AddToFolderDialog')
                .props({
                    items: this.selectedItems,
                    accountId: this.accountId,
                    folders,
                    current: this.value
                })
                .addListener('moved', (move) => {
                    const folder = this.folders.find((folder) => folder.id === move.folder.id);
                    folder.assets_count += move.count;
                    this.$emit('moved');
                })
                .wait();
        },
        addFolder () {
            dialogEmitter.name('FolderDialog')
                .props({ folder: { name: '' } })
                .addListener('saved', () => this.listFolders())
                .wait();
        },
        editFolder () {
            dialogEmitter.name('FolderDialog')
                .props({ folder: this.value })
                .addListener('saved', (folder) => {
                    this.listFolders();
                    this.$emit('input', folder);
                })
                .wait();
        },
        viewFolder (folder = undefined) {
            this.$emit('input', folder);
        },
        async listFolders () {
            try {
                const { data } = await services.tags.list(this.accountId, { type: `folder:${this.type}` });
                this.folders = data.data;
            } catch (e) {
                Alert.fromError(e, 'Oops, something went wrong while loading folders. Please try again.');
            }
        },
        async archiveFolder () {
            try {
                await services.tags.archive(this.accountId, this.value.id);
                Alert.success(`Folder - ${this.value.name} - was successfully deleted.`, 3000);
            } catch (e) {
                Alert.fromError(e, 'Oops, something went wrong while deleting the folder. Please try again.');
            }
        },
        async confirmDelete () {
            const confirmed = await dialogEmitter
                .message(`Are you sure you want to delete this folder - ${this.value.name} ?`)
                .confirm('Confirm Delete');

            if (confirmed) {
                await this.archiveFolder();
                this.listFolders();
                this.$emit('deleted');
            }
        }
    }
};
</script>
