<template>
    <div class="flex items-center p-5 flex-col">
        <p class="text-gray-800 text-xl flex-1 text-center">The page is not found</p>
        <p class="text-gray-700 text-baseflex-1 text-center">Return to
            <a class="text-brand-regular" @click="returnPage">previous page</a>
        </p>
    </div>
</template>

<script>
export default {
    methods: {
        returnPage () {
            this.$router.go(-1);
        }
    }

};
</script>

<style>

</style>
