import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';

const isBlob = (value) => value instanceof Blob;
const isFile = (value) => value instanceof File;
const isBoolean = (value) => typeof value === 'boolean';
const isNull = (value) => value === null;
const isUndefined = (value) => value === undefined;

const processData = (value, formData, parent) => {
    const processedKey = parent || '';

    if (isNull(value) || isUndefined(value)) {
        return;
    }

    if (isFile(value)) {
        formData.append(processedKey, value);
        return;
    }

    if (isBlob(value)) {
        formData.append(processedKey, value);
        return;
    }

    if (isArray(value)) {
        value.forEach((item, index) => {
            const computedKey = `${processedKey}[${index}]`;
            processData(item, formData, computedKey);
        });
        return;
    }

    if (isObject(value)) {
        Object.entries(value).forEach(([key, data]) => {
            let computedKey = key;
            if (parent) {
                computedKey = `${parent}[${key}]`;
            }
            processData(data, formData, computedKey);
        });
        return;
    }

    if (isBoolean(value)) {
        formData.append(processedKey, value ? 'true' : 'false');
        return;
    }

    formData.append(processedKey, value);
};

export const formDataBuilder = (data, formData = new FormData()) => {
    if (!data) return formData;

    processData(data, formData);

    return formData;
};
