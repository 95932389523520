<template>
    <div class="mb-6 relative">
        <v-input
            ref="name-input"
            label="Name"
            type="search"
            :autocomplete="$browserDetect.isChrome ? 'disabled' : 'off'"
            :value="value"
            :errors="formErrors"
            @input="onSearch($event)"
            @keydown.up="onArrowUp"
            @keydown.down="onArrowDown"
            @keydown.enter="onEnter"
        />
        <ul
            v-if="isOpen"
            id="autocomplete-results"
            class="border border-solid bg-white border-gray-400 rounded overflow-hidden absolute z-50 w-full"
        >
            <li v-if="loading" class="p-2 text-gray-600">
                Loading results...
            </li>
            <li v-else-if="options.length === 0" class="p-2 text-gray-600">
                No results found.
            </li>
            <li
                v-for="(option, i) in options"
                v-else
                ref="option"
                :key="i"
                class="p-2 list-none cursor-pointer"
                :class="{ 'bg-gray-200': i === selectionIndex }"
                @click="onSelect(option)"
                @mouseover="selectionIndex = i"
            >
                <slot :option="option"></slot>
            </li>
        </ul>
    </div>
</template>

<script>
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { VInput },
    props: {
        value: { type: String, default: '' },
        options: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        formErrors: { type: [Array, String], required: false, default: () => [] }
    },
    data: () => ({
        isOpen: false,
        selectionIndex: 0
    }),
    mounted () {
        document.addEventListener('click', this.close);
    },
    destroyed () {
        document.removeEventListener('click', this.close);
    },
    methods: {
        onSearch (event) {
            this.$emit('input', event);
            this.isOpen = true;
        },
        onArrowDown () {
            if (this.selectionIndex < this.options.length) {
                this.selectionIndex = this.selectionIndex + 1;
            }
        },
        onArrowUp () {
            if (this.selectionIndex > 0) {
                this.selectionIndex = this.selectionIndex - 1;
            }
        },
        onEnter () {
            const option = this.options[this.selectionIndex];
            this.onSelect(option);
            this.isOpen = false;
            this.selectionIndex = -1;
        },
        onSelect (option) {
            this.$emit('select', option);
        },
        close () {
            if (this.isOpen) {
                this.isOpen = false;
                this.selectionIndex = -1;
            }
        }
    }
};
</script>
