<template>
    <div
        id="filter-est-value"
        class="flex flex-col gap-2"
    >
        <h3 class="text-gray-700 font-medium">Estimated Value</h3>
        <div class="flex gap-4">
            <v-dropdown
                :value="minValue"
                label="Min"
                :options="options"
                help="Leave empty for no maximum"
                :errors="formErrors['est_value.min']"
                pattern="^[0-9]*$"
                class="flex-1"
                @input="$emit('update:min-value', $event)"
            />
            <v-dropdown
                :value="maxValue"
                label="Max"
                :options="options"
                help="Leave empty for no maximum"
                :errors="formErrors['est_value.max']"
                pattern="^[0-9]*$"
                class="flex-1"
                @input="$emit('update:max-value', $event)"
            />
        </div>
    </div>
</template>
<script>
import VDropdown from '@outreach/components/inputs/VDropdown.vue';

export default {
    name: 'EstValueDropdown',
    components: { VDropdown },
    props: {
        minValue: { type: [Number, String], default: undefined },
        maxValue: { type: [Number, String], default: undefined },
        disabled: { type: Boolean, default: false },
        formErrors: { type: Object, default: () => ({}) }
    },
    data () {
        return {
            options: [
                0,
                100000,
                200000,
                300000,
                400000,
                500000,
                600000,
                700000,
                800000,
                900000,
                1000000,
                1250000,
                1500000,
                1750000
            ]
        };
    }
};
</script>
