<template>
    <div>
        <template-form
            label="Edit template"
            :template.sync="template"
            :form-errors="formErrors"
            @submit="submit"
        />
        <v-panel title="Accounts">
            <template #actionButton>

                <bulk-template-actions :template="template">
                    <v-button
                        ref="add-template-button"
                        label="Add account"
                        button-style="primary"
                        size="sm"
                        @click="showAccountSelect()"
                    />
                </bulk-template-actions>
            </template>
            <template #default>
                <v-loader v-if="isLoading" />
                <paginator-wrap
                    v-if="accounts.length > 0"
                    :pagination="accountsMeta"
                    :change-page="(page) => fetchAccounts({ page })"
                >
                    <template #content>
                        <v-table :rows="accounts" has-actions>
                            <template #default="{ row }">
                                <v-table-column value="name" label="Name">
                                    <p class="text-lg">{{ row.name }}</p>
                                </v-table-column>
                            </template>
                            <template #priority-buttons="{ row }">
                                <v-button label="Edit" @click="showAccountSelect(row)" />
                                <v-button
                                    label="Remove"
                                    button-style="error"
                                    :loading="isLoading"
                                    @click="confirmAccountRemoval(row)"
                                />
                            </template>
                        </v-table>
                    </template>
                </paginator-wrap>
                <p v-else-if="!isLoading" class="text-lg text-gray-600 text-center">No Accounts Assigned</p>
            </template>
        </v-panel>
    </div>
</template>

<script>
import TemplateForm from '@outreach/pages/Templates/TemplateForm';
import { mapActions, mapGetters } from 'vuex';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VButton from '@outreach/components/VButton';
import { Template } from '@models/Template';
import VPanel from '@outreach/components/shared/VPanel.vue';
import VTable from '@outreach/components/shared/table/VTable.vue';
import VTableColumn from '@outreach/components/shared/table/VTableColumn.vue';
import PaginatorWrap from '@outreach/components/PaginatorWrap.vue';
import services from '@services/Api';
import { Alert } from '@services/Alert';
import VLoader from '@outreach/components/VLoader.vue';
import BulkTemplateActions from '@outreach/pages/Templates/BulkTemplateActions.vue';
import TemplateUpdated from '@events/TemplateUpdated';

export default {
    components: { BulkTemplateActions, PaginatorWrap, VTableColumn, VTable, VPanel, TemplateForm, VButton, VLoader },
    data: () => ({
        selectedAccount: null,
        showAccountModal: false,
        template: new Template(),
        accounts: [],
        accountsMeta: {}
    }),
    computed: {
        ...mapGetters({
            formErrors: 'templates/formErrors',
            isLoading: 'loader/isLoading'
        }),
        templateId () {
            return this.$route.params.template;
        }
    },
    created () {
        this.fetch(this.templateId).then(template => {
            this.template = new Template(template);
        });
        this.fetchAccounts();
        this.subscribe({
            event: TemplateUpdated,
            subscription: this.handleTemplateUpdated
        });
    },
    destroyed () {
        this.unsubscribe({
            event: TemplateUpdated,
            subscription: this.handleTemplateUpdated
        });
    },
    methods: {
        ...mapActions({
            fetch: 'templates/fetch',
            update: 'templates/update',
            detach: 'templates/detach',
            openModal: 'modals/open',
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        async submit () {
            try {
                await this.update(this.template);
                this.$router.push({ name: 'shared-templates' });
                this.$toasted.success('Template updated');
            } catch (error) {
                this.$toasted.error('Failed to update template');
            }
        },
        confirmAccountRemoval (account) {
            dialogEmitter.props({
                header: 'Remove Account',
                message: `Are you sure you want to remove this account - ${account.name}?`
            })
                .onConfirmed(() => this.removeAccount(account))
                .confirm();
        },
        async removeAccount (account) {
            try {
                await this.detach(account.id);
                this.accounts.splice(this.accounts.findIndex(a => a.id === account.id), 1);
            } catch (error) {
                this.$toasted.error('Failed to detach template from account. Please try again.');
            }
        },
        pushAccount (account) {
            if (this.accounts.findIndex((existing) => existing.id === account.id) > -1) return;

            this.accounts.push(account);
        },
        showAccountSelect (account = null) {
            this.selectedAccount = account;
            dialogEmitter.openDialog('AssignAccount', {
                account: this.selectedAccount,
                template: this.template,
                onAccountSelect: this.pushAccount
            });
        },
        async fetchAccounts (params = {}) {
            try {
                const { data } = await services.admin.templates.accounts(this.templateId, params);
                this.accounts = data.data;
                this.accountsMeta = data.meta;
            } catch (error) {
                await Alert.error(`Could not load accounts for template: ${this.template.name}`);
            }
        },
        handleTemplateUpdated (context, event) {
            if (event.data.template.id === this.template.id) {
                this.template = new Template(event.data.template);
            }
            this.fetchAccounts();
        }
    }
};
</script>
