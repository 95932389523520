<template>
    <div>
        <article-type-selector v-if="!type" @selected="type = $event" />
        <video-article-form v-else-if="type === 'video' && !article.media_url" @create="createVideoArticle" />
        <article-form v-else :existing-article="article" :type="type" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DEFAULT_HASHTAGS } from '@outreach/config/articles';
import ArticleForm from '@outreach/pages/Articles/ArticleForm';
import ArticleTypeSelector from '@outreach/pages/Articles/ArticleTypeSelector';
import VideoArticleForm from '@outreach/pages/Articles/VideoArticleForm';

export default {
    name: 'EditArticle',
    components: { VideoArticleForm, ArticleTypeSelector, ArticleForm },
    data () {
        return {
            article: {
                hashtags: DEFAULT_HASHTAGS.join(' ')
            },
            type: null
        };
    },
    computed: {
        ...mapState({
            existingArticle: ({ articles }) => articles.record
        }),
        articleId () {
            return this.$route.params.articleId;
        }
    },
    mounted () {
        if (this.articleId) {
            this.loadExisting();
        }
    },
    methods: {
        ...mapActions({
            fetch: 'articles/fetch'
        }),
        async loadExisting () {
            await this.fetch({ articleId: this.articleId, params: { include: 'accounts' } });
            this.article = { ...this.existingArticle };
            this.type = this.article.media_url ? 'video' : 'standard';
        },
        createVideoArticle (article) {
            this.article = article;
            this.type = 'video';
        }
    }
};
</script>
