import services from '@services/Api';

export const state = {
    records: [],
    record: null,
    meta: null
};

export const actions = {
    list: async ({ commit }, params) => {
        const { data } = await services.articles.list(params);
        commit('setRecords', data.data);
        commit('setMeta', data.meta);
    },
    adminList: async ({ commit }, params) => {
        const { data } = await services.articles.listAllArticles(params);
        commit('setRecords', data.data);
        commit('setMeta', data.meta);
    },
    fetch: async ({ commit }, { articleId, params = {} }) => {
        const { data } = await services.articles.fetch(articleId, params);
        commit('setRecord', data.data);
    },
    update: async ({ commit }, { articleId, payload }) => {
        const { data } = await services.articles.update(articleId, payload);
        commit('setRecord', data.data);
    },
    create: async ({ commit }, { payload }) => {
        const { data } = await services.articles.create(payload);
        commit('setRecord', data.data);
    },
    destroy: async ({ commit }, articleId) => {
        await services.articles.destroy(articleId);
        commit('removeRecord', articleId);
    }
};

export const mutations = {
    setRecords: (state, records) => {
        state.records = records;
    },
    setRecord: (state, record) => {
        state.record = record;
    },
    removeRecord: (state, id) => {
        state.records = state.records.filter(record => record.id !== id);
    },
    setMeta: (state, meta) => {
        state.meta = meta;
    }
};

export default { namespaced: true, state, actions, mutations };
