<template>
    <modal
        header="Import Contacts from CSV"
        :open="showModal"
        disable-on-click-away
        @close-modal="$emit('close-modal')"
    >
        <template #content>
            <import-csv :header-rules="rules" :on-upload="upload" v-on="$listeners" />
        </template>
    </modal>
</template>

<script>
import ImportCsv from '@outreach/components/services/ImportCsv.vue';
import Modal from '@outreach/components/shared/modal/index';
import services from '@services/Api';
import { mapState } from 'vuex';

export default {
    name: 'ImportContactsModal',
    components: { ImportCsv, Modal },
    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        rules: undefined
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    watch: {
        showModal (show) {
            if (show && !this.rules) {
                this.headerRules();
            }
        }
    },
    methods: {
        async upload (upload) {
            return await services.contacts.importCsv(this.user.account_id, {
                contact: upload.file,
                csv_url: upload.csvUrl
            });
        },
        async headerRules () {
            this.rules = await services.admin.csvHeaderRules('contacts');
        }
    }
};
</script>
