<template>
    <div>
        <div class="w-full mb-4">
            <div class="sm:block">
                <nav class="tab-view" aria-label="Tabs">
                    <tab-button
                        v-for="tab in tabs"
                        :key="tab"
                        :label="tab"
                        :value="keyify(tab)"
                        :active="currentTab === keyify(tab)"
                        @click="changeTab"
                    />
                </nav>
            </div>
        </div>
        <div v-for="tab in tabs" :key="keyify(tab)">
            <slot v-if="currentTab === keyify(tab)" :name="keyify(tab)" :current-tab="currentTab"></slot>
        </div>
    </div>
</template>

<script>
import TabButton from '@outreach/components/shared/TabButton';

export default {
    name: 'TabView',
    components: { TabButton },
    props: {
        tabs: { type: Array, default: () => [] }
    },
    data () {
        return {
            currentTab: this.firstTabValue(this.tabs)
        };
    },
    methods: {
        changeTab (value) {
            this.$emit('tab-changed', this.keyify(value));
            this.currentTab = this.keyify(value);
        },
        firstTabValue (tabs) {
            if (tabs.length === 0) return null;
            return this.keyify(tabs[0]);
        },
        keyify (tab) {
            return tab.toLowerCase().replaceAll(' ', '-');
        }
    }
};
</script>

<style lang="scss" scoped>

.tab-view {
    @apply relative z-0 rounded-lg shadow grid grid-cols-2 divide-y divide-x divide-gray-200 sm:flex sm:divide-y-0
}

</style>
