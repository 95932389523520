<template>
    <div>
        <slot name="button" :open="open">
            <v-button ref="open-button" :label="label" class="w-full" @click="open" />
        </slot>
        <modal
            ref="modal"
            size="md"
            :header="header"
            :open="opened"
            disable-on-click-away
            @close-modal="close"
        >
            <template #content>
                <slot name="content"></slot>
            </template>
            <template #modal-actions>
                <slot name="actions" :close="close"></slot>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from '@outreach/components/shared/modal/index';
import VButton from '@outreach/components/VButton';

export default {
    components: { Modal, VButton },
    props: {
        label: { type: String, required: true },
        header: { type: String, required: true }
    },
    data: () => ({
        opened: false
    }),
    methods: {
        open () {
            this.$emit('open');
            this.opened = true;
        },
        close () {
            this.opened = false;
        }
    }
};
</script>
