import ApiClient from '@services/Http/Api';

export const store = async (payload) => {
    return await ApiClient.post('/dashboard/access-tokens', payload);
};

export const list = async (params = {}) => {
    return await ApiClient.get('/dashboard/access-tokens', { params });
};

export const destroy = async (id) => {
    return await ApiClient.destroy(`/dashboard/access-tokens/${id}`);
};

export const regenerate = async (id) => {
    return await ApiClient.post(`/dashboard/access-tokens/${id}/regenerate`);
};
