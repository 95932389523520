<template>
    <div class="container mx-auto">
        <search-header>
            <template #mobile-header>
                <p class="font-bold mb-2">Search Invitations <span v-show="meta.total">({{ meta.total }})</span></p>
            </template>
            <template #content>
                <div class="flex flex-col gap-2 flex-1">
                    <p class="text-2xl bold hidden md:block">
                        Search Invitations <span v-show="meta.total">({{ meta.total }})</span>
                    </p>
                    <div class="flex-col lg:flex-row flex gap-2">
                        <div class="flex-1">
                            <v-input
                                ref="name-input"
                                v-model="query.q"
                                label="Name / Email"
                                :errors="formErrors.q"
                            />
                        </div>
                        <div class="lg:w-1/4">
                            <v-input
                                id="created-after"
                                v-model="query.created_at.after"
                                type="date"
                                placeholder=""
                                label="Submitted After"
                                :errors="formErrors['created_at.after']"
                                @input="query.created_at.after = $event"
                            />
                        </div>
                        <div class="lg:w-1/4">
                            <v-input
                                id="created-before"
                                v-model="query.created_at.before"
                                type="date"
                                label="Submitted Before"
                                :errors="formErrors['created_at.before']"
                                @input="query.created_at.before = $event"
                            />
                        </div>
                    </div>
                    <div>
                        <v-button ref="import" label="Import" @click="importCsv" />
                        <v-button
                            ref="export-invitations"
                            label="Export..."
                            button-style="primary"
                            @click="exportCsv"
                        />
                    </div>
                </div>
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <paginator-wrap
                :pagination="meta"
                :change-page="($event) => fetchInvitations({ ...query, page: $event })"
            >
                <template #content>
                    <div v-if="loading" class="flex flex-col items-center justify-center">
                        <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                        <span class="text-gray-700">Loading invitations</span>
                    </div>
                    <div v-else>
                        <div v-if="invitations.length > 0">
                            <v-table
                                ref="invitations-table"
                                is-elevated
                                has-actions
                                clickable
                                :rows="invitations"
                                :sort="sorting"
                                @sort-changed="updateSort"
                                @clicked="edit"
                            >
                                <template #default="{ row }">
                                    <v-table-column label="Name" value="name">
                                        <div class="mt-2">
                                            <span><strong class="text-lg">{{ row.name }}</strong></span>
                                        </div>
                                    </v-table-column>
                                    <v-table-column label="Email" value="email">
                                        <div class="mt-2">
                                            <span><strong class="text-lg">{{ row.email }}</strong></span>
                                        </div>
                                    </v-table-column>
                                    <v-table-column v-if="row.meta.property" label="Address" value="address">
                                        <i class="fas fa-home text-brand-dark mr-2"></i>
                                        <div>
                                            <p>{{ row.meta.property.street }}</p>
                                            <p>{{ row.meta.property.city }}, {{ row.meta.property.state }}</p>
                                            <p>
                                                {{ row.meta.property.country || 'USA' }}, {{ row.meta.property.zip }}
                                            </p>
                                        </div>
                                    </v-table-column>
                                    <v-table-column label="Created Date" value="created_at" sortable>
                                        <span>{{ formatDate(row.created_at) }}</span>
                                        <span class="text-sm text-gray-700">{{ fromNow(row.created_at) }}</span>
                                    </v-table-column>
                                </template>
                                <template #dropdown="{ row }">
                                    <button class="edit-invitation" @click="edit(row)">Edit</button>
                                    <button
                                        class="archive-invitation"
                                        @click="archiveDialog(row)"
                                    >Archive
                                    </button>
                                </template>
                            </v-table>
                        </div>
                        <div v-else>
                            <div class="text-center">
                                <p class="text-lg text-gray-700">No invitations found.</p>
                            </div>
                        </div>
                    </div>
                </template>
            </paginator-wrap>
        </transition>
    </div>
</template>

<script>
import services from '@services/Api';
import VLoader from '@outreach/components/VLoader';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import { mapActions, mapState } from 'vuex';
import { debounce, get, isEmpty } from 'lodash';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { formatDate, fromNow } from '@services/Filters';
import VInput from '@outreach/components/inputs/VInput';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import VButton from '@outreach/components/VButton.vue';
import { Alert } from '@services/Alert';
import InvitationSaved from '@events/InvitationSaved';
import PaginatorWrap from '@outreach/components/PaginatorWrap.vue';

export default {
    name: 'Invitations',
    components: {
        PaginatorWrap,
        SearchHeader,
        VLoader,
        VTable,
        VTableColumn,
        VInput,
        VButton
    },
    data: () => ({
        meta: {},
        invitations: [],
        sorting: {
            key: 'created_at',
            direction: 'DESC'
        },
        formErrors: {},
        query: {
            q: undefined,
            created_at: {
                after: undefined,
                before: undefined
            }
        }
    }),
    computed: mapState({
        loading: ({ loader }) => loader.loading,
        user: ({ user }) => user.user,
        covers: ({ invitations }) => invitations.covers
    }),
    watch: {
        async sorting () {
            this.fetchInvitations();
        },
        showArchived () {
            this.fetchInvitations();
        },
        query: {
            async handler () {
                await this.debounceInvitations(this.query);
            },
            deep: true
        }
    },
    created () {
        this.fetchInvitations();
        this.subscribe({
            event: InvitationSaved,
            subscription: () => this.fetchInvitations()
        });
    },
    destroy () {
        this.unsubscribe({ event: InvitationSaved });
    },
    methods: {
        formatDate,
        fromNow,
        ...mapActions({
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        debounceInvitations: debounce(function (params) {
            this.fetchInvitations(params);
        }, 500),
        async fetchInvitations (params = {}) {
            try {
                this.formErrors = {};
                const { data } = await services.admin.invitations.list({
                    ...params,
                    sort_key: this.sorting.key,
                    sort_direction: this.sorting.direction
                });
                this.invitations = data.data;
                this.meta = data.meta;
            } catch (e) {
                this.handleError(e, 'Oops, we were unable to load the list. Please refresh and try again.');
            }
        },
        async handleError (e, defaultMessage = '') {
            this.formErrors = get(e, 'response.data.errors', {});
            if (isEmpty(this.formErrors)) {
                await Alert.fromError(e, defaultMessage);
            }
        },
        updateSort (data) {
            this.sorting = data;
        },
        async edit (invitation) {
            await dialogEmitter.name('InvitationDialog').props({ invitation }).wait();
        },
        async archive (invitation) {
            try {
                await services.admin.invitations.destroy(invitation.id);
                Alert.success('Invitation archived.', 3000);
                this.fetchInvitations();
            } catch (e) {
                this.handleError(e, 'An error occurred while deleting the invitation.');
            }
        },
        async archiveDialog (invitation) {
            await dialogEmitter.name('Confirm')
                .message(`Are you sure you want to delete the invitation for ${invitation.name}?`)
                .props({
                    onSuccess: async (confirmed) => {
                        if (!confirmed) return;
                        this.archive(invitation);
                    }
                })
                .wait();
        },
        importCsv () {
            dialogEmitter
                .name('ImportInvitationsDialog')
                .addListener('csv-imported', () => Alert.info(
                    'Your invitations are being imported, we\'ll notify you when they\'re ready! ' +
                    'This might take a few minutes.'
                ))
                .wait();
        },
        async exportCsv () {
            const params = {
                ...this.query,
                sort_key: this.sorting.key,
                sort_direction: this.sorting.direction
            };

            try {
                await services.admin.invitations.export(params);
                await Alert.info('Your invitations are being exported, we\'ll notify you when they\'re ready!');
            } catch (e) {
                Alert.fromError(e, 'Oops, we aren\'t able to export invitations right now. Please try again later!');
            }
        }
    }
};
</script>
