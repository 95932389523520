<template>
    <div class="w-full">
        <v-card class="w-full relative">
            <template #body>
                <div class="flex flex-col-reverse gap-y-3 lg:flex-row lg:gap-y-0 lg:gap-x-3">
                    <div class="flex flex-col pt-2 lg:w-1/3 lg:p-0">
                        <google-auto-complete
                            v-if="loaded && map"
                            :map="map"
                            @selected="place = $event"
                        />

                        <div class="my-6">
                            <div class="flex items-center gap-12">
                                <div>
                                    <div class="font-thin text-4xl text-brand-regular mb-1">{{ estateCount }}</div>
                                    <div>Households</div>
                                </div>
                                <div>
                                    <div class="font-thin text-4xl text-brand-regular mb-1">
                                        {{ formatToCurrency(price / 100) }}
                                    </div>
                                    <div>$/Month</div>
                                </div>
                            </div>

                            <div class="flex items-center gap-4 py-6">
                                <div class="">Selection Size</div>
                                <div
                                    class="scale-button"
                                    :class="{'bg-brand-regular text-white' : size === 1}"
                                    @click="resize(1)"
                                >S
                                </div>
                                <div
                                    class="scale-button"
                                    :class="{'bg-brand-regular text-white' : size === 2}"
                                    @click="resize(2)"
                                >M
                                </div>
                                <div
                                    class="scale-button"
                                    :class="{'bg-brand-regular text-white' : size === 4}"
                                    @click="resize(4)"
                                >L
                                </div>
                            </div>

                            <button
                                class="bg-brand-regular text-white px-4 py-2 rounded mt-2 w-full"
                                :disabled="territory.length === 0"
                                :class="{'cursor-not-allowed opacity-75' : territory.length === 0}"
                                @click="claimTerritory"
                            >
                                Claim Territory
                            </button>

                        </div>
                    </div>
                    <div class="w-full lg:w-2/3 relative">
                        <mapbox
                            class="min-h-[400px] lg:min-h-[600px]"
                            @init="onMapInitialized"
                        />
                    </div>
                </div>
            </template>
        </v-card>
    </div>
</template>

<script>
import VCard from '@outreach/components/VCard';
import services from '@services/Api';
import { getCoords } from '@turf/turf';
import TerritoryGrid from '@outreach/pages/OutboundProspecting/TerritoryGrid.ts';
import debounce from 'lodash/debounce';
import GoogleAutoComplete from '@outreach/pages/OutboundProspecting/GoogleAutoComplete.vue';
import { Alert } from '@services/Alert';
import Mapbox from '@outreach/pages/OutboundProspecting/Mapbox';
import { formatToCurrency } from '@services/Utils';
import { mapState } from 'vuex';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { ViewPortBBox } from '@outreach/pages/OutboundProspecting/ViewPortBBox';
import merge from 'lodash/merge';
import { loadGoogleMaps } from '@services/GoogleMaps';

export default {
    name: 'TerritoryMap',
    components: {
        Mapbox,
        VCard,
        GoogleAutoComplete
    },
    data () {
        return {
            map: null,
            grid: null,
            loaded: false,
            territory: [],
            claimed: [],
            estateCount: 0,
            price: 0,
            place: null,
            size: 1,
            marker: null,
            order: null
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        paths () {
            return this.territory.map(
                (cell) => getCoords(cell.geometry)[0].map(([lng, lat]) => (`${lat},${lng}`)).join(';'));
        }
    },
    watch: {
        place (newPlace) {
            this.grid.setCenterMarker(
                newPlace.geometry.location.lng(),
                newPlace.geometry.location.lat(),
                newPlace.formatted_address
            );
        }
    },
    async mounted () {
        await loadGoogleMaps('places');
        this.loaded = true;
    },
    methods: {
        formatToCurrency,
        onMapInitialized (map) {
            this.map = map;
            this.grid = new TerritoryGrid(this.map);

            this.grid.onUpdate(this.gridUpdated);

            this.grid.onBoundsChanged((bounds) => {
                this.getClaimed(bounds);
            });

            this.grid.onLoad(() => this.getPendingOrders());
        },
        async getPendingOrders () {
            const response = await services.orders.list(this.user.account_id, {
                per_page: 1,
                sort_direction: 'DESC',
                sort_key: 'created_at',
                status: ['pending'],
                type: 'territories'
            });

            this.order = response.data.data[0];

            if (this.order) {
                this.grid.loadSelected(this.order.territories, this.gridUpdated);
            }
        },
        gridUpdated (error, { territory }) {
            if (error) {
                Alert.error(error.message);
                return;
            }
            this.territory = territory;
            this.estateCount = territory.reduce((acc, cell) => (acc || 0) + (cell.properties.count || 0), 0);
            this.price = territory.reduce((acc, cell) => (acc || 0) + (cell.properties.price || 0), 0);
        },
        async claimTerritory () {
            await this.upsertOrder();
            dialogEmitter.name('TerritoryPaymentDialog')
                .props({ order: this.order })
                .addListener('purchased', () => {
                    Alert.success('Territory claimed successfully');
                    this.getClaimed(new ViewPortBBox(this.map.getBounds()));
                    this.reset();
                })
                .addListener('expired', () => {
                    this.reset();
                })
                .addListener('update', (expiry) => {
                    this.updateOrder(expiry);
                })
                .wait();
        },
        getClaimed: debounce(function (bounds) {
            services.prospecting.listTerritories({ path: bounds.toBoundsString() }).then(response => {
                this.grid.markClaimed(response.data.data);
            });
        }, 500),
        async upsertOrder () {
            this.order ? await this.updateOrder(this.order.meta?.period.end, this.paths) : await this.createOrder();
        },
        async createOrder () {
            try {
                const { data } = await services.prospecting.claimTerritory({ paths: this.paths });
                this.order = data.data;
            } catch (e) {
                Alert.fromError(e, 'Error loading checkout, please try again.');
            }
        },
        async updateOrder (expiry, paths = undefined) {
            try {
                const { data } = await services.orders.update(
                    this.user.account_id,
                    this.order.id,
                    { expires_at: expiry, paths }
                );
                this.order = merge(this.order, data.data);
            } catch (e) {
                await Alert.fromError(e, 'Error updating order, please try again.');
            }
        },
        shouldResize () {
            return this.grid.selectedArea.length === 0 ||
                window.confirm('This will clear your current selection. Are you sure?');
        },
        resize (size) {
            if (!this.shouldResize()) return;

            this.grid.setSize(size);
            this.size = size;
        },
        reset () {
            this.grid.clearSelection();
            this.territory = [];
            this.estateCount = 0;
            this.price = 0;
            this.order = null;
        }
    }
};
</script>

<style scoped>
.scale-button {
    @apply cursor-pointer rounded-full leading-none w-10 h-10 flex items-center justify-center border border-brand-regular;
}
</style>
