"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const WidgetResponse_1 = require("@outreach/components/widgets/WidgetResponse");
class NoteCountResponse extends WidgetResponse_1.WidgetResponse {
    get total() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.total) !== null && _b !== void 0 ? _b : 0;
    }
    get labels() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.records.map(record => record.label)) !== null && _b !== void 0 ? _b : [];
    }
    get values() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.records.map(record => record.count)) !== null && _b !== void 0 ? _b : [];
    }
}
exports.default = NoteCountResponse;
