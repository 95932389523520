<template>
    <div>
        <v-label :text="label" :focused="isFocused" :readonly="readonly">
            <template v-if="!loading">
                <select
                    class="appearance-none p-2 block rounded focus:outline-none transition-all text-gray-800"
                    :class="{
                        'bg-white focus:border-brand-regular border border-solid border-gray-400': !readonly,
                        'border-transparent bg-transparent w-full': readonly
                    }"
                    :aria-label="label"
                    :value="value"
                    :disabled="readonly"
                    @focus="focused = true"
                    @blur="focused = false"
                    @input="$emit('input', $event.target.value)"
                >
                    <option
                        v-for="(option, index) in options"
                        :key="index"
                        :value="option.value"
                    >{{ option.label }}</option>
                </select>

                <div class="absolute top-1/2 right-3 text-gray-700 -translate-t-50 pointer-events-none">
                    <i class="fa fa-chevron-down "></i>
                </div>
            </template>
            <div
                v-else
                class="flex justify-end appearance-none p-2 block rounded focus:outline-none"
                :class="{
                    'bg-white focus:border-brand-regular border border-solid border-gray-400': !readonly,
                    'border-transparent bg-transparent w-full': readonly
                }"
            >
                <v-loader :size="16" class="p-1" />
            </div>
        </v-label>
        <p v-for="(error, index) in errors" :key="index" class="text-red-400 text-xs mb-2">{{ error }}</p>
    </div>
</template>

<script>
import isNil from 'lodash/isNil';
import VLoader from '@outreach/components/VLoader';
import VLabel from '@outreach/components/shared/VLabel';

export default {
    components: { VLoader, VLabel },
    props: {
        loading: { type: Boolean, default: false },
        value: { type: [String, Number, Boolean], default: null },
        label: { type: String, required: true },
        options: { type: Array, required: true },
        errors: { type: Array, required: false, default: () => [] },
        readonly: { type: Boolean, required: false, default: false }
    },
    data: () => ({
        focused: false
    }),
    computed: {
        isFocused () {
            return this.focused || this.hasValue;
        },
        hasValue () {
            return this.value === 0 || Boolean(this.value) || this.hasDisplay;
        },
        hasDisplay () {
            const selectedOption = this.options.find(option => option.value === this.value);
            return !isNil(selectedOption) && !isNil(selectedOption.label) && selectedOption.label.length > 0;
        }
    }
};
</script>
