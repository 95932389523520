<template>
    <div class="container mx-auto">
        <base-table
            ref="base-table"
            type="pending"
            header="Pending Jobs"
            :campaign-actions="campaignActions"
            :job-actions="jobActions"
            :loading="loading"
        >
            <template #character-count="{ campaign }">
                <p class="text-sm text-gray-600">~{{ formatNumber(campaign.character_count) }} characters</p>
            </template>
            <template #job-count="{ campaign }">
                <p class="text-center">{{ campaign.completed }}/{{ campaign.pending + campaign.completed }}</p>
            </template>
        </base-table>

        <simple-machine-modal
            v-if="selectedCampaign && selectedCampaign.id"
            :campaign-id="selectedCampaign.id"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SimpleMachineModal from '@outreach/components/machine/SimpleMachineModal';
import BaseTable from '@outreach/components/queues/BaseTable';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { formatNumber } from '@services/Utils';

export default {
    components: {
        BaseTable,
        SimpleMachineModal
    },

    data () {
        return {
            selectedCampaign: null,
            campaignActions: [
                {
                    label: 'Regenerate',
                    action: 'pending/regenerateCampaign',
                    confirm: 'Are you sure you want to regenerate all jobs in this campaign?',
                    ref: 'regenerate',
                    buttonStyle: 'info',
                    disable: this.disableRegenerateButton
                },
                {
                    label: 'Delete',
                    action: 'pending/deleteCampaign',
                    confirm: 'Are you sure you want to delete all jobs in this campaign?',
                    ref: 'delete',
                    buttonStyle: 'error'
                },
                {
                    label: 'Change Machines',
                    action: this.changeMachines,
                    ref: 'change-machines',
                    buttonStyle: 'primary'
                },
                {
                    label: 'Assign Associates',
                    action: this.assignAssociates,
                    ref: 'assign-associates',
                    buttonStyle: 'success'
                },
                {
                    label: 'Comments',
                    action: this.openComments,
                    ref: 'comments',
                    buttonStyle: 'info'
                }
            ],
            jobActions: [
                {
                    label: 'Complete',
                    action: 'pending/completeJob',
                    confirm: 'Are you sure you want to complete this job?',
                    ref: 'complete',
                    buttonStyle: 'warning'
                },
                {
                    label: 'Regenerate',
                    action: 'pending/regenerateJob',
                    confirm: 'Are you sure you want to regenerate this job?',
                    ref: 'regenerate',
                    buttonStyle: 'info'
                },
                {
                    label: 'Delete',
                    action: 'pending/deleteJob',
                    confirm: 'Are you sure you want to delete this job?',
                    ref: 'delete',
                    buttonStyle: 'error'
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            loading: 'pending/loading'
        })
    },
    methods: {
        ...mapActions({
            openModal: 'modals/open',
            clearQueue: 'queue/clearQueue'
        }),
        async changeMachines (campaign) {
            this.selectedCampaign = campaign;
            this.openModal('change-machines-modal');
        },
        async assignAssociates (campaign) {
            dialogEmitter.openDialog('AssignAssociates', { campaignId: campaign.id });
        },
        async openComments (campaign) {
            dialogEmitter.openDialog('CommentDialog', { campaign });
        },
        disableRegenerateButton (campaign) {
            return campaign.machine_count === 0;
        },
        formatNumber
    }
};
</script>
