"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RangeConstraint {
    constructor(minValue, maxValue) {
        this.minValue = minValue;
        this.maxValue = maxValue;
    }
    toRange(minimum, maximum) {
        const min = Number(minimum || this.minValue);
        const defaultMax = min === this.minValue ? min : this.maxValue;
        const max = maximum && maximum >= min ? Number(maximum) : defaultMax;
        return [min, max];
    }
}
exports.default = RangeConstraint;
