<template>
    <td
        class="py-3.5 pl-4 pr-3 sm:pl-6 text-sm"
        :class="{[colAlign]: true}"
        :style="{width}"
    >
        <slot></slot>
    </td>
</template>

<script>
export default {
    props: {
        label: { type: String, required: true },
        value: { type: String, required: true },
        sortable: { type: Boolean, required: false, default: false },
        width: { type: String, required: false, default: 'auto' },
        colAlign: { type: String, required: false, default: 'text-left' }
    },
    mounted () {
        const { label, value, sortable, width, colAlign } = this;

        const addColumnHeader = this.$parent.addColumnHeader || this.$parent.$parent.addColumnHeader;

        if (!addColumnHeader) throw new Error('VTableColumn must be inside a VTable');

        addColumnHeader({ label, value, sortable, width, colAlign });
    }
};
</script>
