<template>
    <v-panel
        title="Edit Add-Ons"
        subtitle="Click and drag to reorder add-ons"
        name="reorder-collaterals"
    >
        <draggable
            v-if="collaterals && collaterals.length > 0"
            ref="sortable"
            v-model="collaterals"
            draggable=".item"
            ghost-class="ghost"
            class="divide-y divide-gray-300"
        >
            <section
                v-for="(row, index) in collaterals"
                ref="row"
                :key="index"
                class="flex items-center item mt-4 draggable"
            >
                <div
                    ref="content"
                    class="flex flex-row justify-between w-full gap-4 py-4"
                >
                    <i class="fa fa-sort mt-2 text-gray-700"></i>
                    <div class="flex flex-wrap sm:flex-nowrap flex-row justify-between w-full gap-4">
                        <div class="flex flex-row flex-1">
                            <div v-if="row.image_path" class="flex-none mr-4">
                                <img :src="row.image_path" width="60" alt="" />
                            </div>
                            <dl>
                                <dt class="flex flex-col">
                                    <span class="text-lg">{{ row.name }}</span>
                                    <span class="text-sm text-gray-700">{{ row.quantity }} in stock</span>
                                </dt>
                                <dd class="text-gray-700 pt-2">{{ row.description }}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </section>
        </draggable>

        <v-call-to-action v-else title="No add-ons found." />
    </v-panel>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable';
import VCallToAction from '@outreach/components/shared/VCallToAction';
import VPanel from '@outreach/components/shared/VPanel';

export default {
    name: 'ReorderCollaterals',
    components: { draggable, VCallToAction, VPanel },
    computed: {
        ...mapState({
            loading: ({ loader }) => loader.loading,
            account: ({ accounts }) => accounts.account
        }),
        collaterals: {
            get () {
                return this.account.collaterals;
            },
            async set (value) {
                await this.save(value);
                this.$store.commit('accounts/setCollaterals', value);
            }
        }
    },
    methods: {
        ...mapActions({
            updateAccount: 'accounts/updateAccount'
        }),
        async save (value) {
            await this.updateAccount({
                id: this.account.id,
                collaterals: value.map((collateral, index) => ({ ...collateral, order: index }))
            });
        }
    }
};
</script>

<style scoped lang="scss">
.draggable {
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
}
</style>
