<template>
    <div class="list bg-white shadow-md p-4 font-light">
        <div ref="first-column" class="flex flex-col justify-between p-3">
            <div class="text-xl main-address font-normal">
                <div>{{ property.address }}</div>
                <div>
                    <span>{{ property.city }}</span>
                    <span>{{ property.state }}</span>
                </div>
                <div>{{ property.zip }}</div>
            </div>
            <div v-if="hasResidentInfo" ref="resident-info" class="pt-2">
                <div class="font-bold">Primary Resident</div>
                <div class="break-all flex items-baseline">
                    <i class="fa mr-3 fa-user text-brand-dark"></i>
                    {{ property.primary_first }} {{ property.primary_last }}
                </div>
                <div v-if="property.primary_phone1" class="flex items-baseline">
                    <i class="fa mr-3 fa-phone text-brand-dark"></i>
                    {{ property.primary_phone1 }}
                </div>
                <div v-if="property.primary_email1" class="break-all flex items-baseline">
                    <i class="fa mr-3 fa-envelope text-brand-dark"></i>
                    {{ property.primary_email1 }}
                </div>
            </div>
        </div>
        <div ref="second-column" class="py-3 px-6">
            <div>
                <div class="font-bold">Details</div>
                <div v-if="property.type" class="flex justify-between">
                    <span class="font-normal">Type:</span>
                    {{ property.type }}
                </div>
                <div v-if="property.listing_status" class="flex justify-between">
                    <span class="font-normal">Status:</span>
                    {{ property.listing_status }}
                </div>
                <div v-if="property.sq_ft" class="flex justify-between">
                    <span class="font-normal">Sq. Ft.:</span>
                    {{ formatNumber(property.sq_ft) }}
                </div>
                <div v-if="property.year_built" class="flex justify-between">
                    <span class="font-normal">Year Built:</span>
                    {{ property.year_built }}
                </div>
                <div v-if="property.listing_price" class="flex justify-between">
                    <span class="font-normal">Listing Price:</span>
                    {{ formatToCurrency(property.listing_price) }}
                </div>
                <div v-if="property.est_value" class="flex justify-between">
                    <span class="font-normal">Est. Value:</span>
                    {{ formatToCurrency(property.est_value) }}
                </div>
            </div>
        </div>
        <div ref="third-column" class="p-3">
            <div>
                <div class="font-bold">Owner ({{ property.owner_type }})</div>
                <div>{{ property.owner }}</div>
                <div>{{ property.owner_2 }}</div>
                <div v-if="property.owner_occ" class="mt-2">
                    <span class="border border-brand-dark text-brand-dark  px-2 py-1">Owner Occupied</span>
                </div>
            </div>
            <div class="pt-2">
                <div class="font-bold">Mail Address</div>
                <div>{{ property.mail_address }}</div>
                <div>{{ property.mail_city }}, {{ property.mail_state }} {{ property.mail_zip }}</div>

            </div>
            <div v-if="property.mail_vacant" class="pt-2">
                <span class="border border-brand-dark text-brand-dark px-2 py-1">Vacant</span>
            </div>
        </div>
        <div ref="actions" class="p-3">
            <slot name="actions"></slot>
        </div>
    </div>
</template>
<script>
import { formatNumber, formatToCurrency } from '@services/Utils';

export default {
    name: 'PropertyCard',
    props: {
        property: { type: Object, default: () => ({}) }
    },
    computed: {
        hasResidentInfo () {
            return this.property.primary_first || this.property.primary_last;
        }
    },
    methods: {
        formatToCurrency,
        formatNumber
    }
};

</script>
<style scoped>
.list {
    @apply border-b;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.list:last-child {
    @apply border-b-0;
}

.main-address {
    line-height: 1;
    letter-spacing: 1.2px;
}
</style>
