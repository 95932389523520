<template>
    <div>
        <header class="py-6 px-5 md:px-0 md:pt-0 empty:hidden">
            <slot name="header"></slot>
        </header>
        <table class="w-full divide-y divide-gray-300" :class="{'shadow border md:rounded-lg': isElevated}">
            <thead class="bg-gray-50">
                <tr>
                    <th v-if="hasSelection" class="py-3.5 pl-4 pr-3 sm:pl-6">
                        <v-checkbox :value="allSelected" size="sm" @input="selectAll" />
                    </th>
                    <th
                        v-for="(column, index) in normalizedColumns"
                        :key="index"
                        scope="col"
                        class="py-3.5 pl-4 pr-3 sm:pl-6 text-sm font-semibold text-gray-900"
                        :class="{[column.colAlign]: true}"
                        :style="{width}"
                    >
                        <span
                            v-if="!column.sortable"
                            class="table-column-label whitespace-no-wrap ellipsis"
                        >{{ column.label }}</span>
                        <button
                            v-else
                            class="inline-flex"
                            :class="{'font-bold': sort.key === column.value }"
                            @click="sorted(column)"
                        >
                            {{ column.label }}
                            <span v-if="sort.key === column.value" class="table-order-chevron">
                                <i
                                    class="fa text-xs"
                                    :class="{
                                        'fa-chevron-down': sort.direction === 'DESC',
                                        'fa-chevron-up': sort.direction === 'ASC',
                                    }"
                                ></i>
                            </span>
                        </button>
                    </th>
                    <th v-if="hasActions" aria-hidden="true"></th>
                </tr>
            </thead>
            <transition-group
                name="list"
                tag="tbody"
                class="divide-y divide-gray-200 bg-white"
                :css="animated"
            >
                <tr
                    v-for="(row, index) in rows"
                    :key="getItemKey(row, index)"
                    :class="{
                        'deleted': row.deleted_at != null,
                        'border-b': (index + 1) !== rows.length,
                        'hover:bg-gray-100 cursor-pointer': isRowClickable(row),
                    }"
                    @click="isRowClickable(row) && $emit('clicked', row)"
                >
                    <td v-if="hasSelection" class="py-3.5 pl-4 pr-3 sm:pl-6" :style="{width: '60px'}" @click.stop>
                        <v-checkbox :value="selected.has(row.id)" size="sm" @input="select(row.id)" />
                    </td>
                    <slot :row="row"></slot>
                    <td v-if="showDropdown" class="text-right py-3.5 pl-4 pr-3 sm:pl-6 dropdown" @click.stop>
                        <dropdown>
                            <template #priority-options><slot name="priority-buttons" :row="row"></slot></template>
                            <template #content><slot name="dropdown" :row="row"></slot></template>
                        </dropdown>
                    </td>
                </tr>
            </transition-group>
        </table>
    </div>
</template>

<script>
import Dropdown from '../dropdown/index';
import isObject from 'lodash/isObject';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import VCheckbox from '@outreach/components/inputs/VCheckbox';

export default {
    components: { Dropdown, VCheckbox },
    props: {
        rows: { type: Array, default: () => [] },
        hasSelection: { type: Boolean, default: false },
        hasActions: { type: Boolean, default: false },
        actions: { type: Array, default: () => ['Delete', 'Edit'] },
        isElevated: { type: Boolean, default: true },
        sort: { type: Object, required: false, default: () => ({}) },
        clickable: { type: Boolean, required: false, default: false },
        selected: { type: Set, default: () => new Set() },
        width: { type: String, required: false, default: 'auto' },
        animated: { type: Boolean, default: true }
    },
    data: () => ({
        options: [],
        columns: []
    }),
    computed: {
        allSelected () {
            return this.rows.length === this.selected.size;
        },
        showDropdown () {
            return this.hasActions && (this.$scopedSlots.dropdown || this.$scopedSlots['priority-buttons']);
        },
        normalizedColumns () {
            return (this.columns || []).map(column =>
                isObject(column) ? column : { label: column }
            );
        }
    },
    watch: {
        selected () {
            this.$emit('selection-changed', this.selected);
        }
    },
    methods: {
        getItemKey (row, index) {
            if (row.id != null) {
                return row.id;
            }
            return index;
        },
        isRowClickable (row) {
            return this.clickable && !row.deleted_at;
        },
        addColumnHeader (column) {
            this.columns = uniqBy(union(this.columns, [column]), 'value');
        },
        sorted (column) {
            const direction = this.sortDirection(column);
            this.$emit('sort-changed', {
                key: direction ? column.value : undefined,
                direction
            });
        },
        sortDirection (column) {
            if (this.sort.key !== column.value) {
                return 'ASC';
            }
            return this.reverseDirection();
        },
        reverseDirection () {
            switch (this.sort?.direction) {
                case 'ASC':
                    return 'DESC';
                case 'DESC':
                    return undefined;
                default:
                    return 'ASC';
            }
        },
        select (id) {
            if (this.selected.has(id)) {
                return this.deselect(id);
            }
            this.handleSelectionChanged(new Set([...this.selected, id]));
        },
        deselect (id) {
            const values = new Set(this.selected);
            values.delete(id);
            this.handleSelectionChanged(values);
        },
        selectAll () {
            if (this.allSelected) {
                return this.deselectAll();
            }
            this.handleSelectionChanged(new Set([...this.selected, ...this.rows.map(row => row.id)]));
        },
        deselectAll () {
            this.handleSelectionChanged(new Set());
        },
        handleSelectionChanged (values) {
            this.$emit('selection-changed', values);
        }
    }
};
</script>

<style lang="scss" scoped>
    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: scale(0.9) translateX(30px);
    }
    .deleted {
        @apply bg-gray-200 ring-inset ring ring-red-300;
    }
    .table-order-chevron {
        @apply ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 w-5 h-5 flex items-center justify-center;
    }
    .table-column-label {
        @apply text-gray-700 text-xs uppercase tracking-widest overflow-hidden;
    }
</style>
