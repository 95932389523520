<template>
    <v-button
        button-style="primary"
        :label="label"
        :disabled="disabled"
        :size="size"
        @click="sendEmail"
    >
        <template #before-label>
            <slot name="before-label"></slot>
        </template>
    </v-button>
</template>

<script>
import VButton from '@outreach/components/VButton';
import services from '@services/Api';
import { Alert } from '@services/Alert';

export default {
    name: 'MailToButton',
    components: {
        VButton
    },
    props: {
        contactId: { type: Number, required: true },
        templateId: { type: Number, required: true },
        disabled: { type: Boolean, default: false },
        label: { type: String, default: 'EMAIL' },
        size: { type: String, default: 'md' }
    },
    methods: {
        async sendEmail () {
            try {
                const { data } = await services.contacts.mailto(this.contactId, this.templateId);
                location.href = data.data.link;
            } catch (e) {
                await Alert.error('Could not send email, please try again');
            }
        }
    }
};
</script>
