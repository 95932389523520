<template>
    <div>
        <p v-for="(error, index) in errors" :key="index" class="text-red-400 text-xs mb-2">{{ error }}</p>
    </div>
</template>

<script>
export default {
    name: 'FormErrors',
    props: {
        errors: { type: [Array, String], required: false, default: () => [] }
    },
    computed: {
        errorMessages () {
            return Array.isArray(this.errors) ? this.errors : [this.errors];
        }
    }
};
</script>
