<template>
    <div>
        <v-card :has-body-padding="false" class="p-8">
            <div slot="body">
                <h2 class="text-lg text-gray-900 mb-4">Update User</h2>
                <div>
                    <div class="flex flex-col md:flex-row mb-6">
                        <v-input
                            v-model="userDetails.firstName"
                            class="flex-1 mb-6 md:mb-0 md:mr-4"
                            label="First Name"
                            :errors="formErrors.first_name"
                        />
                        <v-input
                            v-model="userDetails.lastName"
                            class="flex-1"
                            label="Last Name"
                            :errors="formErrors.last_name"
                        />
                    </div>
                    <v-json-input
                        v-model="userDetails.phones"
                        header="Phone(s)"
                        type="tel"
                        key-label="Label"
                        value-label="Phone Number"
                        class="mb-4"
                    />
                    <v-input
                        v-model="userDetails.email"
                        class="mb-8"
                        label="Email"
                        :errors="formErrors.email"
                    />
                    <v-button
                        v-tooltip="isValid ? null : 'All fields must be filled'"
                        button-style="primary"
                        label="Save Changes"
                        :disabled="!isValid"
                        @click="updateUserInfo"
                    />
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';
import services from '@services/Api';
import VCard from '@outreach/components/VCard';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import VJsonInput from '@outreach/components/inputs/VJsonInput.vue';

export default {
    components: { VJsonInput, VCard, VButton, VInput },
    data: () => ({
        address: {},
        formErrors: {},
        userDetails: {
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            phones: {}
        }
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        isValid () {
            return (Object.entries(this.userDetails.phones).every(([key, value]) => key !== '' && value !== ''));
        }
    },
    created () {
        this.userDetails.email = this.user.email;
        this.userDetails.firstName = this.user.first_name;
        this.userDetails.lastName = this.user.last_name;
        this.userDetails.phone = this.user.phone;
        this.userDetails.phones = this.user.phones;
    },
    methods: {
        ...mapMutations({
            setUser: 'user/setUser'
        }),
        ...mapActions({
            getUser: 'user/getUser'
        }),
        async updateUserInfo () {
            this.formErrors = {};

            try {
                const { data } = await services.user.updateUserInformation(this.user.id, {
                    first_name: this.userDetails.firstName,
                    last_name: this.userDetails.lastName,
                    email: this.userDetails.email,
                    phone: this.userDetails.phone,
                    phones: this.userDetails.phones
                });

                this.setUser(data.data);
                this.$toasted.success('User details have been updated');
            } catch ({ response }) {
                this.formErrors = get(response, 'data.errors', {});
            }
        }
    }
};
</script>
