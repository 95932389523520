import ApiClient from '@services/Http/Api';

export const listCompleted = (params) => {
    return ApiClient.get('/dashboard/admin/queues/completed', { params });
};

export const listCompletedJobs = (campaignId, params) => {
    return ApiClient.get(`/dashboard/admin/queues/campaigns/${campaignId}/completed`, { params });
};

export const listFailed = () => {
    return ApiClient.get('/dashboard/admin/queues/failed');
};

export const listPending = (params) => {
    return ApiClient.get('/dashboard/admin/queues', { params });
};

export const listReserved = (params) => {
    return ApiClient.get('/dashboard/admin/queues/reserved', { params });
};

export const listReservedJobs = (campaignId, params) => {
    return ApiClient.get(`/dashboard/admin/queues/campaigns/${campaignId}/reserved`, { params });
};

export const listCampaignJobs = (campaignId, params = {}) => {
    return ApiClient.get(`/dashboard/admin/queues/campaigns/${campaignId}`, { params });
};

export const releaseCampaign = (campaignId) => {
    return ApiClient.post(`/dashboard/admin/queues/campaigns/${campaignId}/release`);
};

export const releaseJob = (job) => {
    return ApiClient.post(`/dashboard/admin/queues/jobs/${job.payload.job_id}/release`);
};

export const completeJob = (job) => {
    return ApiClient.post(`/dashboard/admin/queues/jobs/${job.payload.job_id}/complete`);
};

export const deleteJob = (job) => {
    return ApiClient.destroy(`/dashboard/admin/queues/jobs/${job.payload.job_id}`);
};

export const deleteSingleJob = (job) => {
    return ApiClient.destroy(`/dashboard/admin/queues/jobs/single/${job.id}`);
};

export const regenerateJob = (job) => {
    return ApiClient.post(`/dashboard/admin/queues/jobs/${job.payload.job_id}/regenerate`);
};

export const deleteCompletedJob = (completedId) => {
    return ApiClient.destroy(`/dashboard/admin/queues/completed/jobs/${completedId}`);
};

export const regenerateCampaignRenders = (campaignId) => {
    return ApiClient.post(`/dashboard/admin/queues/campaigns/${campaignId}/regenerate`);
};

export const deleteJobsForCampaign = (campaignId) => {
    return ApiClient.destroy(`/dashboard/admin/queues/campaigns/${campaignId}`);
};

export const retryCompletedJob = (job) => {
    return ApiClient.post(`/dashboard/admin/queues/completed/jobs/${job.id}/retry`, job);
};

export const retryCampaign = (campaignId) => {
    return ApiClient.post(`/dashboard/admin/queues/campaigns/${campaignId}/retry`);
};

export const retryFailedJob = (id) => {
    return ApiClient.post(`/dashboard/admin/queues/failed/${id}/retry`);
};

export function clear () {
    return ApiClient.destroy('/dashboard/admin/queues/jobs');
}

export function stats () {
    return ApiClient.get('/dashboard/admin/queues/stats');
}

export const listSingleJobs = (params) => {
    return ApiClient.get('/dashboard/admin/queues/jobs', { params });
};
