import services from '@services/Api';
import ImpersonateUser from '@events/ImpersonateUser';
import StopImpersonating from '@events/StopImpersonating';

const state = {
    user: null,
    imposter: null
};

const impersonateUserSubscription = (context, event) => {
    context.commit('user/setImposter', JSON.parse(event.data.impersonated_by), { root: true });
    context.dispatch('notifications/sync', null, { root: true });
};

const stopImpersonatingSubscription = (context) => {
    context.commit('user/setImposter', null, { root: true });
    context.dispatch('notifications/sync', null, { root: true });
};

const actions = {
    getUser: async ({ commit }) => {
        try {
            const { data } = await services.users.currentUser({
                include: 'roles, account.nav_items, senderAddress, current_team, account.outreachTemplate'
            });

            commit('setUser', data.data);
        } catch (e) {
            commit('setUser', null);
        }
    },
    subscribe: ({ dispatch }) => {
        dispatch('events/subscribe', {
            event: ImpersonateUser,
            subscription: impersonateUserSubscription
        }, { root: true });
        dispatch('events/subscribe', {
            event: StopImpersonating,
            subscription: stopImpersonatingSubscription
        }, { root: true });
    },
    unsubscribe: ({ dispatch }) => {
        dispatch('events/unsubscribe', {
            event: ImpersonateUser,
            subscription: impersonateUserSubscription
        }, { root: true });
        dispatch('events/unsubscribe', {
            event: StopImpersonating,
            subscription: stopImpersonatingSubscription
        }, { root: true });
    }
};

const mutations = {
    setUser: (state, user) => {
        state.user = user;
    },
    setImposter: (state, imposter) => {
        state.imposter = imposter;
    }
};

export default { namespaced: true, state, actions, mutations };
