<template>
    <div class="p-8 rounded bg-white shadow">
        <div class="mb-4">
            <div class="flex items-start gap-3">
                <div><i class="fas fa-bullhorn text-gray-700"></i></div>
                <div class="font-bold">Stale Campaigns</div>
            </div>
        </div>

        <div
            v-for="(campaign, index) in campaigns"
            :key="`campaign-${index}`"
            class="flex items-center mb-2"
        >
            <div class="border-r border-gray-200 px-4 text-center">
                <div class="text-xs uppercase text-gray-500">notes</div>
                <div class="text-brand-regular font-bold">{{ campaign.contacts_count }}</div>
            </div>
            <div class="flex flex-col py-2 px-4">
                <router-link :to="{name:'campaign-review', params: {campaign: campaign.id}}">
                    <div class="underline hover:text-brand-regular">{{ campaign.name }}</div>
                </router-link>
                <div class="text-sm italic font-thin">{{ usernameWhenDefined(campaign) }}</div>
            </div>
            <div class="ml-auto">{{ fromNow(campaign.created_at) }}</div>
        </div>
    </div>
</template>

<script>
import { fromNow } from '@services/Filters';
import { staleCampaigns } from '@services/Api/Customers';
import { Alert } from '@services/Alert';
import { mapActions, mapState } from 'vuex';
import CampaignUpdated from '@events/CampaignUpdated';

export default {
    name: 'StaleCampaigns',
    data () {
        return {
            campaigns: []
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    created () {
        this.getCampaigns();

        this.subscribe({
            event: CampaignUpdated,
            subscription: () => this.getCampaigns()
        });
    },
    destroyed () {
        this.unsubscribe({ event: CampaignUpdated });
    },
    methods: {
        fromNow,
        ...mapActions({
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        usernameWhenDefined (campaign) {
            return campaign.account.users[0]?.name;
        },
        async getCampaigns () {
            try {
                const { data } = await staleCampaigns.oldest(this.user.id);
                this.campaigns = data.data;
            } catch (e) {
                Alert.fromError(e, 'An error occurred when trying to list the campaigns.');
            }
        }
    }
};
</script>
