<template>
    <i v-tooltip="text" class="fa-regular cursor-pointer" :class="iconType"></i>
</template>

<script>
export default {
    name: 'VHelpIcon',
    props: {
        text: { type: String, required: true },
        type: {
            validator: (value) => ['help', 'info'].includes(value),
            default: 'help'
        }
    },
    computed: {
        iconType () {
            return this.type === 'help' ? 'fa-circle-question' : 'fa-circle-info';
        }
    }
};
</script>
