<template>
    <div class="flex items-center xl:flex-col">
        <button
            :id="`delivery-${contactId}-delete`"
            :disabled="deleteDisabled"
            class="button-icon mx-4 xl:mx-0 xl:mb-2"
            :class="{
                'cursor-not-allowed opacity-40': deleteDisabled,
                'hover:bg-red-500': !deleteDisabled
            }"
            @click.prevent="$emit('delete')"
        >
            <span class="fas fa-times"></span>
        </button>
        <button
            :id="`delivery-${contactId}-edit`"
            class="button-icon"
            @click.prevent="$emit('edit')"
        >
            <span class="fas fa-pen"></span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'ContactActions',
    props: {
        deleteDisabled: {
            type: Boolean,
            default: false
        },
        contactId: {
            type: Number,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.button-icon {
  @apply text-white bg-gray-400 rounded-full w-6 h-6 flex items-center justify-center;
}
</style>
