"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Coordinate = void 0;
class Coordinate {
    constructor(coordinate) {
        this.x = coordinate.lat();
        this.y = coordinate.lng();
    }
}
exports.Coordinate = Coordinate;
