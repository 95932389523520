<template>
    <div>
        <p class="font-medium text-sm text-gray-700 mb-1">{{ header }}</p>
        <div class="divide-y">
            <div
                v-for="(detail, index) in serializedValue"
                :key="index"
                class="py-2"
            >
                <div class="flex flex-row items-center gap-2 first:pt-0">
                    <div class="flex flex-col md:flex-row gap-2 items-center w-full">
                        <v-input
                            :ref="`attribute-${index}-key`"
                            v-model="detail.key"
                            :label="keyLabel"
                            class="w-full"
                        />
                        <v-input
                            :ref="`attribute-${index}-value`"
                            v-model="detail.value"
                            :label="valueLabel"
                            :type="type"
                            class="w-full"
                        />
                    </div>
                    <button :ref="`attribute-${index}-delete`" class="ml-2" @click.prevent="deleteDetail(index)">
                        <i class="fa fa-times text-lg text-red-600 text-right"></i>
                    </button>
                </div>
                <div v-if="showError(index)" class="text-red-500 text-sm">{{ error }}</div>
            </div>
        </div>
        <v-button
            ref="add-attribute"
            outlined
            label="Add"
            button-style="primary"
            size="sm"
            @click.prevent="add"
        />
    </div>
</template>

<script>
import { arrayToObject, DefaultAttribute, objectToArray } from '@services/Utils';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import { map, uniq } from 'lodash';

export default {
    name: 'VJsonInput',
    components: { VButton, VInput },
    props: {
        value: { type: [Array, Object], default: () => ({}) },
        header: { type: String, required: true },
        keyLabel: { type: String, default: 'Key' },
        valueLabel: { type: String, default: 'Value' },
        type: { type: String, default: 'text' }
    },
    data () {
        return {
            serializedValue: objectToArray(this.value || {}),
            error: null
        };
    },
    watch: {
        serializedValue: {
            handler () {
                const keys = map(this.serializedValue, (keyPair) => keyPair.key.toLowerCase());
                this.error = keys.length !== uniq(keys).length ? `${this.keyLabel} already exists` : null;

                if (this.error) {
                    return;
                }

                this.$emit('input', arrayToObject(this.serializedValue));
            },
            deep: true
        }
    },
    methods: {
        add () {
            this.serializedValue.push({ ...DefaultAttribute });
        },
        deleteDetail (index) {
            this.serializedValue.splice(index, 1);
        },
        showError (index) {
            return this.error && index === this.serializedValue.length - 1;
        }
    }
};
</script>
