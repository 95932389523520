<template>
    <div class="mention-container">
        <slot name="left"></slot>
        <div v-click-outside="onOutsideClick" class="relative">
            <action-bar-button :active="showFlyout" tooltip="Insert a Variable" @click="showFlyout = !showFlyout" />
            <flyout :show-flyout="showFlyout">
                <drilldown-menu
                    :items="tags"
                    title="SELECT A VARIABLE"
                    @clicked-item="$emit('clicked-item', $event)"
                />
            </flyout>
        </div>
        <font-select v-if="!isEmail" :value="font" @input="$emit('font-select', $event)" />
    </div>
</template>

<script>
import ActionBarButton from '@outreach/components/shared/ActionBarButton.vue';
import DrilldownMenu from '@outreach/components/shared/DrilldownMenu.vue';
import Flyout from '@outreach/components/shared/Flyout.vue';
import FontSelect from '@outreach/components/letter/FontSelect.vue';
import { directive as clickOutside } from '@outreach/mixins/clickAway';

export default {
    name: 'ActionBar',
    components: { FontSelect, ActionBarButton, DrilldownMenu, Flyout },
    directives: {
        clickOutside
    },
    props: {
        tags: { type: Array, required: true },
        isEmail: { type: Boolean, required: true },
        font: { type: Object, default: undefined }
    },
    data: () => ({
        showFlyout: false,
        foo: undefined
    }),
    methods: {
        onOutsideClick () {
            this.showFlyout = false;
        }
    }

};
</script>
<style lang="scss" scoped>
.mention-container {

    @apply flex flex-shrink -mb-1 justify-end border-r border-l border-t border-gray-400 rounded-t p-3 pb-4;

    ::-webkit-scrollbar {
        display: none;
    }
}

</style>
