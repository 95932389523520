<template>
    <quick-send
        :contacts="contacts"
        :templates="templates"
        :data="data"
        @contact-selected="(selected) => this.data.contact = selected"
        @search="onSearch"
        @submit="submit"
    />
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from 'lodash';
import services from '@services/Api';
import QuickSend from '@outreach/pages/QuickSend/QuickSend';

export default {
    components: { QuickSend },
    data: () => ({
        contacts: [],
        templates: [],
        data: {
            contact: {
                name: ''
            },
            template_id: '',
            message: '',
            photo: null
        }
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    created () {
        this.loadTemplates();
    },
    methods: {
        async loadTemplates () {
            const { data } = await services.templates.list(this.user.account_id, { quick_send: true });
            this.templates = data.data;
        },
        onSearch (newVal) {
            this.clearContacts();
            this.debounceFindContact(newVal);
        },
        clearContacts () {
            this.loading = true;
            this.contacts = [];
        },
        debounceFindContact: debounce(function (q) {
            this.findContact(q);
        }, 250),
        async findContact (q) {
            this.loading = true;
            const { data } = await services.contacts.list(this.user.account_id, { q });
            this.contacts = data.data;
            this.loading = false;
        },
        async submit () {
            const { data } = await services.campaigns.quickSend({
                contact_id: this.data.contact.id,
                template_id: this.data.template_id,
                custom_message: this.data.message,
                photo: this.data.photo
            });
            this.$router.push({
                name: 'campaign-review',
                params: {
                    campaign: data.data.id
                }
            });
        }
    }
};
</script>
