import ApiClient from '@services/Http/Api';

export const list = (accountId, params = {}) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/contacts`, { params });
};

export const exportAll = (accountId, params = {}) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/contacts/export`, params);
};

export const destroy = (accountId, contactId) => {
    return ApiClient.destroy(`/dashboard/accounts/${accountId}/contacts/${contactId}`);
};

export const restore = (accountId, params = {}) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/contacts/restore`, params);
};

export const destroyMany = (accountId, params = {}) => {
    return ApiClient.destroy(`/dashboard/accounts/${accountId}/contacts`, params);
};

export const fetch = contactId => {
    return ApiClient.get(`/dashboard/contacts/${contactId}`);
};

export const update = (contactId, contact) => {
    return ApiClient.patch(`/dashboard/contacts/${contactId}`, contact);
};

export const requestContactAddress = (contactId) => {
    return ApiClient.post(`/dashboard/contacts/${contactId}/request-address`);
};

export const create = (accountId, contact) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/contacts`, contact);
};

export const sendMany = (ids) => {
    return ApiClient.post('/dashboard/campaigns/from-contacts', { ids });
};

export const importCsv = (accountId, data) => {
    return ApiClient.upload(`/dashboard/accounts/${accountId}/contacts/import`, data);
};

export const deliverySummary = (accountId, params = {}) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/delivery-summary`, { params });
};
export const deliverySummaryPreview = (accountId, params = {}) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/delivery-summary/preview`, { params });
};

export const publicContact = (id, params = {}) => {
    return ApiClient.get(`/dashboard/contacts/${id}/public`, { params });
};

export const updatePublicContact = (id, contact) => {
    return ApiClient.patch(`/dashboard/contacts/${id}/public`, contact);
};

export const mailto = (contactId, templateId) => {
    return ApiClient.get(`/dashboard/contacts/${contactId}/templates/${templateId}/mailto`);
};
