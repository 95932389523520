"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointDrawMode = void 0;
const GooglePolygon_1 = require("@outreach/pages/OutboundProspecting/GooglePolygon");
const EstateCluster_1 = require("@outreach/pages/OutboundProspecting/EstateCluster");
const config_1 = require("@global/config");
class PointDrawMode {
    constructor(map) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.onCompleteCallback = () => { };
        this.map = map;
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: false,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    google.maps.drawing.OverlayType.POLYGON
                ]
            },
            map: null,
            polygonOptions: {
                draggable: true,
                editable: true,
                fillColor: config_1.brandColor,
                fillOpacity: 0.5,
                strokeColor: config_1.brandColor,
                strokeWeight: 3
            }
        });
    }
    onComplete(callback) {
        this.onCompleteCallback = callback;
        return this;
    }
    disable() {
        this.drawingManager.setMap(null);
        this.drawingManager.setDrawingMode(null);
        if (this.listener) {
            google.maps.event.removeListener(this.listener);
        }
    }
    enable(onPolygonComplete) {
        google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => __awaiter(this, void 0, void 0, function* () {
            yield this.onCompleteCallback();
            onPolygonComplete(new GooglePolygon_1.GooglePolygon({ polygon, cluster: new EstateCluster_1.EstateCluster(this.map) }));
        }));
        this.drawingManager.setMap(this.map);
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    }
}
exports.PointDrawMode = PointDrawMode;
