<template>
    <v-table
        :rows="campaigns"
        has-actions
        :sort="sort"
        @sort-changed="sort = $event; $emit('sort-changed', $event)"
    >
        <template #default="{row}">
            <v-table-column label="name" value="name">
                <div class="flex flex-col md:flex-row gap-2 items-center">
                    <img v-if="row.thumbnail" :src="row.thumbnail" :alt="row.name" class="w-32" />
                    <div
                        v-else
                        v-tooltip="'No Available Photo'"
                        class="justify-center bg-gray-400 inline-flex px-2 py-1 rounded w-32 h-16"
                    >
                        <i :id="`shadow-photo-${row.id}`" class="fa fa-image text-6xl"></i>
                    </div>
                    <span>{{ row.name }}</span>
                </div>
            </v-table-column>
            <v-table-column label="Print Date" value="printed_at" sortable>
                <span>{{ formatDate(row.printed_at, "MMM Do YYYY") }}</span>
            </v-table-column>
            <v-table-column label="Note Count" value="notes_sent" class="text-center" sortable>
                <span> {{ row.notes_sent }}</span>
            </v-table-column>
            <v-table-column label="Scan Count" value="scanned" class="text-center" sortable>
                <span> {{ row.scanned }}</span>
            </v-table-column>
            <v-table-column label="Scan Percent" value="scan_percent" class="text-center" sortable>
                <span> {{ scanPercentage(row.scan_percent) }}</span>
            </v-table-column>
        </template>
        <template #priority-buttons="{ row }">
            <button @click="goToCampaign(row.id)">Review</button>
            <button @click="$router.push({ name: 'scan-details', query: {campaign_id: row.id }})">Scans</button>
        </template>
    </v-table>
</template>

<script>
import VTableColumn from '@outreach/components/shared/table/VTableColumn';
import { VTable } from '@outreach/components/shared/table';
import { formatDate } from '@services/Filters';
import { formatNumber } from '@services/Utils';

export default {
    name: 'CampaignScanTable',
    components: { VTableColumn, VTable },
    props: {
        campaigns: { type: Array, required: true }
    },
    data: () => ({
        sort: { key: 'printed_at', direction: 'DESC' }
    }),
    methods: {
        scanPercentage (scanPercent) {
            return scanPercent > 0
                ? formatNumber(
                    scanPercent,
                    ['en-US', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }]
                )
                : 'No Scans';
        },
        goToCampaign (id) {
            this.$router.push({ name: 'campaign-review', params: { campaign: id } });
        },
        formatDate
    }
};
</script>
