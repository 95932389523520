<template>
    <div class="w-full">
        <div v-if="!isUpdate" class="mb-8">
            <p class="text-base text-gray-700">
                Please upload a SVG file with dimensions of 300px by 200px.
                All elements in the SVG file must be converted to paths.
                Use our Illustrator
                <a class="text-brand-regular" :href="downloadTemplate">template</a>
                to size the doodle
            </p>
        </div>
        <div class="flex justify-center flex-row">
            <svg
                v-if="isUpdate"
                class="border border-dashed border-gray-500 mb-4"
                height="150"
                viewBox="0 0 300 200"
                v-html="doodle.svg"
            />
            <vue-dropzone
                v-else
                ref="dropzone"
                class="mb-4"
                accepted="image/svg+xml"
                :errors="formErrors.svg"
                @add-file="update('svg', $event)"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="doodle.title"
                class="ml-auto mb-4 w-full"
                label="Title"
                type="text"
                :errors="formErrors.title"
            />
        </div>
        <div class="flex flex-row">
            <tag-select
                :value="doodle.tags"
                :type="tagType"
                @input="update('tags', $event)"
            />
        </div>
    </div>
</template>

<script>
import VueDropzone from '@outreach/components/shared/dropzone/VueDropzone';
import TagSelect from '@outreach/components/tag-select/TagSelect';
import VInput from '@outreach/components/inputs/VInput';
import { TagType } from '@services/TagType';

export default {
    components: { VueDropzone, TagSelect, VInput },
    props: {
        doodle: { type: Object, required: true },
        formErrors: { type: Object, required: true }
    },
    data () {
        return {
            loading: false,
            title: null
        };
    },
    computed: {
        downloadTemplate () {
            return `${process.env.API_URL}/dashboard/doodle-template`;
        },
        isUpdate () {
            return !!this.doodle.id;
        },
        tagType () {
            return TagType.DOODLES;
        }
    },
    methods: {
        update (name, value) {
            const data = {
                ...this.doodle,
                [name]: value
            };
            this.$emit('update:doodle', data);
        }
    }
};
</script>
