"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
class DeliveryValidator {
    constructor(selectedDelivery) {
        this.BLOCK_LIST = [
            'current resident',
            'current owner'
        ];
        this.MATCHER = /({|@) ?(contact\.(first_|last_)?name) ?}?/g;
        this.delivery = selectedDelivery;
    }
    static checkDirty(selectedDelivery, data) {
        return new DeliveryValidator(selectedDelivery).isDirty(data);
    }
    isDirty(data) {
        return data && this.delivery && this.hasDirtyParts(data);
    }
    isInvalid() {
        return this.hasInvalidContent();
    }
    isContentDirty(data) {
        return this.isPathDirty(data, 'content');
    }
    isQRCodeDirty(data) {
        return this.isPathDirty(data, 'resources.qr_code_url');
    }
    isImageDirty(data) {
        return this.isPathDirty(data, 'photo_path');
    }
    isDoodleDirty(data) {
        const original = (0, lodash_1.get)(this.delivery, 'doodles', []).map((d) => d.id);
        const current = (0, lodash_1.get)(data, 'doodles', []).map((d) => d.id);
        return !(0, lodash_1.isEqual)(original, current);
    }
    hasInvalidContent() {
        return this.content.length === 0;
    }
    hasInvalidName() {
        return this.BLOCK_LIST.includes(this.contactName.toLowerCase()) && this.MATCHER.test(this.content);
    }
    get content() {
        var _a, _b;
        return (_b = (_a = this.delivery) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : '';
    }
    get selectedContact() {
        var _a;
        return (_a = this.delivery) === null || _a === void 0 ? void 0 : _a.contact;
    }
    get contactName() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.delivery) === null || _a === void 0 ? void 0 : _a.contact) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '';
    }
    hasDirtyParts(data) {
        return [
            this.isContentDirty(data),
            this.isDoodleDirty(data),
            this.isQRCodeDirty(data),
            this.isImageDirty(data)
        ].some(Boolean);
    }
    isPathDirty(data, path) {
        const content = (0, lodash_1.get)(data, path);
        return content !== (0, lodash_1.get)(this.delivery, path);
    }
}
exports.default = DeliveryValidator;
