<template>
    <div v-if="bootstrapped" class="bg-gray-200 antialiased font-sans font-medium">
        <div class="flex flex-col min-h-screen">
            <dashboard-nav v-if="user && showHeader" @click:menu="menuOpen = !menuOpen" />

            <alert class="md:ml-nav sticky top-16 z-40" />
            <div class="min-h-full flex flex-1 items-stretch">
                <side-nav v-if="showSidebar" v-click-away="away" :open="menuOpen" />
                <main id="main" class="main-content flex flex-1 flex-col items-stretch" :class="containerClasses">
                    <dashboard-header v-if="showPageTitle" :title="$route.meta.title" />

                    <div :class="contentClasses" class="flex flex-1 justify-center">
                        <transition name="fade" mode="out-in">
                            <router-view :key="$route.fullPath" />
                        </transition>
                    </div>
                </main>
            </div>

            <dialog-manager />
            <portal-target name="modal" multiple />
        </div>
        <helpdesk v-if="helpdeskEnabled" />
    </div>
    <v-loader v-else class="p-8" />
</template>

<script>
import { mixin as clickAway } from '@outreach/mixins/clickAway';
import { mapActions, mapState } from 'vuex';
import SideNav from '@outreach/components/navigation/side-nav/index';
import DashboardNav from '@outreach/components/navigation/dashboard-nav/index';
import DashboardHeader from '@outreach/components/shared/dashboard-header/index';
import * as PusherService from '@services/Pusher';
import CsvRowWasProcessed from '@events/CsvRowWasProcessed';
import CsvWasProcessed from '@events/CsvWasProcessed';
import CampaignUpdated from '@events/CampaignUpdated';
import Alert from '@outreach/components/shared/alert/Alert';
import DialogManager from './dialogs/DialogManager';
import TeamUpdated from '@events/TeamUpdated';
import JobWasCompleted from '@events/JobWasCompleted';
import LinkTrackerWasDeleted from '@events/LinkTrackerWasDeleted';
import VLoader from '@outreach/components/VLoader';
import WebAlert from '@events/WebAlert';
import TemplateUpdated from '@events/TemplateUpdated';
import Helpdesk from '@outreach/components/services/Helpdesk.vue';
import InvitationSaved from '@events/InvitationSaved';
import CsvWasImported from '@events/CsvWasImported';
import { Notification } from '@models/Notification';
import QrCodeScanned from '@events/QrCodeScanned';
import AssetsUpdated from '@events/AssetsUpdated';

export default {
    components: { Helpdesk, DashboardNav, SideNav, DashboardHeader, Alert, DialogManager, VLoader },
    mixins: [clickAway],
    data: () => ({
        menuOpen: false,
        bootstrapped: false
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        containerClasses () {
            return { 'md:pl-nav': this.showSidebar };
        },
        contentClasses () {
            return { 'md:p-6': this.showPageTitle };
        },
        showHeader () {
            return !this.$route.meta.full_screen;
        },
        showSidebar () {
            return this.user && !this.$route.meta.full_screen;
        },
        showPageTitle () {
            if (this.$route.meta.full_screen) {
                return false;
            }
            return this.$route.meta.header !== false;
        },
        helpdeskEnabled () {
            return !['false', 0, false].includes(process.env.HELPDESK_ENABLED);
        }
    },
    watch: {
        $route (to) {
            document.title = `${to.meta.title} | Audience`;
        },
        user () {
            this.setupSocket();
        }
    },
    created () {
        this.bootstrap();
        this.subscribe();
    },
    destroyed () {
        this.teardownSocket();
        this.unsubscribe();
    },
    methods: {
        ...mapActions({
            getUser: 'user/getUser',
            subscribe: 'user/subscribe',
            unsubscribe: 'user/unsubscribe'
        }),
        async bootstrap () {
            await this.getUser();
            this.bootstrapped = true;
            this.setupSocket();
        },
        away () {
            this.menuOpen = false;
        },
        async setupSocket () {
            PusherService.disconnect();
            if (!this.user) {
                return;
            }
            await PusherService.connect(this.user);
            PusherService.listen('qr-code-scanned', (message) => {
                this.$store.dispatch('events/broadcast', new QrCodeScanned(message));
            });
            PusherService.listen('recent-mailing-progress', (message) => {
                this.$store.dispatch('events/broadcast', new CsvRowWasProcessed(message));
            });
            PusherService.listen('recent-mailing-done', (message) => {
                this.$store.dispatch('events/broadcast', new CsvWasProcessed(message));
            });
            PusherService.listen('account-updated', () => {
                this.$store.dispatch('user/getUser');
            });
            PusherService.listen('payments-updated', () => {
                this.$store.dispatch('cards/getCards');
            });
            PusherService.listen('campaign-saved', (message) => {
                this.$store.dispatch('events/broadcast', new CampaignUpdated(message));
            });
            PusherService.listen('team-updated', () => {
                this.$store.dispatch('events/broadcast', new TeamUpdated());
            });
            PusherService.listen('template-updated', (template) => {
                this.$store.dispatch('events/broadcast', new TemplateUpdated(template));
            });
            PusherService.listen('link-log-deleted', (data) => {
                this.$store.dispatch('events/broadcast', new LinkTrackerWasDeleted(data));
            });
            PusherService.listen('notify-deploy', (message) => {
                this.$store.dispatch('alerts/setMessage', {
                    messageType: 'warning',
                    message: 'There was a recent update, please save your work and refresh.',
                    duration: null
                });
            });
            PusherService.listen('job-completed', () => {
                this.$store.dispatch('events/broadcast', new JobWasCompleted());
            });
            PusherService.listen('web-alert', (message) => {
                this.$store.dispatch('events/broadcast', new WebAlert(message));
                this.$store.dispatch('notifications/push', Notification.fromEvent(message));
            });

            PusherService.listen('plan-saved', (message) => {
            // dispatch plan was updated\
            // this.$store.dispatch('events/broadcast', new WebAlert(message));
            });
            PusherService.listen('invitation-saved', (message) => {
                this.$store.dispatch('events/broadcast', new InvitationSaved(message));
            });
            PusherService.listen('csv-imported', (message) => {
                this.$store.dispatch('events/broadcast', new CsvWasImported(message));
            });
            PusherService.listen('notification', (message) => {
                this.$store.dispatch('notifications/push', Notification.fromEvent(message));
            });
            PusherService.listen('assets-updated', () => {
                this.$store.dispatch('events/broadcast', new AssetsUpdated());
            });
        },
        teardownSocket () {
            PusherService.disconnect();
        }
    }
};
</script>
