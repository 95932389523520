"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const markerwithlabel_1 = require("@googlemaps/markerwithlabel");
class EstateMarker extends markerwithlabel_1.MarkerWithLabel {
    constructor(estates, map) {
        super({
            labelContent: '',
            map,
            position: estates[0].location,
            title: estates[0].address,
            zIndex: google.maps.Marker.MAX_ZINDEX,
            icon: { path: '' }
        });
        this.estates = estates;
        this.labelContent = this.createMarker();
    }
    getClass() {
        return 'flex bg-brand-regular items-center justify-center border-2 border-white h-8 w-8 rounded-full';
    }
    createMarker() {
        const count = this.estates.length > 1 ? this.estates.length.toString() : '';
        return `<div class="${this.getClass()}"><p class="text-white font-bold leading-none">${count}</p></div>`;
    }
    count() {
        return this.estates.length;
    }
}
exports.default = EstateMarker;
