<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <div class="w-full flex-none">
                    <div class="flex flex-col lg:flex-row gap-2">
                        <v-input
                            v-model="query.q"
                            class="w-full lg:w-1/2"
                            label="Search"
                        />

                        <div class="flex gap-2 lg:w-1/2">
                            <v-input
                                id="submitted-after"
                                v-model="query.submitted_at.after"
                                type="date"
                                class="flex-1"
                                placeholder=""
                                label="Submitted After"
                                @input="query.submitted_at.after = $event"
                            />
                            <v-input
                                id="submitted-before"
                                v-model="query.submitted_at.before"
                                type="date"
                                class="flex-1"
                                placeholder=""
                                label="Submitted Before"
                                @input="query.submitted_at.before = $event"
                            />
                        </div>
                    </div>
                    <div class="w-full flex gap-2 p-1">
                        <v-checkbox v-model="query.archived" label="Show Archived" class="lg:col-span-1" />
                        <v-toggle
                            id="hide_printed"
                            v-model="query.hide_printed"
                            label-enable-text="Hiding Printed"
                            label-disable-text="Showing Printed"
                        />
                    </div>
                </div>
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" key="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading campaigns</span>
            </div>
            <div v-else>
                <div v-if="campaigns.length">
                    <paginator-wrap :pagination="meta" :change-page="changePage">
                        <template slot="content">
                            <v-table
                                is-elevated
                                has-actions
                                has-selection
                                clickable
                                :selected="selected"
                                :rows="campaigns"
                                :sort="{ key: query.sort_key, direction: query.sort_direction }"
                                @sort-changed="updateSort"
                                @selection-changed="handleSelection"
                                @clicked="goToCampaign($event.id)"
                            >
                                <template #default="{ row }">
                                    <v-table-column label="Account" value="account">
                                        <div class="mt-2">
                                            <span><strong class="text-lg">{{ row.account.name }}</strong></span>
                                        </div>
                                    </v-table-column>
                                    <v-table-column label="Layout" value="layout_type">
                                        <span class="capitalize">{{ row.letter.layout_type }}</span>
                                    </v-table-column>
                                    <v-table-column label="Name" value="name" sortable>
                                        <span>{{ row.name }}</span>
                                        <span v-if="row.deleted_at" class="text-xs text-gray-500">Archived</span>
                                    </v-table-column>
                                    <v-table-column label="Status" value="status" sortable>
                                        <campaign-status :status="row.status" with-status />
                                    </v-table-column>
                                    <v-table-column label="Photo" value="photo" is-wider>
                                        <div
                                            v-if="row.thumbnail"
                                            class="inline-block w-64 h-32 max-w-full cursor-pointer"
                                            @click.stop="previewImage(row.photo_path)"
                                        >
                                            <img :src="row.thumbnail" class="max-h-full max-w-full" />
                                        </div>
                                        <span v-else class="w-64">No Photo</span>
                                    </v-table-column>
                                    <v-table-column label="Submitted Date" value="submitted_at" sortable>
                                        <span>{{ formatDate(row.submitted_at) }}</span>
                                        <span class="text-sm text-gray-700">{{ fromNow(row.submitted_at) }}</span>
                                    </v-table-column>
                                    <v-table-column label="Send At" value="send_at" sortable>
                                        <span>{{ formatDate(row.send_at) }}</span>
                                        <span class="text-sm text-gray-700">{{ fromNow(row.send_at) }}</span>
                                    </v-table-column>
                                </template>
                                <template #priority-buttons="{ row }">
                                    <template v-if="!row.deleted_at">
                                        <button ref="plot-button" @click="handlePlot(row.id)">Plot</button>
                                        <button ref="print-button" @click="handlePrintModal(row)">Print</button>
                                    </template>
                                </template>
                                <template #dropdown="{ row }">
                                    <template v-if="!row.deleted_at">
                                        <button @click="goToCampaign(row.id)">Edit/Review</button>
                                        <button v-if="row.letter" @click="loadTemplate(row)">Preview</button>
                                        <button @click="emailRenders(row)">Email Renders</button>
                                        <button @click="regenerate(row)">Regenerate Renders</button>
                                        <button
                                            :id="`mark-as-printed-${row.id}`"
                                            @click="markAsPrinted(row)"
                                        >Mark as Printed</button>
                                    </template>
                                    <template v-else>
                                        <button @click="restore(row.id)">Restore</button>
                                    </template>
                                </template>
                            </v-table>
                        </template>
                    </paginator-wrap>
                </div>
                <div v-else class="text-center">
                    <p class="text-lg text-gray-700">No submitted campaigns waiting to print.</p>
                </div>
            </div>
        </transition>
        <v-modal
            name="preview-modal"
            header="Preview"
            :no-pad="!loading"
        >
            <template slot="content">
                <div class="w-modal">
                    <v-loader v-if="loading" :size="32" :border-width="3" />
                    <letter-template-preview
                        v-if="!loading && preview"
                        :letter="preview.letter"
                    />
                </div>
            </template>
        </v-modal>
    </div>
</template>

<script>
import services from '@services/Api';
import CampaignStatus from '@outreach/components/campaign/CampaignStatus';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import LetterTemplatePreview from '@outreach/components/letter/LetterTemplatePreview';
import VModal from '@outreach/components/shared/modal/VModal';
import { mapActions, mapGetters } from 'vuex';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import { formatDate, fromNow } from '@services/Filters';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VCheckbox from '@outreach/components/inputs/VCheckbox';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import { Alert } from '@services/Alert';
import VToggle from '@outreach/components/VToggle.vue';

export default {
    components: {
        VToggle,
        SearchHeader,
        PaginatorWrap,
        VTable,
        VTableColumn,
        CampaignStatus,
        LetterTemplatePreview,
        VModal,
        VCheckbox,
        VInput,
        VLoader
    },
    mixins: [hasPermission],
    data: () => ({
        showPreviewModal: false,
        selected: new Set(),
        query: {
            submitted_at: {},
            archived: false,
            hide_printed: undefined,
            sort_key: 'send_at',
            sort_direction: 'DESC',
            page: null
        }
    }),
    computed: mapGetters({
        loading: 'loader/isLoading',
        campaigns: 'submitted/campaigns',
        meta: 'submitted/meta',
        formErrors: 'submitted/formErrors',
        preview: 'submitted/preview'
    }),
    watch: {
        query: {
            async handler () {
                await this.getCampaigns(this.query);
            },
            deep: true
        }
    },
    created () {
        this.getCampaigns(this.query);
    },
    methods: {
        ...mapActions({
            getCampaigns: 'submitted/getCampaigns',
            archive: 'submitted/archive',
            restore: 'submitted/restore',
            fetchPreview: 'submitted/preview',
            openModal: 'modals/open',
            regenerateCampaign: 'pending/regenerateCampaign'
        }),
        formatDate,
        fromNow,
        updateSort (data) {
            this.query.sort_key = data.key;
            this.query.sort_direction = data.direction;
        },
        goToCampaign (id) {
            this.$router.push({ name: 'campaign-review', params: { campaign: id } });
        },
        async loadTemplate (campaign) {
            try {
                await this.fetchPreview(campaign.id);
                this.openModal('preview-modal');
            } catch (e) {
                this.$toasted.error('Failed to generate preview');
            }
        },
        async emailRenders (campaign) {
            await services.admin.emailRenders(campaign.id);
        },
        handleSelection (selected) {
            this.selected = selected;
        },
        select (item) {
            if (this.selected.has(item.id)) {
                return this.deselect(item);
            }
            this.selected = new Set([...this.selected, item.id]);
        },
        deselect (item) {
            const values = new Set(this.selected);
            values.delete(item.id);
            this.selected = values;
        },
        changePage (page) {
            this.getCampaigns({ ...this.query, page });
        },
        handlePrintModal (campaign) {
            dialogEmitter.openDialog('PrintDialog', { campaign });
        },
        handlePlot (campaignId) {
            dialogEmitter.openDialog('MachineDialog', { campaignId });
        },
        previewImage (url) {
            dialogEmitter.openDialog('ImagePreviewDialog', { url });
        },
        async regenerate (campaign) {
            const confirm = await dialogEmitter.confirm(
                'Regenerate Campaign',
                'Are you sure you want to regenerate this campaign?'
            );
            if (confirm) {
                await Alert.warning(`The campaign ${campaign.name} is regenerating. This will take a few minutes.`);
                await this.regenerateCampaign(campaign);
            }
        },
        async markAsPrinted (campaign) {
            await dialogEmitter.name('MarkAsPrintedDialog')
                .props({ campaignId: campaign.id })
                .wait();
        }
    }
};
</script>
