<template>
    <div class="container mx-auto">
        <transition name="fade" mode="out-in">
            <div v-if="loading" key="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading machines</span>
            </div>
            <div v-else>
                <div v-if="machines.length > 0">
                    <v-table
                        is-elevated
                        has-actions
                        :rows="machines"
                        :sort="sort"
                        @sort-changed="sort = $event"
                    >
                        <template #header>
                            <div class="flex justify-between">
                                <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2" label="Search" />
                                <v-button
                                    label="Add Machine"
                                    button-style="primary"
                                    @click="openMachineFormModal({name: ''})"
                                />
                            </div>
                        </template>
                        <template #default="{ row }">
                            <v-table-column label="Name" value="name" sortable>
                                <div>
                                    <span>{{ row.name }}</span>
                                </div>
                            </v-table-column>
                            <v-table-column label="Email" value="email" sortable>
                                <div>
                                    <span>{{ row.email }}</span>
                                </div>
                            </v-table-column>
                            <v-table-column label="Type" value="type" sortable>
                                <div>
                                    <span>{{ row.type }}</span>
                                </div>
                            </v-table-column>
                        </template>
                        <template #dropdown="{ row }">
                            <button @click="openMachineFormModal(row, true)">Edit</button>
                        </template>
                    </v-table>
                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getMachines"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No machines found.</p>
                    </div>
                </div>
            </div>
        </transition>
        <modal header="Create Machine" :open="machineFormModalOpen" @close-modal="closeMachineFormModal">
            <template slot="content">
                <div class="flex w-modal">
                    <machine-form
                        :machine="formMachine"
                        :submitting="submittingForm"
                        :is-update="isUpdate"
                        @formSubmitSuccessful="closeMachineFormModal"
                        @formHasErrors="submittingForm=false"
                    />
                </div>
            </template>
            <template slot="modal-actions">
                <div class="p-2">
                    <v-button
                        label="cancel"
                        :disabled="loading"
                        :loading="loadingOrSubmitting"
                        class="mr-2"
                        @click="closeMachineFormModal"
                    />
                    <v-button
                        label="Save"
                        button-style="primary"
                        :disabled="loadingOrSubmitting"
                        :loading="loadingOrSubmitting"
                        @click="submit"
                    />
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import services from '@services/Api';
import Modal from '@outreach/components/shared/modal/index';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import VLoader from '@outreach/components/VLoader';
import VButton from '@outreach/components/VButton';
import MachineForm from '../../components/machine/MachineForm';
import VPaginator from '@outreach/components/VPaginator';
import { cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: {
        VLoader,
        VButton,
        VTable,
        VTableColumn,
        Modal,
        MachineForm,
        VPaginator,
        VInput
    },
    data: () => ({
        machineFormModalOpen: false,
        machines: [],
        formMachine: null,
        submittingForm: false,
        deleting: null,
        isUpdate: false,
        meta: {},
        query: null,
        sort: {
            key: 'created_at',
            direction: 'DESC'
        }
    }),
    computed: {
        ...mapGetters({
            loading: 'loader/isLoading'
        }),
        loadingOrSubmitting () {
            return this.loading || this.submittingForm;
        }
    },
    watch: {
        sort: debounce(function () {
            this.getMachines();
        }, 400),
        query: debounce(function () {
            this.getMachines();
        }, 400)
    },
    created () {
        this.getMachines();
    },
    methods: {
        async getMachines (page = 1) {
            const { data } = await services.machines.list({
                page,
                q: this.query,
                sort_key: this.sort.key,
                sort_direction: this.sort.direction
            });
            this.machines = data.data;
            this.meta = data.meta;
        },
        openMachineFormModal (machine, isUpdate = false) {
            this.isUpdate = isUpdate;
            this.formMachine = cloneDeep(machine);
            this.machineFormModalOpen = true;
        },
        async closeMachineFormModal () {
            if (this.submittingForm) {
                await this.getMachines();
            }
            this.machineFormModalOpen = false;
            this.submittingForm = false;
        },
        submit () {
            this.submittingForm = true;
        }
    }
};
</script>
