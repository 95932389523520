<template>
    <div v-if="value">
        <div v-if="details.length > 0" class="flex justify-between my-6 items-center overflow-y-scroll">
            <div>
                <p class="text-lg text-gray-800">Details</p>
                <p v-for="(error, index) in formErrors" :key="index" class="text-red-400 text-xs">{{ error }}</p>
            </div>
            <v-button
                class="ml-auto"
                label="Add"
                button-style="primary"
                outlined
                @click="addDetail()"
            />
        </div>
        <div v-else class="flex flex-col py-6">
            <i class="text-center text-2xl fa fa-square-plus text-gray-700"></i>
            <p class="w-full text-center mb-3 text-gray-800">
                No Details
            </p>
            <v-button
                label="Add"
                button-style="primary"
                outlined
                class="self-center md:w-1/3"
                @click="addDetail()"
            />
        </div>
        <div
            v-for="(detail, index) in details"
            :key="index"
            class="mb-6"
        >
            <v-input v-model="detail.key" class="inline-block w-2/5 mr-2" label="Key" @input="updateContact" />
            <v-input v-model="detail.value" class="inline-block w-2/5" label="Value" @input="updateContact" />
            <a class="inline-block ml-5" @click="removeDetail(index)">
                <i class="fa fa-times text-red-600 cursor-pointer"></i>
            </a>
        </div>

    </div>
</template>

<script>
import services from '@services/Api';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { VButton, VInput },
    props: {
        audienceId: {
            type: Number,
            required: true
        },
        value: {
            type: [Array, Object],
            default: null
        },
        formErrors: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            details: [],
            suggestions: []
        };
    },
    watch: {
        details () {
            this.updateContact();
        }
    },
    created () {
        this.details = this.convertObjectToArray(this.value);
        this.fetchAudienceDetailsKeys().then((response) => {
            this.suggestions = this.filterSuggestions(response);
            this.suggestions.forEach(suggestion => {
                this.addDetail(suggestion);
            });
        });
    },
    methods: {
        async fetchAudienceDetailsKeys () {
            if (this.audienceId) {
                const { data } = await services.audiences.detailsKeys(this.audienceId);
                return data.data;
            }
            return [];
        },
        addDetail (key = '') {
            this.details.push({ key, value: '' });
        },
        convertArrayToObject (array) {
            return array.reduce((accumulator, currentValue) => {
                return {
                    ...accumulator,
                    [currentValue.key]: currentValue.value
                };
            }, {});
        },
        convertObjectToArray (object) {
            return [
                ...Object.entries(object || {}).map(([key, value]) => {
                    return { key, value };
                })
            ];
        },
        filterSuggestions (suggestions) {
            return suggestions.filter(suggestion => !Object.keys(this.value).includes(suggestion.toString()));
        },
        removeDetail (index) {
            this.details.splice(index, 1);
        },
        updateContact () {
            this.$emit('input', this.convertArrayToObject(this.details));
        }
    }
};
</script>
