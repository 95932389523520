<template>
    <div class="add-customers">
        <div class="flex items-center">
            <div class="pr-3 -mt-2"><i class="fa fa-search"></i></div>
            <div class="flex-1">
                <v-input v-model="query" label="Search For Customers" class="mb-4" />
            </div>
        </div>

        <div v-if="! loading">
            <v-table :rows="accounts">
                <template #default="{ row }">
                    <v-table-column label="Account" value="account">
                        <div class="flex items-center">
                            <div class="text-lg" :class="{'font-bold': row.selected}">{{ row.name }}</div>

                            <i v-if="row.selected" class="fa fa-check text-green-500 ml-4"></i>
                        </div>
                    </v-table-column>
                    <v-table-column label="Name" value="name">
                        <account-users-info :account="row" :class="{'font-bold': row.selected}" />
                    </v-table-column>
                    <v-table-column label="" value="" class="flex">
                        <div class="ml-auto">
                            <v-button
                                :ref="`select-customer-${row.id}`"
                                :label="row.selected ? `Un-Select` : `Select`"
                                @click="toggleSelection(row)"
                            />
                        </div>
                    </v-table-column>
                </template>
            </v-table>

            <div class="flex">
                <v-button
                    v-if="isCustomerSelected"
                    ref="save-customers"
                    :label="`Assign ${selectedAccounts.length} Selected Customers`"
                    class="mt-2 ml-auto"
                    button-style="primary"
                    @click="updateCustomers"
                />
            </div>
        </div>

        <v-loader v-else />

        <div v-if="showNoResultsMessage">
            <p class="text-center">No Customers Found</p>
        </div>
    </div>
</template>

<script>
import VInput from '@outreach/components/inputs/VInput';
import { VTable, VTableColumn } from '@outreach/components/shared/table';
import VButton from '@outreach/components/VButton';
import VLoader from '@outreach/components/VLoader';
import { debounce } from 'lodash';
import services from '@services/Api';
import { mapState } from 'vuex';
import { Alert } from '@services/Alert';
import AccountUsersInfo from '@outreach/pages/Customers/AccountUsersInfo';
import { customers } from '@services/Api/Customers';

const MIN_SEARCH_CHARS = 2;

export default {
    name: 'AddCustomers',
    components: {
        AccountUsersInfo,
        VInput,
        VTable,
        VTableColumn,
        VLoader,
        VButton
    },
    data: () => ({
        query: null,
        accounts: []
    }),
    computed: {
        ...mapState({
            loading: ({ loader }) => loader.loading
        }),
        isCustomerSelected () {
            return this.selectedAccounts.length > 0;
        },
        selectedAccounts () {
            return this.accounts.filter(account => account.selected);
        },
        showNoResultsMessage () {
            return !this.loading && !this.accounts.length && this.query && this.query.length >= 2;
        }
    },
    watch: {
        query (q) {
            if (q?.length >= MIN_SEARCH_CHARS) {
                this.searchCustomers({ q });
            }
        }
    },
    methods: {
        searchCustomers: debounce(async function (params) {
            this.accounts = this.accounts.filter(account => account.selected);

            try {
                const { data } = await services.admin.accounts({
                    ...params,
                    unassigned: true,
                    exclude: this.accounts.map((account) => account.id)
                });

                data.data
                    .forEach(account => this.accounts.push({ ...account, selected: false }));
            } catch (e) {
                await Alert.error('Error searching customers');
            }
        }, 500),
        toggleSelection (account) {
            account.selected = !account.selected;
        },
        async updateCustomers () {
            const selectedIds = this.selectedAccounts.map(account => account.id);

            try {
                await customers.update(this.$route.params.userId, selectedIds);

                await Alert.success('Customers successfully assigned.', 3000);
                this.accounts = [];
                this.query = null;
                this.$emit('updated');
                window.scrollTo(0, 0);
            } catch (e) {
                await Alert.error('Error assigning customers');
            }
        }
    }
};
</script>
