<template>
    <a :href="href" class="h-full">
        <v-card class="h-full">
            <template slot="body">
                <div class="h-full flex flex-col justify-center">
                    <span>{{ count }} {{ title }}</span>
                    <span v-if="subtitle" class="text-xs text-gray-600" v-text="subtitle"></span>
                </div>
            </template>
        </v-card>
    </a>
</template>

<script>
import VCard from '@outreach/components/VCard';

export default {
    components: { VCard },
    props: {
        href: {
            type: String,
            default: ''
        },
        count: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>
