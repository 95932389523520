<template>
    <div class="bg-white shadow sm:rounded-lg mb-8 overflow-hidden">
        <div class="px-4 py-5 sm:px-6 w-full flex items-center space-between">
            <div class="flex-1">
                <h2
                    id="applicant-information-title"
                    class="text-lg inline-flex items-center leading-6 font-medium text-gray-900"
                >
                    {{ title }}
                    <slot name="titleAction"></slot>
                </h2>
                <p v-if="subtitle" class="mt-1 max-w-2xl text-sm text-gray-500">
                    {{ subtitle }}
                </p>
            </div>
            <slot name="actionButton"></slot>
            <div v-if="loading">
                <v-loader :size="20" />
            </div>
        </div>
        <div class="border-t border-gray-200" :class="{'px-4 py-5 sm:px-6': padded}">
            <slot></slot>
        </div>
        <div v-if="$slots['footer']" class="bg-gray-100 px-4 py-6 sm:px-6">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
import VLoader from '@outreach/components/VLoader';

export default {
    name: 'VPanel',
    components: { VLoader },
    props: {
        title: { type: String, required: true },
        subtitle: { type: String, default: null },
        padded: { type: Boolean, default: true },
        loading: { type: Boolean, default: false }
    }
};
</script>
