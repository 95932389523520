<template>
    <div class="relative">
        <div :class="{'trigger': !hasTrigger}">
            <div class="priorityOptions flex">
                <slot name="priority-options"></slot>
            </div>
            <span v-if="$slots.content" ref="toggle" @click="showDropdown = !showDropdown">
                <slot :slot-scope="showDropdown" name="trigger">
                    <button
                        id="option-menu-button"
                        type="button"
                        class="option-menu"
                        aria-expanded="true"
                        aria-haspopup="true"
                    >
                        <span class="sr-only">Open options</span>
                        <i class="fa fa-ellipsis-vertical h-5 w-5 text-lg"></i>
                    </button>
                </slot>
            </span>
        </div>
        <div
            v-if="showDropdown"
            ref="dropdown-menu"
            v-click-away="() => showDropdown = false"
            class="dropdownMenu w-56 rounded-md shadow-lg bg-white ring-1 ring-gray-300 focus:outline-none z-30"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="option-menu-button"
            tabindex="-1"
        >
            <div class="py-1" role="none">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickAway } from '@outreach/mixins/clickAway';
import { createPopper } from '@popperjs/core';

export default {
    mixins: [clickAway],
    props: {
        isIcon: { type: Boolean, default: true }
    },
    data: () => ({
        showDropdown: false,
        popperInstance: null
    }),
    computed: {
        hasTrigger () {
            return this.$scopedSlots.trigger;
        }
    },
    watch: {
        showDropdown (value) {
            if (!value && this.popperInstance) {
                this.popperInstance.destroy();
            }

            if (value) {
                this.$nextTick(() => {
                    if (!this.$refs.toggle || !this.$refs['dropdown-menu']) return;
                    this.popperInstance = createPopper(this.$refs.toggle, this.$refs['dropdown-menu'], {
                        placement: 'bottom-end',
                        modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
                    });
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dropdownMenu {
    button,
    a {
        @apply text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 w-full text-left;
        &:disabled {
            @apply opacity-50 cursor-not-allowed;
        }
    }
}

.option-menu {
    @apply relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-inset focus:ring-brand-regular focus:border-brand-regular;
}

.trigger {
    @apply shadow border border-gray-300 rounded-md overflow-hidden inline-flex divide-x divide-gray-300;
}

.priorityOptions {
    @apply divide-x divide-gray-300;
    button,
    a {
        @apply relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-inset focus:ring-brand-regular focus:border-brand-regular;

        &.red {
            @apply bg-red-500 text-white hover:opacity-90;
        }
        &.gray {
            @apply bg-gray-50 hover:opacity-90;
        }
    }
}
</style>
