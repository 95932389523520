import { debugLog } from '@services/Utils';

const state = {
    subscriptions: {}
};

const actions = {
    subscribe: ({ commit }, options) => {
        commit('addSubscription', options);
    },
    unsubscribe: ({ commit }, options) => {
        commit('removeSubscription', options);
    },
    reset: ({ commit }) => {
        commit('clearSubscriptions');
    },
    broadcast: (context, event) => {
        const subscriptions = context.state.subscriptions[event.constructor.name] || [];
        debugLog('[events]', `${event.constructor.name} fired.`);
        subscriptions.forEach((subscription) => {
            subscription(context, event);
        });
    }
};

const mutations = {
    addSubscription: (state, { event, subscription }) => {
        debugLog('[events]', `listening on ${event.name}`);
        const subscriptions = state.subscriptions[event.name] || [];
        state.subscriptions[event.name] = [...subscriptions, subscription];
    },
    removeSubscription: (state, { event, subscription }) => {
        const subscriptions = state.subscriptions[event.name] || [];
        const index = subscriptions.findIndex((fn) => fn === subscription);
        state.subscriptions[event.name] = [
            ...subscriptions.slice(0, index),
            ...subscriptions.slice(index + 1)
        ];
    },
    clearSubscriptions: (state) => {
        state.subscriptions = {};
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
