"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plan = void 0;
class Plan {
    constructor(data) {
        this.id = 0;
        this.name = '';
        this.credits = 0;
        this.note_price = 0;
        this.base_price = 0;
        this.published_at = null;
        Object.assign(this, data);
    }
    get discount() {
        return this.fullPrice - this.totalPrice;
    }
    get fullPrice() {
        return this.base_price * this.credits / 100;
    }
    get pricePerNote() {
        return this.note_price / 100;
    }
    set pricePerNote(price) {
        this.note_price = Math.trunc(price * 100);
    }
    get totalPrice() {
        return this.credits * this.note_price / 100;
    }
}
exports.Plan = Plan;
