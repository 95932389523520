<template>
    <v-multiple-select
        ref="add-users-select"
        label="label"
        :loading="loading"
        :options="userOptions"
        :internal-search="true"
        :value="[]"
        placeholder="Add Users"
        @search-change="debounceListUsers($event)"
        @input="addUser"
    />
</template>

<script>
import debounce from 'lodash/debounce';
import services from '@services/Api';
import { mapState } from 'vuex';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VMultipleSelect from '@outreach/components/inputs/VMultipleSelect';

export default {
    name: 'UserMultiSelect',
    components: { VMultipleSelect },
    mixins: [hasPermission],
    props: {
        selectedIds: { type: Array, required: false, default: () => [] },
        withCreate: { type: Boolean, default: false }
    },
    data () {
        return {
            users: []
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            loading: ({ loader }) => loader.loading
        }),
        userOptions () {
            const users = this.users.filter(user => !this.selectedIds.includes(user.id))
                .map(user => ({ id: user.id, label: `${user.first_name} ${user.last_name}` }));

            if (this.withCreate) {
                users.unshift({ id: null, label: '+ Create New' });
            }

            return users;
        }
    },
    async mounted () {
        if (this.isAdmin()) {
            await this.listUsers();
        }
    },
    methods: {
        debounceListUsers: debounce(function (q) {
            this.listUsers(q);
        }, 500),
        async listUsers (term = '') {
            const { data } = await services.admin.listUsers({ q: term });
            this.users = data.data;
        },
        addUser (users) {
            if (users.length <= 0) return;
            const userToAdd = this.users.find((u) => u.id === users[0].id);
            this.$emit('addUser', userToAdd);
        }
    }
};
</script>
