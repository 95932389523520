<template>
    <div v-if="loaded" class="border border-gray-400 rounded p-4">
        <div class="border border-gray-400 rounded p-2 mb-6">
            <card ref="card" class="stripe-card" :stripe="stripeKey" @change="handleChange" />
        </div>
        <v-button
            ref="submit-button"
            :loading="processing"
            :label="label"
            :disabled="!valid"
            @click="submit"
        />
    </div>
</template>

<script>
import { Card, createToken } from 'vue-stripe-elements-plus';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import VButton from '@outreach/components/VButton';

export default {
    components: { Card, VButton },
    props: {
        label: { type: String, required: true }
    },
    data () {
        return {
            stripeKey: process.env.STRIPE_KEY,
            loaded: false,
            valid: false,
            processing: false
        };
    },
    async created () {
        await this.$loadScript('https://js.stripe.com/v3/');
        this.loaded = true;
    },
    methods: {
        handleChange (event) {
            this.valid = event.complete;
        },
        async submit () {
            this.processing = true;
            try {
                const response = await createToken();
                this.$emit('update', response.token);
                this.reset();
            } catch (error) {
                console.log(error);
                throw new ClientVisibleError('Could not save card');
            }
            this.processing = false;
        },
        reset () {
            if (this.$refs.card && this.$refs.card.clear) {
                this.$refs.card.clear();
            }
            this.valid = false;
        }
    }
};
</script>
