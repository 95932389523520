<template>
    <span v-tooltip="showTooltip ? value : undefined">
        {{ truncatedValue }}
    </span>
</template>

<script>
import truncate from 'lodash/truncate';

export default {
    name: 'TruncateWithTooltip',
    props: {
        value: { type: [String, Number], required: true },
        length: { type: Number, required: true }
    },
    computed: {
        showTooltip () {
            return this.value.length > this.length;
        },
        truncatedValue () {
            return truncate(this.value, this.length);
        }
    }
};
</script>

<style scoped>

</style>
