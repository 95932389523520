import ApiClient from '@services/Http/Api';
import Account from '@models/Account';
import isObject from 'lodash/isObject';
import NoteCountResponse from '@outreach/components/widgets/NoteCountResponse';
import WidgetError from '@exceptions/WidgetError';
import { CsvMapper } from '@outreach/components/services/CsvMapper';
import { Alert } from '@services/Alert';

export const submittedCampaigns = (params = {}) => {
    return ApiClient.get('/dashboard/admin/campaigns', { params });
};

export const rejectCampaign = (campaignId) => {
    return ApiClient.post(`/dashboard/admin/campaigns/${campaignId}/reject`);
};

export const campaigns = {
    links (campaignId, params = {}) {
        return ApiClient.get(`/dashboard/admin/campaigns/${campaignId}/links`, { params });
    },
    updateAllLinks (campaignId, params = {}) {
        return ApiClient.patch(`/dashboard/admin/campaigns/${campaignId}/links`, params);
    },
    markAsPrinted (campaignId, params = {}) {
        return ApiClient.post(`dashboard/admin/campaigns/${campaignId}/printed`, params);
    }
};

export const unifyPdfs = (layout, campaignIds = []) => {
    return ApiClient.post(`/dashboard/admin/campaigns/merge-pdfs/${layout}/merge`, {
        campaign_ids: campaignIds
    });
};

export const generatePdf = (campaignId, layout) => {
    if (layout === 'without_qr') {
        return pdfWithoutQRCode(campaignId);
    }
    return ApiClient.post(`/dashboard/admin/campaigns/generate-pdf/${campaignId}`, { type: layout });
};

export const pdfWithoutQRCode = (campaignId) => {
    return ApiClient.post(`/dashboard/admin/campaigns/generate-pdf/${campaignId}/without-qr-code`);
};

export const recentlyContacted = (data) => {
    return ApiClient.upload('/dashboard/admin/tools/recently-contacted', data);
};

export const emailRenders = (campaignId) => {
    return ApiClient.post(`/dashboard/admin/campaigns/${campaignId}/send-export-email`);
};

export const accounts = (params = {}) => {
    return ApiClient.get('/dashboard/admin/accounts', { params });
};

export const fetchAccount = async (accountId, params = {}) => {
    const { data } = await ApiClient.get(`/dashboard/admin/accounts/${accountId}`, { params });
    return { data: { data: new Account(data.data) } };
};

const hasFile = (value) => {
    if (value instanceof File) {
        return true;
    }
    if (isObject(value)) {
        return Object.values(value).some(hasFile);
    }
    if (Array.isArray(value)) {
        return value.some(hasFile);
    }
    return false;
};

export const updateAccount = async (account, params = {}) => {
    const method = hasFile(account) ? 'patchUpload' : 'patch';
    const { data } = await ApiClient[method](`/dashboard/admin/accounts/${account.id}`, account, { params });
    return { data: { data: new Account(data.data) } };
};

export const archiveAccount = (accountId) => {
    return ApiClient.destroy(`/dashboard/admin/accounts/${accountId}`);
};

export const deleteCover = (accountId, coverId) => {
    return ApiClient.destroy(`/dashboard/admin/accounts/${accountId}/covers/${coverId}`);
};

export const listUsers = params => {
    return ApiClient.get('/dashboard/admin/users', { params });
};

export const importUsers = (data) => {
    return ApiClient.upload('/dashboard/admin/users/import', data);
};

export const csvHeaderRules = async (type) => {
    try {
        const { data } = await ApiClient.get(`/dashboard/csv-headers/${type}`);
        return new CsvMapper(data.data);
    } catch (e) {
        await Alert.fromError(
            e,
            'Oops, we aren\'t able to upload a file right now. Please reload the page and try again!'
        );
    }
};

export const restoreUser = (userId) => {
    return ApiClient.post(`/dashboard/admin/users/${userId}/restore`);
};

export const createUser = (params) => {
    return ApiClient.post('/dashboard/admin/users', params);
};

export const updateUser = (userId, params) => {
    return ApiClient.patch(`/dashboard/admin/users/${userId}`, params);
};

export const generateTemporaryUrl = (accountId) => {
    return ApiClient.post(`/dashboard/admin/accounts/${accountId}/generate-temporary-url`);
};

export const getScanSummary = (params = {}) => {
    return ApiClient.get('dashboard/admin/reports/scan-summary', { params });
};

export const printCount = (params = {}) => {
    return ApiClient.get('dashboard/admin/reports/print-count', { params });
};

export const lastSent = (params = {}) => {
    return ApiClient.get('dashboard/admin/reports/last-sent', { params });
};

export const repStaleCampaigns = (params = {}) => {
    return ApiClient.get('dashboard/admin/reports/rep-stale-campaigns', { params });
};

export const templates = {
    create (template) {
        return ApiClient.post('/dashboard/admin/templates', template);
    },

    fetch (templateId) {
        return ApiClient.get(`/dashboard/admin/templates/${templateId}`);
    },

    list (params = {}) {
        return ApiClient.get('/dashboard/admin/templates', { params });
    },

    accounts (templateId, params) {
        return ApiClient.get(`/dashboard/admin/templates/${templateId}/accounts`, { params });
    },

    assignAll (templateId, accounts = undefined) {
        return ApiClient.post(`/dashboard/admin/templates/${templateId}/assignments`, { accounts });
    },

    unassignAll (templateId, accounts = undefined) {
        return ApiClient.destroy(`/dashboard/admin/templates/${templateId}/assignments`, { accounts });
    },

    update (templateId, template) {
        return ApiClient.patch(`/dashboard/admin/templates/${templateId}`, template);
    },

    destroy (templateId) {
        return ApiClient.destroy(`/dashboard/admin/templates/${templateId}`);
    },

    detach (templateId, accountId) {
        return ApiClient.destroy(`/dashboard/admin/templates/${templateId}/detach`, { account_id: accountId });
    }
};

export const tags = {
    list (params = {}) {
        return ApiClient.get('/dashboard/admin/tags', { params });
    },
    create (tag) {
        return ApiClient.post('/dashboard/admin/tags', tag);
    }
};

export const teams = {
    list (params = {}) {
        return ApiClient.get('/dashboard/admin/teams', { params });
    },
    create (team) {
        return ApiClient.post('/dashboard/admin/teams', team);
    }
};

export const links = {
    listLogs (linkId, params = {}) {
        return ApiClient.get(`/dashboard/admin/links/${linkId}/logs`, { params });
    },
    deleteLog (log) {
        return ApiClient.destroy(`/dashboard/admin/links/${log.link_id}/logs/${log.id}`);
    },
    updateLink (link) {
        return ApiClient.patch(`/dashboard/admin/links/${link.id}`, { url: link.url });
    }
};

export const widgets = {
    async noteCount (params = {}) {
        try {
            const { data: { data } } = await ApiClient.get('/dashboard/admin/widgets/note-count', { params });
            return new NoteCountResponse(data);
        } catch (e) {
            if (!e.response) {
                throw new WidgetError(e.message);
            }
            return NoteCountResponse.fromError(e);
        }
    },
    getWeeklyScanSummary (params = {}) {
        return ApiClient.get('/dashboard/admin/widgets/scan-summary', { params });
    },
    repStaleCampaigns (params = {}) {
        return ApiClient.get('/dashboard/admin/widgets/rep-stale-campaigns', { params });
    }
};

export const account = {
    assignArticles (accountId, params = {}) {
        return ApiClient.post(`/dashboard/admin/accounts/${accountId}/articles`, params);
    },
    listEmails (accountId, params = {}) {
        return ApiClient.get(`/dashboard/admin/accounts/${accountId}/emails`, { params });
    }
};

export const invitations = {
    list (params = {}) {
        return ApiClient.get('/dashboard/admin/invitations', { params });
    },
    create (params = {}) {
        return ApiClient.post('/dashboard/admin/invitations', params);
    },
    update (invitationId, params = {}) {
        return ApiClient.patch(`/dashboard/admin/invitations/${invitationId}`, params);
    },
    destroy (invitationId) {
        return ApiClient.destroy(`/dashboard/admin/invitations/${invitationId}`);
    },
    async import (data) {
        return ApiClient.upload('/dashboard/admin/invitations/import', data);
    },
    async export (params = {}) {
        return ApiClient.post('/dashboard/admin/invitations/export', { params });
    }
};

export const agents = {
    listAgents (params = {}) {
        return ApiClient.get('/dashboard/admin/agents', { params });
    },
    deleteAgent (agent) {
        return ApiClient.destroy(`/dashboard/admin/agents/${agent.id}`);
    },
    showAgent (id) {
        return ApiClient.get(`/dashboard/admin/agents/${id}`);
    },

    updateAgent (id, params = {}) {
        return ApiClient.patch(`/dashboard/admin/agents/${id}`, params);
    }
};
