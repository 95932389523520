import services from '@services/Api';

const state = {
    records: [],
    meta: {}
};

const actions = {
    index: async ({ commit }, params) => {
        const { data } = await services.admin.teams.list(params);
        commit('setTeams', data.data);
        commit('setMeta', data.meta);
    },
    create: async ({ commit }, payload) => {
        const { data } = await services.admin.teams.create(payload);
        return data.data;
    },
    update: async ({ commit }, team) => {
        const { data } = await services.teams.update(team.id, team);
        commit('replaceTeam', data.data);
        return data.data;
    },
    delete: async ({ commit }, team) => {
        await services.teams.destroy(team.id);
        commit('filterTeam', team);
    },
    show: async ({ commit }, teamId) => {
        const { data } = await services.teams.show(teamId);
        commit('replaceTeam', data.data);
        return data.data;
    }
};

const mutations = {
    setTeams: (state, payload) => {
        state.records = payload;
    },
    setMeta: (state, payload) => {
        state.meta = payload;
    },
    replaceTeam: (state, payload) => {
        const index = state.records.findIndex(record => record.id === payload.id);
        if (index >= 0) {
            state.records[index] = payload;
        }
    },
    filterTeam: (state, payload) => {
        state.records = state.records.filter(team => team.id !== payload.id);
    }
};

export default { state, actions, mutations, namespaced: true };
