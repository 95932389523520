<template>
    <div class="flex flex-col flex-1">
        <steps class="border-b 3xl:border-b-0 border-gray-500" :steps="steps" value="Review" />
        <div class="flex flex-col flex-1 md:flex-row container">
            <div class="border-b md:border-b-0 border-gray-400 flex flex-col flex-auto items-stretch">
                <contacts-navigation
                    ref="contacts-navigation"
                    class="block 3xl:hidden"
                    :rows="contacts"
                    :selected-id="selectedContactId"
                    @end-reached="$emit('get-more-deliveries')"
                    @select="$emit('select-contact', $event)"
                />
                <div class="flex flex-col md:flex-row justify-between md:items-center p-4 border-b border-gray-400">
                    <div class="mb-4 md:mb-0">Written in Audience Ultra 1</div>
                    <v-select
                        label="Choose font..."
                        :options="fonts"
                        :value="letter.font_id"
                        class="w-full md:w-64"
                        @input="$emit('update:font_id', $event)"
                    />
                </div>
                <div class="flex flex-col md:flex-row justify-between md:items-center p-4 border-b border-gray-400">
                    <div v-if="!editing" class="flex flex-col sm:flex-row gap-2">
                        <span class="text-gray-700">Campaign Name</span>
                        <button class="inline-flex items-center gap-3 group" @click="editing = true">
                            {{ campaign.name }} <i class="fa fa-pen cursor-pointer group-hover:text-brand-regular"></i>
                        </button>
                    </div>
                    <div v-if="editing" class="w-full flex gap-4">
                        <v-input
                            v-model="campaign.name"
                            label="Campaign Name"
                            class="flex-1"
                            type="text"
                            :errors="formErrors.campaign_name"
                        />
                        <button
                            v-tooltip="'Save'"
                            class="text-green-500 hover:text-green-600"
                            @click="$emit('update:campaign_name'); editing = false"
                        >
                            <i class="fas fa-save"></i>
                        </button>
                        <button v-tooltip="'Cancel'" class="hover:text-red-500" @click="editing = false">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="flex flex-1 items-stretch flex-col xl:flex-row">
                    <contacts-list
                        ref="contacts-list"
                        class="hidden w-64 3xl:block 3xl:border-b-0 3xl:border-r"
                        :rows="contacts"
                        :selected-id="selectedContactId"
                        :loading="loading"
                        @select="$emit('select-contact', $event)"
                        @get-more-deliveries="$emit('get-more-deliveries')"
                        @set-delivery-query="value => $emit('set-delivery-query', value)"
                    />
                    <div class="flex flex-1 justify-center">
                        <div class="flex flex-1 flex-col p-6 preview-container">
                            <div class="flex justify-between items-center flex-wrap mb-6">
                                <h3 class="text-2xl font-semibold text-grey-800">Review</h3>
                                <v-button
                                    v-if="!['sent', 'submitted'].includes(campaign.status) || isCustomerService()"
                                    ref="edit-button"
                                    label="Edit"
                                    button-style="default"
                                    @click="$emit('edit')"
                                />
                                <div class="w-full">
                                    <toggle-group
                                        :value="letter.layout_type"
                                        :options="noteOptions"
                                        label="Select a note size"
                                        @input="$emit('update-layout-type', $event)"
                                    />
                                </div>
                            </div>
                            <div class="relative">
                                <div
                                    v-if="loading || !previews"
                                    class="loader"
                                >
                                    <v-loader :size="32" />
                                </div>
                                <div v-else>
                                    <note-preview
                                        v-if="['note', 'half-note'].includes(campaign.letter.layout_type)"
                                        ref="note-preview"
                                        :previews="previews"
                                        :photo-path="photoPath"
                                        @discard-post-it="discardPostIt"
                                    />
                                    <postcard-preview
                                        v-if="campaign.letter.layout_type === 'postcard'"
                                        ref="postcard-preview"
                                        :previews="previews"
                                        :photo-path="photoPath"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="summary" class="bg-gray-200 border-l border-gray-400 p-4 summary">
                <v-multiple-select
                    id="sender-address-select"
                    class="w-full mb-4"
                    label="address1"
                    :errors="formErrors.sender_address_id"
                    placeholder="Sender Address"
                    :allow-empty="false"
                    :internal-search="false"
                    :loading="loading"
                    :multiple="false"
                    :options="addresses"
                    :value="senderAddress ? [senderAddress] : []"
                    @input="$emit('update:sender_address_id', $event.id)"
                    @search-change="searchAddress"
                />
                <v-input
                    ref="date-picker"
                    label="Send At"
                    type="date"
                    :value="campaign.send_at"
                    :min="minDate"
                    :max="maxDate"
                    :disabled="!enableSendAt"
                    @input="$emit('update:send_at', $event)"
                />

                <button-modal
                    v-if="collaterals.length > 0"
                    ref="delivery-modal"
                    class="mb-6"
                    label="Add a Gift"
                    header="Add A Gift"
                    disable-on-click-away
                >
                    <template #content>
                        <div>
                            <v-radio-button
                                v-for="collateral in collaterals"
                                :ref="`collateral-button-${collateral.id}`"
                                :key="collateral.id"
                                name="collaterals"
                                class="collateral-item"
                                :checked="options.collaterals"
                                :label="collateral.name"
                                :value="collateral.id"
                                @change="$emit('update:collateral-ids', $event)"
                            >
                                <div class="flex flex-row">
                                    <div v-if="collateral.image_path" class="flex-none gift-image">
                                        <img :src="collateral.image_path" />
                                    </div>
                                    <div class="flex flex-col flex-1 mx-4">
                                        <div class="text-lg">{{ collateral.name }}</div>
                                        <div class="description">{{ collateral.description }}</div>
                                    </div>
                                </div>
                            </v-radio-button>
                        </div>
                    </template>
                    <template #actions="{ close }">
                        <div class="p-2">
                            <v-button
                                label="Done"
                                class="mr-2"
                                @click="$emit('update:delivery-options', close)"
                            />
                        </div>
                    </template>
                </button-modal>

                <hr class="border-b border-gray-400 mb-4" />

                <credit-summary :quantity="quantity" :balance="balance" />

                <div>
                    <submit-campaign
                        :campaign="campaign"
                        :loading="loading"
                        @submit="$emit('submit')"
                        @duplicate="$emit('duplicate')"
                    />
                </div>
                <v-button
                    v-if="campaign.status === 'submitted' && isCustomerService()"
                    ref="reject-button"
                    class="w-full my-2"
                    label="Reject"
                    confirm="Are you sure you want to reject this campaign?"
                    :loading="loading"
                    button-style="error"
                    @click="$emit('rejected', campaign.id)"
                />
                <v-button
                    v-if="includeFeature && imposter"
                    ref="email-user-button"
                    class="w-full my-2"
                    label="Email User"
                    button-style="primary"
                    @click="$emit('notify-user', campaign.id, user.id)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ContactsList from '@outreach/pages/CampaignReview/ContactsList';
import ContactsNavigation from '@outreach/pages/CampaignReview/ContactsNavigation';
import ButtonModal from '@outreach/components/shared/modal/ButtonModal';
import PostcardPreview from '@outreach/pages/CampaignReview/PostcardPreview';
import NotePreview from '@outreach/pages/CampaignReview/NotePreview';
import Steps from '@outreach/pages/Steps';
import VLoader from '@outreach/components/VLoader';
import { formatToCurrency } from '@services/Utils';
import VRadioButton from '@outreach/components/inputs/VRadioButton';
import { mapActions, mapGetters, mapState } from 'vuex';
import { debounce } from 'lodash';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import ToggleGroup from '@outreach/components/shared/ToggleGroup';
import VMultipleSelect from '@outreach/components/inputs/VMultipleSelect';
import VSelect from '@outreach/components/inputs/VSelect';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import moment from 'moment';
import { maxSendAtOffset, minSendAtOffset } from '@global/config';
import { CampaignStatuses } from '@services/CampaignStatuses';
import CreditSummary from '@outreach/pages/CampaignReview/CreditSummary.vue';
import SubmitCampaign from '@outreach/pages/CampaignReview/SubmitCampaign';

export default {
    components: {
        CreditSummary,
        ToggleGroup,
        ButtonModal,
        ContactsNavigation,
        ContactsList,
        NotePreview,
        PostcardPreview,
        Steps,
        VLoader,
        VRadioButton,
        VMultipleSelect,
        VSelect,
        VButton,
        VInput,
        SubmitCampaign
    },
    mixins: [hasPermission],
    props: {
        balance: { type: Number, required: true },
        campaign: { type: Object, default: undefined },
        collaterals: { type: Array, default: () => [] },
        deliveries: { type: Array, default: () => [] },
        fonts: { type: Array, default: () => [] },
        options: { type: Object, required: true },
        previews: { type: Object, default: undefined },
        price: { type: Number, required: true },
        selectedContact: { type: Object, default: undefined },
        orderSubmitted: { type: Boolean, default: false },
        letter: { type: Object, default: undefined },
        steps: { type: Array, required: true }
    },
    data () {
        return {
            showPaymentModal: false,
            editing: false,
            paying: false
        };
    },
    computed: {
        ...mapState({
            imposter: ({ user }) => user.imposter,
            addresses: ({ addresses }) => addresses.addresses,
            messages: ({ alerts }) => alerts.messages,
            formErrors: ({ review }) => review.formErrors,
            pagination: ({ campaignDeliveries }) => campaignDeliveries.meta
        }),
        ...mapGetters({
            loading: 'loader/isLoading'
        }),
        contacts () {
            if (!this.deliveries) {
                return [];
            }
            return this.deliveries.map(({ contact }) => ({
                id: contact.id,
                title: contact.name,
                description: contact.address.address1
            }));
        },
        enableSendAt () {
            return this.isCustomerService() || this.isPending;
        },
        isPending () {
            return this.campaign.status === CampaignStatuses.PENDING;
        },
        minDate () {
            return this.isCustomerService() ? null : moment().add(minSendAtOffset, 'days').format('YYYY-MM-DD');
        },
        maxDate () {
            return moment().add(maxSendAtOffset, 'days').format('YYYY-MM-DD');
        },
        formattedSendAt () {
            return moment.utc(this.campaign.send_at).format('YYYY-MM-DD');
        },
        selectedContactId () {
            if (!this.selectedContact) {
                return undefined;
            }
            return this.selectedContact.contact_id;
        },
        postIt () {
            return this.selectedContact ? this.selectedContact.post_it : null;
        },
        photoPath () {
            if (!this.selectedContact) {
                return undefined;
            }
            return this.selectedContact.photo_path;
        },
        quantity () {
            if (!this.campaign || !this.pagination) {
                return 0;
            }
            return this.pagination.total;
        },
        total () {
            return this.quantity * this.price;
        },
        due () {
            return this.total;
        },
        includeFeature () {
            return process.env.DISPLAY_NOTIFICATION_BUTTON === 'true';
        },
        senderAddress () {
            return this.addresses.find(address => address.id === this.campaign.sender_address_id);
        },
        noteOptions () {
            return [
                { value: 'note', label: 'Note' },
                { value: 'half-note', label: 'Half Note' }
            ];
        }
    },
    watch: {
        orderSubmitted () {
            if (this.orderSubmitted) {
                this.setMessage({ messageType: 'success', message: 'Your order has been received' });
                this.$router.replace({ name: 'campaign-contacts' });
            }
        }
    },
    methods: {
        formatToCurrency,
        ...mapActions({
            getAddresses: 'addresses/list',
            setMessage: 'alerts/setMessage'
        }),
        searchAddress: debounce(function (q) {
            this.getAddresses({ account_id: this.user.account_id, params: { q } });
        }, 400),
        async discardPostIt () {
            const result = await dialogEmitter.confirm(
                'Discard Post-It',
                'Are you sure you want to permanently delete the post-it and discard it\'s content?',
                { confirmButtonStyle: 'error' }
            );
            if (result) {
                this.$emit('discard-post-it');
            }
        }
    }
};
</script>

<style scoped>
    .gift-image {
        width: 100px;
    }

    .collateral-item {
        @apply mb-4;
    }

    .collateral-item.unchecked .description {
        @apply text-gray-600
    }

    .collateral-item:last-child {
        @apply mb-0;
    }
    .container {
        max-width: unset;
    }
    .loader {
        @apply flex justify-center items-center bg-gray-600 opacity-75 absolute rounded-lg z-10;
        height: 100px;
        left: 50%;
        margin-left: -50px;
        top: 200px;
        width: 100px;
    }
    .preview-container {
        max-width: 800px;
    }

    @screen lg {
        .summary {
            width: 350px;
        }
    }
</style>
