<template>
    <div>
        <v-loader class="m-4" />
    </div>
</template>

<script>
import services from '@services/Api';
import VLoader from '@outreach/components/VLoader';

export default {
    name: 'SignedLinkHandler',
    components: { VLoader },
    async mounted () {
        try {
            await services.authentication.loginByToken(window.location.href);
            await this.$router.replace(this.$route.query.redirect || { name: 'send-notes' });
        } catch (e) {
            this.$toasted.error('Sorry, we were unable to sign you in. Please login to review your account.');
            await this.$router.replace({ name: 'login' });
        }
    }
};
</script>
