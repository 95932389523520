<template>
    <div></div>
</template>
<script>
import { appendScriptToBody } from '@services/Utils';
import { watch } from '@vue/composition-api';
import { useIdle } from '@vueuse/core';
import { helpdeskCooldown, helpdeskIdleDelay } from '@global/config';
import moment from 'moment';

export default {
    created () {
        appendScriptToBody(
            `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_KEY}`,
            'ze-snippet'
        );
    },
    setup () {
        const { idle } = useIdle(helpdeskIdleDelay);

        watch(idle, (idleValue) => {
            if (idleValue) {
                const cd = localStorage.getItem('helpdeskCooldown');
                if (cd && moment.utc().isBefore(moment.utc(cd).add(helpdeskCooldown, 'milliseconds'))) {
                    return;
                }
                localStorage.setItem('helpdeskCooldown', moment.utc().toISOString());
                window.zE('messenger', 'open');
            }
        });
    }
};
</script>
