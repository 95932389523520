<template>
    <div>
        <label v-if="!hideLabel" for="search" class="block font-bold mb-2">Search for an Address</label>
        <input id="search" v-model="query" type="text" class="google-input" />
    </div>
</template>

<script>
import { GoogleService } from '@outreach/pages/OutboundProspecting/GoogleService';

export default {
    name: 'GoogleAutoComplete',
    props: {
        hideLabel: {
            type: Boolean,
            default: false
        },
        map: {
            type: Object,
            default: undefined
        }
    },
    data () {
        return {
            autocomplete: null,
            query: null,
            service: new GoogleService()
        };
    },
    async mounted () {
        this.initAutocomplete();
        await this.autofill();
    },
    destroyed () {
        google.maps.event.clearInstanceListeners(this.autocomplete);
    },
    methods: {
        initAutocomplete () {
            this.autocomplete = this.service.createAutocomplete(
                this.$el.querySelector('#search'),
                () => this.$emit('selected', this.autocomplete.getPlace())
            );
        },
        async autofill () {
            this.query = this.$route.query.address ?? null;

            if (this.query && !this.$route.query.path) {
                await this.service.searchPlace(this.query, (results) => this.$emit('autofilled', results[0]), this.map);
            }
        }
    }
};
</script>

<style scoped>
.google-input {
  @apply p-2 w-full appearance-none block rounded focus:outline-none transition-all text-gray-800 bg-white;
  @apply focus:border-brand-regular border border-solid border-gray-400;
}
</style>
