<template>
    <v-label :text="label" :focused="isFocused" :readonly="readonly" :disabled="disabled">
        <textarea
            ref="input"
            :aria-label="label"
            class="appearance-none resize-none p-2 block focus:outline-none transition-all text-gray-800 rounded-b"
            :class="{
                'bg-white focus:border-brand-regular border border-solid border-gray-400': !readonly,
                'border-transparent bg-transparent w-full': readonly,
                'opacity-50 cursor-not-allowed': disabled,
                'rounded-t': true,
                ...styles,
            }"
            :value="value"
            :rows="rows"
            :readonly="readonly"
            :disabled="disabled"
            @focus="onFocus"
            @blur="onBlur"
            @input="$emit('input', $event.target.value)"
        ></textarea>
        <div v-if="limit" class="mt-1">
            <p v-if="overLimit" class="text-red-600">{{ characterLimit }} characters over... </p>
            <p v-else>{{ characterLimit }} characters remaining...</p>
        </div>
    </v-label>
</template>

<script>
import VLabel from '@outreach/components/shared/VLabel';

export default {
    components: { VLabel },
    props: {
        value: { type: String, default: '' },
        label: { type: String, required: true },
        readonly: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, default: false },
        limit: { type: Number, default: null },
        rows: { type: Number, default: 10 },
        styles: { type: Object, default: () => ({}) }
    },
    data: () => ({
        cursorPosition: null,
        focused: false
    }),
    computed: {
        isFocused () {
            return this.focused || this.hasValue;
        },
        hasValue () {
            return Boolean(this.value);
        },
        characterLimit () {
            if (this.value) {
                return Math.abs(this.limit - this.value.length);
            }
            return this.limit;
        },
        overLimit () {
            return this.value && this.value.length > this.limit;
        }
    },
    methods: {
        onFocus () {
            this.focused = true;
            this.cursorPosition = null;
        },
        onBlur () {
            this.focused = false;
        },
        insert (index, text) {
            const value = this.value || '';
            const valueAfterInsert = [value.slice(0, index), text, value.slice(index)].join('');
            this.$emit('input', valueAfterInsert);
        },
        insertAtSelectionStart (value) {
            const index = this.cursorPosition || this.$refs.input.selectionStart;
            value = this.cursorPosition ? ` ${value}` : value;
            this.insert(index, value);
            this.cursorPosition = index + value.length;
        }
    }
};
</script>
