<template>
    <li class="relative group p-1 pt-4 rounded" :class="{'bg-gray-300': checked}">
        <div class="transition-all" :class="{'px-2': checked}">
            <div class="relative group">
                <input
                    :id="`image-input-${image.id}`"
                    :ref="`image-name-${image.id}`"
                    v-model="image.name"
                    name="text"
                    :disabled="true"
                    class="image-name-input"
                />
                <button
                    :ref="`image-${image.id}`"
                    type="button"
                    class="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden"
                    :class="{
                        'image': !disableSelect,
                        'deleted-image': isDeleted,
                        'ring-4 ring-brand-regular': !disableSelect && isSelected,
                    }"
                    @click="selectImage"
                >
                    <img
                        :src="(image.srcset || {}).thumbnail || image.location"
                        :alt="`Image named ${image.name}`"
                        class="object-cover pointer-events-none"
                        :class="{'opacity-80': isDeleted}"
                    />
                </button>
            </div>
            <div class="flex mt-2 text-gray-500 font-medium">
                <div class="text-sm pointer-events-none">
                    {{ formatFullDate(image.created_at) }}
                </div>
                <i v-if="isGlobal" class="fa fa-globe ml-auto"></i>
            </div>
            <div class="col-span-3 sm:col-span-2">
                <label :for="`image-${image.id}`" class="sr-only">{{ image.name }}</label>
                <div v-if="editable" class="my-1 relative">
                    <div v-if="!isDeleted" class="flex pb-2">
                        <button
                            :ref="`edit-image-${image.id}`"
                            class="bg-gray-300 m-1 p-2 rounded w-1/2 "
                            @click="openImageDialog"
                        >Edit</button>
                        <button
                            :ref="`delete-image-${image.id}`"
                            class="bg-red-500 m-1 p-2 rounded w-1/2 text-white border-red-500"
                            @click="deleteImage"
                        >Delete</button>
                    </div>
                    <div v-else class="flex py-2">
                        <button
                            :ref="`restore-image-${image.id}`"
                            class="border-green-500 bg-green-500 text-white m-1 p-2 rounded w-full "
                            @click="restore"
                        >Restore</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="editable" class="checkbox" @click="$emit('check')">
            <i class="fa fa-check absolute font-bold top-[4px] right-[5px] text-white"></i>
            <input type="checkbox" :checked="checked" />
        </div>
    </li>
</template>

<script>
import { formatFullDate } from '@services/Filters';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';

export default {
    name: 'ImageRow',
    props: {
        image: { type: Object, required: true },
        isSelected: { type: Boolean, required: false, default: false },
        disableSelect: { type: Boolean, default: false },
        editable: { type: Boolean, default: true },
        checked: { type: Boolean, default: false }
    },
    computed: {
        isDeleted () {
            return this.image.deleted_at != null;
        },
        isGlobal () {
            return this.image.account_id === null;
        }
    },
    methods: {
        formatFullDate,
        async deleteImage () {
            const confirmed = await dialogEmitter.confirm(
                'Delete Image',
                'Are you sure you want to delete this image?',
                { confirmButtonStyle: 'error' }
            );
            if (confirmed) {
                this.$emit('delete', this.image);
            }
        },
        updateAsset (image) {
            this.$emit('update', image);
        },
        async restore () {
            const confirmed = await dialogEmitter.confirm(
                'Restore Image',
                'Are you sure you want to restore this image?'
            );
            if (confirmed) {
                this.$emit('restore', this.image);
            }
        },
        openImageDialog () {
            dialogEmitter.openDialog('EditImageDialog', { image: this.image }, {
                update: this.updateAsset
            });
        },
        selectImage () {
            if (this.disableSelect) {
                dialogEmitter.openDialog('ImagePreviewDialog', { url: this.image.location });
                return;
            }
            this.$emit('onSelect', this.image);
        }
    }
};
</script>

<style lang="scss" scoped>

.action-button {
    @apply inline-flex items-center p-1 rounded-full text-sm font-medium;
}

.image-name-input {
    @apply outline-none flex-1 block w-full rounded-md mr-2 sm:text-sm border-b truncate disabled:bg-gray-300 px-2 py-1;
}

.image {
    @apply focus-within:ring-4 focus-within:ring-brand-regular hover:ring-4 hover:ring-brand-regular cursor-pointer;
}

.deleted-image {
    @apply opacity-25 group-hover:opacity-100 cursor-default group-hover:ring-0 transition-opacity;
}

.checkbox {
  @apply absolute -top-2 -right-2 cursor-pointer z-50;

  input {
    @apply w-6 h-6 appearance-none checked:bg-brand-regular checked:border-brand-regular border-2 border-gray-300;
    @apply rounded-full cursor-pointer;
  }
}
</style>
