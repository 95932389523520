export function toggleLoading (method) {
    return async function (store, ...args) {
        store.commit('setLoading', true);
        try {
            await method.call(this, store, ...args);
            store.commit('setLoading', false);
        } catch (e) {
            store.commit('setLoading', false);
            throw e;
        }
    };
}
export default {
    namespaced: true,
    state: {
        campaigns: [],
        campaignMeta: {},
        jobs: [],
        jobMeta: {},
        previewVersion: 0,
        loading: false,
        query: undefined
    },
    actions: {
        setQuery: function ({ commit }, q) {
            commit('setQuery', q);
        }
    },
    mutations: {
        setJobs: (state, payload = {}) => {
            state.jobs = payload.data;
            state.previewVersion += 1;
            state.jobMeta = payload.meta;
        },
        setCampaigns: (state, payload = {}) => {
            const { data, ...meta } = payload;
            state.campaigns = data;
            state.campaignMeta = meta;
        },
        setLoading: (state, value) => {
            state.loading = value;
        },
        setQuery: (state, value) => {
            state.query = value;
        }
    },
    getters: {
        campaigns: (state) => state.campaigns,
        jobs: (state) => state.jobs,
        jobPagination: (state) => state.jobMeta,
        campaignPagination: (state) => state.campaignMeta,
        loading: (state) => state.loading,
        query: (state) => state.query
    }
};
