<template>
    <div class="w-full">
        <div class="flex flex-row">
            <v-input
                v-model="formMachine.name"
                class="ml-auto mb-4 w-full"
                label="Name"
                type="text"
                :errors="formErrors.name"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formMachine.email"
                class="ml-auto mb-4 w-full"
                label="Email"
                type="email"
                :errors="formErrors.email"
            />
        </div>
        <div class="flex flex-row">
            <v-select
                v-model="formMachine.type"
                class="ml-auto mb-4 w-full"
                label="Type"
                :options="[{ label: 'plot', value: 'plot' }, { label: 'easel', value: 'easel' }]"
                :errors="formErrors.type"
            />
        </div>
    </div>
</template>

<script>
import services from '@services/Api';
import get from 'lodash/get';
import { cloneDeep } from 'lodash';
import VSelect from '@outreach/components/inputs/VSelect';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { VSelect, VInput },
    props: {
        machine: { type: Object, required: true },
        submitting: { type: Boolean, required: true, default: false },
        isUpdate: { type: Boolean, default: false }
    },
    data () {
        const formMachine = cloneDeep(this.machine);
        return {
            formMachine,
            formErrors: {},
            loading: false
        };
    },
    watch: {
        submitting () {
            if (this.submitting) {
                this.loading = true;
                this.isUpdate ? this.updateMachine() : this.createMachine();
            }
        }
    },
    methods: {
        async createMachine () {
            this.formErrors = {};
            this.loading = true;

            try {
                await services.machines.create(this.formMachine);

                this.formErrors = {};
                this.$emit('formSubmitSuccessful');
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
                this.$emit('formHasErrors');
            }
            this.loading = false;
        },
        async updateMachine () {
            this.formErrors = {};
            this.loading = true;

            try {
                await services.machines.update(this.formMachine.id, this.formMachine);

                this.formErrors = {};
                this.$emit('formSubmitSuccessful');
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
                this.$emit('formHasErrors');
            }
            this.loading = false;
        }
    }
};
</script>
