<template>
    <div>
        <div v-if="paginator.isLengthAware" class="flex items-center justify-center px-2">
            <v-paginator
                class="flex-none my-8"
                :total="paginator.lastPage"
                :value="paginator.currentPage"
                @input="changePage($event)"
            />
        </div>
        <div v-else-if="paginator.isSimple" class="flex items-center justify-center py-8 px-2">
            <v-simple-paginator :pagination="pagination" @input="changePage($event)" />
        </div>

        <slot name="content"></slot>

        <div v-if="paginator.isLengthAware" class="flex items-center justify-center px-2">
            <v-paginator
                class="flex-none my-8"
                :total="paginator.lastPage"
                :value="paginator.currentPage"
                @input="changePage($event)"
            />
        </div>
        <div v-else-if="paginator.isSimple" class="flex items-center justify-center py-8 px-2">
            <v-simple-paginator :pagination="pagination" @input="changePage($event)" />
        </div>
    </div>
</template>

<script>

import VPaginator from '@outreach/components/VPaginator';
import VSimplePaginator from '@outreach/components/VSimplePaginator';
import { LengthAwarePaginator } from '@services/LengthAwarePaginator';

export default {
    name: 'PaginatorWrap',
    components: { VPaginator, VSimplePaginator },
    props: {
        pagination: { type: Object, default: () => ({}) },
        changePage: { type: Function, default: () => {} }
    },
    computed: {
        paginator () {
            if (this.pagination instanceof LengthAwarePaginator) {
                return this.pagination;
            }

            return new LengthAwarePaginator(this.pagination);
        }
    }
};
</script>
