<template>
    <div class="border rounded grid container">
        <div class="flex items-center justify-center main-image">
            <img
                v-if="asset"
                class="max-w-full max-h-full"
                :src="asset.location"
                :alt="asset.name"
            />
            <span v-else class="text-gray-600">
                None selected
            </span>
        </div>
        <div class="border-t justify-center flex py-4 w-full">
            <button class="upload-button" @click="openDialog">
                <i class="fa fa-plus text-2xl text-gray-500"></i> Upload or Select Image
            </button>
        </div>
    </div>
</template>

<script>
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { AssetType } from '@services/AssetType';

export default {
    name: 'ImageSelect',
    props: {
        asset: { type: Object, required: false, default: undefined },
        accountId: { type: Number, required: true }
    },
    methods: {
        openDialog () {
            dialogEmitter.name('ImageLibraryDialog')
                .props({ accountId: this.accountId, assetType: AssetType.NOTE_COVER, onSelect: this.onSelect })
                .wait();
        },
        onSelect (image) {
            this.$emit('select', image);
        }
    }
};
</script>

<style scoped lang="scss">
.image {
    min-height: 80px;
    height: 80px;
}
.main-image {
    height: 300px;
}
.upload-button {
    @apply border rounded flex items-center bg-gray-200 gap-2 px-6 image uppercase tracking-wider text-sm font-bold text-gray-800;
}
</style>
