<template>
    <div class="container max-w-4xl py-8">
        <div class="bg-white shadow-md rounded p-10">
            <h3 class="text-2xl mb-6 font-semibold text-grey-800 text-center">Quick Send</h3>
            <autocomplete-input
                ref="name-input"
                v-model="data.contact.name"
                :options="contacts"
                @select="$emit('contact-selected', $event)"
                @input="$emit('search', $event)"
            >
                <template #default="{option}">
                    <div>{{ option.name }}</div>
                    <div>{{ option.address.address1 }}</div>
                </template>
            </autocomplete-input>
            <div class="flex flex-col md:flex-row -mx-2 mb-4">
                <v-radio-button
                    v-for="template in templates"
                    :key="template.id"
                    v-model="data.template_id"
                    name="type"
                    class="flex-1 mx-2 mb-2"
                    :label="template.name"
                    :value="template.id"
                >
                    <div class="flex-1">
                        <div class="font-semibold mb-2">{{ template.name }}</div>
                        <div class="leading-tight font-normal opacity-75">{{ template.description }}</div>
                    </div>
                </v-radio-button>
            </div>
            <v-input v-model="data.message" label="Personalized message" class="mb-6" autocomplete="off" />
            <vue-dropzone
                label="Choose photo"
                :accepted="imageTypes"
                class="mb-12"
                @add-file="data.photo = $event"
            />
            <v-button
                class="mt-2 w-full"
                label="Submit"
                button-style="primary"
                @click="$emit('submit')"
            />
        </div>
    </div>
</template>

<script>
import AutocompleteInput from '@outreach/components/shared/AutocompleteInput';
import VInput from '@outreach/components/inputs/VInput';
import VRadioButton from '@outreach/components/inputs/VRadioButton';
import VueDropzone from '@outreach/components/shared/dropzone/VueDropzone';
import { imageTypes } from '@global/config';
import VButton from '@outreach/components/VButton';

export default {
    components: { AutocompleteInput, VInput, VRadioButton, VueDropzone, VButton },
    props: {
        contacts: { type: Array, default: () => [] },
        templates: { type: Array, default: () => [] },
        data: { type: Object, default: () => {} }
    },
    computed: {
        imageTypes: () => imageTypes
    }
};
</script>
