import services from '@services/Api';
import debounce from 'lodash/debounce';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { mapState } from 'vuex';
import get from 'lodash/get';
import { Alert } from '@services/Alert';

export default {
    data: () => ({
        contacts: null,
        meta: {},
        selected: new Set(),
        sort: { key: 'name', direction: 'ASC' },
        query: null,
        tags: [],
        lastPage: null,
        archived: false,
        requiredProp: undefined,
        formErrors: {}
    }),
    computed: {
        ...mapState({
            loading: ({ loader }) => loader.loading
        }),
        accountId () {
            throw new Error('Account id must be defined on the host component.');
        }
    },
    watch: {
        async sort () {
            this.debounceLoad(1, true);
        },
        async query () {
            this.debounceLoad(1, true);
        },
        async archived () {
            await this.load(1, true);
        }
    },
    async created () {
        await this.checkForRequiredProp();
        await this.load();
    },
    methods: {
        async checkForRequiredProp () {
            const prop = (this.$route.query?.required_prop || '')
                .split(/, ?/)
                .every(prop => ['email', 'linkedin', 'phone', undefined].includes(prop));

            if (prop) {
                this.requiredProp = this.$route.query.required_prop;
            }
        },
        debounceLoad: debounce(function (page = 1, resetList = false) {
            this.load(page, resetList);
        }, 500),
        async load (page = 1, resetList = false) {
            if (!resetList && !this.canFetchNextPage(page)) return;

            this.lastPage = page;
            try {
                const { data } = await services.contacts.list(this.accountId, {
                    page,
                    q: this.query,
                    tags: this.tags.map(tag => tag.id),
                    campaign_id: this.$route?.params.campaign ?? undefined,
                    ...(this.filters || {}),
                    sort_key: this.sort.key,
                    sort_direction: this.sort.direction,
                    per_page: 50,
                    required_prop: this.requiredProp,
                    archived: this.archived ? true : undefined
                });
                this.contacts = resetList ? data.data : [...(this.contacts || []), ...data.data];
                this.meta = data.meta;
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
                if (!this.formErrors) {
                    await Alert.error('Sorry, we were unable to load contacts. Please refresh the page and try again.');
                }
            }
        },
        canFetchNextPage (page) {
            return !this.meta.last_page || page <= this.meta.last_page;
        },
        async delete (id) {
            try {
                if (!await dialogEmitter.confirm('Delete Contact', 'Are you sure you want to delete this contact?')) {
                    return;
                }
                await services.contacts.destroy(this.accountId, id);
                this.$toasted.success('Contacts deleted');
                this.contacts = this.deleteContact(id);
            } catch (error) {
                this.$toasted.error(
                    'Sorry, we were unable to remove the selected contact. Please refresh the page and try again.');
            }
        },
        modifyContact (contactId, callback) {
            const contacts = [...this.contacts];

            const existing = contacts.findIndex(({ id }) => id === contactId);

            if (existing < 0) return contacts;

            return callback(contacts, existing);
        },
        deleteContact (contactId) {
            return this.modifyContact(contactId, (contacts, index) => {
                if (this.archived) {
                    contacts[index].deleted_at = new Date().toISOString();
                } else {
                    delete contacts[index];
                }

                return contacts;
            });
        },
        async restore (id) {
            try {
                await services.contacts.restore(this.accountId, { ids: [id] });
                await this.load(1, true);
            } catch (error) {
                this.$toasted.error(
                    'Sorry, we were unable to restore the selected contact. Please refresh the page and try again.'
                );
            }
        },
        handlePage (page) {
            this.load(page);
        },
        handleNextPage () {
            const currentPage = this.meta.current_page || 1;
            const nextPage = currentPage + 1;
            if (nextPage === this.lastPage || currentPage === this.meta.last_page) {
                return;
            }
            this.handlePage(nextPage);
        },
        handleSelection (selected) {
            this.selected = selected;
        },
        handleSort (options) {
            this.sort = options;
        },
        select (contact) {
            if (this.selected.has(contact.id)) {
                return this.deselect(contact);
            }
            this.selected = new Set([...this.selected, contact.id]);
        },
        deselect (contact) {
            const values = new Set(this.selected);
            values.delete(contact.id);
            this.selected = values;
        }
    }
};
