"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewPortBBox = void 0;
class ViewPortBBox {
    constructor(bounds) {
        this.bounds = bounds;
    }
    toBoundCoordinates() {
        return {
            ne: this.bounds.getNorthEast(),
            se: this.bounds.getSouthEast(),
            sw: this.bounds.getSouthWest(),
            nw: this.bounds.getNorthWest()
        };
    }
    toBoundsString() {
        const { ne, se, sw, nw } = this.toBoundCoordinates();
        return `${ne.lat},${ne.lng};${se.lat},${se.lng};${sw.lat},${sw.lng};${nw.lat},${nw.lng}`;
    }
}
exports.ViewPortBBox = ViewPortBBox;
