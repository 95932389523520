<template>
    <div class="p-5 bg-gray-100 border-b border-gray-300">
        <div class="pb-4 mb-4 border-b border-gray-300 md:flex items-center justify-between">
            <h1 class="text-4xl text-gray-900 font-semibold ">{{ title }}</h1>
            <div>
                <portal-target name="header-actions" />
            </div>
        </div>
        <breadcrumbs />
    </div>
</template>

<script>
import Breadcrumbs from '@outreach/components/navigation/breadcrumbs/index';

export default {
    components: { Breadcrumbs },
    props: {
        title: { type: String, default: 'Outreach' }
    }
};
</script>
