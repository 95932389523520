<template>
    <nav
        class="container nav"
        :class="{'open': open}"
    >
        <team-select />
        <div v-for="(section, index) in allowedLinks(links)" :key="index" class="flex flex-col mb-8">
            <span class="font-bold px-4 mb-2 text-sm leading-tight text-brand-regular">
                {{ section.group }}
            </span>
            <div
                v-for="(linkItem, i) in allowedLinks(section.links)"
                :key="i"
            >
                <nav-item :link="linkItem" :account-id="user.account_id">
                    <template #default="{ link }">
                        <div
                            class="absolute hidden inset-y-0 left-0 bg-brand-regular w-1 rounded-tr-lg rounded-br-lg"
                            role="presentation"
                            aria-hidden="true"
                        ></div>

                        <i class="mr-3 text-xl" :class="`${link.subset || 'fa'} fa-${link.icon}`"></i>
                        <div class="flex items-center">
                            <span class="text-gray-800">{{ link.label }}</span>
                            <span v-if="link.highlight" class="ml-1 text-red-500 font-bold">
                                [{{ link.highlight }}]
                            </span>
                        </div>
                    </template>
                </nav-item>
            </div>
        </div>
    </nav>
</template>

<script>
import { hasPermission } from '@outreach/mixins/userHasPermission';
import { mapState } from 'vuex';
import TeamSelect from '@outreach/components/navigation/side-nav/TeamSelect';
import NavItem from '@outreach/components/navigation/side-nav/NavItem';
import { Roles } from '@services/Roles';

export default {
    components: {
        TeamSelect,
        NavItem
    },
    mixins: [hasPermission],
    props: {
        open: { type: Boolean, required: true }
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        showArticles () {
            return this.user && !this.user.preferences.articles_unsubscribed && this.user.account.details?.MGE === true;
        },
        navItems () {
            if (!this.user || !this.user.account) {
                return [];
            }
            return this.user.account.nav_items || [];
        },
        canManageTeam () {
            return this.hasTeamSelected && this.user.current_team.role === 'team-owner';
        },
        hasTeamSelected () {
            return this.user.current_team != null;
        },
        links () {
            return [
                {
                    links: [
                        {
                            label: 'Terms of Service',
                            href: 'https://www.audience.co/terms-of-use/',
                            target: '_blank',
                            icon: 'file-contract'
                        }
                    ]
                },
                this.isCustomerService() || this.user.preferences?.enable_map
                    ? {
                        group: 'Map Search',
                        links: [
                            {
                                label: 'Map Search',
                                highlight: 'New',
                                route: 'send-notes-with-map',
                                icon: 'map-location-dot'
                            }
                        ]

                    }
                    : null,
                {
                    group: 'Contacts',
                    links: [
                        {
                            roles: ['super-admin', 'admin', 'account-manager', 'customer-service'],
                            label: 'Contacts',
                            route: 'contacts',
                            icon: 'address-book'
                        },
                        {
                            roles: ['super-admin', 'admin', 'account-manager', 'customer-service'],
                            label: 'Tags',
                            route: 'tags',
                            icon: 'tags'
                        }
                    ]
                },
                {
                    group: 'Campaigns',
                    links: [
                        {
                            label: 'Send Notes',
                            route: 'send-notes',
                            icon: 'mail-bulk'
                        },
                        {
                            roles: ['super-admin', 'admin', 'account-manager', 'customer-service'],
                            label: 'Drafts',
                            route: 'drafts',
                            icon: 'envelope-open-text'
                        },
                        {
                            roles: ['super-admin', 'admin', 'account-manager', 'customer-service'],
                            label: 'Sent',
                            route: 'sent',
                            icon: 'envelope'
                        },
                        {
                            roles: ['super-admin', 'admin', 'account-manager', 'customer-service'],
                            label: 'QR Scans',
                            route: 'qr-scans',
                            icon: 'qrcode'
                        },
                        {
                            roles: ['super-admin', 'admin', 'account-manager', 'customer-service'],
                            label: 'Templates',
                            route: 'templates',
                            icon: 'file-contract'
                        },
                        {
                            label: 'Graphics',
                            route: 'graphics',
                            icon: 'paint-brush'
                        }
                    ]
                },
                {
                    group: 'Training',
                    links: [
                        {
                            label: 'App Training',
                            route: 'app-training',
                            icon: 'chalkboard'
                        },
                        {
                            label: 'Digital Marketing',
                            route: 'training',
                            icon: 'people-group'
                        },
                        {
                            label: 'Campaign Breakdowns',
                            route: 'campaign-breakdowns',
                            icon: 'line-chart'
                        },
                        {
                            label: 'CH Masterclass',
                            route: 'ch-masterclass',
                            icon: 'graduation-cap'
                        }
                    ]
                },
                this.showArticles
                    ? {
                        group: 'Marketing Growth Engine',
                        roles: ['account-manager', 'admin', 'super-admin', 'customer-service'],
                        links: [
                            {
                                label: 'AI-Powered Content',
                                route: 'social',
                                icon: 'newspaper'
                            }
                        ]
                    }
                    : null,
                {
                    group: 'Account',
                    roles: ['account-manager', 'admin', 'super-admin', 'customer-service'],
                    links: [
                        ...this.navItems.map((asset) => ({
                            label: asset.name,
                            href: asset.location,
                            target: '_blank',
                            icon: 'link'
                        })),
                        this.isCustomerService()
                            ? {
                                label: 'Buy More Notes',
                                route: 'credits',
                                icon: 'money-bill'
                            }
                            : null,
                        {
                            label: 'Profile',
                            route: 'user',
                            icon: 'user-circle'
                        },
                        {
                            label: 'Password',
                            route: 'password',
                            icon: 'key'
                        },
                        {
                            label: 'Return Addresses',
                            route: 'addresses',
                            icon: 'address-book'
                        },
                        {
                            label: 'Order History',
                            route: 'order-history',
                            icon: 'history'
                        },
                        {
                            label: 'Personal Access Tokens',
                            route: 'personal-access-tokens',
                            icon: 'file-code'
                        },
                        this.canManageTeam
                            ? {
                                label: 'Manage Team',
                                route: 'manage-team',
                                icon: 'users-cog'
                            }
                            : false,
                        this.hasTeamSelected && !this.canManageTeam
                            ? {
                                label: 'View Team',
                                route: 'view-team',
                                icon: 'users-cog'
                            }
                            : false
                    ]
                },
                {
                    group: 'Sales',
                    roles: ['super-admin', 'admin', 'account-sales', 'customer-service'],
                    links: [
                        {
                            label: 'Properties (old)',
                            route: 'list-properties',
                            icon: 'sign'
                        },
                        {
                            label: 'Properties (new)',
                            route: 'properties-index',
                            icon: 'sign'
                        },
                        {
                            label: 'Agents',
                            route: 'agents',
                            icon: 'people-roof'
                        },
                        {
                            label: 'Reserved Properties',
                            route: 'reserved-properties',
                            icon: 'building'
                        },
                        this.hasPermission([Roles.CUSTOMER_SERVICE])
                            ? {
                                label: 'Customers',
                                route: 'customers',
                                icon: 'users'
                            }
                            : false
                    ]
                },
                {
                    group: 'Admin',
                    roles: ['super-admin', 'admin', 'fulfillment', 'customer-service'],
                    links: [
                        {
                            label: 'Submitted Campaigns',
                            route: 'submitted-campaigns',
                            icon: 'envelope-open-text'
                        },
                        {
                            label: 'Print Queue',
                            route: 'print-queue',
                            icon: 'print'
                        },
                        {
                            label: 'Users',
                            route: 'admin-users',
                            icon: 'users'
                        },
                        {
                            label: 'Accounts',
                            route: 'admin-accounts',
                            icon: 'id-card'
                        },
                        {
                            label: 'Invitations',
                            route: 'admin-invitations',
                            icon: 'user-plus'
                        },
                        {
                            roles: ['super-admin'],
                            label: 'Fonts',
                            route: 'admin-fonts',
                            icon: 'font'
                        },
                        {
                            roles: ['super-admin'],
                            label: 'Doodles',
                            route: 'admin-doodles',
                            icon: 'pencil-ruler'
                        },
                        {
                            label: 'Drawings',
                            route: 'admin-drawings',
                            icon: 'wave-square'
                        },
                        {
                            label: 'Machines',
                            route: 'admin-machines',
                            icon: 'hdd'
                        },
                        {
                            roles: ['super-admin', 'admin'],
                            label: 'Templates',
                            route: 'shared-templates',
                            icon: 'file-alt'
                        },
                        {
                            label: 'Add-ons',
                            route: 'collaterals',
                            icon: 'images'
                        },
                        {
                            label: 'Tools',
                            route: 'tools',
                            icon: 'tools'
                        },
                        {
                            label: 'Reports',
                            route: 'reports',
                            icon: 'chart-simple'
                        },
                        {
                            label: 'Content',
                            route: 'admin-articles',
                            icon: 'newspaper'
                        },
                        {
                            label: 'Associates',
                            route: 'associates',
                            icon: 'users'
                        },
                        {
                            label: 'Teams',
                            route: 'teams',
                            icon: 'users-cog'
                        },
                        {
                            label: 'Covers',
                            route: 'covers',
                            icon: 'panorama'
                        },
                        {
                            label: 'Pricing',
                            route: 'pricing',
                            icon: 'money-bill'
                        }
                    ]
                }
            ];
        }
    },

    methods: {
        allowedLinks (linkArray) {
            return linkArray.filter(group => {
                return group && this.hasPermission(group.roles);
            });
        }
    }
};
</script>

<style scoped>
.link {
    display: grid;
    grid-template-columns: 40px 1fr;
}

.active-link > .absolute {
    display: block;
}

.container {
    @apply w-nav -left-nav;
}

.container.open {
    @apply left-0;
}

.nav {
    @apply flex flex-col bg-white h-screen border-r border-gray-300 overflow-y-scroll fixed top-0 mt-18 md:mt-16 bottom-0 z-30 pt-5 pb-10 transition-all;
}

@screen md {
    .container {
        @apply left-0;
    }
}
</style>
