<template>
    <div class="block text-left border px-4 py-3 rounded-lg shadow-lg bg-white">
        <div v-if="!data" class="flex items-center justify-center">
            <v-loader />
        </div>
        <div v-else-if="data.isError" class="flex items-center justify-center flex-col gap-3 py-5">
            <p class="font-bold text-sm text-gray-700">Error Loading Widget</p>
            <v-button label="Retry" size="sm" button-style="error" @click.prevent="process" />
        </div>
        <div v-else>
            <slot name="content" :data="data"></slot>
        </div>
        <router-link
            v-if="reportLink"
            ref="report-link"
            class="block w-full text-center text-brand-dark mt-3 hover:underline"
            :to="reportLink"
        >
            View Report
        </router-link>
    </div>
</template>

<script>
import VLoader from '@outreach/components/VLoader';
import VButton from '@outreach/components/VButton';

export default {
    name: 'BaseWidget',
    components: { VLoader, VButton },
    props: {
        method: { type: Function, required: true },
        reportLink: { type: Object, default: undefined }
    },
    data: function () {
        return {
            data: undefined
        };
    },
    mounted () {
        this.process();
    },
    methods: {
        async process () {
            try {
                this.data = await this.method();
            } catch (e) {}
        }
    }
};
</script>
