"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupEstates = exports.Estate = void 0;
const lodash_1 = require("lodash");
class Estate {
    constructor(estate = {}) {
        this.id = -1;
        this.address = '';
        this.name = '';
        this.location = { lat: 0, lng: 0 };
        Object.assign(this, estate);
    }
}
exports.Estate = Estate;
function groupEstates(estates) {
    const grouped = (0, lodash_1.groupBy)(estates, (estate) => `${estate.location.lat}-${estate.location.lng}`);
    return Object.values(grouped).map(item => item.map(estate => new Estate(estate)));
}
exports.groupEstates = groupEstates;
