<template>
    <transition name="fade" mode="out-in">
        <div class="container mx-auto">
            <div class="flex p-6 mb-4 bg-white rounded-sm shadow-sm lg:items-center">
                <v-input
                    v-model="q"
                    class="flex-1"
                    label="Search"
                />
                <export-links-button :account-id="user.account_id" :q="q" />
                <router-link :to="{ name: 'scan-details'}">
                    <v-button ref="scan-details-button" label="View All" button-style="primary">
                        <template #before-label>
                            <i class="fa fa-angle-right mr-2"></i>
                        </template>
                    </v-button>
                </router-link>
            </div>
            <campaign-scan-table
                :campaigns="campaigns"
                @sort-changed="list({ q, sort_key: $event.key, sort_direction: $event.direction })"
            />
            <div class="flex p-6 mb-4 lg:items-center justify-center">
                <v-paginator
                    :total="pagination.lastPage"
                    :value="pagination.currentPage"
                    @input="list({ q, page: $event})"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import CampaignScanTable from '@outreach/components/campaign/CampaignScanTable';
import VInput from '@outreach/components/inputs/VInput';
import VPaginator from '@outreach/components/VPaginator';
import { LengthAwarePaginator } from '@services/LengthAwarePaginator';
import { debounce } from 'lodash';
import services from '@services/Api';
import { Alert } from '@services/Alert';
import { mapState } from 'vuex';
import ExportLinksButton from '@outreach/components/account/ExportLinksButton';
import VButton from '@outreach/components/VButton.vue';

export default {
    name: 'CampaignScans',
    components: { ExportLinksButton, CampaignScanTable, VInput, VPaginator, VButton },
    data: () => ({
        campaigns: [],
        q: undefined,
        pagination: new LengthAwarePaginator()
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    watch: {
        q: debounce(function (q) {
            this.list({ q });
        }, 400)
    },
    created () {
        this.list();
    },
    methods: {
        async list (params = {}) {
            try {
                const { data } = await services.links.campaignLinks(this.user.account_id, params);
                this.campaigns = data.data;
                this.pagination.meta = data.meta;
            } catch (e) {
                await Alert.error('An error has occurred. Please try again');
            }
        }
    }
};
</script>
