import services from '@services/Api';
import QrCodeScanned from '@events/QrCodeScanned';
import uniqBy from 'lodash/uniqBy';
import LinkTrackerWasDeleted from '@events/LinkTrackerWasDeleted';

export const state = {
    links: [],
    pagination: {},
    cachedArguments: null
};

const scannedSubscription = (context, event) => {
    context.commit('links/mergeLinks', [event.data], { root: true });
};
let cachedArguments = null;
const linkTrackerDeletedSubscription = (context, event) => {
    if (!cachedArguments) return;
    context.dispatch('links/list', cachedArguments, { root: true });
};

export const actions = {
    list: async ({ commit }, { accountId, params = {} }) => {
        commit('clearLinks');
        const { data } = await services.links.list(accountId, params);
        commit('setLinks', data);
        cachedArguments = { accountId, params };
    },
    subscribe: ({ dispatch }) => {
        dispatch('events/subscribe', {
            event: QrCodeScanned,
            subscription: scannedSubscription
        }, { root: true });
        dispatch('events/subscribe', {
            event: LinkTrackerWasDeleted,
            subscription: linkTrackerDeletedSubscription
        }, { root: true });
    },
    close: ({ dispatch }) => {
        dispatch('events/unsubscribe', {
            event: QrCodeScanned,
            subscription: scannedSubscription
        }, { root: true });
        dispatch('events/subscribe', {
            event: LinkTrackerWasDeleted,
            subscription: linkTrackerDeletedSubscription
        }, { root: true });
    }
};

const compareLastVisit = (a, b) => a.last_visit > b.last_visit ? -1 : 1;

export const mutations = {
    setLinks: (state, payload) => {
        const data = payload.hasOwnProperty('data') ? payload.data : [payload];
        mutations.mergeLinks(state, data);
        state.pagination = payload.meta;
    },
    mergeLinks: (state, data) => {
        state.links = uniqBy(
            [...state.links, ...data].sort(compareLastVisit),
            'id'
        );
    },
    clearLinks: (state) => {
        state.links = [];
        state.pagination = {};
    }
};

export const getters = {
    links: (state) => state.links,
    pagination: (state) => state.pagination
};

export default { namespaced: true, state, actions, mutations, getters };
