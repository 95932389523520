<template>
    <div>
        <ul
            class="drilldown-transition"
        >
            <li v-if="!position" class="item back sticky top-0 cursor-auto">
                {{ title }}
            </li>
            <li
                v-if="position"
                class="item back sticky top-0 cursor-pointer"
                @click="back"
            >
                BACK
            </li>
            <transition-group name="fade" mode="in-out">
                <li
                    v-for="(item, index) in currentItems"
                    :key="item.key"
                    class="item flex justify-between items-center "
                    @click="selectItem(item, index)"
                >
                    {{ item.key }}
                    <i v-if="item.children && item.children.length" class="fa-solid fa-caret-right"></i>
                    <i
                        v-if="selectedItem && selectedItem.key === item.key"
                        class="fas fa-check text-brand-light selected-item opacity-0 text-lg"
                    ></i>
                </li>
            </transition-group>

        </ul>
    </div>

</template>

<script>
import { get } from 'lodash';

export default {
    name: 'DrilldownMenu',
    props: {
        items: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    data: () => ({
        selectedItem: undefined,
        position: ''
    }),
    computed: {
        currentItems () {
            if (!this.position) {
                return this.items;
            }

            return get(this.items, this.position);
        }
    },
    methods: {
        selectItem (item, index) {
            this.selectedItem = item;
            if (!item.children?.length) {
                this.$emit('clicked-item', { ...item, position: this.position });
                return;
            }

            this.position = [this.position, index, 'children'].filter(val => val !== '').join('.');
        },
        back () {
            this.position = this.position.replace(/\.?\d+.children$/, '');
        }
    }

};
</script>

<style lang="scss" scoped>

.item {
  transition: font-weight, background, 0.2s ease-in-out;

  @apply capitalize px-6 py-3 cursor-pointer border border-b border-gray-300 ;

  &:hover {
    @apply bg-gray-300 font-bold;
  }

  &:active {
    @apply bg-gray-400;
  }
}

.item.back {
  @apply font-bold text-sm bg-gray-200;

  &:hover {
    @apply bg-gray-300;
  }
}

.drilldown-transition {
  @apply h-auto opacity-100 transition-opacity duration-500 ease-in-out;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.selected-item {
  animation: fadeOut 3000ms ease-in-out;
  animation-iteration-count: 1;
}
</style>
