<template>
    <div class="google-map"></div>
</template>

<script>

export default {
    name: 'GoogleMap',
    props: {
        options: {
            type: Object,
            default: () => ({})
        },
        service: { type: Object, required: true }

    },
    mounted () {
        this.service.createMap(this.$el, this.options);

        this.$emit('init');
    }
};
</script>

<style>
.google-map button[role='menuitemradio'] {
    padding: 10px !important;
}
</style>
