<template>
    <div>
        <div
            v-for="folder in folders"
            :key="folder.id"
            class="bg-gray-300 rounded p-4 py-3 cursor-pointer hover:shadow flex items-center text-gray-800"
            :class="{ 'bg-gray-500 text-white': selected && selected.id === folder.id }"
            :title="folder.name"
            @click="select(folder)"
        >
            <i class="fa fa-folder mr-2"></i> <span class="truncate">{{ folder.name }}</span>
            <div class="ml-auto font-bold">{{ folder.assets_count }}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FolderList',
    props: {
        folders: { type: Array, default: () => [] }
    },
    data () {
        return {
            selected: undefined
        };
    },
    methods: {
        select (folder) {
            this.selected = folder;
            this.$emit('selected', this.selected);
        }
    }
};
</script>
