"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetResponse = void 0;
class WidgetResponse {
    constructor(data) {
        this.data = data;
        this.error = null;
    }
    setError(error) {
        this.error = error;
        return this;
    }
    static fromError(error) {
        return new this().setError(error);
    }
    static fromResponse(data) {
        return new this(data);
    }
    get isError() {
        return this.error != null;
    }
}
exports.WidgetResponse = WidgetResponse;
