<template>
    <authentication-layout>
        <h2 class="text-gray-800 text-xl mb-2">Reset your password</h2>
        <p class="text-gray-500 text-sm mb-5">Enter your email address to receive a reset password link.</p>
        <form class="block" @submit.prevent="resetPassword">
            <v-input
                v-model="email"
                class="mb-6"
                label="Email"
                type="email"
                :errors="formErrors.email"
            />
            <v-button
                class="w-full text-left flex items-center"
                label="Send reset password email"
                button-style="success"
                :loading="loading"
            >
                <template slot="after-label">
                    <i class="fa fa-chevron-right ml-auto"></i>
                </template>
            </v-button>
        </form>
    </authentication-layout>
</template>

<script>
import AuthenticationLayout from '@outreach/components/layouts/Authentication';
import services from '@services/Api';
import get from 'lodash/get';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { AuthenticationLayout, VButton, VInput },
    data: () => ({
        email: '',
        loading: false,
        formErrors: {}
    }),
    methods: {
        async resetPassword () {
            this.loading = true;
            this.formErrors = {};

            try {
                const { data } = await services.users.resetPassword(this.email);
                this.$router.push({ name: 'login' });
                if (data.status) {
                    this.$toasted.success(data.status);
                }
                return;
            } catch (e) {
                const { response } = e;
                this.formErrors = get(response, 'data.errors', {});
            }

            this.loading = false;
        }
    }
};
</script>
