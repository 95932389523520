<template>
    <section class="w-full">
        <search-header>
            <template #content>
                <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1" label="Search" @input="search" />
                <admin-tag-select
                    v-model="tags"
                    placeholder="Filter By Tag"
                    :taggable="false"
                    :type="tagType"
                    @input="list({ q: query, tags: tagIds, type })"
                />
            </template>
        </search-header>
        <div v-if="articles.length > 0" class="flex flex-col">
            <v-paginator
                class="self-center my-4 md:mt-0"
                :value="meta.current_page"
                :total="meta.last_page"
                @input="list({ page: $event, q: query, tags: tagIds, type })"
            />
            <div class="grid gap-4 lg:grid-cols-2">
                <div
                    v-for="article in articles"
                    :key="article.id"
                    class="bg-white rounded shadow overflow-hidden flex flex-col"
                >
                    <img class="w-full bg-gray-500" :src="article.header_image" :alt="article.title" />
                    <div class="p-4 flex-1">
                        <p class="text-xl font-bold">{{ article.title }}</p>
                        <time
                            :datetime="article.subscribed_at"
                            class="font-light text-sm"
                        >{{ formatFullDate(article.subscribed_at) }}</time>
                        <display-tags class="mt-1" :tags="article.tags" />
                        <p class="mt-4 font-light" v-html="article.summary"></p>
                    </div>
                    <div class="border-t border-t flex mt-auto">
                        <router-link
                            :to="{
                                name: `view-${article.type === 'video' ? 'video' : 'article'}`,
                                params: { articleId: article.id }
                            }"
                            class="article-link"
                        >
                            <span class="ml-3">View</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <v-paginator
                class="self-center mb-44 mt-4 md:mb-0"
                :value="meta.current_page"
                :total="meta.last_page"
                @input="list({ page: $event, q: query, tags: tagIds, type })"
            />
        </div>
        <v-call-to-action
            v-else
            title="No subscribed articles"
            subtitle="Get started by reaching out to a customer service rep for articles."
            icon="newspaper"
        />
    </section>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import VCallToAction from '@outreach/components/shared/VCallToAction';
import { formatFullDate } from '@services/Filters';
import debounce from 'lodash/debounce';
import AdminTagSelect from '@outreach/components/tag-select/AdminTagSelect';
import VInput from '@outreach/components/inputs/VInput';
import VPaginator from '@outreach/components/VPaginator';
import DisplayTags from '@outreach/components/shared/DisplayTags';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import { TagType } from '@services/TagType';

export default {
    name: 'UserArticles',
    components: { SearchHeader, DisplayTags, VCallToAction, AdminTagSelect, VInput, VPaginator },
    props: {
        type: { type: String, required: true },
        requireTag: { type: String, default: undefined },
        excludeTags: { type: Array, default: undefined }
    },
    data () {
        return {
            query: null,
            tags: []
        };
    },
    computed: {
        ...mapState({
            articles: ({ articles }) => articles.records,
            meta: ({ articles }) => articles.meta
        }),
        tagType () {
            return TagType.CONTENT;
        },
        tagIds () {
            return this.tags.map(tag => ({ id: tag.id }));
        }
    },
    mounted () {
        this.list({ type: this.type, require_tag: this.requireTag, exclude_tags: this.excludeTags });
    },
    methods: {
        ...mapActions({
            list: 'articles/list'
        }),
        search: debounce(function (q) {
            this.list({ q, tags: this.tagIds, type: this.type });
        }, 500),
        formatFullDate
    }
};
</script>

<style lang="scss" scoped>
.article-link {
    @apply relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium hover:text-gray-500 w-full flex;
}
</style>
