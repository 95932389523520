"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstatesInfoWindow = void 0;
const InfoWindow_1 = require("@outreach/pages/OutboundProspecting/InfoWindow");
class EstatesInfoWindow extends InfoWindow_1.InfoWindow {
    constructor(estates) {
        super();
        this.estates = estates;
        this.label = estates[0].name;
    }
    getAddressContent() {
        return this.estates.map((estate) => `<div class="mb-3 text-base capitalize">
                    <p class="text-xs">${estate.name.toLowerCase()}</p>
                    <p>${estate.address.toLowerCase()}</p>
                </div>`).join('');
    }
    getHeader() {
        return this.estates.length > 1
            ? `<div class="pb-2 border-b mb-2">
                Displaying <span class="font-bold">${this.estates.length}</span> Results
                </div>`
            : '';
    }
    getContent() {
        return `<div id="content">
                    ${this.getHeader()}
                    ${this.getAddressContent()}
                </div>`;
    }
    build(options = {}) {
        return super.build(Object.assign({ position: this.estates[0].location }, options));
    }
}
exports.EstatesInfoWindow = EstatesInfoWindow;
