import ApiClient from '@services/Http/Api';

export const list = (params = {}) => {
    return ApiClient.get('/dashboard/letters', { params });
};

export const listForCampaign = (campaign) => {
    return ApiClient.get(`/dashboard/campaigns/${campaign}/letters`);
};

export const fetch = (letterId) => {
    return ApiClient.get(`/dashboard/letters/${letterId}`);
};

export const update = (letterId, letter) => {
    return ApiClient.patch(`/dashboard/letters/${letterId}`, letter);
};

export const create = (letter) => {
    return ApiClient.post('/dashboard/letters', letter);
};

export const createForCampaign = (campaign, letter) => {
    return ApiClient.post(`/dashboard/campaigns/${campaign}/letters`, letter);
};

export const destroy = letterId => {
    return ApiClient.destroy(`/dashboard/letters/${letterId}`);
};

export const restore = letterId => {
    return ApiClient.post(`/dashboard/letters/${letterId}/restore`);
};
