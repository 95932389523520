<template>
    <div>
        <button class="upload-button" @click="uploadFile">
            <i class="fa fa-plus text-2xl text-gray-500"></i>
            <span class="text-center text-gray-700">Upload Image</span>
        </button>
        <input
            ref="input"
            class="hidden"
            type="file"
            accept=".jpg,.jpeg,.png,.svg,.gif,.bmp,.webp"
            @input="encodeImage"
        />
    </div>
</template>
<script>
export default {
    name: 'ImageUpload',
    data () {
        return {
            reader: new FileReader(),
            file: undefined
        };
    },
    methods: {
        uploadFile () {
            this.$refs.input.click();
        },
        encodeImage () {
            this.file = this.$refs.input.files[0];
            this.reader.onloadend = this.imageUploaded;
            this.reader.readAsDataURL(this.file);
        },
        imageUploaded () {
            this.$emit('imageUploaded', { data: this.reader.result, file: this.file });
            this.$refs.input.value = '';
        }
    }
};
</script>
<style lang="scss" scoped>
.upload-button {
  @apply flex flex-col items-center justify-center w-full h-full py-6 rounded-lg bg-gray-100 cursor-pointer hover:ring-2 hover:ring-brand-dark;
}
</style>
