<template>
    <v-field
        :has-value="hasValidOption"
        :is-focused="isFocused"
        :errors="[...errors, isValid ? undefined : validationMessage]"
        :help="help"
    >
        <v-label
            :text="label"
            :focused="hasValidOption || isFocused"
            :disabled="disabled"
        >
            <multiselect
                :class="{'invalid': !isValid}"
                :aria-label="label"
                :disabled="disabled"
                :value="value"
                :options="currentOptions"
                taggable
                :show-labels="false"
                tag-placeholder=""
                close-on-select
                placeholder=""
                @tag="onChange($event)"
                @input="onChange($event)"
                @open="isFocused = true"
                @close="isFocused = false"
                @search-change="onSearchChange($event)"
            />
        </v-label>
    </v-field>
</template>
<script>
import { Multiselect } from 'vue-multiselect';
import VField from '@outreach/components/shared/VField.vue';
import VLabel from '@outreach/components/shared/VLabel.vue';

export default {
    components: { VLabel, VField, Multiselect },
    props: {
        value: { type: [String, Number], default: undefined },
        options: { type: Array, required: true },
        label: { type: String, required: true },
        errors: { type: [Array, String], required: false, default: () => [] },
        help: { type: String, default: undefined },
        pattern: { type: String, default: undefined },
        disabled: { type: Boolean, default: false },
        validationMessage: { type: String, default: 'Please enter valid value.' }
    },
    data () {
        return {
            isFocused: false,
            currentValue: undefined
        };
    },
    computed: {
        hasValidOption () {
            return this.value === 0 || Boolean(this.value);
        },
        currentOptions () {
            return !this.optionExists && this.hasValidOption ? [this.value, ...this.options] : this.options;
        },
        optionExists () {
            return this.options.includes(this.value);
        },
        isValid () {
            return !this.pattern || RegExp(this.pattern).test(this.currentValue) || !this.currentValue;
        }
    },
    methods: {
        onChange (value) {
            this.currentValue = value;

            this.$emit('input', value);
        },
        onSearchChange (value) {
            if (value) {
                this.currentValue = value;
            }
        }
    }
};
</script>
