<template>
    <div>
        <div v-if="template.admin_provided" class="p-4 py-2 mb-2 bg-red-100 rounded-lg text-gray-700">
            <i class="fa-regular fa-circle-info"></i>
            This is an admin template. If you would like to edit the its contents, duplicate it first.
        </div>
        <template-form
            label="Edit template"
            :template.sync="template"
            :form-errors="formErrors"
            :disabled="template.admin_provided"
            @submit="submit"
        />
    </div>
</template>

<script>
import TemplateForm from '@outreach/pages/Templates/TemplateForm';
import { mapActions, mapGetters } from 'vuex';
import { Template } from '@models/Template';

export default {
    components: { TemplateForm },
    data: () => ({
        template: new Template()
    }),
    computed: {
        ...mapGetters({
            formErrors: 'userTemplates/formErrors'
        }),
        templateId () {
            return this.$route.params.template;
        }
    },
    created () {
        this.fetch(this.templateId).then(template => {
            this.template = new Template(template);
        });
    },
    methods: {
        ...mapActions({
            fetch: 'userTemplates/fetch',
            update: 'userTemplates/update'
        }),
        async submit () {
            const updated = await this.update(this.template);
            if (updated) {
                this.$router.push({ name: 'templates' });
                this.$toasted.success('Template updated');
            }
        }
    }
};
</script>
