<template>
    <v-modal header="Machines" name="change-machines-modal" disable-on-click-away>
        <template v-if="campaign" slot="content">
            <div class="w-modal pb-64">
                <machine-select
                    :campaign="campaign"
                    label="Change machines for campaign"
                    @machines="refreshState"
                />
            </div>
        </template>
        <template #modal-actions="{close}">
            <v-button
                ref="done-button"
                label="Done"
                button-style="primary"
                @click="close"
            />
        </template>
    </v-modal>
</template>

<script>
import MachineSelect from '@outreach/components/campaign/MachineSelect';
import VModal from '@outreach/components/shared/modal/VModal';
import { mapActions, mapState } from 'vuex';
import VButton from '@outreach/components/VButton';

export default {
    components: {
        VModal,
        MachineSelect,
        VButton
    },
    props: {
        campaignId: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapState({
            campaign: ({ campaign }) => campaign.campaign
        })
    },
    watch: {
        campaignId () {
            this.getCampaign();
        }
    },
    created () {
        this.getCampaign();
    },
    methods: {
        ...mapActions({
            fetchCampaign: 'campaign/getCampaign',
            refreshPending: 'pending/listCampaigns'
        }),
        async getCampaign () {
            await this.fetchCampaign({ campaignId: this.campaignId, includes: ['machines'] });
        },
        refreshState () {
            this.getCampaign();
            this.refreshPending();
        }
    }
};
</script>
