<template>
    <div class="text-center">
        <i class="mx-auto text-gray-400 fa mr-3 text-6xl" :class="`fa-${icon}`"></i>
        <h3 class="mt-2 text-lg font-medium text-gray-700">{{ title }}</h3>
        <p class="mt-1 text-sm text-gray-600">{{ subtitle }}</p>
        <slot name="action"></slot>
    </div>
</template>

<script>
export default {
    name: 'VCallToAction',
    props: {
        title: { type: String, required: true },
        subtitle: { type: String, default: null },
        icon: { type: String, default: null }
    }
};
</script>
