<template>
    <base-table
        ref="base-table"
        type="completed"
        header="Completed Jobs"
        :campaign-actions="campaignActions"
        :job-actions="jobActions"
        :searchable="true"
        :loading="loading"
    />
</template>

<script>
import BaseTable from '@outreach/components/queues/BaseTable';
import { mapGetters } from 'vuex';

export default {
    components: {
        BaseTable
    },
    data () {
        return {
            campaignActions: [
                {
                    label: 'Retry',
                    action: 'completed/retryCampaign',
                    confirm: 'Are you sure you want to retry this campaign?',
                    ref: 'retry',
                    buttonStyle: 'info'
                }
            ],
            jobActions: [
                {
                    label: 'Retry',
                    action: 'completed/retryJob',
                    confirm: 'Are you sure you want to retry this job?',
                    ref: 'retry',
                    buttonStyle: 'warning'
                },
                {
                    label: 'Delete',
                    action: 'completed/deleteJob',
                    confirm: 'Are you sure you want to delete this job?',
                    ref: 'delete',
                    buttonStyle: 'error'
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            loading: 'completed/loading'
        })
    }
};
</script>
