<template>
    <div>
        <div class="w-full lg:grid lg:grid-cols-2 xl:grid-cols-4 gap-3">
            <div class="w-full break-inside-avoid-column mb-2">
                <stale-campaigns-widget />
            </div>
            <div class="w-full break-inside-avoid-column mb-2">
                <scan-summary-widget />
            </div>
            <div class="w-full break-inside-avoid-column mb-2">
                <note-count-widget />
            </div>
            <div class="w-full break-inside-avoid-column mb-2">
                <router-link :to="{name: 'last-sent-report'}" class="link">
                    <i class="fa fa-analytics text-gray-500"></i>
                    <p>Last Sent Report</p>
                </router-link>
            </div>

        </div>
    </div>
</template>

<script>
import NoteCountWidget from '@outreach/components/widgets/NoteCountWidget';
import ScanSummaryWidget from '@outreach/components/widgets/ScanSummaryWidget';
import StaleCampaignsWidget from '@outreach/components/widgets/StaleCampaignsWidget.vue';

export default {
    name: 'Reports',
    components: { StaleCampaignsWidget, ScanSummaryWidget, NoteCountWidget },
    computed: {
        isDevelopment () {
            return process.env.NODE_ENV !== 'production';
        }
    }
};
</script>

<style scoped>
.link {
    @apply shadow rounded bg-white p-4 flex items-center justify-center gap-3 hover:text-brand-regular hover:bg-gray-50;
}
</style>
