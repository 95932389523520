<template>
    <div class="w-full">
        <div class="flex flex-row">
            <v-input
                v-model="formFont.title"
                class="ml-auto mb-4 w-full"
                label="Title"
                type="text"
                :errors="formErrors.title"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formFont.size"
                class="ml-auto mb-4 w-full"
                label="Size"
                type="number"
                :errors="formErrors.size"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formFont.baseline_variation"
                class="ml-auto mb-4 w-full"
                label="Baseline Variation"
                type="number"
                :min="0"
                :max="100"
                :step="10"
                :errors="formErrors.baseline_variation"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formFont.indent_variation"
                class="ml-auto mb-4 w-full"
                label="Indent Variation"
                type="number"
                :min="0"
                :max="100"
                :step="10"
                :errors="formErrors.indent_variation"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formFont.kerning_variation"
                class="ml-auto mb-4 w-full"
                label="Kerning Variation"
                type="number"
                :min="0"
                :max="100"
                :step="10"
                :errors="formErrors.kerning_variation"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formFont.font_size_variation"
                class="ml-auto mb-4 w-full"
                label="Font Size Variation"
                type="number"
                :min="1"
                :max="100"
                :step="5"
                :errors="formErrors.font_size_variation"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formFont.random_seed"
                class="ml-auto mb-4 w-full"
                label="Random Seed"
                type="number"
                :errors="formErrors.random_seed"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formFont.letter_spacing"
                class="ml-auto mb-4 w-full"
                label="Letter Spacing"
                type="number"
                :min="50"
                :max="400"
                :step="10"
                :errors="formErrors.letter_spacing"
            />
        </div>
        <div class="flex flex-row">
            <v-input
                v-model="formFont.word_spacing"
                class="ml-auto mb-4 w-full"
                label="Word Spacing"
                type="number"
                :min="50"
                :max="600"
                :step="10"
                :errors="formErrors.word_spacing"
            />
        </div>
        <div class="flex flex-row">
            <v-checkbox
                v-model="formFont.enable_defects"
                class="ml-auto mb-4 w-full"
                label="Enable Defects"
                :errors="formErrors.enable_defects"
            />
        </div>
    </div>
</template>

<script>
import services from '@services/Api';
import get from 'lodash/get';
import { cloneDeep } from 'lodash';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import VCheckbox from '@outreach/components/inputs/VCheckbox';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { VCheckbox, VInput },
    props: {
        font: { type: Object, required: true },
        submitting: { type: Boolean, required: true, default: false }
    },
    data () {
        const formFont = cloneDeep(this.font);
        return {
            formFont,
            formErrors: {},
            loading: false
        };
    },
    watch: {
        submitting () {
            if (this.submitting) {
                this.loading = true;
                this.updateFont();
            }
        }
    },
    methods: {
        async updateFont () {
            this.formErrors = {};
            this.loading = true;

            try {
                await services.fonts.update(this.formFont.id, this.formFont);

                this.formErrors = {};
                this.$emit('formSubmitSuccessful');
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
                this.$emit('formHasErrors');
                throw new ClientVisibleError('Update font failed.');
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
