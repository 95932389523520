<template>
    <div>
        <div class="my-4 mx-4 md:mx-0 grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
            <queue-count href="#pending-queue" :count="stats.pending_count" title="Pending" />
            <queue-count href="#reserved-queue" :count="stats.reserved_count" title="Reserved" />
            <queue-count href="#failed-queue" :count="stats.failed_count" title="Failed" />
            <note-count-widget interval="hour" />
        </div>
        <h3 class="text-2xl pb-3">Campaigns</h3>
        <note-jobs-queue />
        <h3 class="text-2xl">Drawings</h3>
        <single-jobs-queue />
    </div>
</template>

<script>
import QueueCount from '@outreach/components/queues/QueueCount';
import { mapActions, mapGetters } from 'vuex';
import NoteCountWidget from '@outreach/components/widgets/NoteCountWidget';
import SingleJobsQueue from '@outreach/pages/admin/SingleJobsQueue';
import NoteJobsQueue from '@outreach/components/queues/NoteJobsQueue';

export default {
    components: {
        NoteJobsQueue,
        SingleJobsQueue,
        QueueCount,
        NoteCountWidget
    },
    computed: mapGetters({
        stats: 'queue/stats'
    }),
    created () {
        this.refreshQueues();
    },
    methods: {
        ...mapActions({
            refreshQueues: 'queue/refreshQueues'
        })
    }
};
</script>
