"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RealEstateFilters {
    constructor() {
        this._est_value = { min: undefined, max: undefined };
        this._types = [];
        this._bedroom_count = { min: undefined, max: undefined };
        this._year = { min: undefined, max: undefined };
        this._equity_percent = { min: undefined, max: undefined };
        this._sold_at = { min: undefined, max: undefined };
        this._sq_ft = { min: undefined, max: undefined };
        this._exclude_companies = false;
        this._owner_occupied = undefined;
    }
    static fromQueryString(queryParams) {
        const filters = new RealEstateFilters();
        const filtered = filters.parseQueryString(queryParams);
        filters.exclude_companies = ['1', 'true'].includes(filtered.exclude_companies);
        filters.owner_occupied = filtered.owner_occupied === undefined
            ? undefined
            : ['1', 'true'].includes(filtered.owner_occupied);
        filters.types = filtered.types ? filtered.types.split(/, ?/) : [];
        RealEstateFilters.ranges.forEach(key => {
            filters.setRange(key, { min: filtered[`${key}.min`], max: filtered[`${key}.max`] });
        });
        return filters;
    }
    get est_value() {
        return this._est_value;
    }
    set est_value(value) {
        this._est_value = value;
    }
    get types() {
        return this._types;
    }
    set types(types) {
        this._types = types.filter(type => RealEstateFilters.validTypes.includes(type));
    }
    get bedroom_count() {
        return this._bedroom_count;
    }
    set bedroom_count(value) {
        this._bedroom_count = value;
    }
    get year() {
        return this._year;
    }
    set year(value) {
        this._year = value;
    }
    get equity_percent() {
        return this._equity_percent;
    }
    set equity_percent(value) {
        this._equity_percent = value;
    }
    get sold_at() {
        return this._sold_at;
    }
    set sold_at(value) {
        this._sold_at = value;
    }
    get sq_ft() {
        return this._sq_ft;
    }
    set sq_ft(value) {
        this._sq_ft = value;
    }
    get exclude_companies() {
        return this._exclude_companies;
    }
    set exclude_companies(value) {
        this._exclude_companies = value;
    }
    get owner_occupied() {
        return this._owner_occupied;
    }
    set owner_occupied(value) {
        this._owner_occupied = value;
    }
    getFilters() {
        return {
            est_value: this.est_value,
            types: this.types,
            bedroom_count: this.bedroom_count,
            year: this.year,
            equity_percent: this.equity_percent,
            sold_at: this.sold_at,
            sq_ft: this.sq_ft,
            exclude_companies: this.exclude_companies,
            owner_occupied: this.owner_occupied
        };
    }
    hasFilters() {
        return RealEstateFilters.ranges
            .some(field => this[field].min ||
            this[field].max) ||
            this.exclude_companies ||
            this.types.length > 0 ||
            typeof this.owner_occupied === 'boolean';
    }
    setFilters(filters) {
        Object.entries(filters)
            .filter(([key]) => RealEstateFilters.ranges.includes(key))
            .forEach(([key, value]) => {
            this.setRange(key, value);
        });
        this.types = filters.types || this.types;
        this.exclude_companies = filters.exclude_companies || this.exclude_companies;
        this.owner_occupied = filters.owner_occupied || this.owner_occupied;
    }
    parseQueryString(queryParams) {
        const filtered = {};
        Object.entries(queryParams)
            .filter(([, value]) => typeof value === 'string')
            .forEach(([key, value]) => {
            filtered[key] = value;
        });
        return filtered;
    }
    setRange(key, value) {
        this[key] = value;
    }
}
exports.default = RealEstateFilters;
RealEstateFilters.validTypes = [
    'UTL', 'LND', 'RES', 'AGR', 'COM', 'SFR', 'GOV', 'OTH',
    'REC', 'CND', 'APT', 'IND', 'TRA', 'UNK', 'MFR'
];
RealEstateFilters.ranges = [
    'est_value', 'bedroom_count', 'year', 'equity_percent', 'sold_at', 'sq_ft'
];
