<template>
    <div class="container mx-auto">
        <div class="flex flex-col xl:flex-row gap-8">
            <div class="xl:w-2/3">
                <search-header>
                    <template #content>
                        <div class="w-full">
                            <div class="flex flex-col lg:flex-row lg:items-center gap-3">
                                <toggle-group
                                    :options="tabs"
                                    label="Type"
                                    class="w-full sm:w-auto"
                                    :default-selected="(v) => v.value === tab"
                                    @input="toggleTable"
                                />

                                <v-input
                                    ref="search-input"
                                    v-model="query"
                                    label="Search"
                                    class="w-full lg:w-auto lg:flex-1"
                                />
                            </div>
                        </div>
                    </template>
                </search-header>
                <customers-table v-if="tab === 'customers'" :query="query" :sort.sync="sort" />
                <stale-campaigns-table v-else :query="query" :sort.sync="sort" />
            </div>
            <div class="xl:w-1/3">
                <stale-campaigns />
            </div>
        </div>

    </div>
</template>

<script>
import SearchHeader from '@outreach/components/shared/SearchHeader.vue';
import VInput from '@outreach/components/inputs/VInput.vue';
import StaleCampaignsTable from '@outreach/pages/Customers/StaleCampaignsTable.vue';
import ToggleGroup from '@outreach/components/shared/ToggleGroup.vue';
import CustomersTable from '@outreach/pages/Customers/CustomersTable.vue';
import StaleCampaigns from '@outreach/pages/Customers/Stats/StaleCampaigns.vue';
import { get } from 'lodash';

export default {
    name: 'ViewCustomers',
    components: {
        StaleCampaigns,
        CustomersTable,
        ToggleGroup,
        StaleCampaignsTable,
        SearchHeader,
        VInput
    },
    data () {
        return {
            query: undefined,
            tabs: [{ label: 'Customers', value: 'customers' }, { label: 'Stale Campaigns', value: 'stale-campaigns' }],
            tab: 'customers',
            sort: {
                key: undefined,
                direction: undefined
            }
        };
    },
    computed: {
        sortableBy () {
            return {
                customers: [
                    'last_logged_in_at',
                    'last_submitted_at',
                    'completed_count',
                    'credits',
                    'transmissions_count'
                ],
                'stale-campaigns': ['name', 'contacts_count', 'created_at']
            }[this.tab] || [];
        }
    },
    watch: {
        query () {
            this.updateQs();
        },
        sort () {
            this.updateQs();
        }
    },
    created () {
        this.tab = this.tabs.find(tab => tab.value === this.$route.query?.tab)?.value || this.tab;
        this.query = this.$route.query?.query?.replace(/(<([^>]+)>)/gi, '');
        this.sort.key = this.sortableBy
            .find(column => column === this.$route.query?.['sort.key']?.toLowerCase()) || undefined;
        this.sort.direction = ['asc', 'desc']
            .find(direction => direction === this.$route.query?.['sort.direction']?.toLowerCase()) || undefined;
    },
    methods: {
        updateQs () {
            const qp = new URLSearchParams();
            const filters = ['tab', 'query', 'sort.key', 'sort.direction'];
            filters.filter((filter) => get(this, filter)).forEach((filter) => qp.set(filter, get(this, filter)));
            history.replaceState(null, null, '?' + qp.toString());
        },
        async toggleTable (value) {
            this.query = undefined;
            this.sort = {
                key: undefined,
                direction: undefined
            };
            this.tab = value;
            this.updateQs();
        }
    }
};
</script>
