import ApiClient from '@services/Http/Api';

export const list = (campaignId, params = {}) => {
    return ApiClient.get(`/dashboard/campaigns/${campaignId}/contacts`, { params });
};

export const destroy = (campaignId, contactId) => {
    return ApiClient.destroy(`/dashboard/campaigns/${campaignId}/contact/${contactId}`);
};

export const fetch = contactId => {
    return ApiClient.get(`/dashboard/contacts/${contactId}`);
};

export const update = (contactId, contact) => {
    return ApiClient.patch(`/dashboard/contacts/${contactId}`, contact);
};

export const create = (audience_id, contact) => {
    return ApiClient.post(`/dashboard/audiences/${audience_id}/contacts`, contact);
};
