"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetType = void 0;
var AssetType;
(function (AssetType) {
    AssetType["POSTCARD_COVER"] = "postcard_cover";
    AssetType["NOTE_COVER"] = "note_cover";
    AssetType["NOTE_PDF"] = "note_pdf";
    AssetType["ENVELOPE_PDF"] = "envelope_pdf";
    AssetType["POSTCARD_PDF"] = "postcard_pdf";
    AssetType["QR_CODE_PDF"] = "qr_code_pdf";
    AssetType["WITHOUT_QR"] = "without_qr_pdf";
    AssetType["THUMBNAIL"] = "thumbnail";
    AssetType["NAV_ITEM"] = "nav_item";
})(AssetType = exports.AssetType || (exports.AssetType = {}));
