<template>
    <div class="justify-between py-8">
        <h3 class="mb-2 text-xl text-gray-800 font-semibold">{{ label }}</h3>

        <div v-if="!loading && !options.length">
            <p class="text-gray-700">No machines available, contact an admin to add machines.</p>
        </div>
        <div v-else class="flex flex-col items-start">
            <div class="inline-block w-full">
                <v-multiple-select
                    id="account-select"
                    v-model="selected"
                    :internal-search="false"
                    :loading="loading"
                    :multiple="true"
                    :options="options"
                    placeholder="Add machines"
                    @input="updateMachines"
                    @remove="updateMachines"
                    @search-change="getMachines"
                />
            </div>
            <v-button
                ref="remove-all-machines-button"
                class="pt-2"
                size="sm"
                label="Remove All Machines"
                @click="removeAllMachines"
            />
        </div>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>

import services from '@services/Api';
import { mapGetters } from 'vuex';
import VMultipleSelect from '@outreach/components/inputs/VMultipleSelect';
import VButton from '@outreach/components/VButton';

export default {
    components: { VMultipleSelect, VButton },
    props: {
        campaign: {
            type: Object,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: 'Select machines for printing'
        },
        machineType: {
            validator: (value) => ['plot', 'easel'].includes(value),
            default: 'plot'
        }
    },
    data () {
        return {
            selected: this.campaign.machines,
            options: []
        };
    },
    computed: mapGetters({
        loading: 'loader/isLoading'
    }),
    watch: {
        campaign: function (value) {
            this.selected = value.machines;
        },
        machineType: function () {
            this.getMachines();
        }
    },
    created () {
        this.getMachines();
    },
    methods: {
        async getMachines (q) {
            const { data } = await services.machines.list({ q, type: this.machineType });
            this.options = [{ id: 'all', name: 'All' }, ...data.data];
        },
        async updateMachines (selected) {
            await this.handleAll(selected);
            await services.campaigns.update(this.campaign.id, {
                machines: this.selected.map(machine => machine.id)
            });
            this.$emit('machines', this.selected);
        },
        async handleAll (selected) {
            if (Array.isArray(selected) && selected.some(value => value.id === 'all')) {
                this.selected = this.options.filter(option => option.id !== 'all');
            }
        },
        removeAllMachines () {
            this.selected = [];
            this.updateMachines([]);
        }
    }
};
</script>

<style>
.multiselect__tag {
    @apply bg-brand-regular;
}

.multiselect__tag-icon:after {
    @apply text-white;
}

.multiselect__tag-icon:hover {
    @apply bg-brand-dark;
}

.multiselect__option--highlight,
.multiselect__option--highlight:after {
    @apply bg-brand-light;
}
</style>
