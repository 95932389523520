export const state = {
    open: []
};

export const actions = {
    open: ({ commit }, payload) => commit('openModal', payload),
    close: ({ commit }, payload) => commit('closeModal', payload)
};

export const mutations = {
    openModal: (state, payload) => state.open.unshift(payload),
    closeModal: (state, payload) => (state.open = state.open.filter((e) => e !== payload))
};

export const getters = {
    active: (state) => (state.open.length > 0 ? state.open[0] : null),
    allOpen: (state) => state.open
};

export default { namespaced: true, state, actions, mutations, getters };
