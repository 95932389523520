<template>
    <div>
        <div class="bg-white mb-4 preview-lg shadow-md">
            <img :src="`${ previews.letter }?v=${ version }`" alt="Postcard preview" />
        </div>
        <div>
            <h3 class="text-sm mb-2 uppercase font-semibold text-gray-500">Front</h3>
            <div class="preview-sm">
                <div class="bg-white mb-4 shadow-md flex items-center justify-center preview-sm w-full">
                    <img v-if="photoPath" class="max-h-full max-w-full" :src="photoPath" alt="Postcard preview" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        previews: { type: Object, required: true },
        photoPath: { type: String, default: null }
    },
    data: () => ({
        photo: null,
        version: 0
    }),
    watch: {
        previews () {
            this.version++;
        }
    }
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
$w-preview-sm: 240px;

.preview-lg {
    max-width: 760px;
}

@screen md {
    .preview-sm {
        height: math.div($w-preview-sm * 2, 3);
        width: $w-preview-sm;
    }
}
</style>
