import moment from 'moment';

export class LinkTracker {
    constructor () {
        this.fetchStore();
    }

    fetchStore () {
        const store = window.localStorage.getItem('link_tracker');
        this.store = store ? JSON.parse(store) : {};
    }

    track (name) {
        this.store[name] = new Date().toISOString();
        this.writeStore();
    }

    get (name) {
        return this.store[name];
    }

    writeStore () {
        window.localStorage.setItem('link_tracker', JSON.stringify(this.processStore()));
    }

    processStore () {
        return Object.entries(this.store).reduce((carry, [key, value]) => {
            if (moment(value).isSameOrBefore(moment().subtract(6, 'months'))) {
                return carry;
            }
            return {
                ...carry,
                [key]: value
            };
        }, {});
    }
}
