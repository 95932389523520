<template>
    <div class="w-full">
        <v-panel title="" class="container mx-auto">
            <template #titleAction>
                <h3 class="text-2xl text-gray-800 font-semibold">{{ label }}</h3>
            </template>
            <template #actionButton>
                <div class="flex flex-col lg:flex-row gap-4">
                    <duplicate-template-button
                        v-if="template.admin_provided"
                        :template="template"
                        label="Duplicate"
                        confirm="Do you want to duplicate this template?"
                    />
                    <restore-button :value="template" @restore="$emit('update:template', $event)" />
                    <v-button ref="next-button" label="Save" button-style="primary" @click="$emit('submit')" />
                </div>
            </template>
            <template>
                <v-input
                    class="mb-4"
                    label="Name"
                    :value="template.name"
                    :errors="formErrors.name"
                    :disabled="disabled"
                    @input="update('name', $event)"
                />
                <v-input
                    class="mb-4"
                    label="Description"
                    :value="template.description"
                    :errors="formErrors.description"
                    :disabled="disabled"
                    @input="update('description', $event)"
                />
                <v-input
                    v-if="template.isEmail"
                    ref="subject-input"
                    class="mb-4"
                    label="Subject"
                    :value="template.meta.subject"
                    :errors="formErrors['meta.subject']"
                    :disabled="disabled"
                    @input="update('meta', {subject: $event})"
                />
                <div class="mb-4">
                    <div class="grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-2">
                        <v-radio-button
                            v-for="layout in layoutTypes"
                            :key="layout.value"
                            :ref="`${layout.value}-layout`"
                            class="flex-1 mx-1"
                            :label="layout.label"
                            :value="layout.value"
                            :checked="template.layout_type"
                            :disabled="disabled"
                            @change="update('layout_type', $event)"
                        />
                        <p
                            v-for="(error, index) in formErrors.layout_type"
                            :key="index"
                            class="text-red-400 text-xs mb-2"
                        >
                            {{ error }}
                        </p>
                    </div>
                </div>
                <v-typeahead
                    ref="content"
                    label="Your message"
                    type="textarea"
                    :options="tags"
                    :rows="10"
                    :value="template.content"
                    :errors="formErrors.content"
                    :disabled="disabled"
                    @input="update('content', $event)"
                >
                    <template #action-bar>
                        <action-bar
                            :tags="typeAheadObjectToDrilldownArray(tags)"
                            :is-email="template.isEmail"
                            :font="template.font"
                            @clicked-item="insertTag"
                            @font-select="updateFont"
                        >
                            <template #left>
                                <v-button
                                    v-if="showPreview"
                                    ref="preview-button"
                                    v-tooltip="'Preview Template'"
                                    class="mr-2"
                                    label="Preview"
                                    @click="openPreviewDialog"
                                >
                                    <template #before-label>
                                        <i class="fa-solid fa-eye mr-2"></i>
                                    </template>
                                </v-button>
                            </template>
                        </action-bar>
                    </template>
                </v-typeahead>
                <doodle-select
                    v-if="!template.isEmail"
                    ref="doodle-select-bottom"
                    class="mt-6"
                    label="Choose bottom doodle..."
                    :value="template.doodles && template.doodles.find(doodle => doodle.position === 'bottom')"
                    @selected="updateDoodle($event, 'bottom')"
                />
                <v-input
                    v-model="template.qr_code_url"
                    :disabled="disabled"
                    class="mt-6"
                    label="qr code url"
                />
                <div v-if="showScope" class="pt-4 mt-4 border-t border-gray-200">
                    <v-select
                        ref="scope"
                        v-model="template.scope"
                        label="Action"
                        :options="scopeOptions"
                        class="w-1/3"
                    />
                </div>

            </template>
        </v-panel>

        <v-panel v-if="!template.isEmail" title="Cover Image">
            <image-select
                class="flex-1"
                :asset="template.cover"
                :account-id="user.account_id"
                @select="updateCover"
            />
        </v-panel>
    </div>
</template>

<script>
import VRadioButton from '@outreach/components/inputs/VRadioButton';
import VTypeahead from '@outreach/components/VTypeahead';
import DoodleSelect from '@outreach/components/campaign/DoodleSelect';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import { Layout, LayoutLabels } from '@services/Layout';
import RestoreButton from '@outreach/components/shared/RestoreButton';
import { Template } from '@models/Template';
import VSelect from '@outreach/components/inputs/VSelect';
import VPanel from '@outreach/components/shared/VPanel';
import DuplicateTemplateButton from '@outreach/pages/Templates/DuplicateTemplateButton';
import ImageSelect from '@outreach/components/shared/image-library/ImageSelect';
import { mapState } from 'vuex';
import { get } from 'lodash';
import ActionBar from '@outreach/pages/Templates/ActionBar.vue';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { TypeaheadOptions } from '@services/TypeaheadOptions';

export default {
    components: {
        ActionBar,
        DuplicateTemplateButton,
        VPanel,
        RestoreButton,
        VRadioButton,
        VTypeahead,
        DoodleSelect,
        VButton,
        VInput,
        VSelect,
        ImageSelect
    },
    props: {
        label: { type: String, required: true },
        template: { type: Template, default: () => new Template() },
        formErrors: { type: Object, default: () => ({}) },
        disabled: { type: Boolean, default: false }
    },
    data: () => ({
        showAccountModal: false,
        scopeOptions: [{ label: '-- None --', value: '' }, { label: 'Contact Email', value: 'outreach' }],
        options: new TypeaheadOptions()
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        showPreview () {
            return ![Layout.POST_IT, Layout.EMAIL].includes(this.template.layout_type);
        },
        layoutTypes () {
            return Object.entries(LayoutLabels).map(([value, label]) => ({ label, value }));
        },
        showScope () {
            return this.template.id && this.template.isEmail;
        },
        tags () {
            return this.options.merge({
                contact: [...(get(this.user, 'account.custom_attributes', []) || [])]
            });
        }
    },
    methods: {
        typeAheadObjectToDrilldownArray (obj) {
            return this.options.objectToDrilldownArray(obj, '');
        },
        insertTag ({ path }) {
            const input = this.$refs.content.$refs.input;
            input.insertAtSelectionStart(`@${path}`);
        },
        update (name, value) {
            const data = new Template({
                ...this.template,
                [name]: value
            });
            this.$emit('update:template', data);
        },
        updateFont (font) {
            this.$emit('update:template', new Template({ ...this.template, font, font_id: font.id }));
        },
        updateDoodle (doodle, position) {
            const updated = this.template.doodles.filter(current => current.position !== position)
                .concat([{ ...doodle, ...{ position } }]);
            this.update('doodles', updated);
        },
        updateCover (image) {
            this.$emit('update:template', new Template({
                ...this.template,
                cover_id: image.id,
                cover: image
            }));
        },
        async openPreviewDialog () {
            await dialogEmitter
                .name('TemplatePreviewDialog')
                .props({ accountId: this.user.account.id, template: this.template, photoPath: '' })
                .wait();
        }
    }
};
</script>
