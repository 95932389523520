<template>
    <div class="container mx-auto p-8">

        <search-header>
            <template #content>
                <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1" label="Search" />
                <router-link :to="{ name: 'add-template'}">
                    <v-button ref="add-template-button" class="w-full" label="Add template" button-style="primary">
                        <template #before-label>
                            <i class="fa fa-plus mr-2"></i>
                        </template>
                    </v-button>
                </router-link>
            </template>
        </search-header>

        <v-loader v-if="loading" />
        <div v-if="rows.length > 0">
            <div class="flex items-center justify-center py-4 px-2">
                <v-paginator
                    v-if="meta"
                    class="flex-none"
                    :total="meta.last_page"
                    :value="meta.current_page"
                    @input="changePage"
                />
            </div>
            <v-panel title="Edit Templates" subtitle="Click and drag to reorder templates">
                <draggable
                    :list="rows"
                    draggable=".item"
                    ghost-class="ghost"
                    class="divide-y divide-gray-300"
                    @change="onChange"
                >
                    <section v-for="row in rows" :key="row.id" class="item">
                        <div
                            class="flex flex-row flex-wrap justify-between w-full gap-4 py-4"
                        >
                            <i class="fa fa-sort mt-2 text-gray-700 cursor-pointer"></i>
                            <dl class="flex-1">
                                <dt class="text-lg">{{ row.name }}</dt>
                                <dd class="text-gray-700">{{ row.description }}</dd>
                                <dd class="font-normal text-sm text-gray-700">{{ row.content }}</dd>
                            </dl>
                            <div>
                                <router-link
                                    v-if="!row.admin_provided || isCustomerService()"
                                    :to="{ name: 'edit-template', params: { template: row.id }} "
                                >
                                    <v-button
                                        :ref="`edit-template-button-${row.id}`"
                                        class="my-1"
                                        button-style="default"
                                        :outlined="true"
                                        label="Edit"
                                    >
                                        <template #before-label>
                                            <i class="fa fa-edit mr-2"></i>
                                        </template>
                                    </v-button>
                                </router-link>
                                <div v-if="row.admin_provided">
                                    <duplicate-template-button
                                        v-if="row.admin_provided"
                                        :ref="`duplicate-template-button-${row.id}`"
                                        :template="row"
                                        label="Duplicate"
                                        confirm="Do you want to duplicate this template?"
                                    />
                                </div>
                                <div v-else class="flex sm:flex-col">
                                    <v-button
                                        :ref="`delete-template-button-${row.id}`"
                                        class="my-1 ml-4 sm:ml-0"
                                        button-style="error"
                                        label="Delete"
                                        confirm="Are you sure you want to delete this template?"
                                        @click="deleteTemplate(row)"
                                    >
                                        <template #before-label>
                                            <i class="fa fa-trash mr-2"></i>
                                        </template>
                                    </v-button>
                                </div>
                                <div class="flex justify-center mt-3">
                                    <div class="layout-tag">
                                        {{ layoutLabels[row.layout_type] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </draggable>
            </v-panel>
            <div class="flex items-center justify-center py-4 px-2">
                <v-paginator
                    v-if="meta"
                    class="flex-none"
                    :total="meta.last_page"
                    :value="meta.current_page"
                    @input="changePage"
                />
            </div>
        </div>
        <div v-else class="text-center">
            <p class="text-lg text-gray-700">No templates found.</p>
        </div>
    </div>
</template>

<script>
import VPaginator from '@outreach/components/VPaginator';
import { mapActions, mapGetters, mapState } from 'vuex';
import VLoader from '@outreach/components/VLoader';
import draggable from 'vuedraggable';
import VPanel from '@outreach/components/shared/VPanel';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import { LayoutLabels } from '@services/Layout';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import DuplicateTemplateButton from '@outreach/pages/Templates/DuplicateTemplateButton';
import { hasPermission } from '@outreach/mixins/userHasPermission';

export default {
    components: {
        DuplicateTemplateButton,
        VPaginator,
        VLoader,
        VPanel,
        draggable,
        VButton,
        VInput,
        SearchHeader
    },
    mixins: [hasPermission],
    data: () => ({
        query: undefined
    }),
    computed: {
        ...mapGetters({
            rows: 'userTemplates/list',
            meta: 'userTemplates/pagination',
            loading: 'loader/isLoading'
        }),
        ...mapState({
            user: ({ user }) => user.user
        }),
        layoutLabels: () => LayoutLabels
    },
    watch: {
        query (q) {
            this.list({ q });
        }
    },
    created () {
        this.list();
    },
    methods: {
        ...mapActions({
            list: 'userTemplates/index',
            delete: 'userTemplates/delete',
            sort: 'userTemplates/sort'
        }),
        changePage (page) {
            this.list({ q: this.query, page });
        },
        async deleteTemplate (template) {
            try {
                await this.delete(template.id);
            } catch (error) {
                this.$toasted.error('The template could not be deleted.');
            }
        },
        async onChange () {
            await this.sort(this.rows.map((template, index) => ({ ...template, order: index })));
            await this.list({ q: this.query });
        }
    }

};
</script>

<style scoped>
.layout-tag {
    @apply inline-flex grow-0 items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800;
}
</style>
