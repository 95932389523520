"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawType = exports.ControlMode = void 0;
var ControlMode;
(function (ControlMode) {
    ControlMode["DRAW"] = "draw";
    ControlMode["MOVE"] = "move";
})(ControlMode = exports.ControlMode || (exports.ControlMode = {}));
var DrawType;
(function (DrawType) {
    DrawType["FREE_DRAW"] = "free-draw";
    DrawType["POINT_DRAW"] = "draw";
})(DrawType = exports.DrawType || (exports.DrawType = {}));
