import ApiClient from '@services/Http/Api';

export const list = (campaignId, params = {}) => {
    if (!params.includes) {
        params.includes = ['contact.address'];
    }
    return ApiClient.get(`/dashboard/campaigns/${campaignId}/deliveries`, { params });
};

export const update = (campaignId, deliveryId, delivery) => {
    if (delivery.photo_path === null) {
        delete delivery.photo_path;
    }
    return ApiClient.patch(`/dashboard/campaigns/${campaignId}/deliveries/${deliveryId}`, delivery);
};

export const deleteDelivery = (deliveryId) => {
    return ApiClient.destroy(`/dashboard/campaigns/deliveries/${deliveryId}`);
};

export const updateAll = (campaignId, delivery) => {
    return ApiClient.patch(`/dashboard/campaigns/${campaignId}/deliveries/all`, delivery);
};

export const updatePhoto = async (campaignId, deliveryId, photo) => {
    const data = {
        _method: 'patch',
        photo,
        photo_name: photo.name
    };
    return ApiClient.upload(`/dashboard/campaigns/${campaignId}/deliveries/${deliveryId}`, data);
};

export const updatePhotoAll = (campaignId, photo) => {
    const formData = new FormData();
    formData.append('_method', 'patch');
    formData.append('photo', photo);
    formData.append('photo_name', photo.name);
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
    };
    return ApiClient.post(`/dashboard/campaigns/${campaignId}/deliveries/all`, formData, config);
};
