"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CsvMapper = void 0;
class CsvMapper {
    constructor(fields = {}) {
        this._mappings = {};
        this._fields = { required: [], available: [], aliases: {} };
        this._fields = Object.assign(Object.assign({}, this._fields), fields);
    }
    get fields() {
        if (!this.availableFields) {
            this.availableFields = [...this._fields.available, ...this._fields.required, ...this.getAliases()].sort();
        }
        return this.availableFields;
    }
    get mappings() {
        return this._mappings;
    }
    updateMappings(field, csvField) {
        const mapping = {};
        Object.entries(this._mappings)
            .filter(([, value]) => value !== csvField)
            .forEach(([key, value]) => {
            mapping[key] = value;
        });
        if (field !== '') {
            mapping[field] = csvField;
        }
        this._mappings = mapping;
    }
    get invalidFields() {
        return this.fields
            .filter((field) => !this.hasMapping(field))
            .filter((field) => this.hasRequirement(field))
            .filter((field) => !this.hasMappedAlias(field))
            .filter((field) => !this.hasRequiredAlias(field));
    }
    hasInvalidFields() {
        return this.invalidFields.length > 0;
    }
    hasMappableFields() {
        return this.fields.length > 0;
    }
    getField(field) {
        return this._mappings[field];
    }
    hasRequiredAlias(field) {
        return this.getAliasesOf(field).length > 0 && this.allFieldsMapped(this.getAliasesOf(field));
    }
    hasMappedAlias(field) {
        return this.getAliases().includes(field) && this.allFieldsMapped(this._fields.aliases[field]);
    }
    allFieldsMapped(fields) {
        return fields.every((dependentField) => this.hasMapping(dependentField));
    }
    getAliases() {
        if (!this.aliases) {
            this.aliases = Object.keys(this._fields.aliases);
        }
        return this.aliases;
    }
    getAliasesOf(field) {
        return this.getAliases().filter((key) => this._fields.aliases[key].includes(field));
    }
    hasMapping(key) {
        return Object.keys(this.mappings).includes(key);
    }
    hasRequirement(field) {
        return this._fields.required.includes(field) || this.getAliases().includes(field);
    }
}
exports.CsvMapper = CsvMapper;
