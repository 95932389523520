<template>
    <div class="flex flex-col flex-1">
        <steps :steps="['Contacts', 'Compose', 'Review']" value="Contacts" />
        <div class="w-full h-full wrapper mx-auto container pt-6 md:p-6">
            <import-contacts-modal
                :show-modal="showImportModal"
                @close-modal="showImportModal = false"
                @csv-imported="csvImported"
            />
            <search-header>
                <template #mobile-header>
                    <div class="flex flex-row w-full items-center justify-between">
                        <h3 class="text-xl text-gray-800 font-semibold">Choose Contacts</h3>
                        <span class="text-gray-700 ml-3">
                            {{ allSelected ? meta.total : selected.size }} selected
                        </span>
                    </div>
                </template>
                <template #content>
                    <div class="grid w-full md:gap-2 lg:grid-cols-2">
                        <div class="hidden md:flex md:flex-row md:w-full md:items-center md:justify-start">
                            <h3 class="text-xl text-gray-800 font-semibold">Choose Contacts</h3>
                            <span class="text-gray-700 ml-3">
                                {{ allSelected ? meta.total : selected.size }} selected
                            </span>
                        </div>
                        <div class="flex justify-around my-2 md:my-0 md:items-center">
                            <label>
                                <input ref="select-all-checkbox" v-model="allSelected" type="checkbox" />
                                <span class="pl-2">Select All</span>
                            </label>
                        </div>
                        <div
                            class="flex flex-col mb-6 mt-2 md:w-full md:flex-row md:flex-wrap md:my-0"
                        >
                            <v-input
                                v-model="query"
                                class="mb-2 flex-1 sm:mb-0 sm:mr-2"
                                label="Search"
                                :errors="formErrors.q"
                            />
                            <tag-select
                                v-model="tags"
                                placeholder="Filter By Tag"
                                :taggable="false"
                                :type="tagType"
                                @input="load(1, true)"
                            />
                        </div>
                        <div class="flex flex-col justify-center items-stretch md:flex-row md:flex-wrap md:gap-2">
                            <v-button
                                class="md:ml-2 mb-2 md:mb-0"
                                label="Import..."
                                @click="showImportModal = true"
                            />
                            <button-modal class="md:ml-2 mb-2 md:mb-0" label="Add contact" header="Add contact">
                                <template #content>
                                    <contact-form
                                        style="width: 64rem;"
                                        :contact.sync="contact"
                                        :form-errors="formErrors"
                                        :audience-id="audienceId"
                                    />
                                </template>
                                <template #actions="{ close }">
                                    <div class="flex justify-end p-2">
                                        <v-button
                                            class="mr-2"
                                            label="Cancel"
                                            @click="close"
                                        />
                                        <div class="flex items-center justify-end mr-10">
                                            <v-button
                                                label="Save Contact"
                                                button-style="primary"
                                                :loading="isSaving"
                                                @click="handleSave(close)"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </button-modal>
                            <v-button
                                class="ml-0 sm:ml-2"
                                label="Next"
                                button-style="primary"
                                :disabled="!selected.size && !allSelected"
                                @click="sendSelected"
                            />
                        </div>
                    </div>
                </template>
            </search-header>
            <div
                v-if="loading || !contacts"
                class="flex items-center justify-center flex-1 px-2 pt-4 md-pt-0"
            >
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-sm text-gray-700 px-2 text-center">Loading contact data...</span>
            </div>
            <div class="h-full md:h-auto">
                <transition name="fade" mode="out-in">
                    <contacts-virtual-list
                        v-if="contacts && contacts.length > 0"
                        :contacts="contacts"
                        :meta="meta"
                        :sort="sort"
                        :selected="selected"
                        :all-selected="allSelected"
                        @click="select"
                        @page-changed="handlePage"
                        @selection-changed="handleSelection"
                        @sort-changed="handleSort"
                        @page-end="handleNextPage"
                    />
                    <div v-else-if="(contacts && contacts.length === 0) && !loading" class="text-center">
                        <p class="text-lg text-gray-700">Contact not found.</p>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ButtonModal from '@outreach/components/shared/modal/ButtonModal';
import contacts from '@outreach/mixins/contacts';
import services from '@services/Api';
import ContactForm from '@outreach/pages/ContactForm';
import Steps from '@outreach/pages/Steps';
import TagSelect from '@outreach/components/tag-select/TagSelect';
import ImportContactsModal from '@outreach/components/contacts/ImportContactsModal';
import ContactsVirtualList from '@outreach/components/contacts/ContactsVirtualList';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import CsvWasImported from '@events/CsvWasImported';
import { Alert } from '@services/Alert';
import { TagType } from '@services/TagType';

export default {
    components: {
        SearchHeader,
        ImportContactsModal,
        ContactForm,
        ContactsVirtualList,
        ButtonModal,
        Steps,
        TagSelect,
        VButton,
        VInput,
        VLoader
    },
    mixins: [contacts],
    data: () => ({
        contact: { address: {} },
        formErrors: {},
        isSaving: false,
        showImportModal: false,
        allSelected: false,
        expanded: false
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        tagType () {
            return TagType.CONTACTS;
        },
        accountId () {
            return this.$route.params.accountId || this.user.account_id;
        },
        audienceId () {
            if (this.$route.params.audience) {
                return parseInt(this.$route.params.audience);
            }
            if (this.contact && this.contact.audience_id) {
                return parseInt(this.contact.audience_id);
            }
            return undefined;
        }
    },
    watch: {
        formErrors () {
            if (this.formErrors.hasOwnProperty('availability')) {
                this.setMessage({ messageType: 'error', message: this.formErrors.availability });
            }
        }
    },
    async created () {
        await this.subscribe({
            event: CsvWasImported,
            subscription: () => this.load()
        });
    },
    destroy () {
        this.unsubscribe({ event: CsvWasImported });
    },
    methods: {
        ...mapActions({
            setMessage: 'alerts/setMessage',
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        sendSelected () {
            this.send([...this.selected]);
        },
        async send (ids) {
            try {
                const response = await services.campaigns.create({
                    ids: this.allSelected ? ['all'] : ids,
                    filters: {
                        q: this.query,
                        tags: this.tags,
                        sort_key: this.sort.key,
                        sort_direction: this.sort.direction
                    }
                });
                this.$router.push({ name: 'campaign-compose', params: { campaign: response.data.data.id } });
            } catch (error) {
                this.handleErrors(error.response);
                this.$toasted.error(
                    'Sorry we were unable to send mail to the selected contacts. Please refresh the page and try again.'
                );
            }
        },
        async handleSave (close) {
            this.isSaving = true;
            try {
                await services.contacts.create(this.accountId, this.contact);
                close();
            } catch (error) {
                this.handleErrors(error.response);
            }
            this.isSaving = false;
        },
        handleErrors (response) {
            if (response.data && response.data.errors) {
                this.formErrors = response.data.errors;
            }
        },
        csvImported () {
            this.showImportModal = false;
            Alert.info(
                'Your contacts are being imported, we\'ll notify you when they\'re ready! ' +
                'This might take a few minutes.'
            );
        }
    }
};
</script>

<style scoped>
@media (min-width: 768px) {
    .wrapper {
        display: grid;
        grid-template-rows: auto 1fr;
    }
}

.contacts-search {
    @apply flex flex-col sm:flex-row items-center items-stretch lg:mb-0 mb-6 sm:mb-2 px-6 sm:px-0;
}

.section-header {
    @apply flex flex-col p-6 bg-white rounded-sm shadow-sm fixed bottom-0 left-0 w-full border-t-2 z-20 md:relative md:mb-4;
}
</style>
