<template>
    <search-header>
        <template #mobile-header>
            <div class="flex flex-row w-full items-center justify-between">
                <h3 class="text-xl text-gray-800 font-semibold">{{ total }} Contacts</h3>
                <span class="text-gray-700 mr-2">
                    {{ allSelected ? total : selectedCount }} selected
                </span>
            </div>
        </template>
        <template #content>
            <div class="grid w-full md:gap-2">
                <div class="hidden md:flex md:flex-row md:w-full md:items-center md:justify-start">
                    <h3 class="text-xl text-gray-800 font-semibold">{{ total }} Contacts</h3>
                    <span class="text-gray-700 ml-3">
                        {{ allSelected ? total : selectedCount }} selected
                    </span>
                </div>
                <div class="flex gap-6 lg:gap-10 my-2 md:my-0 md:items-center col-span-2">
                    <label>
                        <input
                            ref="select-all-checkbox"
                            :disabled="!total"
                            :value="allSelected"
                            type="checkbox"
                            @input="$emit('update:all-selected', $event.target.checked)"
                        />
                        <span class="pl-2">Select All</span>
                    </label>
                    <label v-if="allowDeletedCheckbox">
                        <input
                            ref="show-deleted-checkbox"
                            :value="isShowingArchived"
                            type="checkbox"
                            @input="$emit('update:is-showing-archived', $event.target.checked)"
                        />
                        <span class="pl-2">Show Deleted</span>
                    </label>
                </div>
                <div class="flex flex-col w-full col-span-2">
                    <div class="flex flex-col md:flex-row flex-1">
                        <div class="query-tag-filters">
                            <v-input
                                :value="query"
                                class="flex-1 shrink-0"
                                label="Search"
                                :errors="queryErrors"
                                @input="$emit('update:query', $event)"
                            />
                            <tag-select
                                :value="tags"
                                class="flex-1 shrink-0"
                                placeholder="Filter By Tag"
                                :taggable="false"
                                :type="tagType"
                                @input="$emit('update:tags', $event)"
                            />
                            <v-multiple-select
                                class="flex-1 shrink-0"
                                :options="campaigns"
                                :value="selectedCampaign"
                                :multiple="false"
                                track-by="id"
                                placeholder="Campaign Name"
                                @search-change="$emit('campaign-search-change', $event)"
                                @input="$emit('update:selected-campaign', $event)"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col md:gap-2 flex-1">
                        <div class="flex flex-col mt-2 w-full sm:flex-row sm:flex-wrap gap-2">
                            <v-text-dropdown
                                class="mb-2 md:mb-0"
                                label="Actions"
                                :disabled="bulkDropdownDisabled"
                            >
                                <template #content>
                                    <button
                                        class="dropdown-item"
                                        @click="$emit('delete')"
                                    >Delete</button>
                                    <button
                                        v-if="allowExtraActions"
                                        class="dropdown-item"
                                        @click="$emit('send')"
                                    >Send Mail</button>
                                    <button
                                        ref="add-tags-button"
                                        class="dropdown-item"
                                        @click="$emit('add-tags')"
                                    >Add Tags</button>
                                    <button
                                        v-if="isShowingArchived"
                                        ref="restore-many"
                                        class="dropdown-item"
                                        @click="$emit('restore')"
                                    >Restore</button>
                                </template>
                            </v-text-dropdown>
                            <template v-if="allowExtraActions">
                                <v-button
                                    v-if="allowExportButton"
                                    ref="export-all"
                                    label="Export All"
                                    @click="$emit('export')"
                                />
                                <v-button
                                    label="Import..."
                                    @click="$emit('import')"
                                />
                            </template>
                            <div class="md:te flex flex-col lg:flex-row lg:m-0 gap-2">
                                <v-button
                                    v-if="showFilters"
                                    :label="`Filters${filterCount ? ` (${filterCount})` : ''}`"
                                    button-style="info"
                                    @click="$emit('open-filters')"
                                />
                                <v-button
                                    label="Add Contact"
                                    button-style="primary"
                                    @click="$emit('add-contact')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </search-header>
</template>

<script>
import VTextDropdown from '@outreach/components/shared/dropdown/VTextDropdown';
import TagSelect from '@outreach/components/tag-select/TagSelect';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import VMultipleSelect from '@outreach/components/inputs/VMultipleSelect';
import { TagType } from '@services/TagType';

export default {
    components: {
        SearchHeader,
        TagSelect,
        VTextDropdown,
        VButton,
        VInput,
        VMultipleSelect
    },
    props: {
        total: { type: Number, default: 0 },
        allSelected: { type: Boolean, required: true },
        selectedCount: { type: Number, required: true },
        isShowingArchived: { type: Boolean, required: true },
        allowDeletedCheckbox: { type: Boolean, required: true },
        query: { type: String, default: null },
        queryErrors: { type: [Array, String], required: false, default: () => [] },
        tags: { type: Array, required: true },
        campaigns: { type: Array, required: true },
        selectedCampaign: { type: Object, default: undefined },
        allowExtraActions: { type: Boolean, required: true },
        showFilters: { type: Boolean, required: true },
        filterCount: { type: Number, default: undefined },
        allowExportButton: { type: Boolean, required: true }
    },
    computed: {
        bulkDropdownDisabled () {
            return (!this.allSelected && !this.selectedCount) || !this.total;
        },
        tagType () {
            return TagType.CONTACTS;
        }
    }
};
</script>

<style scoped>
.query-tag-filters {
    @apply flex flex-col w-full lg:flex-row gap-2;
}
.dropdown-item {
    @apply py-1 px-3 font-normal text-left hover:bg-gray-100;
}
</style>
