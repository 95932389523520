<template>
    <campaign-dashboard
        ref="campaign-dashboard"
        :loading="loading"
        :contact="contact"
        :contacts="contacts"
        :form-errors="formErrors"
        @contact-selected="(selected) => this.contact = selected"
        @send-one="sendToOne"
        @search="onSearch"
        @send-many="sendToMany"
    />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import services from '@services/Api';
import CampaignDashboard from '@outreach/pages/CampaignDashboard/CampaignDashboard';
import { debounce } from 'lodash';
import get from 'lodash/get';
import ClientVisibleError from '@exceptions/ClientVisibleError';

export default {
    components: { CampaignDashboard },
    data: () => ({
        formErrors: {},
        contact: {
            name: null,
            email: null,
            address: {
                address1: null,
                address2: null,
                city: null,
                state: null,
                country: null,
                postcode: null
            }
        },
        contacts: [],
        isOpen: false,
        selectionIndex: 0
    }),
    computed: mapState({
        user: ({ user }) => user.user,
        loading: ({ loader }) => loader.loading
    }),
    async created () {
        const contactId = get(this.$route, 'query.contact_id');
        if (contactId) {
            const { data } = await services.contacts.fetch(contactId);
            this.contact = data.data;
        }
    },
    methods: {
        ...mapActions({
            setMessage: 'alerts/setMessage'
        }),
        onSearch (newVal) {
            this.clearContacts();
            this.debounceFindContact(newVal);
        },
        clearContacts () {
            this.contacts = [];
        },
        debounceFindContact: debounce(function (q) {
            this.findContact(q);
        }, 250),
        sendToMany () {
            this.$router.push({ name: 'campaign-contacts' });
        },
        async sendToOne () {
            try {
                if (!this.contact.id) {
                    const { data } = await services.contacts.create(this.user.account_id, this.contact);
                    this.contact = data.data;
                }
                const response = await services.campaigns.create({
                    ids: [this.contact.id]
                });
                this.$router.push({
                    name: 'campaign-compose',
                    params: { campaign: response.data.data.id }
                });
            } catch (error) {
                if (error.response && error.response.data && error.response.data.errors) {
                    this.formErrors = error.response.data.errors;
                    if (this.formErrors.hasOwnProperty('availability')) {
                        this.setMessage({ messageType: 'error', message: this.formErrors.availability });
                    }
                }
                throw new ClientVisibleError('Could not send to contact.');
            }
        },
        async findContact (q) {
            const { data } = await services.contacts.list(this.user.account_id, { q });
            this.contacts = data.data;
        }
    }
};
</script>
