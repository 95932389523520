"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutLabels = exports.Layout = void 0;
var Layout;
(function (Layout) {
    Layout["NOTE"] = "note";
    Layout["POSTCARD"] = "postcard";
    Layout["HALF_NOTE"] = "half-note";
    Layout["POST_IT"] = "post-it";
    Layout["EMAIL"] = "email";
})(Layout = exports.Layout || (exports.Layout = {}));
exports.LayoutLabels = {
    [Layout.NOTE]: 'Note',
    [Layout.HALF_NOTE]: 'Half-note',
    [Layout.POST_IT]: 'Post-it',
    [Layout.EMAIL]: 'Email'
};
