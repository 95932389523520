<template>
    <div>
        <transition name="fade" mode="out-in">
            <div v-if="isLoading" key="isLoading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading order history data</span>
            </div>
            <div v-else>
                <div v-if="orders.length > 0">
                    <v-table
                        is-elevated
                        :rows="orders"
                        :sort="sorting"
                        @sort-changed="updateSort"
                    >
                        <template #default="{ row }">
                            <v-table-column label="Order placed" value="created_at" sortable>
                                <span>{{ formatDate(row.created_at) }}</span>
                                <span class="text-sm text-gray-700">{{ fromNow(row.created_at) }}</span>
                            </v-table-column>
                            <v-table-column label="Description" value="description" sortable>
                                <span>{{ row.description }}</span>
                            </v-table-column>
                            <v-table-column label="Total" value="amount" sortable>
                                <span>{{ centsToDollars(row.amount) }}</span>
                                <span class="text-sm text-gray-700">{{ row.currency }}</span>
                            </v-table-column>
                            <v-table-column label="Order #" value="id" sortable>
                                <span>{{ row.id }}</span>
                            </v-table-column>
                        </template>
                    </v-table>
                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getOrders"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No order history found.</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import services from '@services/Api';
import VPaginator from '@outreach/components/VPaginator';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import { centsToDollars, formatDate, fromNow } from '@services/Filters';
import VLoader from '@outreach/components/VLoader';

export default {
    components: {
        VPaginator,
        VTable,
        VTableColumn,
        VLoader
    },
    data: () => ({
        isLoading: true,
        meta: {},
        orders: [],
        sorting: {
            key: 'created_at',
            direction: 'DESC'
        }
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    watch: {
        async sorting () {
            this.getOrders();
        }
    },
    async created () {
        this.getOrders();
    },
    methods: {
        formatDate,
        fromNow,
        centsToDollars,
        async getOrders (page = 1) {
            this.isLoading = true;
            try {
                const { data } = await services.orders.list(
                    this.user.account_id,
                    { page, sort_key: this.sorting.key, sort_direction: this.sorting.direction }
                );
                this.orders = data.data;
                this.meta = data.meta;
            } catch (error) {
                console.error(error);
            }
            this.isLoading = false;
        },
        getDownloadUrl (orderId) {
            return `/dashboard/accounts/${this.user.account_id}/orders/${orderId}/download`;
        },
        updateSort (data) {
            this.sorting = data;
        }
    }
};
</script>
