<template>
    <modal header="Add a Service" :open="true" @close-modal="$emit('close-modal')">
        <template slot="content">
            <div class="w-modal">
                <div class="mb-6">
                    <img
                        class="h-12 mb-5"
                        :src="`/svg/services/${this.service.name.toLowerCase()}-logo.svg`"
                        :alt="service.name"
                    />
                    <p class="text-lg text-gray-700">
                        Please provide connection details for your {{ service.name }} service.
                        If you need help locating these values a tutorial can be
                        <router-link :to="{ name: 'shopify-setup' }" class="text-brand-regular">
                            found here
                        </router-link>.
                    </p>
                </div>
                <v-input v-model="integration.name" class="mb-4" label="Name" :errors="formErrors.name" />

                <div>
                    <div class="flex mb-4">
                        <v-input class="w-16 -mr-2" label="" value="https://" readonly />
                        <v-input
                            v-model="integration.api_domain"
                            class="flex-1"
                            label="API Domain"
                            :errors="formErrors.api_domain"
                        />
                        <v-input class="flex-1 -ml-2" label="" value=".myshopify.com" readonly />
                    </div>
                    <div class="flex mb-4">
                        <v-input
                            v-model="integration.api_key"
                            class="flex-1 mr-4"
                            label="API Key"
                            :errors="formErrors.api_key"
                        />
                        <v-input
                            v-model="integration.api_secret"
                            class="flex-1"
                            label="API Password"
                            :errors="formErrors.api_secret"
                        />
                    </div>
                </div>

                <div class="mb-6">
                    <p
                        v-for="(error, index) in formErrors.credentials"
                        :key="index"
                        class="text-red-400 text-xs mb-2"
                    >{{ error }}</p>
                </div>
            </div>
        </template>

        <template slot="modal-actions">
            <div class="p-2">
                <v-button
                    label="cancel"
                    :disabled="loading"
                    class="mr-2"
                    @click="$emit('close-modal')"
                />
                <v-button
                    label="Save"
                    button-style="primary"
                    :disabled="loading"
                    :loading="loading"
                    @click="$emit('submitted', integration)"
                />
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@outreach/components/shared/modal/index';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    name: 'AddIntegration',
    components: { Modal, VButton, VInput },
    props: {
        service: { type: Object, required: true },
        details: { type: Object, default: () => ({}) },
        loading: { type: Boolean, default: false },
        formErrors: { type: Object, default: () => ({}) }
    },
    data () {
        return {
            integration: {}
        };
    },
    watch: {
        details () {
            this.integration = this.details;
        }
    },
    created () {
        this.integration = this.details;
    }
};
</script>
