<template>
    <div class="container mx-auto p-8">
        <search-header>
            <template #content>
                <v-input
                    ref="search-associates"
                    v-model="q"
                    class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1"
                    label="Search"
                />
                <v-button label="Add Associate" button-style="primary" @click="openModify()" />
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" key="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading Associates</span>
            </div>
            <div v-else>
                <paginator-wrap
                    v-if="associates.length > 0"
                    :pagination="meta"
                    :change-page="changePage"
                >
                    <template #content>
                        <v-table
                            ref="associates-table"
                            is-elevated
                            has-actions
                            :rows="associates"
                            :sort="sorting"
                            @sort-changed="updateSort"
                        >
                            <template #default="{ row }">
                                <v-table-column label="Name" value="name">
                                    <div class="mt-2">
                                        <span><strong class="text-lg">{{ row.name }}</strong></span>
                                    </div>
                                </v-table-column>
                                <v-table-column label="Title" value="title">
                                    <div class="mt-2">
                                        <span>
                                            {{ row.title }}
                                        </span>
                                    </div>
                                </v-table-column>
                                <v-table-column label="Status" value="status" sortable>
                                    <span>{{ row.status }}</span>
                                </v-table-column>
                                <v-table-column label="Created Date" value="created_at" sortable>
                                    <span>{{ formatDate(row.created_at) }}</span>
                                    <span class="text-sm text-gray-700">{{ fromNow(row.created_at) }}</span>
                                </v-table-column>
                            </template>
                            <template #dropdown="{ row }">
                                <button ref="edit-associate-button" @click="openModify(row)">Edit</button>
                                <button ref="delete-associate-button" @click="destroyAssociate(row)">Delete</button>
                            </template>
                        </v-table>
                    </template>
                </paginator-wrap>
                <div v-else>
                    <p class="text-lg text-gray-700 text-center">No associates found.</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { VTable, VTableColumn } from '@outreach/components/shared/table';
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { formatDate, fromNow } from '@services/Filters';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import SearchHeader from '@outreach/components/shared/SearchHeader';

export default {
    name: 'Associates',
    components: { SearchHeader, PaginatorWrap, VTable, VTableColumn, VButton, VInput, VLoader },
    data: function () {
        return {
            q: '',
            sorting: {
                key: 'created_at',
                direction: 'DESC'
            }
        };
    },
    computed: {
        ...mapState({
            associates: ({ associates }) => associates.records,
            meta: ({ associates }) => associates.meta,
            loading: ({ loader }) => loader.loading
        })
    },
    watch: {
        q: debounce(function (q) {
            this.getAssociates({ q });
        }, 500)
    },
    async created () {
        await this.getAssociates();
    },
    methods: {
        ...mapActions({
            getAssociates: 'associates/list',
            deleteAssociate: 'associates/destroy'
        }),
        formatDate,
        fromNow,
        updateSort (data) {
            this.sorting = data;
        },
        changePage (page) {
            this.getAssociates({ q: this.q, page });
        },
        openModify (associate) {
            dialogEmitter.openDialog('ModifyAssociateDialog', { associate });
        },
        async destroyAssociate (associate) {
            if (!await dialogEmitter.confirm('Delete Associate', `Are you sure you want to delete ${associate.name}`)) {
                return;
            }
            await this.deleteAssociate(associate.id);
        }
    }
};
</script>
