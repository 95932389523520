import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import services from '@services/Api';

Vue.use(VueRouter);

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err;
        }
        return Promise.reject(err);
    });
};

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !services.authentication.getAccessToken()) {
        return next({
            name: 'login',
            query: { redirect: to.fullPath }
        });
    }
    if (to.name === 'login' && services.authentication.getAccessToken()) {
        return next({
            name: '/'
        });
    }

    return next();
});

export default router;
