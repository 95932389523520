<template>
    <div class="container mx-auto p-6 bg-white rounded-sm shadow-sm ">
        <image-library class="" :account-id="user.account_id" disable-select />
    </div>
</template>

<script>
import ImageLibrary from '@outreach/components/shared/image-library/ImageLibrary';
import { mapState } from 'vuex';

export default {
    name: 'Graphics',
    components: { ImageLibrary },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    }
};
</script>
