<template>
    <div class="container mx-auto">
        <transition name="fade" mode="out-in">
            <div v-if="loading" key="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading campaigns</span>
            </div>
            <div v-else>
                <search-header>
                    <template #mobile-header>
                        <div class="flex items-center">
                            <h3 class="text-xl text-gray-800 font-semibold">{{ meta.total }} Campaigns</h3>
                            <span class="text-gray-700 ml-3">{{ selected.size }} selected</span>
                        </div>
                    </template>
                    <template #content>
                        <div class="flex flex-col w-full">
                            <div class="hidden md:flex md:items-center">
                                <h3 class="text-xl text-gray-800 font-semibold">{{ meta.total }} Campaigns</h3>
                                <span class="text-gray-700 ml-3">{{ selected.size }} selected</span>
                            </div>
                            <div class="flex flex-col w-full justify-between mt-4 md:flex-row">
                                <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1" label="Search" />
                                <div class="flex flex-col md:flex-row items-center items-stretch justify-end">
                                    <v-text-dropdown
                                        v-if="status.includes('pending')"
                                        class="md:ml-2 mb-2 md:mb-0"
                                        label="Actions"
                                        :disabled="!allSelected && !selected.size"
                                    >
                                        <template #content>
                                            <button @click="deleteSelected">Delete</button>
                                        </template>
                                    </v-text-dropdown>
                                    <v-button
                                        class="ml-0 sm:ml-2"
                                        label="Add Campaign"
                                        button-style="primary"
                                        @click="$router.push({ name: 'send-notes' })"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </search-header>
                <div v-if="campaigns.length">
                    <paginator-wrap :change-page="getCampaigns" :pagination="meta">
                        <template #content>
                            <v-table
                                is-elevated
                                has-actions
                                :has-selection="status.includes('pending')"
                                clickable
                                :rows="campaigns"
                                :sort="sorting"
                                :selected="selected"
                                @sort-changed="updateSort"
                                @clicked="goToCampaign($event.id)"
                                @selection-changed="handleSelection"
                            >
                                <template #default="{ row }">
                                    <v-table-column label="Name" value="name">
                                        <div class="flex flex-col md:flex-row gap-2 items-center">
                                            <img
                                                v-if="row.thumbnail"
                                                :src="row.thumbnail"
                                                :alt="row.name"
                                                class="w-32"
                                            />
                                            <div
                                                v-else
                                                v-tooltip="'No Available Photo'"
                                                class="shadow-photo"
                                            >
                                                <i :id="`shadow-photo-${row.id}`" class="fa fa-image text-6xl"></i>
                                            </div>
                                            <span>{{ row.name }}</span>
                                            <span v-if="row.deleted_at" class="text-xs text-gray-500">Archived</span>
                                        </div>
                                    </v-table-column>
                                    <v-table-column label="Status" value="status" sortable>
                                        <campaign-status :status="row.status" with-status />
                                    </v-table-column>
                                    <v-table-column
                                        v-if="status.includes('pending')"
                                        label="Created Date"
                                        value="created_at"
                                        sortable
                                    >
                                        <span>{{ formatDateTime(row.created_at, "MM/DD h:mm a") }}</span>
                                        <span class="text-sm text-gray-700">{{ fromNow(row.created_at) }}</span>
                                    </v-table-column>
                                    <v-table-column
                                        v-if="isSentOrSubmitted"
                                        label="Submitted Date"
                                        value="submitted_at"
                                        sortable
                                    >
                                        <span>{{ formatDateTime(row.submitted_at) }}</span><br />
                                        <span class="text-sm text-gray-700">{{ fromNow(row.submitted_at) }}</span>
                                    </v-table-column>
                                    <v-table-column
                                        v-if="isSentOrSubmitted"
                                        label="Send Date"
                                        value="send_at"
                                        sortable
                                    >
                                        <span>{{ formatDate(row.send_at) }}</span><br />
                                        <span class="text-sm text-gray-700">{{ fromNow(row.send_at) }}</span>
                                    </v-table-column>
                                    <v-table-column
                                        v-if="isSentOrSubmitted"
                                        label="Print Date"
                                        value="printed_at"
                                        sortable
                                    >
                                        <div v-if="row.printed_at">
                                            <span>{{ formatDate(row.printed_at, "MM/DD h:mm a") }}</span><br />
                                            <span class="text-sm text-gray-700">{{ fromNow(row.printed_at) }}</span>
                                        </div>
                                        <div v-else>
                                            <span>Pending</span>
                                        </div>
                                    </v-table-column>
                                </template>
                                <template #priority-buttons="{row}">
                                    <button
                                        v-if="isCustomerService()"
                                        :ref="`list-links-${row.id}`"
                                        @click="listLinks(row.id)"
                                    >Links</button>
                                    <router-link
                                        v-if="followUp"
                                        :to="{ name: 'campaign-contacts-index', params: { campaign: row.id }}"
                                    >
                                        <button :ref="`follow-up-button-${row.id}`">Follow Up</button>
                                    </router-link>
                                </template>
                                <template #dropdown="{ row }">
                                    <template v-if="!row.deleted_at && canArchive">
                                        <button @click="archive(row.id)">Archive</button>
                                    </template>
                                    <template v-else-if="canArchive">
                                        <button @click="restore(row.id)">Restore</button>
                                    </template>
                                </template>
                            </v-table>
                        </template>
                    </paginator-wrap>
                </div>
                <div v-else class="text-center">
                    <p class="text-lg text-gray-700">
                        It doesn't look like you have any campaigns yet. Create a new Audience to get started!
                    </p>
                    <v-button
                        button-style="info"
                        label="Add an Audience"
                        class="mt-2"
                        @click="$router.push({ name: 'send-notes' })"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import services from '@services/Api';
import CampaignStatus from '@outreach/components/campaign/CampaignStatus';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VTextDropdown from '@outreach/components/shared/dropdown/VTextDropdown';
import debounce from 'lodash/debounce';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import { formatDate, formatDateTime, fromNow } from '@services/Filters';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import SearchHeader from '@outreach/components/shared/SearchHeader';

export default {
    components: {
        SearchHeader,
        VTable,
        VTableColumn,
        CampaignStatus,
        VTextDropdown,
        PaginatorWrap,
        VButton,
        VInput,
        VLoader
    },
    mixins: [hasPermission],
    props: {
        status: { type: Array, default: () => ['pending'] },
        followUp: { type: Boolean, default: true }
    },
    data: () => ({
        loading: true,
        meta: {},
        campaigns: [],
        showArchived: false,
        sorting: {
            key: 'created_at',
            direction: 'DESC'
        },
        query: null,
        allSelected: false,
        selected: new Set(),
        expanded: false
    }),
    computed: {
        canArchive () {
            return this.status.includes('pending') || this.followUp;
        },
        isSentOrSubmitted () {
            return this.status.includes('sent') || this.status.includes('submitted');
        }
    },
    watch: {
        sorting () {
            this.getCampaigns();
        },
        showArchived () {
            this.getCampaigns();
        },
        query: debounce(function () {
            this.getCampaigns();
        }, 400)
    },
    created () {
        this.getCampaigns();
    },
    methods: {
        formatDate,
        formatDateTime,
        fromNow,
        async getCampaigns (page = 1) {
            this.loading = true;
            const { data } = await services.campaigns.list({
                page,
                sort_key: this.sorting.key,
                sort_direction: this.sorting.direction,
                archived: this.showArchived,
                status: this.status,
                q: this.query
            });
            this.campaigns = data.data;
            this.meta = data.meta;
            this.loading = false;
        },
        updateSort (data) {
            this.sorting = data;
        },
        async archive (campaignId) {
            const confirm = await dialogEmitter.confirm(
                'Delete Campaign',
                'Are you sure you want to permanently delete this campaign?'
            );
            if (confirm) {
                this.loading = true;
                await services.campaigns.destroy(campaignId);
                await this.getCampaigns();
                this.loading = false;
            }
        },
        async restore (campaignId) {
            this.loading = true;
            await services.campaigns.restore(campaignId);
            await this.getCampaigns();
            this.loading = false;
        },
        async deleteSelected () {
            await services.campaigns.batchDestroy([...this.selected]);
            await this.getCampaigns();
        },
        goToCampaign (id) {
            this.$router.push({ name: 'campaign-review', params: { campaign: id } });
        },
        handleSelection (selected) {
            this.selected = selected;
        },
        listLinks (campaignId) {
            dialogEmitter.openDialog('LinksDialog', { campaignId });
        }
    }
};
</script>

<style lang="scss" scoped>
.shadow-photo {
    @apply justify-center items-center bg-gray-400 inline-flex px-2 py-1 w-32 h-24
}
</style>
