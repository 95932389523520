<template>
    <div>
        <div>
            <div>
                <v-multiple-select
                    id="account-select"
                    :internal-search="false"
                    :loading="loading"
                    :multiple="multiple"
                    :options="accounts"
                    :value="value"
                    placeholder="Select Account"
                    @input="$emit('input', $event)"
                    @search-change="debounceAccounts"
                />
            </div>
            <p v-for="(error, index) in errorMessages" :key="index" class="text-red-400 text-xs mb-2">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapState } from 'vuex';
import VMultipleSelect from '@outreach/components/inputs/VMultipleSelect';

export default {
    components: { VMultipleSelect },
    props: {
        value: { type: Array, required: false, default: () => [] },
        errors: { type: [Array, String], required: false, default: () => [] },
        multiple: { type: Boolean, default: false }
    },
    data: () => ({
        options: [],
        loading: false
    }),
    computed: {
        ...mapState({
            accounts: ({ accounts }) => accounts.accounts
        }),
        errorMessages () {
            return Array.isArray(this.errors) ? this.errors : [this.errors];
        }
    },
    created () {
        this.getAccounts({});
    },
    methods: {
        ...mapActions({
            getAccounts: 'accounts/getAccounts'
        }),
        debounceAccounts: debounce(function (q) {
            this.getAccounts({ q });
        }, 500)
    }
};
</script>
