<template>
    <label class="v-checkbox flex">
        <div class="bg-white flex justify-center items-center border border-gray-400 mr-2 rounded" :class="sizeClasses">
            <input
                type="checkbox"
                class="hidden"
                :checked="value"
                :disabled="disabled"
                @input="$emit('input', $event.target.checked)"
            />
            <svg class="hidden w-4 h-4 text-green-600 pointer-events-none" viewBox="0 0 172 172">
                <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode:normal"
                >
                    <path d="M0 172V0h172v172z" />
                    <path
                        d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                        fill="currentColor"
                        stroke-width="1"
                    />
                </g>
            </svg>
        </div>
        <span v-if="label" class="select-none font-normal text-gray-800" :class="classes">{{ label }}</span>
        <span v-if="$slots.default" class="select-none font-normal text-gray-800" :class="classes">
            <slot></slot>
        </span>
    </label>
</template>

<script>

export default {
    props: {
        value: { type: null, default: null },
        disabled: { type: Boolean, default: false },
        label: { type: String, default: null },
        readonly: { type: Boolean, required: false, default: false },
        errors: { type: Array, required: false, default: () => [] },
        size: { type: String, default: 'md' }
    },
    computed: {
        sizes () {
            return {
                sm: 'w-4 h-4 p-0.5 shadow-xs',
                md: 'w-6 h-6 p-1 shadow'
            };
        },
        sizeClasses () {
            return this.sizes[this.size];
        },
        classes () {
            if (this.disabled) {
                return {
                    'opacity-50': true
                };
            }
            return undefined;
        }
    }
};
</script>

<style>
    .v-checkbox input:checked + svg {
        display: block !important;
    }
</style>
