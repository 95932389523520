<template>
    <div class="flex rounded bg-gray-200 border px-2 py-1 w-full gap-3 items-center">
        <p class="break-all" v-text="parsedToken"></p>
        <button
            v-if="!showByDefault"
            v-tooltip="show ? 'Hide' : 'Show'"
            class="text-gray-600 hover:text-brand-regular ml-auto"
            @click="show = !show"
        >
            <i class="fa" :class="{'fa-eye-slash': show, 'fa-eye': !show}"></i>
        </button>
        <button v-tooltip="'Copy'" class="text-gray-600 hover:text-brand-regular" @click="copyText(token)">
            <i class="fa fa-copy"></i>
        </button>
    </div>
</template>

<script>
import { copyText } from '@services/Utils';

const DEFAULT_TOKEN = '**************';

export default {
    name: 'ApiToken',
    props: {
        token: { type: String, default: DEFAULT_TOKEN },
        showByDefault: { type: Boolean, default: false }
    },
    data () {
        return { show: false };
    },
    computed: {
        showToken () {
            return this.showByDefault || this.show;
        },
        parsedToken () {
            return this.showToken ? this.token : DEFAULT_TOKEN;
        }
    },
    methods: {
        copyText
    }
};
</script>
