<template>
    <tab-view :tabs="tabs">
        <template #pending>
            <queue-pending-table ref="pendingTable" />
        </template>
        <template #reserved>
            <queue-reserved-table ref="reservedTable" />
        </template>
        <template #completed>
            <queue-completed-table ref="completedTable" />
        </template>
    </tab-view>
</template>

<script>
import QueueCompletedTable from '@outreach/components/queues/QueueCompletedTable';
import QueuePendingTable from '@outreach/components/queues/QueuePendingTable';
import QueueReservedTable from '@outreach/components/queues/QueueReservedTable';
import TabView from '@outreach/components/shared/TabView';

export default {
    name: 'NoteJobsQueue',
    components: {
        TabView,
        QueueCompletedTable,
        QueuePendingTable,
        QueueReservedTable
    },
    data: () => ({
        tabs: ['Pending', 'Reserved', 'Completed']
    })
};
</script>
