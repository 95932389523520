import ApiClient from '@services/Http/Api';

export const list = (params = {}) => {
    return ApiClient.get('/dashboard/machines', { params });
};

export const update = (id, params) => {
    return ApiClient.patch(`/dashboard/machines/${id}`, params);
};

export const create = (params) => {
    return ApiClient.post('/dashboard/machines', params);
};

export const plot = (campaignId, params) => {
    return ApiClient.post(`/dashboard/admin/machines/${campaignId}/plot`, params);
};

export const easel = (campaignId, params) => {
    return ApiClient.post(`/dashboard/admin/machines/${campaignId}/easel`, params);
};
