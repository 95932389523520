<template>
    <div>
        <div class="flex my-4 lg:mt-0">
            <v-button
                v-if="isAdmin()"
                ref="add-plan"
                label="Add Plan"
                button-style="primary"
                class="ml-auto mr-4 lg:mr-0"
                @click="add"
            />
        </div>
        <div class="px-2 py-1 text-inherit lg:hidden">Select a plan to edit details</div>

        <transition name="fade" mode="out-in">
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 px-2 lg:px-0">
                <div
                    v-for="(plan, index) in pricing"
                    :key="`package-${index}`"
                    class="cursor-pointer flex items-stretch"
                    @click="update(plan)"
                >
                    <pricing-card
                        :plan="plan"
                        :badge="plan.badge"
                        :editable="isAdmin()"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import services from '@services/Api';
import { formatToCurrency } from '@services/Utils';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VButton from '@outreach/components/VButton.vue';
import { Plan } from '@models/Plan';
import PricingCard from '@outreach/components/payments/PricingCard.vue';
import { hasPermission } from '@outreach/mixins/userHasPermission';

export default {
    name: 'AdminPricing',
    components: { PricingCard, VButton },
    mixins: [hasPermission],
    data () {
        return {
            pricing: [],
            loading: true,
            error: null
        };
    },
    created () {
        this.fetchData();
    },
    methods: {
        formatToCurrency,
        add () {
            const basePrice = this.pricing[0].base_price;
            dialogEmitter.name('PlanDialog')
                .props({ plan: new Plan({ base_price: basePrice, note_price: basePrice }) })
                .addListener('submitted', () => this.fetchData())
                .wait();
        },
        async fetchData () {
            this.pricing = await services.plans.list({ draft: true });
        },
        update (plan) {
            if (!this.isAdmin()) return;

            dialogEmitter.name('PlanDialog')
                .props({ plan })
                .addListener('submitted', () => this.fetchData())
                .addListener('archived', () => this.fetchData())
                .wait();
        }
    }
};
</script>
