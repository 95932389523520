<template>
    <div>
        <v-panel :title="title">
            <paginator-wrap v-if="accounts.length" :pagination="pagination" :change-page="(page) => list({ page })">
                <template #content>
                    <v-table has-actions :rows="accounts">
                        <template #default="{ row }">
                            <v-table-column label="Account" value="account">
                                <p><strong class="text-lg">{{ row.name }}</strong></p>
                            </v-table-column>
                            <v-table-column label="Name" value="name">
                                <account-users-info :account="row" />
                            </v-table-column>
                        </template>
                        <template #priority-buttons="{ row }">
                            <button :id="`delete-customer-${row.id}`" @click="deleteCustomer(row)">Un-Assign</button>
                        </template>
                    </v-table>
                </template>
            </paginator-wrap>
            <div v-else-if="!accounts.length && !loading" class="text-center">
                <p class="text-lg text-gray-700">This user does not have any customers yet.</p>
            </div>
            <v-loader v-else />
        </v-panel>

        <v-panel title="Add Customers">
            <add-customers
                @updated="list({page: pagination.meta.current_page})"
            />
        </v-panel>
    </div>
</template>

<script>
import { VTable, VTableColumn } from '@outreach/components/shared/table';
import services from '@services/Api';
import { formatFullDate } from '@services/Filters';
import { LengthAwarePaginator } from '@services/LengthAwarePaginator';
import { Alert } from '@services/Alert';
import VPanel from '@outreach/components/shared/VPanel';
import AccountUsersInfo from '@outreach/pages/Customers/AccountUsersInfo';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import AddCustomers from '@outreach/pages/Customers/AddCustomers';
import { mapState } from 'vuex';
import VLoader from '@outreach/components/VLoader';
import { customers } from '@services/Api/Customers';

export default {
    name: 'ManageCustomers',
    components: { PaginatorWrap, VPanel, VTable, VTableColumn, AccountUsersInfo, AddCustomers, VLoader },
    data () {
        return {
            accounts: [],
            pagination: new LengthAwarePaginator(),
            owner: null
        };
    },
    computed: {
        ...mapState({
            loading: ({ loader }) => loader.loading
        }),
        title () {
            return this.owner ? `${this.owner.name}'s Customers` : '';
        }
    },
    mounted () {
        this.list();
        this.getOwner();
    },
    methods: {
        formatFullDate,
        async list (params = {}) {
            try {
                const { data } = await customers.list(this.$route.params.userId, params);
                this.accounts = data.data;
                this.pagination.meta = data.meta;
            } catch (e) {
                await Alert.error('Failed to load users, please try again');
            }
        },
        async getOwner () {
            try {
                const { data } = await services.users.get(this.$route.params.userId);
                this.owner = data.data;
            } catch (e) {
                await Alert.error('Failed to load customer service user, please try again');
            }
        },
        async confirmRemoval (customer) {
            return await dialogEmitter.confirm('Remove Customer', `Are you sure you want to remove ${customer.name}?`);
        },
        async deleteCustomer (customer) {
            if (!await this.confirmRemoval(customer)) return;

            try {
                const { data } = await customers.destroy(this.$route.params.userId, customer.id);
                this.accounts = this.accounts.filter(account => account.id !== data.data.id);
            } catch (e) {
                await Alert.error('Could not un-assign customer, please try again.');
            }
        }
    }
};
</script>
