<template>
    <div class="bg-gray-400 w-screen md:w-auto overflow-x-auto">
        <ul ref="list" class="flex flex-row steps">
            <li
                v-for="step in steps"
                ref="step"
                :key="step"
                class="relative chevron"
                :class="classes(step)"
            >
                <div class="label">{{ step }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        steps: { type: Array, required: true },
        value: { type: String, default: undefined }
    },
    methods: {
        classes (step) {
            return {
                active: step === this.value
            };
        }
    }
};
</script>

<style scoped lang="scss">
@use 'sass:math';
$height: 2.5rem;
$skew: 45deg;
$translate: math.div(-$height, 4);

.steps li {
    @apply border-gray-400 text-gray-600;
    height: $height;
    line-height: $height;
    padding-right: math.div($height, 2);
}
.steps li.active {
    @apply text-white;
}
.steps li .label {
    @apply px-6;
    position: relative;
    z-index: 2;
}

.chevron::before, .chevron::after {
    @apply border-r-2 border-gray-200;
    content: '';
    display: block;
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: skewX($skew) translateX($translate);
}
.chevron::after {
    top: auto;
    bottom: 0;
    transform: skewX(-$skew) translateX($translate);
}
.chevron.active::before, .chevron.active::after {
    @apply bg-brand-regular;
}
</style>
