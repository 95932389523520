<template>
    <authentication-layout>
        <div v-if="loading">
            <h2 class="text-gray-800 text-xl mb-5 text-center">Logging you into Audience</h2>
            <v-loader class="mb-2" color="primary" size="32" border-width="3" />
        </div>

        <div v-else>
            <h2 class="text-gray-800 text-xl text-center mb-2">Sorry, we're not able to log you in!</h2>
            <p class="mb-0 text-center">Please verify the link in your email and try again.</p>
        </div>
    </authentication-layout>

</template>

<script>
import AuthenticationLayout from '@outreach/components/layouts/Authentication';
import VLoader from '@outreach/components/VLoader';
import services from '@services/Api';

export default {
    name: 'AcceptInvitation',
    components: { AuthenticationLayout, VLoader },
    data: () => ({
        loading: true
    }),
    async beforeMount () {
        await this.validateToken();
    },
    methods: {
        async validateToken () {
            this.loading = true;

            try {
                await services.authentication.validateToken({
                    login_token: this.$route.params.loginToken
                });

                this.$router.push({ name: 'send-notes' });
                return;
            } catch (e) {
                this.loading = false;
            }
        }
    }
};
</script>
