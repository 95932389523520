import ApiClient from '@services/Http/Api';

export const list = (params) => {
    return ApiClient.get('/dashboard/sales/properties', { params });
};

export const zipCodes = () => {
    return ApiClient.get('/dashboard/sales/properties/zip-codes');
};

export const exportProperties = (params) => {
    return ApiClient.get('/dashboard/sales/properties/export', { params });
};

export const reserved = (accountId, params = {}) => {
    return ApiClient.get(`/dashboard/sales/accounts/${accountId}/properties`, { params });
};

export const exportReserved = (accountId, params = {}) => {
    return ApiClient.post(`/dashboard/sales/accounts/${accountId}/properties/export`, params);
};

export const releaseReserved = (accountId, params = {}) => {
    return ApiClient.post(`/dashboard/sales/accounts/${accountId}/properties/release`, params);
};

export const releaseAll = (accountId) => {
    return ApiClient.post(`/dashboard/sales/accounts/${accountId}/properties/release/all`);
};

export const importProperties = (accountId, params = {}) => {
    return ApiClient.upload(`/dashboard/sales/accounts/${accountId}/properties/import`, params);
};
