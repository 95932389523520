import ApiClient from '@services/Http/Api';

export const updatePassword = (userId, password) => {
    return ApiClient.patch(`/dashboard/users/${userId}`, {
        current_password: password.current_password,
        password: password.password,
        password_confirmation: password.confirmation_password
    });
};

export const updateUserInformation = (userId, user) => {
    return ApiClient.patch(`/dashboard/users/${userId}`, user);
};
