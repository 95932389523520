<template>
    <div>
        <manage-contacts-modal @get-new-contacts="$emit('get-new-contacts')" />
        <v-input v-model="query" label="search" @input="handleSearchOnChange" />
        <virtual-list
            ref="list"
            class="bg-white border-b border-gray-400 h-150 overflow-y-scroll"
            :data-key="'id'"
            :data-sources="rows"
            :bottom-threshold="600"
            :data-component="component"
            @tobottom="handleContactsListScroll"
        >
            <template #item="{item}">
                <slide-transition>
                    <div
                        v-if="!item.deleted_at"
                        :ref="`row-${item.id}`"
                        :key="item.id"
                        :class="classes(item)"
                        class="flex flex-row p-4 cursor-pointer"
                        @click="$emit('select', item.id)"
                    >
                        <div class="flex-1">
                            <div>{{ item.title }}</div>
                            <div class="text-gray-600">{{ item.description }}</div>
                            <div
                                v-if="item.context"
                                class="bg-green-100 text-green-800 text-sm"
                            >
                                <i class="mr-3 text-xl fa fa-location-dot"></i>
                                {{ item.context }}
                            </div>
                        </div>
                        <div class="flex-0 ml-2">
                            <slot name="actions" :row="item"></slot>
                        </div>
                    </div>
                </slide-transition>
            </template>
        </virtual-list>
    </div>
</template>

<script>
import throttle from 'lodash/throttle';
import VirtualList from 'vue-virtual-scroll-list';
import { mapState } from 'vuex';
import ManageContactsModal from '@outreach/components/contacts/ManageContactsModal';
import DeliveryValidator from '@services/Delivery/DeliveryValidator';
import VInput from '@outreach/components/inputs/VInput';
import SlideTransition from '@outreach/components/shared/SlideTransition.vue';

export default {
    components: { VirtualList, ManageContactsModal, VInput, SlideTransition },
    props: {
        rows: { type: Array, default: () => [] },
        selectedId: { type: Number, default: undefined },
        loading: { type: Boolean, default: false },
        nextHasBeenClicked: { type: Boolean, default: false }
    },
    data: () => {
        return {
            query: null,
            component: () => null
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        rowIds () {
            return this.rows.map(row => row.contact_id);
        }
    },
    methods: {
        handleContactsListScroll: throttle(function () {
            this.$emit('get-more-deliveries');
        }, 400),
        classes (row) {
            const isInvalid = new DeliveryValidator(row).isInvalid();
            return {
                'bg-gray-300': row.id === this.selectedId,
                'border-b border-gray-200 bg-red-100 hover:bg-red-200': this.nextHasBeenClicked && isInvalid,
                'border-b border-gray-200 hover:bg-gray-300': !this.nextHasBeenClicked || !isInvalid
            };
        },
        handleSearchOnChange (value) {
            this.$emit('set-delivery-query', value);
        }
    }
};
</script>
