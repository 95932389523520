import services from '@services/Api';
import common, { toggleLoading } from '@audience/modules/queue/common';

export const state = {
    ...common.state,
    events: {
        'pending/completeJob': ['queue/refreshQueues'],
        'pending/deleteJob': ['queue/refreshQueues']
    }
};

export const actions = {
    listCampaigns: toggleLoading(async function ({ commit }, params) {
        const { data } = await services.queues.listPending(params);
        commit('setCampaigns', data);
    }),
    listJobs: async function ({ commit }, { params = {}, campaignId }) {
        const { data } = await services.queues.listCampaignJobs(campaignId, params);
        commit('setJobs', data);
    },
    deleteCampaign: async function ({ dispatch }, campaign) {
        await services.queues.deleteJobsForCampaign(campaign.id);
        dispatch('listCampaigns');
        dispatch('queue/refreshQueues', null, { root: true });
    },
    regenerateCampaign: async function ({}, campaign) {
        await services.queues.regenerateCampaignRenders(campaign.id);
    },
    completeJob: async function ({ dispatch }, job) {
        await services.queues.completeJob(job);
        dispatch('listJobs', { campaignId: job.campaign_id });
    },
    deleteJob: async function ({ dispatch }, job) {
        await services.queues.deleteJob(job);
        dispatch('listJobs', { campaignId: job.campaign_id });
    },
    regenerateJob: async ({ dispatch }, job) => {
        await services.queues.regenerateJob(job);
        dispatch('listJobs', { campaignId: job.campaign_id });
    }
};

export const mutations = {
    ...common.mutations
};

export const getters = {
    ...common.getters
};

export default { namespaced: true, state, actions, mutations, getters };
