"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagType = void 0;
var TagType;
(function (TagType) {
    TagType["ACCOUNTS"] = "accounts";
    TagType["CAMPAIGNS"] = "campaigns";
    TagType["CONTACTS"] = "contacts";
    TagType["CONTENT"] = "content";
    TagType["COVER"] = "cover";
    TagType["DOODLES"] = "doodles";
    TagType["IMAGES_FOLDER"] = "folder:images";
    TagType["TEMPLATES"] = "templates";
    TagType["TRAINING"] = "training";
})(TagType = exports.TagType || (exports.TagType = {}));
