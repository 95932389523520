<template>
    <base-widget :method="load" :report-link="{ name: 'rep-stale-campaigns-report' }">
        <template #content="{data}">
            <p class="font-bold text-sm text-gray-700">Stale Campaigns</p>
            <div
                v-for="user in data"
                :key="`user-${user.id}`"
                class="cursor-pointer my-4 flex items-start gap-2"
                @click="pushToUserPage(user)"
            >
                <div><i class="fa fa-user-circle text-gray-600"></i></div>
                <div class="flex-1">
                    <div>
                        <div class="font-bold">{{ user.name }}</div>
                        <div class="text-xs">{{ user.email }}</div>
                    </div>

                    <progress-bar
                        :total="100"
                        :completed="user.percent"
                        small
                        :percent="false"
                        class="mt-1"
                    />
                </div>
                <div class="w-8 text-center text-xl font-bold">{{ user.stale_campaigns }}</div>
            </div>
        </template>
    </base-widget>
</template>

<script>

import BaseWidget from '@outreach/components/widgets/BaseWidget';
import ProgressBar from '@outreach/components/shared/ProgressBar.vue';
import { maxBy } from 'lodash';
import services from '@services/Api';
import { Alert } from '@services/Alert';

export default {
    name: 'StaleCampaignsWidget',
    components: { ProgressBar, BaseWidget },
    methods: {
        pushToUserPage (user) {
            this.$router.push({ name: 'manage-customers', params: { userId: user.id } });
        },
        async load () {
            try {
                const { data } = await services.admin.widgets.repStaleCampaigns({ per_page: 6 });
                if (data.data.length === 0) return [];
                const maxStale = parseInt(maxBy(data.data, 'stale_campaigns').stale_campaigns + 5);
                return data.data.map(user => ({
                    ...user,
                    percent: (user.stale_campaigns / maxStale) * 100
                }));
            } catch (e) {
                console.error(e);
                await Alert.fromError(
                    e,
                    'Sorry, we\'re Unable to load stale campaigns widget data. Please refresh the page and try again.'
                );
            }
        }
    }
};

</script>
