import services from '@services/Api';
import debounce from 'lodash/debounce';
import { handleErrors, withLoadingState } from '@audience/modules/common';
import { Template } from '@models/Template';

export const state = {
    templates: [],
    template: {},
    meta: {},
    formErrors: {},
    loading: false
};

export const actions = {
    index: debounce(async function ({ commit }, params) {
        await withLoadingState(commit, async () => {
            const { data } = await services.admin.templates.list(params);
            commit('setTemplates', data);
        });
    }, 400),
    fetch: async ({ commit }, templateId) => {
        const { data } = await services.admin.templates.fetch(templateId);
        commit('setTemplate', data.data);
        return new Template(data.data);
    },
    create: async ({ commit }, template) => {
        try {
            commit('setFormErrors', {});
            const { data } = await services.admin.templates.create(template);
            commit('setTemplate', data.data);
        } catch (error) {
            handleErrors(error, commit);
            throw error;
        }
    },
    update: async ({ commit }, template) => {
        try {
            commit('setFormErrors', {});
            const { data } = await services.admin.templates.update(template.id, template);
            commit('setTemplate', data.data);
        } catch (error) {
            handleErrors(error, commit);
            throw error;
        }
    },
    delete: async ({ dispatch }, templateId) => {
        await services.admin.templates.destroy(templateId);
        dispatch('index');
    },
    detach: async ({ commit, state }, accountId) => {
        try {
            await services.admin.templates.detach(state.template.id, accountId);
        } catch (error) {
            handleErrors(error, commit);
        }
    }
};

export const mutations = {
    setTemplates: (state, payload = {}) => {
        state.templates = payload.data;
        state.meta = payload.meta;
    },
    setTemplate: (state, payload = {}) => {
        state.template = new Template(payload);
    },
    setLoading: (state, value = false) => {
        state.loading = value;
    },
    setFormErrors: (state, payload) => {
        state.formErrors = payload;
    }
};

export const getters = {
    get: (state) => state.template,
    list: (state) => state.templates,
    pagination: (state) => state.meta,
    formErrors: (state) => state.formErrors,
    loading: (state) => state.loading
};

export default { namespaced: true, state, actions, mutations, getters };
