<template>
    <section class="w-full">
        <portal to="header-actions">
            <v-button
                ref="add-collateral-button"
                label="Add New"
                button-style="primary"
                @click="addCollateral"
            />
        </portal>

        <v-panel v-if="rows.length > 0" title="Collaterals" subtitle="Select an account to reorder their collaterals.">
            <template #actionButton>
                <div class="collateral-search">
                    <v-input
                        ref="search-input"
                        v-model="query"
                        class="mb-2 sm:mb-0 flex-1"
                        label="Search"
                    />
                </div>
            </template>

            <paginator-wrap
                :pagination="meta"
                :change-page="changePage"
            >
                <template slot="content">
                    <section class="divide-y divide-gray-300">
                        <div v-for="row in rows" :key="row.id">
                            <div class="flex flex-wrap sm:flex-nowrap flex-row justify-between w-full gap-4 py-4">
                                <div class="flex flex-row flex-1">
                                    <div v-if="row.image_path" class="flex-none mr-4">
                                        <img :src="row.image_path" width="60" alt="" />
                                    </div>
                                    <dl>
                                        <dt class="flex flex-col">
                                            <span class="text-lg">{{ row.name }}</span>
                                            <span v-if="row.accounts" class="text-gray-700">
                                                {{ row.accounts.map(account => account.name).join(', ') }}
                                            </span>
                                            <span class="text-sm text-gray-700">{{ row.quantity }} in stock</span>
                                        </dt>
                                        <dd class="text-gray-700 pt-2">{{ row.description }}</dd>
                                    </dl>
                                </div>
                                <div class="flex sm:flex-col">
                                    <v-button
                                        :ref="`edit-button-${row.id}`"
                                        class="mx-1 sm:mx-0 my-1"
                                        button-style="default"
                                        :outlined="true"
                                        label="Edit"
                                        @click="editCollateral(row)"
                                    />
                                    <v-button
                                        :ref="`delete-button-${row.id}`"
                                        class="my-1"
                                        button-style="error"
                                        label="Delete"
                                        confirm="Are you sure you want to delete this collateral?"
                                        @click="deleteCollateral(row)"
                                    />
                                </div>
                            </div>
                            <v-button
                                :ref="`print-button-${row.id}`"
                                button-style="default"
                                :outlined="true"
                                label="Print QR Code"
                                class="pb-4"
                                @click="printCollateral(row)"
                            >
                                <template #before-label>
                                    <i class="fa fa-qrcode mr-2"></i>
                                </template>
                            </v-button>
                        </div>
                    </section>

                </template>
            </paginator-wrap>
        </v-panel>

        <v-call-to-action v-else title="No collaterals found." />
    </section>
</template>

<script>
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import VPanel from '@outreach/components/shared/VPanel';
import VCallToAction from '@outreach/components/shared/VCallToAction';
import { mapActions, mapState } from 'vuex';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import debounce from 'lodash/debounce';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { PaginatorWrap, VCallToAction, VPanel, VButton, VInput },
    data () {
        return {
            query: null,
            selectedAccount: null
        };
    },
    computed: {
        ...mapState({
            rows: ({ collaterals }) => collaterals.records,
            meta: ({ collaterals }) => collaterals.meta
        })
    },
    watch: {
        query: debounce(function (q) {
            this.getCollaterals({ q });
        }, 400)
    },
    created () {
        this.getCollaterals();
    },
    methods: {
        ...mapActions({
            list: 'collaterals/list',
            destroy: 'collaterals/destroy',
            setMessage: 'alerts/setMessage'
        }),
        async getCollaterals (params = {}) {
            await this.list(params);
        },
        async changePage (page) {
            await this.getCollaterals({ page, q: this.query });
        },
        addCollateral () {
            this.$router.push({ name: 'add-collateral' });
        },
        editCollateral (collateral) {
            this.$router.push({ name: 'edit-collateral', params: { collateral: collateral.id } });
        },
        async deleteCollateral (collateral) {
            try {
                await this.destroy(collateral.id);
                this.setMessage({ messageType: 'success', message: 'The collateral was deleted.' });
            } catch (e) {
                throw ClientVisibleError('Could not delete collateral');
            }
        },
        printCollateral (collateral) {
            const tab = window.open('');
            tab.document.write(collateral.qr_code);
            tab.focus();
            tab.print();
            tab.close();
        }
    }
};
</script>

<style scoped>
.collateral-search {
    @apply flex flex-col items-center items-stretch mb-6 px-6 max-w-md sm:mb-2 sm:px-0 sm:flex-row lg:mb-0;
}
</style>
