import services from '@services/Api';
import common, { toggleLoading } from '@audience/modules/queue/common';

export const state = {
    ...common.state,
    events: {
        'reserved/completeJob': ['queue/refreshQueues'],
        'reserved/deleteJob': ['queue/refreshQueues'],
        'reserved/releaseJob': ['queue/refreshQueues']
    }
};

export const actions = {
    listCampaigns: toggleLoading(async function ({ commit }, params) {
        const { data } = await services.queues.listReserved(params);
        commit('setCampaigns', data);
    }),
    listJobs: async function ({ commit }, { params = {}, campaignId }) {
        const { data } = await services.queues.listReservedJobs(campaignId, params);
        commit('setJobs', data);
    },
    completeJob: async function ({ dispatch }, job) {
        await services.queues.completeJob(job);
        dispatch('listJobs', { campaignId: job.campaign_id });
    },
    deleteJob: async function ({ dispatch }, job) {
        await services.queues.deleteJob(job);
        dispatch('listJobs', { campaignId: job.campaign_id });
    },
    releaseJob: async function ({ dispatch }, job) {
        await services.queues.releaseJob(job);
        dispatch('listJobs', { campaignId: job.campaign_id });
    }
};

export const mutations = {
    ...common.mutations
};

export const getters = {
    ...common.getters
};

export default { namespaced: true, state, actions, mutations, getters };
