<template>
    <div id="my-map" class="map"></div>
</template>

<script>
import { GeolocateControl, LngLat, Map, NavigationControl } from 'mapbox-gl';
import { geoCenterOfUS } from '@global/config';

export default {
    name: 'Mapbox',
    props: {
        options: {
            type: Object,
            default: () => ({})
        }
    },
    async mounted () {
        const map = new Map({
            container: 'my-map',
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [geoCenterOfUS.lng, geoCenterOfUS.lat],
            zoom: 4,
            accessToken: process.env.MAPBOX_API_KEY,
            attributionControl: false,
            ...this.options
        });
        map.addControl(new NavigationControl(), 'top-right');
        map.addControl(new GeolocateControl({
            positionOptions: { enableHighAccuracy: true },
            trackUserLocation: true
        }), 'top-right');
        await this.enableGeolocation(map);

        this.$emit('init', map);
    },
    methods: {
        async enableGeolocation (map) {
            if (!navigator.geolocation) {
                return;
            }
            await navigator.geolocation.getCurrentPosition((position) => {
                map.flyTo({
                    center: new LngLat(position.coords.longitude, position.coords.latitude),
                    zoom: 12
                });
            });
        }
    }
};
</script>

<style>
@import '~mapbox-gl/dist/mapbox-gl.css';
</style>
