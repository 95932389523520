<template>
    <div>
        <multiselect
            :id="id"
            ref="multiselect"
            :label="label"
            :track-by="trackBy"
            :loading="loading"
            :multiple="multiple"
            :options="options"
            :class="{ 'invalid': errors.length > 0}"
            :placeholder="placeholder"
            :taggable="taggable"
            :value="value"
            :internal-search="internalSearch"
            :options-limit="optionsLimit"
            :block-keys="['Delete']"
            :allow-empty="allowEmpty"
            :disabled="disabled"
            @input="$emit('input', $event)"
            @search-change="$emit('search-change', $event)"
            @tag="$emit('tag', $event)"
        >
            <template #afterList>
                <visible-signal @visible="$emit('reached-bottom', $event)" />
            </template>
        </multiselect>
        <label v-for="error in errors" :key="error" class="text-sm text-red-500" v-text="error"></label>
    </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import VisibleSignal from '@outreach/components/inputs/VisibleSignal.vue';

export default {
    components: { VisibleSignal, Multiselect },
    props: {
        loading: { type: Boolean, default: false },
        multiple: { type: Boolean, default: true },
        options: { type: Array, required: true },
        optionsLimit: { type: Number, default: 15 },
        placeholder: { type: String, default: undefined },
        value: { type: [Array, Object], default: null },
        internalSearch: { type: Boolean, default: true },
        allowEmpty: { type: Boolean, default: true },
        id: { type: String, default: 'vue-multiselect' },
        taggable: { type: Boolean, default: false },
        label: { type: String, default: 'name' },
        trackBy: { type: String, default: 'id' },
        disabled: { type: Boolean, default: false },
        errors: { type: Array, default: () => [] }
    },
    mounted () {
        this.$refs.multiselect.$refs.search.setAttribute('autocomplete', 'off');
    }
};
</script>

<style>
    .multiselect__tags {
        @apply border border-solid border-gray-400;
    }
    .multiselect__placeholder, .multiselect__input {
        @apply text-base text-gray-600;
        line-height: 24px;
        padding: 0;
    }
    .multiselect__tag {
        margin-bottom: 4px;
        padding-right: 30px;
    }
    .multiselect__tag-icon {
        border-radius: 0 5px 5px 0;
    }
    .multiselect__tag-icon:after {
        @apply text-lg text-white;
    }
    .multiselect__tag-icon:hover {
        @apply bg-gray-700;
    }
    .multiselect__option--highlight,
    .multiselect__option--highlight:after {
        @apply bg-brand-light;
    }
    .multiselect__select {
        height: auto;
        padding: 0;
        position: absolute;
        right: 16px;
        top: 14px;
        width: auto;
    }
    .multiselect__select::before {
        @apply text-gray-700;
        border: none;
        content: "\f078";
        font-family: 'Font Awesome 5 Free', serif;
        font-weight: 900;
        margin: 0;
    }
    .invalid .multiselect__tags {
        @apply border-red-500;
    }
</style>
