<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <div class="grid grid-cols-1 gap-2 md:grid-cols-4 md:items-center md:w-full">
                    <v-input
                        ref="search-input"
                        v-model="query"
                        class="md:col-span-2"
                        label="Search"
                    />
                    <v-text-dropdown
                        label="Actions"
                        :disabled="!selected.size"
                        :loading="loading"
                    >
                        <template #content>
                            <button
                                id="delete-tags"
                                class="py-1 px-3 font-normal text-left hover:bg-gray-100"
                                @click="archiveMultiple"
                            >
                                Delete {{ selected.size }} Tags
                            </button>
                        </template>
                    </v-text-dropdown>
                    <v-button
                        id="add-tag-button"
                        label="Add Tag"
                        button-style="primary"
                        @click="addTag"
                    />
                </div>
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading tag data</span>
            </div>
            <div v-else>
                <div v-if="tags.length > 0">
                    <v-table
                        is-elevated
                        has-actions
                        has-selection
                        clickable
                        :rows="tags"
                        :selected="selected"
                        @selection-changed="handleSelection"
                    >
                        <template #default="{ row }">
                            <v-table-column label="Name" value="name" sortable>
                                <span>{{ row.name }}</span>
                            </v-table-column>
                        </template>
                        <template #priority-buttons="{ row }">
                            <button
                                v-if="row.account_id"
                                ref="`edit-button-${row.id}`"
                                @click="editTag(row)"
                            >Edit</button>
                            <button
                                v-if="row.account_id"
                                ref="`delete-button-${row.id}`"
                                class="red"
                                @click="archive(row)"
                            >Delete</button>
                        </template>
                    </v-table>
                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getTags"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No tags found.</p>
                    </div>
                </div>
            </div>
        </transition>
        <modal header="Save Tag" :open="showFormModal" @close-modal="showFormModal = false">
            <template #content>
                <div class="flex w-modal">
                    <div class="w-full">
                        <div class="flex flex-row">
                            <v-input
                                ref="add-tag-input"
                                v-model="formTag.name"
                                class="ml-auto mb-4 w-full"
                                label="Name"
                                type="text"
                                :errors="formErrors.name"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template #modal-actions>
                <div class="p-2">
                    <v-button
                        label="cancel"
                        :disabled="loading"
                        :loading="loading"
                        class="mr-2"
                        @click="showFormModal = false"
                    />
                    <v-button
                        label="Save"
                        button-style="primary"
                        :disabled="loading"
                        :loading="loading"
                        @click="submit"
                    />
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import services from '@services/Api';
import VPaginator from '@outreach/components/VPaginator';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import { mapState } from 'vuex';
import Modal from '@outreach/components/shared/modal';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VTextDropdown from '@outreach/components/shared/dropdown/VTextDropdown.vue';
import SearchHeader from '@outreach/components/shared/SearchHeader.vue';
import { Alert } from '@services/Alert';
import debounce from 'lodash/debounce';

export default {
    components: {
        SearchHeader,
        VTextDropdown,
        VPaginator,
        VTable,
        VTableColumn,
        Modal,
        VButton,
        VInput,
        VLoader
    },
    data: () => ({
        loading: true,
        meta: {},
        tags: [],
        selected: new Set(),
        showFormModal: false,
        formErrors: {},
        formTag: {},
        query: null
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    watch: {
        query: debounce(function () {
            this.getTags();
        }, 500)
    },
    created () {
        this.getTags();
    },
    methods: {
        async getTags (page = 1) {
            this.loading = true;
            try {
                const { data } = await services.tags.list(this.user.account_id, {
                    managed: true,
                    page,
                    q: this.query
                });
                this.tags = data.data;
                this.meta = data.meta;
            } catch (e) {
                await Alert.error(
                    'Oops, we weren\'t able to load the tags. ' +
                    'Please refresh the page and try again or contact support for assistance.'
                );
            }
            this.loading = false;
        },
        async archive (tag) {
            await dialogEmitter
                .name('Confirm')
                .message(`Are you sure you want to delete '${tag.name}'?`)
                .isDestructive()
                .onConfirmed(async () => {
                    try {
                        await services.tags.archive(this.user.account_id, tag.id);
                        await Alert.success(`Successfully deleted tag '${tag.name}'.`);
                    } catch (e) {
                        await Alert.error(
                            'Oops, we weren\'t able to delete the tag. ' +
                            'Please refresh the page and try again or contact support for assistance.'
                        );
                    }
                    await this.getTags();
                })
                .confirm();
        },
        addTag () {
            this.showFormModal = true;
        },
        editTag (tag) {
            this.formTag = tag;
            this.showFormModal = true;
        },
        async submit () {
            const method = this.formTag.id ? 'update' : 'create';
            try {
                await services.tags[method](this.user.account_id, { type: 'contacts', ...this.formTag });
                await this.getTags();
                this.showFormModal = false;
                this.formTag = {};
            } catch (error) {
                if (!error.response) {
                    throw error;
                }
                if (error.response.data?.errors) {
                    this.formErrors = error.response.data.errors;
                }
                await Alert.error('Oops, we weren\'t able to save the tag. Please try again.');
            }
        },
        handleSelection (selected) {
            this.selected = selected;
        },
        select (item) {
            if (this.selected.has(item.id)) {
                return this.deselect(item);
            }
            this.selected = new Set([...this.selected, item.id]);
        },
        deselect (item) {
            const values = new Set(this.selected);
            values.delete(item.id);
            this.selected = values;
        },
        async archiveMultiple () {
            await dialogEmitter
                .name('Confirm')
                .message(`Are you sure you want to delete ${this.selected.size} tags?`)
                .isDestructive()
                .onConfirmed(async () => {
                    try {
                        await services.tags.archiveMany(this.user.account_id, { ids: [...this.selected] });
                        await Alert.success(`Successfully deleted ${this.selected.size} tags.`);
                    } catch (e) {
                        await Alert.error(
                            'Oops, we weren\'t able to delete the selected tags. ' +
                            'Please refresh the page and try again or contact support for assistance.'
                        );
                    }
                    this.selected = new Set();
                    await this.getTags();
                })
                .confirm();
        }
    }
};
</script>
