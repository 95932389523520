<template>
    <transition name="fade" mode="out-in">
        <div v-if="isLoading" key="loading" class="flex flex-col items-center justify-center">
            <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
            <span class="text-gray-700">Loading Contact data</span>
        </div>
        <div v-else class="mx-auto max-w-5xl">
            <v-card class="py-6">
                <template slot="body">
                    <contact-form
                        key="contact"
                        class="mb-10"
                        :contact.sync="contact"
                        :form-errors="formErrors"
                    />
                    <div class="flex justify-end">
                        <v-button
                            class="mr-2"
                            label="Cancel"
                            @click="$router.back()"
                        />
                        <div class="flex items-center justify-end mr-10">
                            <v-button
                                label="Save Contact"
                                button-style="primary"
                                :loading="isSaving"
                                @click="updateContact"
                            />
                        </div>
                    </div>
                </template>
            </v-card>
        </div>
    </transition>
</template>

<script>
import services from '@services/Api';
import ContactForm from './ContactForm';
import { mapState } from 'vuex';
import VCard from '@outreach/components/VCard';
import VButton from '@outreach/components/VButton';
import VLoader from '@outreach/components/VLoader';

const defaultFormErrors = {
    address: {}
};

export default {
    components: { ContactForm, VCard, VButton, VLoader },
    data: () => ({
        formErrors: {},
        isLoading: false,
        isSaving: false,
        contact: {
            name: null,
            email: null,
            avatar: null,
            address: {
                name: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                country: null,
                postcode: null
            },
            external_id: null,
            details: {}
        }
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    created () {
        this.fetchContact();
    },
    methods: {
        async fetchContact () {
            this.isLoading = true;
            try {
                const { contact } = this.$route.params;
                const { data } = await services.contacts.fetch(contact);
                this.contact = data.data;
            } catch (e) {
                this.handleErrors(e);
            }
            this.isLoading = false;
        },
        async updateContact () {
            this.isSaving = true;
            this.formErrors = defaultFormErrors;
            try {
                const { data } = await services.contacts.update(this.contact.id, this.contact);
                this.handleResponse(data.data);
            } catch (error) {
                this.handleErrors(error);
            }
            this.isSaving = false;
        },
        handleResponse (contact) {
            this.$router.push({
                name: 'view-contact',
                params: { accountId: contact.account_id, contact: contact.id }
            });
        },
        handleErrors (error) {
            if (!error.response) {
                throw error;
            }
            if (error.response.data && error.response.data.errors) {
                this.formErrors = error.response.data.errors;
            }
        }
    }
};
</script>
