<template>
    <div
        id="filter-sq-ft"
        class="flex flex-col gap-2"
    >
        <h3 class="text-gray-700 font-medium">Sq Ft</h3>
        <div class="flex gap-4">
            <v-dropdown
                :value="minValue"
                label="Min"
                :options="options"
                help="Leave empty for no maximum"
                :errors="formErrors['sq_ft.min']"
                pattern="^[0-9]*$"
                class="flex-1"
                @input="$emit('update:min-value', $event)"
            />
            <v-dropdown
                :value="maxValue"
                label="Max"
                :options="options"
                help="Leave empty for no maximum"
                :errors="formErrors['sq_ft.max']"
                pattern="^[0-9]*$"
                class="flex-1"
                @input="$emit('update:max-value', $event)"
            />
        </div>
    </div>

</template>
<script>
import VDropdown from '@outreach/components/inputs/VDropdown.vue';

export default {
    name: 'SqFtDropdown',
    components: { VDropdown },
    props: {
        minValue: { type: [Number, String], default: undefined },
        maxValue: { type: [Number, String], default: undefined },
        errors: { type: [Array, String], required: false, default: () => [] },
        disabled: { type: Boolean, default: false },
        formErrors: { type: Object, default: () => ({}) }
    },
    data () {
        return {
            options: [
                500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3500, 4000, 5000, 7500
            ]
        };
    }
};
</script>
