<template>
    <div class="p-2">
        <div class="grid grid-cols-2 gap-4 text-gray-900">
            <div class="col-span-2">
                <slot></slot>
            </div>
            <div class="col-span-2 text-gray-700">
                <choose-card
                    :user="user"
                    class="mb-2"
                    :card-id="cardId"
                    @change="$emit('update:cardId', $event)"
                    @remove-card="$emit('remove-card', $event)"
                />
            </div>
        </div>
        <div class="flex items-center justify-between text-gray-900 pt-4">
            <div>
                <slot name="bottom">
                    <p class="text-gray-700 font-medium">Total</p>
                    <p class="text-lg">{{ total }}</p>
                </slot>
            </div>
            <div class="text-right">
                <form-errors :errors="errors" />
                <v-button
                    ref="submit-btn"
                    label="Submit payment"
                    button-style="primary"
                    :disabled="!canSubmit"
                    :loading="loading"
                    @click="$emit('submit')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ChooseCard from '@outreach/pages/Payment/ChooseCard';
import { mapState } from 'vuex';
import VButton from '@outreach/components/VButton';
import FormErrors from '@outreach/components/shared/FormErrors.vue';
import { flatten } from 'lodash';

export default {
    name: 'Payment',
    components: { FormErrors, ChooseCard, VButton },
    props: {
        cardId: { type: Number, required: false, default: undefined },
        total: { type: String, default: '0' },
        canSubmit: { type: Boolean, default: false },
        formErrors: { type: Object, default: () => ({}) }
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            loading: ({ loader }) => loader.loading
        }),
        errors () {
            return flatten(Object.values(this.formErrors));
        }
    }
};
</script>
