<template>
    <div v-if="hasTeams" class="relative inline-block text-left px-3 mb-4">
        <div class="relative">
            <div>
                <button
                    id="menu-button"
                    ref="menu-button"
                    type="button"
                    class="dropdown-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    @click="showDropdown = !showDropdown"
                >
                    <span class="truncate">{{ selectedLabel }}</span>
                    <i class="fa fa-chevron-down"></i>
                </button>
            </div>
            <transition name="dropdown">
                <div
                    v-if="showDropdown"
                    ref="dropdown-menu"
                    v-click-away="() => showDropdown = false"
                    class="dropdown-menu"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                    @scroll="onDropdownScroll"
                >
                    <div class="py-1" role="none">
                        <a
                            v-for="team in teams"
                            :id="team.id"
                            :ref="`team-${team.id}`"
                            :key="team.id"
                            href="#"
                            class="block px-4 py-2 text-sm"
                            :class="{
                                'bg-brand-regular hover:bg-brand-dark text-white': isTeamSelected(team.id),
                                'text-gray-700 hover:bg-gray-100 hover:text-gray-900': !isTeamSelected(team.id)
                            }"
                            role="menuitem"
                            tabindex="-1"
                            @click.prevent="selectTeam(team)"
                        >{{ team.name }}</a>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import { mixin as clickAway } from '@outreach/mixins/clickAway';
import services from '@services/Api';
import { mapActions, mapState } from 'vuex';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import TeamUpdated from '@events/TeamUpdated';
import { LengthAwarePaginator } from '@services/LengthAwarePaginator';
import { Alert } from '@services/Alert';

export default {
    name: 'TeamSelect',
    mixins: [clickAway],
    data () {
        return {
            showDropdown: false,
            teams: [],
            meta: new LengthAwarePaginator()
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        selectedLabel () {
            return this.selectedTeam ? this.selectedTeam.name : 'Select a team';
        },
        hasTeams () {
            return this.teams.length > 0;
        },
        selectedTeam () {
            if (!this.user) return;
            return this.user.current_team;
        }
    },
    watch: {
        user (newUser, oldUser) {
            if (!isEqual(newUser, oldUser)) {
                this.reset();
                this.fetchTeams();
            }
        }
    },
    async created () {
        this.teams = [];
        this.reset();
        await this.fetchTeams();

        this.subscribe({
            event: TeamUpdated,
            subscription: this.handleTeamUpdated
        });
    },
    destroyed () {
        this.unsubscribe({
            event: TeamUpdated,
            subscription: this.handleTeamUpdated
        });
    },
    methods: {
        get,
        ...mapActions({
            getUser: 'user/getUser',
            setMessage: 'alerts/setMessage',
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        handleTeamUpdated () {
            this.reset();
            this.fetchTeams();
        },
        isTeamSelected (teamId) {
            return get(this.selectedTeam, 'id') === teamId;
        },
        reset () {
            this.teams = [];
            this.meta = new LengthAwarePaginator();
        },
        async selectTeam (team) {
            try {
                await services.user.updateUserInformation(this.user.id, {
                    current_team_id: team.id
                });
                await this.getUser();
            } catch (err) {
                this.setMessage({ type: 'error', errorMessage: 'Could not change team' });
            }
            this.showDropdown = false;
        },
        async onDropdownScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollTop + clientHeight < scrollHeight || !this.meta.nextPage) {
                return;
            }
            await this.fetchTeams(this.meta.nextPage);
        },
        async fetchTeams (page) {
            if (page === this.meta.currentPage || page > this.meta.lastPage) return;
            try {
                const { data } = await services.teams.fetchTeams({ page });
                this.teams = [...this.teams, ...data.data];
                this.meta = new LengthAwarePaginator(data.meta);
            } catch (e) {
                await Alert.error('Could not fetch teams.');
            }
        }
    }
};
</script>

<style lang="scss" scoped>

.dropdown-button {
    @apply inline-flex justify-between w-full items-center text-left rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500;
}

.dropdown-menu {
    @apply origin-top-left border border-gray-400 absolute left-0 mt-2 w-full rounded-md bg-white focus:outline-none z-40 max-h-[300px] overflow-y-auto;
}

</style>
