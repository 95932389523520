<template>
    <div class="container mx-auto">
        <div class="flex w-modal">
            <account-select
                class="flex-1 mx-2 mb-2"
                :errors="formErrors.account_id"
                :multiple="false"
                :value="account.id ? [account] : []"
                @input="account = $event"
            />
        </div>
        <div v-if="account.id">
            <search-header>
                <template #content>
                    <div class="grid grid-cols-1 gap-3 w-full md:grid-cols-4">
                        <v-input v-model="query.street" class="md:col-span-2" label="Street" />
                        <v-input v-model="query.city" class="md:col-span-2" label="City" />
                        <multiselect
                            id="type-select"
                            placeholder="Property Type"
                            class="md:col-span-2"
                            :value="query.types"
                            :options="propertyTypes"
                            :multiple="true"
                            @input="query.types = $event"
                        />
                        <multiselect
                            id="state-select"
                            placeholder="States"
                            class="md:col-span-2"
                            :value="query.states"
                            :options="states"
                            :multiple="true"
                            @input="query.states = $event"
                        />
                        <multiselect
                            id="zip-code-select"
                            class="md:col-span-2"
                            placeholder="ZIP"
                            :value="query.zip"
                            :options="zipCodes"
                            :multiple="true"
                            @input="query.zip = $event"
                        />
                        <multiselect
                            id="min-value-select"
                            placeholder="Min Value"
                            track-by="value"
                            label="name"
                            :value="minValue"
                            :options="ranges"
                            :multiple="false"
                            @input="updateMinValue($event.value)"
                        />
                        <multiselect
                            id="max-value-select"
                            placeholder="Max Value"
                            track-by="value"
                            label="name"
                            :value="maxValue"
                            :options="ranges"
                            :multiple="false"
                            @input="updateMaxValue($event.value)"
                        />
                        <div class="flex flex-col gap-3 md:col-span-4 md:flex-row">
                            <v-button
                                ref="import-button"
                                label="Import"
                                button-style="primary"
                                @click="importProperties"
                            />
                            <v-button
                                ref="release-all-button"
                                label="Release All"
                                confirm="Are you sure you want to release all properties?"
                                button-style="info"
                                @click="releaseAll"
                            />
                            <v-button
                                ref="export-button"
                                label="Export"
                                button-style="primary"
                                @click="exportProperties"
                            />
                        </div>
                    </div>
                </template>
            </search-header>
            <transition name="fade" mode="out-in">
                <div v-if="loading" class="flex flex-col items-center justify-center">
                    <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                    <span class="text-gray-700">Loading properties</span>
                </div>
                <div v-else>
                    <div v-if="properties.length > 0">
                        <div class="flex items-center justify-center py-2 px-2">
                            <v-paginator
                                class="flex-none"
                                :total="meta.last_page"
                                :value="meta.current_page"
                                @input="getProperties({ ...query, page: $event })"
                            />
                        </div>
                        <div class="px-4 py-3 text-gray-700 flex items-center font-regular">
                            <p>
                                Available Properties: {{ (meta.total || 0) | formatNumber }}
                                ({{ (meta.total / meta.total_count * 100).toFixed(2) }}% available)
                            </p>
                        </div>
                        <property-card
                            v-for="(row, index) in properties"
                            :key="index"
                            :properties="properties"
                            :property="row"
                        >
                            <template #actions>
                                <v-button
                                    label="Release"
                                    class="w-full"
                                    button-style="info"
                                    :loading="loading"
                                    @click="release(row)"
                                />
                            </template>
                        </property-card>
                        <div class="flex items-center justify-center py-2 px-2">
                            <v-paginator
                                class="flex-none"
                                :total="meta.last_page"
                                :value="meta.current_page"
                                @input="getProperties({ ...query, page: $event })"
                            />
                        </div>
                    </div>
                    <div v-else>
                        <div class="text-center">
                            <p class="text-lg text-gray-700">No properties found.</p>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <v-modal name="import-properties-modal" header="Import Properties" disable-on-click-away>
            <template slot="content">
                <div class="mb-8">
                    <h2 class="text-2xl mb-4 font-semibold text-grey-800">Check Contacts for Recent Mailings</h2>
                    <p class="text-lg text-gray-700">
                        Please upload a CSV file containing property data from PropertyRadar.
                    </p>
                </div>

                <div class="mb-6">
                    <p
                        v-for="(error, index) in formErrors.credentials"
                        :key="index"
                        class="text-red-400 text-xs mb-2"
                    >{{ error }}</p>
                </div>

                <vue-dropzone ref="dropzone" class="mb-4" accepted=".csv" @add-file="file = $event" />

                <div class="mb-6">
                    <p
                        v-for="(error, index) in formErrors.csv"
                        :key="index"
                        class="text-red-400 text-xs mb-2"
                    >{{ error }}</p>
                </div>

                <v-button
                    label="Upload"
                    class="w-full"
                    button-style="primary"
                    :disabled="loading || !this.file"
                    :loading="loading"
                    @click="upload"
                />
                <div v-if="downloadFileUrl" class="flex flex-col py-4">
                    <p class="pb-2 text-center text-gray-700">Your file is ready for download!</p>
                    <a ref="download-href" :href="downloadFileUrl">
                        <v-button
                            label="Download"
                            class="w-full"
                            button-style="success"
                        />
                    </a>
                </div>
            </template>
        </v-modal>
        <v-modal name="progress-bar-modal" full-screen :header="progressHeader" :disable-on-click-away="true">
            <template slot="content">
                <v-loader v-if="csvUploading" />
                <progress-bar v-else :total="jobs.total" :completed="jobs.completed" />
            </template>
        </v-modal>
    </div>
</template>

<script>
import services from '@services/Api';
import VLoader from '@outreach/components/VLoader';
import VPaginator from '@outreach/components/VPaginator';
import { mapActions, mapGetters, mapState } from 'vuex';
import { debounce, range } from 'lodash';
import { Multiselect } from 'vue-multiselect';
import { csvDownloader, formatNumber, formatToCurrency } from '@services/Utils';
import PropertyCard from '@outreach/pages/sales/PropertyCard';
import AccountSelect from '@outreach/components/account/AccountSelect';
import * as PusherService from '@services/Pusher';
import VModal from '@outreach/components/shared/modal/VModal';
import VueDropzone from '@outreach/components/shared/dropzone/VueDropzone';
import ProgressBar from '@outreach/components/shared/ProgressBar';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import SearchHeader from '@outreach/components/shared/SearchHeader';

export default {
    components: {
        PropertyCard,
        VLoader,
        VPaginator,
        Multiselect,
        AccountSelect,
        VModal,
        VueDropzone,
        ProgressBar,
        VButton,
        VInput,
        SearchHeader
    },
    data: () => ({
        account: {},
        meta: {},
        properties: [],
        sorting: {
            key: 'created_at',
            direction: 'DESC'
        },
        formErrors: {},
        query: { states: [], est_value: {}, zip: [], types: [] },
        states: [
            'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE',
            'FL', 'FM', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS',
            'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MP',
            'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY',
            'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN',
            'TX', 'UM', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
        ],
        zipCodes: [],
        propertyTypes: [
            'UTL', 'LND', 'RES', 'AGR', 'COM', 'SFR', 'GOV', 'OTH',
            'REC', 'CND', 'APT', 'IND', 'TRA', 'UNK', 'MFR'
        ],
        downloadFileUrl: null,
        file: null,
        jobs: {}
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        ...mapGetters({
            loading: 'loader/isLoading'
        }),
        ranges () {
            return range(0, 10000000, 100000).map(step => ({ value: step, name: formatToCurrency(step) }));
        },
        minValue () {
            const min = this.query.est_value.min;
            return min ? { value: min, name: formatToCurrency(min) } : null;
        },
        maxValue () {
            const max = this.query.est_value.max;
            return max ? { value: max, name: formatToCurrency(max) } : null;
        },
        csvUploading () {
            return !this.jobs.hasOwnProperty('total');
        },
        progressHeader () {
            return this.csvUploading ? 'Preparing the document' : 'Processing contacts';
        }
    },
    watch: {
        async account () {
            await this.getProperties();
        },
        async sorting () {
            await this.getProperties();
        },
        showArchived () {
            this.getProperties();
        },
        query: {
            handler (q) {
                this.debounceProperties(q);
            },
            deep: true
        }
    },
    async created () {
        await Promise.all([this.getZipCodes(), PusherService.connect(this.user)]);
        PusherService.listen('recent-mailing-progress', (data) => {
            this.jobs = data;
        });
        PusherService.listen('recent-mailing-done', this.handleProgressBarComplete);
    },
    destroyed () {
        PusherService.disconnect();
    },
    methods: {
        ...mapActions({
            open: 'modals/open',
            close: 'modals/close'
        }),
        formatNumber,
        debounceProperties: debounce(function (params) {
            this.getProperties(params);
        }, 500),
        async getProperties (params = {}) {
            const { data } = await services.properties.reserved(this.account.id, {
                ...params,
                sort_key: this.sorting.key,
                sort_direction: this.sorting.direction
            });

            this.properties = data.data;
            this.meta = { ...data.meta, ...{ total_count: data.total_count } };
        },
        async getZipCodes () {
            const { data } = await services.properties.zipCodes();
            this.zipCodes = data.data.zip_codes;
        },
        async exportProperties () {
            const { data } = await services.properties.exportReserved(this.account.id, this.query);
            csvDownloader(data);
        },
        async release (property) {
            await services.properties.releaseReserved(this.account.id, { properties: [{ id: property.id }] });
            await this.getProperties();
        },
        async releaseAll () {
            await services.properties.releaseAll(this.account.id);
            await this.getProperties();
        },
        async importProperties () {
            this.open('import-properties-modal');
        },
        updateSort (data) {
            this.sorting = data;
        },
        updateMinValue (value) {
            this.query.est_value = { ...this.query.est_value, min: value };
        },
        updateMaxValue (value) {
            this.query.est_value = { ...this.query.est_value, max: value };
        },

        async upload () {
            this.open('progress-bar-modal');
            this.downloadFileUrl = null;
            try {
                await services.properties.importProperties(this.account.id, { properties: this.file });
            } catch (error) {
                this.close('progress-bar-modal');
                if (!error.response) {
                    throw error;
                }
                if (error.response.data && error.response.data.errors) {
                    this.formErrors = error.response.data.errors;
                }
            }
        },
        handleProgressBarComplete (data) {
            this.close('progress-bar-modal');
            this.jobs = {};
            if (!data.error) {
                this.downloadFileUrl = data.fileName;
                this.getProperties();
                return;
            }
            this.$toasted.error(
                'Sorry, we were unable to process the provided CSV. Please try again or contact support for help'
            );
        }
    }
};
</script>
