<template>
    <div ref="target"></div>
</template>
<script>
import { useElementVisibility } from '@vueuse/core';
import { ref, watch } from '@vue/composition-api';

export default {
    name: 'VisibleSignal',
    emits: ['visible'],
    setup (props, { emit }) {
        const target = ref(null);
        const isVisible = useElementVisibility(target);

        watch(isVisible, (visible) => {
            if (visible) {
                emit('visible');
            }
        });

        return { isVisible, target };
    }

};
</script>
