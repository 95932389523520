<template>
    <div class="loader-container flex items-center justify-center">
        <div class="loader" :style="{ width: `${size}px`, height: `${size}px` }">
            <div class="loader-animation" :class="classes" :style="{ borderWidth: `${borderWidth}px` }"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        color: { type: String, default: 'default' },
        size: { type: Number, default: 28 },
        borderWidth: { type: Number, default: 2 }
    },
    computed: {
        classes () {
            const styles = {
                primary: { borderColor: 'border-brand-regular' },
                error: { borderColor: 'border-red-400' },
                warning: { borderColor: 'border-orange-400' },
                success: { borderColor: 'border-green-400' },
                info: { borderColor: 'border-blue-400' },
                default: { borderColor: 'border-gray-200' }
            };

            return `border-r ${styles[this.color].borderColor}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.loader {
    display: inline-block;
    position: relative;
}

.loader-animation {
    animation: loader 1200ms cubic-bezier(0.4, -0.35, 0.25, 1.75) infinite;
    border-bottom: 6px solid rgba(#ccc, 0.85);
    border-left: 6px solid rgba(#ccc, 0.85);
    border-radius: 50%;
    border-top: 6px solid rgba(#ccc, 0.85);
    height: 100%;
    width: 100%;
}

@keyframes loader {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
