<template>
    <v-card :has-body-padding="false">
        <div slot="body" class="p-8">
            <div class="mb-8">
                <h2 class="text-2xl mb-4 font-semibold text-grey-800">
                    Upload <span class="capitalize">{{ type }}s</span>
                </h2>
                <p class="text-lg text-gray-700">
                    Please upload a CSV file containing user data following the format provided by <a
                        id="sample-link"
                        :href="downloadTemplate"
                        class="text-brand-regular"
                    >our sample</a>
                </p>
            </div>

            <div class="mb-6">
                <p
                    v-for="(error, index) in formErrors.credentials"
                    :key="index"
                    class="text-red-400 text-xs mb-2"
                >{{ error }}</p>
            </div>

            <csv-fields
                v-if="showCsvFields"
                :file="file"
                :header-rules="headerRules"
                @change-file="file = undefined"
            />
            <vue-dropzone v-else ref="dropzone" class="mb-4" @add-file="handleFile($event)" />

            <div class="mb-6">
                <p
                    v-for="(error, index) in formErrors[type]"
                    :key="index"
                    class="text-red-400 text-xs mb-2"
                >{{ error }}</p>
            </div>

            <div v-if="byUrl">
                <div class="mb-4 text-center">
                    <p>OR</p>
                </div>

                <v-input
                    v-if="byUrl"
                    v-model="csvUrl"
                    class="mb-8"
                    label="Url to CSV"
                    :errors="formErrors.csv_url"
                />
            </div>

            <v-button
                label="Upload"
                class="w-full"
                button-style="primary"
                :disabled="isNotUploadable"
                :loading="loading"
                @click="upload"
            />
        </div>
    </v-card>
</template>

<script>
import services from '@services/Api';
import VueDropzone from '@outreach/components/shared/dropzone/VueDropzone';
import { mapState } from 'vuex';
import VCard from '@outreach/components/VCard';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import CsvFields from '@outreach/components/services/CsvFields.vue';
import { CsvMapper } from '@outreach/components/services/CsvMapper';

export default {
    name: 'ImportCsv',
    components: { CsvFields, VueDropzone, VCard, VButton, VInput },
    props: {
        type: {
            type: String,
            default: 'contact',
            validator: value => ['contact', 'user', 'invitation'].includes(value)
        },
        byUrl: { type: Boolean, default: true },
        teamId: { type: Number, default: undefined },
        headerRules: { type: CsvMapper, default: () => new CsvMapper() },
        onUpload: { type: Function, default: () => {} }
    },
    data () {
        return {
            loading: false,
            csvUrl: '',
            file: undefined,
            formErrors: {}
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        downloadTemplate () {
            return `${process.env.API_URL}/dashboard/csv-template?type=${this.type}s`;
        },
        isNotUploadable () {
            return this.loading || !this.file || this.headerRules.hasInvalidFields();
        },
        showCsvFields () {
            return this.headerRules.hasMappableFields() && this.file;
        }
    },
    methods: {
        handleFile (file) {
            this.file = file;
        },
        async upload () {
            this.loading = true;
            try {
                await this.processCsv();
                this.$emit('csv-imported');
            } catch (error) {
                if (!error.response) {
                    throw error;
                }
                if (error.response.data && error.response.data.errors) {
                    this.formErrors = error.response.data.errors;
                }
            }
            this.loading = false;
        },
        async processCsv () {
            switch (this.type) {
                case 'user':
                    return await services.admin.importUsers(
                        { users: this.file, team_id: this.teamId, mappings: this.headerRules.mappings }
                    );
                case 'invitation':
                    return await services.admin.invitations.import({
                        invitations: this.file,
                        mappings: this.headerRules.mappings
                    });

                default:
                    return this.onUpload({
                        file: this.file,
                        csv_url: this.csvUrl
                    });
            }
        }
    }
};
</script>

<style>
.csv-import-file {
    width: 100%;
    background-color: red;
}
</style>
