<template>
    <button
        ref="main-button"
        :class="classes"
        :disabled="disabled || loading"
        @click="$emit('click', $event)"
    >
        <slot name="before-label"></slot>
        <v-loader v-if="loading" :size="16" class="opacity-75" />
        <span v-else>{{ label }}</span>
        <slot name="after-label"></slot>
    </button>
</template>

<script>
import VLoader from '@outreach/components/VLoader';

export default {
    components: { VLoader },
    props: {
        label: { type: String, default: null },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        buttonStyle: { type: String, default: 'default' },
        outlined: { type: Boolean, default: false },
        size: { type: String, default: 'md' }
    },
    computed: {
        classes () {
            const styles = {
                primary: {
                    borderColor: 'border-brand-regular',
                    bgColor: 'bg-brand-regular',
                    textColor: 'text-white',
                    outlinedTextColor: 'text-brand-regular'
                },
                error: {
                    borderColor: 'border-red-500',
                    bgColor: 'bg-red-500',
                    textColor: 'text-white',
                    outlinedTextColor: 'text-red-500'
                },
                warning: {
                    borderColor: 'border-yellow-500',
                    bgColor: 'bg-yellow-500',
                    textColor: 'text-white',
                    outlinedTextColor: 'text-orange-500'
                },
                success: {
                    borderColor: 'border-green-500',
                    bgColor: 'bg-green-500',
                    textColor: 'text-white',
                    outlinedTextColor: 'text-green-500'
                },
                info: {
                    borderColor: 'border-blue-500',
                    bgColor: 'bg-blue-500',
                    textColor: 'text-white',
                    outlinedTextColor: 'text-blue-500'
                },
                default: {
                    borderColor: 'border-gray-300',
                    bgColor: 'bg-gray-300',
                    textColor: 'text-gray-800',
                    outlinedTextColor: 'text-gray-800'
                }
            };

            const sizes = {
                sm: 'py-1',
                md: 'h-10 py-2'
            };

            const match = styles[this.buttonStyle];

            return {
                'button transition-all tracking-wider': true,
                [sizes[this.size]]: true,
                'opacity-50 cursor-not-allowed hover:opacity-50': this.disabled,
                [`bg-transparent ${match.outlinedTextColor} border-solid border ${match.borderColor}`]: this.outlined,
                [`${match.textColor} ${match.bgColor} border-solid border ${match.borderColor}`]: !this.outlined
            };
        }
    }
};
</script>

<style scoped>
.button {
    @apply shadow min-w-button inline-flex justify-center items-center flex-1 px-5 font-bold uppercase text-sm rounded-md hover:opacity-90 focus:outline-none focus:shadow-outline;
}
</style>
