<template>
    <div
        v-tooltip="tooltip"
        class="rounded-full flex items-center justify-center cursor-pointer relative"
        @click="onClickIcon"
    >
        <i :class="icon"></i>
        <i
            class="rounded-full border border-white absolute top-0 right-0 -translate-y-1/3 translate-x-1/3"
            :class="{'fa-square-check fa-solid':completed, 'fa-regular fa-square':!completed}"
            @click.stop="onClickCheckbox"
        ></i>
    </div>

</template>

<script>

import { copyText } from '@services/Utils';

export default {
    name: 'ContactCardButton',
    props: {
        completed: { type: Boolean, default: false },
        icon: { type: String, default: 'fa-regular fa-envelope' },
        copy: { type: [String, Number], default: undefined }
    },
    computed: {
        isChecked () {
            return this.completed;
        },
        tooltip () {
            return this.copy
                ? `${this.copy} (click to copy)`
                : '';
        }
    },
    methods: {
        copyText,
        onClickCheckbox () {
            this.$emit('toggle');
        },
        onClickIcon () {
            if (this.copy) {
                copyText(this.copy);
            }

            this.$emit('click');
        }
    }
};
</script>
