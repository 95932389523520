<template>
    <div class="flex flex-wrap sm:flex-nowrap flex-row justify-between w-full gap-4 p-4">
        <div class="flex flex-row flex-1">
            <div class="flex-none mr-4">
                <div class="border h-20 bg-gray-50" style="min-width: 5rem;">
                    <img v-if="team.logo_url" :src="team.logo_url" class="h-full w-auto" alt="" />
                </div>
            </div>
            <dl>
                <dt class="flex flex-col text-lg">{{ team.name }}</dt>
                <dd class="text-gray-700">{{ team.description }}</dd>
                <dd ref="owners">{{ owners }}</dd>
            </dl>
        </div>

        <div class="flex sm:flex-col">
            <router-link :to="{ name: 'edit-team', params: { teamId: team.id }} ">
                <v-button
                    :ref="`edit-team-button-${team.id}`"
                    class="mx-1 sm:mx-0 my-1"
                    button-style="default"
                    :outlined="true"
                    label="Edit"
                    size="sm"
                />
            </router-link>

            <v-button
                :ref="`delete-team-button-${team.id}`"
                class="my-1"
                button-style="error"
                label="Delete"
                confirm="Are you sure you want to delete this team?"
                size="sm"
                @click="deleteTeam(team)"
            />
        </div>
    </div>
</template>
<script>
import VButton from '@outreach/components/VButton';
import { mapActions } from 'vuex';

export default {
    name: 'ListTeamItem',
    components: { VButton },
    props: {
        team: { type: Object, required: true }
    },
    computed: {
        owners () {
            if (!this.team.owners.length) {
                return 'No Owners';
            }

            const [firstOwner, ...rest] = this.team.owners;

            return rest.length > 0
                ? `Owners: ${firstOwner.first_name} and ${rest.length} more`
                : `Owner: ${firstOwner.first_name}`;
        }
    },
    methods: {
        ...mapActions({
            deleteTeam: 'teams/delete'
        })
    }
};
</script>
