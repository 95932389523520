import Campaigns from '@outreach/pages/Campaigns';
import Contacts from '@outreach/pages/Contacts.vue';

export default [
    {
        path: '/campaigns',
        redirect: '/campaigns/drafts'
    },
    {
        path: '/campaigns/drafts',
        name: 'drafts',
        component: Campaigns,
        props: { status: ['pending'] },
        meta: {
            title: 'Drafts',
            breadcrumbs: [{ name: 'drafts', label: 'Drafts' }],
            requiresAuth: true
        }
    },
    {
        path: '/campaigns/sent',
        name: 'sent',
        component: Campaigns,
        props: { status: ['submitted', 'sent', 'printing'] },
        meta: {
            title: 'Sent',
            breadcrumbs: [{ name: 'sent', label: 'Sent' }],
            requiresAuth: true
        }
    },
    {
        path: '/campaigns/follow-up',
        name: 'follow-up',
        component: Campaigns,
        props: { status: ['sent'], followUp: true },
        meta: {
            title: 'Phone & Email',
            breadcrumbs: [{ name: 'follow-up', label: 'Phone & Email' }],
            requiresAuth: true
        }
    },
    {
        path: '/campaigns/:campaign/contacts',
        name: 'campaign-contacts-index',
        component: Contacts,
        meta: {
            breadcrumbs: [
                { name: 'follow-up', label: 'Follow Up' },
                { name: 'campaign-contacts-index', label: 'Contacts' }
            ],
            title: 'Contacts',
            header: true,
            requiresAuth: true
        }
    }
];
