<template>
    <div v-if="data" class="flex flex-col w-full">
        <steps class="border-b xl:border-b-0 border-gray-500" :steps="steps" value="Compose" />
        <contacts-navigation
            ref="contacts-navigation"
            class="block xl:hidden"
            :rows="contacts"
            :selected-id="selectedDeliveryId"
            @select="$emit('select-delivery', $event)"
            @end-reached="$emit('get-more-deliveries')"
        >
            <template #actions>
                <contact-actions
                    v-if="selectedDeliveryId"
                    :contact-id="selectedDeliveryId"
                    :delete-disabled="deleteDisabled"
                    @delete="$emit('delete-contact', selectedDelivery)"
                    @edit="quickEdit(selectedDeliveryId)"
                />
            </template>
        </contacts-navigation>
        <div class="flex flex-1 items-stretch flex-col xl:flex-row">
            <contacts-list
                ref="contacts-list"
                class="hidden w-64 xl:block xl:border-b-0 xl:border-r"
                :rows="contacts"
                :selected-id="selectedDeliveryId"
                :loading="deliveriesLoading"
                :next-has-been-clicked="nextHasBeenClicked"
                @select="$emit('select-delivery', $event)"
                @get-more-deliveries="$emit('get-more-deliveries')"
                @get-new-contacts="$emit('get-new-contacts')"
                @set-delivery-query="value => $emit('set-delivery-query', value)"
            >
                <template #actions="{ row }">
                    <v-loader
                        v-if="deliveriesLoading"
                        class="mb-2"
                        color="primary"
                        :size="32"
                        :border-width="3"
                    />
                    <div v-else>
                        <contact-actions
                            :contact-id="row.id"
                            :delete-disabled="deleteDisabled"
                            @delete="$emit('delete-contact', row)"
                            @edit="quickEdit(row.id)"
                        />
                    </div>
                </template>
            </contacts-list>
            <div class="container mx-auto max-w-4xl py-6 md:p-6">
                <div class="flex justify-between items-center flex-wrap px-6 md:px-0 mb-6">
                    <div class="flex mb-4 sm:mb-2 items-center w-full">
                        <div class="flex flex-col sm:flex-row sm:items-center flex-1">
                            <h3 class="text-2xl text-gray-800 font-semibold">Compose</h3>
                            <span
                                v-if="campaign.deliveries_count"
                                class="text-gray-700 -mt-1 sm:mt-0 sm:ml-2"
                            >({{ campaign.deliveries_count }} notes)</span>
                        </div>
                        <v-button
                            ref="next-button"
                            class="mx-1 w-auto ml-auto"
                            label="Next"
                            button-style="primary"
                            @click="continueIfClean"
                        />
                    </div>
                    <div class="flex flex-col sm:flex-row w-full items-center justify-between gap-3">
                        <toggle-group
                            :value="data.layout_type"
                            :options="noteOptions"
                            label="Select a note size"
                            class="w-full sm:w-auto"
                            @input="updateLayout($event)"
                        />
                        <div class="flex flex-row -mx-1">
                            <v-button
                                v-if="contacts && contacts.length > 1"
                                ref="update-all-button"
                                class="mx-1"
                                label="Update All"
                                size="sm"
                                confirm="Are you sure you want to update the message for all contacts?"
                                :loading="loading"
                                @click="$emit('update-all-with-refresh', withQrUrls);"
                            >
                                <template #confirmable-content>
                                    <v-checkbox
                                        v-model="withQrUrls"
                                        label="Apply current QR Code URL to all contacts"
                                        class="mt-4"
                                    />
                                </template>
                            </v-button>
                            <v-button
                                ref="update-button"
                                :loading="loading"
                                class="mx-1"
                                :label="updateButtonLabel"
                                size="sm"
                                @click="$emit('update');"
                            />
                        </div>
                    </div>
                </div>

                <v-panel title="Choose a text template" subtitle="(optional)">
                    <template #actionButton>
                        <v-button
                            ref="choose-template-button"
                            button-style="primary"
                            label="Choose Template"
                            @click="openTemplateModal"
                        />
                    </template>

                    <v-typeahead
                        ref="message"
                        class="mb-6"
                        label="Your message"
                        type="textarea"
                        :options="tags"
                        :rows="10"
                        :value="data.content"
                        @input="update('content', $event)"
                    />

                    <interpolate-tags :tags="tags" @tagged="insert" />

                    <div v-if="selectedDeliveryId && selectedDelivery.contact.note" class="mb-6">
                        <p class="notes-title">Notes</p>
                        <p class="whitespace-pre pt-2">{{ selectedDelivery.contact.note }}</p>
                    </div>

                    <v-typeahead
                        ref="qr-code-input"
                        class="mb-6"
                        label="QR Code URL"
                        :value="data.resources ? data.resources.qr_code_url : null"
                        :options="tags"
                        :errors="errors.qr_code_url"
                        @input="updateQrCodeUrl"
                    />

                    <template slot="footer">
                        <doodle-select
                            ref="doodle-select-bottom"
                            label="Choose bottom doodle..."
                            :value="data.doodles && data.doodles.find((doodle) => doodle.position === 'bottom')"
                            :errors="errors.doodles"
                            @selected="updateDoodle($event, 'bottom')"
                        />
                    </template>
                </v-panel>

                <v-panel
                    title="Cover Images"
                    subtitle="Upload an image or select one of the recommended covers provided."
                >
                    <v-image-picker
                        ref="image-picker"
                        v-model="data.photo_path"
                        :account-id="campaign.account_id"
                        :errors="getPhotoErrors()"
                        :asset-type="assetType"
                    />
                </v-panel>

                <post-it-form
                    v-if="user.preferences.post_it_notes"
                    :text="data ? data.post_it : null"
                    :doodle="postItDoodle"
                    class="w-full"
                    :show="showPostItForm"
                    :tags="tags"
                    :account-id="campaign.account_id"
                    @doodleSelected="updateDoodle($event, 'post-it')"
                    @input="updateSelectedDelivery('post_it', $event)"
                    @discard="removePostIt"
                    @toggleForm="showPostItForm = true"
                />
            </div>
        </div>

        <v-modal ref="validation-error" name="validation-modal">
            <template #content>
                <div class="w-modal">Some or all deliveries are missing content</div>
            </template>

            <template #modal-actions="{ close }">
                <div class="p-2">
                    <v-custom-button
                        ref="back-button"
                        label="Back"
                        button-style="error"
                        class="mr-2"
                        @click="close"
                    />
                </div>
            </template>
        </v-modal>

        <v-modal ref="unsaved-changes-modal" name="dirty-modal">
            <template #content>
                <div class="w-modal">You have unsaved changes!</div>
                <v-checkbox v-model="withQrUrls" class="mt-4" label="Apply current QR Code URL to all contacts" />
            </template>

            <template #modal-actions="{ close }">
                <div class="flex flex-col sm:flex-row gap-2">
                    <v-custom-button
                        ref="cancel-button"
                        label="Cancel"
                        class="flex-none"
                        button-style="error"
                        @click="close"
                    />

                    <v-custom-button
                        v-if="contacts && contacts.length > 1"
                        ref="update-all-button"
                        label="Update All"
                        confirm="Are you sure you want to update the message for all contacts?"
                        class="flex-none"
                        @click="handleUpdateAll(close)"
                    />
                    <v-custom-button
                        ref="update-button"
                        :label="updateButtonLabel"
                        class="flex-none"
                        @click="handleUpdate(close)"
                    />

                    <v-custom-button
                        ref="confirm-button"
                        label="Continue Without Saving"
                        button-style="primary"
                        class="flex-none"
                        @click="next(close)"
                    />
                </div>
            </template>
        </v-modal>
    </div>
</template>

<script>
import VTypeahead from '@outreach/components/VTypeahead';
import ContactsList from '@outreach/pages/CampaignReview/ContactsList';
import ContactsNavigation from '@outreach/pages/CampaignReview/ContactsNavigation';
import DoodleSelect from '@outreach/components/campaign/DoodleSelect';
import Steps from '@outreach/pages/Steps';
import VCustomButton from '@outreach/components/VCustomButton';
import VModal from '@outreach/components/shared/modal/VModal';
import { get } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import VImagePicker from '@outreach/components/shared/VImagePicker';
import VPanel from '@outreach/components/shared/VPanel';
import VCheckbox from '@outreach/components/inputs/VCheckbox';
import ToggleGroup from '@outreach/components/shared/ToggleGroup';
import InterpolateTags from '@outreach/components/campaign/InterpolateTags';
import DeliveryValidator from '@services/Delivery/DeliveryValidator';
import { AssetType } from '@services/AssetType';
import VButton from '@outreach/components/VButton';
import VLoader from '@outreach/components/VLoader';
import PostItForm from '@outreach/components/campaign/PostItForm';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { Layout } from '@services/Layout';
import ContactActions from '@outreach/pages/CampaignCompose/ContactActions.vue';

export default {
    components: {
        ContactActions,
        PostItForm,
        InterpolateTags,
        ToggleGroup,
        VImagePicker,
        DoodleSelect,
        ContactsList,
        ContactsNavigation,
        Steps,
        VTypeahead,
        VModal,
        VCustomButton,
        VPanel,
        VCheckbox,
        VButton,
        VLoader
    },
    props: {
        errors: { type: Object, default: () => ({}) },
        data: { type: Object, default: undefined },
        deliveries: { type: Array, default: () => [] },
        selectedDeliveryId: { type: Number, default: null },
        tags: { type: Object, required: true },
        templates: { type: Array, default: () => [] },
        covers: { type: Array, default: () => [] },
        deliveriesLoading: { type: Boolean, default: false },
        total: { type: Number, default: null },
        steps: { type: Array, required: true }
    },
    data: () => ({
        photo: null,
        nextHasBeenClicked: false,
        withQrUrls: false,
        showPostItForm: false
    }),
    computed: {
        ...mapState({
            campaign: ({ campaign }) => campaign.campaign,
            user: ({ user }) => user.user
        }),
        ...mapGetters({
            loading: 'loader/isLoading'
        }),
        selectedDelivery () {
            return this.deliveries.find(delivery => delivery.id === this.selectedDeliveryId);
        },
        assetType () {
            return AssetType.NOTE_COVER;
        },
        contacts () {
            return this.deliveries.map(delivery => ({
                id: delivery.id,
                contact_id: delivery.contact_id,
                title: delivery.contact.name,
                description: delivery.contact.address.address1,
                content: delivery.content,
                deleted_at: delivery.deleted_at,
                context: delivery.contact.secondary_address?.address1
            }));
        },
        updateButtonLabel () {
            return `Update ${get(this.selectedDelivery, 'contact.name', '')}`;
        },
        noteOptions () {
            return [
                { value: 'note', label: 'Note' },
                { value: 'half-note', label: 'Half Note' }
            ];
        },
        deleteDisabled () {
            return this.campaign.deliveries_count <= 1;
        },
        postItDoodle () {
            if (!this.data || !this.data.doodles) return;
            return this.data.doodles.find(doodle => doodle.position === 'post-it');
        },
        isProduction: () => process.env.NODE_ENV === 'production',
        isDirty () {
            return DeliveryValidator.checkDirty(this.selectedDelivery, this.data);
        }
    },
    watch: {
        selectedDeliveryId (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.showPostItForm = false;
            }
        },
        isDirty (value) {
            this.$emit('on-dirty', value);
        }
    },
    methods: {
        ...mapActions({
            openModal: 'modals/open'
        }),
        getPhotoErrors () {
            return [
                ...get(this.errors, 'photo', []),
                ...get(this.errors, 'photo_path', [])
            ];
        },
        isInvalid () {
            return this.deliveries.some(delivery => new DeliveryValidator(delivery).isInvalid());
        },
        classes (id) {
            return {
                'bg-gray-100': id === this.selectedDeliveryId
            };
        },
        insert (value) {
            const input = this.$refs.message.$refs.input;
            input.insertAtSelectionStart(`@${value}`);
        },
        update (name, value) {
            const data = {
                ...this.data,
                [name]: value
            };
            this.$emit('update:data', data);
        },
        updateDoodle (doodle, position) {
            const doodles = this.data.doodles || [];
            this.update(
                'doodles',
                doodles.filter(current => current.position !== position).concat([{ ...doodle, ...{ position } }])
            );
        },
        updateSelectedDelivery (name, value) {
            this.update('post_it', value);
        },
        async updateTemplate (template) {
            await this.update('content', template.content);
            await this.update('doodles', template.doodles);
            await this.update('photo_path', template.cover?.location ?? '');
            this.$emit('fontUpdated', template.font_id);
            await this.updateQrCodeUrl(template.qr_code_url);
            this.updateLayout(template.layout_type);

            this.$emit('update-all-with-refresh', true);
        },
        async updateQrCodeUrl (url) {
            const data = {
                ...this.data,
                resources: { ...this.data.resources, qr_code_url: url }
            };
            await this.$emit('update:data', data);
        },
        updateLayout (layoutType) {
            this.$emit('update-layout-type', layoutType);
        },
        continueIfClean () {
            if (this.isDirty) {
                return this.openModal('dirty-modal');
            }
            return this.next();
        },
        next (callback = () => {}) {
            callback();
            this.nextHasBeenClicked = true;
            if (this.isInvalid()) {
                return this.openModal('validation-modal');
            }
            this.$emit('next');
        },
        handleUpdateAll (close) {
            this.$emit('update-all-and-continue', this.withQrUrls);
            close();
        },
        handleUpdate (close) {
            this.$emit('update-and-continue', true);
            close();
        },
        async removePostIt () {
            await this.update('post_it', null);
            await this.update('doodles', this.data.doodles.filter(item => item.position !== 'post-it'));
            this.showPostItForm = false;
        },
        openTemplateModal () {
            dialogEmitter.openDialog(
                'TemplateSelect',
                { accountId: this.campaign.account_id, layoutTypes: [Layout.NOTE, Layout.HALF_NOTE] },
                { choose: (event) => this.updateTemplate(event) }
            );
        },
        quickEdit (deliveryId) {
            const contact = this.deliveries.find(delivery => delivery.id === deliveryId).contact;
            dialogEmitter.name('ContactQuickEdit')
                .props({ value: contact })
                .addListener('updated', (updated) => {
                    const index = this.deliveries.findIndex(d => d.id === deliveryId);
                    this.deliveries.splice(index, 1, { ...this.deliveries[index], contact: updated });
                })
                .wait();
        }
    }
};
</script>

<style lang="scss" scoped>
.notes-title {
    @apply uppercase text-sm text-gray-700 pointer-events-none tracking-wider truncate border-b border-gray-400;
}

.button-icon {
    @apply text-white bg-gray-400 rounded-full w-6 h-6 flex items-center justify-center;
}
</style>
