<template>
    <button
        class="bg-gray-300 rounded p-2 truncate"
        :class="classes"
        @click="$emit('click', value)"
    >@{{ value }}</button>
</template>

<script>
export default {
    props: {
        value: { type: String, required: true },
        disabled: { type: Boolean, default: false }
    },
    computed: {
        classes () {
            return { 'opacity-25': this.disabled };
        }
    }
};
</script>
