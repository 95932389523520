<template>
    <modal header="Address" :open="showModal" @close-modal="$emit('close')">
        <template slot="content">
            <div class="mb-5 pt-5 w-modal">
                <v-input v-model="subject.name" class="mb-4" label="Name" :errors="formErrors.name" />
                <v-input v-model="subject.address1" class="mb-4" label="Address" :errors="formErrors.address1" />
                <v-input v-model="subject.address2" class="mb-4" label="Address2" :errors="formErrors.address2" />
                <v-input v-model="subject.city" class="mb-4" label="City" :errors="formErrors.city" />
                <v-input v-model="subject.state" class="mb-4" label="State" :errors="formErrors.state" />
                <v-input v-model="subject.postcode" class="mb-4" label="Postcode" :errors="formErrors.postcode" />
                <v-input v-model="subject.country" class="mb-4" label="Country" :errors="formErrors.country" />
            </div>
        </template>

        <template slot="modal-actions">
            <div class="p-2">
                <v-button
                    label="cancel"
                    class="mr-2"
                    @click="$emit('close')"
                />
                <v-button
                    ref="save-address-button"
                    label="Save Address"
                    button-style="primary"
                    :loading="isLoading"
                    @click="submitAddress()"
                />
            </div>
        </template>
    </modal>
</template>

<script>
import { mapState } from 'vuex';
import services from '@services/Api';
import Modal from '@outreach/components/shared/modal/index';
import { cloneDeep, get } from 'lodash';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { Modal, VButton, VInput },
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        address: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        formErrors: {},
        isLoading: false,
        subject: {}
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    watch: {
        showModal (isShowing) {
            if (isShowing) {
                this.subject = cloneDeep(this.address);
            }
        }
    },
    methods: {
        async submitAddress () {
            this.isLoading = true;
            this.formErrors = {};
            try {
                const { data } = await services.addresses.save(this.user.account_id, this.subject);

                this.notify(data.data);

                this.$emit('close');
                this.subject = {};
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
            }
            this.isLoading = false;
        },
        notify (payload) {
            if (this.subject.id) {
                return this.$emit('addressUpdated', payload);
            }

            this.$emit('addressCreated', payload);
        }
    }
};
</script>
