<template>
    <div>
        <section class="w-full">
            <portal to="header-actions">
                <div class="flex gap-4">
                    <v-input ref="search" v-model="q" label="Find a team..." class="mb-4" />
                    <router-link :to="{ name: 'add-team'}">
                        <v-button label="Add New" style="primary" />
                    </router-link>
                </div>
            </portal>
        </section>
        <v-loader v-if="loading" />
        <div v-else>
            <div v-if="teams.length > 0">

                <paginator-wrap :pagination="meta" :change-page="changePage">
                    <template #content>
                        <section class="divide-y divide-gray-300 bg-white shadow-md rounded">
                            <div v-for="team in teams" :key="team.id">
                                <list-team-item :ref="`team-${team.id}`" :team="team" />
                            </div>
                        </section>
                    </template>
                </paginator-wrap>
            </div>
            <v-call-to-action v-else title="No teams found" subtitle="Create your first team now." icon="users" />
        </div>
    </div>
</template>

<script>
import VButton from '@outreach/components/VButton';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';
import VCallToAction from '@outreach/components/shared/VCallToAction';
import ListTeamItem from '@outreach/pages/admin/teams/ListTeamItem';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';

export default {
    name: 'ListTeams',
    components: { ListTeamItem, VButton, PaginatorWrap, VCallToAction, VInput, VLoader },
    data () {
        return {
            page: 1,
            q: ''
        };
    },
    computed: {
        ...mapState({
            teams: ({ teams }) => teams.records,
            meta: ({ teams }) => teams.meta,
            loading: ({ loader }) => loader.loading
        })
    },
    watch: {
        page () {
            this.fetchTeams();
        },
        q () {
            this.debounceFetchTeams();
        }
    },
    async created () {
        await this.fetchTeams();
    },
    methods: {
        ...mapActions({
            listTeams: 'teams/index'
        }),
        async fetchTeams () {
            await this.listTeams({ q: this.q, page: this.page });
        },
        debounceFetchTeams: debounce(function () {
            this.fetchTeams();
        }, 500),
        changePage (page) {
            this.page = page;
        }
    }
};
</script>
