<template>
    <div class="bg-gray-200/50 absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center">
        <v-loader color="primary" :size="48" :border-width="8" />
    </div>
</template>

<script>
import VLoader from '@outreach/components/VLoader';

export default {
    name: 'LoadingOverlay',
    components: { VLoader }
};
</script>
