<template>
    <div>
        <div v-if="jobs.length > 0">
            <v-panel :title="title" :padded="false" :loading="loading">
                <template #actionButton>
                    <div class="search-box">
                        <v-input
                            v-model="query"
                            class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1"
                            label="Search"
                        />
                    </div>
                </template>
                <div class="p-4 grid grid-cols-3 gap-x-4 gap-y-2 bg-gray-50">
                    <p class="column-header">Job Type</p>
                    <p class="column-header hidden md:inline-block">Details</p>
                    <p class="column-header hidden sm:block">Actions</p>
                </div>
                <TransitionGroup name="list" tag="div">
                    <div
                        v-for="(job, index) in jobs"
                        :key="index"
                        class="px-4 py-4 grid grid-cols-3 gap-x-4 gap-y-2 text-center items-center"
                        :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    >
                        <div>
                            <p>{{ job.payload.jobable_type }}</p>
                        </div>
                        <div class="hidden md:block md:flex md:justify-center">
                            <img width="75" alt="drawing job preview" :src="job.jobable.location" />
                        </div>
                        <div class="mt-2 sm:mt-0">
                            <v-button
                                :ref="`delete-job-${job.id}`"
                                label="Delete"
                                button-style="error"
                                size="sm"
                                confirm="Are you sure you want to delete this job?"
                                @click="deleteJob(job)"
                            />
                        </div>
                    </div>
                </TransitionGroup>

                <template
                    v-if="meta.total > meta.per_page || meta.total == null"
                    #footer
                >
                    <v-simple-paginator
                        v-if="meta.total == null"
                        :pagination="meta"
                        @input="listPending({ page: $event })"
                    />
                    <v-paginator
                        v-else
                        :value="meta.current_page"
                        :total="meta.last_page"
                        :simple="meta.total == null"
                        @input="listPending({ page: $event })"
                    />
                </template>
            </v-panel>
        </div>
        <div v-else>
            <v-panel :title="title">
                <div class="text-center">
                    <p v-if="loading">Loading jobs...</p>
                    <p v-else class="text-lg text-gray-700">No jobs found.</p>
                </div>
            </v-panel>

        </div>
    </div>
</template>
<script>
import VInput from '@outreach/components/inputs/VInput';
import VPaginator from '@outreach/components/VPaginator';
import VPanel from '@outreach/components/shared/VPanel';
import VSimplePaginator from '@outreach/components/VSimplePaginator';
import VButton from '@outreach/components/VButton';
import services from '@services/Api';
import { Alert } from '@services/Alert';
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

export default {
    name: 'SingleJobsTable',
    components: { VInput, VButton, VPaginator, VPanel, VSimplePaginator },
    props: {
        title: { type: String, default: null },
        status: { type: String, required: true }
    },
    data () {
        return {
            jobs: [],
            meta: {},
            query: ''
        };
    },
    computed: {
        ...mapGetters({
            loading: 'loader/isLoading'
        })
    },
    watch: {
        query () {
            this.listJobs();
        }
    },
    mounted () {
        this.listJobs();
    },
    methods: {
        listJobs: debounce(async function (params = {}) {
            try {
                const { data } = await services.queues.listSingleJobs({
                    ...params,
                    q: this.query.length ? this.query : undefined,
                    status: this.status
                });
                this.jobs = data.data;
                this.meta = data.meta;
            } catch (e) {
                await Alert.error('Failed to load pending jobs. Please try again.');
            }
        }, 600),
        async deleteJob (job) {
            try {
                await services.queues.deleteSingleJob(job);
                this.listJobs();
                await Alert.success('Job successfully removed.');
            } catch (e) {
                await Alert.error('Failed attempting to remove job. Please refresh the page and try again.');
            }
        }
    }
};
</script>
<style scoped lang="scss">
.search-box {
    @apply flex flex-col sm:flex-row items-center items-stretch lg:mb-0 mb-6 sm:mb-2 px-6 sm:px-0;
}
.column-header {
    @apply text-gray-700 text-xs uppercase tracking-widest overflow-hidden text-center;
}
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
