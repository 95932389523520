"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GooglePolygon = void 0;
const config_1 = require("@global/config");
const EstateCluster_1 = require("@outreach/pages/OutboundProspecting/EstateCluster");
const Path_1 = require("@outreach/pages/OutboundProspecting/Path");
class GooglePolygon {
    constructor(data = {}) {
        this.polygon = new google.maps.Polygon();
        this.highlight = false;
        Object.assign(this, data);
    }
    static fromPath(paths, map, options = {}) {
        const polygon = new google.maps.Polygon(Object.assign({ map,
            paths, fillColor: config_1.brandColor, fillOpacity: 0.6, strokeWeight: 0, draggable: true }, options));
        return new GooglePolygon({ polygon, cluster: new EstateCluster_1.EstateCluster(map) });
    }
    static fromPolyline(polyline, map) {
        return GooglePolygon.fromPath(new Path_1.Path(polyline.getPath()).simplify(), map);
    }
    setPolygon(polygon) {
        this.polygon = polygon;
        return this;
    }
    setCluster(cluster) {
        this.cluster = cluster;
        return this;
    }
    onDrag(callback) {
        this.polygon.addListener('drag', callback);
        return this;
    }
    onDragEnd(callback) {
        this.polygon.addListener('dragend', callback);
        return this;
    }
    onClick(callback) {
        this.polygon.addListener('click', callback);
        return this;
    }
    getCenter() {
        const bounds = new google.maps.LatLngBounds();
        this.polygon.getPath().forEach((point) => bounds.extend(point));
        return bounds.getCenter();
    }
    sqMiles() {
        return new Path_1.Path(this.polygon.getPath()).sqMiles();
    }
    setPath(path) {
        if (!path) {
            return;
        }
        this.polygon.setPath(path);
    }
    getPath() {
        return this.polygon.getPath();
    }
    bindTo(position, marker, bindingArg) {
        this.polygon.bindTo(position, marker, bindingArg);
    }
    toString() {
        var _a;
        return (_a = this.polygon.getPath().getArray()
            .map(item => `${item.lat()},${item.lng()}`)
            .join(';')) !== null && _a !== void 0 ? _a : '';
    }
    toTuples() {
        return this.polygon.getPath().getArray()
            .map(item => [item.lat(), item.lng()]);
    }
    details() {
        return {
            path: this.toString(),
            centroid: this.getCenter(),
            sqMiles: this.sqMiles()
        };
    }
    setMap(map) {
        this.polygon.setMap(map);
    }
    setHighlight(highlight = true) {
        this.highlight = highlight;
        this.polygon.setOptions({
            fillColor: config_1.brandColor,
            fillOpacity: highlight ? 0.7 : 0.6,
            strokeWeight: highlight ? 5 : 3,
            strokeColor: highlight ? 'white' : config_1.brandColor,
            strokeOpacity: 1
        });
        return this;
    }
}
exports.GooglePolygon = GooglePolygon;
