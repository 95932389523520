<template>
    <div class="inline-flex">
        <v-custom-button
            ref="main-button"
            :label="label"
            :button-style="buttonStyle"
            :disabled="disabled"
            :outlined="outlined"
            :loading="loading"
            :size="size"
            @click="handleClick"
        >
            <template #before-label>
                <slot name="before-label"></slot>
            </template>
            <template #after-label>
                <slot name="after-label"></slot>
            </template>
        </v-custom-button>
        <modal v-if="confirm" :open="confirming" @close-modal="confirming = false">
            <template #content>
                <div class="w-modal">
                    {{ confirm }}
                    <slot name="confirmable-content"></slot>
                </div>
            </template>

            <template #modal-actions>
                <div class="p-2">
                    <v-custom-button
                        ref="cancel-button"
                        label="Cancel"
                        class="mr-2"
                        @click="handleCancel"
                    />
                    <v-custom-button
                        ref="confirm-button"
                        :label="label"
                        button-style="primary"
                        @click="handleConfirm"
                    />
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from '@outreach/components/shared/modal/index';
import VCustomButton from '@outreach/components/VCustomButton';

export default {
    components: { Modal, VCustomButton },
    props: {
        label: { type: String, default: null },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        buttonStyle: { type: String, default: 'default' },
        outlined: { type: Boolean, default: false },
        size: { type: String, default: 'md' },
        confirm: { type: String, default: null }
    },
    data: () => ({
        confirming: false
    }),
    methods: {
        handleClick (event) {
            if (this.confirm) {
                this.confirming = true;
                return;
            }
            this.$emit('click', event);
        },
        handleCancel () {
            this.confirming = false;
        },
        handleConfirm () {
            this.confirming = false;
            this.$emit('click');
        }
    }
};
</script>
