<template>
    <div class="flex items-center text-gray-800">
        <router-link class="text-brand-regular" :to="{ name: 'send-notes' }">
            Home
        </router-link>
        <template v-if="hasBreadcrumbs">
            <i class="fa fa-chevron-right text-gray-700 text-xs mx-2"></i>
            <template v-for="(route, index) in $route.meta.breadcrumbs">
                <router-link
                    :key="`link-${route.name}`"
                    :class="{ 'text-brand-regular': isLink(index), 'pointer-events-none': !isLink(index) }"
                    :disabled="!isLink(index)"
                    :to="{ name: route.name }"
                >
                    {{ route.label }}
                </router-link>
                <i
                    v-if="isLink(index)"
                    :key="`icon-${route.name}`"
                    class="fa fa-chevron-right text-xs text-gray-700 mx-2"
                >
                </i>
            </template>
        </template>
    </div>
</template>

<script>
import size from 'lodash/size';

export default {
    computed: {
        hasBreadcrumbs () {
            return size(this.$route.meta.breadcrumbs) >= 1;
        }
    },
    methods: {
        isLink (index) {
            return (index + 1) !== (this.$route.meta.breadcrumbs || []).length;
        }
    }
};
</script>
