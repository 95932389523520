<template>
    <v-slider
        :value="range"
        v-bind="attributes"
        :enable-cross="false"
        @input="emitValue"
    />
</template>
<script>
import VSlider from '@outreach/components/inputs/VSlider.vue';
import RangeConstraint from '@outreach/pages/OutboundProspecting/RangeConstraint';

export default {
    name: 'CountPlusSlider',
    components: { VSlider },
    props: {
        value: { type: Object, default: () => ({}) },
        field: { type: String, required: true },
        count: { type: Number, default: undefined },
        minValue: { type: Number, default: 0 },
        maxValue: { type: Number, default: undefined }
    },
    data () {
        return {
            range: [0, 0]
        };
    },
    computed: {
        attributes () {
            return this.count
                ? { data: this.steps }
                : { min: this.minValue, max: this.maxValue, minRange: 0, maxRange: this.maxValue, interval: 1 };
        },
        steps () {
            return [
                ...Array.from({ length: this.count }).map((_, step) => ({ label: step.toString(), value: step })),
                { label: `${this.count}+`, value: undefined }
            ];
        }
    },
    watch: {
        value: {
            handler (value) {
                this.range = new RangeConstraint(this.minValue, this.maxValue).toRange(value.min, value.max);
            },
            deep: true
        }
    },
    mounted () {
        this.initialize();
        this.emitValue(this.range);
    },
    methods: {
        initialize () {
            this.range = new RangeConstraint(this.minValue, this.maxValue)
                .toRange(this.$route.query[`${this.field}.min`], this.$route.query[`${this.field}.max`]);
        },
        emitValue (range) {
            const min = range[0] && range[0] !== this.minValue ? range[0] : undefined;
            const max = range[1] && range[1] !== this.minValue ? range[1] : undefined;
            this.$emit('input', { min, max });
        }
    }
};
</script>
