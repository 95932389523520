<template>
    <div class="flex flex-col md:flex-row items-center gap-2">
        <google-auto-complete
            v-if="loaded"
            ref="autocomplete"
            :hide-label="true"
            class="w-full md:flex-1"
            @selected="onAddress"
        />
        <v-input
            v-model.number="radius"
            type="number"
            label="Radius (miles)"
            class="w-full md:w-1/3"
            :disabled="!place"
            @input="emitUpdate"
        />
    </div>
</template>

<script>
import GoogleAutoComplete from '@outreach/pages/OutboundProspecting/GoogleAutoComplete.vue';
import VInput from '@outreach/components/inputs/VInput.vue';
import { loadGoogleMaps } from '@services/GoogleMaps';

export default {
    name: 'PropertyRadiusInput',
    components: { GoogleAutoComplete, VInput },
    data () {
        return {
            loaded: false,
            radius: 5,
            place: null
        };
    },
    async mounted () {
        await loadGoogleMaps('places');
        this.loaded = true;
    },
    methods: {
        onAddress (place) {
            this.place = place;
            this.emitUpdate();
        },
        emitUpdate () {
            this.$emit('selected', {
                location: {
                    lng: this.place.geometry.location.lng(),
                    lat: this.place.geometry.location.lat()
                },
                radius: this.radius
            });
        }
    }
};
</script>
