<template>
    <div class="ml-auto items-center flex md:flex md:text-center md:mr-4">
        <p class="md:flex-1 justify-center items-center uppercase mr-2 font-semibold text-xs text-gray-600 tracking-wider">Credits
            <router-link
                ref="credit-badge"
                class="inline-flex px-3 py-1 rounded text-sm font-medium"
                :class="color"
                :to="{ name: 'credits' }"
            >{{ user.account.credits }}</router-link>
        </p>
        <router-link :to="{ name: 'credits'}" class="flex-1">
            <v-button label="Buy More" button-style="primary" size="sm" />
        </router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VButton from '@outreach/components/VButton';

export default {
    name: 'Credits',
    components: { VButton },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        color () {
            const colors = {
                10: 'bg-red-200 text-red-900',
                100: 'bg-yellow-200 text-yellow-900',
                Infinity: 'bg-gray-200 text-gray-900'
            };
            const [, color] = Object.entries(colors).find(([amount]) => this.user.account.credits < amount);
            return color;
        }
    }
};
</script>
