<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1" label="Search" />
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading accounts</span>
            </div>
            <div v-else>
                <div v-if="accounts.length > 0">
                    <v-table
                        ref="accounts-table"
                        is-elevated
                        has-actions
                        clickable
                        :rows="accounts"
                        :sort="sorting"
                        @sort-changed="updateSort"
                        @clicked="edit"
                    >
                        <template #default="{ row }">
                            <v-table-column label="Name" value="name">
                                <div class="mt-2">
                                    <span><strong class="text-lg">{{ row.name }}</strong></span>
                                </div>
                            </v-table-column>
                            <v-table-column label="Users" value="users">
                                <div class="mt-2">
                                    <span
                                        v-for="user in row.users"
                                        :key="user.id"
                                        class="text-xs bg-gray-200 inline-block px-2 py-1 rounded mr-2"
                                    >
                                        {{ user.first_name }} {{ user.last_name }} ({{ user.email }})
                                    </span>
                                </div>
                            </v-table-column>
                            <v-table-column label="Available Credits" value="credits" sortable>
                                <span>{{ row.credits }}</span>
                            </v-table-column>
                            <v-table-column label="Recent Emails" value="transmissions_count" sortable>
                                <span>{{ row.transmissions_count }}</span>
                            </v-table-column>
                            <v-table-column label="Created Date" value="created_at" sortable>
                                <span>{{ formatDate(row.created_at) }}</span>
                                <span class="text-sm text-gray-700">{{ fromNow(row.created_at) }}</span>
                            </v-table-column>
                            <v-table-column label="Tags" value="tags">
                                <display-tags ref="tags" :tags="row.tagged" />
                            </v-table-column>
                        </template>
                        <template #dropdown="{ row }">
                            <button @click="edit(row)">Edit</button>
                            <button
                                v-if="user.account_id !== row.id"
                                :ref="`archive-button-${row.id}`"
                                @click="archive(row)"
                            >Archive</button>
                            <button @click="generateDeliveriesLink(row)">Generate Temporary Link</button>
                            <button @click="assignArticles(row)">Assign Articles</button>
                            <button @click="viewEmails(row)">View Emails</button>
                        </template>
                    </v-table>
                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getAccounts({ q: query, page: $event })"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No accounts found.</p>
                    </div>
                </div>
            </div>
        </transition>
        <modal header="Delivery Summary Link" :open="showDeliveriesLink" @close-modal="showDeliveriesLink = false">
            <template slot="content">
                <div class="flex w-modal">
                    <v-input
                        ref="deliveriesLink"
                        v-model="deliveriesLink"
                        class="w-full"
                        readonly
                        label="Sharable Link"
                    />
                </div>
            </template>
            <template slot="modal-actions">
                <div class="p-2">
                    <v-button
                        label="Close"
                        :disabled="loading"
                        :loading="loading"
                        button-style="primary"
                        class="mr-2"
                        @click="showDeliveriesLink = false"
                    />
                    <v-button
                        label="Copy to Clipboard"
                        :disabled="loading"
                        :loading="loading"
                        class="mr-2"
                        @click="copyDeliveriesLink"
                    />
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import services from '@services/Api';
import Modal from '@outreach/components/shared/modal/index';
import VLoader from '@outreach/components/VLoader';
import VPaginator from '@outreach/components/VPaginator';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import VButton from '@outreach/components/VButton';
import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { formatDate, fromNow } from '@services/Filters';
import VInput from '@outreach/components/inputs/VInput';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import DisplayTags from '@outreach/components/shared/DisplayTags.vue';

export default {
    components: {
        DisplayTags,
        SearchHeader,
        VLoader,
        VPaginator,
        VTable,
        VTableColumn,
        VButton,
        Modal,
        VInput
    },
    data: () => ({
        meta: {},
        accounts: [],
        selectedAccount: {},
        sorting: {
            key: 'created_at',
            direction: 'DESC'
        },
        showModal: false,
        showDeliveriesLink: false,
        deliveriesLink: null,
        formErrors: {},
        query: null
    }),
    computed: mapState({
        loading: ({ loader }) => loader.loading,
        user: ({ user }) => user.user,
        covers: ({ accounts }) => accounts.covers
    }),
    watch: {
        async sorting () {
            this.getAccounts();
        },
        showArchived () {
            this.getAccounts();
        },
        query (q) {
            this.debounceAccounts({ q });
        }
    },
    created () {
        this.getAccounts();
    },
    methods: {
        ...mapActions({
            getCovers: 'accounts/getCovers',
            removeCover: 'accounts/removeCover'
        }),
        formatDate,
        fromNow,
        debounceAccounts: debounce(function (params) {
            this.getAccounts(params);
        }, 500),
        async getAccounts (params = {}) {
            const { data } = await services.admin.accounts({
                ...params,
                sort_key: this.sorting.key,
                sort_direction: this.sorting.direction,
                include: 'users'
            });

            this.accounts = data.data;
            this.meta = data.meta;
        },
        updateSort (data) {
            this.sorting = data;
        },
        edit (account) {
            this.$router.push({ name: 'edit-account', params: { accountId: account.id } });
        },
        async archive (account) {
            const result = await dialogEmitter.confirm(
                'Archive Account',
                `Are you sure you want to archive ${account.name}?`
            );

            if (!result) return;

            await services.admin.archiveAccount(account.id);
            await this.getAccounts();
        },
        async generateDeliveriesLink (account) {
            const { data } = await services.admin.generateTemporaryUrl(account.id);
            this.deliveriesLink = data.data.url;
            this.showDeliveriesLink = true;
        },
        copyDeliveriesLink () {
            this.$refs.deliveriesLink.$el.querySelector('input').select();
            document.execCommand('copy');
        },
        assignArticles (account) {
            dialogEmitter.name('AssignArticlesDialog').props({ account }).wait();
        },
        viewEmails (account) {
            this.$router.push({ name: 'view-emails', params: { accountId: account.id } });
        }
    }
};
</script>
