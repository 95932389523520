import Vue from 'vue';

export let toast = {
    success: (...args) => console.log(args),
    error: (...args) => console.error(args)
};

if (Vue.prototype.$toasted) {
    toast = {
        success: (...args) => Vue.prototype.success(args),
        error: (...args) => Vue.prototype.error(args)
    };
}

if (process.env.NODE_ENV === 'test') {
    toast = {
        success: jest.fn(),
        error: jest.fn()
    };
}
