<template>
    <div>
        <div class="avatar -mt-4">
            <avatar :contact="contact" />
        </div>
        <div class="flex flex-col lg:flex-row">
            <div class="flex-1 flex-col lg:px-10">
                <h3 class="heading">Profile</h3>

                <v-input
                    ref="name-input"
                    label="Name"
                    :value="contact.name"
                    :errors="formErrors.name"
                    @input="update('name', $event)"
                />
                <div v-if="hasOriginalName" class="flex gap-1 text-sm">
                    <p class="text-gray-700">Original Input:</p>
                    <p>{{ contact.details.original_name }}</p>
                </div>

                <v-input
                    class="mt-4 mb-6"
                    label="Email"
                    :value="contact.email"
                    :errors="formErrors.email"
                    @input="update('email', $event)"
                />
                <v-input
                    class="mb-6"
                    label="Avatar URL"
                    :value="contact.avatar"
                    :errors="formErrors.avatar"
                    @input="update('avatar', $event)"
                />
                <v-input
                    class="mb-6"
                    label="External Id"
                    :value="contact.external_id"
                    :errors="formErrors.external_id"
                    @input="update('external_id', $event)"
                />
                <tag-select
                    class="mb-6"
                    :value="contact.tags || []"
                    :type="tagType"
                    @input="update('tags', $event)"
                />
                <v-textarea v-model="contact.note" label="Notes" :limit="500" :rows="5" />
            </div>

            <div class="flex-1 flex-col pt-2 lg:pt-0 lg:px-10">
                <h3 class="heading">Address</h3>

                <address-form
                    v-model="contact.address"
                    :form-errors="formErrors"
                    @input="$emit('update:contact', contact)"
                />

            </div>
        </div>

        <contact-form-details
            :audience-id="contact.audiences && contact.audiences.length > 0 ? contact.audiences[0].id : 0"
            :value="contact.details"
            :form-errors="formErrors.details"
            class="lg:px-10"
            @input="update('details', $event)"
        />
    </div>
</template>

<script>
import Avatar from '@outreach/components/shared/avatar';
import ContactFormDetails from '@outreach/components/contacts/ContactFormDetails';
import VTextarea from '@outreach/components/inputs/VTextarea';
import TagSelect from '@outreach/components/tag-select/TagSelect';
import VInput from '@outreach/components/inputs/VInput';
import AddressForm from '@outreach/pages/AddressForm';
import { TagType } from '@services/TagType';

export default {
    components: { AddressForm, VTextarea, TagSelect, Avatar, ContactFormDetails, VInput },
    props: {
        contact: { type: Object, default: null },
        formErrors: { type: Object, required: true }
    },
    computed: {
        hasOriginalName () {
            return this.contact && this.contact.details?.original_name;
        },
        tagType () {
            return TagType.CONTACTS;
        }
    },
    methods: {
        update (name, value) {
            const data = {
                ...this.contact,
                [name]: value
            };
            this.$emit('update:contact', data);
        }
    }
};
</script>

<style scoped>
.avatar {
    @apply mb-2 w-16 h-16 rounded-full mx-auto overflow-hidden bg-gray-300 border border-gray-300 lg:w-24 lg:h-24;
}
.heading {
    @apply text-lg mb-2 font-bold mx-auto;
}
</style>
