<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <div class="grid grid-cols-1 gap-3 w-full md:grid-cols-4">
                    <v-input v-model="query.street" label="Street" class="md:col-span-2" />
                    <v-button
                        ref="export-button"
                        class="md:col-span-2"
                        label="Export"
                        button-style="primary"
                        @click="exportProperties"
                    />
                    <v-input v-model="query.city" label="City" />
                    <multiselect
                        id="type-select"
                        placeholder="Property Type"
                        :value="query.types"
                        :options="propertyTypes"
                        :multiple="true"
                        @input="query.types = $event"
                    />
                    <multiselect
                        id="state-select"
                        placeholder="States"
                        :value="query.states"
                        :options="states"
                        :multiple="true"
                        @input="query.states = $event"
                    />
                    <multiselect
                        id="zip-code-select"
                        placeholder="ZIP"
                        :value="query.zip"
                        :options="zipCodes"
                        :multiple="true"
                        @input="query.zip = $event"
                    />
                    <multiselect
                        id="min-value-select"
                        placeholder="Min Value"
                        track-by="value"
                        label="name"
                        :value="minValue"
                        :options="ranges"
                        :multiple="false"
                        @input="updateMinValue($event.value)"
                    />
                    <multiselect
                        id="max-value-select"
                        placeholder="Max Value"
                        track-by="value"
                        label="name"
                        :value="maxValue"
                        :options="ranges"
                        :multiple="false"
                        @input="updateMaxValue($event.value)"
                    />
                </div>
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading properties</span>
            </div>
            <div v-else>
                <div v-if="properties.length > 0">
                    <div class="flex items-center justify-center py-2 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getProperties({ ...query, page: $event })"
                        />
                    </div>
                    <div class="px-4 py-3 text-gray-700 flex items-center font-regular">
                        <p>
                            Available Properties: {{ formatNumber((meta.total || 0)) }}
                            ({{ (meta.total/meta.total_count * 100).toFixed(2) }}% available)
                        </p>
                    </div>
                    <property-card
                        v-for="(row, index) in properties"
                        :key="index"
                        :properties="properties"
                        :property="row"
                    />
                    <div class="flex items-center justify-center py-2 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getProperties({ ...query, page: $event })"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No properties found.</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import services from '@services/Api';
import VLoader from '@outreach/components/VLoader';
import VPaginator from '@outreach/components/VPaginator';
import { mapState } from 'vuex';
import { debounce, range } from 'lodash';
import { Multiselect } from 'vue-multiselect';
import { csvDownloader, formatNumber, formatToCurrency } from '@services/Utils';
import PropertyCard from '@outreach/pages/sales/PropertyCard';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import SearchHeader from '@outreach/components/shared/SearchHeader';

export default {
    components: {
        PropertyCard,
        VLoader,
        VPaginator,
        Multiselect,
        VButton,
        VInput,
        SearchHeader
    },
    data: () => ({
        loading: true,
        meta: {},
        properties: [],
        sorting: {
            key: 'created_at',
            direction: 'DESC'
        },
        formErrors: {},
        query: { states: [], est_value: {}, zip: [], types: [] },
        states: [
            'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE',
            'FL', 'FM', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS',
            'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MP',
            'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY',
            'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN',
            'TX', 'UM', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
        ],
        zipCodes: [],
        propertyTypes: [
            'UTL', 'LND', 'RES', 'AGR', 'COM', 'SFR', 'GOV', 'OTH',
            'REC', 'CND', 'APT', 'IND', 'TRA', 'UNK', 'MFR'
        ]
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        ranges () {
            return range(0, 10000000, 100000).map(step => ({ value: step, name: formatToCurrency(step) }));
        },
        minValue () {
            const min = this.query.est_value.min;
            return min ? { value: min, name: formatToCurrency(min) } : null;
        },
        maxValue () {
            const max = this.query.est_value.max;
            return max ? { value: max, name: formatToCurrency(max) } : null;
        }
    },
    watch: {
        async sorting () {
            this.getProperties();
        },
        showArchived () {
            this.getProperties();
        },
        query: {
            handler (q) {
                this.debounceAccounts(q);
            },
            deep: true
        }
    },
    created () {
        this.getProperties();
        this.getZipCodes();
    },
    methods: {
        formatNumber,
        debounceAccounts: debounce(function (params) {
            this.getProperties(params);
        }, 500),
        async getProperties (params = {}) {
            this.loading = true;
            const { data } = await services.properties.list({
                ...params,
                sort_key: this.sorting.key,
                sort_direction: this.sorting.direction
            });

            this.properties = data.data;
            this.meta = { ...data.meta, ...{ total_count: data.total_count } };
            this.loading = false;
        },
        async getZipCodes () {
            const { data } = await services.properties.zipCodes();
            this.zipCodes = data.data.zip_codes;
        },
        async exportProperties () {
            const { data } = await services.properties.exportProperties(this.query);
            csvDownloader(data);
        },
        updateSort (data) {
            this.sorting = data;
        },
        updateMinValue (value) {
            this.query.est_value = { ...this.query.est_value, min: value };
        },
        updateMaxValue (value) {
            this.query.est_value = { ...this.query.est_value, max: value };
        }
    }
};
</script>
