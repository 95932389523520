<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <div class="flex flex-col w-full md:flex-row md:flex-wrap">
                    <v-input v-model="query" class="mb-2 mr-0 flex-1 sm:mb-0 sm:mr-2" label="Search" />
                    <v-multiple-select
                        ref="add-users-select"
                        label="label"
                        :loading="loading"
                        :options="users.map(user => ({ id: user.id, label: user.name }))"
                        :internal-search="true"
                        :value="reps"
                        placeholder="Select Associate"
                        @search-change="listReps($event)"
                        @input="reps = $event"
                    />
                    <div class="md:w-full">
                        <v-checkbox v-model="showArchived" label="Show Archived" class="mt-4" />
                    </div>
                </div>
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading report&hellip;</span>
            </div>
            <div v-else>
                <div v-if="reports.length > 0">
                    <paginator-wrap :change-page="changePage" :pagination="meta">
                        <template #content>
                            <v-table is-elevated :rows="reports">
                                <template #default="{ row }">
                                    <v-table-column label="Name" value="name">
                                        <div class="mt-2">
                                            <p><strong class="text-lg">
                                                {{ row.first_name }} {{ row.last_name }}
                                            </strong></p>
                                            <p class="text-xs inline-block text-gray-700">
                                                <a :href="`mailto:${row.email}`" target="_blank">
                                                    <i class="fa fa-envelope ml-auto"></i> {{ row.email }}
                                                </a>
                                            </p>
                                        </div>
                                    </v-table-column>
                                    <v-table-column
                                        label="Properties Reserved"
                                        value="reserved"
                                        col-align="text-center"
                                    >
                                        <span class="text-sm px-2 py-1 rounded mr-2 bg-gray-200">
                                            {{ row.reserved }}
                                        </span>
                                    </v-table-column>
                                    <v-table-column label="Notes Sent" value="sent" col-align="text-center">
                                        <span class="text-sm px-2 py-1 rounded mr-2 bg-gray-200">
                                            {{ row.sent }}
                                        </span>
                                    </v-table-column>
                                    <v-table-column
                                        label="# of Days Since Last Send"
                                        value="last_sent"
                                        col-align="text-center"
                                    >
                                        <div class="text-xl font-normal">
                                            <div v-if="row.last_sent" v-tooltip="formatDate(row.last_sent)">
                                                {{ daysAgo(row.last_sent) }}
                                            </div>
                                            <div v-else>N/A</div>
                                        </div>
                                    </v-table-column>
                                    <v-table-column value="associate" label="Associate">
                                        <p>{{ row.associate }}</p>
                                    </v-table-column>
                                </template>
                            </v-table>
                        </template>
                    </paginator-wrap>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No reports found.</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import VLoader from '@outreach/components/VLoader';
import { VTable, VTableColumn } from '@outreach/components/shared/table';
import { mapActions, mapGetters } from 'vuex';
import services from '@services/Api';
import { formatDate } from '@services/Filters';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import debounce from 'lodash/debounce';
import VInput from '@outreach/components/inputs/VInput';
import moment from 'moment/moment';
import VMultipleSelect from '@outreach/components/inputs/VMultipleSelect';
import VCheckbox from '@outreach/components/inputs/VCheckbox';
import { Roles } from '@services/Roles';
import SearchHeader from '@outreach/components/shared/SearchHeader';

export default {
    name: 'LastSentReport',
    components: {
        SearchHeader,
        VLoader,
        VTable,
        VTableColumn,
        PaginatorWrap,
        VInput,
        VMultipleSelect,
        VCheckbox
    },
    data: () => ({
        reports: [],
        meta: {},
        query: null,
        formErrors: {},
        reps: [],
        users: [],
        showArchived: undefined
    }),
    computed: {
        ...mapGetters({
            loading: 'loader/isLoading'
        })
    },
    watch: {
        query: debounce(async function () {
            await this.getLastSent();
        }, 400),
        reps: debounce(async function () {
            await this.getLastSent();
        }, 400),
        showArchived: debounce(async function () {
            await this.getLastSent();
        }, 400)
    },
    async created () {
        await this.getLastSent();
    },
    methods: {
        ...mapActions({
            setMessage: 'alerts/setMessage',
            clearMessages: 'alerts/clearAll'
        }),
        async getLastSent (params = {}) {
            this.formErrors = {};
            this.clearMessages();
            try {
                const { data } = await services.admin.lastSent({
                    ...params,
                    reps: this.reps.map(rep => rep.id) || undefined,
                    q: this.query,
                    archived: this.showArchived
                });
                this.reports = data.data;
                this.meta = data.meta;
            } catch (error) {
                if (((error.response || {}).data || {}).errors) {
                    this.formErrors = error.response.data.errors;
                }
                this.setMessage({ messageType: 'error', message: 'Unable to load report. Please try again!' });
            }
        },
        async changePage (page) {
            await this.getLastSent({ page });
        },
        async listReps (q = undefined) {
            const { data } = await services.admin.listUsers({ roles: [Roles.CUSTOMER_SERVICE], q });
            this.users = data.data;
        },
        daysAgo (value) {
            if (!value) return;
            return moment().diff(moment.utc(value, 'YYYY-MM-DD HH:mm:ss'), 'd');
        },
        formatDate
    }
};
</script>
