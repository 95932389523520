<template>
    <nav class="bg-white border-b border-gray-300 justify-between items-center sticky top-0 z-50">
        <div class="flex flex-row justify-between items-center">
            <div class="flex items-center md:w-nav">
                <div class="cursor-pointer p-6 md:hidden" @click.stop="$emit('click:menu')">
                    <i class="fa fa-bars text-2xl text-gray-600"></i>
                </div>
                <router-link aria-label="Home" :to="{ name: 'send-notes' }" class="hover:bg-white p-4 pl-0 md:pl-4">
                    <img src="/images/logo-orange-red.png" width="138" alt="Audience Logo" />
                </router-link>
            </div>
            <div class="flex flex-row md:flex-1 justify-between items-center pr-2">
                <div class="px-3 text-center hidden md:block">
                    <router-link
                        aria-label="Sent Today"
                        :to="{ name: 'order-history' }"
                        class="hover:bg-white"
                    >
                        Sent Today: <span>{{ user.sent_today || 0 }}</span>
                    </router-link>
                </div>

                <credits class="hidden md:block" />
                <div
                    class="px-3 py-1 rounded mr-2 hover:bg-gray-100 cursor-pointer"
                    :class="{ 'bg-red-500 hover:bg-red-400 text-white': imposter, 'text-gray-600': !imposter }"
                >
                    <dropdown :is-icon="false">
                        <template slot="trigger" slot-scope="showDropdown">
                            <div class="flex flex-row-reverse md:flex-row items-center h-12 -mx-2">
                                <div class="flex items-center mx-2 py-2">
                                    <div class="h-8 w-8 border border-gray-300 rounded-full overflow-hidden">
                                        <avatar
                                            :alt="user.name"
                                            :contact="{ name: user.name, email: user.email }"
                                            :size="150"
                                            default-img="mm"
                                        />
                                    </div>
                                    <div
                                        class="md:flex flex-col ml-2 hidden"
                                    >
                                        <span v-if="imposter" class="text-xs -mb-1">Impersonating</span>
                                        <span class="">{{ user.name }}</span>
                                    </div>
                                </div>
                                <i
                                    class="fa text-sm fa-chevron-down mx-2 transition-all"
                                    :class="{ 'transform-rotate-x180': showDropdown.slotScope }"
                                ></i>
                            </div>
                        </template>

                        <template slot="content">
                            <router-link :to="{ name: 'user' }">Profile</router-link>
                            <button @click="logout">Logout</button>
                            <button
                                v-if="imposter"
                                id="stop-impersonating"
                                @click="stopImpersonating"
                            >
                                Stop Impersonating
                            </button>
                        </template>
                    </dropdown>
                </div>
                <div>
                    <notification-center />
                </div>
            </div>
        </div>
        <div class="flex items-center flex-row bg-gray-300 justify-between p-4 md:hidden">
            <div class="text-center">
                <router-link
                    aria-label="Sent Today"
                    :to="{ name: 'order-history' }"
                    class="hover:bg-white"
                >
                    Sent Today: <span>{{ user.sent_today || 0 }}</span>
                </router-link>
            </div>
            <credits class="" />
        </div>
    </nav>
</template>

<script>
import { mapState } from 'vuex';
import Avatar from '@outreach/components/shared/avatar/index';
import Dropdown from '@outreach/components/shared/dropdown/index';
import services from '@services/Api';
import Credits from '@outreach/components/account/Credits';
import NotificationCenter from '@outreach/components/notification/NotificationCenter.vue';

export default {
    components: { NotificationCenter, Dropdown, Avatar, Credits },
    computed: mapState({
        user: ({ user }) => user.user,
        imposter: ({ user }) => user.imposter
    }),
    methods: {
        async logout () {
            await services.authentication.logout();
            this.$router.push({ name: 'login' });
        },
        async stopImpersonating () {
            await services.authentication.stopImpersonating();
            this.$router.push({ name: 'send-notes' });
        }
    }
};
</script>
