"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ToggleMode;
(function (ToggleMode) {
    ToggleMode["PAN"] = "pan";
    ToggleMode["SELECT"] = "select";
})(ToggleMode || (ToggleMode = {}));
class SelectToggle {
    constructor() {
        this.button = null;
        this.container = undefined;
        this.mode = { name: ToggleMode.SELECT, class: 'fa-hand' };
        this.modes = [{ name: ToggleMode.SELECT, class: 'fa-hand' }, { name: ToggleMode.PAN, class: 'fa-grid' }];
    }
    onAdd(map) {
        this.container = this.createElement('div', 'mapboxgl-ctrl-group mapboxgl-ctrl');
        this.button = this.createButton('toggle-button', 'fa-solid fa-hand', () => {
            this.toggle();
            this.isPanning() ? map.dragPan.enable() : map.dragPan.disable();
        });
        this.mode = this.modes[0];
        this.disable();
        return this.container;
    }
    onRemove() {
        var _a, _b;
        (_b = (_a = this.container) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.removeChild(this.container);
    }
    isPanning() {
        return this.mode.name === ToggleMode.PAN;
    }
    setState(active, map) {
        if (active) {
            this.enable();
            this.isPanning() ? map.dragPan.enable() : map.dragPan.disable();
            return;
        }
        map.dragPan.enable();
        this.disable();
    }
    enable() {
        var _a, _b;
        (_a = this.button) === null || _a === void 0 ? void 0 : _a.removeAttribute('disabled');
        (_b = this.button) === null || _b === void 0 ? void 0 : _b.classList.remove('text-gray-300');
    }
    disable() {
        var _a, _b;
        (_a = this.button) === null || _a === void 0 ? void 0 : _a.setAttribute('disabled', 'disabled');
        (_b = this.button) === null || _b === void 0 ? void 0 : _b.classList.add('text-gray-300');
    }
    createButton(className, icon, fn) {
        const button = this.createElement('button', className, this.container);
        button.type = 'button';
        button.addEventListener('click', fn);
        this.createElement('i', icon, button);
        return button;
    }
    toggle() {
        var _a;
        const icon = (_a = this.container) === null || _a === void 0 ? void 0 : _a.querySelector('i');
        const previous = this.mode;
        this.mode = this.modes.find(m => m.name !== this.mode.name);
        icon === null || icon === void 0 ? void 0 : icon.classList.replace(previous.class, this.mode.class);
    }
    createElement(tagName, className, container) {
        const el = window.document.createElement(tagName);
        if (className !== undefined)
            el.className = className;
        if (container)
            container.appendChild(el);
        return el;
    }
}
exports.default = SelectToggle;
