<template>
    <div>
        <img class="border border-gray-500 border-dashed w-full" :src="letter" alt="Letter and Envelope" />
    </div>
</template>
<script>
export default {
    props: {
        letter: {
            type: String,
            default: null
        }
    }
};
</script>
