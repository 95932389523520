<template>
    <div>
        <search-header>
            <template #mobile-header>
                <v-input v-model="q" label="Search" class="w-full" />
            </template>
            <template #content>
                <v-input v-model="q" label="Search" class="w-full hidden md:block" />
            </template>
        </search-header>
        <v-loader v-if="loading" :size="42" />
        <paginator-wrap v-if="emails.length" :change-page="changePage" :pagination="pagination">
            <template #content>
                <sent-email-table :emails="emails" @sort="list({...$event, q })" />
            </template>
        </paginator-wrap>
        <div v-else-if="!loading && emails.length === 0">
            <p class="text-center text-gray-700 text-lg">Account has no Sent Emails</p>
        </div>
    </div>
</template>

<script>
import services from '@services/Api';
import SentEmailTable from '@outreach/components/account/SentEmailTable';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import VInput from '@outreach/components/inputs/VInput';
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import VLoader from '@outreach/components/VLoader';

export default {
    name: 'ViewEmails',
    components: { SentEmailTable, PaginatorWrap, SearchHeader, VInput, VLoader },
    data: () => ({
        emails: [],
        pagination: {},
        q: undefined
    }),
    computed: {
        ...mapState({
            loading: ({ loader }) => loader.loading
        }),
        accountId () {
            return this.$route.params.accountId;
        }
    },
    watch: {
        q (searchValue) {
            this.list({ q: searchValue });
        }
    },
    mounted () {
        this.list();
    },
    methods: {
        list: debounce(async function (params = {}) {
            const { data } = await services.admin.account.listEmails(this.accountId, params);
            this.emails = data.data;
            this.pagination = data.meta;
        }, 500),
        changePage (page) {
            this.list({ page, q: this.q });
        }
    }
};
</script>
