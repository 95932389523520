"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Place {
    constructor(place) {
        this.place = place;
    }
    toCentroid() {
        var _a, _b, _c, _d;
        return {
            lat: (_b = (_a = this.place.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat(),
            lng: (_d = (_c = this.place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lng()
        };
    }
    toString() {
        var _a, _b, _c;
        const number = (_a = this.place.address_components) === null || _a === void 0 ? void 0 : _a.find((component) => component.types.includes('street_number'));
        const street = (_b = this.place.address_components) === null || _b === void 0 ? void 0 : _b.find((component) => component.types.includes('route'));
        const sub = (_c = this.place.address_components) === null || _c === void 0 ? void 0 : _c.find((component) => component.types.includes('subpremise'));
        return `${number === null || number === void 0 ? void 0 : number.short_name} ${street === null || street === void 0 ? void 0 : street.short_name}${sub ? ` ${sub.short_name}` : ''}`;
    }
}
exports.default = Place;
