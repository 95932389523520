import { render, staticRenderFns } from "./VList.vue?vue&type=template&id=79e1db3e&scoped=true&"
import script from "./VList.vue?vue&type=script&lang=js&"
export * from "./VList.vue?vue&type=script&lang=js&"
import style0 from "./VList.vue?vue&type=style&index=0&id=79e1db3e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e1db3e",
  null
  
)

export default component.exports