"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FreeDrawMode = void 0;
const GooglePolygon_1 = require("@outreach/pages/OutboundProspecting/GooglePolygon");
class FreeDrawMode {
    constructor(map) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.mouseUpCallback = () => { };
        this.map = map;
    }
    onComplete(callback) {
        this.mouseUpCallback = callback;
        return this;
    }
    disable() {
        google.maps.event.clearListeners(this.map, 'mousedown');
        google.maps.event.clearListeners(this.map, 'mouseup');
        this.map.setOptions({ gestureHandling: 'auto' });
    }
    enable(onPolygonComplete) {
        let polyline;
        this.map.addListener('mousedown', () => {
            document.body.classList.add('overflow-y-hidden');
            polyline = new google.maps.Polyline({
                clickable: false,
                map: this.map,
                strokeColor: '#ff731f',
                strokeWeight: 5
            });
            this.map.addListener('mousemove', (e) => {
                if (e.latLng) {
                    polyline.getPath().push(e.latLng);
                }
            });
        });
        this.map.addListener('mouseup', () => {
            document.body.classList.remove('overflow-y-hidden');
            google.maps.event.clearListeners(this.map, 'mousemove');
            this.mouseUpCallback();
            polyline.setMap(null);
            if (!polyline.getPath().getLength())
                return;
            const polygon = GooglePolygon_1.GooglePolygon.fromPolyline(polyline, this.map);
            onPolygonComplete(polygon);
        });
        this.map.setOptions({ gestureHandling: 'none' });
    }
}
exports.FreeDrawMode = FreeDrawMode;
