<template>
    <div style="width: 100%">
        <template v-if="percent">{{ Math.round(completed / total * 100) }}%</template>
        <div class="bg-gray-400 rounded w-full" :class="height">
            <div class="bg-orange-400 rounded" :class="height" :style="`width: ${progressBarWidth}%;`"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ProgressBar',
    props: {
        total: { type: Number, default: 0 },
        completed: { type: Number, default: 0 },
        small: { type: Boolean, default: false },
        percent: { type: Boolean, default: true }
    },
    computed: {
        progressBarWidth () {
            if (this.total === 0) {
                return 0;
            }
            return (this.completed / this.total) * 100;
        },
        height () {
            return this.small ? 'h-1' : 'h-5';
        }
    }
};
</script>

<style scoped>

</style>
