"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoWindow = void 0;
class InfoWindow {
    constructor() {
        this.label = '';
    }
    build(options = {}) {
        return new google.maps.InfoWindow(Object.assign({ content: this.getContent(), ariaLabel: this.label }, options));
    }
}
exports.InfoWindow = InfoWindow;
