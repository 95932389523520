<template>
    <div class="container mx-auto p-8">

        <search-header>
            <template #content>
                <v-input v-model="query" class="flex-1 mb-2 md:mb-0 md:mr-2" label="Search" />
                <router-link :to="{ name: 'add-shared-templates'}">
                    <v-button ref="add-template-button" class="w-full" label="Add template" button-style="primary" />
                </router-link>
            </template>
        </search-header>

        <v-loader v-if="loading" />
        <div v-else>
            <div v-if="rows.length > 0">
                <div class="flex items-center justify-center py-4 px-2">
                    <v-paginator
                        v-if="meta"
                        class="flex-none"
                        :total="meta.last_page"
                        :value="meta.current_page"
                        @input="changePage"
                    />
                </div>
                <v-list
                    ref="list"
                    :rows="rows"
                    class="bg-white shadow-md rounded"
                >
                    <template #default="{ row }">
                        <div class="flex flex-row justify-between items-center w-full p-2">
                            <dl>
                                <dt class="text-lg">{{ row.name }}</dt>
                                <dd class="mb-2 text-gray-700">{{ row.description }}</dd>
                                <dd class="font-normal">{{ row.content }}</dd>
                            </dl>
                            <div class="flex flex-col -my-1 ml-4">
                                <router-link :to="{ name: 'edit-shared-templates', params: { template: row.id }} ">
                                    <v-button
                                        :ref="`edit-template-button-${row.id}`"
                                        class="my-1"
                                        button-style="default"
                                        :outlined="true"
                                        label="Edit"
                                    />
                                </router-link>
                                <v-button
                                    :ref="`delete-template-button-${row.id}`"
                                    class="my-1"
                                    button-style="error"
                                    label="Delete"
                                    confirm="Are you sure you want to delete this template?"
                                    @click="deleteTemplate(row)"
                                />
                                <div class="flex justify-center mt-3">
                                    <div class="layout-tag">
                                        {{ layoutLabels[row.layout_type] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </v-list>
                <div class="flex items-center justify-center py-4 px-2">
                    <v-paginator
                        v-if="meta"
                        class="flex-none"
                        :total="meta.last_page"
                        :value="meta.current_page"
                        @input="changePage"
                    />
                </div>
            </div>
            <div v-else class="text-center">
                <p class="text-lg text-gray-700">No templates found.</p>
            </div>
        </div>
    </div>
</template>

<script>
import VList from '@outreach/components/shared/VList';
import VPaginator from '@outreach/components/VPaginator';
import { mapActions, mapGetters } from 'vuex';
import VLoader from '@outreach/components/VLoader';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import { LayoutLabels } from '@services/Layout';
import SearchHeader from '@outreach/components/shared/SearchHeader';

export default {
    components: { VList, VPaginator, VLoader, VButton, VInput, SearchHeader },
    data: () => ({
        query: null
    }),
    computed: {
        ...mapGetters({
            rows: 'templates/list',
            meta: 'templates/pagination',
            loading: 'templates/loading'
        }),
        layoutLabels: () => LayoutLabels
    },
    watch: {
        query (q) {
            this.list({ q });
        }
    },
    created () {
        this.list();
    },
    methods: {
        ...mapActions({
            list: 'templates/index',
            delete: 'templates/delete',
            setLoading: 'templates/setLoading'
        }),
        changePage (page) {
            this.list({ q: this.query, page });
        },
        async deleteTemplate (template) {
            try {
                await this.delete(template.id);
            } catch (error) {
                this.$toasted.error('The template could not be deleted.');
            }
        }
    }
};
</script>

<style scoped>
.layout-tag {
    @apply inline-flex grow-0 items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800;
}
</style>
