<template>
    <div class="w-7"><img :src="path" title="Audience" /></div>
</template>

<script>
import { logoPath } from '@global/config';

export default {
    name: 'VLogo',
    data () {
        return { path: logoPath };
    }
};
</script>
