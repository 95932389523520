<template>
    <v-button
        v-if="showRestore"
        ref="restore-button"
        confirm="Are you sure you want to undo all changes?"
        :label="label"
        :button-style="buttonStyle"
        @click="$emit('restore', restorePoint)"
    />
</template>

<script>
import { isEqual } from 'lodash';
import VButton from '@outreach/components/VButton';

export default {
    name: 'RestoreButton',
    components: { VButton },
    props: {
        value: { required: false, type: Object, default: () => ({}) },
        label: { required: false, type: String, default: 'Undo' },
        buttonStyle: { required: false, type: String, default: 'info' }
    },
    data () {
        return {
            restorePoint: undefined
        };
    },
    computed: {
        showRestore () {
            return !isEqual(this.restorePoint, this.value);
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (value) {
                if (this.restorePoint || !value) return;

                this.restorePoint = JSON.parse(JSON.stringify(value));
            }
        }
    }
};
</script>
