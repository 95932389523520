"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeaheadOptions = void 0;
const lodash_1 = require("lodash");
class TypeaheadOptions {
    constructor() {
        this._options = {
            contact: [{ address: ['address1', 'address2'] }],
            account: ['name', 'website'],
            user: ['first_name', 'last_name', 'email', 'phone'],
            address: ['address', 'address1', 'address2', 'city', 'state', 'zip', 'country']
        };
    }
    get values() {
        return this._options;
    }
    merge(obj) {
        this._options = Object.assign(Object.assign({}, this._options), obj);
        return this.values;
    }
    static joinPath(first, second) {
        return [first, second].filter(n => n).join('.');
    }
    objectToDrilldownArray(options, path) {
        return Object.keys(options).map((key) => {
            return {
                key,
                path: TypeaheadOptions.joinPath(path, key),
                children: this.arrayToDrilldownArray(options[key], TypeaheadOptions.joinPath(path, key))
            };
        });
    }
    arrayToDrilldownArray(subOptions, path) {
        if (isOptions(subOptions)) {
            return subOptions.map((key) => {
                return { key, path: TypeaheadOptions.joinPath(path, key) };
            });
        }
        return (0, lodash_1.flatten)(subOptions.map((value) => {
            return this.objectToDrilldownArray(value, path);
        }));
    }
}
exports.TypeaheadOptions = TypeaheadOptions;
function isOptions(options) {
    return Array.isArray(options) && options.length > 0 && typeof options[0] === 'string';
}
