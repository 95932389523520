"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleMarkerClusterBuilder = void 0;
const markerclusterer_1 = require("@googlemaps/markerclusterer");
class GoogleMarkerClusterBuilder {
    setMarkers(markers) {
        this.markers = markers;
        return this;
    }
    forMap(map) {
        this.map = map;
        return this;
    }
    build(options = {}) {
        return new markerclusterer_1.MarkerClusterer(Object.assign({ map: this.map, markers: this.markers }, options));
    }
}
exports.GoogleMarkerClusterBuilder = GoogleMarkerClusterBuilder;
