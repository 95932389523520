export const withLoadingState = async (commit, callback) => {
    commit('setLoading', true);
    await callback();
    commit('setLoading', false);
};

export const handleErrors = (error, commit) => {
    if (!error.response) {
        throw error;
    }
    if (error.response && error.response.data && error.response.data.errors) {
        commit('setFormErrors', error.response.data.errors);
    }
};
