<template>
    <div class="relative">
        <div v-if="editor" class="flex items-center border-b bg-white flex-wrap">
            <div class="pr-5">
                <label for="typography" class="sr-only">Typography</label>
                <select
                    id="typography"
                    name="typography"
                    class="typography-select"
                    @change="handleSelect"
                >
                    <option
                        v-for="(option, index) in dropdownOptions"
                        :key="index"
                        :value="option.value"
                    >
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div
                v-for="(buttonGroup, buttonGroupIndex) in toolbarButtons"
                :key="buttonGroupIndex"
                ref="toolbar"
                class="border-l border-gray-200 py-3 px-2"
            >
                <button
                    v-for="(button, index) in buttonGroup.options"
                    :key="index"
                    :ref="button.label"
                    class="px-3"
                    :aria-label="button.label"
                    @click="editorChain(button.label)"
                >
                    <i class="fa" :class="button.icon"></i>
                </button>
            </div>

        </div>
        <div v-if="linkRange" class="flex border-b">
            <form
                ref="link-form"
                class="flex gap-2 p-2 mx-auto w-full"
                @submit.prevent="setLink()"
                @reset="setLink('')"
            >
                <input
                    ref="link-input"
                    v-model="linkValue"
                    type="text"
                    class="w-full border rounded p-2"
                    placeholder="https://example.com"
                />
                <button
                    ref="submit-link"
                    type="submit"
                    class="bg-brand-regular text-white py-2 px-4 rounded"
                >Set</button>
                <button type="reset" class="bg-gray-400 py-2 px-4 rounded">Clear</button>
            </form>
        </div>
        <editor-content class="prose max-w-full px-8" :editor="editor" />
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import { Interpolation } from '@outreach/components/services/rich-text-plugins/interpolation';
import { Placeholder } from '@tiptap/extension-placeholder';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { mapState } from 'vuex';

export default {
    name: 'RichTextEditor',
    components: {
        EditorContent
    },
    props: {
        value: { type: String, default: '' },
        suggestions: { type: Array, default: () => [] }
    },
    data () {
        return {
            editor: null,
            linkRange: null,
            linkValue: ''
        };
    },
    computed: {
        ...mapState({
            account_id: ({ user }) => user.user.account_id
        }),
        dropdownOptions () {
            if (!this.editor) return [];
            return [
                { label: 'Paragraph', value: 'p' },
                { label: 'Heading 1', value: 'h1' },
                { label: 'Heading 2', value: 'h2' },
                { label: 'Heading 3', value: 'h3' }
            ];
        },
        toolbarButtons () {
            if (!this.editor) return [];
            return [
                {
                    options: [
                        { label: 'toggleBold', icon: 'fa-bold' },
                        { label: 'toggleItalic', icon: 'fa-italic' },
                        { label: 'toggleUnderline', icon: 'fa-underline' },
                        { label: 'toggleStrike', icon: 'fa-strikethrough' }
                    ]
                },
                {
                    options: [
                        { label: 'toggleBulletList', icon: 'fa-list' },
                        { label: 'toggleOrderedList', icon: 'fa-list-ol' }
                    ]
                },
                {
                    options: [
                        { label: 'toggleBlockquote', icon: 'fa-quote-right' },
                        { label: 'toggleLinkMenu', icon: 'fa-link' }
                    ]
                },
                {
                    options: [
                        { label: 'fileUpload', icon: 'fa-image' },
                        { label: 'toggleCodeBlock', icon: 'fa-code' }
                    ]
                },
                {
                    options: [
                        { label: 'undo', icon: 'fa-undo' },
                        { label: 'redo', icon: 'fa-redo' }
                    ]
                }
            ];
        }
    },
    watch: {
        value (value) {
            if (this.editor.getHTML() === value) {
                return;
            }
            this.editor.commands.setContent(value, false);
        }
    },
    mounted () {
        this.initializeEditor();
    },
    beforeDestroy () {
        this.editor.destroy();
    },
    methods: {
        initializeEditor () {
            this.editor = new Editor({
                editorProps: {
                    attributes: {
                        class: 'p-2'
                    }
                },
                content: this.value,
                extensions: [
                    StarterKit,
                    Underline,
                    Image,
                    Link.configure({ openOnClick: false }),
                    Interpolation.configure({
                        suggestion: {
                            items: ({ query }) => {
                                return this.suggestions
                                    .filter(item => item.toLowerCase().startsWith(query.toLowerCase()))
                                    .slice(0, 5);
                            }
                        }
                    }),
                    Placeholder.configure({ placeholder: 'Start writing your article...' })
                ],
                onUpdate: () => {
                    this.$emit('input', this.editor.getHTML());
                }
            });
        },
        fileUpload () {
            dialogEmitter
                .name('ImageLibraryDialog')
                .addProp('onSelect', (image) => {
                    this.editor.chain().focus().setImage({ src: image.location }).run();
                })
                .addProp('accountId', this.account_id)
                .wait();
        },
        toggleLinkMenu () {
            if (!this.linkRange) {
                this.linkRange = this.editor.chain().focus().extendMarkRange('link');
                this.linkValue = this.editor.getAttributes('link').href || 'https://';
                return;
            }
            this.linkRange = null;
        },
        setLink (linkUrl = this.linkValue) {
            if (linkUrl === '') {
                this.linkRange.unsetLink().run();
                this.linkRange = null;
                return;
            }
            this.linkRange.setLink({ href: linkUrl }).run();
            this.linkRange = null;
        },
        editorChain (operator) {
            if (operator === 'toggleLinkMenu') return this.toggleLinkMenu();
            if (operator === 'fileUpload') return this.fileUpload();
            this.editor.chain().focus()[operator]().run();
        },
        handleSelect (event) {
            switch (event.target.value) {
                case 'h1':
                    this.editor.chain().focus().toggleHeading({ level: 1 }).run();
                    break;
                case 'h2':
                    this.editor.chain().focus().toggleHeading({ level: 2 }).run();
                    break;
                case 'h3':
                    this.editor.chain().focus().toggleHeading({ level: 3 }).run();
                    break;
                default:
                    this.editor.chain().focus().setParagraph().run();
            }
        }
    }
};
</script>

<style scoped>

.ProseMirror:focus {
    outline: none;
}

.ProseMirror p.is-editor-empty:first-child:before {
    content: attr(data-placeholder);
    @apply text-gray-500 pointer-events-none h-0 float-left;
}

[data-type='interpolation'] {
    @apply border rounded py-1 px-2 bg-gray-200;
}

.typography-select {
    @apply block w-full pl-3 pr-10 py-3 px-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md;
}

</style>
