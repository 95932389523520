import * as accounts from './Accounts';
import * as admin from './Admin';
import * as authentication from './Authentication';
import * as addresses from './Addresses';
import * as campaigns from './Campaigns';
import * as cards from './Cards';
import * as fonts from './Fonts';
import * as audiences from './Audiences';
import * as letters from './Letters';
import * as campaignContacts from './CampaignContacts';
import * as contacts from './Contacts';
import * as queues from './Queues';
import * as user from './User';
import * as orders from './Orders';
import * as users from './Users';
import * as layoutTypes from './LayoutTypes';
import * as doodles from './Doodles';
import * as machines from './Machines';
import * as deliveries from './Deliveries';
import * as templates from './Templates';
import * as collaterals from './Collaterals';
import * as tags from './Tags';
import * as integrations from './Integrations';
import * as properties from './Properties';
import * as links from './Links';
import * as articles from './Articles';
import * as images from './Images';
import * as associates from './Associates';
import * as teams from './Teams';
import * as accessTokens from './AccessTokens';
import * as drawings from './Drawings';
import * as prospecting from './Prospecting';
import * as claims from './Claims';
import * as plans from './Plans';
import * as notifications from './Notifications';

const services = {
    accounts,
    admin,
    authentication,
    addresses,
    campaigns,
    cards,
    fonts,
    audiences,
    letters,
    campaignContacts,
    contacts,
    queues,
    user,
    orders,
    users,
    layoutTypes,
    doodles,
    machines,
    deliveries,
    templates,
    collaterals,
    tags,
    integrations,
    properties,
    links,
    articles,
    images,
    associates,
    teams,
    accessTokens,
    drawings,
    prospecting,
    claims,
    plans,
    notifications
};

export default services;
