<template>
    <div class="flex items-center z-10 text-gray-800">
        <div
            id="move-mode"
            class="shadow-md border-r border-gray-500 py-2 px-3 cursor-pointer hover:bg-gray-200"
            :class="{'bg-gray-300': ! isDrawing, 'bg-white': isDrawing}"
            @click="$emit('mode', ControlMode.MOVE)"
        >
            <i class="fas fa-hand"></i>
        </div>
        <div
            id="free-draw-type"
            class="shadow-md py-2 px-3 cursor-pointer hover:bg-gray-200 bg-white border-r border-gray-500"
            :class="{'bg-gray-300': isFreeDraw}"
            @click="changeDrawType(DrawType.FREE_DRAW)"
        >
            <i class="fas fa-pencil"></i>
        </div>
        <div
            id="point-draw-type"
            class="shadow-md py-2 px-3 cursor-pointer hover:bg-gray-200 bg-white"
            :class="{'bg-gray-300': isPointDraw}"
            @click="changeDrawType(DrawType.POINT_DRAW)"
        >
            <i class="fa-solid text-lg text-gray-800 fa-draw-polygon"></i>
        </div>
        <div
            v-if="selected"
            class="bg-white shadow-md border-r border-gray-500 py-2 px-3 cursor-pointer hover:bg-gray-200"
            @click="$emit('trash')"
        >
            <i class="fa fa-trash"></i>
        </div>
        <div
            v-if="selected"
            class="bg-white shadow-md py-2 px-3 cursor-pointer hover:bg-gray-200"
            @click="$emit('cancel')"
        >
            <i class="fa fa-times"></i>
        </div>
    </div>
</template>

<script>
import { ControlMode, DrawType } from '@definitions/MapTypes';

export default {
    name: 'MapControls',
    props: {
        selected: { type: Boolean, required: true },
        controlMode: { type: String, required: true }
    },
    data: () => ({
        ControlMode,
        DrawType,
        type: DrawType.FREE_DRAW
    }),
    computed: {
        isDrawing () {
            return this.controlMode === ControlMode.DRAW;
        },
        isFreeDraw () {
            return this.isDrawing && this.type === DrawType.FREE_DRAW;
        },
        isPointDraw () {
            return this.isDrawing && this.type === DrawType.POINT_DRAW;
        }
    },
    methods: {
        async changeDrawType (type) {
            this.type = type;
            this.$emit('type', this.type);
        }
    }
};
</script>
