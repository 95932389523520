import ApiClient from '@services/Http/Api';

export const list = (account, params = {}) => {
    return ApiClient.get(`/dashboard/accounts/${account}/orders`, { params });
};

export const create = (account, data) => {
    return ApiClient.post(`/dashboard/accounts/${account}/orders`, data);
};

export const update = (account, order, data) => {
    return ApiClient.patch(`/dashboard/accounts/${account}/orders/${order}`, data);
};

export const finalize = (account, order, data) => {
    return ApiClient.post(`/dashboard/accounts/${account}/orders/${order}`, data);
};
