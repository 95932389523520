const clickEventType = 'mousedown';

const UNIQUE_ID = '__vue_click_away__';

const onMounted = (element, binding, vnode) => {
    onUnmounted(element);

    const vm = vnode.context;
    const callback = binding.value;

    let nextTick = false;
    setTimeout(function () {
        nextTick = true;
    }, 0);

    element[UNIQUE_ID] = (event) => {
        if (
            (!element || !element.contains(event.target)) &&
            callback &&
            nextTick &&
            typeof callback === 'function'
        ) {
            return callback.call(vm, event);
        }
    };

    document.addEventListener(clickEventType, element[UNIQUE_ID], false);
};

const onUnmounted = (element) => {
    document.removeEventListener(clickEventType, element[UNIQUE_ID], false);
    delete element[UNIQUE_ID];
};

const onUpdated = (element, binding, vnode) => {
    if (binding.value === binding.oldValue) {
        return;
    }
    onMounted(element, binding, vnode);
};

const plugin = {
    install: (app) => {
        app.directive('click-away', directive);
    }
};

const directive = {
    // vue 2 support
    bind: onMounted,
    unbind: onUnmounted,
    update: onUpdated,

    // vue 3 support
    mounted: onMounted,
    updated: onUpdated,
    unmounted: onUnmounted
};

const mixin = {
    directives: { ClickAway: directive }
};

export {
    directive,
    mixin
};

export default plugin;
