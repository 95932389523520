<template>
    <div class="container mx-auto">
        <transition name="fade" mode="out-in">
            <div class="mt-8 md:mt-0 md:p-8">
                <div class="flex flex-col sm:flex-row justify-between mb-6 px-6 sm:px-0">
                    <div class="flex flex-row lg:w-1/3 w-full items-center">
                        <h3 class="text-xl text-gray-800 font-semibold">{{ pagination.total }} Scanned So Far</h3>
                    </div>
                </div>
                <div
                    class="flex p-6 mb-4 bg-white rounded-sm shadow-sm items-center"
                >
                    <v-input
                        class="flex-1 lg:flex-none md:w-3/4"
                        label="Search"
                        @input="q = $event"
                    />
                    <export-links-button :account-id="user.account_id" :q="q" />
                </div>
                <div v-if="loading" class="flex flex-col items-center justify-center p-6">
                    <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                    <span class="text-gray-700">Loading scan data...</span>
                </div>
                <div v-else-if="!loading && links.length > 0">
                    <paginator-wrap :pagination="pagination" :change-page="changePage">
                        <template #content>
                            <qr-scan-card
                                v-for="(row, index) in links"
                                :key="index"
                                :link="row"
                                @list-logs="listLogs"
                            />
                        </template>
                    </paginator-wrap>
                </div>
                <div v-else class="text-center">
                    <p class="text-lg text-gray-700">It doesn't look like you've had any scanned QR codes yet.</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import VInput from '@outreach/components/inputs/VInput';
import QrScanCard from '@outreach/pages/account/QrScanCard';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import VLoader from '@outreach/components/VLoader';
import { mapActions, mapGetters, mapState } from 'vuex';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import ExportLinksButton from '@outreach/components/account/ExportLinksButton';

export default {
    name: 'QrScans',
    components: { ExportLinksButton, QrScanCard, PaginatorWrap, VInput, VLoader },
    data () {
        return {
            q: undefined
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        ...mapGetters({
            links: 'links/links',
            pagination: 'links/pagination',
            loading: 'loader/isLoading'
        }),
        campaignId () {
            return this.$route.query.campaign_id;
        }
    },
    watch: {
        q: function (q) {
            this.listLinks({ q });
        }
    },
    mounted () {
        this.listLinks();
        this.subscribe();
    },
    destroyed () {
        this.close();
    },
    methods: {
        ...mapActions({
            list: 'links/list',
            close: 'links/close',
            subscribe: 'links/subscribe',
            clearMessages: 'alerts/clearAll'
        }),
        listLinks: debounce(function (params = {}) {
            this.list({
                accountId: this.user.account_id,
                params: { campaign_id: this.campaignId, q: this.q, ...params }
            });
        }, 400),
        changePage (page) {
            this.listLinks({ page });
        },
        async listLogs (link) {
            this.clearMessages();
            dialogEmitter.openDialog('ListLogsDialog', { linkId: link.id });
        }
    }
};
</script>
