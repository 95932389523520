import ApiClient from '@services/Http/Api';

export const tagMany = (accountId, params = {}) => {
    return ApiClient.put(`/dashboard/accounts/${accountId}/contacts/tag-many`, params);
};

export const list = (accountId, params = {}) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/tags`, { params });
};

export const create = (accountId, tag) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/tags`, tag);
};

export const update = (accountId, tag) => {
    return ApiClient.patch(`/dashboard/accounts/${accountId}/tags/${tag.id}`, tag);
};

export const archive = (accountId, tagId) => {
    return ApiClient.destroy(`/dashboard/accounts/${accountId}/tags/${tagId}`);
};

export const archiveMany = (accountId, tags) => {
    return ApiClient.destroy(`/dashboard/accounts/${accountId}/tags`, tags);
};
