<template>
    <v-table :rows="members">
        <template #default="{ row }">
            <v-table-column label="Members" value="member">
                <p>{{ row.first_name }} {{ row.last_name }} <span
                    v-if="isCurrentUser(row)"
                >(You)</span></p>
                <span class="text-gray-700 text-sm">{{ formatFullDate(row.joined_at) }}</span>
            </v-table-column>
            <v-table-column v-if="canEdit" label="Campaign Last Submitted" value="last_submitted">
                <p>{{ formatFullDate(row.account.last_submitted_at, 'll') }}</p>
            </v-table-column>
            <v-table-column v-if="canEdit" label="Notes Sent" value="notes_sent">
                <p>{{ row.account.completed_jobs_count }}</p>
            </v-table-column>
            <v-table-column v-if="canEdit" label="Credits" value="credits">
                <div v-if="row.account && !row.draft">
                    <p class="text-gray-700">{{ row.account.credits }}</p>
                    <a
                        v-if="!isCurrentUser(row) && showCreditTransfer"
                        class="text-sm text-brand-regular underline"
                        href="#"
                        @click="openCreditsModal(row)"
                    >Transfer Credits</a>
                </div>
                <div v-else>
                    <p>Please save before managing credits</p>
                </div>
            </v-table-column>
            <v-table-column label="Role" value="role">
                <div v-if="canEdit" class="flex">
                    <v-select
                        v-model="row.team_role"
                        label="Select"
                        :options="roles"
                        class="flex-1 mt-2 sm:mt-0"
                    />
                    <button ref="remove" class="ml-2" @click="removeMember(row)">
                        <i class="fa fa-times text-lg text-red-600 text-right"></i>
                    </button>
                </div>
                <div v-else>
                    {{ roleLabel(row.team_role) }}
                </div>
            </v-table-column>
        </template>
    </v-table>
</template>

<script>
import { TeamRoles } from '@outreach/config/teams';
import { mapState } from 'vuex';
import { formatFullDate } from '@services/Filters';
import { VTable, VTableColumn } from '@outreach/components/shared/table';
import VSelect from '@outreach/components/inputs/VSelect';

export default {
    name: 'TeamMemberTable',
    components: { VTable, VTableColumn, VSelect },
    props: {
        members: { type: Array, required: true },
        canEdit: { type: Boolean, required: false, default: false },
        showCreditTransfer: { type: Boolean, required: false, default: false }
    },
    data () {
        return {
            roles: [
                { value: TeamRoles.MEMBER, label: 'Member' },
                { value: TeamRoles.OWNER, label: 'Owner' }
            ]
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            loading: ({ loader }) => loader.loading
        })
    },
    methods: {
        formatFullDate,
        removeMember (member) {
            this.$emit('removeMember', member);
        },
        openCreditsModal (member) {
            this.$emit('openCreditsModal', member);
        },
        isCurrentUser (member) {
            return this.user.id === member.id;
        },
        roleLabel (role) {
            return (this.roles.find(r => r.value === role) || {}).label;
        }
    }
};
</script>
