import services from '@services/Api';
import debounce from 'lodash/debounce';
import { handleErrors } from '@audience/modules/common';
import { Template } from '@models/Template';

export const state = {
    templates: [],
    meta: {},
    formErrors: {}
};

export const actions = {
    index: debounce(async function ({ commit, rootState }, params = {}) {
        const { data } = await services.templates.list(rootState.user.user.account_id, params);
        commit('setTemplates', data);
    }, 400),
    fetch: async ({ rootState }, templateId) => {
        const { data } = await services.templates.fetch(rootState.user.user.account_id, templateId);
        return new Template(data.data);
    },
    create: async ({ commit, rootState }, template) => {
        try {
            commit('setFormErrors', {});
            const { data } = await services.templates.create(rootState.user.user.account_id, template);
            return new Template(data.data);
        } catch (error) {
            handleErrors(error, commit);
        }
    },
    update: async ({ commit, rootState }, template) => {
        try {
            commit('setFormErrors', {});
            const { data } = await services.templates.update(rootState.user.user.account_id, template);
            return new Template(data.data);
        } catch (error) {
            handleErrors(error, commit);
        }
    },
    sort: async function ({ commit, rootState }, templates) {
        await services.templates.sort(rootState.user.user.account_id, templates);
    },
    delete: async ({ dispatch, rootState }, templateId) => {
        await services.templates.destroy(rootState.user.user.account_id, templateId);
        dispatch('index');
    },
    duplicate: async ({ commit, rootState }, template) => {
        const { data } = await services.templates.duplicate(rootState.user.user.account_id, template.id);
        return data.data;
    }
};

export const mutations = {
    setTemplates: (state, payload = {}) => {
        state.templates = payload.data;
        state.meta = payload.meta;
    },
    setFormErrors: (state, payload) => {
        state.formErrors = payload;
    }
};

export const getters = {
    get: (state) => state.template,
    list: (state) => state.templates,
    pagination: (state) => state.meta,
    formErrors: (state) => state.formErrors
};

export default { namespaced: true, state, actions, mutations, getters };
