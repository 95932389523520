<template>
    <router-link
        v-if="link.route != null"
        class="link nav-link font-regular"
        active-class="active-link brand-regular-gradient text-brand-regular"
        :to="{ name: link.route, params: { accountId }, query: link.query }"
        exact
    >
        <slot name="default" :link="link"></slot>
    </router-link>
    <a
        v-else
        class="link nav-link font-regular"
        :href="link.href"
        :target="link.target"
    >
        <slot name="default" :link="link"></slot>
    </a>
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: true
        },
        accountId: {
            type: Number,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>

.nav-link {
    @apply px-4 py-3 text-gray-700 text-sm flex items-center relative transition-all hover:text-brand-regular;
}

</style>
