<template>
    <div class="navigation-section">
        <div class="flex flex-col w-full items-center ">
            <div class="md:hidden w-full">
                <slot name="mobile-header"></slot>
            </div>
            <div class="flex flex-col w-full h-full mr-8 md:flex md:m-0 md:flex-row" :class="{ 'hidden': !expanded }">
                <slot name="content"></slot>
            </div>
        </div>
        <button class="h-full w-8 md:hidden" @click="expanded = !expanded">
            <i
                slot="after-label"
                class="text-sm text-gray-600 fa"
                :class="{'fa-chevron-down': expanded, 'fa-chevron-up': !expanded}"
            >
            </i>
        </button>
    </div>
</template>

<script>
export default {
    name: 'SearchHeader',
    data () {
        return {
            expanded: false
        };
    }
};
</script>

<style scoped>
.navigation-section {
    @apply flex p-6 bg-white rounded-sm shadow-sm fixed bottom-0 left-0 w-full border-t-2 z-20 md:relative md:mb-4;
}
</style>
