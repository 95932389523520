<template>
    <div class="container mx-auto">
        <div v-if="loading" class="flex flex-col items-center justify-center">
            <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
            <span class="text-gray-700">Loading agent data</span>
        </div>
        <div v-else>
            <v-card has-header class="rounded">
                <template #header>
                    Update Agent
                </template>
                <template #body>
                    <div class="flex gap-2 mb-4">
                        <div class="flex flex-col flex-1 gap-2">
                            <v-input ref="agent-name" v-model="agent.name" :errors="formErrors.name" label="Name" />
                            <v-input
                                ref="agent-realtorid"
                                v-model="agent.realtor_id"
                                disabled
                                label="Realtor Id"
                            />
                            <v-input
                                ref="agent-zillowid"
                                v-model="agent.zillow_id"
                                disabled
                                label="Zillow Id"
                            />
                            <v-input ref="agent-email" v-model="agent.email" :errors="formErrors.email" label="Email" />
                            <v-input
                                ref="agent-phone"
                                v-model="agent.phone.mobile"
                                :errors="formErrors.phone"
                                label="Mobile Phone"
                            />
                            <v-input
                                ref="agent-phone"
                                v-model="agent.phone.office"
                                :errors="formErrors.phone"
                                label="Office Phone"
                            />
                        </div>
                        <div class="flex flex-col flex-1 gap-2">
                            <v-input
                                ref="agent-website"
                                v-model="agent.website"
                                :errors="formErrors.website"
                                label="Website"
                            />
                            <v-input
                                ref="agent-realtorurl"
                                v-model="agent.realtor_url"
                                :errors="formErrors.realtor_url"
                                disabled
                                label="Realtor URL"
                            />
                            <v-input
                                ref="agent-zillowurl"
                                v-model="agent.zillow_url"
                                :errors="formErrors.zillow_url"
                                disabled
                                label="Zillow URL"
                            />
                            <v-input ref="agent-blog" v-model="agent.blog" :errors="formErrors.blog" label="Blog" />
                            <v-input
                                ref="agent-company"
                                v-model="agent.company"
                                :errors="formErrors.company"
                                label="Company"
                            />
                        </div>
                    </div>
                    <v-input
                        ref="agent-address"
                        v-model="agent.address"
                        :errors="formErrors.address"
                        label="Address"
                        class="mb-4"
                    />
                    <v-textarea ref="agent-about" v-model="agent.about" :errors="formErrors.about" label="About" />
                    <v-button ref="save-button" label="Save" button-style="primary" @click="updateAgent" />
                </template>
            </v-card>
            <v-panel title="Listings" class="mt-2">
                <div v-if="agent.listings.length > 0">
                    <v-table :rows="agent.listings" has-actions is-elevated>
                        <template #default="{row}">
                            <v-table-column label="Image" value="image">
                                <img ref="image" alt="preview of the image" class="w-32" :src="row.image" />
                            </v-table-column>
                            <v-table-column label="Address" value="address">
                                <p> {{ row.address.all_address_lines }} {{ row.address.city }}, {{ row.address.state }}</p>
                            </v-table-column>
                            <v-table-column label="List Date" value="list-date">
                                <p class="whitespace-nowrap"> {{ row.list_date }}</p>
                            </v-table-column>
                            <v-table-column label="Price" value="price">
                                <p> {{ formatToCurrency(row.price) }}</p>
                            </v-table-column>
                            <v-table-column label="Status" value="status">
                                <p> {{ row.status }}</p>
                            </v-table-column>
                        </template>
                        <template #priority-buttons="{row}">
                            <button :id="`view-listing-${row.id}`" @click="viewListing(row)">
                                View
                            </button>
                        </template>
                    </v-table>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No listings found.</p>
                    </div>
                </div>
            </v-panel>
        </div>
    </div>
</template>
<script>
import VCard from '@outreach/components/VCard.vue';
import VButton from '@outreach/components/VButton.vue';
import VInput from '@outreach/components/inputs/VInput.vue';
import services from '@services/Api';
import { Alert } from '@services/Alert';
import VLoader from '@outreach/components/VLoader.vue';
import VTable from '@outreach/components/shared/table/VTable.vue';
import { VTableColumn } from '@outreach/components/shared/table';
import { formatToCurrency } from '@services/Utils';
import VPanel from '@outreach/components/shared/VPanel.vue';
import VTextarea from '@outreach/components/inputs/VTextarea.vue';
import get from 'lodash/get';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';

export default {
    name: 'UpdateAgent',
    components: { VTextarea, VTableColumn, VTable, VCard, VButton, VInput, VLoader, VPanel },
    data: () => ({
        agent: undefined,
        loading: undefined,
        formErrors: {}
    }),
    computed: {
        agentId () {
            return this.$route.params.agent;
        }
    },
    async created () {
        try {
            this.loading = true;
            const { data } = await services.admin.agents.showAgent(this.agentId);
            this.agent = data.data;
        } catch (error) {
            await Alert.error('This Agent could not be shown.');
        }
        this.loading = false;
    },
    methods: {
        formatToCurrency,
        async updateAgent () {
            try {
                this.loading = true;
                await services.admin.agents.updateAgent(this.agent.id, this.agent);
                await Alert.success('Agent Updated Successfully');
            } catch (e) {
                this.formErrors = get(e, 'response.data.errors', {});
                await Alert.fromError(e, 'Error Updating Agent, Please Try Again.');
            }
            this.loading = false;
        },
        viewListing (listing) {
            dialogEmitter.name('ListingDialog').props({ listing, agent: this.agent }).wait();
        }
    }
};
</script>
