<template>
    <template-form
        label="Add template"
        :template.sync="template"
        :form-errors="formErrors"
        @submit="submit"
        @save="save"
    />
</template>

<script>
import TemplateForm from '@outreach/pages/Templates/TemplateForm';
import { mapActions, mapGetters } from 'vuex';
import { Template } from '@models/Template';
import { Alert } from '@services/Alert';
import { get } from 'lodash';

export default {
    components: { TemplateForm },
    data: () => ({
        template: new Template({
            layout_type: 'note',
            doodles: []
        })
    }),
    computed: {
        ...mapGetters({
            formErrors: 'templates/formErrors'
        })
    },
    methods: {
        ...mapActions({
            create: 'templates/create'
        }),
        async submit () {
            try {
                await this.create(this.template);
                await this.$router.push({ name: 'shared-templates' });
                await Alert.success('Template created');
            } catch (e) {
                this.formErrors = get(e, 'response.data.errors', {});
            }
        },
        async save () {
            try {
                await this.create(this.template);
                await Alert.success('Template created');
            } catch (e) {
                this.formErrors = get(e, 'response.data.errors', {});
            }
        }
    }
};
</script>
