<template>
    <div class="container mx-auto">
        <transition name="fade" mode="out-in">
            <div v-if="loading" key="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading fonts</span>
            </div>
            <div v-else>
                <div v-if="fonts.length > 0">
                    <v-table
                        is-elevated
                        has-actions
                        :rows="fonts"
                    >
                        <template #default="{ row }">
                            <v-table-column label="Typeface" value="title">
                                <div>
                                    <span>{{ changeDisplayName(row.title) }}</span>
                                </div>
                            </v-table-column>
                        </template>
                        <template #priority-buttons="{ row }">
                            <button @click="openFontFormModal(row)">Edit</button>
                            <button class="gray" @click="preview(row)">Preview</button>
                            <button class="red" @click="destroy(row)">Delete</button>
                        </template>
                    </v-table>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No fonts found.</p>
                    </div>
                </div>
            </div>
        </transition>
        <modal :header="fontFormHeading" :open="fontFormModalOpen" @close-modal="closeFontFormModal">
            <template slot="content">
                <div class="flex w-modal">
                    <font-form
                        v-if="formFont"
                        :font="formFont"
                        :submitting="submittingForm"
                        @formSubmitSuccessful="closeFontFormModal"
                        @formHasErrors="submittingForm=false"
                    />
                </div>
            </template>
            <template slot="modal-actions">
                <div class="p-2">
                    <v-button
                        label="cancel"
                        :disabled="loading"
                        :loading="loadingOrSubmitting"
                        class="mr-2"
                        @click="closeFontFormModal"
                    />
                    <v-button
                        label="Save"
                        button-style="primary"
                        :disabled="loadingOrSubmitting"
                        :loading="loadingOrSubmitting"
                        @click="submit"
                    />
                </div>
            </template>
        </modal>
        <modal
            :open="previewModalOpen"
            :full-screen="!!previewData"
            :no-pad="!!previewData"
            @close-modal="closePreviewModal"
        >
            <template slot="content">
                <div class="w-modal">
                    <v-loader v-if="!previewData" :size="40" />
                    <div v-else class="flex">
                        <img class="h-full w-auto mx-auto" :src="previewData" />
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import services from '@services/Api';
import Modal from '@outreach/components/shared/modal/index';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import get from 'lodash/get';
import VLoader from '@outreach/components/VLoader';
import VButton from '@outreach/components/VButton';
import FontForm from '../../components/font/FontForm';
import { cloneDeep } from 'lodash';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';

export default {
    components: {
        VLoader,
        VButton,
        VTable,
        VTableColumn,
        Modal,
        FontForm
    },
    data: () => ({
        loading: true,
        previewModalOpen: false,
        fontFormModalOpen: false,
        previewData: null,
        fonts: [],
        formFont: null,
        submittingForm: false,
        deleting: null
    }),
    computed: {
        fontFormHeading () {
            return `Edit ${this.formFont ? this.formFont.title : 'Font'}`;
        },
        loadingOrSubmitting () {
            return this.loading || this.submittingForm;
        }
    },
    created () {
        this.getFonts();
    },
    methods: {
        changeDisplayName (value) {
            return value.replace(/Premium/, 'Audience');
        },
        async getFonts () {
            this.loading = true;

            const { data } = await services.fonts.list();
            this.fonts = data.data;

            this.loading = false;
        },
        openFontFormModal (font) {
            this.formFont = cloneDeep(font);
            this.fontFormModalOpen = true;
        },
        async closeFontFormModal () {
            if (this.submittingForm) {
                await this.getFonts();
            }
            this.fontFormModalOpen = false;
            this.submittingForm = false;
        },
        openPreviewModal () {
            this.previewModalOpen = true;
        },
        closePreviewModal () {
            this.previewModalOpen = false;
            this.previewData = null;
        },
        submit () {
            this.submittingForm = true;
        },
        async preview (font) {
            this.openPreviewModal();
            this.previewData = null;
            try {
                const { data } = await services.fonts.preview(font.id);
                this.previewData = get(data, 'data.letter', null);
            } catch (e) {
                await this.closePreviewModal();
                await this.openFontFormModal(font);
            }
        },
        async destroy (font) {
            const result = await dialogEmitter.confirm(
                'Delete Font',
                `Are you sure you want to delete '${this.changeDisplayName(font.title)}'?`
            );

            if (!result) return;

            this.loading = true;
            try {
                await services.fonts.destroy(font.id);
                await this.getFonts();
            } catch (e) {
                this.$toasted.error('Failed to delete tag. Please try again.');
            }
        }
    }
};
</script>
