<template>
    <div class="flex-row items-center w-full">
        <v-input
            ref="search-jobs"
            v-model="search"
            class="w-full my-1"
            label="Search"
            type="text"
        />
        <div v-if="loading" class="flex flex-col items-center justify-start p-6" style="height: 100vh;">
            <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
            <span class="text-gray-700">Loading jobs data</span>
        </div>
        <div v-else>
            <paginator-wrap :change-page="changePage" :pagination="pagination">
                <template slot="content">
                    <div v-for="row in rows" :key="row.id" class="p-4 border-b">
                        <div class="flex flex-col md:flex-row md:-mx-2 gap-2">
                            <div class="flex-0">
                                <a
                                    :href="row.payload.letter"
                                    target="_blank"
                                >
                                    <img
                                        class="border rounded-lg"
                                        :src="`${row.payload.letter}?v=${version}`"
                                        width="100px"
                                    />
                                </a>
                            </div>
                            <div class="flex-1">
                                <div>{{ get(row, 'delivery.contact.name', '') }}</div>
                                <v-address
                                    v-if="get(row, 'delivery.contact.address')"
                                    class="text-gray-600"
                                    :address="get(row, 'delivery.contact.address')"
                                />
                            </div>
                            <div class="flex-0 flex item-center gap-2">
                                <a
                                    v-if="row.payload.qr_code_url"
                                    v-tooltip="`View QR code`"
                                    :href="row.payload.qr_code_url"
                                    target="_blank"
                                >
                                    <i class="fa fa-qrcode text-2xl text-gray-600 hover:text-gray-700"></i>
                                </a>
                                <a
                                    v-if="row.delivery && row.delivery.photo_path"
                                    v-tooltip="`View cover image`"
                                    :href="row.delivery.photo_path"
                                    target="_blank"
                                >
                                    <i class="fa fa-image text-2xl text-gray-600 hover:text-gray-700"></i>
                                </a>
                            </div>
                            <div class="flex-1 md:text-right">
                                <div v-if="row.completed_at" class="text-gray-600 whitespace-no-wrap">
                                    <div>Completed on {{ formatDateTime(row.completed_at) }}</div>
                                    <div>{{ fromNow(row.completed_at) }}</div>
                                </div>
                                <div v-else class="text-gray-600 whitespace-no-wrap">
                                    <div>Created on {{ formatDateTime(row.created_at) }}</div>
                                    <div>{{ fromNow(row.created_at) }}</div>
                                </div>
                            </div>
                            <div class="job-actions flex flex-col flex-0">
                                <slot name="actions" :row="row"></slot>
                            </div>
                        </div>
                    </div>
                </template>
            </paginator-wrap>
        </div>
    </div>
</template>

<script>
import VLoader from '@outreach/components/VLoader';
import get from 'lodash/get';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import VAddress from '@outreach/components/shared/VAddress';
import VInput from '@outreach/components/inputs/VInput';
import { mapState } from 'vuex';
import { formatDateTime, fromNow } from '@services/Filters';

export default {
    name: 'IndividualJobTable',
    components: {
        VInput,
        VAddress,
        PaginatorWrap,
        VLoader
    },
    props: {
        rows: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        pagination: { type: Object, default: () => ({}) }
    },
    data: () => ({
        search: ''
    }),
    computed: {
        ...mapState({
            version: ({ pending }) => pending.previewVersion
        })
    },
    watch: {
        search () {
            this.$emit('search', this.search);
        }
    },
    methods: {
        get,
        formatDateTime,
        fromNow,
        changePage (page) {
            this.$emit('change-page', page);
        }
    }
};
</script>

<style>
.job-actions > * {
    margin-bottom: 4px;
    width: 100%;
}
</style>
