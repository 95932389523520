<template>
    <dl class="mb-4 grid grid-cols-2">
        <dt>Current Balance</dt>
        <dd class="text-right">{{ balance }}</dd>
        <dt>Quantity</dt>
        <dd class="text-right">{{ quantity }}</dd>
        <dt :class="creditsClass">Remaining Available</dt>
        <dd :class="creditsClass" class="text-right">{{ balance - quantity }}</dd>
    </dl>
</template>

<script>
export default {
    name: 'CreditSummary',
    props: {
        balance: { type: Number, required: true },
        quantity: { type: Number, required: true }
    },
    computed: {
        creditsClass () {
            return { 'text-red-500': this.balance < this.quantity };
        }
    }
};
</script>
