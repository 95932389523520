<template>
    <div>
        <div class="sm:hidden mt-2">
            <label for="sendNotes" class="sr-only">{{ label }}</label>
            <select
                id="sendNotes"
                name="sendNotes"
                class="toggle-group-select"
                @change="emit"
            >
                <option
                    v-for="option in options"
                    :key="option.label"
                    :selected="selected === option.label"
                    :value="JSON.stringify(option)"
                >
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="hidden sm:block">
            <nav
                class="inline-flex rounded divide-x divide-gray-200 overflow-hidden bg-white shadow"
                :aria-label="label"
            >
                <button
                    v-for="option in options"
                    :key="option.label"
                    :ref="option.label"
                    :value="JSON.stringify(option)"
                    class="px-3 py-2 font-medium text-sm cursor-pointer flex"
                    :class="[
                        selected === option.label ? 'bg-brand-regular text-white' : 'text-gray-500 hover:text-gray-700'
                    ]"
                    @click="emit"
                >
                    {{ option.label }}
                </button>
            </nav>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ToggleGroup',
    props: {
        value: { type: undefined, default: undefined },
        options: { type: Array, required: true },
        label: { type: String, required: true },
        defaultSelected: { type: Function, default: () => true }
    },
    data: () => ({
        selected: ''
    }),
    mounted () {
        this.selected = this.options.find(this.defaultSelected)?.label ?? this.options[0].label;
    },
    methods: {
        emit (event) {
            const option = JSON.parse(event.target.value);
            this.selected = option.label;
            this.$emit('input', option.value);
        }
    }
};
</script>

<style lang="scss" scoped>

.toggle-group-select {
    @apply block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md;
}

</style>
