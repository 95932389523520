import services from '@services/Api';
import ClientVisibleError from '@exceptions/ClientVisibleError';

const state = {
    loading: false,
    list: [],
    currentCardId: null,
    notePrice: 0
};

const actions = {
    getCards: async ({ commit }, account) => {
        commit('setLoading', true);
        try {
            const { data } = await services.cards.list(account);

            const cards = data.data.map(card => {
                return {
                    ...card,
                    label: `${card.brand} - ${card.last4}`,
                    value: card.id
                };
            });
            commit('setNotePrice', data.note_price);
            commit('setCards', cards);
        } catch (error) {
            throw ClientVisibleError('Failed to fetch cards.');
        } finally {
            commit('setLoading', false);
        }
    },
    deleteCard: async ({ commit }, payload) => {
        try {
            await services.cards.destroy(payload.accountId, payload.cardId);
            commit('removeCard', payload.cardId);
        } catch (error) {
            throw ClientVisibleError('Failed to delete card.');
        }
    }
};

const mutations = {
    removeCard: (state, id) => {
        state.list = state.list.filter(card => card.id !== id);
    },
    setCards: (state, cards) => {
        state.list = cards;
    },
    setLoading (state, loading) {
        state.loading = loading;
    },
    setNotePrice (state, price) {
        state.notePrice = price;
    }
};

export default { namespaced: true, state, actions, mutations };
