<template>
    <div class="container">

        <div class="my-4">
            <div class="text-center mb-8 px-4">
                <h2 class="text-3xl mb-2 font-semibold text-grey-900">Have an ecommerce store?</h2>
            </div>

            <div class="lg:flex lg:justify-center -mx-2">
                <service-card
                    v-for="(integration, index) in integrations"
                    :key="index"
                    class="flex-none mx-2 mb-2 lg:mb-0"
                    :service="integration"
                    @select-service="showServiceModal"
                />
            </div>
        </div>

        <div v-if="connected.length > 0" class="max-w-6xl mx-auto">
            <div class="text-center mt-8 px-4">
                <h2 class="text-3xl mb-2 font-semibold text-grey-900">Connected Services</h2>
            </div>

            <div class="">
                <v-table
                    is-elevated
                    has-actions
                    :rows="connected"
                >
                    <template #default="{ row }">
                        <v-table-column label="Type" value="type">
                            <img
                                class="h-10 m-auto"
                                :src="`/svg/services/${row.type.toLowerCase()}-logo.svg`"
                                :alt="row.type"
                            />
                        </v-table-column>
                        <v-table-column label="Name" value="name">
                            <span>{{ row.name }}</span>
                        </v-table-column>
                        <v-table-column label="Domain" value="domain">
                            <span>https://{{ row.api_domain }}.myshopify.com</span>
                        </v-table-column>
                        <v-table-column label="Auto Sync Enabled" value="sync_enabled">
                            <v-toggle v-model="row.sync_enabled" @input="submitIntegration(row)" />
                        </v-table-column>
                        <v-table-column label="Last Synced at" value="synced_at" class="text-center">
                            <div v-if="row.synced_at">
                                <span>{{ formatDate(row.synced_at) }}</span>
                                <span class="text-sm text-gray-700">{{ fromNow(row.synced_at) }}</span>
                            </div>
                            <span v-else>--</span>
                        </v-table-column>
                        <v-table-column label="Actions" value="actions">
                            <v-button
                                ref="edit-button"
                                label="Edit"
                                button-style="default"
                                @click="editIntegration(row)"
                            >
                                Edit
                            </v-button>

                        </v-table-column>
                    </template>
                </v-table>
                <div class="flex items-center justify-center py-8 px-2">
                    <v-paginator
                        class="flex-none"
                        :total="meta.last_page"
                        :value="meta.current_page"
                        @input="getConnected"
                    />
                </div>
            </div>
        </div>
        <add-integration
            v-if="showModal"
            :service="selectedService"
            :details="modalDetails"
            :form-errors="formErrors"
            :loading="loading"
            @close-modal="showModal = false"
            @submitted="submitIntegration"
        />
    </div>
</template>

<script>
import ServiceCard from '@outreach/components/services/ServiceCard';
import AddIntegration from '@outreach/components/services/AddIntegration';
import VTable from '@outreach/components/shared/table/VTable';
import VTableColumn from '@outreach/components/shared/table/VTableColumn';
import VPaginator from '@outreach/components/VPaginator';
import services from '@services/Api';
import { mapState } from 'vuex';
import { get } from 'lodash';
import VToggle from '@outreach/components/VToggle';
import VButton from '@outreach/components/VButton';
import { formatDate, fromNow } from '@services/Filters';

export default {
    components: { VButton, VToggle, ServiceCard, AddIntegration, VTable, VTableColumn, VPaginator },
    data: () => ({
        showModal: false,
        selectedService: null,
        connected: [],
        integrations: [],
        meta: {},
        formErrors: {},
        loading: false,
        modalDetails: {}
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    created () {
        this.available();
        this.getConnected();
    },
    methods: {
        formatDate,
        fromNow,
        showServiceModal (service, details = {}) {
            this.selectedService = service;
            this.modalDetails = details;
            this.showModal = true;
        },
        async available () {
            this.loading = true;
            const { data } = await services.integrations.available();
            this.integrations = data.data;
            this.loading = false;
        },
        async getConnected () {
            this.loading = true;
            const { data } = await services.integrations.connected(this.user.account_id);
            this.connected = data.data;
            this.meta = data.meta;
            this.loading = false;
        },
        async submitIntegration (integration) {
            this.loading = true;

            try {
                await services.integrations.upsert(this.user.account_id, { ...integration, ...{ type: 'shopify' } });
                this.showModal = false;
                this.selectedService = null;
                await this.getConnected();
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
            }

            this.loading = false;
        },
        editIntegration (integration) {
            const service = this.integrations.find(service => service.driver === integration.type);
            this.showServiceModal(service, integration);
        }
    }
};
</script>
