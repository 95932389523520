<template>
    <div class="flex flex-col p-4">
        <img src="/images/logo-orange-red.png" class=" mb-6 w-32" alt="Audience Logo" />
        <div class="flex flex-col ml-12">
            <p class="text-5xl text-gray-800 font-bold mb-4">Shoot!</p>
            <p class="text-2xl text-gray-800 font-semibold">Well this is unexpected...</p>
            <div class="mt-4 text-gray-800 text-lg flex flex-col gap-y-2.5">
                <p>An error has occurred and we're working to fix the problem! We'll be up and running shortly. </p>
                <p>Please try again later or feel free to contact us if the problem persists.</p>
                <p>Thanks for your patience!</p>
            </div>
            <router-link class="text-brand-regular router-link-active mt-4" :to="{ name: 'send-notes' }">Click here to return to dashboard.</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServerError'
};
</script>
