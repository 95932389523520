<template>
    <div class="flex flex-col flex-1">
        <steps :steps="['Contacts', 'Compose', 'Review']" value="Contacts" />
        <div class="container mx-auto max-w-4xl py-8">
            <div class="bg-white shadow-md rounded">
                <div class="flex flex-col md:flex-row items-stretch">
                    <div class="border-b border-gray-400 flex-1 flex-col p-10 md:border-b-0 md:border-r">
                        <h3 ref="many-header" class="text-2xl mb-6 font-semibold text-gray-800 text-center">Send to more
                            than one person</h3>
                        <v-button
                            ref="many-button"
                            class="mt-2 w-full"
                            label="Next"
                            button-style="primary"
                            @click="$emit('send-many')"
                        />

                        <v-button
                            v-if="isCustomerService()"
                            ref="map-button"
                            class="mt-2 w-full"
                            label="Send Using Map"
                            button-style="default"
                            @click="$router.push({name: 'send-notes-with-map'})"
                        />
                    </div>

                    <div class="flex-1 flex-col p-10">
                        <h3 class="text-2xl mb-6 font-semibold text-gray-800 text-center">Send to one person</h3>
                        <autocomplete-input
                            ref="name-input"
                            v-model="contact.name"
                            :options="contacts"
                            :loading="loading"
                            :form-errors="formErrors['name']"
                            @select="$emit('contact-selected', $event)"
                            @input="$emit('search', $event)"
                        >
                            <template #default="{option}">{{ option.name }}</template>
                        </autocomplete-input>
                        <v-input
                            ref="email-input"
                            v-model="contact.email"
                            class="mb-6"
                            label="Email"
                            :errors="formErrors['email']"
                        />
                        <v-input
                            ref="address1-input"
                            v-model="contact.address.address1"
                            class="mb-6"
                            label="Address"
                            :errors="formErrors['address.address1']"
                        />
                        <v-input
                            ref="address2-input"
                            v-model="contact.address.address2"
                            class="mb-6"
                            label="Address 2"
                            :errors="formErrors['address.address2']"
                        />
                        <v-input
                            ref="city-input"
                            v-model="contact.address.city"
                            class="mb-6"
                            label="City"
                            :errors="formErrors['address.city']"
                        />
                        <div class="flex flex-col md:flex-row items-stretch mb-6 -mx-2 -my-2">
                            <v-input
                                ref="state-input"
                                v-model="contact.address.state"
                                class="flex-1 mx-2 my-2"
                                label="State"
                                :errors="formErrors['address.state']"
                            />
                            <v-input
                                ref="postal-code-input"
                                v-model="contact.address.postcode"
                                class="flex-1 mx-2 my-2"
                                label="Postal Code"
                                :errors="formErrors['address.postcode']"
                            />
                        </div>
                        <v-input
                            ref="country-input"
                            v-model="contact.address.country"
                            class="mb-6"
                            label="Country"
                            :errors="formErrors['address.country']"
                        />
                        <v-button
                            ref="send-one-button"
                            :disabled="isInvalid"
                            class="mt-2 w-full"
                            label="Next"
                            button-style="primary"
                            :loading="loading"
                            @click="$emit('send-one')"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AutocompleteInput from '@outreach/components/shared/AutocompleteInput';
import Steps from '@outreach/pages/Steps';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import { hasPermission } from '@outreach/mixins/userHasPermission';

export default {
    components: { AutocompleteInput, Steps, VButton, VInput },
    mixins: [hasPermission],
    props: {
        loading: { type: Boolean, default: false },
        contact: { type: Object, default: () => {} },
        contacts: { type: Array, default: () => [] },
        formErrors: { type: Object, default: () => {} }
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        isInvalid () {
            return !this.contact.name;
        }
    }

};
</script>
