import loader from './loader';
import cards from './cards';
import user from './user';
import campaign from './campaign';
import submitted from './campaign/submitted';
import letters from './letters';
import review from './review';
import modals from './modals';
import templates from './templates';
import userTemplates from './templates/user-templates';
import doodles from './doodles';
import addresses from './addresses';
import links from './links';
import queue from './queue';
import completed from './queue/completed';
import pending from './queue/pending';
import reserved from './queue/reserved';
import events from './events';
import accounts from './accounts';
import articles from './articles';
import alerts from './alerts';
import imageLibrary from './image-library';
import campaignDeliveries from './campaignDeliveries';
import collaterals from './collaterals';
import associates from './associates';
import teams from './teams';
import notifications from './notifications';

export default {
    loader,
    cards,
    user,
    campaign,
    submitted,
    letters,
    review,
    modals,
    templates,
    userTemplates,
    doodles,
    addresses,
    links,
    queue,
    completed,
    pending,
    reserved,
    events,
    accounts,
    articles,
    alerts,
    imageLibrary,
    campaignDeliveries,
    collaterals,
    associates,
    teams,
    notifications
};
