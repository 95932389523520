<template>
    <campaign-review
        v-if="campaign"
        ref="campaign-review"
        :balance="user.account.credits"
        :campaign="campaign"
        :collaterals="collaterals"
        :deliveries="deliveries"
        :letter="letter"
        :fonts="fonts"
        :steps="steps"
        :loading="loading"
        :order-submitted="orderSubmitted"
        :options="options"
        :previews="previews"
        :price="2"
        :selected-contact="selectedDelivery"
        @edit="edit"
        @select-contact="handleSelectDelivery"
        @submit="handleSubmit"
        @duplicate="handleDuplicate"
        @rejected="reject"
        @update:collateral-ids="handleToggleCollateral"
        @update:delivery-options="handleUpdateDeliveryOptions"
        @update:destination="handleUpdateDestination"
        @update:font_id="handleUpdateFont"
        @update:campaign_name="handleUpdateCampaignName"
        @update:sender_address_id="updateSenderAddress"
        @update:send_at="updateCampaignField({ send_at: $event })"
        @notify-user="handleNotifyUser"
        @update-layout-type="handleUpdateLayoutType"
        @get-more-deliveries="getDeliveries({ campaignId: campaign.id })"
        @set-delivery-query="setDeliveryQuery({ query: $event, campaignId: campaign.id })"
        @discard-post-it="discardPostIt(selectedDelivery)"
    />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CampaignReview from '@outreach/pages/CampaignReview/CampaignReview';
import services from '@services/Api';

export default {
    components: { CampaignReview },
    props: {
        steps: { type: Array, required: false, default: () => ['Contacts', 'Compose', 'Review'] }
    },
    data: () => ({
        options: {
            collaterals: [],
            destination: ''
        },
        orderLoading: false,
        orderSubmitted: false
    }),
    computed: {
        ...mapState({
            contact: ({ review }) => review.contact,
            collaterals: ({ review }) => review.collaterals,
            deliveries: ({ campaignDeliveries }) => campaignDeliveries.deliveries,
            selectedDelivery: ({ campaignDeliveries }) => campaignDeliveries.selected,
            fonts: ({ review }) => review.fonts,
            loading: ({ review }) => review.loading,
            previews: ({ review }) => review.previews,
            campaign: ({ review }) => review.campaign,
            user: ({ user }) => user.user,
            letter: ({ letters }) => letters.letter,
            isLoading: ({ loader }) => loader,
            formErrors: ({ review }) => review.formErrors
        }),
        campaignId () {
            return this.$route.params.campaign;
        }
    },
    watch: {
        campaign () {
            this.syncDeliveryOptions();
        },
        formErrors (newFormErrors, oldFormErrors) {
            if (newFormErrors.contacts && !oldFormErrors.contacts) {
                this.setMessage({ messageType: 'error', message: newFormErrors.contacts });
            }
        }
    },
    async created () {
        this.clearPreview();
        await this.load(this.campaignId);
        await this.getLetter(this.campaign.id);
    },
    methods: {
        ...mapActions({
            load: 'review/load',
            refreshDeliveries: 'campaignDeliveries/refreshDeliveries',
            selectDelivery: 'campaignDeliveries/select',
            setDeliveryQuery: 'campaignDeliveries/setQuery',
            getDeliveries: 'campaignDeliveries/getNextPage',
            updateDeliveryOptions: 'review/updateDeliveryOptions',
            updateFont: 'review/updateFont',
            updatePhoto: 'review/updatePhoto',
            updatePhotoAll: 'review/updatePhotoAll',
            updateSenderAddress: 'review/updateSenderAddress',
            getUser: 'user/getUser',
            submit: 'review/submit',
            duplicate: 'review/duplicate',
            reject: 'review/reject',
            getLetter: 'letters/get',
            updateLetter: 'letters/update',
            preview: 'review/preview',
            clearPreview: 'review/clearPreview',
            setMessage: 'alerts/setMessage',
            updateCampaign: 'review/updateCampaign',
            discardPostIt: 'review/discardPostIt',
            updateCampaignField: 'review/updateCampaignField'
        }),
        async handleUpdateFont (font_id) {
            await this.updateLetter({ ...this.letter, font_id });
            await this.preview({ campaignId: this.campaignId });
        },
        edit () {
            this.$router.push({ name: 'campaign-compose', params: { campaign: this.campaign.id } });
        },
        syncDeliveryOptions () {
            this.options = {
                collaterals: this.campaign.collaterals.map(collateral => collateral.id),
                destination: this.campaign.destination
            };
        },
        handleToggleCollateral (id) {
            if (!this.options.collaterals.includes(id)) {
                this.options.collaterals = [...this.options.collaterals, id];
            } else {
                this.options.collaterals = this.options.collaterals.filter(item => item !== id);
            }
        },
        handleUpdateDestination (destination) {
            this.options.destination = destination;
        },
        handleUpdateDeliveryOptions (close) {
            this.updateDeliveryOptions(this.options);
            close();
        },
        async handleSubmit () {
            try {
                await this.submit();
                this.orderSubmitted = true;
            } catch (e) {
                this.setMessage({ messageType: 'error', message: 'Unable to submit campaign.' });
            }
        },
        async handleDuplicate () {
            const id = await this.duplicate(this.campaignId);
            this.$router.push({ name: 'campaign-compose', params: { campaign: parseInt(id, 10) } });
        },
        async handleRejected () {
            const id = await this.duplicate(this.campaignId);
            this.$router.push({ name: 'campaign-compose', params: { campaign: parseInt(id, 10) } });
        },
        async handleNotifyUser (campaignId, userId) {
            try {
                await services.campaigns.sendSuggestion(campaignId, userId);
                this.$toasted.success('Successfully sent e-mail.');
            } catch (e) {
                this.$toasted.error('There was an error sending the e-mail.');
            }
        },
        async handleUpdateLayoutType (value) {
            await this.updateLetter({ ...this.letter, layout_type: value });
            await this.preview({ campaignId: this.campaignId });
        },
        async handleSelectDelivery (deliveryId) {
            const delivery = this.deliveries.find(delivery => delivery.contact_id === parseInt(deliveryId, 0));
            this.selectDelivery(delivery);
            await this.preview({ campaignId: this.campaignId, contactId: delivery.contact_id });
        },
        async handleUpdateCampaignName () {
            await this.updateCampaign();
        }
    }
};
</script>
