<template>
    <i :class="icon" class="text-2xl"></i>
</template>

<script>
export default {
    props: {
        brand: { type: String, required: true }
    },
    computed: {
        icon () {
            const icons = {
                visa: 'fab fa-cc-visa',
                mastercard: 'fab fa-cc-mastercard',
                amex: 'fab fa-cc-amex',
                default: 'fas fa-credit-card'
            };
            return icons[this.brand.toLowerCase()] || icons.default;
        }
    }
};
</script>
