export const state = {
    loading: false,
    requestsPending: 0
};

export const actions = {
    show: ({ commit }) => commit('show'),
    hide: ({ commit }) => commit('hide'),
    pending: ({ commit }) => {
        commit('show');
        commit('pending');
    },
    cancel: ({ commit }) => commit('done'),
    done: ({ commit, getters }) => {
        commit('done');
        if (getters.requestsPending <= 1) {
            commit('hide');
        }
    }
};

export const mutations = {
    show: (state) => {
        state.loading = true;
    },
    hide: (state) => {
        state.loading = false;
    },
    pending: (state) => {
        state.requestsPending++;
    },
    done: (state) => {
        state.requestsPending = Math.max(0, state.requestsPending - 1);
    }
};

export const getters = {
    isLoading: (state) => state.loading,
    requestsPending: (state) => state.requestsPending
};

export default { namespaced: true, state, actions, mutations, getters };
