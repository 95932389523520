<template>
    <div>
        <div class="text-center font-bold py-4">
            Select the type of content you want to create
        </div>

        <div class="flex items-center mx-auto max-w-md justify-center text-center text-gray-600">
            <button
                class="w-1/2 mr-2 bg-gray-50 rounded shadow-sm hover:shadow-lg p-8"
                @click="$emit('selected','standard')"
            >
                <i class="fa fa-5x fa-regular fa-image"></i>
                <div class="w-24 mx-auto">
                    <div class="h-2 bg-gray-400 mt-1"></div>
                    <div class="h-2 bg-gray-300 mt-1"></div>
                    <div class="h-2 bg-gray-200 mt-px"></div>
                </div>
                <div class="mt-4">Standard Content</div>
            </button>
            <button
                class=" w-1/2 ml-2 bg-gray-50 rounded shadow-sm hover:shadow-lg p-8"
                @click="$emit('selected','video')"
            >
                <i class="fab fa-5x fa-regular fa-youtube"></i>
                <div class="w-24 mx-auto">
                    <div class="h-2 bg-gray-400 mt-1"></div>
                    <div class="h-2 bg-gray-300 mt-1"></div>
                    <div class="h-2 bg-gray-200 mt-px"></div>
                </div>
                <div class="mt-4">Video Content</div>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ArticleTypeSelector'
};
</script>
