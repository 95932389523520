<template>
    <div class="relative">
        <map-controls
            v-show="isDrawing"
            :control-mode="controlMode"
            :selected="polygonSelected"
            class="absolute top-2 left-2"
            @type="drawTypeChanged"
            @mode="onDrawModeChanged"
            @trash="removePolygon"
            @cancel="unselectPolygon"
        />
        <google-map :service="service" class="min-h-[400px] lg:min-h-[600px]" @init="onMapInitialized" />
    </div>
</template>

<script>
import GoogleMap from '@outreach/pages/OutboundProspecting/GoogleMap';
import { METERS_PER_MILE } from '@global/config';
import { GoogleService } from '@outreach/pages/OutboundProspecting/GoogleService';
import MapControls from '@outreach/pages/OutboundProspecting/MapControls.vue';
import { ControlMode, DrawType } from '@definitions/MapTypes';

export default {
    name: 'ProspectMap',
    components: {
        MapControls,
        GoogleMap
    },
    props: {
        place: { type: Object, required: false, default: () => ({}) },
        radius: { type: Number, default: 0 },
        results: { type: Array, required: false, default: () => [] },
        isDrawing: { type: Boolean, default: false }
    },
    data () {
        return {
            map: null,
            marker: null,
            circle: null,
            service: new GoogleService(),
            polygonSelected: false,
            controlMode: ControlMode.MOVE,
            drawType: DrawType.FREE_DRAW
        };
    },
    computed: {
        radiusInMeters () {
            return (this.radius || 0) * METERS_PER_MILE;
        }
    },
    watch: {
        place (newPlace) {
            if (!newPlace || !newPlace.geometry) return;
            this.service.removePolygon();
            this.service.centerMarker.setMap(null);
            this.initializeMarker();
            this.initializeCircle();
            this.service.centerMarker.setPosition(newPlace.geometry.location);
            this.service.map.setCenter(newPlace.geometry.location);
            this.service.centerMarker.setVisible();
            this.service.zoomMap();
            this.updateCircle();
        },
        radius () {
            this.updateCircle();
        },
        results () {
            if (this.results.length === 0) {
                this.service.clearCluster();
                return;
            }

            this.service.rebuildCluster(this.results);
        },
        isDrawing () {
            this.removePolygon();
            this.service.disableDrawing();
        }
    },
    methods: {
        updateCircle () {
            this.service
                .polygon
                .setPath(
                    this.service.polygonPath(this.service.centerMarker.getPosition(), this.radiusInMeters, 20)
                );
            this.service.polygon.cluster.clear();
            this.service.zoomMap();
            this.emitPath();
        },
        async drawTypeChanged (type) {
            this.drawType = type;
            this.removePolygon();
            await this.service.switchDrawMode(this.drawType);
            this.onDrawModeChanged(ControlMode.DRAW);
        },
        onMapInitialized () {
            this.initializeMarker();

            this.$emit('init', this.service.map);
            if (this.$route.query.path) {
                this.setInitialPath();
                this.$emit('initialPath');
            }
        },
        initializeCircle () {
            this.service.createPolygon(
                this.service.polygonPath(this.service.map.getCenter(), this.radiusInMeters, 20)
            ).onDrag(() => this.service.centerMarker.setPosition(this.service.polygon.getCenter())).onDragEnd(
                () => {
                    this.updateCircle();
                    this.service.clearCluster();
                    this.$emit('circleMoved');
                }
            );

            this.service.polygon.bindTo('center', this.service.centerMarker, 'position');

            this.setInitialPath();
        },
        initializeMarker () {
            this.service.createCenterMarker();

            this.service.centerMarker.addListener('drag', () => {
                this.updateCircle();
            });
        },
        setInitialPath () {
            if (!this.$route.query.path) {
                return;
            }

            const path = this.$route.query.path.split(';').map(coordinate => {
                const [lat, lng] = coordinate.split(',');

                return new google.maps.LatLng(lat, lng);
            });

            if (!this.service.polygon) {
                this.service.createPolygon(path);
            } else {
                this.service.polygon.setPath(path);
            }
            this.service.centerMarker.setPosition(this.service.getPolygonCenter());
            this.service.zoomMap();
            this.emitPath();
        },
        removePolygon () {
            this.service.removePolygon();
            this.polygonSelected = false;
            this.$emit('searchDetails', { path: null, centroid: null, sqMiles: null });
        },
        unselectPolygon () {
            this.service.polygon.setHighlight(false);
            this.polygonSelected = false;
        },
        emitPath () {
            this.$emit('searchDetails', this.service.polygon.details());
        },
        onDrawModeChanged (mode) {
            this.controlMode = mode;
            mode === ControlMode.DRAW
                ? this.service.enableDrawing(this.onPolygonComplete)
                : this.service.disableDrawing();
        },
        onPolygonComplete (polygon) {
            this.emitPath();
            this.service.clearCluster();
            this.polygonSelected = false;
            this.service.disableDrawing();
            this.controlMode = ControlMode.MOVE;
            polygon
                .onDragEnd(() => {
                    this.service.clearCluster();
                    this.service.zoomMap();
                    this.emitPath();
                })
                .onClick(() => {
                    this.polygonSelected = true;
                    if (this.drawType === DrawType.FREE_DRAW) this.service.polygon.setHighlight(true);
                });
        }
    }
};
</script>
