<template>
    <div class="container mx-auto">
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading doodles</span>
            </div>
            <div v-else>
                <div v-if="doodles.length > 0">
                    <v-table
                        is-elevated
                        has-actions
                        :rows="doodles"
                    >
                        <template #header>
                            <div class="flex justify-end">
                                <v-button
                                    label="Add Doodle"
                                    button-style="primary"
                                    @click="openDoodleFormModal"
                                />
                            </div>
                        </template>
                        <template #default="{ row }">
                            <v-table-column label="Preview" value="svg">
                                <svg height="50" viewBox="0 0 300 200" v-html="row.svg" />
                            </v-table-column>
                            <v-table-column is-center label="Title" value="title">
                                <span>{{ row.title }}</span>
                            </v-table-column>
                        </template>
                        <template #dropdown="{ row }">
                            <button @click="openDoodleFormModal(row, true)">Edit</button>
                            <button @click="destroy(row.id)">Delete</button>
                        </template>
                    </v-table>
                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="getDoodles"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No doodles found.</p>
                    </div>
                </div>
            </div>
        </transition>
        <modal
            :header="isUpdate ? 'Update Doodle' : 'Create Doodle'"
            :open="showModal"
            :disable-on-click-away="true"
            @close-modal="closeDoodleFormModal"
        >
            <template slot="content">
                <div class="flex w-modal">
                    <doodle-form
                        :doodle.sync="formDoodle"
                        :form-errors="formErrors"
                    />
                </div>
            </template>
            <template slot="modal-actions">
                <div class="p-2">
                    <v-button
                        label="cancel"
                        :disabled="loading"
                        :loading="loading"
                        class="mr-2"
                        @click="closeDoodleFormModal"
                    />
                    <v-button
                        label="Save"
                        button-style="primary"
                        :disabled="loading"
                        :loading="loading"
                        @click="submit"
                    />
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import services from '@services/Api';
import Modal from '@outreach/components/shared/modal';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import VLoader from '@outreach/components/VLoader';
import VButton from '@outreach/components/VButton';
import DoodleForm from '../../components/doodle/DoodleForm';
import VPaginator from '@outreach/components/VPaginator';
import { cloneDeep } from 'lodash';
import get from 'lodash/get';
import ClientVisibleError from '@exceptions/ClientVisibleError';

const save = (doodle) => {
    if (doodle.id) {
        return services.doodles.update(doodle.id, doodle);
    }
    return services.doodles.create(doodle);
};

export default {
    components: {
        VLoader,
        VButton,
        VTable,
        VTableColumn,
        Modal,
        DoodleForm,
        VPaginator
    },
    data: () => ({
        loading: true,
        showModal: false,
        isUpdate: false,
        doodles: [],
        formDoodle: {
            id: null,
            title: null,
            svg: null
        },
        deleting: null,
        meta: {},
        formErrors: {}
    }),
    created () {
        this.getDoodles();
    },
    methods: {
        async getDoodles (page = 1) {
            this.loading = true;
            const { data } = await services.doodles.list({ page });

            this.doodles = data.data;
            this.meta = data.meta;
            this.loading = false;
        },
        async destroy (doodleId) {
            this.loading = true;
            try {
                await services.doodles.destroy(doodleId);
                await this.getDoodles();
            } catch (e) {
                throw new ClientVisibleError('Could not delete this doodle.');
            } finally {
                this.loading = false;
            }
        },
        openDoodleFormModal (doodle, isUpdate = false) {
            this.isUpdate = isUpdate;
            if (doodle) {
                this.formDoodle = cloneDeep(doodle);
            }
            this.showModal = true;
        },
        closeDoodleFormModal () {
            this.showModal = false;
            this.formDoodle = {};
        },
        async submit () {
            this.formErrors = {};
            this.loading = true;

            try {
                await save(this.formDoodle);
                this.closeDoodleFormModal();
                this.formErrors = {};
                await this.getDoodles();
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});

                throw new ClientVisibleError(`Could not ${this.formDoodle.id ? 'update' : 'create'} doodle.`);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
