<template>
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
    >
        <div class="relative w-56 rounded-md shadow-lg bg-white border border-gray-200 focus:outline-none">
            <div class="py-1">
                <button
                    v-for="(item, index) in items"
                    :ref="item"
                    :key="index"
                    class="block px-4 py-2 text-sm w-full"
                    :class="{
                        'bg-gray-100 text-gray-900': index === selectedIndex,
                        'text-gray-700': index !== selectedIndex
                    }"
                    @click="selectItem(index)"
                >
                    {{ item }}
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },

        command: {
            type: Function,
            required: true
        }
    },

    data () {
        return {
            selectedIndex: 0
        };
    },

    watch: {
        items () {
            this.selectedIndex = 0;
        }
    },

    methods: {
        onKeyDown ({ event }) {
            if (event.key === 'ArrowUp') {
                this.upHandler();
                return true;
            }

            if (event.key === 'ArrowDown') {
                this.downHandler();
                return true;
            }

            if (event.key === 'Enter') {
                this.enterHandler();
                return true;
            }

            return false;
        },

        upHandler () {
            this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length;
        },

        downHandler () {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
        },

        enterHandler () {
            this.selectItem(this.selectedIndex);
        },

        selectItem (index) {
            const item = this.items[index];

            if (item) {
                this.command({ id: item });
            }
        }
    }
};
</script>
