<template>
    <div class="notification-dropdown">
        <div class="flex border-b">
            <span class="p-4 md:py-2 flex-1">Notifications</span>
            <button
                class="toggle-show-all-button"
                @click="$emit('toggle-show-all')"
            >
                {{ showingAll ? 'Hide read' : 'Show all' }}
            </button>
            <button class="px-6 md:hidden" @click="$emit('close')">
                <i class="fal fa-times"></i>
            </button>
        </div>
        <div v-if="notifications.length === 0" class="empty-message">
            No notifications yet.
        </div>
        <div class="flex flex-col overflow-auto">
            <transition-group name="fade">
                <notification-card
                    v-for="notification in notifications"
                    :key="notification.id"
                    :notification="notification"
                    @toggle-read="$emit('toggle-read', notification.id)"
                />
            </transition-group>
        </div>
    </div>
</template>
<script>

import NotificationCard from '@outreach/components/notification/NotificationCard.vue';

export default {
    components: { NotificationCard },
    props: {
        notifications: { type: Array, required: true },
        showingAll: { type: Boolean, required: true }
    }
};
</script>
<style>
.notification-dropdown {
    @apply
    fixed md:absolute
    top-0 right-0 md:top-auto md:right-1
    w-screen h-screen md:max-w-sm md:h-fit md:max-h-[70vh]
    flex flex-col
    bg-white shadow overflow-hidden border
    md:rounded-lg md:mt-1
}

.empty-message {
    @apply p-4 text-center text-gray-600
}

.toggle-show-all-button {
    @apply text-sm text-blue-600 dark:text-blue-500 hover:underline pr-4
}
</style>
