import AccountWrapper from '@outreach/pages/account/Index';
import Account from '@outreach/pages/account/Account';
import User from '@outreach/pages/account/User';
import Password from '@outreach/pages/account/Password';
import Addresses from '@outreach/pages/account/Addresses';
import OrderHistory from '@outreach/pages/account/OrderHistory';
import AddContact from '@outreach/pages/AddContact';
import EditContact from '@outreach/pages/EditContact';
import Tags from '@outreach/pages/Tags';
import UserTemplates from '@outreach/pages/Templates/UserTemplates';
import AddUserTemplate from '@outreach/pages/Templates/AddUserTemplate';
import EditUserTemplate from '@outreach/pages/Templates/EditUserTemplate';
import UserArticles from '@outreach/pages/Articles/UserArticles';
import ViewArticle from '@outreach/pages/Articles/ViewArticle';
import Graphics from '@outreach/pages/Graphics';
import Pricing from '@outreach/pages/Payment/Pricing.vue';

export default [
    {
        path: '/account',
        component: AccountWrapper,
        children: [
            {
                path: ':accountId/edit',
                name: 'edit-account',
                component: Account,
                meta: {
                    title: 'Edit Account',
                    breadcrumbs: [
                        { name: 'admin-accounts', label: 'Accounts' },
                        { name: 'edit-account', label: 'Edit Account' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: 'profile',
                name: 'user',
                component: User,
                meta: {
                    title: 'Profile',
                    breadcrumbs: [{ name: 'user', label: 'Profile' }]
                }
            },
            {
                path: 'password',
                name: 'password',
                component: Password,
                meta: {
                    title: 'Password',
                    breadcrumbs: [{ name: 'user', label: 'Account' }, { name: 'password', label: 'Password' }]
                }
            },
            {
                path: 'addresses',
                name: 'addresses',
                component: Addresses,
                meta: {
                    title: 'Addresses',
                    breadcrumbs: [{ name: 'user', label: 'Account' }, { name: 'addresses', label: 'Addresses' }]
                }
            },
            {
                path: ':accountId/addresses',
                name: 'account-addresses',
                component: Addresses,
                meta: {
                    title: 'Addresses',
                    breadcrumbs: [{ name: 'user', label: 'Account' }, { name: 'addresses', label: 'Addresses' }]
                }
            },
            {
                path: 'credits',
                name: 'credits',
                component: Pricing,
                meta: {
                    title: 'Credits',
                    breadcrumbs: [
                        { name: 'user', label: 'Account' },
                        { name: 'credits', label: 'Credits' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: 'order-history',
                name: 'order-history',
                component: OrderHistory,
                meta: {
                    title: 'Order History',
                    breadcrumbs: [{ name: 'user', label: 'Account' }, { name: 'order-history', label: 'Order History' }]
                }
            },
            {
                path: ':accountId/contacts/add',
                name: 'add-contact',
                component: AddContact,
                meta: {
                    title: 'Add Contact',
                    breadcrumbs: [
                        { name: 'contacts', label: 'Contacts' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/:accountId/contact/:contact/edit',
                name: 'edit-contact',
                component: EditContact,
                meta: {
                    title: 'Edit Contact',
                    breadcrumbs: [
                        { name: 'contacts', label: 'Contacts' },
                        { name: 'edit-contact', label: 'Edit Contact' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/:accountId/tags',
                name: 'tags',
                component: Tags,
                meta: {
                    title: 'Tags',
                    breadcrumbs: [
                        { name: 'tags', label: 'Tags' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/graphics',
                name: 'graphics',
                component: Graphics,
                meta: {
                    title: 'Graphics',
                    breadcrumbs: [
                        { name: 'graphics', label: 'Graphics' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/templates',
                name: 'templates',
                component: UserTemplates,
                meta: {
                    title: 'Templates',
                    breadcrumbs: [
                        { name: 'templates', label: 'Templates' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/templates/add',
                name: 'add-template',
                component: AddUserTemplate,
                meta: {
                    title: 'Add Template',
                    breadcrumbs: [
                        { name: 'templates', label: 'Templates' },
                        { name: 'add-template', label: 'Add Template' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/templates/:template',
                name: 'edit-template',
                component: EditUserTemplate,
                meta: {
                    title: 'Edit Template',
                    breadcrumbs: [
                        { name: 'templates', label: 'Templates' },
                        { name: 'edit-template', label: 'Edit Template' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: 'social',
                component: AccountWrapper,
                children: [
                    {
                        path: '',
                        name: 'social',
                        component: UserArticles,
                        props: { type: 'standard' },
                        meta: {
                            title: 'AI-Powered Content',
                            breadcrumbs: [
                                { name: 'social', label: 'AI-Powered Content' }
                            ],
                            requiresAuth: true
                        }
                    },
                    {
                        path: ':articleId',
                        name: 'view-article',
                        component: ViewArticle,
                        meta: {
                            title: 'View AI-Powered Content',
                            breadcrumbs: [
                                { name: 'social', label: 'AI-Powered Content' },
                                { name: 'view-article', label: 'View AI-Powered Content' }
                            ],
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'training',
                name: 'training',
                component: UserArticles,
                props: {
                    type: 'video',
                    excludeTags: ['other-training', 'app-training', 'ch-masterclass', 'campaign-training']
                },
                meta: {
                    title: 'Digital Marketing',
                    breadcrumbs: [
                        { name: 'training', label: 'Digital Marketing' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: 'other-training',
                name: 'other-training',
                component: UserArticles,
                props: {
                    type: 'video',
                    requireTag: 'other-training',
                    excludeTags: ['ch-masterclass', 'campaign-training']
                },
                meta: {
                    title: 'Other - Training',
                    breadcrumbs: [
                        { name: 'training', label: 'Other - Training' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: 'app-training',
                name: 'app-training',
                component: UserArticles,
                props: {
                    type: 'video',
                    requireTag: 'app-training',
                    excludeTags: ['ch-masterclass', 'campaign-training']
                },
                meta: {
                    title: 'App - Training',
                    breadcrumbs: [
                        { name: 'training', label: 'App - Training' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: 'campaign-breakdowns',
                name: 'campaign-breakdowns',
                component: UserArticles,
                props: { type: 'standard, video', requireTag: 'campaign-training' },
                meta: {
                    title: 'Campaign Breakdowns',
                    breadcrumbs: [
                        { name: 'campaign-breakdowns', label: 'Campaign Breakdowns' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: 'ch-masterclass',
                name: 'ch-masterclass',
                component: UserArticles,
                props: { type: 'video', requireTag: 'ch-masterclass' },
                meta: {
                    title: 'CH Masterclass',
                    breadcrumbs: [
                        { name: 'training', label: 'Training' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: ':articleId',
                name: 'view-video',
                component: ViewArticle,
                meta: {
                    title: 'View Video',
                    breadcrumbs: [
                        { name: 'social', label: 'Training' },
                        { name: 'view-article', label: 'View Video' }
                    ],
                    requiresAuth: true
                }
            }
        ]
    }
];
