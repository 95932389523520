<template>
    <div class="flex flex-row gap-4">
        <button
            ref="previous-page-button"
            v-tooltip="'Previous Page'"
            type="button"
            class="py-1 px-3 rounded hover:bg-gray-400 mr-4"
            :disabled="isOnFirstPage"
            :class="{ 'pointer-events-none opacity-75': isOnFirstPage }"
            aria-label="Previous Page"
            @click="onClickPreviousPage"
        >
            <i class="fa fa-chevron-left"></i>
        </button>
        <button
            ref="next-page-button"
            v-tooltip="'Next Page'"
            type="button"
            class="py-1 px-3 rounded hover:bg-gray-400 mr-4"
            :disabled="isOnLastPage"
            :class="{ 'pointer-events-none opacity-75': isOnLastPage }"
            aria-label="Next Page"
            @click="onClickNextPage"
        >
            <i class="fa fa-chevron-right"></i>
        </button>
    </div>
</template>

<script>

export default {
    name: 'VSimplePaginator',

    props: {
        pagination: { type: Object, required: true }
    },
    computed: {
        current: {
            get () {
                return this.pagination.current_page;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
        isOnFirstPage () {
            return this.pagination.prev_page_url == null;
        },
        isOnLastPage () {
            return this.pagination.next_page_url == null;
        }
    },
    methods: {
        classes (page) {
            return {
                'bg-brand-regular text-white hover:bg-brand-dark': this.current === page,
                'hidden sm:block': this.current !== page
            };
        },
        onClickPreviousPage () {
            this.$emit('input', this.current - 1);
            this.$emit('previous');
        },
        onClickNextPage () {
            this.$emit('input', this.current + 1);
            this.$emit('next');
        }
    }
};
</script>
