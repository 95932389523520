<template>
    <div class="w-full max-w-3xl mx-auto">
        <v-panel title="Personal Access Tokens" :padded="false">
            <v-table :rows="records" has-actions>
                <template #default="{row}">
                    <v-table-column label="Name" value="Name">
                        {{ row.name }}
                    </v-table-column>
                    <v-table-column label="Token" value="Token">
                        <api-token :show-by-default="row.show" :token="row.token" />
                    </v-table-column>
                </template>
                <template #priority-buttons="{row}">
                    <button :ref="`regenerate-record-${row.id}`" @click="regenerateRecord(row.id)">
                        Regenerate
                    </button>
                </template>
                <template #dropdown="{row}">
                    <button :ref="`delete-record-${row.id}`" @click="deleteRecord(row.id)">
                        Delete
                    </button>
                </template>
            </v-table>
            <template #actionButton>
                <v-button label="Add New" button-style="primary" @click="addNewToken" />
            </template>
        </v-panel>
    </div>
</template>

<script>
import services from '@services/Api';
import VPanel from '@outreach/components/shared/VPanel';
import VTable from '@outreach/components/shared/table/VTable';
import VTableColumn from '@outreach/components/shared/table/VTableColumn';
import VButton from '@outreach/components/VButton';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { removeRecordById } from '@services/ArrayUtils';
import ApiToken from '@outreach/components/ApiToken';

export default {
    name: 'PersonalAccessTokens',
    components: { ApiToken, VTableColumn, VTable, VPanel, VButton },
    data () {
        return { records: undefined };
    },
    mounted () {
        this.fetchAccessCodes();
    },
    methods: {
        async fetchAccessCodes () {
            const { data: { data } } = await services.accessTokens.list();
            this.records = data;
        },
        async deleteRecord (id) {
            // @fixme: move confirm to the destroy method and return a response object with an `isConfirmed` method.
            const message = 'Deleting an access token will cause any services using this token to stop working.';
            if (!await dialogEmitter.confirm('Are you sure?', message)) {
                return;
            }
            await services.accessTokens.destroy(id);
            this.records = removeRecordById(this.records, id);
        },
        async regenerateRecord (id) {
            const message = 'Regenerating an access token will cause any services using this token to stop working.';
            if (!await dialogEmitter.confirm('Are you sure?', message)) {
                return;
            }
            const { data: { data } } = await services.accessTokens.regenerate(id);

            this.records = removeRecordById(this.records, id);
            this.appendNewToken(data);
        },
        addNewToken () {
            dialogEmitter.openDialog('CreateApiToken', {}, {
                success: (token) => {
                    this.appendNewToken(token);
                }
            });
        },
        appendNewToken (token) {
            this.records = [
                { ...token, show: true },
                ...this.records
            ];
        }
    }
};
</script>
