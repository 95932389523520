<template>
    <div class="w-full h-full wrapper">
        <folder-navigation
            v-if="accountId"
            v-model="folder"
            type="images"
            :selected-items="selectedItems"
            :account-id="accountId"
            @moved="onMove"
            @clear="clearSelected"
            @deleted="folder = undefined; fetchImages();"
        />

        <div class="flex gap-4 items-center justify-center py-4">
            <v-input
                ref="search-input"
                v-model="q"
                class="mb-2 flex-1 sm:mb-0"
                label="Search"
            />
            <v-checkbox
                v-if="isAdmin()"
                ref="toggle-archived"
                v-model="showArchived"
                label="Show Deleted"
            />
        </div>

        <div v-if="selectedItems.length" class="leading-none flex items-center text-xl">
            <i
                id="clear-items"
                class="fa fa-times mr-2 font-bold cursor-pointer"
                title="Clear Items"
                @click="clearSelected"
            ></i>
            <div>{{ selectedItems.length }} selected</div>
        </div>

        <paginator-wrap v-if="meta" :change-page="changePage" :pagination="meta">
            <template #content>
                <div v-if="loading" class="flex items-center justify-center p-12">
                    <v-loader />
                </div>
                <ul
                    v-else
                    role="list"
                    class="grid grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-6 mt-4"
                >
                    <li><image-upload class="h-full" @imageUploaded="editImage" /></li>
                    <li v-if="images.length === 0" class="flex items-center justify-center text-gray-500 col-span-3">
                        <i class="fa fa-2x fa-regular fa-image-slash mr-2"></i> No Images Found
                    </li>
                    <image-row
                        v-for="image in images"
                        :ref="`image-${image.id}`"
                        :key="image.location"
                        :image="image"
                        :is-selected="selectedImage && selectedImage.id === image.id"
                        :disable-select="disableSelect"
                        :show-archived="showArchived"
                        :editable="isEditable(image)"
                        :account-id="accountId"
                        :checked="selected.has(image.id)"
                        @check="onCheck(image)"
                        @delete="deleteImage"
                        @update="updateAsset"
                        @onSelect="handleImageClick"
                        @restore="restoreImage"
                    />
                </ul>
            </template>
        </paginator-wrap>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { formatFullDate } from '@services/Filters';
import debounce from 'lodash/debounce';
import { Alert } from '@services/Alert';
import VCheckbox from '@outreach/components/inputs/VCheckbox';
import ImageRow from '@outreach/components/shared/image-library/ImageRow';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import ImageUpload from '@outreach/components/shared/image-library/ImageUpload.vue';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import FolderNavigation from '@outreach/components/shared/image-library/FolderNavigation.vue';
import AssetsUpdated from '@events/AssetsUpdated';

export default {
    name: 'ImageLibrary',
    components: { FolderNavigation, ImageUpload, ImageRow, VCheckbox, VInput, VLoader, PaginatorWrap },
    mixins: [hasPermission],
    props: {
        assetType: { type: String, default: undefined },
        accountId: { type: Number, default: undefined },
        disableSelect: { type: Boolean, default: false }
    },
    data () {
        return {
            editImageId: null,
            q: undefined,
            showArchived: false,
            folder: undefined,
            selected: new Set()
        };
    },
    computed: {
        ...mapGetters({
            images: 'imageLibrary/images'
        }),
        ...mapState({
            loading: ({ loader }) => loader.loading,
            selectedImage: ({ imageLibrary }) => imageLibrary.record,
            meta: ({ imageLibrary }) => imageLibrary.meta,
            user: ({ user }) => user.user
        }),
        selectedItems () {
            return Array.from(this.selected).map(id => this.images.find(image => image.id === id));
        }
    },
    watch: {
        q () {
            this.fetchImages({ page: 1, q: this.q });
        },
        showArchived () {
            this.fetchImages({ page: 1 });
        },
        folder () {
            this.clearSelected();
            this.fetchImages();
        }
    },
    created () {
        this.fetchImages();

        this.subscribe({
            event: AssetsUpdated,
            subscription: () => {
                this.fetchImages();
            }
        });
    },
    destroyed () {
        this.unsubscribe({
            event: AssetsUpdated
        });
    },
    methods: {
        ...mapActions({
            list: 'imageLibrary/list',
            upload: 'imageLibrary/upload',
            update: 'imageLibrary/update',
            select: 'imageLibrary/select',
            destroy: 'imageLibrary/destroy',
            restore: 'imageLibrary/restore',
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        ...mapMutations({
            removeImage: 'imageLibrary/removeRecord'
        }),
        formatFullDate,
        changePage (page) {
            this.clearSelected();
            this.fetchImages({ page });
        },
        async editImage (image) {
            await dialogEmitter
                .name('CropImageDialog')
                .props({ image, assetType: this.assetType })
                .listeners({ croppedImage: (event) => this.uploadImage(event) })
                .wait();
        },
        async uploadImage (file) {
            try {
                await this.upload({ accountId: this.accountId, params: { type: this.assetType, file } });
                await this.fetchImages();
            } catch (e) {
                const message = e.response?.data.message ? e.response.data.message : 'Error uploading image.';
                await Alert.error(message);
            }
        },
        async updateAsset ({ id, name }) {
            await this.update({ assetId: id, accountId: this.accountId, payload: { name } });
        },
        async restoreImage (image) {
            try {
                await this.restore({ assetId: image.id, accountId: this.accountId });
            } catch (e) {
                await Alert.error('Unable to restore asset. Please refresh and try again. ');
            }
        },
        async deleteImage (image) {
            try {
                await this.destroy({ accountId: this.accountId, assetId: image.id, showArchived: this.showArchived });
            } catch (e) {
                await Alert.error('Unable to delete asset. Please refresh and try again. ');
            }
        },
        fetchImages: debounce(async function (params = {}) {
            await this.list({
                accountId: this.accountId,
                params: {
                    ...params,
                    type: this.assetType,
                    archived: this.showArchived ? true : undefined,
                    tags: this.folder?.id ? [{ id: this.folder?.id }] : undefined
                }
            });
        }, 500),
        handleImageClick (image) {
            this.select(image);
        },
        isEditable (image) {
            return this.accountId ? image.account_id === this.accountId : this.isAdmin();
        },
        onCheck (image) {
            const values = new Set(this.selected);
            this.selected.has(image.id) ? values.delete(image.id) : values.add(image.id);
            this.selected = values;
        },
        onMove () {
            this.selected.forEach((value) => this.removeImage(value));
            this.clearSelected();
        },
        clearSelected () {
            this.selected = new Set();
        }
    }
};
</script>
