<template>
    <base-widget :method="getScanSummary" :report-link="{ name: 'scan-summary-report' }">
        <template #content="{data}">
            <p class="font-bold text-sm text-gray-700">Scan Summary</p>
            <p ref="scan-total" class="text-4xl">{{ formatNumber(data.total) }}</p>
            <line-graph :data="processChart(data).data" :labels="processChart(data).labels" />
            <div v-if="data.accounts" class="divide-y divide-gray-200 border-t border-b border-gray-200 mt-4 -mx-4">
                <router-link
                    v-for="record in data.accounts"
                    :ref="`account-link-${record.account.id}`"
                    :key="record.account.id"
                    :to="{name: 'scan-summary-report', query: { accountId: record.account.id}}"
                    class="py-2 flex justify-between hover:bg-gray-200 px-4"
                >
                    <p>{{ record.account.name }}</p>
                    <p class="font-bold">{{ formatNumber(record.total) }}</p>
                </router-link>
            </div>
        </template>
    </base-widget>
</template>

<script>

import services from '@services/Api';
import LineGraph from '@outreach/components/widgets/LineGraph';
import { formatNumber } from '@services/Utils';
import moment from 'moment';
import BaseWidget from '@outreach/components/widgets/BaseWidget';

export default {
    name: 'ScanSummaryWidget',
    components: { BaseWidget, LineGraph },
    data () {
        return {
            error: false
        };
    },
    computed: {

    },
    methods: {
        formatNumber,
        async getScanSummary () {
            const { data: { data } } = await services.admin.widgets.getWeeklyScanSummary();
            return data;
        },
        processChart (data) {
            return (data.records || []).reduce((carry, item) => {
                return {
                    labels: [...carry.labels, moment(item.date).format('ddd')],
                    data: [...carry.data, item.total]
                };
            }, { labels: [], data: [] });
        }
    }
};

</script>
