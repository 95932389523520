<template>
    <div v-if="!isLoading" class="mx-auto max-w-5xl">
        <v-card class="py-6">
            <template slot="body">
                <contact-form
                    key="contact"
                    class="mb-10"
                    :audience-id="audienceId"
                    :contact.sync="contact"
                    :form-errors="formErrors"
                />
                <div class="flex justify-end">
                    <v-button
                        class="mr-2"
                        label="Cancel"
                        @click="$router.back()"
                    />
                    <div class="flex items-center justify-end mr-10">
                        <v-button
                            label="Save Contact"
                            button-style="primary"
                            :loading="isSaving"
                            @click="addContact"
                        />
                    </div>
                </div>
            </template>
        </v-card>
    </div>
</template>

<script>
import services from '@services/Api';
import ContactForm from './ContactForm';
import { mapState } from 'vuex';
import get from 'lodash/get';
import VCard from '@outreach/components/VCard';
import VButton from '@outreach/components/VButton';

const defaultFormErrors = {
    address: {}
};

export default {
    components: { ContactForm, VCard, VButton },
    data: () => ({
        formErrors: {},
        isLoading: false,
        isSaving: false,
        contact: {
            name: null,
            email: null,
            avatar: null,
            address: {
                address1: null,
                address2: null,
                city: null,
                state: null,
                country: null,
                postcode: null
            },
            external_id: null,
            details: {}
        }
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        accountId () {
            return this.$route.params.accountId || this.user.account_id;
        },
        audienceId () {
            return parseInt(this.$route.params.audience || get(this.contact, 'audience_id'));
        }
    },
    methods: {
        async addContact () {
            this.isSaving = true;
            this.formErrors = defaultFormErrors;
            try {
                await services.contacts.create(this.accountId, this.contact);
                this.$router.push({ name: 'contacts', params: this.accountId });
            } catch (error) {
                this.handleErrors(error.response);
            }
            this.isSaving = false;
        },
        handleErrors (response) {
            if (response.data && response.data.errors) {
                this.formErrors = response.data.errors;
            }
        }
    }
};
</script>
