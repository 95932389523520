<script>
import TagSelect from '@outreach/components/tag-select/TagSelect';
import services from '@services/Api';

export default {
    name: 'AdminTagSelect',
    extends: TagSelect,
    methods: {
        list (params) {
            return services.admin.tags.list(params);
        },
        create (params) {
            return services.admin.tags.create(params);
        }
    }
};
</script>
