"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeRecordById = void 0;
function removeRecordById(records, id) {
    const result = [...records];
    const index = result.findIndex(record => record.id === id);
    if (index >= 0) {
        result.splice(index, 1);
    }
    return result;
}
exports.removeRecordById = removeRecordById;
