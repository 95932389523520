<template>
    <v-card has-header>
        <template #header>
            Checkout
        </template>
        <template #header-actions>
            <button id="back" class="text-gray-600 hover:text-brand-regular" @click="$emit('back')">
                <i class="fa-solid fa-arrow-left"></i>
                Back
            </button>
        </template>
        <template #body>
            <payment
                :total="formatToCurrency(plan.totalPrice)"
                :card-id.sync="cardId"
                :can-submit="Boolean(cardId)"
                @submit="submit"
            >
                <div>
                    <div class="flex items-end gap-2 mb-2">
                        <v-logo class="mb-2" />
                        <div class="text-3xl font-normal text-gray-800">{{ plan.name }}</div>
                    </div>
                    <div class="grid grid-cols-2 gap-8 xl:w-1/2">
                        <div class="mb-2">

                            <div class="text-md text-gray-600">Note credits</div>
                            <div v-if="plan.savings" class="text-md text-gray-600">Regular Price</div>
                            <div v-if="plan.savings" class="text-md text-gray-600">Savings</div>
                            <div class="text-md text-gray-600">Total</div>
                        </div>
                        <div class="mb-2">
                            <div class="text-md text-gray-800 font-bold ">{{ plan.credits }}</div>
                            <div v-if="plan.savings" class="text-md text-gray-600 font-bold line-through">
                                {{ formatToCurrency(plan.fullPrice) }}
                            </div>
                            <div v-if="plan.savings" class="text-md text-gray-800 font-bold ">
                                {{ formatToCurrency(plan.discount) }}
                            </div>
                            <div class="text-md text-gray-800 font-bold ">
                                {{ formatToCurrency(plan.totalPrice) }}
                            </div>
                        </div>
                    </div>
                </div>
            </payment>
        </template>
    </v-card>
</template>

<script>
import Payment from '@outreach/pages/Payment/Payment.vue';
import VCard from '@outreach/components/VCard.vue';
import { formatToCurrency } from '@services/Utils';
import { Alert } from '@services/Alert';
import { mapActions, mapState } from 'vuex';
import services from '@services/Api';
import VLogo from '@outreach/components/shared/VLogo.vue';

export default {
    name: 'PlanPayment',
    components: { VLogo, VCard, Payment },
    props: {
        plan: { type: Object, required: true }
    },
    data () {
        return {
            cardId: null
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    methods: {
        formatToCurrency,
        ...mapActions({
            getUser: 'user/getUser'
        }),
        async submit () {
            try {
                await services.orders.create(this.user.account_id, {
                    quantity: this.plan.credits,
                    card_id: this.cardId
                });
                await this.getUser();
                this.$emit('purchased');
                await Alert.success(
                    `Your Purchase Was Successful! You're ready to send ${this.plan.credits} more notes.`
                );
            } catch (e) {
                await Alert.fromError(e, 'Unable to process payment. Please contact support@audience.co.');
            }
        }
    }
};
</script>
