import Admin from '@outreach/pages/admin';
import Properties from '@outreach/pages/sales/Properties';
import ReservedProperties from '@outreach/pages/sales/ReservedProperties';
import Agents from '@outreach/pages/admin/agents/Agents.vue';
import UpdateAgent from '@outreach/pages/admin/agents/UpdateAgent.vue';

export default [
    {
        path: '/sales',
        component: Admin,
        children: [
            {
                path: '/sales/properties',
                name: 'list-properties',
                component: Properties,
                meta: {
                    title: 'Properties',
                    requiresAuth: true
                }
            },
            {
                path: '/sales/reserved-properties',
                name: 'reserved-properties',
                component: ReservedProperties,
                meta: {
                    title: 'Reserved Properties',
                    requiresAuth: true
                }
            },
            {
                path: '/sales/agents',
                name: 'agents',
                component: Agents,
                meta: {
                    title: 'Agents',
                    breadcrumbs: [{ name: 'agents', label: 'Agents' }],
                    requiresAuth: true
                }
            },
            {
                path: '/sales/agents/:agent',
                name: 'update-agent',
                component: UpdateAgent,
                meta: {
                    title: 'Update Agent',
                    breadcrumbs: [
                        { name: 'agents', label: 'Agents' },
                        { name: 'update-agents', label: 'Update Agents' }
                    ],
                    requiresAuth: true
                }
            }
        ]
    }
];
