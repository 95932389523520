var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"py-6 px-5 md:px-0 md:pt-0 empty:hidden"},[_vm._t("header")],2),_vm._v(" "),_c('table',{staticClass:"w-full divide-y divide-gray-300",class:{'shadow border md:rounded-lg': _vm.isElevated}},[_c('thead',{staticClass:"bg-gray-50"},[_c('tr',[(_vm.hasSelection)?_c('th',{staticClass:"py-3.5 pl-4 pr-3 sm:pl-6"},[_c('v-checkbox',{attrs:{"value":_vm.allSelected,"size":"sm"},on:{"input":_vm.selectAll}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.normalizedColumns),function(column,index){
var _obj;
return _c('th',{key:index,staticClass:"py-3.5 pl-4 pr-3 sm:pl-6 text-sm font-semibold text-gray-900",class:( _obj = {}, _obj[column.colAlign] = true, _obj ),style:({width: _vm.width}),attrs:{"scope":"col"}},[(!column.sortable)?_c('span',{staticClass:"table-column-label whitespace-no-wrap ellipsis"},[_vm._v(_vm._s(column.label))]):_c('button',{staticClass:"inline-flex",class:{'font-bold': _vm.sort.key === column.value },on:{"click":function($event){return _vm.sorted(column)}}},[_vm._v("\n                        "+_vm._s(column.label)+"\n                        "),(_vm.sort.key === column.value)?_c('span',{staticClass:"table-order-chevron"},[_c('i',{staticClass:"fa text-xs",class:{
                                    'fa-chevron-down': _vm.sort.direction === 'DESC',
                                    'fa-chevron-up': _vm.sort.direction === 'ASC',
                                }})]):_vm._e()])])}),_vm._v(" "),(_vm.hasActions)?_c('th',{attrs:{"aria-hidden":"true"}}):_vm._e()],2)]),_vm._v(" "),_c('transition-group',{staticClass:"divide-y divide-gray-200 bg-white",attrs:{"name":"list","tag":"tbody","css":_vm.animated}},_vm._l((_vm.rows),function(row,index){return _c('tr',{key:_vm.getItemKey(row, index),class:{
                    'deleted': row.deleted_at != null,
                    'border-b': (index + 1) !== _vm.rows.length,
                    'hover:bg-gray-100 cursor-pointer': _vm.isRowClickable(row),
                },on:{"click":function($event){_vm.isRowClickable(row) && _vm.$emit('clicked', row)}}},[(_vm.hasSelection)?_c('td',{staticClass:"py-3.5 pl-4 pr-3 sm:pl-6",style:({width: '60px'}),on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{attrs:{"value":_vm.selected.has(row.id),"size":"sm"},on:{"input":function($event){return _vm.select(row.id)}}})],1):_vm._e(),_vm._v(" "),_vm._t("default",null,{"row":row}),_vm._v(" "),(_vm.showDropdown)?_c('td',{staticClass:"text-right py-3.5 pl-4 pr-3 sm:pl-6 dropdown",on:{"click":function($event){$event.stopPropagation();}}},[_c('dropdown',{scopedSlots:_vm._u([{key:"priority-options",fn:function(){return [_vm._t("priority-buttons",null,{"row":row})]},proxy:true},{key:"content",fn:function(){return [_vm._t("dropdown",null,{"row":row})]},proxy:true}],null,true)})],1):_vm._e()],2)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }