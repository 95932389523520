<template>
    <label class="flex flex-col relative block text-gray-600">
        <span
            :class="{
                'uppercase text-sm pointer-events-none tracking-wider top-1/2 -translate-t-50 transition-all': true,
                'truncate top-[-9px] z-10 left-2 bg-white text-gray-700 px-2 round-sm': true,
                'text-xs scale-3/4 transform-origin-left-center absolute': true
            }"
        >
            {{ label }}
        </span>
        <admin-tag-select
            :value="value"
            :placeholder="placeholder"
            :type="type"
            @input="$emit('input', $event)"
        />
    </label>
</template>

<script>
import AdminTagSelect from '@outreach/components/tag-select/AdminTagSelect';
import { TagType } from '@services/TagType';

export default {
    name: 'LabelledTagSelect',
    components: { AdminTagSelect },
    props: {
        value: { type: Array, default: () => ([]) },
        label: { type: String, default: 'Tags' },
        placeholder: { type: String, default: 'Select Tags' },
        type: {
            type: String,
            default: TagType.CONTENT,
            validator: (value) => Object.values(TagType).includes(value)
        }
    }
};
</script>
