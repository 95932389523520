<template>
    <div>
        <div v-if="selectable" class="flex-none px-4 pb-2">
            <label>
                <input ref="select-all-checkbox" type="checkbox" :checked="allSelected" @input="selectAll" />
                <span class="pl-2">Select All on Page</span>
            </label>
        </div>
        <div class="list bg-white shadow-md rounded">
            <div
                v-for="(row, index) in rows"
                :key="index"
                class="p-4 flex items-center item"
                :class="{'hover:bg-gray-100 cursor-pointer': clickable}"
            >
                <div v-if="selectable" class="flex-none mr-6">
                    <input
                        ref="select"
                        type="checkbox"
                        :value="row.id"
                        :checked="localSelected.has(row.id)"
                        @input="select(row.id)"
                    />
                </div>

                <div ref="content" class="flex flex-1" @click="$emit('click', row)">
                    <slot :row="row"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rows: { type: Array, default: () => [] },
        selected: { type: Set, default: () => new Set() }
    },
    data: () => ({
        localSelected: new Set()
    }),
    computed: {
        clickable () {
            return this.$listeners.click;
        },
        selectable () {
            return this.$listeners['change:selection'];
        },
        allSelected () {
            return this.rows.every(row => this.localSelected.has(row.id));
        }
    },
    watch: {
        selected (value) {
            this.localSelected = new Set(value);
        }
    },
    created () {
        this.localSelected = new Set(this.selected);
    },
    methods: {
        select (id) {
            if (this.localSelected.has(id)) {
                return this.deselect(id);
            }
            this.localSelected.add(id);
            this.emitSelectionChanged();
        },
        deselect (id) {
            this.localSelected.delete(id);
            this.emitSelectionChanged();
        },
        selectAll () {
            if (this.allSelected) {
                return this.deselectAll();
            }
            this.localSelected = new Set([...this.selected, ...this.rows.map(row => row.id)]);
            this.emitSelectionChanged();
        },
        deselectAll () {
            const tempArray = [...this.localSelected];

            this.localSelected = new Set(tempArray.filter((id) => !this.rows.map(row => row.id).includes(id)));

            this.emitSelectionChanged();
        },
        emitSelectionChanged () {
            this.$emit('change:selection', this.localSelected);
        }
    }
};
</script>

<style scoped>
    .list > div {
        @apply border-b
    }
    .list > div:last-child {
        @apply border-b-0
    }
</style>
