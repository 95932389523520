<template>
    <div v-if="total > 1" class="flex flex-row gap-4">
        <div class="inline-flex">
            <button
                v-tooltip="'First Page'"
                type="button"
                class="py-1 px-3 rounded hover:bg-gray-400 mr-4"
                :disabled="isOnFirstPage"
                :class="{ 'pointer-events-none opacity-75': isOnFirstPage }"
                aria-label="First Page"
                @click="onClickFirstPage"
            >
                <i class="fa fa-long-arrow-left"></i>
            </button>

            <button
                v-tooltip="'Previous Page'"
                type="button"
                class="py-1 px-3 rounded hover:bg-gray-400 mr-4"
                :disabled="isOnFirstPage"
                :class="{ 'pointer-events-none opacity-75': isOnFirstPage }"
                aria-label="Previous Page"
                @click="onClickPreviousPage"
            >
                <i class="fa fa-chevron-left"></i>
            </button>

            <button
                v-for="page in pages"
                :key="page.name"
                type="button"
                class="py-1 px-3 rounded hover:bg-gray-400 mr-4"
                :class="classes(page.name)"
                :disabled="page.isDisabled"
                @click="onClickPage(page.name)"
            >
                {{ page.name }}
            </button>

            <button
                v-tooltip="'Next Page'"
                type="button"
                class="py-1 px-3 rounded hover:bg-gray-400 mr-4"
                :disabled="isOnLastPage"
                :class="{ 'pointer-events-none opacity-75': isOnLastPage }"
                aria-label="Next Page"
                @click="onClickNextPage"
            >
                <i class="fa fa-chevron-right"></i>
            </button>

            <button
                v-tooltip="'Last Page'"
                type="button"
                class="py-1 px-3 rounded hover:bg-gray-400"
                :disabled="isOnLastPage"
                :class="{ 'pointer-events-none opacity-75': isOnLastPage }"
                aria-label="Last Page"
                @click="onClickLastPage"
            >
                <i class="fa fa-long-arrow-right"></i>
            </button>
        </div>
        <input
            ref="jump-to-page"
            type="text"
            :value="value"
            class="py-1 px-3 rounded hover:bg-gray-400  border-gray-400 mr-4 grow-0 inline-flex border w-16 text-center"
            @input="onSubmitGoToPage"
        />
    </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
    name: 'VPaginator',

    props: {
        value: { type: Number, default: null },
        total: { type: Number, default: null },
        maxVisiblePages: { required: false, type: Number, default: 3 }
    },
    computed: {
        current: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
        startPage () {
            let startPage = 1;

            if (this.current === 1) {
                return startPage;
            }

            if (this.current === this.total) {
                startPage = this.total - this.maxVisiblePages + 1;
            } else {
                startPage = this.current - 1;
            }

            if (startPage === 0) {
                startPage = 1;
            }

            return startPage;
        },
        endPage () {
            return Math.min(this.startPage + this.maxVisiblePages - 1, this.total);
        },
        pages () {
            const range = [];

            for (let i = this.startPage; i <= this.endPage; i += 1) {
                range.push({
                    name: i,
                    isDisabled: i === this.current
                });
            }

            return range;
        },
        isOnFirstPage () {
            return this.current === 1;
        },
        isOnLastPage () {
            return this.current === this.total;
        }
    },
    methods: {
        classes (page) {
            return {
                'bg-brand-regular text-white hover:bg-brand-dark': this.current === page,
                'hidden sm:block': this.current !== page
            };
        },
        onClickFirstPage () {
            this.$emit('input', 1);
            this.$emit('first');
        },
        onClickPreviousPage () {
            this.$emit('input', this.current - 1);
            this.$emit('previous');
        },
        onClickPage (page) {
            this.$emit('input', page);
        },
        onClickNextPage () {
            this.$emit('input', this.current + 1);
            this.$emit('next');
        },
        onClickLastPage () {
            this.$emit('input', this.total);
            this.$emit('last');
        },
        onSubmitGoToPage: debounce(function (e) {
            this.$emit('input', e.target.value);
        }, 500)
    }
};
</script>
