<template>
    <div class="flex flex-col border p-6 rounded border-gray-400">
        <div class="leading-none flex justify-content-center items-center mb-4">
            <i class="fas fa-coins mr-4 ml-2 rounded-sm text-xl text-gray-800"></i>
            <div class="flex text-xl mr-2">{{ currentCredits }}</div>
            <div class="text-gray-600">Current Credits</div>
        </div>
        <div class="flex flex-col justify-center items-center">
            <div class="w-full flex mb-2">
                <input
                    id="credit-range"
                    v-model="credits"
                    type="range"
                    class="flex-1 mr-6"
                    :min="minCredits"
                    :max="currentMaxCredits"
                    :step="sliderSteps"
                />
                <v-input
                    v-model="credits"
                    type="number"
                    class="w-1/6"
                    label="Amount"
                    :min="minCredits"
                    :max="currentMaxCredits"
                />
            </div>
            <div class="flex justify-between items-end w-full">

                <div class="flex flex-col items-start justify-end">
                    <div>{{ formattedCreditPrice }} / each</div>
                    <div id="total" class="text-2xl text-gray-800 font-bold -mb-1">Total: {{ total }}</div>
                </div>
                <v-button
                    disabled="disabled"
                    label="Buy Now"
                    button-style="primary"
                    outlined
                    @click="$emit('credits', credits)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VInput from '@outreach/components/inputs/VInput';
import VButton from '@outreach/components/VButton.vue';
import { formatToCurrency } from '@services/Utils';

export default {
    name: 'PricingSlider',
    components: { VButton, VInput },
    props: {
        minCredits: { type: Number, required: true },
        maxCredits: { type: Number, required: true },
        creditPrice: { type: Number, required: true },
        plans: { type: Array, default: () => [] },
        currentCredits: { type: Number, default: 0 }
    },
    data: () => ({
        credits: undefined
    }),
    computed: {
        total () {
            return formatToCurrency((this.credits * this.currentPrice) / 100);
        },
        currentMaxCredits () {
            return this.maxCredits ?? this.minCredits * 5;
        },
        sliderSteps () {
            return Math.floor(this.currentMaxCredits * 0.05);
        },
        formattedCreditPrice () {
            return formatToCurrency(this.currentPrice / 100);
        },
        currentPrice () {
            return this.plans.find(plan => this.credits >= plan.credits)?.note_price ?? this.creditPrice;
        }
    },
    mounted () {
        this.credits = this.minCredits;
    }
};
</script>
