<template>
    <ul class="flex flex-row flex-wrap -m-1 mb-6">
        <li v-for="key in flattened" :key="key" class="m-1">
            <tag :value="key" @click="$emit('tagged', $event)" />
        </li>
    </ul>
</template>
<script>
import Tag from '@outreach/pages/CampaignCompose/Tag';
import { flattenTags } from '@services/Utils';
import take from 'lodash/take';

export default {
    name: 'InterpolateTags',
    components: { Tag },
    props: {
        tags: { type: Object, required: true },
        limit: { type: Number, default: 6 }
    },
    data () {
        return {
            priorityTags: ['contact.first_name', 'contact.last_name', 'contact.address.address1']
        };
    },
    computed: {
        flattened () {
            const tags = flattenTags(this.tags).sort((a, b) => {
                const priority = [...this.priorityTags].reverse();
                return priority.indexOf(b) - priority.indexOf(a);
            });
            return take(tags, this.limit);
        }
    }
};
</script>
