<template>
    <div class="flex w-full justify-end">
        <v-custom-button
            v-if="!showForm()"
            ref="add-post-it"
            class="font-bold flex-none"
            label="Add a post-it"
            @click="$emit('toggleForm')"
        />
        <v-panel v-else class="w-full" title="Post-it Note" subtitle="(optional)">
            <template #titleAction>
                <button
                    ref="discard-post-it"
                    v-tooltip="'Discard Post-It'"
                    class="discard-button"
                    @click="confirmDiscard"
                >
                    <i class="fa fa-trash"></i>
                </button>
            </template>
            <template #actionButton>
                <v-button button-style="primary" label="Choose Template" @click="openTemplateModal" />
            </template>
            <v-typeahead
                ref="message"
                class="mb-6"
                label="Your message"
                type="textarea"
                :value="text"
                :rows="3"
                :options="tags"
                @input="$emit('input', $event)"
            />
            <doodle-select
                ref="doodle-select-bottom"
                :value="doodle"
                label="Choose post-it doodle..."
                @selected="$emit('doodleSelected', $event)"
            />
        </v-panel>
    </div>
</template>

<script>
import VTypeahead from '@outreach/components/VTypeahead';
import DoodleSelect from '@outreach/components/campaign/DoodleSelect';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VPanel from '@outreach/components/shared/VPanel';
import VCustomButton from '@outreach/components/VCustomButton';
import VButton from '@outreach/components/VButton';
import { Layout } from '@services/Layout';

export default {
    name: 'PostItForm',
    components: {
        VTypeahead,
        DoodleSelect,
        VPanel,
        VCustomButton,
        VButton
    },
    props: {
        text: { type: String, required: false, default: null },
        doodle: { type: Object, default: () => ({}) },
        show: { type: Boolean, default: false },
        tags: { type: Object, default: () => ({}) },
        accountId: { type: Number, default: null }
    },
    methods: {
        async confirmDiscard () {
            const dialog = await dialogEmitter.confirm(
                'Discard Post-It',
                'Are you sure you want to permanently delete the post-it and discard it\'s content?',
                { confirmButtonStyle: 'error' }
            );
            if (dialog) {
                this.$emit('discard');
            }
        },
        showForm () {
            return this.show || Boolean(this.text);
        },
        openTemplateModal () {
            dialogEmitter.openDialog('TemplateSelect', {
                accountId: this.accountId,
                layoutTypes: [Layout.POST_IT]
            }, { choose: (event) => this.updateTemplate(event) });
        },
        async updateTemplate (template) {
            await this.$emit('input', template.content);
            if (template.doodles.length) {
                this.$emit('doodleSelected', template.doodles[0]);
            }
        }
    }
};
</script>
<style>
.discard-button {
    @apply shadow ml-2 px-1.5 py-0.5 bg-red-500 border-red-500 h-1/2 rounded-md text-white text-xs items-start hover:opacity-90;
}
</style>
