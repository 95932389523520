import ApiClient from '@services/Http/Api';

export const list = () => {
    return ApiClient.get('/dashboard/fonts');
};

export const update = (id, params) => {
    return ApiClient.patch(`/dashboard/fonts/${id}`, params);
};

export const preview = (id) => {
    return ApiClient.get(`/dashboard/fonts/${id}/preview`);
};

export const destroy = (id) => {
    return ApiClient.destroy(`/dashboard/fonts/${id}`);
};
