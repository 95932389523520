<template>
    <delivery-summary
        :loading="loading"
        :contacts="contacts"
        :meta="meta"
        :actions="actions"
        :query.sync="query"
        @page-changed="handlePage"
    />
</template>

<script>
import services from '@services/Api';
import debounce from 'lodash/debounce';
import DeliverySummary from '@outreach/pages/account/DeliverySummary';

export default {
    components: { DeliverySummary },
    data: function () {
        return {
            actions: [],
            loading: true,
            contacts: [],
            meta: {},
            query: null,
            showImportModal: false
        };
    },
    computed: {
        accountId () {
            return this.$route.params.accountId;
        }
    },
    watch: {
        async query () {
            this.debounceLoad(1);
        }
    },
    async created () {
        await this.load();
    },
    methods: {
        debounceLoad: debounce(function (page = 1) {
            this.load(page);
        }, 500),
        async load (page = 1) {
            this.loading = true;
            try {
                const { data } = await services.contacts.deliverySummaryPreview(this.accountId, {
                    signed: window.location.href,
                    page,
                    q: this.query
                });
                this.contacts = data.data;
                this.meta = data.meta;
            } catch (error) {
                this.$toasted.error('Sorry, we were unable to load results. Please refresh the page and try again.');
            }
            this.loading = false;
        },
        handlePage (page) {
            this.load(page);
        }
    }
};
</script>
