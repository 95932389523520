<template>
    <v-button
        button-style="info"
        :label="label"
        :confirm="confirm"
        @click="handleDuplicate"
    >
        <template #before-label>
            <i class="fa fa-copy mr-2"></i>
        </template>
    </v-button>
</template>

<script>
import VButton from '@outreach/components/VButton';
import { mapActions } from 'vuex';

export default {
    name: 'DuplicateTemplateButton',
    components: { VButton },
    props: {
        template: { type: Object, required: true },
        confirm: { type: String, required: true },
        label: { type: String, required: false, default: 'Duplicate' }
    },
    methods: {
        ...mapActions({
            duplicate: 'userTemplates/duplicate'
        }),
        async handleDuplicate () {
            const duplicate = await this.duplicate(this.template);
            await this.$router.push({ name: 'edit-template', params: { template: duplicate.id } });
        }
    }
};
</script>
