<template>
    <div
        ref="radio-button"
        class="flex p-4 py-8 rounded border justify-center items-stretch relative"
        :class="classes"
        @click="change"
    >
        <i :class="icon" class="icon"></i>
        <slot>
            <div class="flex flex-1 justify-center items-center">{{ label }}</div>
        </slot>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        checked: { type: [Array, Boolean, Number, String], default: false },
        disabled: { type: Boolean, default: false },
        label: { type: String, required: true },
        value: { type: [Boolean, Number, String], default: undefined }
    },
    computed: {
        isChecked () {
            if (this.value === undefined) {
                return Boolean(this.checked);
            }
            if (Array.isArray(this.checked)) {
                return this.checked.includes(this.value);
            }
            return this.checked === this.value;
        },
        classes () {
            if (this.disabled) {
                return {
                    'opacity-50 cursor-default border-gray-400 text-gray-800 disabled': true
                };
            }

            return {
                'border-brand-regular cursor-pointer text-brand-regular checked': this.isChecked,
                'border-gray-400 cursor-pointer text-gray-800 unchecked': !this.isChecked
            };
        },
        icon () {
            return {
                'fas fa-check-circle': this.isChecked,
                'far fa-circle text-gray-400': !this.isChecked
            };
        }
    },
    methods: {
        change () {
            if (this.disabled) {
                return;
            }
            this.$emit('change', this.value);
        }
    }
};
</script>

<style scoped>
    .icon {
        position: absolute;
        right: 4px;
        top: 4px;
    }
</style>
