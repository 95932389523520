<template>
    <div>
        <modal
            ref="modal"
            :open="isOpen"
            :no-pad="noPad"
            :header="header"
            :full-screen="fullScreen"
            :disable-on-click-away="disableOnClickAway"
            @close-modal="close"
        >
            <template #content>
                <div>
                    <slot name="content"></slot>
                </div>
            </template>
            <template #modal-actions>
                <slot name="modal-actions" :close="close"></slot>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from '@outreach/components/shared/modal';

export default {
    components: { Modal },
    props: {
        header: { type: String, default: null },
        fullScreen: { type: Boolean, default: false },
        noPad: { type: Boolean, default: false },
        disableOnClickAway: { type: Boolean, default: false },
        name: { type: String, default: null }
    },
    computed: {
        isOpen () {
            return this.$store.getters['modals/allOpen'].includes(this.name);
        }
    },
    methods: {
        close () {
            this.$store.dispatch('modals/close', this.name);
        }
    }
};
</script>
