<template>
    <v-card :has-body-padding="false" class="p-8">
        <div slot="body" class="flex">
            <div class="w-1/3 pr-8">
                <h2 class="text-xl text-gray-900 mb-2">Update password</h2>
            </div>
            <form class="w-2/3" @submit.prevent="updatePassword">
                <v-input
                    v-model="password.current"
                    type="password"
                    :errors="formErrors.current_password"
                    class="mb-6"
                    label="Current Password"
                />
                <v-input
                    v-model="password.new"
                    type="password"
                    :errors="formErrors.password"
                    class="mb-6"
                    label="New Password"
                />
                <v-input
                    v-model="password.confirm"
                    type="password"
                    :errors="formErrors.password_confirmation"
                    class="mb-8"
                    label="Verify New Password"
                />
                <v-button :disabled="loading" :loading="loading" button-style="primary" label="Save Changes" />
            </form>
        </div>
    </v-card>
</template>

<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import services from '@services/Api';
import VCard from '@outreach/components/VCard';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    components: { VCard, VButton, VInput },
    data: () => ({
        loading: false,
        formErrors: {},
        password: {
            current: null,
            new: null,
            confirm: null
        }
    }),
    computed: {
        ...mapState({
            currentUser: ({ user }) => user.user
        })
    },
    methods: {
        resetPasswordFields () {
            this.password.current = null;
            this.password.new = null;
            this.password.confirm = null;
        },
        async updatePassword () {
            this.loading = true;
            this.formErrors = {};

            try {
                await services.user.updatePassword(this.currentUser.id, {
                    confirmation_password: this.password.confirm,
                    current_password: this.password.current,
                    password: this.password.new
                });

                this.resetPasswordFields();
                this.$toasted.success('Your password has been updated');
            } catch ({ response }) {
                this.formErrors = get(response, 'data.errors', {});
            }

            this.loading = false;
        }
    }
};
</script>
