import ApiClient from '@services/Http/Api';

export const fetch = (claim) => {
    return ApiClient.get(`/dashboard/admin/claims/${claim}`);
};

export const create = (params) => {
    return ApiClient.post('/dashboard/admin/claims', params);
};

export const release = (claim) => {
    return ApiClient.destroy(`/dashboard/admin/claims/${claim}`);
};

export const update = (claim, data) => {
    return ApiClient.patch(`/dashboard/admin/claims/${claim}`, data);
};
