<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <div class="grid grid-cols-1 gap-2 md:grid-cols-3 md:items-center md:w-full">
                    <div class="">
                        <label class="uppercase text-gray-600">Owner / Address</label>
                        <v-input
                            v-model="query.q"
                            class="md:col-span-2"
                            label="Search"
                        />
                    </div>

                    <div class="flex-1">
                        <label class="uppercase text-gray-600">Account</label>
                        <account-select
                            :errors="formErrors.account_id"
                            :multiple="false"
                            :value="account ? [account] : []"
                            @input="accountSelected"
                        />
                    </div>

                    <div>
                        <div class="flex items-center">
                            <label class="uppercase text-gray-600">Surrounding Area</label>
                        </div>
                        <property-radius-input @selected="query.proximity = $event;" />
                    </div>

                    <div>
                        <v-button label="Filters" @click="openFilters" />
                        <v-button
                            ref="export-properties"
                            :disabled="! canExport || loading"
                            label="Export..."
                            button-style="primary"
                            @click="exportProperties"
                        />
                    </div>
                </div>
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" key="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading properties</span>
            </div>
            <div v-else>
                <div v-if="properties.length">
                    <paginator-wrap :pagination="meta" :change-page="changePage">
                        <template #content>
                            <v-table
                                is-elevated
                                has-actions
                                :selected="selected"
                                :rows="properties"
                                :sort="{ key: query.sort_key, direction: query.sort_direction }"
                                @sort-changed="updateSort"
                                @selection-changed="handleSelection"
                            >
                                <template #default="{ row }">
                                    <v-table-column label="Owner" value="owner">
                                        <div class="mt-2">
                                            <span><strong class="text-lg">{{ row.owner }}</strong></span>
                                        </div>
                                    </v-table-column>
                                    <v-table-column label="Address" value="address">
                                        <span>{{ row.address }}</span>
                                    </v-table-column>
                                    <v-table-column label="City" value="city" sortable>
                                        <span>{{ row.city }}</span>
                                    </v-table-column>
                                    <v-table-column label="State" value="state" sortable>
                                        <span>{{ row.state }}</span>
                                    </v-table-column>
                                    <v-table-column label="Postcode" value="postcode" sortable>
                                        <span>{{ row.postcode }}</span>
                                    </v-table-column>
                                    <v-table-column label="Claimed" value="claimed">
                                        <div v-if="row.claims && row.claims.length > 0" class="flex flex-col gap-4">
                                            <div v-for="claim in row.claims" :key="claim.id">
                                                <dl>
                                                    <dt class="flex items-center gap-2">
                                                        <i class="fas fa-calendar-days text-gray-500"></i>
                                                        {{ formatDate(claim.claimed_at) }}
                                                    </dt>
                                                    <dt class="flex items-center gap-2">
                                                        <i class="fas fa-user text-gray-500"></i>
                                                        {{ claim.account.users[0].name }}
                                                    </dt>
                                                    <dt class="flex items-center gap-2 capitalize">
                                                        <i class="fas fa-circle-info text-gray-500"></i>
                                                        {{ claim.method }}
                                                    </dt>
                                                </dl>
                                                <div
                                                    class="cursor-pointer font-bold mt-2"
                                                    @click="manageClaim(claim, row)"
                                                >
                                                    <i class="fas fa-eye cursor-pointer mr-1"></i> View
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="cursor-pointer font-bold" @click="createClaim(row)">
                                                <i class="fas fa-plus"></i> Add Claim
                                            </div>
                                        </div>
                                    </v-table-column>
                                </template>
                            </v-table>
                        </template>
                    </paginator-wrap>
                </div>
                <div v-else class="text-center">
                    <p class="text-lg text-gray-700">No properties were found.</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import services from '@services/Api';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import { mapGetters } from 'vuex';
import PaginatorWrap from '@outreach/components/PaginatorWrap';
import { formatDate } from '@services/Filters';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import SearchHeader from '@outreach/components/shared/SearchHeader';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import AccountSelect from '@outreach/components/account/AccountSelect.vue';
import PropertyRadiusInput from '@outreach/components/inputs/PropertyRadiusInput.vue';
import RealEstateFilters from '@outreach/pages/OutboundProspecting/RealEstateFilters';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VButton from '@outreach/components/VButton.vue';
import { clone } from 'lodash';
import { Alert } from '@services/Alert';

export default {
    name: 'Properties',
    components: {
        PropertyRadiusInput,
        AccountSelect,
        SearchHeader,
        PaginatorWrap,
        VButton,
        VTable,
        VTableColumn,
        VInput,
        VLoader
    },
    mixins: [hasPermission],
    data: () => ({
        selected: new Set(),
        query: {
            q: undefined,
            account_id: undefined,
            sort_key: 'created_at',
            sort_direction: 'DESC',
            page: null,
            proximity: undefined
        },
        properties: [],
        meta: {},
        formErrors: {},
        account: undefined,
        filters: clone(new RealEstateFilters().getFilters())
    }),
    computed: {
        ...mapGetters({
            loading: 'loader/isLoading'
        }),
        canExport () {
            if ([this.query.q, this.query.account_id, this.query.proximity].some(value => Boolean(value))) {
                return true;
            }

            return this.filtersSet;
        },
        filtersSet () {
            return Object.keys(this.filters)
                .filter((key) => RealEstateFilters.ranges.includes(key))
                .some(key => this.filters[key].min || this.filters[key].max);
        }
    },
    watch: {
        query: {
            handler: debounce(async function () {
                await this.fetchProperties();
            }, 500),
            deep: true
        }
    },
    created () {
        this.fetchProperties();
    },
    methods: {
        formatDate,
        async fetchProperties (params) {
            try {
                const { data } = await services.prospecting.listProperties({
                    ...this.query,
                    ...this.filters,
                    ...params
                });
                this.properties = data.data;
                this.meta = { ...data.meta, next_page_url: data.links.next, prev_page_url: data.links.prev };
            } catch (e) {
                this.formErrors = get(e, 'response.data.errors', {});
            }
        },
        async exportProperties () {
            try {
                await services.prospecting.exportProperties({
                    q: this.query.q,
                    account_id: this.query.account_id,
                    proximity: this.query.proximity,
                    ...this.filters
                });
                Alert.warning('Your export request is being processed. This may take a few minutes.');
            } catch (e) {
                Alert.error('Error exporting properties. Please try again.');
            }
        },
        updateSort (data) {
            this.query.sort_key = data.key;
            this.query.sort_direction = data.direction;
        },
        handleSelection (selected) {
            this.selected = selected;
        },
        changePage (page) {
            this.fetchProperties({ page });
        },
        accountSelected ($event) {
            this.query.account_id = $event?.id;
            this.account = $event;
        },
        openFilters () {
            dialogEmitter.name('RealEstateFiltersDialog')
                .props({ filters: this.filters, formErrors: this.formErrors })
                .addListener('submitted', () => this.fetchProperties())
                .wait();
        },
        manageClaim (claim, property) {
            dialogEmitter
                .name('ManageClaimDialog')
                .addListener('updated', () => {
                    Alert.success('Claim updated successfully', 3000);
                    this.fetchProperties();
                })
                .addListener('released', () => {
                    Alert.success('Claim released successfully', 3000);
                    this.fetchProperties();
                })
                .props({ claim, property })
                .wait();
        },
        createClaim (property) {
            dialogEmitter
                .name('CreateClaimDialog')
                .addListener('created', () => {
                    Alert.success('Claim created successfully', 3000);
                    this.fetchProperties();
                })
                .props({ property })
                .wait();
        }
    }
};
</script>
