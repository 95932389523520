<template>
    <div class="flex border-1 items-stretch border-b">
        <div class="flex items-center justify-center w-4" :class="accentClass">
            <i class="fas text-white text-xs" :class="icon"></i>
        </div>
        <div class="flex justify-between items-center gap-2 flex-1 p-4">
            <div>
                <span
                    class="notification-text text-sm"
                    :class="{
                        'text-gray-600': notification.read
                    }"
                    v-html="notification.text"
                ></span>
            </div>
            <div class="flex gap-2">
                <div>
                    <router-link
                        v-for="action in notification.actions"
                        :key="action.label"
                        class="action-button flex flex-col my-1"
                        :to="action.route"
                    >
                        {{ action.label }}
                    </router-link>
                </div>
                <button class="toggle-button" @click="$emit('toggle-read')">
                    <i v-if="!notification.read" class="fas fa-check text-sky-500"></i>
                    <i v-else class="fas fa-rotate-left text-gray-400"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>

import { Notification, NotificationType } from '@models/Notification';

const typeStyles = {
    [NotificationType.Success]: { icon: 'fa-check', style: 'notification-success' },
    [NotificationType.Error]: { icon: 'fa-xmark', style: 'notification-error' },
    [NotificationType.Warning]: { icon: 'fa-exclamation', style: 'notification-warning' },
    [NotificationType.Info]: { icon: 'fa-info', style: 'notification-info' }
};

export default {
    props: {
        notification: { type: Notification, required: true }
    },
    computed: {
        icon () {
            return typeStyles[this.notification.type].icon;
        },
        accentClass () {
            return this.notification.read ? 'notification-read' : typeStyles[this.notification.type].style;
        }
    }
};
</script>
<style>
.notification-text a {
    @apply font-bold text-blue-600 hover:underline;
}

.notification-success { @apply bg-emerald-400; }
.notification-error { @apply bg-red-400; }
.notification-warning { @apply bg-orange-400; }
.notification-info { @apply bg-sky-400; }
.notification-read { @apply bg-gray-400; }

.action-button {
    @apply text-blue-600 hover:text-white text-center text-xs
    border border-blue-600
    rounded-full hover:bg-blue-600
    focus:ring-4 focus:outline-none focus:ring-blue-300
    px-2.5 py-1;
}
</style>
