<template>
    <div v-if="account.users.length > 0">
        <span>{{ this.account.users[0].name }}</span>
        <span class="text-sm text-gray-700">({{ this.account.users[0].email }})</span>
        <span v-if="account.users.length > 1">{{ `and ${this.account.users.length - 1} others` }}</span>
        <div class="mt-2">
            <span
                v-for="role in this.account.users[0].roles"
                :key="role.id"
                class="text-xs bg-gray-200 inline-block px-2 py-1 rounded mr-2"
            >
                {{ role.title }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountUsersInfo',
    props: {
        account: { type: Object, required: true }
    }
};
</script>
