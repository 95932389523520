"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const turf_1 = require("@turf/turf");
const config_1 = require("@global/config");
class PolygonPath {
    constructor(poly) {
        this.poly = poly;
    }
    toString() {
        return (0, turf_1.getCoords)(this.poly)[0].map(([lng, lat]) => (`${lat},${lng}`)).join(';');
    }
    static fromRadius(centroid, radius) {
        return new PolygonPath((0, turf_1.buffer)((0, turf_1.point)([centroid.lng, centroid.lat]), (radius || 0) * config_1.METERS_PER_MILE, { units: 'meters', steps: 4 }));
    }
}
exports.default = PolygonPath;
