<template>
    <div class="pricing-card">
        <div v-if="! plan.published_at" class="badge unpublished">Unpublished</div>
        <div v-if="badge" class="badge">{{ badge }}</div>
        <div class="flex flex-col justify-between gap-4 mx-auto w-full flex-1">
            <div class="relative flex flex-col gap-2 ">

                <div v-if="editable" class="edit-button absolute top-0 right-0">
                    <i class="fa fa-pencil"></i>
                </div>
                <div class="flex items-end gap-2 mb-2">
                    <v-logo class="mb-2" />
                    <div
                        class="text-3xl font-normal text-gray-800 border border-transparent"
                        :class="{'focused': focus === 'name' }"
                    >
                        {{ plan.name }}
                    </div>
                </div>

                <div class="mb-2">
                    <div
                        class="text-4xl text-gray-800 font-bold -mb-1 border border-transparent"
                        :class="{'focused': focus === 'credits' }"
                    >{{ plan.credits }}
                    </div>
                    <div class="text-md text-gray-600">Note credits</div>
                </div>
                <div
                    v-if="plan.discount > 0"
                    class="-mb-1 rounded bg-green-100 text-green-600 px-2 py-1 text-md font-bold w-fit"
                >
                    Save {{ formatToCurrency(plan.discount) }}
                </div>
                <div>
                    <div
                        v-if="plan.discount > 0"
                        class="-mb-1 text-3xl text-gray-600 font-bold line-through"
                    >
                        {{ formatToCurrency(plan.fullPrice) }}
                    </div>
                    <div class="text-4xl text-gray-800 font-bold -mb-1">
                        {{ formatToCurrency(plan.totalPrice) }}
                    </div>
                    <div
                        class="text-md text-gray-600 border border-transparent"
                        :class="{'focused': focus === 'pricePerNote' }"
                    >{{ formatToCurrency(plan.pricePerNote) }}/Note Credit
                    </div>
                </div>
            </div>
            <v-button
                disabled="disabled"
                outlined
                label="Buy now"
                button-style="primary"
                @click="$emit('selected')"
            />
        </div>

    </div>
</template>
<script>
import VButton from '@outreach/components/VButton.vue';
import { formatToCurrency } from '@services/Utils';
import { Plan } from '@models/Plan';
import VLogo from '@outreach/components/shared/VLogo.vue';

export default {
    name: 'PricingCard',
    components: { VLogo, VButton },
    props: {
        plan: { type: Plan, required: true },
        badge: { type: String, default: undefined },
        focus: { type: String, default: undefined },
        editable: { type: Boolean, default: false }
    },
    methods: { formatToCurrency }
};
</script>
<style scoped>
.pricing-card {
    transition: border 0.3s ease-in-out;
    @apply flex flex-col border border-gray-300 gap-4 mx-auto w-full;
    @apply text-gray-600 p-6 bg-gray-50 rounded shadow-sm hover:shadow-lg hover:border-brand-regular relative;
}

.badge {
    @apply top-4 -mt-6 -mx-6 text-center font-bold right-2 leading-none;
    @apply bg-brand-light text-white px-4 py-2 rounded-t uppercase text-sm;
}

.badge.unpublished {
    @apply text-inherit bg-gray-400;
}

.focused {
    transition: border 0.2s ease-in-out;
    @apply border border-red-500 rounded box-content;
}

.edit-button{
    @apply opacity-0;
}

.pricing-card:hover .edit-button {
    transition:opacity 0.2s ease-in-out;
    @apply opacity-100;
}

</style>
