<template>
    <button-modal
        ref="modal"
        header="Manage Contacts"
        label="Manage Contacts"
        name="manage-contacts"
        class="manage-contacts-modal w-full p-4"
        @open="getContacts"
    >
        <template #content>
            <v-input v-model="q" label="Search" class="mb-2" :loading="loading" />
            <div
                v-for="contact in contacts"
                :key="contact.id"
                :class="{ 'opacity-50': loading}"
                class="bg-white flex flex-row flex-1 items-center py-2 w-full"
            >
                <avatar class="rounded-full" :contact="contact" style="height: 40px; width: 40px;" />
                <p ref="name" class="flex-1 text-lg ml-4">{{ contact.name }}</p>
                <button :ref="`manage-contact-button-${contact.id}`" @click="handleAddContact(contact.id)">
                    <i
                        v-if="!isPendingContact(contact.id)"
                        class="fa fa-plus-circle text-lg text-green-600 text-right cursor-pointer"
                    ></i>
                    <i v-else class="fa fa-times-circle text-lg text-red-600 text-right cursor-pointer"></i>
                </button>
            </div>
            <v-paginator
                v-if="meta"
                class="flex-none my-2 justify-center w-full"
                :total="meta.last_page"
                :value="meta.current_page"
                @input="page = $event"
            />
        </template>
        <template #actions="{ close }">
            <v-button
                ref="add-new-button"
                button-style="primary"
                label="Add New Contacts"
                :disabled="pendingNewContacts.length === 0"
                @click="updateCampaignContacts(close)"
            />
        </template>
    </button-modal>
</template>
<script>
import ButtonModal from '@outreach/components/shared/modal/ButtonModal';
import { mapActions, mapGetters, mapState } from 'vuex';
import VPaginator from '@outreach/components/VPaginator';
import debounce from 'lodash/debounce';
import Avatar from '@outreach/components/shared/avatar';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    name: 'ManageContactsModal',
    components: { Avatar, ButtonModal, VPaginator, VButton, VInput },
    props: {
        omit: { type: Array, default: () => [] }
    },
    data () {
        return {
            page: 1,
            q: '',
            pendingNewContacts: []
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            contacts: ({ campaign }) => campaign.contacts.records,
            meta: ({ campaign }) => campaign.contacts.meta,
            campaign: ({ campaign }) => campaign.campaign
        }),
        ...mapGetters({
            loading: 'loader/isLoading'
        })
    },
    watch: {
        page () {
            this.debounceGetContacts();
        },
        q () {
            this.page = 1;
            this.debounceGetContacts();
        },
        omit () {
            this.getContacts();
        }
    },
    methods: {
        ...mapActions({
            list: 'campaign/getContacts',
            setCampaignContacts: 'campaign/setCampaignContacts'
        }),
        debounceGetContacts: debounce(function () {
            this.getContacts();
        }, 500),
        async getContacts () {
            await this.list({
                params: {
                    page: this.page,
                    per_page: 10,
                    q: this.q
                }
            });
        },
        changePage (page) {
            this.page = page;
        },
        handleAddContact (contactId) {
            const modified = [...this.pendingNewContacts];
            const index = modified.indexOf(contactId);
            if (index < 0) {
                modified.push(contactId);
            } else {
                modified.splice(index, 1);
            }
            this.pendingNewContacts = modified;
        },
        isPendingContact (contactId) {
            return this.pendingNewContacts.indexOf(contactId) >= 0;
        },
        async updateCampaignContacts (close) {
            await this.setCampaignContacts(this.pendingNewContacts);
            close();
            this.pendingNewContacts = [];
            this.$emit('get-new-contacts');
        }
    }
};
</script>

<style lang="scss" scoped>
.manage-contacts-modal {
    max-width: 900px;
}
</style>
