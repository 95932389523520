import services from '../Api/index';
import { get } from 'lodash';
import { getAccessToken, setAccessToken } from '../Api/Authentication';

let isRefreshing = false;
let requestQueue = [];

export const refreshTokenAndReattemptRequest = async (error, client) => {
    const { response: errorResponse } = error;

    if (get(error, 'config.url', '').includes('/refresh')) {
        return Promise.error(error);
    }

    const token = getAccessToken();
    if (!token) {
        return Promise.error(error);
    }

    const originalRequest = retryOriginalRequest(errorResponse, client);

    if (!isRefreshing) {
        isRefreshing = true;
        await refreshToken();
        isRefreshing = false;
    }

    return await originalRequest;
};

const retryOriginalRequest = (errorResponse, client) => new Promise(resolve => {
    addRequestToQueue((access_token) => {
        errorResponse.config.headers.Authorization = `Bearer ${access_token}`;
        resolve(client(errorResponse.config));
    });
});

const refreshToken = async () => {
    const response = await services.authentication.refresh();
    if (!response.data) {
        return Promise.reject();
    }
    const newToken = get(response, 'data.data.token.access_token');
    setAccessToken(newToken);
    onAccessTokenFetched(newToken);
};

const onAccessTokenFetched = (access_token) => {
    requestQueue.forEach(callback => callback(access_token));
    requestQueue = [];
};

const addRequestToQueue = (callback) => {
    requestQueue.push(callback);
};
