<template>
    <div class="w-full">
        <p>
            <span
                v-if="address.address1 || address.address2"
                class="leading-5"
                :class="lineClass"
            >{{ join([address.address1, address.address2], ' ') }}</span>
            <span
                v-if="address.city || address.state"
                class="leading-5"
                :class="lineClass"
            >{{ join([address.city, address.state]) }}</span>
            <span
                v-if="address.country || address.postcode"
                class="leading-5"
                :class="lineClass"
            >{{ join([address.country, address.postcode]) }}</span>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        address: { type: Object, required: true },
        inline: { type: Boolean, default: false }
    },
    computed: {
        lineClass () {
            return { block: !this.inline };
        }
    },
    methods: {
        join (components, delimiter = ', ') {
            return components.filter(s => s).join(delimiter);
        }
    }
};
</script>
