import services from '@services/Api';
import { handleErrors } from '@audience/modules/common';

export const state = {
    doodles: [],
    formErrors: {}
};

export const actions = {
    list: async ({ commit }) => {
        try {
            const { data } = await services.doodles.list();
            commit('setDoodles', data.data);
        } catch (error) {
            handleErrors(error, commit);
        }
    }
};

export const mutations = {
    setDoodles: (state, doodles = []) => {
        state.doodles = doodles;
    }
};

export const getters = {
    get: (state) => state.doodles,
    formErrors: (state) => state.formErrors
};

export default { namespaced: true, state, actions, mutations, getters };
