import { brandColor } from '@global/config';

export class GoogleMarkerBuilder {
    #map;
    #location;
    #title;
    #icon;
    #draggable = false;
    #label = null;

    constructor (map) {
        this.#map = map;
    }

    forLocation (location) {
        this.#location = location;
        return this;
    }

    withTitle (title) {
        this.#title = title;
        return this;
    }

    draggable () {
        this.#draggable = true;
        return this;
    }

    withLabel (label) {
        this.#label = label;
        return this;
    }

    icon (options) {
        this.#icon = {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 12,
            fillOpacity: 1,
            strokeWeight: 3,
            fillColor: brandColor,
            strokeColor: '#ffffff',
            ...options
        };
        return this;
    }

    build () {
        return new google.maps.Marker({
            map: this.#map,
            anchorPoint: new google.maps.Point(0, -29),
            position: this.#location,
            title: this.#title,
            visible: true,
            icon: this.#icon,
            draggable: this.#draggable,
            label: this.#label ? { text: this.#label, color: 'white' } : null
        });
    }
}
