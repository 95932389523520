export const state = {
    messages: []
};

export const actions = {
    setMessage: ({ commit }, { messageType, message, duration = 30000 }) => {
        commit('updateMessage', { messageType, message, duration });
    },
    clearMessage: ({ commit }, index) => {
        commit('deleteMessage', index);
    },
    clearAll: ({ commit }) => {
        commit('clearAll');
    }
};

export const mutations = {
    updateMessage: (state, message) => {
        state.messages.push(message);
        if (message.duration) {
            setTimeout(() => {
                state.messages.splice(state.messages.indexOf(message), 1);
            }, message.duration);
        }
    },
    deleteMessage: (state, index) => {
        state.messages.splice(index, 1);
    },
    clearAll: (state) => {
        state.messages = [];
    }
};

export default { namespaced: true, state, actions, mutations };
