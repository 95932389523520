import ApiClient from '@services/Http/Api';

export const listAllArticles = (params) => {
    return ApiClient.get('/dashboard/admin/articles', { params });
};

export const list = (params) => {
    return ApiClient.get('/dashboard/articles', { params });
};

export const fetch = (articleId, params = {}) => {
    return ApiClient.get(`/dashboard/articles/${articleId}`, { params });
};

export const create = (data) => {
    return ApiClient.upload('/dashboard/articles', data);
};

export const destroy = (articleId) => {
    return ApiClient.destroy(`/dashboard/articles/${articleId}`);
};

export const update = (articleId, data) => {
    const method = !data.header_image_file ? 'patch' : 'patchUpload';
    return ApiClient[method](`/dashboard/articles/${articleId}`, data);
};

export const uploadFile = (file) => {
    return ApiClient.upload('/dashboard/admin/articles/upload', { file });
};

export const getVideo = (videoId) => {
    return ApiClient.get(`/dashboard/admin/articles/video-lookup/${videoId}`);
};
