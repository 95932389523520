<template>
    <div>
        <div class="flex">
            <label class="block font-bold mb-2">Select Radius (miles)</label>
            <div class="ml-auto">{{ value }} miles</div>
        </div>
        <v-slider
            :value="value"
            :min="min"
            :max="max"
            :interval="interval"
            @input="$emit('input', $event)"
        />
    </div>
</template>

<script>
import 'vue-slider-component/theme/default.css';
import VSlider from '@outreach/components/inputs/VSlider';

export default {
    name: 'RadiusSelector',
    components: { VSlider },
    props: {
        value: { type: Number, required: false, default: 1 },
        max: { type: Number, required: false, default: 2 },
        min: { type: Number, required: false, default: 0.05 },
        interval: { type: Number, required: false, default: 0.05 }
    }
};
</script>
