import ApiClient from '@services/Http/Api';

export const list = (account) => {
    return ApiClient.get(`/dashboard/accounts/${account}/cards`);
};

export const create = (account, data) => {
    return ApiClient.post(`/dashboard/accounts/${account}/cards`, data);
};

export const destroy = (account, card) => {
    return ApiClient.destroy(`/dashboard/accounts/${account}/cards/${card}`);
};
