import services from '@services/Api';

const state = {
    records: [],
    record: null,
    meta: null
};

export const getters = {
    images: (state) => state.records
};

export const actions = {
    async list ({ commit }, { accountId, params }) {
        const { data } = await services.images.list(accountId, params);
        commit('setRecords', data.data);
        commit('setMeta', data.meta);
    },
    async upload ({ commit }, { accountId, params }) {
        const { data } = await services.images.create(accountId, params);
        commit('setRecord', data.data);
    },
    async update ({ commit }, { accountId, assetId, payload }) {
        const { data } = await services.images.update(accountId, assetId, payload);
        commit('modifyRecord', data.data);
    },
    async destroy ({ commit }, { accountId, assetId, showArchived = true }) {
        const { data } = await services.images.destroy(accountId, assetId);
        if (!showArchived) {
            commit('removeRecord', assetId);
        } else {
            commit('modifyRecord', data.data);
        }
    },
    async restore ({ commit }, { accountId, assetId }) {
        const { data } = await services.images.restore(accountId, assetId);
        commit('setRecord', data.data);
        commit('modifyRecord', data.data);
    },
    async select ({ commit }, record) {
        commit('setRecord', record);
    }
};

export const mutations = {
    setRecords (state, records = []) {
        state.records = records;
    },
    setMeta (state, meta) {
        state.meta = meta;
    },
    setRecord (state, record) {
        state.record = record;
    },
    modifyRecord (state, modified) {
        const index = state.records.findIndex(record => record.id === modified.id);
        state.records[index] = modified;
        state.record = modified;
    },
    removeRecord (state, recordId) {
        state.records = state.records.filter((item) => item.id !== recordId);
    }
};

export default { namespaced: true, state, actions, mutations, getters };
