<template>
    <div class="container mx-auto max-w-3xl pt-12 md:pt-16 px-6 md:p-0">
        <img src="/images/logo-orange-red.png" class="mx-auto mb-12 w-64" alt="Audience Logo" />
        <v-card :has-body-padding="false" class="overflow-hidden">
            <div slot="body" class="md:flex bg-white">
                <div
                    class="flex-1 flex items-center justify-center p-4 h-64 md:h-auto bg-cover"
                    style="background-image: url(/images/login-image.jpg)"
                >
                    <ul class="text-white text-xl leading-loose">
                        <li class="flex items-center text-lg mb-2">
                            <i class="fa fa-check mr-2 opacity-60"></i>Book more meetings
                        </li>
                        <li class="flex items-center text-lg mb-2">
                            <i class="fa fa-check mr-2 opacity-60"></i>Close more deals
                        </li>
                        <li class="flex items-center text-lg">
                            <i class="fa fa-check mr-2 opacity-60"></i>Retain more customers
                        </li>
                    </ul>
                </div>

                <div class="flex-1 p-8 md:p-12">
                    <slot></slot>
                </div>
            </div>
        </v-card>
        <slot name="footer"></slot>
    </div>
</template>

<script>
import VCard from '@outreach/components/VCard';

export default {
    components: { VCard }
};
</script>
