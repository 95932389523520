<template>
    <div v-if="team">
        <v-panel :title="team.name">
            <div class="flex gap-4">
                <div>
                    <img
                        class="border h-20 bg-gray-50"
                        style="min-width: 5rem;"
                        :src="team.logo_url"
                        :alt="`${team.name} avatar`"
                    />
                </div>
                <div class="flex-1">
                    <p class="mt-1 text-sm text-gray-900">{{ team.description }}</p>
                </div>
            </div>
        </v-panel>
        <team-member-table :members="team.users" />
    </div>
    <div v-else-if="!teamId">
        <p class="text-3xl text-gray-500 font-bold text-center">No Team Selected</p>
    </div>
    <v-loader v-else />
</template>

<script>
import services from '@services/Api';
import VPanel from '@outreach/components/shared/VPanel';
import TeamMemberTable from '@outreach/pages/admin/teams/TeamMemberTable';
import { mapState } from 'vuex';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import VLoader from '@outreach/components/VLoader';

export default {
    name: 'ViewTeam',
    components: { TeamMemberTable, VPanel, VLoader },
    data () {
        return { team: null };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        teamId () {
            return this.user.current_team_id;
        }
    },
    created () {
        if (this.teamId) {
            this.loadTeam();
        }
    },
    methods: {
        async loadTeam () {
            try {
                const { data } = await services.teams.show(this.teamId);
                this.team = data.data;
            } catch (e) {
                throw new ClientVisibleError('Unable to load team');
            }
        }
    }
};
</script>
