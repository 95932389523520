<template>
    <authentication-layout>
        <h2 class="text-gray-800 text-xl mb-5">Login to Audience</h2>
        <section class="flex flex-col justify-center min-w-0 flex-2">
            <div class="flex">
                <v-button
                    class="text-left inline-flex items-center mr-2"
                    label="Google"
                    :loading="loading"
                    @click="socialLogin('google')"
                >
                    <template slot="after-label">
                        <i class="fa fa-brands fa-google ml-3"></i>
                    </template>
                </v-button>
                <v-button
                    v-if="false"
                    class="text-left inline-flex items-center mr-2"
                    label="Facebook"
                    :loading="loading"
                    @click="socialLogin('facebook')"
                >
                    <template slot="after-label">
                        <i class="fa fa-brands fa-facebook ml-3"></i>
                    </template>
                </v-button>
            </div>
        </section>
        <br />
        <form class="block" @submit.prevent="login">
            <v-input
                v-model="user.email"
                class="mb-6"
                label="Email"
                type="email"
                :errors="formErrors.email"
            />
            <v-input
                v-model="user.password"
                class="mb-2"
                label="password"
                type="password"
                :errors="formErrors.password"
            />
            <router-link class="block text-right text-sm text-brand-regular mb-6" :to="{ name: 'forgot-password' }">Forgot Password?</router-link>
            <v-button
                class="w-full text-left flex items-center"
                label="Login"
                button-style="primary"
                :loading="loading"
            >
                <template slot="after-label">
                    <i class="fa fa-chevron-right ml-auto"></i>
                </template>
            </v-button>
        </form>
    </authentication-layout>
</template>

<script>
import AuthenticationLayout from '@outreach/components/layouts/Authentication';
import services from '@services/Api';
import get from 'lodash/get';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    name: 'Login',
    components: { AuthenticationLayout, VButton, VInput },
    data: () => ({
        loading: false,
        formErrors: {},
        user: {}
    }),
    methods: {
        async login () {
            this.loading = true;
            this.formErrors = {};

            try {
                await services.authentication.login(this.user);
                if (this.$route.query.redirect) {
                    this.$router.push({ path: this.$route.query.redirect });
                    return;
                }
                this.$router.push({ name: 'send-notes' });
                return;
            } catch (e) {
                const { response } = e;
                this.formErrors = get(response, 'data.errors', {});
            }

            this.loading = false;
        },
        async socialLogin (provider) {
            this.loading = true;
            this.formErrors = {};

            try {
                const response = await services.authentication.socialLogin(provider);
                if (response.data.redirect_url) {
                    window.location.href = response.data.redirect_url;
                }
            } catch (err) {
                if (err.response.data.error) {
                    this.formErrors = err.response.data.error;
                }
            }
            this.loading = false;
        }
    }
};
</script>
