import get from 'lodash/get';
import ApiClient from '../Http/Api';
import store from '@audience';
import jwtDecode from 'jwt-decode';
import { Alert } from '@services/Alert';
import ImpersonateUser from '@events/ImpersonateUser';
import StopImpersonating from '@events/StopImpersonating';

export const ACCESS_TOKEN_KEY = 'access_token';

function setUser (response) {
    const user = get(response, 'data.data.user');
    if (user) {
        store.commit('user/setUser', user);
    }
    return response;
}

export const login = async (data) => {
    const response = await ApiClient.post('/login', data);
    return setUser(response);
};

export const socialLogin = async (provider) => {
    return await ApiClient.get(`/login/${provider}`);
};

export const callback = async (provider, code) => {
    const response = await ApiClient.get(`login/${provider}/callback?code=${code}`);
    return setUser(response);
};

export const validateToken = async (data) => {
    const response = await ApiClient.post('/dashboard/invitations/accept', data);
    return setUser(response);
};

export const register = async (data) => {
    const response = await ApiClient.post('/register', data);
    return setUser(response);
};

export const logout = async () => {
    const response = await ApiClient.post('/logout');
    removeAccessToken();
    return response;
};

export const refresh = async () => {
    const response = await ApiClient.post('/refresh');
    return setUser(response);
};

export const resetPassword = (data) => {
    return ApiClient.post('/password/reset', data);
};

export const getAccessToken = () => {
    return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const setAccessToken = (token) => {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const removeAccessToken = () => {
    store.commit('user/setUser', null);
    store.commit('user/setImposter', null);
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const currentUser = () => {
    return get(store, 'state.user.user');
};

export const impersonate = async (userId) => {
    const response = await ApiClient.post(`/impersonate/${userId}`);
    const user = get(response, 'data.data.user');
    await Alert.success(`You are impersonating ${user.first_name} ${user.last_name}`, 5000);
    return setUser(response);
};

const tryDecode = (token) => {
    try {
        return jwtDecode(token);
    } catch (e) {
        return {};
    }
};

export const setImposter = (token) => {
    const decoded = tryDecode(token);

    if (decoded.impersonated_by && !store.state.user.imposter) {
        store.dispatch('events/broadcast', new ImpersonateUser(decoded));
    }
};

export const stopImpersonating = async () => {
    const response = await ApiClient.destroy('/impersonate');
    store.dispatch('events/broadcast', new StopImpersonating());
    Alert.success('You are no longer impersonating a user.', 5000);
    return setUser(response);
};

export const loginByToken = async (url) => {
    const response = await ApiClient.post('/dashboard/signed-url-login', { url });
    const token = response.data.data.token.access_token;
    setAccessToken(token);
    setUser(response);
};

export const websocket = async (socketId, channel) => {
    return await ApiClient.post(`${process.env.API_URL}/broadcasting/auth`, {
        socket_id: socketId,
        channel_name: channel.name
    });
};
