import services from '@services/Api';
import { handleErrors } from '../common';

export const state = {
    record: null,
    records: [],
    meta: null,
    formErrors: {}
};

export const actions = {
    list: async ({ commit }, params) => {
        const { data } = await services.collaterals.list(params);
        commit('setRecords', data.data);
        commit('setMeta', data.meta);
    },
    create: async ({ commit }, payload) => {
        const { data } = await services.collaterals.create(payload);
        commit('setRecord', data.data);
    },
    fetch: async ({ commit }, collateralId) => {
        const { data } = await services.collaterals.fetch(collateralId);
        commit('setRecord', data.data);
    },
    update: async ({ commit }, { collateralId, payload }) => {
        try {
            commit('setFormErrors', {});
            const { data } = await services.collaterals.update(collateralId, payload);
            commit('setRecord', data.data);
        } catch (error) {
            handleErrors(error, commit);
            throw error;
        }
    },
    destroy: async ({ commit }, collateralId) => {
        await services.collaterals.destroy(collateralId);
        commit('removeRecord', collateralId);
    }
};

export const mutations = {
    setRecords: (state, records) => {
        state.records = records;
    },
    setRecord: (state, record) => {
        state.record = record;
    },
    removeRecord: (state, id) => {
        state.records = state.records.filter(record => record.id !== id);
    },
    setMeta: (state, meta) => {
        state.meta = meta;
    },
    setFormErrors: (state, errors) => {
        state.formErrors = errors;
    }

};

export default { namespaced: true, state, actions, mutations };
