import ApiClient from '@services/Http/Api';

export const upsert = (accountId, data = {}) => {
    return ApiClient.put(`/dashboard/accounts/${accountId}/integrations`, data);
};

export const available = async () => {
    return await ApiClient.get('/dashboard/integrations');
};

export const connected = async (accountId) => {
    return await ApiClient.get(`/dashboard/accounts/${accountId}/integrations`);
};
