<template>
    <v-card :has-body-padding="false">
        <div slot="body" class="p-8">
            <div class="mb-8">
                <h2 class="text-2xl mb-4 font-semibold text-grey-800">Check Contacts for Recent Mailings</h2>
                <p class="text-lg text-gray-700">
                    Please upload a CSV file containing property data from PropertyRadar.
                </p>
            </div>

            <div class="mb-6">
                <p
                    v-for="(error, index) in formErrors.credentials"
                    :key="index"
                    class="text-red-400 text-xs mb-2"
                >{{ error }}</p>
            </div>

            <vue-dropzone ref="dropzone" class="mb-4" accepted=".csv" @add-file="handleFile($event)" />

            <div class="mb-6">
                <p
                    v-for="(error, index) in formErrors.csv"
                    :key="index"
                    class="text-red-400 text-xs mb-2"
                >{{ error }}</p>
            </div>

            <v-button
                label="Upload"
                class="w-full"
                button-style="primary"
                :disabled="loading || !this.file"
                :loading="loading"
                @click="upload"
            />
            <div v-if="downloadFileUrl" class="flex flex-col py-4">
                <p class="pb-2 text-center text-gray-700">Your file is ready for download!</p>
                <a ref="download-href" :href="downloadFileUrl">
                    <v-button
                        label="Download"
                        class="w-full"
                        button-style="success"
                    />
                </a>
            </div>
            <v-modal name="progress-bar-modal" full-screen :header="progressHeader" :disable-on-click-away="true">
                <template slot="content">
                    <v-loader v-if="csvUploading" />
                    <progress-bar v-else :total="jobs.total" :completed="jobs.completed" />
                </template>
            </v-modal>
        </div>
    </v-card>
</template>

<script>
import services from '@services/Api';
import VueDropzone from '@outreach/components/shared/dropzone/VueDropzone';
import { mapActions, mapState } from 'vuex';
import ProgressBar from '@outreach/components/shared/ProgressBar';
import VModal from '@outreach/components/shared/modal/VModal';
import VLoader from '@outreach/components/VLoader';
import CsvRowWasProcessed from '@events/CsvRowWasProcessed';
import CsvWasProcessed from '@events/CsvWasProcessed';
import VCard from '@outreach/components/VCard';
import VButton from '@outreach/components/VButton';

export default {
    name: 'Tools',
    components: { ProgressBar, VueDropzone, VModal, VLoader, VCard, VButton },
    data () {
        return {
            name: '',
            file: '',
            formErrors: {},
            jobs: {},
            loading: false,
            downloadFileUrl: null
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        csvUploading () {
            return !this.jobs.hasOwnProperty('total');
        },
        progressHeader () {
            return this.csvUploading ? 'Preparing the document' : 'Processing contacts';
        }
    },
    async created () {
        this.subscribe({
            event: CsvRowWasProcessed,
            subscription: this.handleProgressUpdate
        });

        this.subscribe({
            event: CsvWasProcessed,
            subscription: this.handleProgressComplete
        });
    },
    destroyed () {
        this.unsubscribe({
            event: CsvRowWasProcessed,
            subscription: this.handleProgressUpdate
        });

        this.unsubscribe({
            event: CsvWasProcessed,
            subscription: this.handleProgressComplete
        });
    },
    methods: {
        ...mapActions({
            closeModal: 'modals/close',
            openModal: 'modals/open',
            subscribe: 'events/subscribe',
            unsubscribe: 'events/unsubscribe'
        }),
        handleFile (file) {
            this.file = file;
        },
        async upload () {
            this.openModal('progress-bar-modal');
            this.downloadFileUrl = null;
            this.loading = true;
            try {
                await services.admin.recentlyContacted({
                    csv: this.file
                });
            } catch (error) {
                this.closeModal('progress-bar-modal');
                if (!error.response) {
                    throw error;
                }
                if (error.response.data && error.response.data.errors) {
                    this.formErrors = error.response.data.errors;
                }
            }
            this.loading = false;
        },
        handleProgressUpdate (context, event) {
            this.jobs = event.data;
        },
        handleProgressComplete (context, event) {
            this.closeModal('progress-bar-modal');
            this.jobs = {};
            if (!event.data.error) {
                this.downloadFileUrl = event.data.fileName;
                return;
            }
            this.$toasted.error(
                'Sorry, we were unable to process the provided CSV. Please try again or contact support for help'
            );
            console.error(event.data.error);
        }
    }
};
</script>
