<template>
    <transition name="fade" mode="in-out" duration="300">
        <div v-if="showFlyout" class="flyout">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        showFlyout: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.flyout{
    min-width:10rem;
    max-height:17rem;
    @apply absolute w-max overflow-y-scroll top-full z-20 border border-gray-200 bg-white right-0 rounded shadow mt-2;
}
</style>
