<template>
    <v-panel :title="title" :padded="false">
        <template #actionButton>
            <slot name="actionButton"></slot>
        </template>
        <form class="py-4 px-4 sm:px-6" @submit="submit">
            <div class="flex flex-row gap-4">
                <div>
                    <button
                        ref="upload-logo-button"
                        class="border h-20 bg-gray-50"
                        style="min-width: 5rem;"
                        @click.prevent="triggerUpload"
                    >
                        <img v-if="team.logo_url" :src="team.logo_url" class="h-full w-auto" alt="" />
                        <div
                            v-else
                            class="p-3 flex flex-col items-center justify-center text-sm hover:text-brand-regular"
                        >
                            <p>
                                <i class="fa fa-plus text-gray-700"></i>
                                Upload
                            </p>
                        </div>
                    </button>
                </div>
                <div class="flex-1">
                    <v-input
                        ref="name-field"
                        v-model="team.name"
                        label="Name"
                        class="mb-4"
                        :errors="formErrors.name"
                    />
                    <v-textarea
                        ref="description-field"
                        v-model="team.description"
                        label="Description"
                        :limit="300"
                        :errors="formErrors.description"
                    />
                </div>
            </div>
        </form>
        <section class="pb-4">
            <team-member-table
                :members="team.users"
                :can-edit="canEdit"
                :show-credit-transfer="showCreditTransfer"
                @removeMember="removeUsers"
                @openCreditsModal="openCreditsModal"
            />

            <v-call-to-action
                v-if="team.users.length <= 0"
                class="pb-4 col-span-4"
                title="No members found"
                subtitle="Add members to your team."
            />

            <div
                v-if="canEdit"
                class="py-4 mx-4 sm:mx-6 grid grid-cols-6 md:grid-cols-12 gap-x-4 gap-y-2 border-t mt-2"
            >
                <div class="col-span-3 md:col-span-8">
                    <user-multi-select
                        :selected-ids="selectedMemberIds"
                        :with-create="canCreateUser"
                        @addUser="onUserSelected"
                    />
                </div>
            </div>
        </section>
        <template #footer>
            <div class="flex w-full justify-end">
                <v-button
                    ref="submit-button"
                    label="Save"
                    button-style="primary"
                    :loading="loading"
                    @click="submit"
                />
            </div>
        </template>
    </v-panel>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import get from 'lodash/get';
import { TeamRoles } from '@outreach/config/teams';
import VTextarea from '@outreach/components/inputs/VTextarea';
import VPanel from '@outreach/components/shared/VPanel';
import VCallToAction from '@outreach/components/shared/VCallToAction';
import UserMultiSelect from '@outreach/dialogs/UserMultiSelect';
import TeamMemberTable from '@outreach/pages/admin/teams/TeamMemberTable';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';

export default {
    name: 'TeamForm',
    components: { TeamMemberTable, UserMultiSelect, VTextarea, VPanel, VCallToAction, VButton, VInput },
    mixins: [hasPermission],
    props: {
        title: { type: String, required: false, default: '' },
        canEdit: { type: Boolean, required: false, default: true },
        showCreditTransfer: { type: Boolean, required: false, default: false },
        team: { type: Object, required: false, default: () => ({ users: [] }) },
        canCreateUser: { type: Boolean, default: false }
    },
    data () {
        return {
            formErrors: {}
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            loading: ({ loader }) => loader.loading
        }),
        selectedMemberIds () {
            return this.team.users.map(item => item.id);
        }
    },
    methods: {
        ...mapActions({
            create: 'teams/create',
            setMessage: 'alerts/setMessage',
            fetchTeam: 'teams/show'
        }),
        triggerUpload () {
            dialogEmitter.openDialog('ImageLibrary', {
                onSelect: (image) => {
                    this.team.logo_url = image.location;
                },
                accountId: this.user.account_id
            });
        },
        submit () {
            this.formErrors = {};
            try {
                this.$emit('save', {
                    ...this.team,
                    users: this.team.users.map(user => ({
                        id: user.id,
                        role: user.team_role
                    }))
                });
            } catch (e) {
                this.formErrors = get(e, 'response.data.errors', {});
            }
        },
        openCreditsModal (member) {
            dialogEmitter.openDialog('CreditTransfer', {
                member,
                team: this.team,
                onSuccess: () => this.$emit('refreshTeam')
            });
        },
        removeUsers (member) {
            this.team.users = this.team.users.filter((user) => user.id !== member.id);
        },
        onUserSelected (teamMember) {
            if (teamMember) {
                this.addUser(teamMember);
                return;
            }

            dialogEmitter.name('UserFormDialog')
                .addListener('onSuccess', (user) => {
                    this.addUser(user);
                    this.submit();
                })
                .wait();
        },
        addUser (teamMember) {
            this.team.users.push({
                ...teamMember,
                team_role: TeamRoles.MEMBER,
                draft: true
            });
        }
    }
};
</script>
