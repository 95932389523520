<template>
    <div class="container mx-auto -px-4">
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading User</span>
            </div>

            <div v-else class="flex">
                <div class="w-2/3 mx-4">
                    <v-card :has-body-padding="false" class="px-6 pb-6">
                        <template slot="body">
                            <div class="flex items-center justify-between py-4 border-b border-gray-300">
                                <div class="flex items-center">
                                    <div class="contact-avatar">
                                        <avatar :contact="contact" />
                                    </div>
                                    <div>
                                        <h2 class="text-xl text-gray-900 font-semibold">{{ contact.name }}</h2>
                                        <p class="text-gray-700 text-sm">
                                            {{ fromNow(contact.updated_at) }}
                                        </p>
                                    </div>
                                </div>

                                <router-link :to="editContactRoute">
                                    <v-button label="Edit Contact" button-style="primary" outlined />
                                </router-link>
                            </div>

                            <div
                                v-for="campaign in contact.campaigns"
                                :key="campaign.id"
                                class="p-4 border-b border-gray-300"
                            >
                                <div class="flex items-center">
                                    <i class="fa fa-envelope text-xl mr-4"></i>
                                    <div class="mr-auto">
                                        <h3
                                            class="text-base text-gray-800 font-semibold"
                                        >{{ campaign.name }} - {{ campaign.status }}</h3>
                                        <p class="text-xs text-gray-700">{{ fromNow(campaign.created_at) }}</p>
                                    </div>
                                    <router-link
                                        class="view-link"
                                        :to="{ name: 'campaign-review', params: { campaign: campaign.id } }"
                                    >
                                        View
                                    </router-link>
                                </div>
                            </div>
                        </template>
                    </v-card>
                </div>

                <div class="w-1/3 mx-4 flex flex-col text-gray-800">
                    <div class="sticky top-0 py-4">
                        <h3 class="text-lg mb-3 font-bold">Profile Information</h3>
                        <dl class="order-list mb-6">
                            <dt class="mr-4">Name:</dt>
                            <dd class="text-gray-700">{{ contact.name }}</dd>
                            <dt class="mr-4">Email:</dt>
                            <dd class="text-gray-700">{{ contact.email }}</dd>
                            <dt class="mr-4">Notes:</dt>
                            <dd class="text-gray-700 whitespace-pre">{{ contact.note }}</dd>
                        </dl>

                        <template v-if="contact.address">
                            <h3 class="text-lg mb-3 font-bold">Address Information</h3>

                            <dl class="order-list mb-6">
                                <dt class="mr-4">Address 1:</dt>
                                <dd class="text-gray-700">{{ contact.address.address1 }}</dd>
                                <dt class="mr-4">Address 2:</dt>
                                <dd class="text-gray-700">{{ contact.address.address2 }}</dd>
                                <dt class="mr-4">City:</dt>
                                <dd class="text-gray-700">{{ contact.address.city }}</dd>
                                <dt class="mr-4">State:</dt>
                                <dd class="text-gray-700">{{ contact.address.state }}</dd>
                                <dt class="mr-4">Postcode:</dt>
                                <dd class="text-gray-700">{{ contact.address.postcode }}</dd>
                                <dt class="mr-4">Country:</dt>
                                <dd class="text-gray-700">{{ contact.address.country }}</dd>
                            </dl>
                        </template>

                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import services from '@services/Api';
import VButton from '../components/VButton';
import Avatar from '@outreach/components/shared/avatar';
import { mapState } from 'vuex';
import { fromNow } from '@services/Filters';
import VCard from '@outreach/components/VCard';
import VLoader from '@outreach/components/VLoader';

export default {
    components: { VButton, Avatar, VCard, VLoader },
    data () {
        return {
            contact: null,
            loading: false
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        }),
        editContactRoute () {
            return {
                name: 'edit-contact',
                params: {
                    accountId: this.user.account_id,
                    contact: this.contact.id
                }
            };
        }
    },
    created () {
        this.fetchUser();
    },
    methods: {
        fromNow,
        async fetchUser () {
            this.loading = true;
            try {
                const { contact } = this.$route.params;
                const { data } = await services.campaignContacts.fetch(contact);
                this.contact = data.data;
            } catch (e) {
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>
.contact-avatar {
    @apply w-20 h-20 rounded-full mr-4 overflow-hidden bg-gray-300 border border-gray-300;
    @apply flex items-center justify-center;
}

.view-link {
    @apply text-brand-regular uppercase tracking-wider text-sm hover:underline font-semibold;
}
</style>
