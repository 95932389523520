<template>
    <div>
        <div class="p-5">
            <div class="container mx-auto">
                <v-card has-header class="mt-6">
                    <template #header>Folder List</template>
                    <template #body>
                        <folder-list
                            class="grid grid-cols-4 gap-4"
                            :folders="[
                                {id: 1, name: 'Folder 1', assets_count: 22},
                                {id: 2, name: 'Another Folder', assets_count: 2},
                                {id: 3, name: 'Some Folder Somewhere', assets_count: 11},
                                {id: 4, name: 'A Folder For Files', assets_count: 43},
                                {id: 6, name: 'A Folder With A Name', assets_count: 14},
                                {id: 7, name: 'A Folder With a Slightly Longer Name that gets', assets_count: 1},
                            ]"
                            @selected="() => {}"
                        />
                    </template>
                </v-card>
                <v-card has-header header="Input recent seraching" class="mt-6">
                    <template #body>
                        <h2 class="py-2">Array of Objects</h2>
                        <categorized-search
                            v-model="searchInput.objects"
                            :items="[ { category: 'Food', text: 'Spaghetti' },
                                      { category: 'Food', text: 'Squash' },
                                      { category: 'Sport', text: 'Soccer' }]"
                        />
                    </template>
                </v-card>
                <v-card has-header class="mb-6">
                    <template #header>Count Plus One Slider</template>
                    <template #body>
                        <div class="flex items-center gap-32">
                            <div class="flex-1">
                                <label for="">Year (1900 - 2023 range)</label>
                                <count-plus-slider
                                    v-model="filters.year"
                                    field="year"
                                    :min-value="1900"
                                    :max-value="2023"
                                />
                            </div>
                            <div class="flex-1">
                                <label for="">Bedroom Count (0 - 10+)</label>
                                <count-plus-slider
                                    v-model="filters.bedroom_count"
                                    field="bedroom_count"
                                    :count="10"
                                />
                            </div>
                        </div>
                    </template>
                </v-card>
                <v-card has-header class="mb-6">
                    <template #header>Contact Card Buttons</template>
                    <template #body>
                        <div class="flex items-center gap-8">
                            <contact-card-button
                                class="bg-brand-regular text-gray-800 p-1 h-12 w-12"
                            />
                            <contact-card-button
                                completed
                                class="bg-brand-regular text-gray-800 p-1 h-12 w-12"
                            />

                            <contact-card-button
                                class="bg-brand-regular text-gray-800 p-1 h-12 w-12"
                                icon="fa-brands fa-linkedin"
                            />

                            <contact-card-button
                                class="bg-brand-regular text-gray-800 p-1 h-12 w-12"
                                icon="fa-brands fa-linkedin"
                                completed
                            />

                            <contact-card-button
                                copy="123-456-7890"
                                class="bg-brand-regular text-gray-800 p-1 h-12 w-12"
                                icon="fa-solid fa-phone"
                            />
                            <contact-card-button
                                class="bg-brand-regular text-gray-800 p-1 h-12 w-12"
                                icon="fa-solid fa-phone"
                                completed
                            />
                        </div>
                    </template>
                </v-card>
                <v-card has-header class="mb-6">
                    <template #header>Report Graphs</template>
                    <template #body>
                        <div class="grid grid-cols-3 gap-3">
                            <note-count-widget />
                        </div>
                    </template>
                </v-card>
                <v-card has-header class="mb-6">
                    <template slot="header">Buttons</template>
                    <template slot="body">
                        <div class="flex mb-3 flex-wrap">
                            <v-button disabled label="Default" class="m-2" />
                            <v-button label="Default" class="m-2" />
                            <v-button button-style="primary" loading label="Primary" class="m-2" />
                            <v-button button-style="primary" label="Primary" class="m-2" />
                            <v-button button-style="success" label="Success" class="m-2" />
                            <v-button button-style="error" label="Error" class="m-2" />
                            <v-button button-style="warning" label="Warning" class="m-2" />
                            <v-button button-style="info" label="Info" class="m-2" />
                        </div>
                        <div class="flex flex-wrap">
                            <v-button disabled outlined label="Disabled" class="m-2" />
                            <v-button outlined label="Default" class="m-2" />
                            <v-button
                                outlined
                                button-style="primary"
                                loading
                                label="Primary"
                                class="m-2"
                            />
                            <v-button outlined button-style="primary" label="Primary" class="m-2" />
                            <v-button outlined button-style="success" label="Success" class="m-2" />
                            <v-button outlined button-style="error" label="Error" class="m-2" />
                            <v-button outlined button-style="warning" label="Warning" class="m-2" />
                            <v-button outlined button-style="info" label="Info" class="m-2" />
                        </div>
                        <div class="flex mb-3 flex-wrap">
                            <v-button
                                label="Confirm"
                                class="m-2"
                                confirm="Are you sure you want to execute this action?"
                                @click="buttonConfirm"
                            />
                            <v-button label="Action" class="m-2" @click="buttonClick" />
                        </div>

                        <div>
                            <v-button-group>
                                <v-button outlined button-style="error" label="Error" />
                                <v-button outlined button-style="warning" label="Warning" />
                                <v-button outlined button-style="info" label="Info" />
                            </v-button-group>
                        </div>

                    </template>
                </v-card>

                <v-card has-header class="mb-6">
                    <template slot="header">Modal</template>
                    <template slot="body">
                        <v-button class="mb-6" button-style="primary" label="toggle modal" @click="showModal = true" />
                        <button-modal class="mb-6" label="Toggle modal" header="Sample modal">
                            <template #content>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </template>
                            <template #actions="{ close }">
                                <div class="p-2">
                                    <v-button label="No" class="mr-2" @click="buttonModalClick('no', close)" />
                                    <v-button
                                        label="Yes"
                                        button-style="error"
                                        @click="buttonModalClick('yes', close)"
                                    />
                                </div>
                            </template>
                        </button-modal>
                    </template>
                </v-card>

                <v-card has-header class="mb-6">
                    <template slot="header">Inputs</template>
                    <template slot="body">
                        <v-input v-model="sampleInputModel" label="Sample Input" class="mb-5" />
                        <div class="flex flex-wrap items-stretch -mx-1 mb-5" style="height: 150px;">
                            <v-radio-button
                                v-model="sampleRadioButton"
                                name="receiveNotes"
                                class="flex-1 mx-1"
                                value="yes"
                                label="Yes, please"
                            />
                            <v-radio-button
                                v-model="sampleRadioButton"
                                name="receiveNotes"
                                class="flex-1 mx-1"
                                value="no"
                                label="No, thanks"
                            />
                        </div>
                        <v-textarea v-model="sampleTextareaModel" label="Sample Textarea" class="mb-5" />
                        <v-select v-model="selectedOption" :options="selectOptions" label="Sample Select" />
                        <v-slider />
                    </template>
                </v-card>

                <v-card has-header class="mb-6">
                    <template slot="header">Table</template>
                    <template slot="body">
                        <v-list :rows="list" class="border mb-6" @click="listClick">
                            <template #default="{row}">
                                <div>{{ row }}</div>
                            </template>
                        </v-list>
                        <v-table
                            v-slot="{ row }"
                            has-selection
                            has-actions
                            :rows="mockTableData"
                            class="mb-6"
                            @data-selected="tableDataChange"
                        >
                            <v-table-column label="User" value="email" sortable>
                                <span>{{ row.user }}</span>
                                <span class="text-sm text-gray-600">{{ row.email }}</span>
                            </v-table-column>
                            <v-table-column label="Location" value="location">
                                <span>{{ row.location }}</span>
                                <span class="text-sm text-gray-600">{{ row.postalCode }}</span>
                            </v-table-column>
                            <v-table-column label="Last Purchase" value="lastPurchase" sortable>
                                <span>{{ formatDate(row.lastPurchase) }}</span>
                                <span class="text-sm text-gray-600">{{ fromNow(row.lastPurchase) }}</span>
                            </v-table-column>
                            <v-table-column label="Average Purchase" value="AveragePurcaseAmount" sortable>
                                ${{ row.AveragePurcaseAmount }}
                            </v-table-column>
                        </v-table>

                        <v-table
                            v-slot="{ row }"
                            :rows="mockTableData"
                            class="mb-6"
                            @data-selected="tableDataChange"
                        >
                            <v-table-column label="User" value="email" sortable>
                                <span>{{ row.user }}</span>
                                <span class="text-sm text-gray-600">{{ row.email }}</span>
                            </v-table-column>
                            <v-table-column label="Location" value="location">
                                <span>{{ row.location }}</span>
                                <span class="text-sm text-gray-600">{{ row.postalCode }}</span>
                            </v-table-column>
                            <v-table-column label="Last Purchase" value="lastPurchase" sortable>
                                <span>{{ formatDate(row.lastPurchase) }}</span>
                                <span class="text-sm text-gray-600">{{ fromNow(row.lastPurchase) }}</span>
                            </v-table-column>
                            <v-table-column label="Average Purchase" value="AveragePurcaseAmount" sortable>
                                ${{ row.AveragePurcaseAmount }}
                            </v-table-column>
                        </v-table>

                        <v-table
                            v-slot="{ row }"
                            has-actions
                            :actions="['Add', 'Remove']"
                            :rows="mockTableData"
                            @data-selected="tableDataChange"
                            @action="tableAction"
                        >
                            <v-table-column label="User" value="email" sortable>
                                <span>{{ row.user }}</span>
                                <span class="text-sm text-gray-600">{{ row.email }}</span>
                            </v-table-column>
                            <v-table-column label="Location" value="location">
                                <span>{{ row.location }}</span>
                                <span class="text-sm text-gray-600">{{ row.postalCode }}</span>
                            </v-table-column>
                            <v-table-column label="Last Purchase" value="lastPurchase" sortable>
                                <span>{{ formatDate(row.lastPurchase) }}</span>
                                <span class="text-sm text-gray-600">{{ fromNow(row.lastPurchase) }}</span>
                            </v-table-column>
                            <v-table-column label="Average Purchase" value="AveragePurcaseAmount" sortable>
                                ${{ row.AveragePurcaseAmount }}
                            </v-table-column>
                        </v-table>
                    </template>
                </v-card>

                <v-card has-header class="mb-6">
                    <template slot="header">Loaders</template>
                    <template slot="body">
                        <div class="flex">
                            <div class="flex-1 mb-4">
                                <v-loader :size="32" :border-width="3" />
                            </div>
                            <div class="flex-1">
                                <v-loader :size="32" :border-width="3" color="primary" />
                            </div>
                            <div class="flex-1">
                                <v-loader :size="32" :border-width="3" color="success" />
                            </div>
                            <div class="flex-1">
                                <v-loader :size="32" :border-width="3" color="warning" />
                            </div>
                            <div class="flex-1">
                                <v-loader :size="32" :border-width="3" color="error" />
                            </div>
                            <div class="flex-1">
                                <v-loader :size="32" :border-width="3" color="info" />
                            </div>
                        </div>
                    </template>
                </v-card>

                <v-card has-header class="mb-6">
                    <template #header>Dropdowns</template>
                    <template #body>
                        <div class="flex justify-around">
                            <dropdown>
                                <template #priority-options>
                                    <button>Edit</button>
                                    <button>Something</button>
                                </template>
                                <template #content>
                                    <button>Save</button>
                                    <button>Create</button>
                                    <button>Delete</button>
                                </template>
                            </dropdown>
                        </div>
                    </template>
                </v-card>
            </div>
        </div>

        <modal header="This is the modal header" :open="showModal" @close-modal="showModal = false">
            <template slot="content">
                <div class="w-modal">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </div>
            </template>

            <template slot="modal-actions">
                <div class="p-2">
                    <v-button label="No" class="mr-2" @click="showModal = false" />
                    <v-button label="Yes" button-style="error" />
                </div>
            </template>
        </modal>

        <v-card>
            <template #body>
                <tab-view :tabs="tabs">
                    <template #first-tab>
                        Hello first tab
                    </template>
                    <template #second-tab>
                        Hello second tab
                    </template>
                </tab-view>
            </template>
        </v-card>

    </div>
</template>

<script>
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import ButtonModal from '@outreach/components/shared/modal/ButtonModal';
import Modal from '@outreach/components/shared/modal/index';
import VRadioButton from '@outreach/components/inputs/VRadioButton';
import VList from '@outreach/components/shared/VList';
import VSlider from '@outreach/components/inputs/VSlider';
import { formatDate, fromNow } from '@services/Filters';
import Dropdown from '@outreach/components/shared/dropdown';
import VButtonGroup from '@outreach/components/shared/VButtonGroup';
import NoteCountWidget from '@outreach/components/widgets/NoteCountWidget';
import VSelect from '@outreach/components/inputs/VSelect';
import VTextarea from '@outreach/components/inputs/VTextarea';
import VCard from '@outreach/components/VCard';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import TabView from '@outreach/components/shared/TabView';
import ContactCardButton from '@outreach/components/contacts/ContactCardButton.vue';
import CountPlusSlider from '@outreach/pages/OutboundProspecting/CountPlusSlider.vue';
import RealEstateFilters from '@outreach/pages/OutboundProspecting/RealEstateFilters';
import CategorizedSearch from '@outreach/components/inputs/CategorizedSearch.vue';
import FolderList from '@outreach/components/shared/image-library/FolderList.vue';

const mockTableData = [
    {
        id: 1,
        user: 'Darren Galway',
        email: 'd.galway@vehikl.com',
        postalCode: 'N2A4K6',
        location: 'Kitchener, Ontario',
        lastPurchase: '1559142512',
        AveragePurcaseAmount: 560
    },
    {
        id: 2,
        user: 'Brad Brothers',
        email: 'b.brothers@vehikl.com',
        postalCode: 'K2L4O2',
        location: 'Kitchener, Ontario',
        lastPurchase: '1515974400',
        AveragePurcaseAmount: 320
    },
    {
        id: 3,
        user: 'Lisa Fehr',
        email: 'l.fehr@vehikl.com',
        postalCode: 'J2R4N4',
        location: 'Kitchener, Ontario',
        lastPurchase: '1551484800',
        AveragePurcaseAmount: 9000
    }
];

export default {
    components: {
        FolderList,
        CategorizedSearch,
        CountPlusSlider,
        ContactCardButton,
        NoteCountWidget,
        VButtonGroup,
        ButtonModal,
        VTable,
        Modal,
        VTableColumn,
        VRadioButton,
        VList,
        VSlider,
        Dropdown,
        VSelect,
        VTextarea,
        VCard,
        VButton,
        VInput,
        VLoader,
        TabView
    },
    data: () => ({
        sampleRadioButton: 'yes',
        sampleInputModel: null,
        sampleTextareaModel: null,
        showModal: false,
        mockTableData,
        table: {
            columns: ['User', 'Location', 'Last Purchase Date', 'Average Purchase Amt'],
            rows: []
        },
        list: [1, 2, 3, 4, 5],
        selectedOption: 'product_1',
        selectOptions: [
            {
                label: 'Product 1',
                value: 'product_1'
            },
            {
                label: 'Product 2',
                value: 'product_2'
            },
            {
                label: 'Product 3',
                value: 'product_3'
            },
            {
                label: 'Product 4',
                value: 'product_4'
            },
            {
                label: 'Product 5',
                value: 'product_5'
            }
        ],
        tabs: ['First Tab', 'Second Tab'],
        filters: new RealEstateFilters(),
        searchInput: {
            strings: '',
            objects: ''
        }
    }),
    computed: {
        priorityOptions () {
            return [
                {
                    label: 'Button 1',
                    onClick: () => console.log('I am a option 1')
                },
                {
                    label: 'Button 2',
                    onClick: () => console.log('I am a option 2')
                },
                {
                    label: 'Button 3',
                    onClick: () => console.log('I am a option 3')
                },
                {
                    label: 'Priority 1',
                    priority: true,
                    onClick: () => console.log('I am a option p1')
                },
                {
                    label: 'Priority 2',
                    priority: true,
                    onClick: () => console.log('I am a option p2')
                }
            ];
        }
    },
    methods: {
        formatDate,
        fromNow,
        tableDataChange (val) {
            console.log(val);
        },
        tableAction (val) {
            console.log(val);
        },
        buttonModalClick (name, close) {
            console.log('buttonModalClick: ', name);
            close();
        },
        listClick (row) {
            console.log('listClick', row);
        },
        buttonConfirm () {
            console.log('button confirm');
        },
        buttonClick () {
            console.log('button click');
        }
    }
};
</script>
