<template>
    <div class="container mx-auto">
        <transition name="fade" mode="out-in">
            <v-panel :title="header" :padded="false" :loading="loading">
                <template #actionButton>
                    <div v-if="searchable" class="search-box">
                        <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1" label="Search" />
                        <button
                            v-if="query"
                            v-tooltip="'Cancel'"
                            class="mr-2 hover:text-red-500 text-xl"
                            @click="query = undefined"
                        >
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </template>

                <paginator-wrap
                    :pagination="campaignPagination"
                    :change-page="nextCampaignPage"
                >
                    <template #content>
                        <div class="p-4 grid grid-cols-2 md:grid-cols-5 gap-x-4 gap-y-2 bg-gray-50">
                            <p class="column-header">Campaign Name</p>
                            <p class="column-header hidden md:inline-block">Account Name</p>
                            <p class="column-header">Job Count</p>
                            <p class="column-header hidden sm:block">Actions</p>
                        </div>

                        <div
                            v-for="(row, index) in campaigns"
                            :key="index"
                            class="px-4 py-4 grid grid-cols-2 md:grid-cols-5 gap-x-4 gap-y-2"
                            :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                        >
                            <div
                                :id="`campaign-${row.id}`"
                                class="cursor-pointer hover:text-brand-regular"
                                @click="$router.push({ name: 'campaign-review', params: { campaign: row.id }})"
                            >
                                <p class="underline">{{ row.name }}</p>
                                <p class="text-sm md:hidden">({{ row.account_name }})</p>
                                <slot name="character-count" :campaign="row"></slot>
                            </div>
                            <div class="hidden md:block">{{ row.account_name }}</div>
                            <slot name="job-count" :campaign="row">
                                <span class="text-center">{{ row[type] }}</span>
                            </slot>
                            <div class="mt-2 sm:mt-0 col-span-2">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <v-button
                                        :ref="`campaign-${row.id}-details`"
                                        label="Details"
                                        size="sm"
                                        @click="() => handleDetailsClick(row)"
                                    />

                                    <v-button
                                        v-for="button in campaignActions"
                                        :key="button.ref"
                                        :ref="`campaign-${row.id}-${button.ref}`"
                                        :button-style="button.buttonStyle"
                                        :label="button.label"
                                        :confirm="button.confirm"
                                        :disabled="button.disable && button.disable(row)"
                                        size="sm"
                                        @click="() => handleCampaignAction(button.action, row)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="!campaigns.length" class="text-center p-4">
                            <p v-if="loading">Loading {{ type }} queue data.</p>
                            <p v-else class="text-lg text-gray-700">No {{ type }} jobs found.</p>
                        </div>
                    </template>
                </paginator-wrap>
            </v-panel>
        </transition>

        <v-modal
            :ref="detailsModalName"
            :name="detailsModalName"
            :header="header"
            disable-on-click-away
        >
            <template #content>
                <individual-job-table
                    class="h-full"
                    :rows="jobs"
                    :pagination="jobPagination"
                    :loading="loading"
                    @change-page="load($event)"
                    @search="search = $event"
                >
                    <template #actions="{ row }">
                        <v-button
                            v-for="button in jobActions"
                            :key="button.ref"
                            :ref="`job-${row.id}-${button.ref}`"
                            :button-style="button.buttonStyle"
                            class="mr-2"
                            :label="button.label"
                            :confirm="button.confirm"
                            @click="() => handleJobAction(button.action, row)"
                        />
                    </template>
                </individual-job-table>
            </template>

            <template #modal-actions="{ close }">
                <div class="p-2">
                    <v-button
                        ref="back-button"
                        label="Back"
                        button-style="error"
                        class="mr-2"
                        @click="close"
                    />
                </div>
            </template>
        </v-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash/debounce';

import VButton from '@outreach/components/VButton';
import VModal from '@outreach/components/shared/modal/VModal';
import IndividualJobTable from '@outreach/components/queues/IndividualJobTable';
import VPanel from '@outreach/components/shared/VPanel';
import VInput from '@outreach/components/inputs/VInput';
import PaginatorWrap from '@outreach/components/PaginatorWrap';

export default {
    components: {
        VButton,
        VModal,
        IndividualJobTable,
        VPanel,
        VInput,
        PaginatorWrap
    },
    props: {
        type: { type: String, required: true },
        header: { type: String, required: true },
        campaignActions: { type: Array, required: true },
        jobActions: { type: Array, required: true },
        searchable: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    data: () => {
        return {
            search: null,
            selectedCampaign: null
        };
    },
    computed: {
        campaignPagination () {
            return this.$store.getters[`${this.type}/campaignPagination`];
        },
        jobPagination () {
            return this.$store.getters[`${this.type}/jobPagination`];
        },
        campaigns () {
            return this.$store.getters[`${this.type}/campaigns`];
        },
        jobs () {
            return this.$store.getters[`${this.type}/jobs`];
        },
        query: {
            get () {
                return this.$store.getters[`${this.type}/query`];
            },
            set (value) {
                this.$store.dispatch(`${this.type}/setQuery`, value);
            }
        },
        detailsModalName () {
            return `${this.type}-details-modal`;
        }
    },
    watch: {
        query: debounce(async function () {
            await this.$store.dispatch(`${this.type}/listCampaigns`, { q: this.query });
        }, 400),
        search: debounce(function () {
            this.load();
        }, 400),
        selectedCampaign () {
            this.load();
        }
    },
    methods: {
        ...mapActions({
            openModal: 'modals/open',
            setMessage: 'alerts/setMessage'
        }),
        async load (page = 1) {
            await this.$store.dispatch(`${this.type}/listJobs`, {
                campaignId: this.selectedCampaign.id,
                params: {
                    page,
                    q: this.search
                }
            });
        },
        async nextCampaignPage (page) {
            await this.$store.dispatch(`${this.type}/listCampaigns`, { page });
        },
        async handleCampaignAction (action, campaign) {
            if (typeof action === 'function') {
                action(campaign);
                return;
            }
            await this.$store.dispatch(action, campaign);
        },
        async handleJobAction (action, job) {
            await this.$store.dispatch(action, job);
            await this.load();
        },
        async handleDetailsClick (row) {
            this.selectedCampaign = row;
            this.search = null;
            this.openModal(this.detailsModalName);
        }
    }
};
</script>
<style scoped lang="scss">
.search-box {
    @apply flex flex-col sm:flex-row items-center items-stretch lg:mb-0 mb-6 sm:mb-2 px-6 sm:px-0;
}
.column-header {
    @apply text-gray-700 text-xs uppercase tracking-widest overflow-hidden text-center;
}
</style>
