import ApiClient from '@services/Http/Api';

export const fetch = (accountId, templateId) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/templates/${templateId}`, { params: { include: 'font' } });
};

export const list = (accountId, params) => {
    return ApiClient.get(`/dashboard/accounts/${accountId}/templates`, { params });
};

export const create = (accountId, data) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/templates`, data);
};

export const update = (accountId, template) => {
    return ApiClient.patch(`/dashboard/accounts/${accountId}/templates/${template.id}`, template);
};

export const duplicate = (accountId, templateId) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/templates/${templateId}/duplicate`);
};

export const destroy = (accountId, templateId) => {
    return ApiClient.destroy(`/dashboard/accounts/${accountId}/templates/${templateId}`);
};

export const sort = (accountId, templates) => {
    return ApiClient.put(`/dashboard/accounts/${accountId}/templates`, { templates });
};

export const previewTemplate = (accountId, template) => {
    return ApiClient.post(`/dashboard/accounts/${accountId}/templates/preview`, template);
};
