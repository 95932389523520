import services from '@services/Api';

export const state = {
    records: [],
    meta: {}
};

export const actions = {
    list: async ({ commit }, params = {}) => {
        const { data } = await services.associates.list(params);
        commit('setRecords', data.data);
        commit('setMeta', data.meta);
    },
    destroy: async ({ commit }, id) => {
        await services.associates.destroy(id);
        commit('removeRecord', id);
    }
};

export const mutations = {
    setRecords: (state, records) => {
        state.records = records;
    },
    setMeta: (state, meta) => {
        state.meta = meta;
    },
    removeRecord (state, id) {
        state.records = state.records.filter(record => record.id !== id);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
