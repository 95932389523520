<template>
    <div>
        <v-button
            v-if="!['sent', 'submitted'].includes(campaign.status)"
            ref="submit-button"
            class="w-full"
            label="Submit Order"
            :loading="processing"
            :disabled="processing || !isCustomerService()"
            button-style="primary"
            @click="onSubmit"
        />
        <v-button
            v-else
            ref="duplicate-button"
            class="w-full"
            label="Duplicate"
            :loading="loading"
            button-style="primary"
            @click="$emit('duplicate')"
        />
        <v-help-icon
            :text="'Currently, we\'re at capacity and are not taking new orders at this time. '
                + 'Please stay tuned for future updates and developments '
                + 'we plan on being back up by early January 2024. '
                + 'If you have any questions, please reach out to the team directly at support@audience.co.'"
        />
    </div>
</template>
<script>
import VButton from '@outreach/components/VButton';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { Alert } from '@services/Alert';
import { mapState } from 'vuex';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VHelpIcon from '@outreach/components/VHelpIcon.vue';

export default {
    name: 'SubmitCampaign',
    components: { VHelpIcon, VButton },
    mixins: [hasPermission],
    props: {
        campaign: { type: Object, required: true },
        loading: { type: Boolean, required: true }
    },
    data () {
        return {
            paying: false
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            pagination: ({ campaignDeliveries }) => campaignDeliveries.meta
        }),
        processing () {
            return this.loading || this.paying;
        },
        balance () {
            return this.user.account.credits;
        }
    },
    methods: {
        async onSubmit () {
            if (!this.campaign.sender_address_id) {
                await Alert.error('A sender address must be selected in order to submit a campaign.');
                return;
            }

            await this.confirmSubmit();
        },
        submit () {
            if (this.pagination.total > this.balance) {
                return this.openPayment();
            }

            this.$emit('submit');
        },
        openPayment () {
            dialogEmitter.name('PaymentDialog')
                .addListener('purchased', () => {
                    this.paying = true;
                    this.$emit('submit');
                })
                .wait();
        },
        async confirmSubmit () {
            dialogEmitter.name('ConfirmSubmitDialog').addListener('confirmed', () => this.submit()).wait();
        }
    }
};
</script>
