const filteredFields = [
    'linkedin_url',
    'first_name',
    'last_name',
    'company_url'
];

export class Contact {
    constructor (data) {
        const { details, ...rest } = data;
        Object.assign(this, rest);
        this._details = details;
    }

    get details () {
        return Object.entries(this._details || {}).filter((pair) => {
            if (pair[1] == null || pair[1].length <= 0) return false;
            return !filteredFields.includes(pair[0]);
        });
    }

    get companyUrl () {
        return this._details && this._details.company_url;
    }

    get linkedinUrl () {
        return this._details && this._details.linkedin_url;
    }

    get hasAddress () {
        return this.address && Boolean(this.address.address1);
    }
}
