<template>
    <article v-if="loaded" class="w-full">
        <div>
            <portal to="header-actions">
                <v-toggle
                    v-if="articleId"
                    ref="global_toggle"
                    v-model="article.is_global"
                    label="Global"
                    class="mr-4"
                    :label-enable-text="'Global'"
                    :label-disable-text="'Assignable'"
                    @input="submit"
                />
                <v-button
                    ref="submit_button"
                    :label="articleId ? 'Update Content' : 'Create Content'"
                    button-style="primary"
                    @click="submit"
                />
                <v-button
                    v-if="articleId"
                    ref="assign_accounts"
                    label="Assign Accounts"
                    :disabled="Boolean(article.is_global)"
                    @click="openAccountsDialog"
                />
            </portal>

            <label for="title" class="sr-only">Content title</label>
            <input
                id="title"
                ref="title_field"
                v-model="article.title"
                name="text"
                class="block w-full text-4xl font-bold p-1 bg-transparent outline-none"
                placeholder="Headline"
                maxlength="255"
            />
            <form-errors :errors="formErrors.title" />

            <div class="aspect-w-16 aspect-h-9 my-4">
                <input
                    ref="file_input"
                    type="file"
                    class="hidden"
                    accept=".jpg, .png, .jpeg"
                    @change="processFile"
                />
                <img
                    v-if="fileSrc"
                    id="frame"
                    :src="fileSrc"
                    :alt="article.title"
                    class="cursor-pointer"
                    @click="$refs.file_input.click()"
                />
                <div v-else class="bg-gray-100 flex flex-col justify-center rounded-lg">
                    <button class="mx-auto text-center" @click="$refs.file_input.click()">
                        <i class="fa fa-image text-gray-500 text-8xl"></i>
                        <p class="text-lg font-bold">Upload a cover image</p>
                        <p class="text-gray-700">Linkedin recommends a pixel size of 1280 x 720</p>
                    </button>
                </div>
            </div>
            <form-errors :errors="formErrors.header_image" />

            <section v-if="type === 'video'" class="py-6">
                <v-input ref="vimeo-link" v-model="article.media_url" readonly label="Vimeo Link" />
            </section>

            <section class="bg-white border">
                <rich-text-editor ref="body_field" v-model="article.body" :suggestions="tipTapSuggestions" />
            </section>
            <form-errors :errors="formErrors.body" />

            <template v-if="type === 'standard'">
                <v-typeahead
                    id="hashtags"
                    ref="typeahead_field"
                    v-model="article.hashtags"
                    class="mt-4"
                    label="Hashtags"
                    :options="tagValues"
                    :strategies-function="strategyFunction"
                />
                <form-errors :errors="formErrors.hashtags" />
            </template>

            <labelled-tag-select v-model="article.tags" class="mt-4" :type="tagType" />

            <v-panel title="Meta Fields" class="overflow-visible my-4">
                <v-input
                    id="meta_title"
                    ref="meta_title_field"
                    v-model="article.meta_title"
                    class="flex-1"
                    label="Meta Title"
                    :errors="formErrors.meta_title"
                />

                <v-input
                    id="meta_body"
                    ref="meta_body_field"
                    v-model="article.meta_body"
                    class="flex-1 my-2"
                    type="textarea"
                    label="Meta Body"
                    :errors="formErrors.meta_body"
                />
            </v-panel>
        </div>
    </article>
    <v-loader v-else />
</template>

<script>
import RichTextEditor from '@outreach/components/services/RichTextEditor';
import { mapActions, mapState } from 'vuex';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import { base64FromFile } from '@services/Utils';
import VTypeahead from '@outreach/components/VTypeahead';
import { getHashtagsStrategy } from '@services/Typeahead';
import FormErrors from '@outreach/components/shared/FormErrors';
import get from 'lodash/get';
import { interpolationValues, tiptapSuggestions } from '@outreach/pages/Articles/ArticleInterpolation';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import VButton from '@outreach/components/VButton';
import VInput from '@outreach/components/inputs/VInput';
import VLoader from '@outreach/components/VLoader';
import VPanel from '@outreach/components/shared/VPanel.vue';
import LabelledTagSelect from '@outreach/components/tag-select/LabelledTagSelect';
import VToggle from '@outreach/components/VToggle.vue';
import { TagType } from '@services/TagType';

export default {
    name: 'ArticleForm',
    components: {
        VToggle,
        LabelledTagSelect,
        FormErrors,
        VTypeahead,
        RichTextEditor,
        VButton,
        VInput,
        VLoader,
        VPanel
    },
    props: {
        existingArticle: { type: Object, required: false, default: () => {} },
        type: { type: String, required: true }
    },
    data () {
        return {
            article: { ...this.existingArticle },
            fileSrc: this.existingArticle.header_image || null,
            formErrors: {},
            bodyFocused: false
        };
    },
    computed: {
        ...mapState({
            articleFromStore: ({ articles }) => articles.record
        }),
        strategyFunction () {
            return getHashtagsStrategy;
        },
        articleId () {
            return this.$route.params.articleId;
        },
        loaded () {
            if (this.articleId == null) return true;
            return this.existingArticle != null;
        },
        requestPayload () {
            const payload = {
                title: this.article.title,
                body: this.article.body,
                meta_title: this.article.meta_title,
                meta_body: this.article.meta_body,
                header_image_file: this.article.header_image_file,
                hashtags: this.article.hashtags,
                tags: this.article.tags,
                is_global: this.article.is_global
            };

            if (this.type === 'video') {
                payload.header_image = this.article.header_image;
                payload.media_url = this.article.media_url;
            }

            return payload;
        },
        tagValues () {
            return interpolationValues;
        },
        tagType () {
            return TagType.CONTENT;
        },
        tipTapSuggestions () {
            return tiptapSuggestions;
        }
    },
    methods: {
        ...mapActions({
            create: 'articles/create',
            update: 'articles/update'
        }),
        async processFile (event) {
            this.$set(this.article, 'header_image_file', event.target.files[0]);
            if (this.article.header_image_file != null) {
                this.fileSrc = await base64FromFile(this.article.header_image_file);
            }
        },
        async submit () {
            if (this.articleId) {
                await this.updateArticle();
            } else {
                await this.createArticle();
            }
        },
        async updateArticle () {
            try {
                await this.update({ payload: this.requestPayload, articleId: this.articleId });
                this.$toasted.success('Content updated.');
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
                throw new ClientVisibleError('This article could not be updated.');
            }
        },
        async createArticle () {
            try {
                await this.create({ payload: this.requestPayload });
                this.$toasted.success('Content created.');
                await this.$router.push({ name: 'edit-article', params: { articleId: this.articleFromStore.id } });
            } catch (error) {
                this.formErrors = get(error, 'response.data.errors', {});
                throw new ClientVisibleError('This article could not be created.');
            }
        },
        openAccountsDialog () {
            dialogEmitter
                .name('AttachAccountsToArticlesDialog')
                .props({
                    articleId: this.article.id,
                    defaultValue: this.article.accounts.map(account => account.id)
                })
                .wait();
        }
    }
};
</script>
