import ApiClient from '@services/Http/Api';

export const transferCredits = (credits, teamId, userId) => {
    return ApiClient.post(`/dashboard/teams/${teamId}/transfer-credits/${userId}`, {
        credits
    });
};

export const update = (teamId, team) => {
    return ApiClient.patch(`/dashboard/teams/${teamId}`, team);
};

export const destroy = (teamId) => {
    return ApiClient.destroy(`/dashboard/teams/${teamId}`);
};

export const show = (teamId) => {
    return ApiClient.get(`/dashboard/teams/${teamId}`);
};

export const fetchTeams = (params = {}) => {
    return ApiClient.get('/dashboard/teams', { params });
};
