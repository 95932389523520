<template>
    <div class="flex-column md:flex bg-white shadow-md p-4 font-light w-full">
        <div ref="first-column" class="flex-1 p-3">
            <div class="flex flex-row mb-2">
                <div class="contact-avatar">
                    <avatar :contact="link.contact" :size="80" />
                </div>
                <div class="md:inline-flex flex-col my-auto">
                    <p>{{ link.contact.name }}</p>
                    <p
                        v-if="link.contact.details.company_name"
                        class="font-semibold text-sm text-gray-600 tracking-wider"
                    >
                        {{ link.contact.details.company_name }}
                    </p>
                    <router-link
                        v-if="link.campaign"
                        :to="{ name: 'campaign-review', params: { campaign: link.campaign.id } }"
                        class="text-sm underline"
                    >{{ link.campaign.name }}</router-link>
                    <span v-if="link.contact.deleted_at">Contact Archived</span>
                    <router-link
                        v-else
                        :to="{ name: 'edit-contact', params: { contact: link.contact.id, accountId: user.account_id } }"
                        class="text-brand-dark text-sm underline"
                    >
                        View Contact
                    </router-link>
                </div>
            </div>
            <div v-if="link.contact.email" ref="contact-info" class="flex items-baseline break-all">
                <i class="fas fa-envelope text-brand-dark mr-3"></i>
                {{ link.contact.email }}
            </div>
            <div v-if="link.contact.details.linkedin_url" ref="contact-info" class="flex items-baseline break-all">
                <i class="fab fa-linkedin text-brand-dark mr-3"></i>
                <a :href="withProtocol(link.contact.details.linkedin_url)">LinkedIn</a>
            </div>
            <div ref="address" class="flex items-baseline address">
                <i class="fas fa-home text-brand-dark mr-2"></i>
                <div>
                    <p>{{ link.contact.address.address1 }}</p>
                    <p v-if="link.contact.address.address2">{{ link.contact.address.address2 }}</p>
                    <p>{{ link.contact.address.city }}, {{ link.contact.address.state }}</p>
                    <p>
                        {{ link.contact.address.country || 'USA' }}, {{ link.contact.address.postcode }}
                    </p>
                </div>
            </div>
        </div>
        <div ref="second-column" class="flex-1 py-3 px-3 overflow-hidden">
            <div class="details">
                <div class="flex justify-between">
                    <span class="mr-2">URL:</span>
                    <a v-tooltip="link.url" :href="link.url" class="underline break-all truncate">{{ link.url }}</a>
                </div>
                <div class="flex justify-between">
                    <span>Visits:</span>{{ link.visits }}
                </div>
                <div v-if="link.sent_at" class="flex justify-between">
                    <span>Sent At:</span> {{ formatDateTime(new Date(link.sent_at)) }}
                </div>
                <div v-else class="flex justify-between">
                    <span>Sent At:</span>-
                </div>
                <div class="flex justify-between">
                    <span>Last Visit:</span>{{ formatRelativeDateTime(link.last_visit) }}
                </div>
            </div>
        </div>
        <div ref="third-column" class="flex-1 flex items-center justify-center">
            <div class="text-center flex flex-col -my-1 ml-4">
                <mail-to-button
                    v-if="link.contact.email"
                    :ref="`email-button-${link.contact.id}`"
                    :template-id="user.account.outreach_template.id"
                    :contact-id="link.contact.id"
                    class="my-1"
                    label="Send Mail"
                >
                    <template #before-label>
                        <i class="fa mr-3 fa-envelope"></i>
                    </template>
                </mail-to-button>
                <a
                    v-if="link.contact.details.linkedin_url"
                    :href="link.contact.details.linkedin_url"
                    class="my-1"
                    target="_blank"
                >
                    <v-button button-style="info" label="Connect on LinkedIn">
                        <template slot="before-label">
                            <i class="fab fa-linkedin mr-3"></i>
                        </template>
                    </v-button>
                </a>
                <v-button
                    v-if="isCustomerService()"
                    :ref="`delete-log-${link.id}`"
                    button-style="error"
                    label="Remove Scans"
                    @click="$emit('list-logs', link)"
                >
                    <template #before-label>
                        <i class="fas fa-trash mr-3"></i>
                    </template>
                </v-button>
            </div>
        </div>
    </div>
</template>
<script>
import Avatar from '@outreach/components/shared/avatar';
import { formatDateTime, formatRelativeDateTime } from '@services/Filters';
import { hasPermission } from '@outreach/mixins/userHasPermission';
import VButton from '@outreach/components/VButton';
import MailToButton from '@outreach/components/contacts/MailToButton';
import { mapState } from 'vuex';

export default {
    components: { Avatar, VButton, MailToButton },
    mixins: [hasPermission],
    props: {
        link: { type: Object, default: () => ({}) }
    },
    data: () => ({
        logs: [],
        meta: {}
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    methods: {
        formatDateTime,
        formatRelativeDateTime,
        withProtocol (url) {
            if (!/^((https?):\/\/)/.test(url)) {
                return `https://${url}`;
            }
            return url;
        }
    }
};
</script>
<style scoped>
.details > div > span {
    @apply font-normal;
}

.address > p {
    @apply text-gray-600;
}

.contact-avatar {
    @apply w-10 h-10 rounded-full mr-4 overflow-hidden bg-gray-300 border border-gray-300 lg:w-20 lg:h-20;
}
</style>
