<template>
    <article v-if="article" class="flex flex-col">
        <portal to="header-actions">
            <v-multiple-select
                id="sender-address-select"
                ref="city-select"
                class="w-full"
                label="address1"
                placeholder="Sender Address"
                :allow-empty="false"
                :internal-search="false"
                :loading="loading"
                :multiple="false"
                :options="addresses"
                :value="address ? [address] : []"
                @input="address = $event"
            />
        </portal>
        <section class="px-2 md:px-0">
            <v-clipboard v-if="isLinkedIn" :copy="article.title">
                <p class="text-4xl font-bold">{{ article.title }}</p>
            </v-clipboard>
            <p v-else class="text-4xl font-bold">{{ article.title }}</p>
        </section>

        <section v-if="isLinkedIn" class="px-2 md:px-0">
            <v-clipboard :copy="interpolatedHashtags">
                <p class="text-sm text-gray-500">{{ interpolatedHashtags }}</p>
            </v-clipboard>
        </section>

        <section class="my-4 relative group">
            <button type="button" class="article-download-image" @click="downloadImage(article.header_image)">
                <i class="fa fa-download"></i>
            </button>
            <div v-if="isVideo" class="aspect-w-16 aspect-h-9">
                <iframe
                    :src="videoEmbedLink"
                    width="640"
                    height="360"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
            <img v-else :src="article.header_image" class="overflow-hidden md:rounded-lg" />
        </section>

        <v-panel title="Content Body">
            <v-clipboard v-if="isLinkedIn" :copy="interpolatedBody">
                <article
                    ref="article-body"
                    class="prose max-w-full mt-4 relative"
                    v-html="interpolatedBody"
                ></article>
            </v-clipboard>
            <article
                v-else
                ref="article-body"
                class="prose max-w-full mt-4 relative"
                v-html="interpolatedBody"
            ></article>
        </v-panel>

        <v-panel v-if="article.meta_title || article.meta_body" title="Meta">
            <div v-if="article.meta_title" class="mb-8">
                <div class="w-full mb-2">Title</div>
                <v-clipboard :copy="article.meta_title">
                    <div class="text-sm text-gray-500 px-4 flex-1">{{ article.meta_title }}</div>
                </v-clipboard>
            </div>

            <div v-if="article.meta_body">
                <div class="w-full mb-2">Body</div>
                <v-clipboard :copy="article.meta_body">
                    <div class="text-sm text-gray-500 px-4 flex-1">{{ article.meta_body }}</div>
                </v-clipboard>
            </div>
        </v-panel>

        <v-panel
            v-if="images.length > 0"
            title="Download Images"
            subtitle="Click an image to download"
        >
            <ul role="list" class="article-list">
                <li v-for="(file, index) in images" :key="index" class="relative">
                    <div class="article group">
                        <img :src="file.url" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                            :ref="`articleImage-${index}`"
                            type="button"
                            class="absolute inset-0 focus:outline-none"
                            @click="downloadImage(file.url, `article-image-${index+1}`)"
                        >
                            <span class="sr-only">View details for article-image-{{ index + 1 }}</span>
                        </button>
                    </div>
                </li>
            </ul>
        </v-panel>

    </article>
    <v-loader v-else />
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import { downloadFromUrl } from '@services/Utils';
import VClipboard from '@outreach/components/shared/VClipboard';
import VPanel from '@outreach/components/shared/VPanel';
import VMultipleSelect from '@outreach/components/inputs/VMultipleSelect';
import interpolateString from '@services/MustacheParser';
import VLoader from '@outreach/components/VLoader';
import { ArticleType } from '@models/Article';
import { split } from 'lodash';

const ImageRegex = /<img\s+[^>]*?src=("|')([^"']+)\1/g;

export default {
    name: 'ViewArticle',
    components: { VClipboard, VPanel, VMultipleSelect, VLoader },
    data () {
        return {
            address: null
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            article: ({ articles }) => articles.record,
            addresses: ({ addresses }) => addresses.addresses
        }),
        ...mapGetters({
            loading: 'loader/isLoading'
        }),
        interpolatedBody () {
            return this.interpolate(this.article.body);
        },
        interpolatedHashtags () {
            return this.interpolate(this.article.hashtags);
        },
        images () {
            return [...(this.article.body || '').matchAll(ImageRegex)]
                .map((match) => {
                    const url = match[2];
                    return {
                        url,
                        fileName: url.substring(url.lastIndexOf('/') + 1)
                    };
                });
        },
        videoEmbedLink () {
            const videoId = split(this.article.media_url, '/', 4).pop();
            const hash = split(this.article.media_url, '/', 5).pop();
            return `https://player.vimeo.com/video/${videoId}?h=${hash}`;
        },
        isVideo () {
            return this.article.type === ArticleType.VIDEO;
        },
        isLinkedIn () {
            return this.article.type === ArticleType.STANDARD;
        }
    },
    async created () {
        await this.fetch({ articleId: this.$route.params.articleId });
        await this.populateAddress();
    },
    methods: {
        ...mapActions({
            fetch: 'articles/fetch',
            getAddresses: 'addresses/list'
        }),
        downloadImage (...args) {
            downloadFromUrl(...args);
        },
        interpolate (value) {
            return interpolateString(value || '', {
                firstname: this.user.first_name,
                lastname: this.user.last_name,
                city: this.address ? this.address.city : ''
            }, (value) => value.toLowerCase().replace(/\s/gm, ''));
        },
        async populateAddress () {
            await this.getAddresses({ account_id: this.user.account_id });
            const defaultAddress = this.addresses.find(address => address.default);
            this.address = defaultAddress || this.address[0];
        }
    }
};
</script>

<style lang="scss" scoped>
.article-list {
    @apply grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 p-2 rounded;
}

.article {
    @apply block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden;
    @apply focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500;
}

.article-download-image {
    @apply hidden group-hover:inline-flex absolute right-2 top-2 items-center p-3 border border-transparent;
    @apply rounded-full shadow-sm text-white bg-brand-regular focus:outline-none;
}
</style>
