import services from '@services/Api';

export const state = {
    stats: {},
    pagination: {}
};

export const actions = {
    fetchStats: async function ({ commit }) {
        const { data } = await services.queues.stats();
        commit('setStats', data.data);
    },
    clearQueue: async function () {
        await services.queues.clear();
    },
    refreshQueues: async function ({ dispatch }) {
        dispatch('fetchStats');
        dispatch('pending/listCampaigns', null, { root: true });
        dispatch('reserved/listCampaigns', null, { root: true });
        dispatch('completed/listCampaigns', null, { root: true });
    }
};

export const mutations = {
    setStats: (state, stats = {}) => {
        state.stats = stats;
    }
};

export const getters = {
    stats: (state) => state.stats,
    pagination: (state) => state.pagination
};

export default { namespaced: true, state, actions, mutations, getters };
