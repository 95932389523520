"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const markerwithlabel_1 = require("@googlemaps/markerwithlabel");
class EstateClusterMarker extends markerwithlabel_1.MarkerWithLabel {
    constructor(cluster) {
        super({
            labelContent: '',
            position: cluster.position,
            icon: { path: '' },
            title: `Cluster of ${cluster.count} markers`,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count
        });
        this.cluster = cluster;
        this.calculateEstateCount().then((count) => {
            this.labelContent = this.createMarker(count);
        });
    }
    getClass() {
        return 'flex bg-red-500 items-center justify-center border-2 border-white leading-none h-8 w-8 rounded-full';
    }
    createMarker(count) {
        return `<div class="${this.getClass()}"><p class="text-white font-bold">${count.toString()}</p></div>`;
    }
    calculateEstateCount() {
        return new Promise((resolve) => {
            resolve(this.cluster.markers
                .reduce((total, marker) => total + marker.count(), 0));
        });
    }
}
exports.default = EstateClusterMarker;
