import ApiClient from '@services/Http/Api';

export const list = (params = {}) => {
    return ApiClient.get('/dashboard/prospects', { params });
};

export const create = (campaign) => {
    return ApiClient.post('/dashboard/prospects/campaigns', campaign);
};

let abortController;

export const listProperties = (params = {}) => {
    if (abortController != null) {
        abortController.abort();
    }
    abortController = new AbortController();
    return ApiClient.get('/dashboard/properties', { params }, { signal: abortController.signal });
};
export const estateList = (params = {}) => {
    if (abortController != null) {
        abortController.abort();
    }
    abortController = new AbortController();
    return ApiClient.post('/dashboard/estates', params, { signal: abortController.signal });
};

export const createFromEstates = (campaign) => {
    return ApiClient.post('/dashboard/estates/campaigns', campaign);
};

export const estateCount = (path) => {
    return ApiClient.get('/dashboard/estates/count', { params: { path } });
};

export const claimTerritory = (params = {}) => {
    return ApiClient.post('/dashboard/territories', params);
};

export const listTerritories = (params = {}) => {
    return ApiClient.get('/dashboard/territories', { params });
};

export const exportProperties = (params = {}) => {
    return ApiClient.post('/dashboard/properties/export', params);
};
