<template>
    <div class="flex flex-col xl:flex-row justify-between">
        <div class="bg-white mb-4 preview-lg shadow-md relative h-full w-full">
            <div class="aspect-32 crease relative">
                <img :src="previews.letter" alt="Note inside" class="image-lg" />
                <img
                    v-if="previews.qr_code"
                    ref="qr-code"
                    :src="previews.qr_code"
                    alt="QR Code"
                    class="absolute bottom-0 right-0 w-12 h-12 mb-7"
                    :class="previews.has_doodles ? 'mr-32' : 'mr-4'"
                />
            </div>
        </div>
        <div class="flex flex-col lg:ml-5 items-start">
            <div>
                <h3 class="text-sm mb-2 uppercase font-semibold text-gray-500">Front</h3>
                <div
                    class="bg-white flex justify-center items-center mb-4 preview-sm shadow-md cursor-pointer"
                    @click="openImagePreview"
                >
                    <img v-if="photoPath" class="max-h-full max-w-full mx-auto" :src="photoPath" alt="Note preview" />
                </div>
            </div>
            <div>
                <h3 class="text-sm mb-2 uppercase font-semibold text-gray-500">Envelope</h3>
                <div class="flex flex-row lg:flex-col flex-wrap -mx-2">
                    <div class="bg-white mx-2 mb-4 preview-sm shadow-md">
                        <img
                            height="30"
                            width="57"
                            src="/images/usa-stamp.png"
                            alt="Stamp"
                            class="absolute top-1 right-0"
                        />
                        <img :src="`${previews.envelope}`" alt="Envelope front" class="image-sm" />
                    </div>
                </div>
            </div>
            <div v-if="previews.post_it && canViewPostItNotes">
                <div class="flex">
                    <h3 class="text-sm mb-2 uppercase font-semibold text-gray-500">Post-it</h3>
                    <button
                        ref="discard-post-it"
                        v-tooltip="'Discard Post-It'"
                        size="sm"
                        class="discard-button"
                        @click="$emit('discard-post-it')"
                    >
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
                <div class="flex flex-row lg:flex-col flex-wrap -mx-2">
                    <div class="bg-white mx-2 post-it-preview mb-4 shadow-md">
                        <img ref="post-it" :src="`${previews.post_it}`" alt="Post-it" class="image-sm" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';

export default {
    props: {
        previews: { type: Object, required: true },
        photoPath: { type: String, default: undefined }
    },
    data: () => ({
        photo: null
    }),
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            version: ({ review }) => review.previewVersion
        }),
        canViewPostItNotes () {
            if (!this.user || !this.user.preferences) return false;
            return Boolean(this.user.preferences.post_it_notes);
        }
    },
    methods: {
        async openImagePreview () {
            await dialogEmitter.openDialog('ImagePreviewDialog', { url: this.photoPath });
        }
    }
};
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    $w-preview-sm: 240px;

    .crease {
        background: rgb(255,255,255);
        background: linear-gradient(
            180deg,
            rgba(255,255,255,0) 45.5%,
            rgba(120,120,120,0.05) 47.5%,
            rgba(120,120,120,0.4) 50%,
            rgba(255,255,255,0) 50.1%,
            rgba(255,255,255,0) 100%
        );
    }
    .image-lg {
        position: absolute;
        width: 100%;
    }
    .image-sm {
        position: absolute;
        width: 100%;
    }
    .preview-lg {
        position: relative;
        overflow: hidden;
        width: 100%;
    }
    .preview-sm {
        height: math.div($w-preview-sm * 2, 3);
        overflow: hidden;
        position: relative;
        width: $w-preview-sm;
    }
    .post-it-preview{
        height: $w-preview-sm;
        width: $w-preview-sm;
        overflow: hidden;
        position: relative;
    }
    .discard-button {
        @apply shadow ml-2 px-1.5 py-0.5 bg-red-500 border-red-500 h-1/2 rounded-md text-white text-xs items-start hover:opacity-90;
    }
    .aspect-32 {
        padding-bottom: 150%;
    }
</style>
