<template>
    <div class="container mx-auto">
        <search-header>
            <template #content>
                <v-input v-model="query" class="mb-2 sm:mb-0 mr-0 sm:mr-2 flex-1" label="Search" />
            </template>
        </search-header>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="flex flex-col items-center justify-center">
                <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                <span class="text-gray-700">Loading report&hellip;</span>
            </div>
            <div v-else>
                <div v-if="reps.length > 0">
                    <v-table
                        ref="reps-table"
                        is-elevated
                        has-actions
                        :rows="reps"
                        :sort="sorting"
                        @sort-changed="updateSort"
                    >
                        <template #default="{ row }">
                            <v-table-column label="Name" value="name">
                                <div class="mt-2">
                                    <p><strong class="text-lg">{{ row.name }}</strong></p>
                                    <p class="text-xs inline-block text-gray-700">
                                        <a :href="`mailto:${row.email}`" target="_blank">
                                            <i class="fa fa-envelope ml-auto"></i> {{ row.email }}
                                        </a>
                                    </p>
                                </div>
                            </v-table-column>
                            <v-table-column label="Last Logged-in" value="last_loggedin">
                                <div v-if="row.last_login">
                                    <p>{{ formatDate(row.last_login.logged_in_at) }}</p>
                                    <p class="text-sm text-gray-700">{{ fromNow(row.last_login.logged_in_at) }}</p>
                                </div>
                                <div v-else>Never</div>
                            </v-table-column>
                            <v-table-column label="Number of Customers" value="customer_count" sortable>
                                <span>{{ row.customer_count }}</span>
                            </v-table-column>
                            <v-table-column label="Stale Campaigns" value="stale_campaigns" sortable>
                                <span>{{ row.stale_campaigns }}</span>
                            </v-table-column>
                        </template>
                    </v-table>
                    <div class="flex items-center justify-center py-8 px-2">
                        <v-paginator
                            class="flex-none"
                            :total="meta.last_page"
                            :value="meta.current_page"
                            @input="fetchReps({ page: $event })"
                        />
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <p class="text-lg text-gray-700">No reps found.</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import services from '@services/Api';
import VLoader from '@outreach/components/VLoader';
import VPaginator from '@outreach/components/VPaginator';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import { mapState } from 'vuex';
import { debounce } from 'lodash';
import { formatDate, fromNow } from '@services/Filters';
import VInput from '@outreach/components/inputs/VInput';
import SearchHeader from '@outreach/components/shared/SearchHeader';

export default {
    name: 'RepStaleCampaignsReport',
    components: {
        SearchHeader,
        VLoader,
        VPaginator,
        VTable,
        VTableColumn,
        VInput
    },
    data: () => ({
        meta: {},
        reps: [],
        selectedAccount: {},
        sorting: {
            key: undefined,
            direction: undefined
        },
        formErrors: {},
        query: null
    }),
    computed: mapState({
        loading: ({ loader }) => loader.loading,
        user: ({ user }) => user.user
    }),
    watch: {
        query: debounce(function () {
            this.fetchReps();
        }, 500)
    },
    created () {
        this.fetchReps();
    },
    methods: {
        formatDate,
        fromNow,
        async fetchReps (params = {}) {
            const { data } = await services.admin.repStaleCampaigns({
                q: this.query,
                ...params,
                sort_key: this.sorting.key,
                sort_direction: this.sorting.direction
            });
            this.reps = data.data;
            this.meta = data.meta;
        },
        updateSort (data) {
            this.sorting = data;
            this.fetchReps();
        }
    }
};
</script>
