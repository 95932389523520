<template>
    <div>
        <v-input
            ref="address-name-input"
            class="mb-6"
            label="Mailer Name (Optional)"
            :value="value.name"
            :errors="formErrors['address.name']"
            @input="update('name', $event)"
        />
        <v-input
            ref="address1-input"
            class="mb-6"
            label="Address"
            :value="value.address1"
            :errors="formErrors['address.address1']"
            @input="update('address1', $event)"
        />
        <v-input
            class="mb-6"
            label="Address2"
            :value="value.address2"
            :errors="formErrors['address.address2']"
            @input="update('address2', $event)"
        />
        <v-input
            class="mb-6"
            label="City"
            :value="value.city"
            :errors="formErrors['address.city']"
            @input="update('city', $event)"
        />
        <v-input
            class="mb-6"
            label="State"
            :value="value.state"
            :errors="formErrors['address.state']"
            @input="update('state', $event)"
        />
        <v-input
            class="mb-6"
            label="Postal Code"
            :value="value.postcode"
            :errors="formErrors['address.postcode']"
            @input="update('postcode', $event)"
        />
        <v-input
            class="mb-6"
            label="Country"
            :value="value.country"
            :errors="formErrors['address.country']"
            @input="update('country', $event)"
        />
    </div>
</template>
<script>
import VInput from '@outreach/components/inputs/VInput';

export default {
    name: 'AddressForm',
    components: { VInput },
    props: {
        value: { type: Object, default: null },
        formErrors: { type: Object, required: true }
    },
    methods: {
        update (key, value) {
            this.$emit('input', { ...this.value, [key]: value });
        }
    }
};
</script>
