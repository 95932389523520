<template>
    <div class="w-full">
        <transition name="fade" mode="out-in">
            <div v-if="!selectedPlan">
                <div class="mb-4">
                    <h2 class="text-3xl font-normal text-gray-800 mb-2">Purchase Credits</h2>
                    <pricing-slider
                        v-if="plans.length"
                        :max-credits="maxCredits"
                        :min-credits="minCredits"
                        :current-credits="user.account.credits"
                        :credit-price="plans[0].base_price"
                        :plans="sortedPlans"
                        class="bg-gray-50"
                        @credits="onCredits"
                    />
                </div>
                <h2 class="text-3xl font-normal text-gray-800 mb-2">Select Plan</h2>
                <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
                    <pricing-card
                        v-for="(plan, index) in plans"
                        :key="`package-${index}`"
                        :plan="plan"
                        :badge="plan.badge"
                        @selected="selectPlan(plan)"
                    />
                </div>
            </div>
            <plan-payment
                v-else
                :plan="selectedPlan"
                @back="selectedPlan = undefined"
                @purchased="selectedPlan = undefined; $emit('purchased')"
            />
        </transition>
    </div>
</template>
<script>
import PricingCard from '@outreach/components/payments/PricingCard.vue';
import PlanPayment from '@outreach/pages/Payment/PlanPayment.vue';
import services from '@services/Api';
import { Alert } from '@services/Alert';
import { mapState } from 'vuex';
import PricingSlider from '@outreach/components/payments/PricingSlider.vue';
import { Plan } from '@models/Plan';

export default {
    name: 'Pricing',
    components: { PlanPayment, PricingCard, PricingSlider },
    props: {
        minCredits: { type: Number, default: 5 },
        maxCredits: { type: Number, default: 3000 }
    },
    data: () => {
        return {
            selectedPlan: undefined,
            plans: [],
            sortedPlans: [],
            total: 0
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user
        })
    },
    mounted () {
        this.fetchPricing();
    },
    methods: {
        selectPlan (plan) {
            this.selectedPlan = plan;
        },
        async fetchPricing () {
            try {
                this.plans = await services.plans.list({ draft: false });
                this.sortedPlans = [...this.plans].sort((a, b) => b.credits - a.credits);
            } catch (e) {
                Alert.error('Error displaying pricing, please trying again');
            }
        },
        onCredits (credits) {
            const plan = this.sortedPlans.find(plan => credits >= plan.credits);
            this.selectedPlan = new Plan({
                id: 0,
                name: plan?.name ?? 'Purchase Credits',
                credits,
                note_price: plan?.note_price ?? this.plans[0].base_price,
                base_price: plan?.base_price ?? this.plans[0].base_price
            });
        }
    }
};
</script>
