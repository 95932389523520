<template>
    <v-table has-actions :rows="emails" :sort="sort" @sort-changed="sortEmails">
        <template #default="{ row }">
            <v-table-column label="Details" value="sender">
                <div class="flex flex-col text-sm gap-2 max-w-[120px] sm:max-w-[240px] md:max-w-none">
                    <p class=" font-bold">{{ row.subject }}</p>
                    <div class="flex flex-col lg:flex-row lg:gap-2">
                        <div class="md:flex ">
                            <span class="text-gray-700 mr-1">To:</span>
                            <p class="break-words">{{ row.recipient_email }}</p>
                        </div>
                        <div class="md:flex ">
                            <span class="text-gray-700 mr-1">From:</span>
                            <p class="break-words">{{ row.sender_email }}</p>
                        </div>
                    </div>
                </div>
            </v-table-column>
            <v-table-column label="Sent" value="created_at" sortable>
                {{ formatDateTime(row.created_at) }}
            </v-table-column>
        </template>
        <template #priority-buttons="{row}">
            <button :id="`content-dialog-${row.id}`" @click="viewContent(row)">
                View
            </button>
        </template>
    </v-table>
</template>

<script>

import { VTable, VTableColumn } from '@outreach/components/shared/table';
import { formatDateTime } from '@services/Filters';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';

export default {
    name: 'SentEmailTable',
    components: {
        VTable,
        VTableColumn
    },
    props: {
        emails: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            sort: {
                key: 'created_at',
                direction: 'DESC'
            }
        };
    },
    methods: {
        formatDateTime,
        viewContent (email) {
            dialogEmitter.name('EmailContentDialog').props({ content: email.content }).wait();
        },
        sortEmails (sort) {
            this.sort = sort;
            this.$emit('sort', { sort_key: sort.key, sort_direction: sort.direction });
        }
    }
};
</script>
