import EventEmitter from 'eventemitter2';

class DialogEmitter extends EventEmitter {
    #name = undefined;
    #header = undefined;
    #message = undefined;
    #onDecline = () => {};
    #onConfirm = () => {};
    #props = {};
    #listeners = {};

    openDialog (name, props = {}, listeners = {}) {
        this.emit('open-dialog', {
            name,
            props,
            listeners
        });
    }

    wait () {
        this.emit('open-dialog', {
            name: this.#name,
            props: this.options,
            listeners: this.#listeners
        });
    }

    get options () {
        return {
            header: this.#header,
            message: this.#message,
            ...this.#props
        };
    }

    resetState () {
        this.#onDecline = () => {};
        this.#onConfirm = () => {};
        this.#listeners = {};
        this.#props = {};
        this.#name = undefined;
        this.#header = undefined;
        this.#message = undefined;
    }

    onConfirmed (action) {
        this.#onConfirm = action;
        return this;
    }

    onDeclined (action) {
        this.#onDecline = action;
        return this;
    }

    doConfirmed () {
        return Promise.resolve(this.#onConfirm()).then(() => this.resetState());
    }

    doDeclined () {
        return Promise.resolve(this.#onDecline()).then(() => this.resetState());
    }

    name (name) {
        this.#name = name;
        return this;
    }

    header (header) {
        this.#header = header;
        return this;
    }

    props (props) {
        this.#props = props;
        return this;
    }

    addProp (key, value) {
        this.#props = { ...this.#props, [key]: value };
        return this;
    }

    isDestructive () {
        this.#props = { ...this.#props, confirmButtonStyle: 'error' };
        return this;
    }

    message (message) {
        this.#message = message;
        return this;
    }

    listeners (listeners) {
        this.#listeners = listeners;
        return this;
    }

    closeDialog () {
        this.emit('close-dialog');
    }

    confirm (header = undefined, message = this.#message, propOverrides = this.options) {
        return new Promise((resolve) => {
            this.openDialog('Confirm', { ...propOverrides, header, message, onSuccess: resolve });
        }).then(result => {
            result === true ? this.doConfirmed() : this.doDeclined();
            return result;
        });
    }

    addListener (name, callback) {
        this.#listeners[name] = callback;
        return this;
    }
}

export const dialogEmitter = new DialogEmitter();

export const DefaultDialogProps = {
    closeDialog: { type: Function, required: true }
};
