import services from '@services/Api';

const state = {
    campaign: null,
    contacts: {
        records: [],
        meta: {}
    }
};

const actions = {
    getCampaign: async ({ commit }, { campaignId, includes = [] }) => {
        const { data } = await services.campaigns.fetch(campaignId, includes);
        commit('setCampaign', data.data);
    },
    setCampaignAddress: async ({ commit, state }, addressId) => {
        const { data } = await services.campaigns.update(state.campaign.id, {
            sender_address_id: parseInt(addressId, 10)
        });
        commit('setAddressId', data.data.sender_address_id);
    },
    setCampaignContacts: async ({ commit, state }, ids) => {
        const { data } = await services.campaigns.update(state.campaign.id, { contact_ids: ids });
        commit('setCampaign', data.data);
    },
    setCampaignCard: async ({ commit, state }, cardId) => {
        const { data } = await services.campaigns.update(state.campaign.id, {
            card_id: parseInt(cardId, 10)
        });
        commit('setCardId', data.data.card_id);
    },
    getContacts: async ({ commit, state }, { params = {} }) => {
        const { data } = await services.campaigns.fetchContacts(state.campaign.id, params);
        commit('setCampaignContacts', data);
    }
};

const mutations = {
    setCampaign: (state, campaign) => {
        state.campaign = campaign;
    },
    setCardId: (state, id) => {
        state.campaign = { ...state.campaign, card_id: id };
    },
    setCampaignContacts: (state, data) => {
        state.contacts.records = data.data;
        state.contacts.meta = data.meta;
    }
};

export default { namespaced: true, state, actions, mutations };
