<template>
    <v-multiple-select
        :taggable="taggable"
        :loading="loading"
        :errors="formErrors"
        :options="tags"
        :value="value"
        :internal-search="false"
        :placeholder="placeholder"
        :options-limit="tags.length"
        class="flex-1"
        @input="$emit('input', $event)"
        @tag="addTag"
        @search-change="debounceLoad"
        @reached-bottom="reachedBottom"
    />
</template>

<script>
import services from '@services/Api';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import VMultipleSelect from '@outreach/components/inputs/VMultipleSelect';
import { TagType } from '@services/TagType';

export default {
    name: 'TagSelect',
    components: { VMultipleSelect },
    props: {
        value: { type: Array, default: () => ([]) },
        taggable: { type: Boolean, default: true },
        placeholder: { type: String, default: 'Tags' },
        type: { type: String, default: undefined, validator: (value) => Object.values(TagType).includes(value) }
    },
    data () {
        return {
            tags: [],
            formErrors: undefined,
            currentPage: 1,
            lastPage: undefined,
            q: undefined
        };
    },
    computed: {
        ...mapState({
            user: ({ user }) => user.user,
            loading: ({ loader }) => loader.loading
        }),
        hasMorePages () {
            return !this.lastPage || this.lastPage > this.currentPage;
        }
    },
    mounted () {
        this.loadTags();
    },
    methods: {
        debounceLoad: debounce(function (query) {
            this.q = query;
            this.tags = [];
            this.currentPage = 1;
            this.loadTags();
        }, 500),
        list (params) {
            return services.tags.list(this.user.account_id, params);
        },
        create (params) {
            return services.tags.create(this.user.account_id, params);
        },
        async loadTags () {
            const { data } = await this.list({ q: this.q, page: this.currentPage, type: this.type });
            this.tags = [...this.tags, ...data.data];
            this.lastPage = data.meta?.last_page;
        },
        async addTag (name) {
            try {
                const { data } = await this.create({ name, type: this.type });
                this.tags.push(data.data);
                this.$emit('input', [...this.value, data.data]);
            } catch (error) {
                if (!((error.response || {}).data || {}).errors) throw error;
                this.formErrors = error.response.data.errors;
            }
        },
        reachedBottom () {
            if (this.hasMorePages) {
                ++this.currentPage;
                this.loadTags();
            }
        }
    }
};
</script>
