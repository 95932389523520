import Admin from '@outreach/pages/admin';
import PrintQueue from '@outreach/pages/admin/PrintQueue';
import Users from '@outreach/pages/admin/Users';
import Accounts from '@outreach/pages/admin/Accounts';
import Fonts from '@outreach/pages/admin/Fonts';
import Doodles from '@outreach/pages/admin/Doodles';
import Machines from '@outreach/pages/admin/Machines';
import SubmittedCampaigns from '@outreach/pages/admin/SubmittedCampaigns';
import Collaterals from '@outreach/pages/Collaterals/Collaterals';
import CollateralForm from '@outreach/pages/Collaterals/CollateralForm';
import Templates from '@outreach/pages/Templates/Templates';
import AddTemplateContainer from '@outreach/pages/Templates/AddTemplateContainer';
import EditTemplate from '@outreach/pages/Templates/EditTemplate';
import Tools from '@outreach/pages/admin/Tools';
import ScanSummaryReport from '@outreach/pages/admin/ScanSummaryReport';
import PrintCountReport from '@outreach/pages/admin/PrintCountReport';
import LastSentReport from '@outreach/pages/admin/LastSentReport';
import Articles from '@outreach/pages/Articles/Articles';
import EditArticle from '@outreach/pages/Articles/EditArticle';
import Associates from '@outreach/pages/Associates/Associates';
import Drawings from '@outreach/pages/admin/Drawings';
import ManageCustomers from '@outreach/pages/Customers/ManageCustomers';
import ViewEmails from '@outreach/pages/admin/ViewEmails';
import Covers from '@outreach/pages/admin/Covers.vue';
import Properties from '@outreach/pages/admin/Properties.vue';
import AdminPricing from '@outreach/pages/admin/AdminPricing.vue';
import Invitations from '@outreach/pages/admin/Invitations.vue';
import RepStaleCampaignsReport from '@outreach/pages/admin/RepStaleCampaignsReport.vue';

export default [
    {
        path: '/admin',
        component: Admin,
        children: [
            {
                path: '/admin/print-queue',
                name: 'print-queue',
                component: PrintQueue,
                meta: {
                    title: 'Print Queue Monitor',
                    breadcrumbs: [{ name: 'print-queue', label: 'Print Queue' }],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/users',
                name: 'admin-users',
                component: Users,
                meta: {
                    title: 'Users',
                    breadcrumbs: [{ name: 'admin-users', label: 'Users' }],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/accounts',
                name: 'admin-accounts',
                component: Accounts,
                meta: {
                    title: 'Accounts',
                    breadcrumbs: [{ name: 'admin-accounts', label: 'Accounts' }],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/invitations',
                name: 'admin-invitations',
                component: Invitations,
                meta: {
                    title: 'Invitations',
                    breadcrumbs: [{ name: 'admin-invitations', label: 'Invitations' }],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/users/:userId/customers',
                name: 'manage-customers',
                component: ManageCustomers,
                meta: {
                    title: 'Manage Customers',
                    breadcrumbs: [
                        { name: 'admin-users', label: 'Users' },
                        { name: 'customers', label: 'Customers' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/fonts',
                name: 'admin-fonts',
                component: Fonts,
                meta: {
                    title: 'Fonts',
                    breadcrumbs: [{ name: 'admin-fonts', label: 'Fonts' }],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/doodles',
                name: 'admin-doodles',
                component: Doodles,
                meta: {
                    title: 'Doodles',
                    breadcrumbs: [{ name: 'admin-doodles', label: 'Doodles' }],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/drawings',
                name: 'admin-drawings',
                component: Drawings,
                meta: {
                    title: 'Drawings',
                    breadcrumbs: [{ name: 'admin-drawings', label: 'Drawings' }],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/machines',
                name: 'admin-machines',
                component: Machines,
                meta: {
                    title: 'Machines',
                    breadcrumbs: [{ name: 'admin-machines', label: 'Machines' }],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/one-off-campaigns',
                redirect: '/admin/submitted-campaigns'
            },
            {
                path: '/admin/submitted-campaigns',
                name: 'submitted-campaigns',
                component: SubmittedCampaigns,
                meta: {
                    title: 'Submitted Campaigns',
                    requiresAuth: true,
                    fullWidth: true
                }
            },
            {
                path: '/admin/properties',
                name: 'properties-index',
                component: Properties,
                meta: {
                    title: 'Properties',
                    requiresAuth: true,
                    fullWidth: true
                }
            },
            {
                path: '/admin/collaterals',
                name: 'collaterals',
                component: Collaterals,
                meta: {
                    breadcrumbs: [{ name: 'collaterals', label: 'Add-ons' }],
                    title: 'Add-ons',
                    requiresAuth: true
                }
            },
            {
                path: '/admin/collaterals/add',
                name: 'add-collateral',
                component: CollateralForm,
                meta: {
                    breadcrumbs: [
                        { name: 'collaterals', label: 'Add-ons' },
                        { name: 'add-collateral', label: 'Create' }
                    ],
                    title: 'Add Collateral',
                    requiresAuth: true
                }
            },
            {
                path: '/admin/collaterals/:collateral/edit',
                name: 'edit-collateral',
                component: CollateralForm,
                meta: {
                    breadcrumbs: [
                        { name: 'collaterals', label: 'Add-ons' },
                        { name: 'edit-collateral', label: 'Edit' }
                    ],
                    title: 'Edit Collateral',
                    requiresAuth: true
                }
            },
            {
                path: '/shared/templates',
                name: 'shared-templates',
                component: Templates,
                meta: {
                    title: 'Templates',
                    breadcrumbs: [{ name: 'shared-templates', label: 'Templates' }],
                    requiresAuth: true
                }
            },
            {
                path: '/shared/templates/add',
                name: 'add-shared-templates',
                component: AddTemplateContainer,
                meta: {
                    title: 'Add Template',
                    breadcrumbs: [
                        { name: 'shared-templates', label: 'Templates' },
                        { name: 'add-shared-templates', label: 'Add Templates' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/shared/templates/:template',
                name: 'edit-shared-templates',
                component: EditTemplate,
                meta: {
                    title: 'Edit Template',
                    breadcrumbs: [
                        { name: 'shared-templates', label: 'Templates' },
                        { name: 'edit-shared-templates', label: 'Edit Template' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/tools',
                name: 'tools',
                component: Tools,
                meta: {
                    title: 'Tools',
                    requiresAuth: true
                }
            },
            {
                path: '/admin/reports/scan-summary',
                name: 'scan-summary-report',
                component: ScanSummaryReport,
                meta: {
                    title: 'Scan Summary',
                    breadcrumbs: [
                        { name: 'reports', label: 'Reports' },
                        { name: 'scan-summary-report', label: 'Scan Summary' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/reports/print-count',
                name: 'print-count-report',
                component: PrintCountReport,
                meta: {
                    title: 'Print Count',
                    breadcrumbs: [
                        { name: 'reports', label: 'Reports' },
                        { name: 'print-count-report', label: 'Print Count' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/reports/last-sent',
                name: 'last-sent-report',
                component: LastSentReport,
                meta: {
                    title: 'Last Sent',
                    breadcrumbs: [
                        { name: 'reports', label: 'Reports' },
                        { name: 'last-sent-report', label: 'Last Sent' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: '/admin/reports/rep-stale-campaigns',
                name: 'rep-stale-campaigns-report',
                component: RepStaleCampaignsReport,
                meta: {
                    title: 'Rep Stale Campaigns',
                    breadcrumbs: [
                        { name: 'reports', label: 'Reports' },
                        { name: 'rep-stale-campaigns-report', label: 'Rep Stale Campaigns' }
                    ],
                    requiresAuth: true
                }
            },
            {
                path: 'articles',
                component: Admin,
                children: [
                    {
                        path: '',
                        name: 'admin-articles',
                        component: Articles,
                        meta: {
                            title: 'Content',
                            breadcrumbs: [
                                { label: 'Content' }
                            ],
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'add',
                        name: 'add-article',
                        component: EditArticle,
                        meta: {
                            title: 'Add Content',
                            breadcrumbs: [
                                { name: 'admin-articles', label: 'Content' },
                                { name: 'add-article', label: 'Add Content' }
                            ],
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'edit/:articleId',
                        name: 'edit-article',
                        component: EditArticle,
                        meta: {
                            title: 'Edit Content',
                            breadcrumbs: [
                                { name: 'admin-articles', label: 'Content' },
                                { name: 'edit-article', label: 'Edit Content' }
                            ],
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                name: 'associates',
                path: 'associates',
                component: Associates,
                meta: {
                    title: 'Associates',
                    breadcrumbs: [
                        { label: 'Associates' }
                    ],
                    requiresAuth: true
                }
            },
            {
                name: 'view-emails',
                path: 'accounts/:accountId/view-emails',
                component: ViewEmails,
                meta: {
                    title: 'View Emails',
                    breadcrumbs: [
                        { name: 'admin-accounts', label: 'Accounts' },
                        { label: 'View Emails' }
                    ],
                    requiresAuth: true
                }
            },
            {
                name: 'covers',
                path: 'covers',
                component: Covers,
                meta: {
                    title: 'Covers',
                    requiresAuth: true
                }
            },
            {
                path: 'pricing',
                name: 'pricing',
                component: AdminPricing,
                meta: {
                    title: 'Pricing',
                    breadcrumbs: [{ name: 'pricing', label: 'Pricing' }],
                    requiresAuth: true
                }
            }
        ]
    }
];
