import ApiClient from '@services/Http/Api';

export const list = (params = {}) => {
    return ApiClient.get('/dashboard/doodles', { params });
};

export const destroy = (id) => {
    return ApiClient.destroy(`/dashboard/doodles/${id}`);
};

export const create = (payload) => {
    payload = { ...payload, ...{ tags: JSON.stringify(payload.tags) } };
    return ApiClient.upload('/dashboard/doodles', payload);
};

export const update = (id, params = {}) => {
    return ApiClient.patch(`/dashboard/doodles/${id}`, params);
};
