<template>
    <canvas ref="chart" width="400" height="200"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
    name: 'LineGraph',
    props: {
        labels: { type: Array, default: () => [] },
        data: { type: Array, default: () => [] }
    },
    watch: {
        labels () {
            this.$refs.chartInstance.data.labels = this.labels;
            this.$refs.chartInstance.update();
        },
        data () {
            this.$refs.chartInstance.data.datasets[0].data = this.data;
            this.$refs.chartInstance.update();
        }
    },
    mounted () {
        this.createGraph();
    },
    beforeDestroy () {
        this.$refs.chartInstance.destroy();
    },
    methods: {
        createGraph () {
            this.$refs.chartInstance = new Chart(this.$refs.chart, {
                type: 'line',
                data: {
                    labels: this.labels,
                    datasets: [
                        {
                            data: this.data,
                            borderColor: '#FE962D',
                            backgroundColor: '#FE962D',
                            tension: 0.5
                        }
                    ]

                },
                options: {
                    plugins: {
                        legend: {
                            display: false
                        }
                    },
                    scales: {
                        y: {
                            display: false
                        },
                        x: {
                            grid: {
                                display: false
                            }
                        }
                    },
                    responsive: true
                }
            });
        }
    }
};
</script>
