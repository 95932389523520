<template>
    <div class="relative">
        <div
            :style="styles"
            class="overflow-hidden relative visible"
            :class="{'closed': length >= 4 ? !expanded : null, 'invisible h-0 md:h-auto md:visible': !expanded}"
        >
            <slot></slot>
        </div>
        <button v-if="length >= 4" class=" w-full text-xs" @click="expanded = !expanded">
            {{ expanded ? 'View Less' : 'View More' }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'Expandable',
    props: {
        maxHeight: { type: Number, required: true },
        length: { type: Number, required: true }
    },
    data () {
        return {
            expanded: false
        };
    },
    computed: {
        styles () {
            return {
                maxHeight: this.expanded ? undefined : `${this.maxHeight}px`
            };
        }
    }
};
</script>

<style scoped>
    .closed:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        height: 25px;
    }
</style>
