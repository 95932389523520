<template>
    <div class="container mx-auto">
        <transition name="fade" mode="out-in">
            <div>
                <v-table
                    is-elevated
                    has-actions
                    :rows="drawings"
                >
                    <template #header>
                        <div class="flex justify-end">
                            <v-button
                                ref="add-drawing-button"
                                label="Add Drawing"
                                button-style="primary"
                                @click="createDrawing"
                            />
                        </div>
                        <div v-if="loading" class="flex flex-col items-center justify-center">
                            <v-loader class="mb-2" color="primary" :size="32" :border-width="3" />
                            <span class="text-gray-700">Loading drawings</span>
                        </div>
                    </template>
                    <template #default="{ row }">
                        <v-table-column label="Preview" value="svg">
                            <img
                                :ref="`image-preview-${row.id}`"
                                class="cursor-pointer"
                                width="75"
                                :src="row.location"
                                alt="preview"
                                @click="previewImage(row.location)"
                            />
                        </v-table-column>
                        <v-table-column is-center label="Name" value="name">
                            <span>{{ row.name }}</span>
                        </v-table-column>
                    </template>
                    <template #priority-buttons="{ row }">
                        <button ref="plot-single" @click="plot(row)">Plot</button>
                        <button :ref="`edit-drawing-button-${row.id}`" @click="updateDrawing(row)">Edit</button>
                        <button ref="delete-drawing" @click="archive(row.id)">Delete</button>
                    </template>
                </v-table>
                <div class="flex items-center justify-center py-8 px-2">
                    <v-paginator
                        class="flex-none"
                        :total="meta.last_page"
                        :value="meta.current_page"
                        @input="getDrawings"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import services from '@services/Api';
import { VTable, VTableColumn } from '@outreach/components/shared/table/index';
import VLoader from '@outreach/components/VLoader';
import VButton from '@outreach/components/VButton';
import VPaginator from '@outreach/components/VPaginator';
import ClientVisibleError from '@exceptions/ClientVisibleError';
import { mapGetters } from 'vuex';
import { dialogEmitter } from '@outreach/dialogs/dialog-utils';
import { Alert } from '@services/Alert';

export default {
    components: {
        VLoader,
        VButton,
        VTable,
        VTableColumn,
        VPaginator
    },
    data: () => ({
        drawings: [],
        deleting: null,
        meta: {},
        formErrors: {}
    }),
    computed: {
        ...mapGetters({
            loading: 'loader/isLoading'
        })
    },
    created () {
        this.getDrawings();
    },
    methods: {
        async getDrawings (page = 1) {
            try {
                const { data } = await services.drawings.list({ page });
                this.drawings = data.data;
                this.meta = data.meta;
            } catch (e) {
                await Alert.error('Could not get drawings, refresh and try again.');
            }
        },
        async archive (drawingId) {
            const confirm = await dialogEmitter.confirm(
                'Delete Drawing',
                'Are you sure you want to permanently delete this drawing?'
            );
            if (confirm) {
                try {
                    await services.drawings.archive(drawingId);
                    await this.getDrawings();
                } catch (e) {
                    throw new ClientVisibleError('Could not delete this drawing.');
                }
            }
        },
        createDrawing () {
            dialogEmitter.openDialog('DrawingDialog', { onSave: this.getDrawings });
        },
        updateDrawing (drawing) {
            dialogEmitter.openDialog('DrawingDialog', { drawing, onSave: this.onUpdate });
        },
        onUpdate (drawing) {
            const drawingToUpdate = this.drawings.findIndex(item => item.id === drawing.id);

            if (drawingToUpdate < 0) return;

            this.drawings[drawingToUpdate].location = `${drawing.location}?v=${drawing.updated_at}`;
        },
        plot (drawing) {
            dialogEmitter.openDialog('PlotDrawingDialog', { drawing });
        },
        previewImage (url) {
            dialogEmitter.openDialog('ImagePreviewDialog', { url });
        }
    }
};
</script>
