import Mustache from 'mustache';

export default function interpolateString (string, data = {}, formatter = null) {
    if (formatter) {
        data = Object.entries(data).reduce((carry, [key, value]) => ({
            ...carry,
            [key]: formatter(value)
        }), {});
    }
    return Mustache.render(string, data);
}
