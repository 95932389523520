import Vue from 'vue';
import router from './router';
import store from './audience';
import App from './components/outreach/index';
import PortalVue from 'portal-vue';
import FloatingVue from 'floating-vue';
import Toasted from 'vue-toasted';
import VueClipboard from 'vue-clipboard2';
import LoadScript from 'vue-plugin-load-script';
import './services/Filters';
import './styles/tailwind.css';
import './styles/index.scss';
import VueBrowserDetect from 'vue-browser-detect-plugin/src/main';
import '@fortawesome/fontawesome-free/css/all.css';
import VueGtag from 'vue-gtag';

Vue.use(FloatingVue);
Vue.use(PortalVue);
Vue.use(Toasted, { duration: 3000, position: 'bottom-right', iconPack: 'fontawesome' });
Vue.use(VueClipboard);
Vue.use(VueBrowserDetect);
Vue.use(LoadScript);

Vue.config.errorHandler = function (error, vm) {
    if (process.env.NODE_ENV !== 'production') {
        console.log(error);
    }

    if (error.showToast) {
        vm.$toasted.error(error.message);
    }
};

if (process.env.ANALYTICS_KEY) {
    Vue.use(VueGtag, {
        config: { id: process.env.ANALYTICS_KEY }
    });
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
